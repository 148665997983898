import { KpiActionsUnion, KpiActionTypes } from '../actions';
import { IProvisioningIssuesKpi } from '../models';

export type ProvisioningIssuesKpiState = IProvisioningIssuesKpi;

const initialState: ProvisioningIssuesKpiState = undefined;

export function provisioningIssuesKpiReducer(
    state = initialState,
    action: KpiActionsUnion,
): ProvisioningIssuesKpiState {
    switch (action.type) {
        case KpiActionTypes.GET_PROVISIONING_ISSUES_SUCCESS: {
            return action.result;
        }
        default:
            return state;
    }
}
