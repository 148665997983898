import { Component, Input, OnChanges } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

import { PagesService } from '@solocal-manager/sirius/core/core';
import { Location, Page } from '@solocal-manager/sirius/support/base-models';

import { environment } from '../../../../environments/environment';

@Component({
    selector: 'slm-app-support-platforms',
    templateUrl: './support-platforms.component.html',
    styleUrls: ['./support-platforms.component.scss'],
})
export class SupportPlatformsComponent implements OnChanges {
    facebookPageId = '';
    facebookLink = '';
    armatisLink = '';
    facebookAdminUrl = '';
    gmbPage: Page;

    @Input() location: Location;

    constructor(
        public pagesService: PagesService,
        public logger: NGXLogger,
    ) {}

    ngOnChanges(inputs): void {
        const currentLocaction = inputs.location.currentValue;
        this.resetLinks();
        if (currentLocaction && currentLocaction.id) {
            this.initFacebookLink();
            this.initArmatisLink();
            this.initFacebookDeepLink();
            this.gmbPage = this.findPage('gmb');
        }
    }

    navigateToGMBAdmin(): void {
        if (this.gmbPage) {
            window.open(this.gmbPage.attributes.admin_url, '_blank');
        }
    }

    initFacebookDeepLink(): void {
        this.pagesService.facebookDeepLink(this.location.id).subscribe(
            res => {
                const facebookPage = res?.data?.pages.filter(item => item.publisher_id === 'facebook');
                if (facebookPage.length) {
                    this.facebookAdminUrl = facebookPage?.[0]?.attributes?.admin_url;
                }
            },
            err => {
                this.logger.info('<SupportPlatformsComponent> facebookDeeblink err: ', err);
            },
        );
    }

    navigateToFacebookDeeplink(): void {
        if (this.facebookAdminUrl) {
            window.open(this.facebookAdminUrl, '_blank');
        }
    }

    initFacebookLink(): void {
        const facebookPage = this.findPage('facebook');
        this.facebookPageId = facebookPage ? facebookPage.publisher_page_id : '';
        this.facebookLink = `https://www.facebook.com/${this.facebookPageId}/settings/?tab=admin_roles`;
    }

    initArmatisLink(): void {
        this.armatisLink = `${environment.armatisUrl}?location=${this.location.id}`;
    }

    findPage(id: string): Page {
        return this.location.pages.find((page: Page) => page.publisher_id === id);
    }

    resetLinks(): void {
        this.facebookPageId = '';
        this.facebookLink = '';
        this.armatisLink = '';
    }
}
