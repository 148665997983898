<slm-header></slm-header>
<div class="container-fluid">
    <div class="row">
        <div class="col-md-2">
            {{ 'search results' | translate }}
        </div>
        <div class="col-md-10">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
