import { Injectable, Injector } from '@angular/core';
import { Params } from '@angular/router';

import { INavigationLogic } from '@solocal-manager/sirius/core/models/navigation/interfaces';
import { NavLogicRouteIds } from '@solocal-manager/sirius/core/models/navigation/nav-logic-route-id.enum';
import { NavigationDescriptor } from '@solocal-manager/sirius/core/models/navigation/navigation-descriptor';

@Injectable()
class PhotosVideosNavLogic implements INavigationLogic {
    getEffectiveRoute(routeId: string, params?: Params): NavigationDescriptor {
        if (routeId !== NavLogicRouteIds.PHOTOS_VIDEOS_INFO) {
            throw new Error(`PhotosVideosNavLogic is not the right nav logic for id '${routeId}'`);
        }
        const queryParams: Params = { epj: params?.epj };
        const route = '/content/photos';

        return {
            route,
            queryParams,
        };
    }
}

export function photosVidosInfoNavLogicFact(injector: Injector): INavigationLogic {
    const createInjector = Injector.create({
        providers: [
            {
                provide: PhotosVideosNavLogic,
            },
        ],
        parent: injector,
    });
    return createInjector.get(PhotosVideosNavLogic);
}
