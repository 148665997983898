<div class="kpi-not-successful-provisionings">
    <div class="sub-title">
        {{ 'Not successful provisionings' | translate }}
    </div>
    <div class="title">{{ 'Not successful provisionings' | translate }}</div>

    <div *ngIf="(provIssues$ | async) || {}; let provIssues" class="provisioning-issues-table">
        <accordion>
            <table>
                <thead>
                    <tr class="error-types-header">
                        <th class="week" scope="col"></th>
                        <th
                            *ngFor="let entry of provIssues?.meta?.error_type_map | keyValue"
                            class="error-type"
                            translate
                            scope="col"
                        >
                            {{ entry.value }}
                        </th>
                        <th class="total" scope="col">
                            {{ 'Total' | translate }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td [colSpan]="(provIssues?.meta?.error_type_map | keyValue)?.length + 2">
                            <accordion-group
                                *ngFor="let year of provIssues?.data; let first = first"
                                [isOpen]="first"
                                #group
                            >
                                <div class="year-heading" accordion-heading>
                                    <div>
                                        <span>{{ year.year }}</span>
                                        <i
                                            class="glyphicon"
                                            [class.glyphicon-menu-down]="group.isOpen"
                                            [class.glyphicon-menu-up]="!group.isOpen"
                                        >
                                        </i>
                                    </div>
                                </div>
                                <table class="year-table">
                                    <tbody class="week-rows">
                                        <tr
                                            *ngFor="let week of year.weeks"
                                            [hidden]="isFuture(year, week)"
                                            class="week-row"
                                        >
                                            <td class="week-title">
                                                <span>{{ 'S.' + week.week }}</span>
                                            </td>
                                            <td
                                                *ngFor="let entry of provIssues?.meta?.error_type_map | keyValue"
                                                class="error-value"
                                            >
                                                <span [class.fake-detals-link]="!week.metrics[entry.key]">
                                                    <a
                                                        [routerLink]="
                                                            getDetailsLink(
                                                                entry.key,
                                                                year.year,
                                                                week.week,
                                                                week.metrics[entry.key]
                                                            )
                                                        "
                                                    >
                                                        {{ week.metrics[entry.key] || '—' }}
                                                    </a>
                                                </span>
                                            </td>
                                            <td class="week-total">
                                                {{ week.total || '—' }}
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tr class="total-row">
                                        <td [colSpan]="(provIssues?.meta?.error_type_map | keyValue)?.length + 1">
                                            <span>{{ 'Overall' | translate }}</span>
                                        </td>
                                        <td class="year-total">
                                            {{ year.total || '—' }}
                                        </td>
                                    </tr>
                                </table>
                            </accordion-group>
                        </td>
                    </tr>
                </tbody>
            </table>
        </accordion>
    </div>
</div>
