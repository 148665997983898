import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';

import { WpmInputRepeatable } from '../../class';
import { ValidatePrice, ValidateRepeatableLength } from '../../validators';

@Component({
    selector: 'wpm-input-description-price',
    template: '',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => WpmInputDescriptionPriceComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => WpmInputDescriptionPriceComponent),
            multi: true,
        },
    ],
})
export class WpmInputDescriptionPriceComponent extends WpmInputRepeatable implements OnInit {
    @Input() field: string;
    @Input() config: any;
    @Output() onChanges: EventEmitter<{
        type: string;
        value: any[];
    }> = new EventEmitter();

    priceValidators = [Validators.pattern(/^(\d+)(\.|,)?(\d{1,2})?$/)];
    _initData = { description: '', price: 0 };

    constructor(protected formBuilder: UntypedFormBuilder) {
        super(formBuilder);
    }

    ngOnInit() {
        super.ngOnInit();

        this.formArrayRepeatable.setValidators(
            ValidateRepeatableLength.createValidator(this.repeatableMaxLength, this.repeatableType),
        );
    }

    getConfig(inputType: string, index: number) {
        return {
            ...this.config,
            label: this.getLabel(inputType, index),
            placeholder: this.getPlaceholder(inputType),
        };
    }

    isInvalidPrice(control: UntypedFormControl): boolean {
        return control.dirty && !/^(\d+)(\.|,)?(\d{1,2})?$/.test(control.value);
    }

    get priceValidator() {
        return ValidatePrice.createValidator();
    }

    emitChanges(changed?: number) {
        if (changed >= 0) {
            const price = this.formData.controls[changed].get('price');
            price.setValue(+price.value);
        }
        const value = this.formData.value.filter(val => !!val.description || !!val.price);
        this.onChanges.emit({ type: 'list', value });
    }

    private getLabel(inputType: string, index: number): string {
        if (index === 0) {
            return inputType === 'price' ? 'Price' : `Write a ${this.field}`;
        }
        return null;
    }

    private getPlaceholder(inputType: string): string {
        return inputType === 'price' ? '-' : `${this.config.type}_meal_placeholder`;
    }
}
