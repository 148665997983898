import { Injectable } from '@angular/core';

import { PjSpecificConfig } from '@solocal-manager/shared/util-common';

const defaultConfig = {
    type: 'text',
    required: false,
    object_type: 'list',
};

@Injectable({ providedIn: 'root' })
export class BackofficePjSpecifcConfig implements PjSpecificConfig {
    value = [
        {
            key: 'services',
            label: 'Services',
            helpText: 'pj_specific_help_services',
            max: 100,
            ...defaultConfig,
        },
        {
            key: 'brands',
            label: 'Brands',
            helpText: 'pj_specific_help_brands',
            max: 30,
            ...defaultConfig,
        },
        {
            key: 'associations',
            label: 'Associations',
            helpText: 'pj_specific_help_associations',
            max: 50,
            ...defaultConfig,
        },
        {
            key: 'customers',
            label: 'Customers',
            helpText: 'pj_specific_help_customers',
            max: 50,
            ...defaultConfig,
        },
        {
            key: 'products',
            label: 'Products',
            helpText: 'pj_specific_help_products',
            max: 100,
            ...defaultConfig,
        },
        {
            key: 'certifications',
            label: 'Certifications',
            helpText: 'pj_specific_help_certifications',
            max: 150,
            ...defaultConfig,
        },
    ];
}
