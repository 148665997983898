import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { ICiamContactV2 } from '@solocal-manager/sirius/core/models';

export enum CiamContactsActionTypes {
    LOAD_CIAM_CONTACT = '[CIAM_CONTACT] get contact',
    LOAD_CIAM_CONTACT_FAIL = '[CIAM_CONTACT] get contact failed',
    LOAD_CIAM_CONTACT_SUCCESS = '[CIAM_CONTACT] get contact succeeded',
    MUTATE_CIAM_CONTACT = '[CIAM_CONTACT] update contact',
}

export const loadCiamContact = createAction(CiamContactsActionTypes.LOAD_CIAM_CONTACT, props<{ userId: string }>());

export const loadCiamContactSuccess = createAction(
    CiamContactsActionTypes.LOAD_CIAM_CONTACT_SUCCESS,
    props<{ payload: ICiamContactV2 }>(),
);

export const loadCiamContactFail = createAction(
    CiamContactsActionTypes.LOAD_CIAM_CONTACT_FAIL,
    props<{ error: HttpErrorResponse }>(),
);

export const mutateCiamContact = createAction(
    CiamContactsActionTypes.MUTATE_CIAM_CONTACT,
    props<{
        payload: ICiamContactV2;
        userId: string;
        epj: string;
        isNewContact: boolean;
    }>(),
);
