import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';

import { getPublisherStatus } from '@solocal-manager/sirius/core/core';
import { DeleteDuplicate, PublisherConnectService, PublisherSearchService } from '@solocal-manager/sirius/core/core';
import { PageMatches } from '@solocal-manager/sirius/core/models';
import { Location } from '@solocal-manager/sirius/support/base-models';

import { GmbProcessService } from '../../services';
import { IGmbProcessComponent } from '../publisher-connect-gmb-process/gmb-process-component.interface';

@Component({
    selector: 'slm-publisher-connect-gmb-search',
    templateUrl: './publisher-connect-gmb-search.component.html',
    styleUrls: ['./publisher-connect-gmb-search.component.scss'],
})
export class PublisherConnectGmbSearchComponent implements IGmbProcessComponent, OnInit, OnDestroy {
    subscribtions: Subscription[] = [];
    results: PageMatches[];
    searchDetailsForm: UntypedFormGroup;
    enabledClaims: any = {};
    searching: boolean;
    deleteDuplicateDispatched = false;
    @Input() location: Location;
    @Input()
    requestRights: boolean;

    constructor(
        private store: Store<any>,
        private publisherSearchService: PublisherSearchService,
        private formBuilder: UntypedFormBuilder,
        public logger: NGXLogger,
        private publisherConnectService: PublisherConnectService,
        public gmbProcessService: GmbProcessService,
    ) {
        this.searchDetailsForm = this.formBuilder.group({
            name: ['', [Validators.required]],
            city: [''],
        });
    }

    ngOnInit() {
        if (this.location) {
            this.searchDetailsForm.get('name').setValue(this.location.name);
            this.searchDetailsForm.get('city').setValue(this.location.address.city);
            this.search();
        }

        if (this.requestRights) {
            this.searchDetailsForm.get('city').setValidators([Validators.required]);
        } else {
            this.searchDetailsForm.get('city').clearValidators();
        }

        const publisherStatusSubscription = this.store.pipe(select('publisherStatus')).subscribe(status => {
            if (!!status && this.deleteDuplicateDispatched) {
                this.search();
                this.deleteDuplicateDispatched = false;
            }
        });

        this.subscribtions.push(publisherStatusSubscription);
    }

    search() {
        this.searching = true;
        const searchSubscibtion = this.publisherSearchService
            .searchAtPublisher(this.location.id, 'gmb', {
                name: this.searchDetailsForm.get('name').value,
                city: this.searchDetailsForm.get('city').value,
                search_gmb_account: !this.requestRights,
            })
            .subscribe((res: { data: PageMatches[] }) => {
                if (res) {
                    this.results = res.data;
                }
                this.searching = false;
            });

        this.subscribtions.push(searchSubscibtion);
    }

    connect(pageId: string) {
        const connectionSubscribtion = this.publisherConnectService.connect(this.location.id, 'gmb', pageId).subscribe(
            res => {
                if (res) {
                    this.store.dispatch(getPublisherStatus({ params: { location: this.location } }));
                    this.gmbProcessService.registerClose(true);
                }
            },
            err => {
                this.logger.debug('<PublisherConnectGmbNewComponent> connect error:', err);
            },
        );

        this.subscribtions.push(connectionSubscribtion);
    }

    openRequestRightsPage(result: PageMatches): void {
        this.enableClaim(result);
        window.open(result.request_admin_rights_url, '_blank');
    }

    enableClaim(result: PageMatches) {
        this.enabledClaims[result.publisher_page_id] = true;
    }

    isClaimEnabled(result: PageMatches) {
        return this.enabledClaims[result.publisher_page_id];
    }

    getAddress(result: PageMatches, line?: number): string {
        const { address_line1, city, zip_code, country } = result.address;
        switch (line) {
            case 1:
                return address_line1;
            case 2:
                return [zip_code, city, country].filter(el => !!el).join(' ');
            default:
                return [address_line1, city, zip_code, country].filter(el => !!el).join(', ');
        }
    }

    deleteDuplicate(publisherPageId: string) {
        this.store.dispatch(
            new DeleteDuplicate({
                publisherId: 'gmb',
                locationId: this.location.id,
                publisherPageId,
            }),
        );
        this.deleteDuplicateDispatched = true;
    }

    ngOnDestroy() {
        this.subscribtions.forEach(sub => sub.unsubscribe());
    }
}
