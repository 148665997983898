import { EventEmitter, Injectable } from '@angular/core';

import { SlmAuthFacade } from '@solocal-manager/auth';
import { IWpmHttpError, IWpmHttpResponse } from '@solocal-manager/sirius/core/models';

@Injectable({ providedIn: 'root' })
export class MessagesService {
    messageSuccess: EventEmitter<IWpmHttpResponse> = new EventEmitter<IWpmHttpResponse>();
    messageError: EventEmitter<IWpmHttpError> = new EventEmitter<IWpmHttpError>();

    constructor(private readonly slmAuthFacade: SlmAuthFacade) {}

    async sendError(err: IWpmHttpError): Promise<void> {
        if (this.slmAuthFacade.hasToken()) {
            this.messageError.emit(err);
        }
    }

    async sendSuccess(res: IWpmHttpResponse): Promise<void> {
        if (this.slmAuthFacade.hasToken()) {
            this.messageSuccess.emit(res);
        }
    }
}
