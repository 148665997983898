<div class="row">
    <div class="col-md-12">
        <label for="bm" translate> Business Manager in which the Facebook page is currently located </label>
        <select name="bm" class="bm-select-dropdown" [(ngModel)]="businessManagerId">
            <option selected value="">
                {{ 'Please select' | translate }}
            </option>
            <option
                *ngFor="let businessManager of businessManagerList$ | async"
                [value]="businessManager.id"
                class="bm-select-dropdown-option"
            >
                {{ businessManager.name }}
            </option>
        </select>
    </div>
</div>

<app-input-search-publisher
    [location]="location"
    [publisherId]="'facebook'"
    [businessManagerId]="businessManagerId"
    (onClaimed)="claimed = $event"
>
</app-input-search-publisher>

<div class="row col-md-12 text-center">
    <button class="btn btn-primary" [disabled]="!businessManagerId || claimed" (click)="connect()">
        {{ 'Create new page' | translate }}
    </button>
</div>
