import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'formatDuration',
})
export class FormatDurationPipe implements PipeTransform {
    transform(minutes: number): string {
        if (!minutes) {
            return '';
        }

        if (+minutes > 60) {
            return moment.utc().startOf('day').add(minutes, 'minutes').format('HH[h]mm[min]');
        }

        return `${minutes}min`;
    }
}
