<div *ngIf="(provIssueDetails$ | async) || {}; let provIssueDetails" class="kpi-provisioning-issue-details">
    <div class="sub-title">
        <a [routerLink]="[basePath, 'not-successful-provisionings']">
            {{ 'Not successful provisionings' | translate }}
        </a>
    </div>
    <div class="title">{{ errorName$ | async | translate }}</div>
    <div class="results">
        {{ provIssueDetails?.data?.length }}
        {{ 'results found' | translate }}
    </div>

    <div class="details-table">
        <table>
            <tr class="fields-header">
                <td class="first-col">
                    <div (click)="orderBy('product_id')" class="header-title">
                        <span>{{ 'Product ID' | translate }}</span>
                        <i *ngIf="sortConfig.descProductId" class="glyphicon glyphicon-menu-down"></i>
                        <i *ngIf="!sortConfig.descProductId" class="glyphicon glyphicon-menu-up"></i>
                    </div>
                </td>
                <td>
                    <div (click)="orderBy('company_epj')" class="header-title">
                        <span>{{ 'Company EPJ' | translate }}</span>
                        <i *ngIf="sortConfig.descCompanyEpj" class="glyphicon glyphicon-menu-down"></i>
                        <i *ngIf="!sortConfig.descCompanyEpj" class="glyphicon glyphicon-menu-up"></i>
                    </div>
                </td>
                <td>
                    <div (click)="orderBy('pos_epj')" class="header-title">
                        <span>{{ 'Location EPJ' | translate }}</span>
                        <i *ngIf="sortConfig.descLocationEpj" class="glyphicon glyphicon-menu-down"></i>
                        <i *ngIf="!sortConfig.descLocationEpj" class="glyphicon glyphicon-menu-up"></i>
                    </div>
                </td>
            </tr>
            <tbody class="product-rows">
                <tr
                    *ngFor="
                        let product of provIssueDetails?.data
                            | sorter
                                : {
                                      property: sortConfig.field,
                                      desc: sortConfig.desc
                                  }
                    "
                    class="product-row"
                >
                    <td class="first-col product-id">
                        {{ product.product_id }}
                    </td>
                    <td class="epj">{{ product.company_epj }}</td>
                    <td class="epj">{{ product.pos_epj }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
