import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { ApiErrorActions } from '@slm/shared/error';
import {
    createPage,
    createPageSuccess,
    deletePage,
    deletePageSuccess,
    disconnectPage,
    disconnectPageSuccess,
    getAvailablePublishers,
    getAvailablePublishersSuccess,
    getPages,
    getPagesSuccess,
    getPublisherStatus,
    getPublisherStatusSuccess,
    updatePage,
    updatePageSuccess,
    updatePublisherSettings,
    updatePublisherSettingsSuccess,
} from '@solocal-manager/sirius/core/actions';
import { PublisherService } from '@solocal-manager/sirius/core/services';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PublisherEffects {
    constructor(
        private readonly update$: Actions,
        private readonly publisherService: PublisherService,
    ) {}

    availablePublishers$ = createEffect(() =>
        this.update$.pipe(
            ofType(getAvailablePublishers),
            switchMap(() =>
                this.publisherService.getPublishers().pipe(
                    map(result => getAvailablePublishersSuccess({ result })),
                    catchError(error => of(new ApiErrorActions.CatchApiError(error))),
                ),
            ),
        ),
    );

    getPublisherStatus$ = createEffect(() =>
        this.update$.pipe(
            ofType(getPublisherStatus),
            map(action => action.params),
            switchMap(params =>
                this.publisherService.getPublisherStatus(params.locationId || params.location.id).pipe(
                    map(result => getPublisherStatusSuccess({ result })),
                    catchError(error => of(new ApiErrorActions.CatchApiError(error))),
                ),
            ),
        ),
    );

    updatePublisherSettings$ = createEffect(() =>
        this.update$.pipe(
            ofType(updatePublisherSettings),
            map(action => action.params),
            switchMap(params =>
                this.publisherService.updatePublisherSettings(params.locationId, params.publisherId, params.state).pipe(
                    tap(result => {
                        this.publisherService.emitPublisherModified(result.publisher_id);
                    }),
                    map(result => updatePublisherSettingsSuccess({ result })),
                    catchError(error => of(new ApiErrorActions.CatchApiError(error))),
                ),
            ),
        ),
    );

    updatePage$ = createEffect(() =>
        this.update$.pipe(
            ofType(updatePage),
            map(action => action.params),
            switchMap(params =>
                this.publisherService.patchPage(params.pageId).pipe(
                    tap(result => {
                        this.publisherService.emitPublisherModified(result.publisher_id);
                    }),
                    map(result => updatePageSuccess({ result })),
                    catchError(error => of(new ApiErrorActions.CatchApiError(error))),
                ),
            ),
        ),
    );

    createPage$ = createEffect(() =>
        this.update$.pipe(
            ofType(createPage),
            map(action => action.params),
            switchMap(params =>
                this.publisherService.createPage(params.locationId, params.publisherId).pipe(
                    tap(result => {
                        this.publisherService.emitPublisherModified(result.publisher_id);
                    }),
                    map(result => createPageSuccess({ result })),
                    catchError(error => of(new ApiErrorActions.CatchApiError(error))),
                ),
            ),
        ),
    );

    deletePage$ = createEffect(() =>
        this.update$.pipe(
            ofType(deletePage),
            map(action => action.params),
            switchMap(params =>
                this.publisherService.deletePage(params.pageId).pipe(
                    tap(() => {
                        if (params.publisherId) {
                            this.publisherService.emitPublisherModified(params.publisherId);
                        }
                    }),
                    map(
                        () => deletePageSuccess(),
                        catchError(error => of(new ApiErrorActions.CatchApiError(error))),
                    ),
                ),
            ),
        ),
    );

    disconnectPage$ = createEffect(() =>
        this.update$.pipe(
            ofType(disconnectPage),
            map(action => action.params),
            switchMap(params =>
                this.publisherService.disconnectPage(params.pageId).pipe(
                    tap(result => {
                        this.publisherService.emitPublisherModified(result.publisher_id);
                    }),
                    map(
                        result => disconnectPageSuccess({ result }),
                        catchError(error => of(new ApiErrorActions.CatchApiError(error))),
                    ),
                ),
            ),
        ),
    );

    listPages$ = createEffect(() =>
        this.update$.pipe(
            ofType(getPages),
            map(action => action.params),
            switchMap(params =>
                this.publisherService.listPages(params.locationId).pipe(
                    map(result => getPagesSuccess({ result })),
                    catchError(error => of(new ApiErrorActions.CatchApiError(error))),
                ),
            ),
        ),
    );
}
