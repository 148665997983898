import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as UserActions from '@slm/user/state';
import { selectUser, User } from '@slm/user/state';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'slm-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
    user: User;
    userPermissions = {};
    applicationId: string;
    @Output() menuClick: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() menuActive = false;
    private isDestroyed$ = new Subject<void>();

    constructor(
        public router: Router,
        public store: Store<any>,
        public translate: TranslateService,
    ) {}

    menuToggle() {
        this.menuActive = !this.menuActive;
        this.menuClick.emit(this.menuActive);
    }

    ngOnInit() {
        this.store
            .select(selectUser)
            .pipe(takeUntil(this.isDestroyed$))
            .subscribe((user: User) => {
                this.user = user;
                this.applicationId = user?.application?.id;
                this.userPermissions = this.mapUserPermissions(user);
            });
    }

    logout() {
        this.store.dispatch(UserActions.logout());
    }

    goHome() {
        this.router.navigate(['app', this.applicationId, 'search']);
    }

    switchLanguage(lang?: string) {
        lang = lang || 'fr';
        this.translate.use(lang);
    }

    ngOnDestroy() {
        this.isDestroyed$.next(undefined);
        this.isDestroyed$.complete();
    }

    private mapUserPermissions(user: User) {
        const permissions = {
            manageEpj: 'manage-epj-search-support_user',
            manageCviv: 'manage-cviv-support_user',
        };
        const userPermissions = {};

        if (user?.user_permissions) {
            Object.keys(permissions).forEach(key => {
                userPermissions[key] = user.user_permissions.some(perm => perm.codename === permissions[key]);
            });
        }

        return userPermissions;
    }
}
