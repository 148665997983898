import { createAction, props } from '@ngrx/store';

import { MenuCategory } from '@solocal-manager/sirius/support/base-models';

export const getMenuCardsCategories = createAction('[MENU_CARDS] get related categories');

export const getMenuCardsCategoriesSuccess = createAction(
    '[MENU_CARDS] get related categories success',
    props<{ result: MenuCategory[] }>(),
);
