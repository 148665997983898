import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EnvironmentService } from '@slm/shared/environment';
import { SlmAuthFacade } from '@solocal-manager/auth';
import { AuthConfig } from '@solocal-manager/shared/util-common';
import { BaseImageService } from '@solocal-manager/sirius/core/services/base-image.service';
import { Photo } from '@solocal-manager/sirius/support/base-models';
import { get } from 'lodash-es';
import { from, lastValueFrom, Observable, Observer, switchMap } from 'rxjs';
import { map, share } from 'rxjs/operators';

interface IUploadProgress {
    file?: File;
    value?: number;
}

interface IPhotoTransferObject {
    id?: string;
    type?: string;
    title?: string;
    description?: string;
}

export interface IFilterFunction {
    name: string;
    fn: (file?: File, img?: any) => boolean;
    onPass?: (file?: File, img?: any) => void;
    onBlock?: (file?: File, img?: any) => void;
}

@Injectable({ providedIn: 'root' })
export class ImageService extends BaseImageService {
    maxFileSize: number;
    progress$: Observable<IUploadProgress>;
    progressObserver: any;

    constructor(
        public store: Store<any>,
        public http: HttpClient,
        public authConfig: AuthConfig,
        public envService: EnvironmentService,
        private readonly slmAuthFacade: SlmAuthFacade,
    ) {
        super();
        this.progress$ = new Observable(observer => {
            this.progressObserver = observer;
        }).pipe(share());
    }

    public uploadCreatedPicture(locationId: string, mediaType: string, base64Data: string): Observable<Photo> {
        const urlService = `${this.envService.apiUrl}/consumer/companies/all/photos`;

        const data = {
            base64_data: base64Data,
            type: mediaType,
            locations: [{ id: locationId, publish_to_location: false }],
        };
        return this.http.post<{ data: Photo }>(urlService, data).pipe(map(res => get(res, 'data')));
    }

    /*
        'gallery': '/companies/all/photos' endpoint with no locations specified
        'content': '/companies/all/photos' endpoint with specified location and 'publish_to_location': true
        'post': '/companies/all/photos-bulk' endpoint with specified location and 'publish_to_location': false
    */
    upload(
        companyId: string,
        mediaType: string,
        mediaName: string,
        file: File,
        imageBase64: string,
        locationIds?: string[],
        publishToLocation?: boolean,
    ): Observable<any> {
        const formData = new FormData();
        formData.append('type', mediaType);
        formData.append('company_id', companyId);
        formData.append(mediaName, file, file.name);
        formData.append('base64_filename', file.name);
        formData.append('base64_data', imageBase64);
        if (!!locationIds && locationIds.every(id => !!id)) {
            formData.append(
                'locations',
                JSON.stringify(
                    locationIds.map(id => {
                        return { id, publish_to_location: !!publishToLocation };
                    }),
                ),
            );
        }

        const url = `${this.envService.apiUrl}/consumer/companies/${companyId}/photos`;

        return from(this.slmAuthFacade.getToken()).pipe(
            switchMap(
                token =>
                    new Observable(observer => {
                        const xhr: XMLHttpRequest = new XMLHttpRequest();

                        xhr.onreadystatechange = () => {
                            if (xhr.readyState === 4) {
                                if (xhr.status === 201) {
                                    const result = JSON.parse(xhr.response);
                                    observer.next(result.data);
                                    observer.complete();
                                } else {
                                    observer.error(xhr.response);
                                }
                            }
                        };

                        xhr.upload.onprogress = event => {
                            const progress = {
                                file,
                                value: Math.round((event.loaded / event.total) * 100),
                            };
                            this.progressObserver.next(progress);
                        };

                        xhr.open('POST', url, true);
                        xhr.setRequestHeader('Authorization', `Bearer ${token}`);
                        xhr.setRequestHeader('Solocal-Frontend-Type', this.authConfig.frontendType);
                        xhr.send(formData);
                    }),
            ),
        );
    }

    removeImage(imageId: string) {
        const url = `${this.envService.apiUrl}/consumer/photos/${imageId}`;
        return this.http.delete(url);
    }

    updateImage(image: IPhotoTransferObject): Observable<any> {
        const url = `${this.envService.apiUrl}/consumer/photos/${image.id}`;
        const updatedImage: IPhotoTransferObject = {};
        if (image.title !== undefined) {
            updatedImage.title = image.title;
        }
        if (image.description !== undefined) {
            updatedImage.description = image.description;
        }
        if (image.type !== undefined) {
            updatedImage.type = image.type;
        }

        return this.http.patch<{ data: Photo }>(url, updatedImage).pipe(map(response => response.data));
    }

    updatePicture(image: Photo, acceptUGC: boolean) {
        const url = `${this.envService.apiUrl}/consumer/photos/${image.id}/moderate`;
        return lastValueFrom(this.http.put(url, { accept: acceptUGC }));
    }

    mergePhotosToPortraitView(
        canvas: HTMLCanvasElement,
        ctx: CanvasRenderingContext2D,
        firstImgDims: any,
        secondImgDims: any,
        image1: HTMLImageElement,
        image2: HTMLImageElement,
    ) {
        return new Observable((observer: Observer<string>) => {
            const ratio = secondImgDims.width / firstImgDims.width;

            const newWidth1 = ratio > 1 ? firstImgDims.width : secondImgDims.width;

            const newHeight1 = ratio > 1 ? firstImgDims.height : firstImgDims.height * ratio;

            const newWidth2 = ratio < 1 ? secondImgDims.width : firstImgDims.width;

            const newHeight2 = ratio < 1 ? secondImgDims.height : secondImgDims.height / ratio;

            canvas.width = newWidth1;
            canvas.height = newHeight1 + newHeight2;

            let imagesLoaded = 0;
            const loadImage = () => {
                imagesLoaded++;

                if (imagesLoaded === 2) {
                    ctx.drawImage(image1, 0, 0, newWidth1, newHeight1);
                    ctx.drawImage(image2, 0, newHeight1, newWidth2, newHeight2);

                    const dataURL = canvas.toDataURL('image/png');

                    observer.next(dataURL);
                    observer.complete();
                }
            };

            image1.onload = loadImage;
            image2.onload = loadImage;
        });
    }

    mergePhotosToLandscapeView(
        canvas: HTMLCanvasElement,
        ctx: CanvasRenderingContext2D,
        firstImgDims: any,
        secondImgDims: any,
        image1: HTMLImageElement,
        image2: HTMLImageElement,
    ) {
        return new Observable((observer: Observer<string>) => {
            const ratio = secondImgDims.height / firstImgDims.height;

            const newWidth1 = ratio > 1 ? firstImgDims.width : firstImgDims.width * ratio;

            const newHeight1 = ratio > 1 ? firstImgDims.height : secondImgDims.height;

            const newWidth2 = ratio < 1 ? secondImgDims.width : secondImgDims.width / ratio;

            const newHeight2 = ratio < 1 ? secondImgDims.height : firstImgDims.height;

            canvas.width = newWidth1 + newWidth2;
            canvas.height = newHeight1;

            let imagesLoaded = 0;
            const loadImage = () => {
                imagesLoaded++;

                if (imagesLoaded === 2) {
                    ctx.drawImage(image1, 0, 0, newWidth1, newHeight1);
                    ctx.drawImage(image2, newWidth1, 0, newWidth2, newHeight2);

                    const dataURL = canvas.toDataURL('image/png');

                    observer.next(dataURL);
                    observer.complete();
                }
            };

            image1.onload = loadImage;
            image2.onload = loadImage;
        });
    }

    drawCanvasWithDimensions(
        canvas: HTMLCanvasElement,
        ctx: CanvasRenderingContext2D,
        dims: any,
        image1: HTMLImageElement,
        image2: HTMLImageElement,
    ): Observable<string> {
        const firstImgDims = dims[0];
        const secondImgDims = dims[1];

        if (this.isPortrait(firstImgDims) && this.isPortrait(secondImgDims)) {
            return this.mergePhotosToLandscapeView(canvas, ctx, firstImgDims, secondImgDims, image1, image2);
        }

        if (this.isLandscape(firstImgDims) && this.isLandscape(secondImgDims)) {
            return this.mergePhotosToPortraitView(canvas, ctx, firstImgDims, secondImgDims, image1, image2);
        }

        if (this.isLandscape(firstImgDims) && this.isPortrait(secondImgDims)) {
            if (firstImgDims.width / firstImgDims.height < secondImgDims.height / secondImgDims.width) {
                return this.mergePhotosToLandscapeView(canvas, ctx, firstImgDims, secondImgDims, image1, image2);
            } else {
                return this.mergePhotosToPortraitView(canvas, ctx, firstImgDims, secondImgDims, image1, image2);
            }
        }

        if (this.isPortrait(firstImgDims) && this.isLandscape(secondImgDims)) {
            if (firstImgDims.width / firstImgDims.height < secondImgDims.height / secondImgDims.width) {
                return this.mergePhotosToLandscapeView(canvas, ctx, firstImgDims, secondImgDims, image1, image2);
            } else {
                return this.mergePhotosToPortraitView(canvas, ctx, firstImgDims, secondImgDims, image1, image2);
            }
        }
    }

    getBase64ImageFromURLObservable(url: string): Observable<string> {
        return new Observable((observer: Observer<string>) => {
            const img = new Image();
            img.crossOrigin = 'Anonymous';
            img.src = url;
            if (!img.complete) {
                img.onload = () => {
                    observer.next(this.getBase64Image(img, 'image/png'));
                    observer.complete();
                };
                img.onerror = err => {
                    observer.error(err);
                };
            } else {
                observer.next(this.getBase64Image(img, 'image/png'));
                observer.complete();
            }
        });
    }

    getDimensions(url: string): any {
        throw new Error('Method not implemented.');
    }

    // unused for now, but may be helpful
    private getBase64Image(img, imageType: string) {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        const dataURL = canvas.toDataURL(imageType);

        return dataURL;
    }
}
