import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { PartnerIdToIdEntity } from './partner-id-to-id.models';

export const PARTNER_ID_TO_ID_FEATURE_KEY = 'partnerIdToId';

export interface State extends EntityState<PartnerIdToIdEntity> {
    selectedId?: string | number; // which PartnerIdToId record has been selected
    loaded: boolean; // has the PartnerIdToId list been loaded
    error?: string | null; // last known error (if any)
}

export interface PartnerIdToIdPartialState {
    readonly [PARTNER_ID_TO_ID_FEATURE_KEY]: State;
}

export const partnerIdToIdAdapter: EntityAdapter<PartnerIdToIdEntity> = createEntityAdapter<PartnerIdToIdEntity>({
    selectId: partnerIdToId => partnerIdToId.partnerId,
});

export const initialState: State = partnerIdToIdAdapter.getInitialState({
    // set initial required properties
    loaded: false,
});
