import { Pipe, PipeTransform } from '@angular/core';

import { LocationCompletionSuggestionService } from '@slm/location/service';
import { Location } from '@solocal-manager/sirius/support/base-models';

@Pipe({
    name: 'locationCompletionRate',
})
export class LocationCompletionRatePipe implements PipeTransform {
    constructor(private completionService: LocationCompletionSuggestionService) {}

    transform(location: Location): number {
        let completionRate = 0;

        if (location) {
            completionRate = this.completionService.getCompletionRateByLocation(location);
        }

        return completionRate;
    }
}
