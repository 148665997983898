import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { pairwise } from 'rxjs/operators';

import { PathService } from '@solocal-manager/sirius/core/core';

@Injectable({ providedIn: 'root' })
export class RouteGuard  {
    lastBasePath = '';

    constructor(public router: Router, public pathService: PathService) {}

    saveBasePath() {
        const calculatedPath = this.calculateBasePath(this.lastBasePath);
        this.pathService.basePath = calculatedPath;
    }

    calculateBasePath(path: string) {
        let baseBath = '';
        const basePathRegExp = /\/app\/([0-9]+)(\/company\/([0-9]+))?/;

        if (basePathRegExp.test(path)) {
            const match = path.match(basePathRegExp);
            const appId = match[1];
            const companyId = match[3];

            baseBath = companyId ? `/app/${appId}/company/${companyId}` : `/app/${appId}`;
        }

        return baseBath;
    }

    canActivate(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): boolean | Observable<boolean> | Promise<boolean> {
        this.router.events.pipe(pairwise()).subscribe((event: any[]) => {
            if (this.lastBasePath !== event[1].url) {
                this.lastBasePath = event[1].url;
                this.saveBasePath();
            }
        });

        return true;
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): boolean | Observable<boolean> | Promise<boolean> {
        this.router.events.pipe(pairwise()).subscribe((event: any[]) => {
            if (this.lastBasePath !== event[1].url) {
                this.lastBasePath = event[1].url;
                this.saveBasePath();
            }
        });

        return true;
    }
}
