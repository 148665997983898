import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ConnectModalService implements OnDestroy {
    onClose: Subject<boolean> = new Subject<boolean>();

    ngOnDestroy() {
        this.onClose.complete();
    }

    registerClose(): void {
        this.onClose.next(true);
    }
}
