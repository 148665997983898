import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';

import { Location } from '@solocal-manager/sirius/support/base-models';

import { ActionWithPayload } from '.';

@Injectable({ providedIn: 'root' })
export class LocationActions {
    static RESET_LOCATION = '[RESET_LOCATION] reset location';

    resetLocation(): Action {
        return {
            type: LocationActions.RESET_LOCATION,
        };
    }

    static GET_LOCATION = '[GET_LOCATION] get location';

    getLocation(locationId: string): ActionWithPayload<string> {
        return {
            type: LocationActions.GET_LOCATION,
            payload: locationId,
        };
    }

    static GET_LOCATION_SUCCESS = '[GET_LOCATION_SUCCESS] get location success';

    getLocationSuccess(result: Location): ActionWithPayload<Location> {
        return {
            type: LocationActions.GET_LOCATION_SUCCESS,
            payload: result,
        };
    }

    //deprecated?
    static UPDATE_LOCATION = '[UPDATE_LOCATION] update location data in store';

    updateLocation(payload): ActionWithPayload<Location> {
        return {
            type: LocationActions.UPDATE_LOCATION,
            payload: payload,
        };
    }

    //deprecated?
    static UPDATE_LOCATION_SUCCESS = '[UPDATE_LOCATION_SUCCESS] update location success';

    updateLocationSuccess(location: Location): ActionWithPayload<Location> {
        return {
            type: LocationActions.UPDATE_LOCATION_SUCCESS,
            payload: location,
        };
    }

    static SET_SELECTED_LOCATION = '[SET_SELECTED_LOCATION] set current location';

    setSelectedLocation(location: Location): ActionWithPayload<Location> {
        return {
            type: LocationActions.SET_SELECTED_LOCATION,
            payload: location,
        };
    }

    static PUT_LOCATION = '[PUT_LOCATION] put location';

    putLocation(locationData: any): ActionWithPayload<any> {
        return {
            type: LocationActions.PUT_LOCATION,
            payload: locationData,
        };
    }

    static PUT_LOCATION_SUCCESS = '[PUT_LOCATION] put location success';

    putLocationSuccess(location: Location): ActionWithPayload<Location> {
        return {
            type: LocationActions.PUT_LOCATION_SUCCESS,
            payload: location,
        };
    }

    static PATCH_LOCATION = '[PATCH_LOCATION] patch location';

    patchLocation(locationData: any): ActionWithPayload<any> {
        return {
            type: LocationActions.PATCH_LOCATION,
            payload: locationData,
        };
    }

    static PATCH_LOCATION_SUCCESS = '[PATCH_LOCATION] patch location success';

    patchLocationSuccess(location: Location): ActionWithPayload<Location> {
        return {
            type: LocationActions.PATCH_LOCATION_SUCCESS,
            payload: location,
        };
    }

    static PATCH_LOCATION_ERROR = '[PATCH_LOCATION] patch location error';

    patchLocationError(error: any): ActionWithPayload<any> {
        return {
            type: LocationActions.PATCH_LOCATION_ERROR,
            payload: error,
        };
    }
}
