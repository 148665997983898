import { Component, EventEmitter, Output } from '@angular/core';
import {
    AbstractControl,
    AsyncValidator,
    UntypedFormBuilder,
    UntypedFormControl,
    ValidationErrors,
} from '@angular/forms';
import { find } from 'lodash-es';
import { of, Observable, startWith, filter, take, map } from 'rxjs';

import { WpmInputRepeatable } from '@solocal-manager/sirius/core/class';
import { ValidateUrl } from '@solocal-manager/sirius/core/validators';

const initConfig = {
    url: ['', [ValidateUrl.createValidator()]],
    type: ['website', []],
    display_url: [''],
    description: [''],
};

@Component({
    selector: 'wpm-app-input-url',
    template: '',
})
export class WpmInputUrlComponent extends WpmInputRepeatable implements AsyncValidator {
    @Output() validationLaunched: EventEmitter<boolean> = new EventEmitter();

    keepOneInputOpen = true;
    currentValidation: any[];
    _initData = initConfig;

    constructor(protected formBuilder: UntypedFormBuilder) {
        super(formBuilder);
        const cloneInitData = { ...initConfig };
        this._initData = cloneInitData;
    }
    //there is a bug AsyncValidator with controller value accessors , this validate function propagate the form is valid or not
    //https://github.com/angular/angular/issues/38464
    //https://stackoverflow.com/questions/59313428/angular-reactive-form-custom-control-async-validation
    validate(c: AbstractControl): Observable<ValidationErrors | null> {
        if (!this.formRepeatable?.valid) {
            return this.formRepeatable.statusChanges.pipe(
                startWith(this.formRepeatable.status),
                filter(status => status !== 'PENDING'),
                take(1),
                map(status => {
                    return this.formRepeatable.valid ? null : { valid: false };
                }),
            );
        }
        return of(null);
    }
    addPrefixes(urls: Array<{ url: string }>) {
        urls.forEach((item: { url: string }, i: number) => {
            if (!item.url.startsWith('http://') && !item.url.startsWith('https://')) {
                urls[i].url = 'http://' + item.url;
            }
        });
    }

    hasUrlError(url: UntypedFormControl): boolean {
        return !!find(
            this.currentValidation,
            row =>
                !row.valid &&
                (row.url === url.value || row.url === 'http://' + url.value || row.url === 'https://' + url.value),
        );
    }

    getUrlError(url: UntypedFormControl) {
        return find(
            this.currentValidation,
            row =>
                !row.valid &&
                (row.url === url.value || row.url === 'http://' + url.value || row.url === 'https://' + url.value),
        );
    }
}
