import { forwardRef, Component, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, Subscriber } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { WpmInputServiceAreaComponent } from '@solocal-manager/sirius/core/core';

@Component({
    selector: 'app-input-service-area',
    templateUrl: './input-service-area.component.html',
    styleUrls: ['./input-service-area.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputServiceAreaComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => InputServiceAreaComponent),
            multi: true,
        },
    ],
})
export class InputServiceAreaComponent extends WpmInputServiceAreaComponent implements ControlValueAccessor, OnInit {
    typeaheadLoading: boolean;

    ngOnInit() {
        super.ngOnInit();
        this.filteredPlaces$ = new Observable((subscriber: Subscriber<string>) => {
            // Runs on every search
            subscriber.next(this.areaControl.value);
        }).pipe(switchMap((q: string) => this.getPlacesByQuery(q)));
    }

    changeTypeaheadLoading(e: boolean): void {
        this.typeaheadLoading = e;
    }
}
