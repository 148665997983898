import { GalleryActionsUnion, GalleryActionTypes } from '@solocal-manager/sirius/core/actions';
import { Photo } from '@solocal-manager/sirius/support/base-models';
import { PaginationDTO, Video } from '@solocal-manager/sirius/support/base-models';

export type ImageListState = PaginationDTO<Photo>;
export type VideoListState = PaginationDTO<Video>;

export interface InitialGalleryState {
    images: ImageListState;
    videos: VideoListState;
}

const initialImages: ImageListState = {
    next: null,
    next_page: null,
    previous: null,
    previous_page: null,
    count: 0,
    data: [],
    initial: true,
};

const initialVideos: VideoListState = {
    next: null,
    next_page: null,
    previous: null,
    previous_page: null,
    count: 0,
    data: [],
    initial: true,
};

const initialState = {
    images: initialImages,
    videos: initialVideos,
};

function updateImageList(state, payload, initial) {
    return {
        ...state,
        images: { ...payload, initial },
    };
}

function updateVideoList(state, result, initial) {
    return {
        ...state,
        videos: { ...result, initial },
    };
}

export function galleryReducer(state = initialState, action: GalleryActionsUnion): InitialGalleryState {
    switch (action.type) {
        case GalleryActionTypes.GET_IMAGE_LIST_SUCCESS:
            return updateImageList(state, action.payload, false);

        case GalleryActionTypes.RESET_IMAGE_LIST:
            return updateImageList(state, initialImages, true);

        case GalleryActionTypes.GET_UPLOADED_VIDEO_LIST_SUCCESS:
            return updateVideoList(state, action.result, false);

        case GalleryActionTypes.RESET_UPLOADED_VIDEO_LIST:
            return updateVideoList(state, initialVideos, true);
        default:
            return state;
    }
}
