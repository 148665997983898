import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EnvironmentService } from '@slm/shared/environment';
import { Location } from '@solocal-manager/sirius/support/base-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IActivateCatalogApiResponse } from '../../models/activate-catalog-api-response';

@Injectable({ providedIn: 'root' })
export class WpmLocationService {
    constructor(
        public http: HttpClient,
        public translate: TranslateService,
        public envService: EnvironmentService,
        public store: Store<any>,
    ) {}

    getLocation(locationId: string): Observable<Location> {
        const currentLang = this.translate.currentLang;
        // TODO Hack YCP-1099: fe_version=2021-03-03
        const requestUrl = `${this.envService.apiUrl}/consumer/locations/${locationId}?fields=custom_data&language=${currentLang}&fe_version=2021-03-03`;

        return this.http.get(requestUrl).pipe(map((result: any) => result.data));
    }

    updateLocation(location: Location): Observable<Location> {
        const currentLang = this.translate.currentLang;
        const requestUrl = `${this.envService.apiUrl}/consumer/locations/${location.id}?fields=custom_data&language=${currentLang}`;

        return this.http.put(requestUrl, location).pipe(map((result: any) => (result.data ? result.data : {})));
    }

    patchLocationSiteCatalogSettings(
        locationId: string,
        email: string,
        status: boolean,
    ): Observable<Partial<IActivateCatalogApiResponse>> {
        const requestUrl = `${this.envService.apiUrl}/consumer/locations/${locationId}/sitecatalog-settings`;
        const requestBody = {
            activate: status,
            email: email,
        };
        return this.http.patch(requestUrl, requestBody);
    }

    patchLocation(locationId: number, locationPartial: any): Observable<Location> {
        const currentLang = this.translate.currentLang;
        // TODO Hack YCP-1099: fe_version=2021-03-03
        const requestUrl = `${this.envService.apiUrl}/consumer/locations/${locationId}?fields=custom_data&language=${currentLang}&fe_version=2021-03-03`;

        return this.http
            .patch(requestUrl, locationPartial)
            .pipe(map((result: any) => (result.data ? result.data : {})));
    }

    patchLocationsBulk(locationIds: string[], data: any): Observable<{ success: boolean }> {
        const requestData = locationIds.map(id => ({ id, ...data }));
        const requestUrl = `${this.envService.apiUrl}/consumer/companies/all/locations-bulk`;
        return this.http
            .patch<{ data: { success: boolean } }>(requestUrl, requestData)
            .pipe(map((result: { data: { success: boolean } }) => result.data));
    }
}
