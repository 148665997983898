import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { LocationsState } from '@slm/location/state';
import { ICustomMenuItemFilter } from '@solocal-manager/sirius/core/models/navigation/interfaces';
import { selectLocationEntities } from '@solocal-manager/sirius/core/reducers/location-entities.reducer';
import { Location } from '@solocal-manager/sirius/support/base-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
class OnboardingJourneyItemFilter implements ICustomMenuItemFilter {
    show$: Observable<boolean>;

    constructor(private store: Store<{ locations: LocationsState }>) {
        this.show$ = store.select(selectLocationEntities).pipe(
            map(locationsDeco => {
                if (locationsDeco == null) {
                    return false;
                }
                if (locationsDeco.length > 1) {
                    return this.handleMultiLocation(locationsDeco);
                }

                return this.handleSingleLocation(locationsDeco[0]);
            }),
        );
    }

    protected handleMultiLocation(locations: Location[]): boolean {
        return locations.some(location => this.handleSingleLocation(location));
    }

    protected handleSingleLocation(location: Location): boolean {
        return location?.custom_data?.onboarding_steps.status === 'pending';
    }
}

export function onboardingJourneyCustomItemFilterFact(injector: Injector): ICustomMenuItemFilter {
    const creationInjector = Injector.create({
        providers: [
            {
                provide: OnboardingJourneyItemFilter,
                useFactory: (store: Store<{ locations: LocationsState }>) => new OnboardingJourneyItemFilter(store),
                deps: [Store],
            },
        ],
        parent: injector,
    });

    return creationInjector.get(OnboardingJourneyItemFilter);
}
