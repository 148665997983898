<div class="kpi-widget-container">
    <div class="grid">
        <app-kpi-widget
            kpiType="totalError"
            [title]="'Total error' | translate"
            [value]="totalError$ | async"
            [percentage]="-13.8"
        >
        </app-kpi-widget>
        <app-kpi-widget
            kpiType="notSuccessfulProvisionings"
            [title]="'Not successful provisionings' | translate"
            [value]="notSuccessfulProvisionings$ | async"
            [percentage]="6.3"
        >
        </app-kpi-widget>
        <app-kpi-widget
            kpiType="totalSales"
            [title]="'Total sales' | translate"
            [value]="totalSales$ | async"
            [percentage]="13.8"
        >
        </app-kpi-widget>
        <app-kpi-widget
            kpiType="totalUses"
            [title]="'Total uses' | translate"
            [value]="totalUses$ | async"
            [percentage]="-13.8"
        >
        </app-kpi-widget>
    </div>
</div>
