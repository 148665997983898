<div *ngIf="canGoBack">
    <button type="button"
            class="btn"
            (click)="resolvePreviousStep()">
        <i class="glyphicon glyphicon-circle-arrow-left"></i>
        {{'go back' | translate}}
    </button>
</div>

<div class="row"
     [ngClass]="{'btns-row': availableSteps.length}">
    <div *ngFor="let step of availableSteps"
         [ngClass]="btnRowClass">
        <button type="button"
                class="btn btn-primary"
                (click)="resolveStep(step)">{{step.btnText | translate}}</button>
    </div>
</div>

<div #processContainer></div>