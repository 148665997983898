import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { EpjSearchActions } from '@solocal-manager/sirius/core/core';

import { SearchResultsComponent } from './search-results.component';

//actions

@Component({
    selector: 'app-search-cviv-results',
    templateUrl: './search-results.component.html',
    styleUrls: ['./search-results.component.scss'],
})
export class SearchCvivResultsComponent extends SearchResultsComponent {
    public showReload = true;

    constructor(public store: Store<any>) {
        super(store, 'pj-connect');
        this.loadResults();
    }

    reloadResults() {
        this.loadResults();
    }

    loadResults() {
        this.store.dispatch({
            type: EpjSearchActions.GET_EPJ_SEARCH,
            payload: { cviv_todo: true },
        });
    }
}
