import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { LocationsSelectors } from '@slm/location/state';
import { EnvironmentService } from '@slm/shared/environment';
import { ESsoOrigins } from '@slm/user/api';
import { User } from '@slm/user/state';
import { EPacks, Location, LocationRole } from '@solocal-manager/sirius/support/base-models';
import { capitalize, compact, flatten, get } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

import { AVAILABLE_PAGES, MULTI_LOCATION, PageIdsMap as PAGE, SINGLE_LOCATION } from '../config/menu.config';
import { EMenuType } from '../models';

const getUrl = urlParts => {
    return flatten(compact(urlParts)).join('/');
};

/**
 *  define which pages should not be displayed depending on pack config
 */
const packPageConfig = {
    // [EPacks.pack0]: [PAGE['post']],
    // [EPacks.otherOffer]: [PAGE['post']]
};

/**
 * blocked pages should be disabled and display "not allowed on click"
 */
const pagesBlockedForReadonly = [PAGE['profile/account'], PAGE['profile/contact']];

/**
 * additionaly enabled pages for readonly users in our case comming from CRM
 */
const pagesEnabledForReadonly = [PAGE['meetings']];

/** config to disable specific widgets by pack */
const disabledWidgets = {
    [EPacks.pack0]: [
        // CORONAVIRUS: Allow prospect to publish posts
        // https://jira.solocal.com/browse/YCP-353
        // 'app-posts-widget',
        // 'app-publisher-connect-for-company'
    ],
    [EPacks.otherOffer]: [
        // CORONAVIRUS: Allow prospect to publish posts
        // https://jira.solocal.com/browse/YCP-353
        // 'app-posts-widget',
        // 'app-publisher-connect-for-company'
    ],
};

/** config to enable specific widgets by pack */
const enabledWidgets = {
    [EPacks.pack0]: ['app-prospect-widget', 'app-prospect-publishers'],
};

/**
 * Position in the list of packs
 * First is highest pack, last is lowert
 */
const packPositionMap = {
    [EPacks.pack3]: 0, // New offer
    [EPacks.pack2]: 1, // New offer
    [EPacks.oldOffer]: 2, // Old offer with PM (higher than pack1 because has more publishers)
    [EPacks.pack1]: 3, // New offer
    [EPacks.otherOffer]: 4, // Old offer without PM
    [EPacks.pack0]: 5, // Prospect,
    [EPacks.pprOtherOffer]: 6, // PPR other offer - Bridge Client + CVI
};
/**
 * @deprecated
 * we should use injection token instead
 */
@Injectable({ providedIn: 'root' })
export class UiService {
    onPermissionsChanged: BehaviorSubject<boolean> = new BehaviorSubject(false);
    noSnackBarEnvs = ['prod', 'demo'];
    private _origin: ESsoOrigins;
    private _originLocationId: string;
    private _regexPath = /app\/[0-9]+\/location\/[0-9]+(.*)/;
    private _regexSpecialPath = [
        // goal is to remove the non numerical id from the path
        /app\/[0-9]+\/location\/[0-9]+\/offer-presentation\/[0-9]+\/booster-contact\/(.*)/,
        /app\/[0-9]+\/location\/[0-9]+\/offer-presentation\/(.*)\/website/,
        /app\/[0-9]+\/location\/[0-9]+\/offer-presentation\/(.*)\/videos/,
        /app\/[0-9]+\/location\/[0-9]+\/profile\/contact\/case\/(.*)\/comment/,
        /app\/[0-9]+\/location\/[0-9]+\/content\/cviv\/(.*)/,
        /app\/[0-9]+\/location\/[0-9]+\/faq\/article\/(.*)/,
    ];

    constructor(
        private envService: EnvironmentService,
        private store: Store<any>,
    ) {
        // this._basePath = ['/app/2', 'company/all'];
        this._basePath = [];
        this._origin = ESsoOrigins.default;
    }

    private _availablePages = [];

    get availablePages() {
        return this._availablePages;
    }

    set availablePages(pages) {
        this._availablePages = pages;
    }

    private _user: User;

    get user() {
        return this._user;
    }

    set user(user: User) {
        this._user = user;
        this.onPermissionsChanged.next(true);
    }

    private _loginPage: string;

    get loginPage(): string {
        return this._loginPage;
    }

    set loginPage(page: string) {
        this._loginPage = page;
    }

    private _basePath: string[];

    get basePath(): string[] {
        return this._basePath;
    }

    set basePath(path: string[]) {
        this._basePath = path;
    }

    private _readonlyUser: boolean;

    get readonlyUser(): boolean {
        return this._readonlyUser;
    }

    set readonlyUser(flag: boolean) {
        this._readonlyUser = flag;
    }

    private _limitedLocation: Location;

    get limitedLocation(): Location {
        return this._limitedLocation;
    }

    set limitedLocation(location: Location) {
        this._limitedLocation = location;
    }

    private _previousUrl: string;

    get previousUrl() {
        return this._previousUrl;
    }

    private _currentUrl: string;

    get currentUrl() {
        return this._currentUrl;
    }

    private _currentFeature: string;

    get currentFeature() {
        return this._currentFeature;
    }

    private _currentTitle: string;

    get currentTitle() {
        return this._currentTitle;
    }

    get basePathUrl(): string {
        return this._basePath.join('/');
    }

    get userOrigin(): ESsoOrigins {
        return this._origin;
    }

    set userOrigin(origin: ESsoOrigins) {
        this._origin = origin;
    }

    get userOriginLocationId(): string {
        return this._originLocationId;
    }

    set userOriginLocationId(id: string) {
        this._originLocationId = id;
    }

    get mainPages() {
        return this._availablePages.reduce((collector, page) => {
            if (page.target === EMenuType.main && page.enabled) {
                collector.push(page);
            }

            return collector;
        }, []);
    }

    get menuPages() {
        return this._availablePages.reduce((collector, page) => {
            if (page.target === EMenuType.dropDown && page.enabled) {
                collector.push(page);
            }
            return collector;
        }, []);
    }

    get navigationMap() {
        return;
    }

    get isPackZero(): boolean {
        return this.highestPack === EPacks.pack0;
    }

    get isPackOne(): boolean {
        return this.highestPack === EPacks.pack1;
    }

    get isPackOneOrPackZero(): boolean {
        return this.isPackOne || this.isPackZero;
    }

    get isPackThree(): boolean {
        return this.highestPack === EPacks.pack3;
    }

    get isOldOffer(): boolean {
        return this.highestPack === EPacks.oldOffer;
    }

    get isOtherOffer(): boolean {
        return this.highestPack === EPacks.otherOffer;
    }

    get isPPROtherOffer(): boolean {
        return this.highestPack === EPacks.pprOtherOffer;
    }

    get hasLimitedPublishers(): boolean {
        const pack = this.highestPack;

        return pack === EPacks.pack0 || pack === EPacks.pack1;
    }

    get highestPack(): EPacks {
        const packs = [...get(this.user, 'packs', [])].sort(
            (packA, packB) => packPositionMap[packA] - packPositionMap[packB],
        );

        return (packs[0] as EPacks) || EPacks.oldOffer;
    }

    get isOnBehalfCustomer(): boolean {
        return this.user.is_onbehalf_customer;
    }

    get packIds(): string[] {
        return get(this.user, 'packs') || [];
    }

    get hasPPROtherOffer(): boolean {
        return this.packIds.includes(EPacks.pprOtherOffer);
    }

    prepareAvailablePages(user: User, oneLocation: string, locationPartnerId: string) {
        if (user) {
            this.loginPage = 'home';
            this.user = user;
            this.readonlyUser = user.readonly;
            this.userOrigin = (this.user.origin as ESsoOrigins) || ESsoOrigins.default;
            this.userOriginLocationId = this.user.origin_location_id;
            this.availablePages = [...AVAILABLE_PAGES];

            this._resetAllowedPages();
            this._enablePages(this.envService.pages);
            this._prepareLimitedLocation(user);

            if (this.user.readonly) {
                this._blockPages(pagesBlockedForReadonly);
                this._enablePages(pagesEnabledForReadonly);
            }

            this.availablePages = this._prepareInternalUrls(oneLocation);
        }
    }

    isPagesjaunesOnly(): boolean {
        return this.highestPack === EPacks.pack0;
    }

    isWidgetDisabled(widget: string) {
        const pack = this.highestPack;
        const disabledConfig = disabledWidgets[pack] || [];

        return disabledConfig.includes(widget);
    }

    isWidgetEnabled(widget: string) {
        const pack = this.highestPack;
        const enabledConfig = enabledWidgets[pack] || [];

        return enabledConfig.includes(widget);
    }

    hasProfilePaidOffer(): boolean {
        return [EPacks.pack1, EPacks.pack2, EPacks.pack3].indexOf(this.highestPack) > -1;
    }

    /**
     * Check if the location has a new offer
     * @param location
     */
    isNewOfferLocation(location: Location): boolean {
        return (
            this.isLocationPack(EPacks.pack1, location) ||
            this.isLocationPack(EPacks.pack2, location) ||
            this.isLocationPack(EPacks.pack3, location) ||
            this.isLocationPack(EPacks.k2sdEssential, location) ||
            this.isLocationPack(EPacks.k2sdPrivilege, location) ||
            this.isLocationPack(EPacks.k2sdPremium, location) ||
            this.isLocationPack(EPacks.k2sdInitial, location) ||
            this.isLocationPack(EPacks.k2sd_beauty, location) ||
            this.isLocationPack(EPacks.ngGcr, location) ||
            this.isLocationPack(EPacks.ngGcrRanked, location)
        );
    }

    isProspectLocation(location: Location): boolean {
        return this.isLocationPack(EPacks.pack0, location);
    }

    isAllowedPage(path: string): boolean {
        return !!this.availablePages.find(page => page.route === path);
    }

    getHighestPack(): string {
        const packs = [...get(this.user, 'packs', [])].sort(
            (packA, packB) => packPositionMap[packA] - packPositionMap[packB],
        );

        return packs[0] || '';
    }

    isLocationPack(packLevel: EPacks, location: Location) {
        const pack = get(location, 'custom_data.pack.id');
        return pack ? pack === packLevel : packLevel === EPacks.oldOffer; // Fallback to oldOffer
    }

    hasPage(page: string) {
        return this.menuPages.find(menuPage => menuPage.route === page);
    }

    /**
     * Checks if location is old offer WITH Presence Management
     */
    isOldOfferLocation(location: Location) {
        return this.isLocationPack(EPacks.oldOffer, location);
    }

    /**
     * Checks if location is old offer WITHOUT Presence Management
     */
    isOtherOfferLocation(location: Location) {
        return this.isLocationPack(EPacks.otherOffer, location);
    }

    /**
     * Checks if location is old offer OR other offer
     */
    isOldOrOtherOfferLocation(location: Location) {
        return this.isOldOfferLocation(location) || this.isOtherOfferLocation(location);
    }

    isOldOrOtherOrPPRLocation(location: Location): boolean {
        return (
            this.isOldOfferLocation(location) ||
            this.isOtherOfferLocation(location) ||
            this.isPPROtherOfferLocation(location)
        );
    }

    /**
     * Checks if location is PPR old offer
     */
    isPPROtherOfferLocation(location: Location) {
        return this.isLocationPack(EPacks.pprOtherOffer, location);
    }

    /**
     * Checks if the location has Presence Management offer
     * @param location
     */
    locationHasPM(location: Location) {
        return this.isOldOfferLocation(location) || this.isNewOfferLocation(location);
    }

    locationHasLimitedPublishers(location: Location): boolean {
        return (
            this.isLocationPack(EPacks.pack0, location) ||
            this.isLocationPack(EPacks.pack1, location) ||
            this.isLocationPack(EPacks.otherOffer, location)
        );
    }

    isK2sdEssentialOfferLocation(location: Location): boolean {
        return this.isLocationPack(EPacks.k2sdEssential, location);
    }

    locationIsEligibleOffer(location: Location): boolean {
        return (
            this.locationHasLimitedPublishers(location) ||
            this.isK2sdEssentialOfferLocation(location) ||
            this.isPPROtherOfferLocation(location)
        );
    }

    isK2sdInitialOfferLocation(location: Location): boolean {
        return this.isLocationPack(EPacks.k2sdInitial, location);
    }

    saveUrl(url: string): void {
        // prevent errors because sometimes the router reload the same route
        if (this._currentUrl !== url) {
            this._previousUrl = this._currentUrl;
            this._currentUrl = url;
            this._currentFeature = this.getFeaturePath(url);
            this._currentTitle = this.getFeatureTitle(this.currentFeature);
        }
    }

    /* <customizedInternalUser> to check if
        login user is internal user + codeProduit
        of the offer
    */
    customizedInternalUser(productId) {
        const allowThisOffers = ['SOC-EG', 'SOC-SP', 'SOC-SPI', 'SOC-SC', 'SR', 'PV', 'SV', 'PB'];
        if (this.user && this.user.readonly) {
            const val = allowThisOffers.findIndex(id => id === productId);
            // if internaluser + above offer are there = true(custom internal user);
            return val > -1 ? true : false;
        } else {
            // (custom internal user) === normal user
            return true;
        }
    }

    locationHasRole(location: Location, role: LocationRole): boolean {
        return !!location.roles?.includes(role);
    }

    locationHasRoles(location: Location, roles: LocationRole[]): boolean {
        return roles.every(role => !!location.roles?.includes(role));
    }

    locationHasFacebookPacks(location: Location) {
        // old_offer, K2SD and Pack 1, 2 & 3
        return (
            this.isLocationPack(EPacks.pack1, location) ||
            this.isLocationPack(EPacks.pack2, location) ||
            this.isLocationPack(EPacks.pack3, location) ||
            this.isLocationPack(EPacks.oldOffer, location) ||
            this.isLocationPack(EPacks.k2sdPremium, location) ||
            this.isLocationPack(EPacks.k2sdEssential, location) ||
            this.isLocationPack(EPacks.k2sdPrivilege, location) ||
            this.isLocationPack(EPacks.k2sdInitial, location) ||
            this.isLocationPack(EPacks.k2sd_beauty, location) ||
            this.isLocationPack(EPacks.ngGcr, location) ||
            this.isLocationPack(EPacks.ngGcrRanked, location)
        );
    }

    locationHasNoGmbPacks(location: Location) {
        // Pack 0, other_offer, ppr_other_offer, k2sd_initial
        return (
            this.isLocationPack(EPacks.pack0, location) ||
            this.isLocationPack(EPacks.pprOtherOffer, location) ||
            this.isLocationPack(EPacks.otherOffer, location) ||
            this.isLocationPack(EPacks.k2sdInitial, location)
        );
    }

    private _prepareLimitedLocation(user: User) {
        this.store
            .pipe(select(LocationsSelectors.getLocationByPartnerId(this.user.origin_location_id)), take(1))
            .subscribe((location: Location) => (this.limitedLocation = location));
    }

    private _blockPages(blockedPages: string[] = []) {
        blockedPages.forEach(index => {
            if (this.availablePages[index]) {
                this.availablePages[index].blocked = true;
            }
        });
    }

    private _disablePages(disabledPages: string[] = []) {
        disabledPages.forEach(index => {
            if (this.availablePages[index]) {
                this.availablePages[index].enabled = false;
            }
        });
    }

    private _enablePages(enabledPages: string[] = []) {
        enabledPages.forEach(index => {
            if (this.availablePages[index]) {
                this.availablePages[index].enabled = true;
            }
        });
    }

    private _resetAllowedPages() {
        this.availablePages.forEach(page => (page.enabled = false));
    }

    private _prepareInternalUrls(oneLocation: string) {
        return this.availablePages.reduce((collector, page) => {
            page._route = getUrl([page.route, page.subRoute]);
            page.url = getUrl([this.basePath, page.route, page.subRoute]);

            if (page.route === 'content') {
                if (oneLocation) {
                    page.url = getUrl([this.basePath, page.route, oneLocation]);
                    page = { ...page, ...SINGLE_LOCATION };
                } else {
                    page = { ...page, ...MULTI_LOCATION };
                }
            }

            collector.push(page);

            return collector;
        }, []);
    }

    /**
     * Some paths have alphanumeric identifier, we need a special regex for those paths.
     * @param url
     */
    private removeIdsFromSpecialPath(url: string): string {
        let result = url;
        this._regexSpecialPath.forEach(reg => {
            const regexedKey = new RegExp(reg);
            if (regexedKey.test(url)) {
                const found = url.match(regexedKey);
                result = url.replace(RegExp.$1, '');
            }
        });
        return result;
    }

    private getFeaturePath(url: string): string {
        const path = this.removeIdsFromSpecialPath(url)
            .replace(/\d/g, '') // remove number
            .replace(/\/\//g, '/'); // keep only one slash
        const getParamsPos = path.indexOf('?');
        if (getParamsPos !== -1) {
            return path.substring(0, getParamsPos);
        } else {
            return path;
        }
    }

    /**
     * Replace slash by space and make it upper case to look like a title.
     */
    private getFeatureTitle(feature: string): string {
        const title = feature
            .replace(/app(\/location)?\//g, '')
            .replace(/\//g, ' ')
            .substring(1)
            .split(' ')
            .map(capitalize)
            .join(' ')
            .trim();
        // capitalize(feature.substr(feature.indexOf('/', 3) + 1));
        const getParamsPos = title.indexOf('?');
        if (getParamsPos !== -1) {
            return title.substring(0, getParamsPos);
        } else {
            return title;
        }
    }
}
