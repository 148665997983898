<a [routerLink]="pageLink">
    <div class="kpi-widget">
        <img class="kpi-type-image" [src]="iconLink" alt="" />
        <div class="kpi-title">{{ title }}</div>
        <div *ngIf="!value" class="loader-area">
            <img src="assets/loader.gif" alt="" />
        </div>
        <div *ngIf="value" class="kpi-value">{{ value | frenchDecimal }}</div>
    </div>
</a>
