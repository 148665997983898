<nav class="navbar navbar-default">
    <div class="container-fluid container-flex col-md-12">
        <a (click)="gotoSearch()" class="col-md-3 navbar-brand">{{ 'Pro.live Backoffice' | translate }}</a>
        <div class="col-md-6 topbar-multi-search-container center-block text-center">
            <slm-multi-search
                *ngIf="!disableSearch"
                [locationOnly]="true"
                (moveLocationChanged)="locationSelection($event)"
            ></slm-multi-search>
        </div>
        <div class="navbar-user-logout-container col-md-3">
            <div class="navbar-user-logout">
                <slm-menu (menuClick)="onMenuClick($event)" [menuActive]="menuActive"></slm-menu>
            </div>
        </div>
        <div class="curtain" (click)="menuActive = !menuActive" [ngClass]="{ 'raise-the-curtain': !menuActive }">
            <div class="curtain-shade"></div>
        </div>
    </div>
</nav>
