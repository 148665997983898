import { IOfferConfig } from '../models';

export const sectionAdvertisingConfig: IOfferConfig = {
    KTBC: {
        label: 'Booster Contact',
        name: '',
        logo: '',
        disableCRM: false,
    },
    'SOC-BS': {
        label: 'Booster Site',
        name: '',
        logo: 'picto_site_essentiel.svg',
        disableCRM: false,
    },
    'SOC-BS1': {
        label: 'Booster Site',
        name: '',
        logo: 'picto_site_essentiel.svg',
        disableCRM: false,
    },
    'SOC-BS2': {
        label: 'Booster Site',
        name: '',
        logo: 'picto_site_essentiel.svg',
        disableCRM: false,
    },
    'SOC-BS3': {
        label: 'Booster Site',
        name: '',
        logo: 'picto_site_essentiel.svg',
        disableCRM: false,
    },
    K2SD: {
        label: 'Digital Solution',
        name: '',
        logo: '',
        disableCRM: false,
    },
    KTTR: {
        label: 'Offre Pro',
        name: '',
        logo: '',
        disableCRM: false,
    },
    KTBN: {
        label: 'Booster Notoriété',
        name: '',
        logo: '',
        disableCRM: false,
    },
};
