import { Action } from '@ngrx/store';

import { IErrorApp } from '@solocal-manager/sirius/core/models';

/**
 * See error code list in ApiErrorEffects
 */
export enum RouteAppActionTypes {
    SET_ERROR_APP = '[SET_ERROR_APP] set error-app',
    SET_ERROR_APP_SUCCESS = '[SET_ERROR_APP_SUCCESS] set error-app success',
}

export class SetErrorApp implements Action {
    readonly type = RouteAppActionTypes.SET_ERROR_APP;

    constructor(public payload: IErrorApp) {}
}

export class SetErrorAppSuccess implements Action {
    readonly type = RouteAppActionTypes.SET_ERROR_APP_SUCCESS;

    constructor() {}
}

export type RouteAppActionTypesUnion = SetErrorApp | SetErrorAppSuccess;
