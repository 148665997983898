import { Pipe, PipeTransform } from '@angular/core';

import { Address } from '@solocal-manager/sirius/support/base-models';

import { AddressService } from '../services';

@Pipe({ name: 'address' })
export class AddressPipe implements PipeTransform {
    constructor(private addressService: AddressService) {}

    transform(address: Address, line?: number, noCountry?: boolean): string {
        return this.addressService.getLine(address, line, noCountry);
    }
}
