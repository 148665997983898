import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'capital',
})
export class Capital implements PipeTransform {
    transform(text: string): any {
        if (!text.length) {
            return null;
        }

        return text.replace(/(?:^|\s)\S/g, function (a) {
            return a.toUpperCase();
        });
    }
}
