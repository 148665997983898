<div [formGroup]="combinedUrlsForm">
    <app-input-url formControlName="website" checkForDuplicates="true"></app-input-url>

    <div *ngFor="let type of additionalUrlTypes" class="row">
        <div class="form-group col-md-10 col-sm-10 col-10">
            <label>{{ 'url_type:label_' + type | translate }}</label>
            <input
                type="text"
                name="reservationUrl"
                placeholder="{{ 'url_type:placeholder_' + type | translate }}"
                class="form-control"
                [formControl]="combinedUrlsForm.controls[type]"
            />
        </div>
        <div class="form-group col-md-2 col-sm-2 col-2"></div>
        <div class="row">
            <div class="col-md-10 col-sm-10 col-10 url-err-msg-holder">
                <app-input-error-messages [control]="combinedUrlsForm.controls[type]"></app-input-error-messages>
            </div>
        </div>
    </div>
</div>
