import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { HOST_APP } from '@slm/shared/environment';
import { filter } from 'rxjs/operators';

import {
    ClickEvent,
    GtmEvent,
    IDataLayerBase,
    IDataLayerEventData,
    IDataLayerIntercomInitData,
    IDataLayerIntercomUserData,
    IDataLayerPathData,
    IDataLayerVariables,
    PageDisplayEvent,
} from '../models/tag-manager.model';

declare const window: any;

/**
 * This service contains functions in order to push the Data Layer Custom variables to GTM.
 * you can use it by importing it in your component and calling the function (see examples below)
 * For performance events, please use the PerfrmanceService instead.
 * @example
 * tagManagerService.dlVariables(...)
 * tagManagerService.uiEvent(...)
 * tagManagerService.livechatEvent(...)
 * tagManagerService.chatbotEvent(...)
 * tagManagerService.routerEvent(...)
 * tagManagerService.intercomInitEvent(...)
 * tagManagerService.intercomUserEvent(...)
 * tagManagerService.pageDisplayEvent(...)
 * tagManagerService.clickEvent(...)
 */
@Injectable({ providedIn: 'root' })
export class TagManagerService {
    private static readonly PLATFORM = 'sirius';
    private static APP_ENV = 'debug';

    private readonly hostApp = inject(HOST_APP);
    private readonly destroyRef = inject(DestroyRef);
    private current: string;

    constructor(private router: Router) {
        this.doInit();
    }

    // data layer variables
    dlVariables(variables: IDataLayerVariables): void {
        const obj: { platform: string } & IDataLayerVariables = {
            platform: TagManagerService.PLATFORM,
            ...variables,
        };
        this.pushEvent(obj);
    }

    // only for CTA event
    uiEvent(gtmEvent: IDataLayerEventData): void {
        const obj: IDataLayerBase & IDataLayerEventData = {
            event: 'eventGA',
            appEnv: TagManagerService.APP_ENV,
            platform: TagManagerService.PLATFORM,
            pwa: window.matchMedia('(display-mode: standalone)').matches,
            ...gtmEvent,
        };
        this.pushEvent(obj);
    }

    // only for livechat event
    livechatEvent(gtmEvent: IDataLayerVariables): void {
        const obj: IDataLayerBase & IDataLayerVariables = {
            event: 'livechat.enable',
            platform: TagManagerService.PLATFORM,
            pwa: window.matchMedia('(display-mode: standalone)').matches,
            ...gtmEvent,
        };
        this.pushEvent(obj);
    }

    // only for chatbot event
    chatbotEvent(gtmEvent: IDataLayerVariables): void {
        const obj: IDataLayerBase & IDataLayerVariables = {
            event: 'chatbot.enable',
            platform: TagManagerService.PLATFORM,
            pwa: window.matchMedia('(display-mode: standalone)').matches,
            ...gtmEvent,
        };
        this.pushEvent(obj);
    }

    // routing based event
    routerEvent(gtmEvent: IDataLayerPathData): void {
        const obj: IDataLayerBase & IDataLayerPathData = {
            event: 'virtualPageview',
            platform: TagManagerService.PLATFORM,
            pwa: window.matchMedia('(display-mode: standalone)').matches,
            ...gtmEvent,
        };
        this.pushEvent(obj);
    }

    intercomInitEvent(gtmEvent: IDataLayerIntercomInitData): void {
        const obj = {
            event: 'intercom.init',
            platform: TagManagerService.PLATFORM,
            pwa: window.matchMedia('(display-mode: standalone)').matches,
            ...gtmEvent,
        };
        this.pushEvent(obj);
    }

    intercomUserEvent(gtmEvent: IDataLayerIntercomUserData): void {
        const obj = {
            event: 'intercom.user',
            platform: TagManagerService.PLATFORM,
            pwa: window.matchMedia('(display-mode: standalone)').matches,
            ...gtmEvent,
        };
        this.pushEvent(obj);
    }

    pageDisplayEvent(gtmEvent: PageDisplayEvent): void {
        const obj = {
            event: 'eventGTM_page',
            platform: TagManagerService.PLATFORM,
            pwa: window.matchMedia('(display-mode: standalone)').matches,
            ...gtmEvent,
        };
        this.pushEvent(obj);
    }

    clickEvent(gtmEvent: ClickEvent): void {
        const obj = {
            event: 'eventGTM_click',
            platform: TagManagerService.PLATFORM,
            pwa: window.matchMedia('(display-mode: standalone)').matches,
            ...gtmEvent,
        };
        this.pushEvent(obj);
    }

    performanceEvent(step: string, customTime?: number): void {
        const obj = {
            page: 'chargement page après connexion',
            page_chapter1: 'connexion à SLM',
            page_chapter2: step,
            page_chapter3: '',
            duree_connexion:
                customTime ?? new Date().getTime() - (window as { slmInitialLoadTime: number }).slmInitialLoadTime,
            event: 'eventGTM_page',
        };
        console.log(`performanceEvent: ${step} took ${obj.duree_connexion}ms`);
        this.pushEvent(obj);
    }

    pushEvent(event: GtmEvent): void {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(event);
    }

    private doInit(): void {
        if (this.hostApp.isProduction) {
            TagManagerService.APP_ENV = 'prod';
        }

        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe((event: NavigationEnd) => {
                this.prepareRoutervent(event);
            });
    }

    private prepareRoutervent(event: NavigationEnd): void {
        const urlWithoutParams = event.url.replace(/(\/)?\?.+/, '');
        const currentFeature = this.getCurrentFeature(event.url);

        if (urlWithoutParams !== this.current) {
            let pagePath: string;
            if (urlWithoutParams.includes('location')) {
                pagePath = currentFeature;
            } else if (currentFeature) {
                pagePath = currentFeature.replace('/app/', '/app/location/');
            } else {
                pagePath = urlWithoutParams;
            }

            const gtmEvent = {
                virtualPagePath: pagePath, // Keep /location/ to keep analytics consistency
                virtualPageTitle: this.getCurrentFeatureTitle(currentFeature),
            };
            this.routerEvent(gtmEvent);
            this.current = urlWithoutParams;
        }
    }

    private getCurrentFeature(url: string): string {
        return url.split('?')[0].replace('/2', '');
    }

    private getCurrentFeatureTitle(featureUrl: string): string {
        return featureUrl
            .replace('/app', '')
            .split('/')
            .map(str => str.charAt(0).toUpperCase() + str.slice(1))
            .join(' ');
    }
}
