import { LocationEntity } from '@slm/location/state';
import { EPacks } from '@solocal-manager/sirius/support/base-models';

export class AccountUtils {
    static getLocationType(location: LocationEntity): string {
        if (!location?.epj) return '';
        if (this.isInternalLocation(location.epj)) {
            return 'internal';
        }
        return location.custom_data?.pack?.id !== EPacks.pack0 ? 'client' : 'prospect';
    }

    static isInternalLocation(epj: string): boolean {
        return (
            epj === '54391408' ||
            epj === '56483458' ||
            epj === '57648453' ||
            epj === '58869687' ||
            epj === '58869688' ||
            epj === '58869689' ||
            epj === '00000006'
        );
    }
}
