import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';

import { FeatureSettingsViewModel, LocationsActions, LocationsAndState, LocationsSelectors } from '@slm/location/state';
import { GetContactDetails, getContactDetails } from '@slm/profile';
import { GetLocation, getPublisherStatus, setFeatureActivationStatus } from '@solocal-manager/sirius/core/actions';
import { IContactDetails, ISetFeatureActivationStatusPayload } from '@solocal-manager/sirius/core/models';
import { PublisherStatusState } from '@solocal-manager/sirius/core/models/state/publishers-status.model';
import { selectPublisherStatus } from '@solocal-manager/sirius/core/reducers';
import {
    GetChatConfig,
    GetGbmWizard,
    GetMultiLocationChatInfos,
    PostGbmWizard,
    PutChatConfig,
    PutChatTemporaryDeactivationConfig,
    PutChatUnreadCount,
    PutMultiLocationChatActivation,
} from '@solocal-manager/sirius/data-access-chat/actions';
import {
    ChatConfig,
    ChatConfigViewModel,
    GbmWizard,
    IChatLocation,
    IChatLocationList,
} from '@solocal-manager/sirius/data-access-chat/models';
import {
    getChatConfig,
    getChatConfigOfSelectedLocation,
    getChatLocationList,
    getGbmWizard,
    getOAuthUrlChange,
    getPutChatConfigState,
    getPutTemporaryChatConfigSucess,
} from '@solocal-manager/sirius/data-access-chat/reducers';
import { FeatureType, Location as LocationModel } from '@solocal-manager/sirius/support/base-models';
import { Observable, take } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InstantMessagingFacade {
    protected readonly store = inject(Store);

    /**
     * Pieces of state as Observables
     */
    readonly selectedLocation$: Observable<LocationModel> = this.store.select(LocationsSelectors.getSelectedLocation);
    readonly selectedLocationChatConfig$: Observable<ChatConfig> = this.store.select(getChatConfig);
    readonly selectedLocationChatConfigOne$: Observable<ChatConfig> = this.store.select(getChatConfig).pipe(take(1));
    readonly selectedLocationOauthUrl$: Observable<boolean> = this.store.select(getOAuthUrlChange);
    readonly selectPutChatConfigState$: Observable<boolean> = this.store.select(getPutChatConfigState);
    readonly selectPutTemporaryChatConfigState$: Observable<boolean> = this.store.select(
        getPutTemporaryChatConfigSucess,
    );

    readonly getChatConfig$: Observable<ChatConfigViewModel> = this.store.select(getChatConfigOfSelectedLocation());

    readonly getFeatureSettings$ = (feature: FeatureType): Observable<FeatureSettingsViewModel> =>
        this.store.select(LocationsSelectors.getFeatureSettingsOfSelectedLocation(feature));
    readonly selectedChatLocationList$: Observable<IChatLocationList> = this.store.select(getChatLocationList);

    readonly isSingleLocation$: Observable<boolean> = this.store.select(LocationsSelectors.isSingleLocationSelected);
    readonly selectlocationList$: Observable<LocationsAndState> = this.store.select(
        LocationsSelectors.getLocationsAndState,
    );

    readonly publisherStatus$: Observable<PublisherStatusState> = this.store.select(selectPublisherStatus);

    readonly getContactDetails$: Observable<IContactDetails> = this.store.select(getContactDetails);

    readonly getGbmWizard$: Observable<GbmWizard> = this.store.select(getGbmWizard);

    /**
     * Action dispatchers
     */
    readonly fetchSelectedLocationChatConfig = (epj: string): void =>
        this.store.dispatch(GetChatConfig({ params: { epj } }));
    readonly putSelectedLocationChatConfig = (epj: string, chatConfig: ChatConfig): void =>
        this.store.dispatch(
            PutChatConfig({
                params: {
                    epj,
                    chatConfig,
                },
            }),
        );
    readonly putTemporaryDeactivationStatus = (epj: string, chatConfig: ChatConfig): void =>
        this.store.dispatch(
            PutChatTemporaryDeactivationConfig({
                params: {
                    epj,
                    chatConfig,
                },
            }),
        );

    readonly updateActivationStatus = (payload: ISetFeatureActivationStatusPayload): void =>
        this.store.dispatch(setFeatureActivationStatus({ payload }));

    readonly fetchChatLocationList = (params): void => this.store.dispatch(GetMultiLocationChatInfos({ params }));

    readonly fetchLocation = (locationId: string): void =>
        this.store.dispatch(LocationsActions.loadLocation({ id: locationId, selectIt: true }));

    readonly setLocation = (locationId: string): void =>
        this.store.dispatch(LocationsActions.setSelectedIds({ selectedIds: [locationId] }));

    readonly putActivation = (epjs: ChatConfig): void => {
        this.store.dispatch(PutMultiLocationChatActivation({ payload: epjs }));
    };

    readonly getPublisherStatus = (locationId: string): void => {
        this.store.dispatch(getPublisherStatus({ params: { locationId } }));
    };

    readonly postGbmWizard = (gbmActivationWizardFormData: GbmWizard): void => {
        this.store.dispatch(PostGbmWizard({ gbmActivationWizardFormData }));
    };

    readonly getGbmWizard = (integrationType: string): void => this.store.dispatch(GetGbmWizard({ integrationType }));

    getContactDetailsFn(): void {
        this.store.dispatch(new GetContactDetails());
    }

    putChatUnreadCount(payload: number): void {
        this.store.dispatch(PutChatUnreadCount({ payload }));
    }

    getLocation(locationId: string): void {
        this.store.dispatch(new GetLocation(locationId));
    }
}
