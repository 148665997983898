import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-submit-button',
    templateUrl: './submit-button.component.html',
    styleUrls: ['./submit-button.component.scss'],
})
export class SubmitButtonComponent {
    @Input('formValid') disabled;
    @Output() clicked: EventEmitter<boolean> = new EventEmitter<boolean>();

    onClick(event): void {
        setTimeout(() => this.clicked.emit(true));
    }
}
