//active, inactive, ignored, pending, oauth_needed, error
export enum EChatIntegrationStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    IGNORED = 'ignored',
    PENDING = 'pending',
    OAUTH_NEEDED = 'oauth_needed',
    ERROR = 'error',
    PAUSED = 'paused',
}
