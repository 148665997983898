import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@slm/shared/environment';
import { Publisher } from '@solocal-manager/sirius/core/models';
import { Reference } from '@solocal-manager/sirius/support/base-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


interface IBusinessManagerResponse {
    data: {
        id: string;
        business_managers: Array<{
            id: string;
            name: string;
        }>;
    };
}

@Injectable({ providedIn: 'root' })
export class PublishersService {
    constructor(
        public http: HttpClient,
        public envService: EnvironmentService,
    ) {}

    getPublishers(): Observable<Publisher[]> {
        const requestUrl = `${this.envService.apiUrl}/consumer/publishers`;
        return this.http.get<Publisher[]>(requestUrl).pipe(map((res: any) => res.data));
    }

    getBusinessManagerOptions(publisherId: string): Observable<Reference[]> {
        const requestUrl = `${this.envService.apiUrl}/consumer/publishers/${publisherId}/settings/business-managers`;
        return this.http
            .get<IBusinessManagerResponse>(requestUrl)
            .pipe(map((res: IBusinessManagerResponse) => res?.data?.business_managers));
    }
}
