import { InjectionToken } from '@angular/core';

import { AppType } from './app-type';
import { SlmEnvironment } from './slm-environment';
import { SlmEnvironmentId } from './slm-environment-id';

export class HostApp {
    readonly isSlmBackoffice: boolean;
    readonly isSlmMobile: boolean;
    readonly isSlmWeb: boolean;
    readonly isStgEnvironment: boolean;
    readonly isDemoEnvironment: boolean;
    readonly isPreprodEnvironment: boolean;
    readonly isProdEnvironment: boolean;

    constructor(
        public readonly type: AppType,
        public readonly environment: SlmEnvironmentId,
        public readonly isProduction: boolean = false,
    ) {
        this.isSlmBackoffice = type === AppType.SlmBackoffice;
        this.isSlmMobile = type === AppType.SlmMobile;
        this.isSlmWeb = type === AppType.SlmWeb;
        this.isStgEnvironment = environment === 'stg';
        this.isDemoEnvironment = environment === 'demo';
        this.isPreprodEnvironment = environment === 'preprod';
        this.isProdEnvironment = environment === 'prod';
    }
}

export const createHostApp = (appType: AppType, environment: SlmEnvironment): HostApp =>
    new HostApp(appType, environment?.env, environment?.production);

export const HOST_APP = new InjectionToken<HostApp>('HOST_APP');
