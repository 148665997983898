import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { InstantMessagingFacade } from '../data-access-chat.facade';
import {
    ChatConfig,
    ChatConfigViewModel,
    ChatPublisherPreferences,
    EChatIntegrationStatus,
    IIntegration,
} from '../models';

@Component({
    template: '',
})
export class BaseChatPreferencesRefactoComponent implements OnInit {
    protected readonly destroyRef = inject(DestroyRef);
    protected readonly imFacade = inject(InstantMessagingFacade);

    listOfPublisher: ChatPublisherPreferences[] = [];
    hasPJChatEnabled = false;
    hasFBChatEnabled = false;
    chatConfigVM: ChatConfigViewModel;
    chatIntegrationLoading: string;
    isWaitingForFacebookActivation = false;
    isWaitingForPJActivation = false;
    temporaryActivationLoading = false;
    hasChatPJFeature = false;
    hasChatFacebookFeature = false;
    hasChatGoogleConnected = false;
    readonly getChatConfig$: Observable<ChatConfigViewModel> = this.imFacade.getChatConfig$;

    ngOnInit(): void {
        this.chatIntegrationLoading = undefined;
        this.imFacade.selectedLocation$
            .pipe(
                filter(location => !!location.epj),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe(location => {
                this.imFacade.fetchSelectedLocationChatConfig(location.epj);
                const features = location.tags || [];
                this.hasChatPJFeature = !!features.includes('chat_pj');
                this.hasChatFacebookFeature = !!features.includes('chat_facebook');
            });
    }

    toggleFeatureActivation(chatFeatureSettings: ChatConfigViewModel, integration: IIntegration): void {
        this.setPreference(chatFeatureSettings, integration);
    }

    getNewChatStatus(integration: IIntegration): string {
        return integration.status === 'active' || integration.status === EChatIntegrationStatus.OAUTH_NEEDED
            ? 'inactive'
            : 'active';
    }

    getChatConfigStatus(_chatConfig: ChatConfig): string {
        return _chatConfig.status === null || _chatConfig.status === 'pending' ? 'active' : _chatConfig.status;
    }

    setPreference(chatConfig: ChatConfigViewModel, chatIntegration: IIntegration): void {
        this.chatConfigVM = chatConfig;

        if (chatIntegration) {
            chatIntegration.status = this.getNewChatStatus(chatIntegration);
        } else {
            this.createChatIntegration(chatIntegration.integration_type);
        }
        const _chatConfig = { ...chatConfig.chatConfig };
        chatIntegration.refresh = true;
        _chatConfig.integrations = [chatIntegration];
        _chatConfig.status = this.getChatConfigStatus(_chatConfig);

        if (chatIntegration.status === 'active' && chatIntegration.integration_type === 'messenger')
            this.isWaitingForFacebookActivation = true;

        if (chatIntegration.status === 'active' && chatIntegration.integration_type === 'pagesjaunes')
            this.isWaitingForPJActivation = true;

        this.imFacade.putSelectedLocationChatConfig(chatConfig.location.epj, _chatConfig);
        this.chatIntegrationLoading = chatIntegration.integration_type;
    }

    /**
     * Create chat integration - if needed create chat config
     */
    createChatIntegration(integration_type): void {
        if (!this.chatConfigVM?.chatConfig) {
            this.chatConfigVM.chatConfig = {
                status: 'active',
                integrations: [
                    {
                        integration_type,
                        status: 'active',
                        options: {},
                    },
                ],
            };
        } else {
            this.chatConfigVM.chatConfig.integrations[this.chatConfigVM.chatConfig.integrations.length] = {
                integration_type,
                status: 'active',
                options: {},
            };
        }
    }

    refreshFeature(): void {
        this.chatIntegrationLoading = undefined;
        this.imFacade.fetchSelectedLocationChatConfig(this.chatConfigVM?.location.epj);
    }

    hasPublisherPageConnected(chatConfigVM, publisherId): boolean {
        switch (publisherId) {
            case 'pagesjaunes':
                return true;
            case 'messenger':
                return chatConfigVM.location?.pages?.find(page => page.publisher_id === 'facebook')?.state === 'active';
            case 'gbm':
                return chatConfigVM.location?.pages?.find(page => page.publisher_id === 'gmb')?.state === 'active';
        }
    }

    chatDescriptionText(): string {
        const publishers = this.chatConfigVM?.chatPublisherListAvailable?.reduce((acc, curr) => {
            acc[curr.label] = true;
            return acc;
        }, {});

        if (publishers?.['messenger'] && publishers?.['pagesjaunes']) {
            return 'chat_preferences:description_fb_pj';
        } else if (publishers?.['messenger']) {
            return 'chat_preferences:description_fb';
        } else if (publishers?.['pagesjaunes']) {
            return 'chat_preferences:description_pj';
        }
    }

    getChatIntegration(integration_type: string): IIntegration {
        if (!this.chatConfigVM || !this.chatConfigVM?.integrations) {
            return null;
        }

        return this.chatConfigVM.integrations.find(integration => integration.integration_type === integration_type);
    }
}
