import { createReducer, on } from '@ngrx/store';

import { getPublisherStatusSuccess } from '@solocal-manager/sirius/core/actions';
import { PublisherStatusState } from '../models/state/publishers-status.model';

const initialState: PublisherStatusState = {
    publisherStatus: null,
    loaded: false,
};

export const publisherStatusReducer = createReducer(
    initialState,
    on(getPublisherStatusSuccess, (state, action) => ({
        ...state,
        publisherStatus: action.result,
        loaded: !!action.result,
    })),
);
