import { AbstractControl, ValidationErrors } from '@angular/forms';
import { UrlCheckerService } from '@solocal-manager/sirius/core/services/url-checker.service';
import { get, isObject } from 'lodash-es';
import { Observable, timer as observableTimer, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

export class UrlAsyncValidator {
    static createValidator(urlCheckerService: UrlCheckerService, debounceTime?: number) {
        return (control: AbstractControl): Observable<ValidationErrors> | null => {
            let inputValue = control.value;
            inputValue = isObject(inputValue) ? get(inputValue, 'url') : inputValue;

            if (!inputValue) {
                return of(null);
            }

            if (!inputValue.startsWith('http://') && !inputValue.startsWith('https://')) {
                inputValue = 'http://' + inputValue;
            }

            return observableTimer(debounceTime || 600).pipe(
                switchMap(() => {
                    return urlCheckerService.validateUrl(inputValue).pipe(
                        map(res => {
                            return res?.valid ? control.errors : { ...control.errors, urlRemote: res };
                        }),
                    );
                }),
            );
        };
    }
}
