import { FormArray, FormGroup, ValidationErrors } from '@angular/forms';
import { InputOpeningHours, InputOpeningHoursFormGroup } from '@solocal-manager/sirius/core/form';
import { TimePeriod } from '@solocal-manager/sirius/support/base-models';
import { findIndex } from 'lodash-es';
import * as momentInstance from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(momentInstance);

export enum OverlappingType {
    current,
    next,
    prev,
}

const FAKE_FULL_TIME_OPENING_HOURS = {
    open_time: '00:00',
    close_time: '23:59',
};

export class OverlappingOpeningHoursValidator {
    static create() {
        return (inputOpeningHoursFormGroup: FormGroup<InputOpeningHoursFormGroup>): ValidationErrors => {
            const openingHoursTimeslotsFormArray: FormArray = inputOpeningHoursFormGroup.parent as FormArray;
            const nbOfTimeSlot = openingHoursTimeslotsFormArray.controls.length;
            const currentIndex = findIndex(openingHoursTimeslotsFormArray.controls, ctrl => {
                return ctrl === inputOpeningHoursFormGroup;
            });
            const nextIndex = (currentIndex + 1) % nbOfTimeSlot;
            const prevIndex = (currentIndex + nbOfTimeSlot - 1) % nbOfTimeSlot;
            const currentWeekDay = this.getOpeningHours(openingHoursTimeslotsFormArray, currentIndex);
            const nextWeekDay = this.getOpeningHours(openingHoursTimeslotsFormArray, nextIndex);
            const prevWeekDay = this.getOpeningHours(openingHoursTimeslotsFormArray, prevIndex);
            let validateErrors: { openingVsClosingTimeOverlapping?: boolean; openingVsClosingInverted?: boolean } = {};
            const currentDaylength = currentWeekDay?.length;
            const currentOverlappingHours = this.getOverlapingHours(currentWeekDay);
            validateErrors = this.computeCurrentDayOverlapping(
                currentDaylength,
                currentWeekDay,
                currentOverlappingHours,
                validateErrors,
            );
            validateErrors = this.computePrevDayOverlapping(
                prevWeekDay,
                validateErrors,
                currentWeekDay,
                openingHoursTimeslotsFormArray,
                prevIndex,
            );
            validateErrors = this.computeNextDayOverlapping$(
                nextWeekDay,
                validateErrors,
                currentOverlappingHours,
                openingHoursTimeslotsFormArray,
                nextIndex,
            );
            openingHoursTimeslotsFormArray.setErrors(validateErrors);
            return validateErrors;
        };
    }

    private static computeNextDayOverlapping$(
        nextWeekDay: Array<TimePeriod>,
        validateErrors: {
            openingVsClosingTimeOverlapping?: boolean;
            openingVsClosingInverted?: boolean;
        },
        currentOverlappingHours: Array<TimePeriod>,
        openingHoursTimeslotsFormArray: FormArray<any>,
        nextIndex: number,
    ) {
        if (!!nextWeekDay && nextWeekDay?.length > 0 && !validateErrors) {
            validateErrors =
                currentOverlappingHours?.length == 0
                    ? null
                    : this.prevNextValidatingHours(nextWeekDay, currentOverlappingHours, true);
            openingHoursTimeslotsFormArray.controls[nextIndex].setErrors(validateErrors);
        }
        return validateErrors;
    }

    private static computePrevDayOverlapping(
        prevWeekDay: Array<TimePeriod>,
        validateErrors: {
            openingVsClosingTimeOverlapping?: boolean;
            openingVsClosingInverted?: boolean;
        },
        currentWeekDay: Array<TimePeriod>,
        openingHoursTimeslotsFormArray: FormArray<any>,
        prevIndex: number,
    ) {
        if (prevWeekDay?.length > 0 && !validateErrors) {
            const prevOverlapingHours = this.getOverlapingHours(prevWeekDay);
            validateErrors =
                prevOverlapingHours?.length > 0
                    ? this.prevNextValidatingHours(prevOverlapingHours, [currentWeekDay[0]])
                    : null;
            openingHoursTimeslotsFormArray.controls[prevIndex].setErrors(validateErrors);
        }
        return validateErrors;
    }

    private static computeCurrentDayOverlapping(
        currentDaylength: number,
        currentWeekDay: Array<TimePeriod>,
        currentOverlappingHours: Array<TimePeriod>,
        validateErrors: {
            openingVsClosingTimeOverlapping?: boolean;
            openingVsClosingInverted?: boolean;
        },
    ) {
        if (currentDaylength > 0) {
            let isOverlapped = false;
            let isInverted = false;
            if (!this.getOpening24Hours(currentWeekDay)) {
                isOverlapped = currentOverlappingHours?.length == 0 ? this.checkOverlapping(currentWeekDay) : false;
            }
            // Add the check for is_inverted
            if (isOverlapped && this.is_inverted(currentWeekDay)) {
                isInverted = true;
                isOverlapped = false;
            }
            if (this.is_after_midnight(currentWeekDay)) {
                isOverlapped = true;
            }
            validateErrors = this.setError(isOverlapped, isInverted);
        }
        return validateErrors;
    }

    private static getOverlapingHours(openingHours: Partial<TimePeriod[]>) {
        const overLappingHours: Partial<TimePeriod[]> = [];
        const overlapingIndex = findIndex(openingHours, (hours: TimePeriod) => {
            return hours.close_time < hours.open_time;
        });
        const length = openingHours?.length;
        if (overlapingIndex > -1) {
            const openingHour = length > 1 ? openingHours[length - 1] : openingHours[overlapingIndex];
            overLappingHours.push(openingHour);
        }
        return overLappingHours;
    }

    private static setError(isOverlapped: boolean, inverted?: boolean) {
        if (isOverlapped) {
            return { openingVsClosingTimeOverlapping: true };
        }

        if (inverted) {
            return { openingVsClosingInverted: true };
        }

        return null;
    }

    private static prevNextValidatingHours(
        prevNextHours: Partial<TimePeriod[]>,
        currentOverlappingHours: Partial<TimePeriod[]>,
        preNext = false,
    ) {
        if (!this.getOpening24Hours(prevNextHours)) {
            const length = prevNextHours?.length;
            const hours = !preNext ? prevNextHours[length - 1] : prevNextHours[0];
            const overlapType = !preNext ? OverlappingType.prev : OverlappingType.next;
            const isValid = this.checkOverlapping([hours, ...currentOverlappingHours], overlapType);
            return this.setError(isValid);
        }
        return null;
    }

    private static getOpeningHours(
        repeatableControls: FormArray<FormGroup<InputOpeningHoursFormGroup>>,
        index: number,
    ): Partial<TimePeriod[]> {
        const control: FormGroup<InputOpeningHoursFormGroup> = repeatableControls.controls[index];

        const controlValue: InputOpeningHours = control?.value as InputOpeningHours;
        let openingHours = controlValue?.openingHours as Partial<TimePeriod[]>;
        if (!controlValue.closed && controlValue.isFullTime) {
            openingHours = [
                {
                    open_day: '',
                    close_day: '',
                    ...FAKE_FULL_TIME_OPENING_HOURS,
                },
            ];
        }
        return !controlValue.closed ? openingHours : [];
    }

    private static getOpening24Hours(openingHours: TimePeriod[]) {
        if (openingHours?.length == 1) {
            return openingHours[0]?.close_time == '24:00';
        }
        return false;
    }

    private static checkOverlapping(
        periodsInDay: Partial<TimePeriod[]>,
        overlapType: OverlappingType = OverlappingType.current,
    ) {
        return periodsInDay.some((current, idx, arr) => {
            if (idx === 0) {
                return false;
            }
            const previous = arr[idx - 1];
            const isOverlapping = this.is_overlapping(previous, current, overlapType);
            return isOverlapping;
        });
    }

    private static is_inverted(currentWeekDay: Partial<TimePeriod[]>) {
        return currentWeekDay.some((current, idx, arr) => {
            if (idx === 0) {
                return false;
            }
            const previous = arr[idx - 1];
            return (
                moment(previous?.open_time, 'hh:mm').isAfter(moment(current?.open_time, 'hh:mm')) ||
                moment(previous?.close_time, 'hh:mm').isAfter(moment(current?.close_time, 'hh:mm'))
            );
        });
    }

    private static is_overlapping(
        prev: Partial<TimePeriod>,
        current: Partial<TimePeriod>,
        overlapType: OverlappingType,
    ): boolean {
        switch (overlapType) {
            case OverlappingType.current:
                return (
                    moment(current?.open_time, 'hh:mm').isSameOrBefore(moment(prev?.close_time, 'hh:mm')) ||
                    moment(current?.close_time, 'hh:mm').isSameOrBefore(moment(current?.open_time, 'hh:mm'))
                );
            case OverlappingType.prev:
                return moment(current?.open_time, 'hh:mm').isSameOrBefore(moment(prev.close_time, 'hh:mm'));
            case OverlappingType.next:
                return moment(prev?.open_time, 'hh:mm').isSameOrBefore(moment(current?.close_time, 'hh:mm'));
            default:
                return false;
        }
    }

    private static is_after_midnight(currentWeekDay: Partial<TimePeriod[]>) {
        return currentWeekDay.some((current, idx, arr) => {
            if (idx === 0) {
                return false;
            }
            const previous = arr[idx - 1];
            if (
                moment(previous?.close_time, 'hh:mm').isAfter(moment('00:00', 'hh:mm')) &&
                moment(previous?.close_time, 'hh:mm').isBefore(moment(previous?.open_time, 'hh:mm'))
            ) {
                return true;
            }

            return false;
        });
    }
}
