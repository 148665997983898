import { QuotationSettingsActionTypes, QuotationSettingsActionUnion } from '../actions';

import { IfeatureSettingsState } from '../models';

export type SettingsActionUnion = QuotationSettingsActionUnion;

const initialState: IfeatureSettingsState = {
    settingsUpdated: false,
    temporaryActivationUpdated: false,
    settingLoading: false,
    chatActivated: null,
    quotationActivated: null,
};

export function reducer(state = initialState, action: SettingsActionUnion): IfeatureSettingsState {
    switch (action.type) {
        case QuotationSettingsActionTypes.GET_QUOTATION_SETTINGS:
            return {
                ...state,
                settingLoading: true,
            };
        case QuotationSettingsActionTypes.GET_QUOTATION_SETTINGS_SUCCESS:
            return {
                ...state,
                settingLoading: false,
                quotationActivated: action.payload,
            };
        case QuotationSettingsActionTypes.PATCH_QUOTATION_SETTINGS:
            return {
                ...state,
                settingsUpdated: false,
                settingLoading: true,
            };
        case QuotationSettingsActionTypes.PATCH_QUOTATION_SETTINGS_SUCCESS:
            return {
                ...state,
                settingLoading: false,
                settingsUpdated: true,
                quotationActivated: action.payload,
            };
        case QuotationSettingsActionTypes.PATCH_QUOTATION_TEMPORARY_DEACTIVATION_STATUS:
            return {
                ...state,
                temporaryActivationUpdated: false,
                settingLoading: true,
            };
        case QuotationSettingsActionTypes.PATCH_QUOTATION_TEMPORARY_DEACTIVATION_STATUS_SUCCESS:
            return {
                ...state,
                temporaryActivationUpdated: true,
                settingLoading: false,
            };

        case QuotationSettingsActionTypes.RESET_PATCH_QUOTATION_SETTINGS_STATE:
            return {
                ...state,
                temporaryActivationUpdated: null,
                settingLoading: false,
                quotationActivated: null,
            };
        default:
            return state;
    }
}
