<div class="kpi-publishers-error">
    <div class="sub-title">
        <a [routerLink]="[basePath, 'total-error']">{{ 'Publishers' | translate }} {{ state | translate }} </a>
    </div>
    <div class="title">{{ publisherName$ | async }}</div>

    <div *ngIf="(kpiPublishersError$ | async) || {}; let kpiPublishersError" class="errors-table">
        <table>
            <tr class="errors-header">
                <td class="kpi-text">
                    <div (click)="orderBy('user_msg')" class="header-title">
                        <span>{{ 'Reject reason' | translate }}</span>
                        <i *ngIf="sortConfig.descMessage" class="glyphicon glyphicon-menu-down"></i>
                        <i *ngIf="!sortConfig.descMessage" class="glyphicon glyphicon-menu-up"></i>
                    </div>
                </td>
                <td class="number-header">
                    <div (click)="orderBy('count')" class="header-title">
                        <span>{{ 'Errors' | translate }}</span>
                        <i *ngIf="sortConfig.descCount" class="glyphicon glyphicon-menu-down"></i>
                        <i *ngIf="!sortConfig.descCount" class="glyphicon glyphicon-menu-up"></i>
                    </div>
                </td>
            </tr>
            <tbody class="errors-rows">
                <tr
                    *ngFor="
                        let errorInfo of kpiPublishersError?.data?.errors
                            | sorter
                                : {
                                      property: sortConfig.field,
                                      desc: sortConfig.desc
                                  }
                    "
                    class="errors-row"
                >
                    <td class="error-name kpi-text">
                        <span [class.has-no-error-status-detail]="!errorInfo.msg_id">
                            <a
                                [routerLink]="
                                    generateRouterLink(
                                        errorInfo,
                                        publisherId,
                                        state,
                                        (kpiPublishersError?.meta?.errorIndexMap)[errorInfo.msg_id]
                                    )
                                "
                            >
                                {{ errorInfo.user_msg || '—' }}
                            </a>
                        </span>
                    </td>
                    <td class="errors-count kpi-number">
                        {{ errorInfo.count }}
                    </td>
                </tr>
            </tbody>
            <tr class="total-row">
                <td class="kpi-text">{{ 'Total' | translate }}</td>
                <td class="total-overall kpi-number">
                    {{ kpiPublishersError?.meta?.total }}
                </td>
            </tr>
        </table>
    </div>
</div>
