import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApiErrorActions } from '@slm/shared/error';
import { Photo } from '@solocal-manager/sirius/support/base-models';
import { Subject, from as observableFrom } from 'rxjs';
import { concatMap, filter, switchMap, takeUntil, tap, toArray } from 'rxjs/operators';

import { ImageService } from '../../services';

// Service

export class ImageUploadStatus {
    file: any;
    params: { [key: string]: any } = {};

    constructor(file?: any) {
        this.file = file;
    }

    setStatus(key: string, errorMessage: any) {
        this.params = {
            ...this.params,
            [key]: errorMessage,
        };
    }
}

@Component({
    template: '',
})
export class WpmInputImageUploadComponent implements OnInit, OnDestroy {
    @Input()
    locationId: string;
    @Input()
    multiple: boolean;
    @Input()
    mediaType: string;
    @Input()
    mediaName: string;
    @Input()
    minHeight: number;
    @Input()
    minWidth: number;
    @Input()
    maxHeight: number;
    @Input()
    maxWidth: number;
    @Input()
    hideUploaded: boolean;
    @Input()
    publishToLocation: boolean; // set this to 'true' for 'gallery' and 'content'
    @Input() minFileSize?: number;

    @Output()
    uploaded: EventEmitter<Photo[]> = new EventEmitter();
    @Output()
    errorOccurred: EventEmitter<ImageUploadStatus[]> = new EventEmitter();

    destroyed$: Subject<boolean> = new Subject();
    filesToUpload: File[] = [];
    errorMessages: ImageUploadStatus[] = [];
    uploadedImages: Photo[] = [];
    uploadProgress: Map<string, number>;
    uploading = false;

    constructor(
        protected store: Store<any>,
        public imageService: ImageService,
        protected cdr: ChangeDetectorRef,
    ) {
        this.imageService.progress$.pipe(takeUntil(this.destroyed$)).subscribe(progress => {
            this.uploadProgress.set(progress.file.name, progress.value);
            this.cdr.detectChanges();
        });
    }

    initFileSizes() {
        this.imageService.setDimensions(this.mediaType);
    }

    ngOnInit() {
        this.initFileSizes();
        if (this.multiple === undefined) {
            this.multiple = false;
        }
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    selectFiles(files) {
        this.filesToUpload = [];
        this.errorMessages = [];
        // clear image errors before uploading new files
        this.imageService.clearImageErrors();
        this.uploadProgress = new Map();
        observableFrom(files)
            .pipe(
                concatMap((file: File) =>
                    this.imageService.getImageElement(file).pipe(
                        filter((img: HTMLImageElement) => {
                            if (
                                this.imageService.validateImage(this.mediaType, {
                                    width: img.width,
                                    height: img.height,
                                    formatType: file.type,
                                    name: file.name,
                                    size: file.size,
                                })
                            ) {
                                this.filesToUpload.push(file);
                                return true;
                            }
                            return false;
                        }),
                        switchMap((img: HTMLImageElement) => {
                            this.uploading = true;
                            this.cdr.detectChanges();
                            return this.imageService.upload(
                                'all',
                                this.mediaType === 'publication' ? 'gallery' : this.mediaType,
                                this.mediaName,
                                file,
                                img.src,
                                [this.locationId],
                                this.publishToLocation,
                            );
                        }),
                        tap((uploadedImage: Photo) => {
                            if (uploadedImage) {
                                this.uploadedImages.push(uploadedImage);
                            }
                        }),
                    ),
                ),
                toArray(),
                takeUntil(this.destroyed$),
            )
            .subscribe(
                (uploadedImages: Photo[]) => {
                    this.uploading = false;
                    if (uploadedImages && uploadedImages.length) {
                        this.uploaded.emit(uploadedImages);
                    } else {
                        this.cdr.detectChanges();
                    }
                    if (this.errorMessages.length) {
                        this.errorOccurred.emit(this.errorMessages);
                    }
                },
                error => {
                    this.uploading = false;
                    this.store.dispatch(new ApiErrorActions.CatchApiError(error));
                },
            );
    }
}
