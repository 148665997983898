import { Action, createReducer, on } from '@ngrx/store';

import * as didomiActions from '@solocal-manager/sirius/core/actions/didomi.actions';
import { IDidomiState } from '@solocal-manager/sirius/core/models';

export const initialState: IDidomiState = {
    isLoaded: false,
};

const reducer = createReducer(
    initialState,

    on(didomiActions.injectDidomi, state => ({ isLoaded: true })),
);

export function didomiReducer(state: IDidomiState | undefined, action: Action) {
    return reducer(state, action);
}
