import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { StandardModalType } from '../../types/modal.type';

@Component({
    selector: 'slm-standard-modal',
    templateUrl: './standard-modal.component.html',
    styleUrls: ['./standard-modal.component.scss'],
})
export class StandardModalComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: StandardModalType) {}
}
