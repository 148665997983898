import { FormArray, FormGroup, UntypedFormArray, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { ExceptionalHoursFormGroup } from '@solocal-manager/sirius/core/form';
import { getCalendarFutureYear } from '@solocal-manager/sirius/core/utils/time-utils';
import * as momentInstance from 'moment';
import { extendMoment, DateRange } from 'moment-range';


const moment = extendMoment(momentInstance);

export class SpecificOpenningHoursValidator {
    static create(formRepeatable: UntypedFormGroup) {
        return (currentExceptionalHoursForm: FormGroup<ExceptionalHoursFormGroup>): ValidationErrors => {
            const exceptionalHoursFormArray: FormArray<FormGroup<ExceptionalHoursFormGroup>> = <UntypedFormArray>(
                formRepeatable.controls.data
            );
            const startDateValue = currentExceptionalHoursForm.controls.start_date.value;
            const endDateValue = currentExceptionalHoursForm.controls.end_date.value;
            const closed = currentExceptionalHoursForm.controls.closed.value;
            const futureYear = getCalendarFutureYear(startDateValue);
            const controlsByDay = exceptionalHoursFormArray.controls.filter(
                control =>
                    control.value.start_date === startDateValue ||
                    control.value.start_date === endDateValue ||
                    control.value.end_date === startDateValue ||
                    (moment(control.value.start_date).isSameOrBefore(moment(endDateValue)) &&
                        moment(control.value.end_date).isSameOrAfter(moment(startDateValue))),
            );
            if (controlsByDay.length > 1) {
                const controlDate = this.checkOverlapping(controlsByDay);
                formRepeatable.setErrors(controlDate);
                return controlDate;
            }
            if (currentExceptionalHoursForm.controls.timeSlots.value.length > 0) {
                const checkTimeOverLaping = currentExceptionalHoursForm.controls.timeSlots.value.some(item => {
                    return moment(item.close_time, 'hh:mm').isSameOrBefore(moment(item.open_time, 'hh:mm'));
                });

                const timeSlotOverlap = !checkTimeOverLaping
                    ? this.checkTimeSlotOverlapping([currentExceptionalHoursForm])
                    : { openingVsClosingTimeOverlapping: true };
                formRepeatable.setErrors(timeSlotOverlap);
                return timeSlotOverlap;
            }
            if (closed && moment(endDateValue) < moment(startDateValue)) {
                return {
                    startEndDateOverlap: true,
                };
            } else if (closed && moment(endDateValue).isAfter(futureYear)) {
                const yearLimitiation = {
                    closingYearLimitations: true,
                };
                formRepeatable.setErrors(yearLimitiation);
                return yearLimitiation;
            }
            return null;
        };
    }

    private static checkOverlapping(controlsByDay: FormGroup<ExceptionalHoursFormGroup>[]) {
        const intervals = this.getExceptionalHoursDateRanges(controlsByDay);
        for (let i = 0; i < intervals.length; i++) {
            for (let j = 0; j < intervals.length; j++) {
                if (
                    intervals[i].overlaps(intervals[j]) ||
                    this.checkControlExists(controlsByDay[i]) ||
                    this.checkControlExists(controlsByDay[j])
                ) {
                    return {
                        openingVsClosingTimeOverlapping: true,
                    };
                }
            }
        }
    }

    private static checkControlExists(control) {
        return control ? control.value.closed : false;
    }

    private static checkTimeSlotOverlapping(controlsByDay: FormGroup<ExceptionalHoursFormGroup>[]) {
        const intervals = this.getExceptionalHoursDateRanges(controlsByDay);
        for (let i = 0; i < intervals.length; i++) {
            for (let j = i + 1; j < intervals.length; j++) {
                if (intervals[i].overlaps(intervals[j], { adjacent: true })) {
                    return {
                        openingVsClosingTimeOverlapping: true,
                    };
                }
            }
        }
    }

    private static readonly defaultFirstOpenTime = '09:00';

    private static getExceptionalHoursDateRanges(controlsByDay: FormGroup<ExceptionalHoursFormGroup>[]) {
        const exceptionalDateRanges: DateRange[] = [];
        controlsByDay.forEach(exceptionalHoursForm => {
            if (exceptionalHoursForm.getError('openingVsClosingTimeOverlapping')) {
                exceptionalHoursForm.setErrors(null);
            }
            if (exceptionalHoursForm.value.timeSlots.length > 0) {
                exceptionalDateRanges.push(...this.addStartEndDateTimeSlots(exceptionalHoursForm));
            } else {
                const { timeslotOpenTime, timeslotCloseTime } = this.initDefaultTimeSlot(exceptionalHoursForm);
                exceptionalDateRanges.push(moment.range(timeslotOpenTime, timeslotCloseTime));
            }
        });
        return exceptionalDateRanges;
    }

    private static initDefaultTimeSlot(exceptionalHoursForm: FormGroup<ExceptionalHoursFormGroup>) {
        const timeslotOpenTime = this.convertToMomentTimeSlot(
            exceptionalHoursForm.value.start_date,
            this.defaultFirstOpenTime,
        );
        const timeslotCloseTime = this.endDateTimeSlot(exceptionalHoursForm);
        return { timeslotOpenTime, timeslotCloseTime };
    }

    private static endDateTimeSlot(control) {
        if (control.end_date) {
            return this.convertToMomentTimeSlot(control.value.end_date, this.defaultFirstOpenTime);
        } else {
            return moment(`${moment().format('YYYY-MM-DD')} ${this.defaultFirstOpenTime}`, 'YYYY-MM-DD HH:mm');
        }
    }

    private static addStartEndDateTimeSlots(control: FormGroup<ExceptionalHoursFormGroup>): DateRange[] {
        return control.value.timeSlots.map((timeSlot: any) =>
            moment.range(
                this.convertToMomentTimeSlot(control.value.start_date, timeSlot.open_time),
                this.convertToMomentTimeSlot(control.value.end_date, timeSlot.close_time),
            ),
        );
    }

    private static convertToMomentTimeSlot(date: string, time: string) {
        return moment(`${moment(date).format('YYYY-MM-DD')} ${time}`, 'YYYY-MM-DD HH:mm');
    }
}
