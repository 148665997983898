import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@solocal-manager/auth';

import { RouteGuard } from './core/guards';
import { AdminSearchPageComponent } from './core/pages/admin-search-page/admin-search-page.component';
import { AdminToolsIndexPageComponent } from './core/pages/admin-tools-index-page/admin-tools-index-page.component';
import { AdminToolsPageComponent } from './core/pages/admin-tools-page/admin-tools-page.component';
import { AdminToolsStatisticsPageComponent } from './core/pages/admin-tools-statistics-page/admin-tools-statistics-page.component';
import { KpiGuard, KpiPublishersErrorGuard } from './feature-kpi/guards';
import {
    AdminKpiPageComponent,
    KpiErrorStatusDetailComponent,
    KpiNotSuccessfulProvisioningsComponent,
    KpiProvisioningIssueDetailsComponent,
    KpiPublishersErrorComponent,
    KpiTotalErrorComponent,
    KpiTotalSalesComponent,
    KpiTotalUsesComponent,
} from './feature-kpi/partials';

export const routes: Routes = [
    { path: '', redirectTo: 'app/2/search', pathMatch: 'full' },
    {
        path: 'app/:id/search',
        component: AdminSearchPageComponent,
        canActivate: [AuthGuard, RouteGuard, KpiGuard],
    },
    {
        path: 'app/:id/kpi',
        component: AdminKpiPageComponent,
        canActivate: [AuthGuard],
        canActivateChild: [RouteGuard, KpiGuard],
        children: [
            { path: 'total-error', component: KpiTotalErrorComponent },
            {
                path: 'total-error/:publisherId/:state',
                component: KpiPublishersErrorComponent,
                canActivate: [KpiPublishersErrorGuard],
            },
            {
                path: 'total-error/:publisherId/:state/error-status-detail/:errorIndex',
                component: KpiErrorStatusDetailComponent,
            },
            { path: 'total-sales', component: KpiTotalSalesComponent },
            { path: 'total-sales/:status', component: KpiTotalSalesComponent },
            {
                path: 'not-successful-provisionings',
                component: KpiNotSuccessfulProvisioningsComponent,
            },
            {
                path: 'not-successful-provisionings/:type/:year/:week',
                component: KpiProvisioningIssueDetailsComponent,
            },
            {
                path: 'total-uses',
                component: KpiTotalUsesComponent,
            },
        ],
    },
    {
        path: 'app/:appId/company/:companyId/location',
        component: AdminToolsIndexPageComponent,
        canActivate: [AuthGuard],
        canActivateChild: [RouteGuard],
        children: [
            {
                path: '',
                component: AdminToolsStatisticsPageComponent,
                runGuardsAndResolvers: 'always',
            },
            { path: ':locationId', component: AdminToolsPageComponent },
            { path: ':locationId/:action', component: AdminToolsPageComponent },
        ],
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
    useHash: true,
    onSameUrlNavigation: 'reload'
}),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
