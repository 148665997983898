import { Component } from '@angular/core';

import { BaseImageErrorMessageComponent } from '@solocal-manager/sirius/core/form/base-image-error-message/base-image-error-message.component';

@Component({
    selector: 'slm-input-image-error-messages',
    templateUrl: './input-image-error-messages.component.html',
    styleUrls: ['./input-image-error-messages.component.scss'],
})
export class InputImageErrorMessagesComponent extends BaseImageErrorMessageComponent {
    translationPrefix = 'input_image_error';
    translationSuffix = '_gallery_error_msg';

    constructor() {
        super();
    }
}
