import { AbstractControl, ValidationErrors } from '@angular/forms';

export class ValidatePrice {
    static createValidator() {
        return (control: AbstractControl): ValidationErrors => {
            const errorObject = {
                price: true,
            };

            return !control.value || /^\d+$|^\d+[,.][0-9]{1,2}$/.test(control.value) ? null : errorObject;
        };
    }
}
