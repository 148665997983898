export enum Pages {
    HOME = 'HOME',
    REVIEWS = 'REVIEWS',
    REVIEWS_RESPONSE_MODELS = 'REVIEWS_RESPONSE_MODELS',
    INSTANT_MESSAGING = 'INSTANT_MESSAGING',
    INSTANT_MESSAGING_CONVERSATIONS = 'INSTANT_MESSAGING_CONVERSATIONS',
    INSTANT_MESSAGING_PREFERENCES = 'INSTANT_MESSAGING_PREFERENCES',
    QUOTATIONS_CLIENT_DEMANDS = 'QUOTATIONS_CLIENT_DEMANDS',
    QUOTATIONS_CLIENT_DEMANDS_PREFERENCES = 'QUOTATIONS_CLIENT_DEMANDS_PREFERENCES',
    LOCATIONS = 'LOCATIONS',
    LOCATION_INFO = 'LOCATION_INFO',
    LOCATION_INFO_NAME = 'LOCATION_INFO_NAME',
    LOCATION_INFO_CATEGORIES = 'LOCATION_INFO_CATEGORIES',
    LOCATION_INFO_ADDRESS = 'LOCATION_INFO_ADDRESS',
    LOCATION_INFO_PHONES = 'LOCATION_INFO_PHONES',
    LOCATION_INFO_PHOTO = 'LOCATION_INFO_PHOTO',
    LOCATION_INFO_DESCRIPTION = 'LOCATION_INFO_DESCRIPTION',
    LOCATION_INFO_EMAILS = 'LOCATION_INFO_EMAILS',
    LOCATION_INFO_WEBSITE = 'LOCATION_INFO_WEBSITE',
    LOCATION_INFO_OPENING_HOURS = 'LOCATION_INFO_OPENING_HOURS',
    LOCATION_INFO_PAYMENT_METHODS = 'LOCATION_INFO_PAYMENT_METHODS',
    LOCATION_PUBLISHERS = 'LOCATION_PUBLISHERS',
    POSTS_PUBLICATIONS_CREATE = 'POSTS_PUBLICATIONS_CREATE',
    POSTS_PUBLICATIONS = 'POSTS_PUBLICATIONS',
    CONTACTS = 'CONTACTS',
    AGENDA = 'AGENDA',
    SLP_CALENDAR = 'SLP_CALENDAR',
    SLP_PAYMENTS = 'SLP_PAYMENTS',
    STATISTICS = 'STATISTICS',
    MY_ACCOUNT = 'MY_ACCOUNT',
    SETTINGS = 'SETTINGS',
    MY_INVOICES = 'MY_INVOICES',
    MY_INVOICES_PROGRESS = 'MY_INVOICES_PROGRESS',
    PURCHASE_ORDER = 'PURCHASE_ORDER',
    MY_SOLUTIONS = 'MY_SOLUTIONS',
    MY_WEBSITE = 'MY_WEBSITE',
    SOLOCAL_APPOINTMENTS = 'SOLOCAL_APPOINTMENTS',
    MY_DEMANDS = 'MY_DEMANDS',
    CONTACT_SOLOCAL = 'CONTACT_SOLOCAL',
    CONTACT_SOLOCAL_CASE_CREATE = 'CONTACT_SOLOCAL_CASE_CREATE',
    CONTACT_SOLOCAL_CASE_SHOW = 'CONTACT_SOLOCAL_CASE_SHOW',
    ACCESSIBILITY = 'ACCESSIBILITY',
    TODO_LIST = 'TODO_LIST',
    LOCATION_PAGES = 'LOCATION_PAGES',
    REVIEWS_REQUESTS = 'REVIEWS_REQUESTS',
    REVIEWS_REQUESTS_HOME = 'REVIEWS_REQUESTS_HOME',
    REVIEWS_REQUESTS_LIST = 'REVIEWS_REQUESTS_LIST',
    REVIEWS_REQUESTS_TEASING = 'REVIEWS_REQUESTS_TEASING',
    REVIEWS_REQUESTS_SMS_PAGE = 'REVIEWS_REQUESTS_SMS_PAGE',
    REVIEWS_REQUESTS_QR_PAGE = 'REVIEWS_REQUESTS_QR_PAGE',
    REVIEWS_REQUESTS_NEW = 'REVIEWS_REQUESTS_NEW',
    CONTACT_ADD = 'CONTACT_ADD',
    COMPANY_PERKS = 'COMPANY_PERKS',
    MKT_AUTO = 'MKT_AUTO',
    MKT_EMAIL = 'MKT_EMAIL',
    MKT_SMS = 'MKT_SMS',
    ONBOARDING_JOURNEY = 'ONBOARDING_JOURNEY',
}
