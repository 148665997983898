<div class="input-video">
    <div [formGroup]="videoForm">
        <div class="youtube-input" formArrayName="video_form">
            <div class="video-input-box" *ngFor="let video of videoList.controls; let i = index">
                <div [formGroupName]="i">
                    <label for="inputVideoUrl" class="control-label">{{
                        'Youtube URL or Parameter' | translate
                    }}</label>
                    <div class="row">
                        <div class="col-md-11">
                            <input
                                [class.ng-invalid]="videoErrorIndex.indexOf(i) !== -1"
                                id="inputVideoUrl_{{ i }}"
                                class="form-control video-text-box"
                                type="text"
                                (input)="onChange($event, i)"
                                formControlName="video_url"
                            />
                            <button
                                type="button"
                                class="btn btn-primary remove-field-button on-the-right"
                                (click)="removeVideo(i)"
                            >
                                X
                            </button>
                            <app-input-error-messages [control]="videoForm"></app-input-error-messages>
                        </div>
                    </div>
                    <input type="hidden" formControlName="id" />
                    <input type="hidden" formControlName="title" />
                    <input type="hidden" formControlName="company_id" />
                    <input type="hidden" formControlName="description" />
                    <input type="hidden" formControlName="length" />
                </div>
            </div>
            <div class="alert alert-danger" *ngIf="videoErrorIndex.length">
                <img src="assets/images/reviews-warning.svg" class="alert-icon" alt="warning icon" />
                <b>{{ 'Your video can not be published because:' | translate }}</b>
                <ul class="alert-reasons">
                    <li>
                        {{ 'either it is a private content' | translate }}
                    </li>
                    <li>
                        {{ 'either it is an age-restricted content +18' | translate }}
                    </li>
                    <li>
                        {{ 'either it is no longer available on Youtube' | translate }}
                    </li>
                </ul>
            </div>
            <div class="add-video">
                <span class="add-video-btn" (click)="addVideo()">+ {{ 'ADD A YOUTUBE URL' | translate }}</span>
            </div>
            <div class="video-parsing-result">
                <div class="video-box">
                    <div *ngIf="videoUrlEmbed" class="video-youtube-iframe">
                        <iframe
                            title="youtube-video"
                            height="100%"
                            width="100%"
                            [src]="videoUrlEmbed | isYoutubeEmbedUrl | safeUrl"
                        ></iframe>
                    </div>
                </div>
                <div class="video-label">
                    <span>{{ videoList.value[0]?.title || '&nbsp;' }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
