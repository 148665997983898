import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'websiteUrl',
})
export class WebsiteUrlPipe implements PipeTransform {
    transform(url: string): any {
        if (url) {
            const urlToDisplay = url
                .replace('http://', '')
                .replace('https://', '')
                .replace('www.', '')
                .replace(/\/([^\/]*)$/, '$1');
            return urlToDisplay;
        }
        return url;
    }
}
