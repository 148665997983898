import { Action } from '@ngrx/store';

// Models
import { IContactDetails } from '@solocal-manager/sirius/core/models';

// Contact details actions
export enum ContactDetailsActionTypes {
    GET_CONTACT_DETAILS = '[CONTACT_DETAILS] get details',
    GET_CONTACT_DETAILS_SUCCESS = '[CONTACT_DETAILS] get details succeeded',
    PATCH_CONTACT_DETAILS = '[CONTACT_DETAILS] patch details',
    PATCH_CONTACT_DETAILS_SUCCESS = '[CONTACT_DETAILS] patch details succeeded',
}

export class GetContactDetails implements Action {
    readonly type = ContactDetailsActionTypes.GET_CONTACT_DETAILS;
}

export class GetContactDetailsSuccess implements Action {
    readonly type = ContactDetailsActionTypes.GET_CONTACT_DETAILS_SUCCESS;

    constructor(public payload: any) {}
}

export class PatchContactDetails implements Action {
    readonly type = ContactDetailsActionTypes.PATCH_CONTACT_DETAILS;

    constructor(public payload: IContactDetails) {}
}

export class PatchContactDetailsSuccess implements Action {
    readonly type = ContactDetailsActionTypes.PATCH_CONTACT_DETAILS_SUCCESS;

    constructor(public payload: any) {}
}

export type ContactDetailsActionUnion =
    | GetContactDetails
    | GetContactDetailsSuccess
    | PatchContactDetails
    | PatchContactDetailsSuccess;
