import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@slm/shared/environment';
import { forOwn } from 'lodash-es';
import { interval as observableInterval, Observable } from 'rxjs';
import { filter, mergeMap, take, timeout } from 'rxjs/operators';


import { JobId, PublisherSearch } from '../models';

@Injectable({ providedIn: 'root' })
export class PublisherSearchService {
    constructor(
        public http: HttpClient,
        public envService: EnvironmentService,
    ) {}

    getJobId(posId: string, publisherId: string): Observable<JobId> {
        const jobIdSearchUrl = `${this.envService.apiUrl}/consumer/locations/${posId}/pages/find-matches/non-blocking`;

        let params = new HttpParams();
        params = params.set('publisher_id', publisherId);

        return this.http.get<JobId>(jobIdSearchUrl, { params });
    }

    search(posId: string, jobId: string): Observable<PublisherSearch> {
        const publisherSearchUrl = `${this.envService.apiUrl}/consumer/locations/${posId}/pages/find-matches/state/${jobId}`;

        return this.http.get<PublisherSearch>(publisherSearchUrl);
    }

    searchAtPublisher(
        locationId: string,
        publisherId: string,
        config?: {
            // config param keys must match api docs
            q?: string;
            name?: string;
            city?: string;
            search_gmb_account?: boolean;
            refresh_cache?: boolean;
            account_id?: string;
            business_manager_id?: string;
        },
    ): Observable<any> {
        const searchAtPublisherUrl = `${this.envService.apiUrl}/consumer/locations/${locationId}/pages/find-matches`;
        let params = new HttpParams().set('publisher_id', publisherId);

        forOwn(config, (value, key) => {
            if (value) {
                params = params.set(key, value.toString());
            }
        });

        return this.http.get(searchAtPublisherUrl, { params });
    }

    poll(locationId: string, publisherId: string): Observable<any> {
        let intervalIterator = 100;
        return this.getJobId(locationId, publisherId).pipe(
            mergeMap(jobQueueData => {
                intervalIterator += 200;
                return observableInterval(intervalIterator).pipe(
                    mergeMap(data => this.search(locationId, jobQueueData['job-id'])),
                    filter(data => data.state === 'SUCCESS' || data.state === 'FAILURE'),
                    take(1),
                    timeout(120000),
                );
            }),
        );
    }
}
