import { Action } from '@ngrx/store';

import { Photo } from '@solocal-manager/sirius/support/base-models';
import { PaginationDTO } from '@solocal-manager/sirius/support/base-models';
import { Video } from '@solocal-manager/sirius/support/base-models';

export enum GalleryActionTypes {
    GET_IMAGE_LIST = '[GET_IMAGE_LIST_GALLERY] get image list',
    GET_IMAGE_LIST_SUCCESS = '[GET_IMAGE_LIST_SUCCESS_GALLERY] get image list success',
    RESET_IMAGE_LIST = '[RESET_IMAGE_LIST_GALLERY] reset image list',

    GET_UPLOADED_VIDEO_LIST = '[GET_UPLOADED_VIDEO_LIST_GALLERY] get uploaded video list',
    GET_UPLOADED_VIDEO_LIST_SUCCESS = '[GET_UPLOADED_VIDEO_LIST_SUCCESS_GALLERY] get uploaded video list success',
    RESET_UPLOADED_VIDEO_LIST = '[RESET_UPLOADED_VIDEOGE_LIST_GALLERY] reset uploaded video list',
    PATCH_UPLOADED_VIDEO = '[PATCH_UPLOADED_VIDEO] patch uploaded video',
    PATCH_UPLOADED_VIDEO_SUCCESS = '[PATCH_UPLOADED_VIDEO_SUCCESS] patch uploaded video success',
    DELETE_UPLOADED_VIDEO = '[DELETE_UPLOADED_VIDEO] delete uploaded video',
    DELETE_UPLOADED_VIDEO_SUCCESS = '[DELETE_UPLOADED_VIDEO_SUCCESS] delete uploaded video success',
}

export class GetImageList implements Action {
    readonly type = GalleryActionTypes.GET_IMAGE_LIST;

    constructor(public payload: any) {}
}

export class GetImageListSuccess implements Action {
    readonly type = GalleryActionTypes.GET_IMAGE_LIST_SUCCESS;

    constructor(public payload: PaginationDTO<Photo>) {}
}

export class ResetImageList implements Action {
    readonly type = GalleryActionTypes.RESET_IMAGE_LIST;

    constructor() {}
}

export class GetUploadedVideoList implements Action {
    readonly type = GalleryActionTypes.GET_UPLOADED_VIDEO_LIST;

    constructor(
        public params: {
            companyId?: string;
            pageSize?: number;
            pageNumber?: number;
        },
    ) {}
}

export class GetUploadedVideoListSuccess implements Action {
    readonly type = GalleryActionTypes.GET_UPLOADED_VIDEO_LIST_SUCCESS;

    constructor(public result: PaginationDTO<Video>) {}
}

export class ResetUploadedVideoList implements Action {
    readonly type = GalleryActionTypes.RESET_UPLOADED_VIDEO_LIST;

    constructor() {}
}

export class PatchUploadedVideo implements Action {
    readonly type = GalleryActionTypes.PATCH_UPLOADED_VIDEO;

    constructor(
        public params: {
            uploadedVideoId: string;
            title?: string;
            description?: string;
            pageNumber?: number; // needed for fetching the list after update
            pageSize?: number; // needed for fetching the list after update
        },
    ) {}
}

export class PatchUploadedVideoSuccess implements Action {
    readonly type = GalleryActionTypes.PATCH_UPLOADED_VIDEO_SUCCESS;

    constructor(
        public result: {
            video: Video;
            pageNumber?: number; // needed for fetching the list after update
            pageSize?: number; // needed for fetching the list after update
        },
    ) {}
}

export class DeleteUploadedVideo implements Action {
    readonly type = GalleryActionTypes.DELETE_UPLOADED_VIDEO;

    constructor(
        public params: {
            uploadedVideoId: string;
            pageNumber?: number; // needed for fetching the list after update
            pageSize?: number; // needed for fetching the list after update
        },
    ) {}
}

export class DeleteUploadedVideoSuccess implements Action {
    readonly type = GalleryActionTypes.DELETE_UPLOADED_VIDEO_SUCCESS;

    constructor(
        public result: {
            pageNumber?: number; // needed for fetching the list after update
            pageSize?: number; // needed for fetching the list after update
        },
    ) {}
}

export type GalleryActionsUnion =
    | GetImageList
    | GetImageListSuccess
    | ResetImageList
    | GetUploadedVideoList
    | GetUploadedVideoListSuccess
    | ResetUploadedVideoList
    | PatchUploadedVideo
    | PatchUploadedVideoSuccess
    | DeleteUploadedVideo
    | DeleteUploadedVideoSuccess;
