<div [formGroup]="formRepeatable">
    <div formArrayName="data">
        <div class="row" [formGroupName]="i" *ngFor="let category of formData.controls; let i = index">
            <div class="form-group col-md-10 col-sm-10 col-10">
                <label class="required-label" *ngIf="i === 0" for="inputCategory">{{
                    'Categories PagesJaunes' | translate
                }}</label>
                <input
                    formControlName="name"
                    (click)="setActiveInput(i)"
                    [typeahead]="categories$"
                    [typeaheadWaitMs]="100"
                    [typeaheadMinLength]="2"
                    (typeaheadLoading)="changeTypeaheadLoading($event)"
                    (typeaheadOnSelect)="typeaheadOnSelect($event)"
                    typeaheadOptionsLimit="10"
                    typeaheadOptionField="name"
                    class="form-control"
                />
            </div>
            <input type="hidden" class="form-control" formControlName="id" />
            <div class="form-group col-md-2 col-sm-2 col-2">
                <button
                    type="button"
                    [ngClass]="{ 'allign-button': i === 0 }"
                    *ngIf="formData.controls.length > 1"
                    class="btn btn-primary remove-field-button"
                    (click)="removeInput(i)"
                >
                    X
                </button>
            </div>
        </div>
        <div *ngIf="typeaheadLoading === true">
            <i class="glyphicon glyphicon-refresh ng-hide"></i>
        </div>
        <div *ngIf="typeaheadNoResults === true">
            <i class="glyphicon glyphicon-remove"></i>{{ 'No Results Found' | translate }}
        </div>
        <div *ngIf="isProspect && formData.controls.length >= 3">
            {{ 'You can only enter a maximum of three activities.' | translate }}
        </div>
        <div class="error-text" *ngIf="formData.controls?.length >= maxProspectPjActivities && isProspect">
            {{ 'To be able to add more activities, please contact Solocal customer service.' | translate }}
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-12 phone-number-err-msg-holder">
        <app-input-error-messages [control]="control" [messageTypes]="['oneCategoryNeeded']"></app-input-error-messages>
    </div>
</div>
<div class="row" *ngIf="!isProspect || (isProspect && formData.controls?.length < 3)">
    <div class="col-md-12">
        <button class="btn btn-primary remove-field-button" (click)="addInput()">
            {{ 'Add Categories PagesJaunes' | translate }}
        </button>
    </div>
</div>
