<div class="image-upload-input">
    <div class="dropbox" [ngClass]="{ progress: uploading }">
        <input type="file" (change)="selectFiles($event.target.files)" accept="image/*" [multiple]="multiple" />
        <p *ngIf="!uploading">
            {{ 'Drag your file(s) here to begin or click to browse' | translate }}
        </p>
        <p *ngIf="uploading">Uploading {{ filesToUpload.length }} file(s)...</p>
    </div>
    <div class="upload-progress" *ngIf="uploading">
        <div *ngFor="let file of filesToUpload">
            <div class="progress">
                <progressbar class="progress-striped" [value]="uploadProgress.get(file.name)" type="info">
                    {{ file.name + ' ' + uploadProgress.get(file.name) + '%' }}
                </progressbar>
            </div>
        </div>
    </div>
    <div class="uploaded-image-list" *ngIf="!hideUploaded">
        <div class="uploaded-image" *ngFor="let image of uploadedImages">
            <div class="image-source">
                <img [src]="image.thumbnail" alt="uploaded image" />
            </div>
            <div>
                <strong>{{ image.title }}</strong>
            </div>
        </div>
    </div>
</div>
