import { IOfferConfig } from '../models';

export const sectionVideoConfig: IOfferConfig = {
    // VI: {
    //     label: 'Vidéo',
    //     name: '',
    //     logo: ''
    // },
    /*
    // disabled because no more active VC
    VC: {
        label: 'Vidéo Comment Faire',
        name: '',
        logo: ''
    },*/
    VE: {
        label: 'Vidéo Platinum',
        name: '',
        logo: '',
        disableCRM: false,
    },
    // FI: {
    //     label: 'Vidéo Premium',
    //     name: '',
    //     logo: ''
    // },
    // IN: {
    //     label: 'Vidéo Interview',
    //     name: '',
    //     logo: ''
    // },
    VS: {
        label: 'Vidéo Reportage',
        name: '',
        logo: '',
        disableCRM: false,
    },
    VF: {
        label: 'Vidéo Spot Pub',
        name: '',
        logo: '',
        disableCRM: false,
    },
};

export const articlesVideoConfig = {
    VS: 'VI',
    VF: 'FI',
    VE: 'IN',
};
