import { Component } from '@angular/core';

import { WpmHereMapComponent } from '@solocal-manager/sirius/core/core';

@Component({
    selector: 'slm-app-locations-map',
    templateUrl: './locations-map.component.html',
    styleUrls: ['./locations-map.component.scss'],
})
export class LocationsMapComponent extends WpmHereMapComponent {}
