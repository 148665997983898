import { IOfferConfig } from '../models';

export const sectionWebsiteConfig: IOfferConfig = {
    'SOC-SC': {
        label: 'Site Internet',
        name: '',
        logo: null,
        disableCRM: false,
    },
    'SOC-IN': {
        label: 'Site initial',
        name: '',
        logo: null,
        disableCRM: false,
    },
    SR: {
        label: 'Site Privilège',
        name: '',
        logo: 'picto_site_privilege.svg',
        disableCRM: false,
    },
    'SOC-SP': {
        label: 'Site Premium',
        name: '',
        logo: 'picto_site_premium.svg',
        disableCRM: false,
    },
    'SOC-SPI': {
        label: 'Site Présence',
        name: '',
        logo: 'picto_site_essentiel.svg',
        disableCRM: false,
    },
    'SOC-SF': {
        label: 'Site Vitrine First',
        name: '',
        logo: null,
        disableCRM: true,
    },
    'SOC-EG': {
        label: 'Site Essentiel',
        name: '',
        logo: 'picto_site_essentiel.svg',
        disableCRM: false,
    },
    'SOC-SP-2': {
        label: 'Site Premium',
        name: '',
        logo: 'picto_site_premium.svg',
        disableCRM: true,
    },
    PB: {
        label: 'Site Présence',
        name: '',
        logo: null,
        disableCRM: false,
    },
    SV: {
        label: 'Site Visibilité',
        name: '',
        logo: null,
        disableCRM: false,
    },
    PV: {
        label: 'Pack Visibilité Internet',
        name: '',
        logo: null,
        disableCRM: false,
    },
    KTSPI: {
        label: 'Site Présence',
        name: '',
        logo: 'picto_site_essentiel.svg',
        disableCRM: false,
    },
};
