import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LocationsActions, LocationsSelectors } from '@slm/location/state';
import { MultiSearchActions, WpmLocationListComponent } from '@solocal-manager/sirius/core/core';
import { isEqual } from 'lodash-es';
import { NGXLogger } from 'ngx-logger';
import { combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';

import { AdminUiService } from '../../services';

@Component({
    selector: 'slm-location-list',
    templateUrl: './location-list.component.html',
    styleUrls: ['./location-list.component.scss'],
})
export class LocationListComponent extends WpmLocationListComponent implements OnInit, OnDestroy {
    showMoveLocation: boolean;
    destroyed$ = new Subject<boolean>();
    resetSearch$: Subject<void> = new Subject();

    constructor(
        public store: Store<any>,
        public router: Router,
        public route: ActivatedRoute,
        public logger: NGXLogger,
        public update$: Actions,
        public uiService: AdminUiService,
    ) {
        super(store, logger);

        this.uiService.onCompanyPage.subscribe(active => {
            this.showMoveLocation = active && this.uiService.mayMoveLocation();

            if (active === true) {
                const routeParams = this.route.snapshot.params || {};
                if (routeParams.companyId) {
                    this.store.dispatch({
                        type: MultiSearchActions.RESET_MULTI_SEARCH_SELECTED,
                    });

                    this.store.dispatch({
                        type: MultiSearchActions.RESET_MULTI_SEARCH,
                    });

                    this.refreshLocations(routeParams.companyId);
                }
            }
        });
    }

    ngOnInit() {
        this.uiService.locationChanged.subscribe((change: boolean) => {
            if (change) {
                this.loadSelectedLocation();
            } else {
                super.ngOnInit();
            }
        });

        this.uiService.onMoveLocationSuccess
            .pipe(
                map((success: any) => success.data || {}),
                takeUntil(this.destroyed$),
            )
            .subscribe(location => {
                if (location && location.company_id) {
                    this.refreshLocations(location.company_id);
                }
            });
    }

    loadSelectedLocation() {
        combineLatest([
            this.store.select(LocationsSelectors.getSelectedLocation),
            this.store.select(LocationsSelectors.getSelectedId),
        ])
            .pipe(
                filter(([location, locationId]) => location?.id === locationId),
                distinctUntilChanged(isEqual),
                takeUntil(this.isDestroyed$),
            )
            .subscribe(([location, locationId]) => {
                if (location) {
                    this.locationList = [{ ...location }];
                }
            });
    }

    gotoCompany() {
        this.resetSearch$.next(undefined);
        const companyPath = this.router.url.replace(/\/location.*/, '/location');
        this.uiService.locationChanged.next(false);
        this.router.navigate([companyPath]);
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    refreshLocations(companyId: string) {
        this.store.dispatch(
            LocationsActions.loadLocations({
                request: {
                    companyId: 'all', // habilitation
                    config: {
                        pageSize: 20,
                        pageNumber: 1,
                        shouldReturnMeta: true,
                        pageOnly: true,
                    },
                },
            }),
        );
    }
}
