import { KpiActionsUnion, KpiActionTypes } from '../actions';
import { ITotalSalesKpi } from '../models';

export type TotalSalesKpiState = ITotalSalesKpi;

const initialState: TotalSalesKpiState = undefined;

export function totalSalesKpiReducer(state = initialState, action: KpiActionsUnion): TotalSalesKpiState {
    switch (action.type) {
        case KpiActionTypes.GET_TOTAL_SALES_SUCCESS: {
            return action.result;
        }
        default:
            return state;
    }
}
