import { ActionWithPayload, MultiSearchActions } from '../actions';
import { MultiSearch } from '../models';

export type MultiSearchState = MultiSearch[];
export type MultiSearchSelectedState = MultiSearch;

const initialState: MultiSearchState = [];
const initialSelectedState: any = {};

export function multiSearchReducer(state = initialState, action: ActionWithPayload<MultiSearch[]>): MultiSearchState {
    switch (action.type) {
        case MultiSearchActions.GET_MULTI_SEARCH_SUCCESS: {
            return action.payload;
        }
        case MultiSearchActions.RESET_MULTI_SEARCH: {
            return initialState;
        }
        default:
            return state;
    }
}

export function multiSearchSelectedReducer(
    state = initialSelectedState,
    action: ActionWithPayload<MultiSearch>,
): MultiSearchSelectedState {
    switch (action.type) {
        case MultiSearchActions.SET_MULTI_SEARCH_SELECTED: {
            return action.payload;
        }
        case MultiSearchActions.RESET_MULTI_SEARCH_SELECTED: {
            return initialSelectedState;
        }

        default:
            return state;
    }
}
