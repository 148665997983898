import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { AccordionModule } from 'ngx-bootstrap/accordion';

import { WpmLibModule, WpmSharedModule, WpmPartialsModule } from '../core';

import { KpiEffects } from './effects';
import {
    AdminKpiPageComponent,
    KpiErrorStatusDetailComponent,
    KpiNotSuccessfulProvisioningsComponent,
    KpiProvisioningIssueDetailsComponent,
    KpiPublishersErrorComponent,
    KpiTotalErrorComponent,
    KpiTotalSalesComponent,
    KpiTotalUsesComponent,
    KpiWidgetComponent,
    KpiWidgetContainerComponent,
} from './partials';
import { kpiReducers } from './reducers';

@NgModule({
    imports: [
        AccordionModule.forRoot(),
        CommonModule,
        RouterModule,
        TranslateModule,
        StoreModule.forFeature('kpi', kpiReducers),
        EffectsModule.forFeature([KpiEffects]),
        WpmLibModule,
        WpmPartialsModule,
        WpmSharedModule,
    ],
    declarations: [
        AdminKpiPageComponent,
        KpiWidgetComponent,
        KpiTotalErrorComponent,
        KpiPublishersErrorComponent,
        KpiErrorStatusDetailComponent,
        KpiWidgetContainerComponent,
        KpiTotalSalesComponent,
        KpiNotSuccessfulProvisioningsComponent,
        KpiProvisioningIssueDetailsComponent,
        KpiTotalUsesComponent,
    ],
    exports: [KpiWidgetContainerComponent],
})
export class KpiModule {}
