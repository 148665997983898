import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@slm/shared/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class PublisherStatusService {
    constructor(
        public http: HttpClient,
        public envService: EnvironmentService,
    ) {}

    updatePublisherSettings(locationId: string, publisherId: string, state: string): Observable<any> {
        const requestUrl = `${this.envService.apiUrl}/consumer/locations/${locationId}/publishers-settings`;

        const body = {
            publisher_id: publisherId,
            state,
        };

        return this.http.post<any>(requestUrl, body).pipe(map((res: any) => res.data));
    }
}
