import { Injectable } from '@angular/core';

import { IDataLayerEventData, IDataLayerPathData, IDataLayerVariables } from '@solocal-manager/sirius/core/models';
import { PageDisplayEvent } from '../models/tag-manager.model';

@Injectable({ providedIn: 'root' })
export class TagManagerMockService {
    constructor() {
        this.doInit();
    }

    private doInit(): void {
        return;
    }

    // data layer variables
    public dlVariables(variables: IDataLayerVariables): void {
        return;
    }

    // only for CTA event
    public uiEvent(gtmEvent: IDataLayerEventData): void {
        return;
    }

    // only for livechat event
    public livechatEvent(gtmEvent: IDataLayerVariables): void {
        return;
    }

    // routing based event
    public routerEvent(gtmEvent: IDataLayerPathData): void {
        return;
    }

    public pageDisplayEvent(gtmEvent: PageDisplayEvent): void {
        return;
    }

    public performanceEvent(name: string, duration?: number): void {
        return;
    }
}
