<div class="results-info">
    <div *ngIf="state === searchState.pending" class="alert alert-info">
        <p>{{ 'searching ...' | translate }}</p>
    </div>
    <div *ngIf="state === searchState.failure" class="alert alert-danger">
        {{ 'Search state:' | translate }} {{ state }}
    </div>
    <div *ngIf="state === searchState.success && count > 0" class="alert alert-success">
        {{ 'Search state:' | translate }} {{ state }}
    </div>
    <div *ngIf="state === searchState.success && !count" class="alert alert-warning">
        {{ 'There are no search results for this publisher.' | translate }}
    </div>
    <div *ngIf="message" class="alert alert-info">
        {{ message }}
    </div>
    <div *ngIf="error?.length > 0">
        <p>{{ 'Please copy and report the following error to the support team:' | translate }}</p>
        <pre>{{ error }}</pre>
    </div>
</div>
