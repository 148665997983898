import { ActionWithPayload, PublisherStatisticsActions } from '../actions';
import { TotalPublisherStatistics } from '../models';

export type PublisherStatisticsState = TotalPublisherStatistics;

const initialState: PublisherStatisticsState = {} as TotalPublisherStatistics;

export function publisherStatisticsReducer(
    state = initialState,
    action: ActionWithPayload<PublisherStatisticsState>,
): PublisherStatisticsState {
    switch (action.type) {
        case PublisherStatisticsActions.GET_PUBLISHER_STATISTICS_SUCCESS: {
            return action.payload;
        }
        default:
            return state;
    }
}
