import { createReducer, on } from '@ngrx/store';

import {
    loadCiamContact,
    loadCiamContactFail,
    loadCiamContactSuccess,
    mutateCiamContact,
} from '@solocal-manager/sirius/core/actions';
import { ICiamContactV2 } from '@solocal-manager/sirius/core/models';

export interface ICiamContactState {
    loaded: boolean;
    loading: boolean;
    info: ICiamContactV2;
}

export const initialState: ICiamContactState = {
    loaded: false,
    loading: false,
    info: undefined,
};

export const ciamContactReducer = createReducer(
    initialState,
    on(loadCiamContact, state => ({
        ...state,
        loading: true,
    })),
    on(loadCiamContactSuccess, mutateCiamContact, (state, { payload }) => ({
        ...state,
        info: payload,
        loading: false,
        loaded: true,
    })),
    on(loadCiamContactFail, state => ({
        ...state,
        loading: false,
        loaded: false,
    })),
);
