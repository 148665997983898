import { createReducer, on } from '@ngrx/store';

import { MenuCategory } from '@solocal-manager/sirius/support/base-models';

import { getMenuCardsCategoriesSuccess } from './menu-category.actions';

export const MenuCategory_FEATURE_KEY = 'menucategory';

export type MenuCardsCategoryState = MenuCategory[];

export const initialState: MenuCardsCategoryState = [];

export const menuCardsCategoriesReducer = createReducer(
    initialState,
    on(getMenuCardsCategoriesSuccess, (state, action) => action.result),
);
