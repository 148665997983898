import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@slm/shared/environment';
import { AddressGeoInfo } from '@solocal-manager/sirius/core/models';
import { Address, IGooglePlace } from '@solocal-manager/sirius/support/base-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class GeocodeService {
    constructor(
        public http: HttpClient,
        public envService: EnvironmentService,
    ) {}

    fetchGeoInfo(addressStr: string): Observable<AddressGeoInfo[]> {
        const requestUrl = `${this.envService.apiUrl}/external/geocode/`;
        return this.http.post(requestUrl, { address: addressStr }).pipe(
            map((result: any) => {
                if (result.status === 'OK') {
                    return result.results;
                }
                return [];
            }),
        );
    }

    getAddressStr(address: Address): string {
        const addressStr =
            `${!address.house_number ? '' : address.house_number + ' '}` +
            `${address.address_line1}, ${address.zip_code} ${address.city}, ${address.country}`;
        return addressStr.replace(/ +(?= )/g, '');
    }

    getGooglePlaceIds(query: string, countryCode: string, sessionToken: string): Observable<IGooglePlace[]> {
        const requestUrl = `${this.envService.apiUrl}/external/google-placeid-lookup/`;
        const params = {
            q: query,
            country: countryCode,
            'session-token': sessionToken,
        };
        return this.http.get<IGooglePlace[]>(requestUrl, { params }).pipe(
            map(result => {
                if (result && result instanceof Array) {
                    return result;
                }
                return [];
            }),
        );
    }
}
