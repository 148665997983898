import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';

import { PublisherConnectService, PublisherSearchService } from '@solocal-manager/sirius/core/core';
import { PageMatches } from '@solocal-manager/sirius/core/models';
import { Location } from '@solocal-manager/sirius/support/base-models';

import { publisherSearchStates } from '../../config';

@Component({
    selector: 'slm-publisher-connect-website',
    templateUrl: './publisher-connect-website.component.html',
    styleUrls: ['./publisher-connect-website.component.scss'],
})
export class PublisherConnectWebsiteComponent implements OnChanges, OnDestroy {
    websiteConnectionSubscribtion: any;
    pollSubscribtion: any;
    publisherSearchStates = publisherSearchStates;
    publisherSearchResults: any = [];
    claimed = false;
    connectionMessage: string;
    state: string;

    @Input() location: Location;
    @Input() publisherId: string;

    @Output() closeModal: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        public logger: NGXLogger,
        public publisherConnectService: PublisherConnectService,
        public publisherSearchService: PublisherSearchService,
        public store: Store<any>,
        public ref: ChangeDetectorRef,
    ) {}

    ngOnChanges() {
        this.publisherSearchResults = [];
        this.state = this.publisherSearchStates.pending;
        this.connectionMessage = '';

        this.pollSubscribtion = this.publisherSearchService.poll(this.location.id, this.publisherId).subscribe(res => {
            if (res) {
                this.publisherSearchResults = res.data;
                this.connectionMessage = res.message;
                this.state = res.state;
            }

            this.ref.detectChanges();
        });
    }

    connect(publisherResult: PageMatches) {
        const { publisher_page_id, publisher_sub_type } = publisherResult;
        this.claimed = false;

        this.logger.debug('<PublisherConnectWebsiteComponent> connect: ', publisher_page_id);

        this.websiteConnectionSubscribtion = this.publisherConnectService
            .connect(this.location.id, this.publisherId, publisher_page_id, {
                publisher_sub_type,
            })
            .subscribe(
                res => {
                    if (res) {
                        this.closeModal.emit(true);
                    }
                },
                err => {
                    this.logger.debug('<PublisherConnectWebsiteComponent> connect error:', err);
                },
            );
    }

    onClaimed(isClaimed) {
        this.claimed = isClaimed;
    }

    onPageClaimed($event) {
        if ($event === true) {
            this.closeModal.emit($event);
        }
    }

    get withPublisherSubType(): boolean {
        return (
            this.publisherSearchResults &&
            this.publisherSearchResults.find((result: PageMatches) => result.publisher_sub_type)
        );
    }

    ngOnDestroy() {
        if (this.websiteConnectionSubscribtion) {
            this.websiteConnectionSubscribtion.unsubscribe();
        }

        if (this.pollSubscribtion) {
            this.pollSubscribtion.unsubscribe();
        }
    }
}
