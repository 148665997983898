<div class="tabs-container">
    <tabset #toolsTabs active="-1">
        <tab
            heading="{{ 'Edit' | translate }}"
            [customClass]="getCustomClass('locationEdit')"
            [disabled]="!tabsConfig.edit"
            (select)="switchRoute('locationEdit')"
        >
            <div *ngIf="ready" class="tab-content-inner">
                <slm-location-edit
                    [publisherStatus]="setPublisherStatus"
                    [location]="selectedLocation"
                    [submitLocation]="submitLocationEvent"
                    [readonly]="locationReadonly"
                    (isFormValid)="checkFormValidity($event)"
                    (locationSubmited)="submitLocation($event)"
                ></slm-location-edit>
            </div>
        </tab>
        <tab
            heading="{{ 'Publisher Status' | translate }}"
            [customClass]="getCustomClass('publisherStatus')"
            [disabled]="!tabsConfig['publisher-status']"
            (select)="switchRoute('publisherStatus')"
            (deselect)="deselectRoute($event)"
        >
            <div *ngIf="ready" class="tab-content-inner">
                <slm-app-publisher-status-list
                    [location]="selectedLocation"
                    [refresh]="refreshStatusList"
                    (publisherStatusSelected)="getStatus($event)"
                    (busy)="isPublisherStatusRefresh = $event"
                >
                </slm-app-publisher-status-list>
            </div>
        </tab>
        <tab
            *ngIf="showProdTab"
            heading="{{ 'Production' | translate }}"
            [customClass]="getCustomClass('production')"
            (select)="switchRoute('production')"
            [disabled]="!tabsConfig.production"
        >
            <div *ngIf="ready" class="tab-content-inner">
                <slm-on-behalf [location]="selectedLocation"></slm-on-behalf>
                <slm-app-support-platforms [location]="selectedLocation"></slm-app-support-platforms>
                <app-production-information [location]="selectedLocation"></app-production-information>
            </div>
        </tab>
    </tabset>
    <app-submit-button *ngIf="isLocationEditEnabled()" (clicked)="submitLocation($event)" [formValid]="!formValid">
    </app-submit-button>

    <div class="refresh" *ngIf="activeTabName === 'publisher-status'">
        <span
            [class.rotate]="isPublisherStatusRefresh"
            class="glyphicon glyphicon-refresh rotate"
            aria-hidden="true"
            (click)="doRefreshStatusList()"
        ></span>
    </div>
</div>
