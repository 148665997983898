import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TooltipDirective } from 'ngx-bootstrap/tooltip';
import { NGXLogger } from 'ngx-logger';
// models
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { getPublisherStatus } from '@solocal-manager/sirius/core/core';
import {
    DeletePage,
    PatchPage,
    PostPageDisconnect,
    PublisherConnectService,
    PublisherStatusService,
} from '@solocal-manager/sirius/core/core';
import { PublisherStatus } from '@solocal-manager/sirius/core/models';
import { PutChatConfig } from '@solocal-manager/sirius/data-access-chat/actions';
import { ChatConfig, IIntegration } from '@solocal-manager/sirius/data-access-chat/models';
import { getPutChatConfigState } from '@solocal-manager/sirius/data-access-chat/reducers/chat.reducer';
import { Location } from '@solocal-manager/sirius/support/base-models';

// configuration
import { connectablePublishers } from '../../config/connectable-publishers.config';
import { publisherStates } from '../../config/publisher-states.config';

// actions

// services

const CLOSE_TOOLTIP_DELAY = 800;

@Component({
    selector: '[slm-app-publisher-status-list-item]',
    templateUrl: './publisher-status-list-item.component.html',
    styleUrls: ['./publisher-status-list-item.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PublisherStatusListItemComponent implements OnInit {
    connectablePublishers = connectablePublishers;
    publisherStates = publisherStates;

    isSelected: boolean;
    tooltipOpened = false;
    chatConfigStateLoading = false;

    publisherETAs: any = {};
    newOfferPacks = ['pack0', 'pack1', 'pack2'];
    @Input() location: Location;
    @Input()
    publisherStatus: PublisherStatus;

    @Input()
    selfOnboardingFlag: string;

    @Input()
    chatConfig: ChatConfig;

    @Input()
    canDisconnect: boolean;

    @Input()
    canConnect: boolean;

    @Input()
    navadsRowsCount: number;

    @Input()
    navadsDisconnect: boolean;
    @Output()
    publisherId: EventEmitter<string> = new EventEmitter<string>();
    @Output()
    publisherStatusSelected: EventEmitter<PublisherStatus> = new EventEmitter<PublisherStatus>();
    @Output()
    ableToChangeConnection: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    disconnection: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('pop')
    pop: TooltipDirective;

    @ViewChild('disconnectModal')
    public disconnectModal: ModalDirective;
    private destroyed$ = new Subject<void>();

    constructor(
        public publisherConnectService: PublisherConnectService,
        public publisherStatusService: PublisherStatusService,
        public store: Store<any>,
        public logger: NGXLogger,
        public translate: TranslateService,
    ) {}

    ngOnInit(): void {
        this.setETAs();
        this.translate.onLangChange.subscribe(languageChanged => {
            if (languageChanged) {
                this.setETAs(languageChanged.lang);
            }
        });

        this.store
            .pipe(select(getPutChatConfigState), takeUntil(this.destroyed$))
            .subscribe((chatConfigStateLoading: boolean) => {
                this.chatConfigStateLoading = chatConfigStateLoading;
            });
    }

    get isNewOffer(): boolean {
        const pack = this.location?.custom_data.pack;
        return pack ? this.newOfferPacks.includes(pack.id) : false;
    }

    get canToggleSwitch(): boolean {
        return (
            !this.shouldDisableActions &&
            this.publisherConnectable() &&
            (this.publisherStatus.state === publisherStates.notConnected ||
                this.publisherStatus.state === publisherStates.doNotConnect)
        );
    }

    get shouldDisableActions(): boolean {
        return this.isNewOffer && this.publisherStatus.publisher_id === 'pagesjaunes';
    }

    setETAs(lang?: string) {
        lang = lang ? lang : this.translate.currentLang;
        moment.relativeTimeThreshold('d', 52);
        this.publisherETAs = {
            waze_navads: moment.duration(51, 'days').locale(lang).humanize(),
            tomtom: moment.duration(4, 'months').locale(lang).humanize(),
            navmii: moment.duration(25, 'days').locale(lang).humanize(),
            foursquare: moment.duration(25, 'days').locale(lang).humanize(),
            uber_navads: moment.duration(51, 'days').locale(lang).humanize(),
            tripadvisor: moment.duration(1, 'days').locale(lang).humanize(),
        };
    }

    publisherConnectable(): boolean {
        const publisherStatus = this.publisherStatus;
        return (
            this.connectablePublishers[publisherStatus.publisher_id] === 'postcard' ||
            this.connectablePublishers[publisherStatus.publisher_id] === 'list' ||
            this.connectablePublishers[publisherStatus.publisher_id] === 'click'
        );
    }

    triggerConnect(publisherId: string): void {
        this.logger.debug('<PublisherStatusListItemComponent> triggerConnect', publisherId);
        this.publisherId.emit(publisherId);
    }

    triggerRefresh(publisherStatus: PublisherStatus): void {
        this.logger.debug('<PublisherStatusListItemComponent> triggerRefresh for this status: ', publisherStatus);

        if (publisherStatus.page_id) {
            this.store.dispatch(
                new PatchPage({
                    pageId: publisherStatus.page_id,
                }),
            );
        }
    }

    canRefresh(publisherStatus: PublisherStatus): boolean {
        // allow Active/Pending/Error to be refreshed
        return (
            !this.shouldDisableActions &&
            (publisherStatus.state === publisherStates.active ||
                publisherStatus.state === publisherStates.pending ||
                publisherStatus.state === publisherStates.error)
        );
    }

    customerAgreed(): boolean {
        return this.publisherStatus.state !== publisherStates.doNotConnect;
    }

    openModal(): void {
        if (this.publisherStatus.publisher_id === 'gmb' || this.publisherStatus.publisher_id === 'facebook') {
            this.disconnectModal.show();
        } else {
            this.disconnect();
        }
    }

    disconnect() {
        if (this.publisherStatus.publisher_id === 'gmb' || this.publisherStatus.publisher_id === 'facebook') {
            this.disconnectModal.hide();
        }
        if (this.publisherStatus.page_id && this.publisherStatus.publisher_id === 'gmb') {
            this.publisherStatus.state = 'not_connected';
            this.store.dispatch(
                new PostPageDisconnect({
                    pageId: this.publisherStatus.page_id,
                }),
            );
        } else {
            this.store.dispatch(
                new DeletePage({
                    pageId: this.publisherStatus.page_id,
                    location: this.location,
                }),
            );
        }
        this.disconnection.emit({
            locationId: this.location.id,
            publisherId: this.publisherStatus.publisher_id,
        });
    }

    togglePublisherDoNotConnect(): void {
        this.logger.debug(
            '<PublisherStatusListItemComponent> toggle this state -> state: ',
            this.publisherStatus.state,
        );

        if (this.publisherStatus.state === publisherStates.notConnected) {
            this.updatePublisherSettings(publisherStates.doNotConnect);
        } else if (this.publisherStatus.state === publisherStates.doNotConnect) {
            this.updatePublisherSettings(publisherStates.notConnected);
        }
    }

    updatePublisherSettings(statusToUpdate): void {
        this.logger.debug(
            '<PublisherStatusListItemComponent> updatePublisherSettings -> statusToUpdate: ',
            statusToUpdate,
        );

        this.publisherStatusService
            .updatePublisherSettings(this.location.id, this.publisherStatus.publisher_id, statusToUpdate)
            .subscribe(publisherSettingsResponse => {
                this.publisherStatus.state = publisherSettingsResponse.state;
                this.customerAgreed();

                this.store.dispatch(getPublisherStatus({ params: { location: this.location } }));
            });
    }

    editLocation(publisherStatus: PublisherStatus): void {
        this.publisherStatusSelected.emit(publisherStatus);
    }

    onStatusMouseLeave(): void {
        setTimeout(() => {
            if (!this.tooltipOpened) {
                this.pop.hide();
            }
        }, CLOSE_TOOLTIP_DELAY);
    }

    onTooltipMouseEnter(): void {
        this.tooltipOpened = true;
    }

    onTooltipMouseLeave(): void {
        setTimeout(() => {
            this.tooltipOpened = false;
            this.pop.hide();
        }, CLOSE_TOOLTIP_DELAY);
    }

    canChangeConnection(): boolean {
        const conCond = { pagesjaunes: true, gmb: true };
        const canChange =
            !this.shouldDisableActions &&
            conCond[this.publisherStatus.publisher_id] &&
            this.customerAgreed() &&
            this.publisherStatus.state !== publisherStates.notConnected;

        this.ableToChangeConnection.emit({
            canChange,
            publisherId: this.publisherStatus.publisher_id,
        });

        return canChange;
    }

    canView(): boolean {
        return this.publisherStatus.page_url && this.publisherStatus.page_url !== '';
    }

    get navadsPublisher(): boolean {
        return !!this.publisherStatus.proxy_publisher && this.publisherStatus.proxy_publisher === 'uberall';
    }

    /**
     * Activate or deactivated chat for a publisher
     * @param {string} status new chat status
     */
    toggleChat(status): void {
        if (this.chatIntegration) {
            this.chatIntegration.status = status;
        } else {
            this.createChatIntegration();
        }
        // We only change data for the selected integration
        // Can cause error due to oauth status for messenger (oauth is not accepted as input status)
        const _chatConfig = { ...this.chatConfig };
        _chatConfig.integrations = [this.chatIntegration];
        this.store.dispatch(
            PutChatConfig({
                params: {
                    epj: this.location.partner_id,
                    chatConfig: _chatConfig,
                },
            }),
        );
    }

    /**
     * Create chat integration - if needed create chat config
     */
    createChatIntegration(): void {
        let integration_type = this.publisherStatus.publisher_id;
        if (this.publisherStatus.publisher_id === 'pagesjaunes') {
            integration_type = 'web';
        }
        // TODO: Change if messenger is add as publisher
        if (this.publisherStatus.publisher_id === 'facebook') {
            integration_type = 'messenger';
        }
        if (!this.chatConfig) {
            this.chatConfig = {
                status: 'active',
                integrations: [
                    {
                        integration_type,
                        status: 'active',
                        options: {},
                    },
                ],
            };
        } else {
            this.chatConfig.integrations[this.chatConfig.integrations.length] = {
                integration_type,
                status: 'active',
                options: {},
            };
        }
    }

    get navadsColumnHidden(): boolean {
        return this.navadsPublisher && (this.canDisconnect || this.canConnect) && !this.navadsRowsCount;
    }

    isChatPublisher(): boolean {
        return false; //TODO: remove activation/deactivation for the poc
        // const conCond = { pagesjaunes: true, facebook: true };
        // return conCond[this.publisherStatus.publisher_id];
    }

    get chatState(): string {
        const integration = this.chatIntegration;
        // chat has 6 states (active, inactive, ignored, pending, oauth_needed, error)
        //  If chat has never been activate status is inactive - if chat is deactivated -> new status is ignored
        return integration ? (integration.status === 'ignored' ? 'inactive' : integration.status) : 'inactive';
    }

    get chatIntegration(): IIntegration {
        if (!this.chatConfig) {
            return;
        }
        let _publisher = this.publisherStatus.publisher_id;
        if (this.publisherStatus.publisher_id === 'pagesjaunes') {
            _publisher = 'web';
        }
        // TODO: Change if messenger is add as publisher
        if (this.publisherStatus.publisher_id === 'facebook') {
            _publisher = 'messenger';
        }
        return this.chatConfig.integrations.find(integration => integration.integration_type === _publisher);
    }

    canConnectChat(): boolean {
        return (
            (!this.chatState || this.chatState === 'inactive') && this.publisherStatus.state === publisherStates.active
        );
    }
}
