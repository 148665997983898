import { Component, Input, OnInit } from '@angular/core';
import {
    ControlValueAccessor,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';

import { ServiceAreaHelperFacade } from '@solocal-manager/sirius/core/facade';
import { ValidateRepeatableLength } from '@solocal-manager/sirius/core/validators';
import { IGooglePlace } from '@solocal-manager/sirius/support/base-models';
import { Observable, of } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { SubscriptionCloserComponent } from '../../class/subscription-closer-component';
import { serviceAreaConfig } from '../../config';
import { ServiceAreaValidator } from '../validators';

@Component({
    selector: 'slm-wpm-input-service-area',
    template: '',
})
export class WpmInputServiceAreaComponent extends SubscriptionCloserComponent implements ControlValueAccessor, OnInit {
    @Input() set country(country: string) {
        if (country) {
            this.countryCode = country.toLowerCase();
        }
    }

    formServiceArea: UntypedFormGroup;
    areaControl: UntypedFormControl = new UntypedFormControl();
    filteredPlaces$: Observable<IGooglePlace[]>;
    countryCode = 'fr';
    sessionToken: string;
    placesStored: IGooglePlace[] = null;
    businessTypeOptions = serviceAreaConfig.businessTypeOptions;
    maxPlaces = serviceAreaConfig.maxPlaces;

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    propagateChange: any = (): void => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    validateFn: any = (): void => {};

    constructor(
        protected readonly formBuilder: UntypedFormBuilder,
        protected readonly serviceAreaHelper: ServiceAreaHelperFacade,
    ) {
        super();
    }

    ngOnInit() {
        this.serviceAreaHelper.generateSessionToken(this.sessionToken);
        this.buildFormControl();
    }

    get places() {
        return this.formServiceArea.controls.places;
    }

    get businessTypeUnspecified(): boolean {
        return this.formServiceArea.get('business_type').value === this.businessTypeOptions[0].id;
    }

    buildFormControl() {
        this.formServiceArea = this.formBuilder.group({
            business_type: ['', [Validators.required]],
            places: [[]],
        });
        this.formServiceArea.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(controls => {
            this.propagateChange(controls);
        });
    }

    getPlacesByQuery(q: string): Observable<IGooglePlace[]> {
        if (q?.length > 3) {
            return this.serviceAreaHelper.getGooglePlaceIds(q, this.countryCode, this.sessionToken).pipe(
                map((places: IGooglePlace[]) => {
                    // in autocomplete show only place ids which haven't beed chosen yet
                    return places.filter(
                        (place: IGooglePlace) =>
                            !this.places.value
                                .map((chosenPlace: IGooglePlace) => chosenPlace.google_place_id)
                                .includes(place.google_place_id),
                    );
                }),
            );
        }
        return of([]);
    }

    onSelectPlace(place: IGooglePlace) {
        this.places.setValue([...this.places.value, place]);
        this.areaControl.setValue('');
    }

    onBusinessTypeChange() {
        this.handleValidation();
        const currentData = this.formServiceArea.value;
        if (this.businessTypeUnspecified) {
            this.placesStored = this.formServiceArea.value.places;
            currentData.places = [];
        } else if (this.placesStored) {
            currentData.places = this.placesStored;
        }
        this.formServiceArea.controls.places.setValue(currentData.places);
    }

    handleValidation() {
        if (!this.businessTypeUnspecified) {
            this.places.setValidators([
                Validators.required,
                ValidateRepeatableLength.createValidator(serviceAreaConfig.maxPlaces, 'places'),
            ]);
        } else {
            this.places.clearValidators();
        }
        this.places.updateValueAndValidity();
    }

    removePlace(selectedPlace: IGooglePlace) {
        const newVal = this.places.value.filter(
            (place: IGooglePlace) => place.google_place_id !== selectedPlace.google_place_id,
        );
        this.places.setValue(newVal);
    }

    clearPlaces() {
        this.places.setValue([]);
    }

    writeValue(value) {
        setTimeout(() => {
            this.formServiceArea.setValue({
                business_type: value?.business_type || this.businessTypeOptions[0].id,
                places: value?.places || [],
            });
            this.handleValidation();
            this.validateFn = ServiceAreaValidator.create(this.formServiceArea);
        });
    }

    registerOnChange(fn) {
        this.propagateChange = value => fn(value);
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    registerOnTouched() {}

    validate(c: UntypedFormControl) {
        return this.validateFn(c);
    }
}
