import { createAction, props } from '@ngrx/store';

import { ChatConfig, GbmWizard, IChatLocationList, IEpjMultiLocation, IPaginationConversation } from '../models';

export enum ChatActionTypes {
    GET_CHAT_CONFIG = '[GET_CHAT_CONFIG] get chat config',
    GET_CHAT_CONFIG_SUCCESS = '[GET_CHAT_CONFIG_SUCCESS] get chat config success',
    GET_CHAT_CONFIG_ERROR = '[GET_CHAT_CONFIG_ERROR] get chat config error',
    PUT_CHAT_CONFIG = '[PUT_CHAT_CONFIG] PUT chat config',
    PUT_CHAT_CONFIG_SUCCESS = '[PUT_CHAT_CONFIG_SUCCESS] PUT chat config success',
    PUT_CHAT_CONFIG_ERROR = '[PUT_CHAT_CONFIG_ERROR] PUT chat config error',
    GET_CHAT_CONVERSATIONS = '[GET_CHAT_CONVERSATIONS] get chat conversations',
    RESET_CHAT_CONVERSATIONS = '[RESET_CHAT_CONVERSATIONS] reset chat conversations',
    GET_CHAT_CONVERSATIONS_SUCCESS = '[GET_CHAT_CONVERSATIONS_SUCCESS] get chat conversations success',
    GET_CHAT_CONVERSATIONS_ERROR = '[GET_CHAT_CONVERSATIONS_ERROR] get chat conversations error',
    PUT_CHAT_UNREAD_COUNT = '[PUT_CHAT_UNREAD_COUNT] change total unread count',
    PUT_CHAT_TEMPORARY_DEACTIVATION_CONFIG = '[PUT_CHAT_TEMPORARY_DEACTIVATION_CONFIG] PUT chat temporary deactivation config',
    PUT_CHAT_TEMPORARY_DEACTIVATION_CONFIG_SUCCESS = '[PUT_CHAT_TEMPORARY_DEACTIVATION_CONFIG_SUCCESS] PUT  chat temporary deactivation config success',
    PUT_CHAT_TEMPORARY_DEACTIVATION_CONFIG_ERROR = '[PUT_CHAT_TEMPORARY_DEACTIVATION_CONFIG_ERROR] PUT  chat temporary deactivation config error',
    GET_MULTI_LOCATION_CHAT_INFOS = '[GET_MULTI_LOCATION_CHAT_INFOS] GET multi location chat infos',
    GET_MULTI_LOCATION_CHAT_INFOS_SUCCESS = '[GET_MULTI_LOCATION_CHAT_INFOS] GET multi location chat infos success',
    GET_MULTI_LOCATION_CHAT_INFOS_ERROR = '[GET_MULTI_LOCATION_CHAT_INFOS] GET multi location chat infos error',
    PUT_MULTI_CHAT_LOCATIONS_ACTIVATION = '[PUT_CHAT_LOCATIONS_ACTIVATION] PUT multi location activation',
    PUT_MULTI_CHAT_LOCATIONS_REACTIVATION = '[PUT_CHAT_LOCATIONS_REACTIVATION] PUT multi location reactivation',
    PUT_MULTI_CHAT_LOCATIONS_PAUSE = '[PUT_CHAT_LOCATIONS_PAUSE] PUT multi location pause',
    GET_MULTI_CHAT_LOCATIONS = '[GET_MULTI_LOCATION_CHAT] GET multi location chat success',
    POST_GBM_WIZARD = '[POST_GBM_WIZARD] POST gbm wizard form',
    POST_GBM_WIZARD_SUCCESS = '[POST_GBM_WIZARD_SUCCESS] POST gbm wizard form success',
    POST_GBM_WIZARD_ERROR = '[POST_GBM_WIZARD_ERROR] gbm wizard form error',
    GET_GBM_WIZARD = '[GET_GBM_WIZARD] GET gbm wizard form',
    GET_GBM_WIZARD_SUCCESS = '[GET_GBM_WIZARD] GET gbm wizard form success',
    GET_GBM_WIZARD_ERROR = '[GET_GBM_WIZARD] GET gbm wizard form error',
}

export interface Params {
    epj: string;
    page?: number;
    chatConfig?: ChatConfig;
}

export const GetChatConfigError = createAction(ChatActionTypes.GET_CHAT_CONFIG_ERROR, props<{ payload: string }>());

export const GetChatConfig = createAction(ChatActionTypes.GET_CHAT_CONFIG, props<{ params: Params }>());

export const GetChatConfigSuccess = createAction(
    ChatActionTypes.GET_CHAT_CONFIG_SUCCESS,
    props<{ payload: ChatConfig }>(),
);

export const PutChatConfigError = createAction(ChatActionTypes.PUT_CHAT_CONFIG_ERROR, props<{ payload: string }>());

export const PutChatConfig = createAction(
    ChatActionTypes.PUT_CHAT_CONFIG,
    props<{ params: { epj: string; chatConfig: ChatConfig } }>(),
);

export const PutChatConfigSuccess = createAction(
    ChatActionTypes.PUT_CHAT_CONFIG_SUCCESS,
    props<{ payload: ChatConfig }>(),
);

export const GetChatConversationsError = createAction(
    ChatActionTypes.GET_CHAT_CONVERSATIONS_ERROR,
    props<{ payload: string }>(),
);

export const ResetChatConversations = createAction(
    ChatActionTypes.RESET_CHAT_CONVERSATIONS,
    props<{ params: Params }>(),
);

export const GetChatConversations = createAction(ChatActionTypes.GET_CHAT_CONVERSATIONS, props<{ params: Params }>());

export const GetChatConversationsSuccess = createAction(
    ChatActionTypes.GET_CHAT_CONVERSATIONS_SUCCESS,
    props<{ payload: IPaginationConversation }>(),
);

export const PutChatUnreadCount = createAction(ChatActionTypes.PUT_CHAT_UNREAD_COUNT, props<{ payload: number }>());

export const PutChatTemporaryDeactivationConfig = createAction(
    ChatActionTypes.PUT_CHAT_TEMPORARY_DEACTIVATION_CONFIG,
    props<{ params: { epj: string; chatConfig: ChatConfig } }>(),
);

export const PutChatTemporaryDeactivationConfigSuccess = createAction(
    ChatActionTypes.PUT_CHAT_TEMPORARY_DEACTIVATION_CONFIG_SUCCESS,
    props<{ payload: ChatConfig }>(),
);

export const PutChatTemporaryDeactivationConfigError = createAction(
    ChatActionTypes.PUT_CHAT_TEMPORARY_DEACTIVATION_CONFIG_ERROR,
    props<{ payload: string }>(),
);

export const GetMultiLocationChatInfos = createAction(
    ChatActionTypes.GET_MULTI_LOCATION_CHAT_INFOS,
    props<{ params: Params }>(),
);
export const GetMultiLocationChatInfosSuccess = createAction(
    ChatActionTypes.GET_MULTI_LOCATION_CHAT_INFOS_SUCCESS,
    props<{ payload: IChatLocationList }>(),
);
export const GetMultiLocationChatInfosError = createAction(
    ChatActionTypes.GET_MULTI_LOCATION_CHAT_INFOS_ERROR,
    props<{ payload: string }>(),
);

export const GetMultiLocationChatSuccess = createAction(
    ChatActionTypes.GET_MULTI_CHAT_LOCATIONS,
    props<{ payload: IEpjMultiLocation[] }>(),
);

/*
activate locations status of publisher
*/
export const PutMultiLocationChatActivation = createAction(
    ChatActionTypes.PUT_MULTI_CHAT_LOCATIONS_ACTIVATION,
    props<{ payload: ChatConfig }>(),
);

/*
post GBM activation wizard
*/
export const PostGbmWizard = createAction(
    ChatActionTypes.POST_GBM_WIZARD,
    props<{ gbmActivationWizardFormData: GbmWizard }>(),
);

export const PostGbmWizardSuccess = createAction(ChatActionTypes.POST_GBM_WIZARD_SUCCESS, props<{ payload: any }>());

export const PostGbmWizardError = createAction(ChatActionTypes.POST_GBM_WIZARD_ERROR, props<{ payload: string }>());

/*
get GBM form wizard data
*/
export const GetGbmWizard = createAction(ChatActionTypes.GET_GBM_WIZARD, props<{ integrationType: string }>());

export const GetGbmWizardSuccess = createAction(ChatActionTypes.GET_GBM_WIZARD_SUCCESS, props<{ payload: any }>());

export const GetGbmWizardError = createAction(ChatActionTypes.GET_GBM_WIZARD_ERROR, props<{ payload: string }>());
