import { ValidationErrors, AbstractControl } from '@angular/forms';

export class ValidateMaxMobilePhone {
    static createValidator() {
        return (form: AbstractControl): ValidationErrors => {
            const error = {
                phonesMobileType: {
                    value: form.value,
                },
            };
            return form.value && (!form.value.length || form.value.filter(phone => phone.type === 'mobile').length > 2)
                ? error
                : null;
        };
    }
}
