import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse,
    HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

// services
import { MessagesService } from '../services/messages.service';

@Injectable({ providedIn: 'root' })
export class MessagesInterceptor implements HttpInterceptor {
    private blackList = /stats\/graphql/;

    constructor(public messagesService: MessagesService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const customReq = request.clone({
            headers: request.headers,
        });

        return next.handle(customReq).pipe(
            tap(
                response => {
                    if (response instanceof HttpResponse && !this.blackList.test(response.url)) {
                        this.messagesService.sendSuccess({
                            response,
                            method: request.method,
                        });
                    }
                },
                err => {
                    if (err instanceof HttpErrorResponse) {
                        this.messagesService.sendError({
                            error: err,
                            url: customReq.url,
                        });
                    }
                },
            ),
        );
    }
}
