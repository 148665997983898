import { EnvironmentProviders, importProvidersFrom, makeEnvironmentProviders } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { provideUserApi, UserApiConfig } from '@slm/user/api';
import { UserEffects } from '../+state/users/user.effects';
import { USER_FEATURE_KEY, userReducer } from '../+state/users/user.reducer';

export const provideUserState = (config?: UserApiConfig): EnvironmentProviders => {
    const userStateProviders = importProvidersFrom(
        StoreModule.forFeature(USER_FEATURE_KEY, userReducer),
        EffectsModule.forFeature([UserEffects]),
    );

    if (!config) {
        return makeEnvironmentProviders([userStateProviders]);
    }

    return makeEnvironmentProviders([userStateProviders, provideUserApi(config)]);
};
