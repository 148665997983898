import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

import { Location } from '@solocal-manager/sirius/support/base-models';

import { LocationDecorated } from '../../models';

@Component({
    template: '',
})
export class WpmLocationListItemComponent implements OnInit {
    publisherIcons = {
        gmb: {},
        pagesjaunes: {},
        facebook: {},
    };

    @Input() location: LocationDecorated;
    @Input() selectedLocation: Location;
    @Output() selectLocation: EventEmitter<Location> = new EventEmitter<Location>();
    @Output() mouseoverLocation: EventEmitter<Location> = new EventEmitter<Location>();

    constructor(public logger: NGXLogger) {}

    ngOnInit(): void {
        this.prepareIcons();
    }

    onSelectLocation(location: Location): void {
        this.selectLocation.emit(location);
    }

    onMouseoverLocation(location: Location): void {
        this.mouseoverLocation.emit(location);
    }

    prepareIcons(): void {
        if (this.location) {
            this.location.pages.forEach(page => {
                if (this.publisherIcons[page.publisher_id]) {
                    this.publisherIcons[page.publisher_id].state = page.state;
                }
            });
        }
    }
}
