import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED, RouterNavigatedAction } from '@ngrx/router-store';
import { Store, select } from '@ngrx/store';
import { LocationsSelectors } from '@slm/location/state';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

import { TagManagerService } from '../services/tag-manager.service';
import * as AnalyticsUtils from '../utils/analytics.utils';

export const ROUTER_NAVIGATED_DELAY = 800;

@Injectable({ providedIn: 'root' })
export class RouterAnalyticsEffects {
    routerNavigated$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ROUTER_NAVIGATED),
                map((action: RouterNavigatedAction) => action.payload?.routerState),
                filter(routerState => routerState.url.startsWith('/app/') && !routerState['queryParams']?.epj),
                distinctUntilChanged(),
                debounceTime(ROUTER_NAVIGATED_DELAY),
                concatLatestFrom(() => [
                    this.store.pipe(select(LocationsSelectors.getSelectedLocation)),
                    this.store.pipe(select(LocationsSelectors.getSelectedEpjsForMultiSelect)),
                ]),
                map(([, location, epjs]) => {
                    this.gtmService.dlVariables(
                        AnalyticsUtils.getUserAndLocationDataLayerVariables({ location, epjs }),
                    );
                }),
            ),
        { dispatch: false },
    );

    constructor(
        private readonly actions$: Actions,
        private readonly gtmService: TagManagerService,
        private readonly store: Store<any>,
    ) {}
}
