import { AbstractControl, ValidationErrors } from '@angular/forms';

export class ValidateUrl {
    static createValidator(httpPrefix?: boolean) {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.value) {
                return null;
            }

            const pattern = !httpPrefix
                ? /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/
                : /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
            const valid = pattern.test(control.value);
            return valid
                ? null
                : {
                      url: {
                          valid: false,
                      },
                  };
        };
    }
}
