import { Observable } from 'rxjs';

import { PermissionStrategy } from '../enums/permission-strategy.enum';

import { PermissionEvaluation } from './permission-evaluation';

export abstract class PermissionBroker {
    abstract hasPermission(type: PermissionStrategy, params?: any): Observable<boolean>;

    protected abstract getEvaluationService(
        type: PermissionStrategy,
        permissions: PermissionEvaluation,
    ): PermissionEvaluation;
}
