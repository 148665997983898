<div class="row location-list-row show-shadow">
    <div class="location-list-header">
        <h4 (click)="gotoCompany()" class="location-list-header-title">
            {{ 'List of locations' | translate }}
        </h4>
        <div class="location-list-header-right show-shadow"></div>
    </div>

    <slm-location-list-search
        [settings]="userSettings"
        [resetSearch]="resetSearch$"
        (search)="setUserSettings($event)"
    ></slm-location-list-search>

    <div class="location-list-container show-shadow">
        <ul class="list-group location-list">
            <slm-location-list-item
                *ngFor="let location of locationList; let i = index"
                (selectLocation)="onSelectLocation($event)"
                (mouseoverLocation)="onMouseoverLocation($event)"
                [selectedLocation]="selectedLocation"
                [location]="location"
                [position]="(userSettings?.currentPage - 1) * userSettings?.itemsPerPage + i + 1"
            ></slm-location-list-item>
        </ul>
    </div>
</div>
<div class="empty-row"></div>
