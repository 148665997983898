import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';

import { PublisherSearchService, PublisherConnectService } from '@solocal-manager/sirius/core/core';
import { PublisherSearch } from '@solocal-manager/sirius/core/models';
import { Location } from '@solocal-manager/sirius/support/base-models';

import { publisherSearchStates } from '../../config/publisher-search-states.config';

@Component({
    selector: 'slm-publisher-connect-pagesjaunes',
    templateUrl: './publisher-connect-pagesjaunes.component.html',
    styleUrls: ['./publisher-connect-pagesjaunes.component.scss'],
})
export class PublisherConnectPagesjaunesComponent implements OnChanges, OnDestroy {
    pjConnectionSubscribtion;
    pollingSubscribtion;

    currentPageId = '';
    publisherSearchResults;
    message: string;
    state: string;

    @Input() location: Location;

    @Output() closeModal: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        public logger: NGXLogger,
        public publisherConnectService: PublisherConnectService,
        public publisherSearchService: PublisherSearchService,
        public store: Store<any>,
        public ref: ChangeDetectorRef,
    ) {}

    ngOnChanges(changes) {
        this.state = publisherSearchStates.pending;
        this.publisherSearchResults = [];
        const location = changes.location ? changes.location.currentValue : {};

        if (location.id) {
            this.pollResults();
            const pjPage = this.location.pages.find(page => page.publisher_id === 'pagesjaunes');
            this.currentPageId = pjPage ? pjPage.publisher_page_id : '';
        }
    }

    connect(pageId: string) {
        this.message = '';
        this.logger.debug('<PublisherConnectPagesjaunesComponent> connect: ', pageId);

        this.pjConnectionSubscribtion = this.publisherConnectService
            .connect(this.location.id, 'pagesjaunes', pageId)
            .subscribe(
                res => {
                    if (res) {
                        this.currentPageId = pageId;

                        if (res.message) {
                            this.message = res.message;
                        }

                        this.pollResults();
                        this.ref.detectChanges();
                        this.closeModal.emit(true);
                    }
                },
                err => {
                    this.logger.debug('<PublisherConnectPagesjaunesComponent> connect error:', err);
                },
            );
    }

    isExistingResult(pageId: string): boolean {
        return pageId === this.currentPageId;
    }

    pollResults() {
        this.pollingSubscribtion = this.publisherSearchService
            .poll(this.location.id, 'pagesjaunes')
            .subscribe((res: PublisherSearch) => {
                if (res) {
                    this.logger.debug('<PublisherConnectPagesjaunesComponent> poll: ', res);
                    const filteredResults = res.data.filter(r => !!r.page_id);

                    this.publisherSearchResults = filteredResults;
                    this.state = res.state;
                }
            });
    }

    ngOnDestroy() {
        if (this.pjConnectionSubscribtion) {
            this.pjConnectionSubscribtion.unsubscribe();
        }
        if (this.pollingSubscribtion) {
            this.pollingSubscribtion.unsubscribe();
        }
    }
}
