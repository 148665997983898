import { createAction, props } from '@ngrx/store';

import {
    Publisher,
    PublisherSettings,
    PublisherSettingsState,
    PublisherStatus,
} from '@solocal-manager/sirius/core/models';
import { Location } from '@solocal-manager/sirius/support/base-models';
import { Page } from '@solocal-manager/sirius/support/base-models';

export const getAvailablePublishers = createAction('[GET_AVAILABLE_PUBLISHERS] get available publishers');

export const getAvailablePublishersSuccess = createAction(
    '[GET_AVAILABLE_PUBLISHERS_SUCCESS] get available publishers success',
    props<{
        result: Publisher[];
    }>(),
);

export const getPublisherStatus = createAction(
    '[GET_PUBLISHER_STATUS] get publisher status',
    props<{
        params: { locationId?: string; location?: Location };
    }>(),
);

export const getPublisherStatusSuccess = createAction(
    '[GET_PUBLISHER_STATUS_SUCCESS] get publisher status success',
    props<{
        result: PublisherStatus[];
    }>(),
);

export const updatePublisherSettings = createAction(
    '[UPDATE_PUBLISHER_SETTINGS] update publisher settings',
    props<{
        params: { locationId: string; publisherId: string; state: PublisherSettingsState };
    }>(),
);

export const updatePublisherSettingsSuccess = createAction(
    '[UPDATE_PUBLISHER_SETTINGS_SUCCESS] update publisher settings success',
    props<{
        result: PublisherSettings;
        // locationId: string;
    }>(),
);

export const updatePage = createAction(
    '[UPDATE_PAGE] update page',
    props<{
        params: { pageId: string };
    }>(),
);

export const updatePageSuccess = createAction(
    '[UPDATE_PAGE_SUCCESS] update page success',
    props<{
        result: Page;
    }>(),
);

export const createPage = createAction(
    '[CREATE_PAGE] create page',
    props<{
        params: { locationId: string; publisherId: string };
    }>(),
);

export const createPageSuccess = createAction(
    '[CREATE_PAGE_SUCCESS] create page success',
    props<{
        result: Page;
    }>(),
);

export const getPages = createAction(
    '[GET_PAGES] get pages',
    props<{
        params: { locationId: string };
    }>(),
);

export const getPagesSuccess = createAction(
    '[GET_PAGES_SUCCESS] get pages success',
    props<{
        result: Page[];
    }>(),
);

export const deletePage = createAction(
    '[DELETE_PAGE] delete page',
    props<{
        params: { pageId: string; publisherId?: string };
    }>(),
);

export const deletePageSuccess = createAction('[DELETE_PAGE_SUCCESS] delete page success');

export const disconnectPage = createAction(
    '[DISCONNECT_PAGE] disconnect page',
    props<{
        params: { pageId: string };
    }>(),
);

export const disconnectPageSuccess = createAction(
    '[DISCONNECT_PAGE_SUCCESS] disconnect page success',
    props<{
        result: Page;
    }>(),
);
