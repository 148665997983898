import { sortBy } from 'lodash-es';

export const sortByProperty = (
    arrayToSort: any[],
    args: {
        property?: string;
        sortedArray?: any[];
        desc?: boolean;
    },
): any[] => {
    if (arrayToSort && args && args.sortedArray && args.property) {
        const sorted = sortBy(arrayToSort, obj => {
            return args.sortedArray.indexOf(obj[args.property]);
        });

        return sorted;
    } else if (arrayToSort && args && args.sortedArray) {
        const sorted = sortBy(arrayToSort, string => {
            return args.sortedArray.indexOf(string);
        });

        return sorted;
    } else if (arrayToSort && args && args.property) {
        const d = args.desc ? 1 : -1;
        return arrayToSort.sort((a, b) =>
            (a[args.property] && !b[args.property]) || a[args.property] > b[args.property] ? d * -1 : d,
        );
    } else {
        return sortBy(arrayToSort);
    }
};
