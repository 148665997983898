import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';

@Injectable({ providedIn: 'root' })
export class CustomerActions {
    static SET_CURRENT_CUSTOMER_ID = '[SET_CURRENT_CUSTOMER_ID] set current customer id';

    setCustomerId(): Action {
        return {
            type: CustomerActions.SET_CURRENT_CUSTOMER_ID,
        };
    }
}
