<slm-header (locationChanged)="locationSelection($event)"></slm-header>

<app-loading-indicator></app-loading-indicator>
<div class="container-fluid admin-tools-index-page">
    <div class="row">
        <div class="col-md-4 admin-tools-locations">
            <slm-location-list
                (selectLocation)="selectLocation($event)"
                (mouseoverLocation)="mouseoverLocation($event)"
                (showMoveLocation)="(showMoveLocation)"
                #locationListScope
                class="slm-location-list"
                [ngClass]="{ 'hide-list': selectedLocation }"
            ></slm-location-list>

            <div
                *ngIf="locationListScope.locationListCount > 1 && !locationSelected"
                class="pagination-holder"
                [ngClass]="{ 'show-shadow': selectedLocation }"
            >
                <app-pagination
                    [totalCount]="
                        locationListScope.habilitatedLocationsListCount || locationListScope.locationListCount
                    "
                    (pagerChanged)="locationListScope.pagerValueChanged($event)"
                ></app-pagination>
            </div>
        </div>
        <div class="col-md-8 admin-tools-content">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
