import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({ template: '' })
export abstract class SubscriptionCloserComponent implements OnDestroy {
    //TODO <Pierre's suggestion> replace any by void
    destroy$: Subject<any> = new Subject<any>();

    ngOnDestroy(): void {
        this.destroy$.next(undefined);
        this.destroy$.complete();
    }
}
