import { Pipe, PipeTransform } from '@angular/core';

/*
Angular 6.0.0
https://github.com/angular/angular/blob/master/CHANGELOG.md#610-beta1-2018-06-13
introduced a KeyValuePipe

so this pipe can be deleted once we do an update
*/
@Pipe({
    name: 'keyValue',
})
export class KeyValuePipe implements PipeTransform {
    transform(value: any, args?: any): any {
        const items = [];
        if (!(value instanceof Array) && value instanceof Object) {
            Object.keys(value).forEach(key => {
                items.push({ key, value: value[key] });
            });
        }
        return items;
    }
}
