import { Injectable } from '@angular/core';
import { ofType, Actions, createEffect } from '@ngrx/effects';
import { ApiErrorActions } from '@slm/shared/error';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import {
    GetLocationListByError,
    GetLocationListByErrorSuccess,
    GetPageStates,
    GetPageStatesSuccess,
    GetProvisioningIssues,
    GetProvisioningIssuesSuccess,
    GetProvisioningIssueDetails,
    GetProvisioningIssueDetailsSuccess,
    GetPublisherErrors,
    GetPublisherErrorsSuccess,
    GetTotalParcUsage,
    GetTotalParcUsageSuccess,
    GetTotalSales,
    GetTotalSalesSuccess,
    KpiActionTypes,
} from '../actions';
import { KpiService } from '../services';

@Injectable({ providedIn: 'root' })
export class KpiEffects {
    constructor(
        public update$: Actions,
        public kpiService: KpiService,
    ) {}

    getPageStates$ = createEffect(() =>
        this.update$.pipe(
            ofType<GetPageStates>(KpiActionTypes.GET_PAGE_STATES),
            switchMap(() =>
                this.kpiService.getPageStates().pipe(
                    map(result => new GetPageStatesSuccess(result)),
                    catchError(error => of(new ApiErrorActions.CatchApiError(error))),
                ),
            ),
        ),
    );

    getLocationListByError$ = createEffect(() =>
        this.update$.pipe(
            ofType<GetLocationListByError>(KpiActionTypes.GET_LOCATION_LIST_BY_ERROR),
            map(action => action.params),
            switchMap(params =>
                this.kpiService
                    .getLocationListByError(params.publisherId, params.errorMessage, params.pageSize, params.pageNumber)
                    .pipe(
                        map(results => new GetLocationListByErrorSuccess(results)),
                        catchError(error => of(new ApiErrorActions.CatchApiError(error))),
                    ),
            ),
        ),
    );

    getPublisherErrors$ = createEffect(() =>
        this.update$.pipe(
            ofType<GetPublisherErrors>(KpiActionTypes.GET_PUBLISHER_ERRORS),
            map(action => action.params),
            switchMap(params =>
                this.kpiService.getPublisherErrors(params.publisherId, params.states).pipe(
                    map(results => new GetPublisherErrorsSuccess(results)),
                    catchError(error => of(new ApiErrorActions.CatchApiError(error))),
                ),
            ),
        ),
    );

    getTotalSales$ = createEffect(() =>
        this.update$.pipe(
            ofType<GetTotalSales>(KpiActionTypes.GET_TOTAL_SALES),
            map(action => action.params),
            switchMap((params?) =>
                this.kpiService.getTotalSales(params ? params.status : '').pipe(
                    map(results => new GetTotalSalesSuccess(results)),
                    catchError(error => of(new ApiErrorActions.CatchApiError(error))),
                ),
            ),
        ),
    );

    getProvisioningIssues$ = createEffect(() =>
        this.update$.pipe(
            ofType<GetProvisioningIssues>(KpiActionTypes.GET_PROVISIONING_ISSUES),
            switchMap(() =>
                this.kpiService.getProvisioningIssues().pipe(
                    map(results => new GetProvisioningIssuesSuccess(results)),
                    catchError(error => of(new ApiErrorActions.CatchApiError(error))),
                ),
            ),
        ),
    );

    getProvisioningIssueDetails$ = createEffect(() =>
        this.update$.pipe(
            ofType<GetProvisioningIssueDetails>(KpiActionTypes.GET_PROVISIONING_ISSUE_DETAILS),
            map(action => action.params),
            switchMap(params =>
                this.kpiService.getProvisioningIssueDetails(params.type, params.year, params.week).pipe(
                    map(results => new GetProvisioningIssueDetailsSuccess(results)),
                    catchError(error => of(new ApiErrorActions.CatchApiError(error))),
                ),
            ),
        ),
    );

    getTotalParcUsage$ = createEffect(() =>
        this.update$.pipe(
            ofType<GetTotalParcUsage>(KpiActionTypes.GET_TOTAL_PARC_USAGE),
            map(action => action.params),
            switchMap((params?) =>
                this.kpiService.getTotalParcUsage(params ? params.yearFrom : '', params ? params.yearUntil : '').pipe(
                    map(results => new GetTotalParcUsageSuccess(results)),
                    catchError(error => of(new ApiErrorActions.CatchApiError(error))),
                ),
            ),
        ),
    );
}
