import { ESsoOrigins, UserDtoModel } from '@slm/user/api';

import { AppSettings, PackPermissions } from '../../models';

export interface User {
    application: AppSettings;
    customers: any[];
    date_joined: string | undefined;
    email: string | undefined;
    epj_default?: string;
    epj_last_used_datetime?: string;
    epj_last_used_value?: string;
    groups: string[] | any[];
    has_restaurants: boolean;
    id: number | undefined;
    ip_address_last_login: string | undefined;
    is_active: boolean;
    is_customer_admin: boolean;
    is_onbehalf_customer: any;
    is_staff: boolean;
    is_superuser: boolean;
    last_login: string | undefined;
    notes: string | undefined;
    onbehalf_customer: any;
    onbehalf_customer_modified: string | undefined;
    packs: string[];
    partner_id: string | undefined; // Cupro ID
    phone: string | undefined;
    tags: string[];
    user_permissions: any[];
    mode?: string;
    origin?: ESsoOrigins;
    origin_location_id?: string; // EPJ from crm, community.
    pack_permissions?: PackPermissions;
    readonly?: boolean;
}

export const createUser = (user: UserDtoModel): User =>
    user && {
        ...user,
        application: user.application && {
            ...user.application,
            id: user.application?.id?.toString(),
        },
        groups: user.groups ?? [],
        readonly: user.groups?.includes('ui-readonly'),

        origin: ESsoOrigins[user.origin],
    };
