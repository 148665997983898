import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@slm/shared/environment';
import { MenuCategory } from '@solocal-manager/sirius/support/base-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface ICategoriesResponse {
    next: number;
    next_page: number;
    previous: number;
    previous_page: number;
    count: number;
    results: MenuCategory[];
}

@Injectable({ providedIn: 'root' })
export class MenuCategoryHttpService {
    constructor(
        public http: HttpClient,
        public envService: EnvironmentService,
    ) {}

    getMenuCardsCategories(): Observable<MenuCategory[]> {
        const requestUrl = `${this.envService.apiUrl}/consumer/menu-cards`;
        return this.http.get<ICategoriesResponse>(requestUrl).pipe(map((res: ICategoriesResponse) => res.results));
    }
}
