import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OAuthStorage } from 'angular-oauth2-oidc';

import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular';
import { SlmAuthFacade } from './classes/slm-oauth.facade';

export function storageFactory(): OAuthStorage {
    return localStorage;
}

@NgModule({
    imports: [
        CommonModule,
        KeycloakAngularModule,
        /* OAuthModule.forRoot({
            resourceServer: {
                sendAccessToken: true,
                customUrlValidation: url => !url.includes('/assets'),
            },
        }), */
    ],
    providers: [SlmAuthFacade, KeycloakService, { provide: OAuthStorage, useFactory: storageFactory }],
    exports: [KeycloakAngularModule],
})
export class AuthModule {}
