import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { API_CONFIG } from '@slm/shared/environment';
import { Observable } from 'rxjs';

import { AddressCity } from '../models';

@Injectable({ providedIn: 'root' })
export class CityService {
    private readonly http = inject(HttpClient);
    private readonly apiConfig = inject(API_CONFIG);

    getCityByZip(zip: string): Observable<AddressCity[]> {
        const requestUrl = `${this.apiConfig.versioned}/external/autocomplete-city`;

        const params = new HttpParams()
            .set('country', 'FR') // according to Konstantin, for now supports only FR
            .set('zip_code', zip);

        return this.http.get<AddressCity[]>(requestUrl, { params });
    }
}
