import { Injectable, EventEmitter } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LoadingService {
    public isLoading: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() {}

    emitIsLoading(isLoading) {
        this.isLoading.emit(isLoading);
    }
}
