<div [formGroup]="formAddress">
    <div class="form-group col-md-12">
        <label [ngClass]="{ 'required-label': requiredLabel.address_line1 }" for="inputAddressLine1">{{
            'input_address:address_line_1' | translate
        }}</label>
        <div [class.lane-invalid]="laneInvalid" class="street-input-wrapper">
            <ng-template #customLaneItemTemplate let-model="item" let-index="index">
                <span>{{ model.l4 }}</span>
            </ng-template>
            <input
                type="text"
                class="form-control"
                id="inputAddressLine1"
                autocomplete="nope"
                formControlName="address_line1"
                [typeaheadItemTemplate]="customLaneItemTemplate"
                [typeaheadWaitMs]="400"
                [typeaheadAsync]="true"
                [typeahead]="lanesDataSource"
                [typeaheadScrollable]="true"
                [typeaheadOptionsInScrollableView]="7"
                [typeaheadOptionsLimit]="999999"
                [typeaheadMinLength]="0"
                (typeaheadLoading)="changeTypeaheadLaneLoading($event)"
                (typeaheadNoResults)="changeTypeaheadLaneNoResults($event)"
                (typeaheadOnSelect)="typeaheadOnLaneSelect($event)"
                typeaheadOptionField="l4"
                id="typeaheadLane"
                autocomplete="nope"
            />
            <div *ngIf="typeaheadLaneLoading" class="loading-icon">
                <i class="glyphicon glyphicon-refresh ng-hide"></i>
            </div>
            <small class="tooltip-for-validator" *ngIf="showToolTip">
                {{
                    (isStreetValidatorRemoved
                        ? 'Click here to enable the RGU address check.'
                        : 'Click here to disable the RGU address check.'
                    ) | translate
                }}
            </small>
            <button
                (mouseover)="showTooltip(true)"
                (mouseleave)="showTooltip(false)"
                class="street-input-wrapper__btn"
                type="button"
                (click)="removeStreetValidator()"
            >
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="-38 0 512 512.00142">
                    <g id="surface1">
                        <path
                            [ngClass]="isStreetValidatorRemoved ? 'gray' : 'green'"
                            d="M 217.996094 158.457031 C 164.203125 158.457031 120.441406 202.21875 120.441406 256.007812 C 120.441406 309.800781 164.203125 353.5625 217.996094 353.5625 C 271.785156 353.5625 315.546875 309.800781 315.546875 256.007812 C 315.546875 202.21875 271.785156 158.457031 217.996094 158.457031 Z M 275.914062 237.636719 L 206.027344 307.523438 C 203.09375 310.457031 199.246094 311.925781 195.402344 311.925781 C 191.558594 311.925781 187.714844 310.460938 184.78125 307.523438 L 158.074219 280.816406 C 152.207031 274.953125 152.207031 265.441406 158.074219 259.574219 C 163.9375 253.707031 173.449219 253.707031 179.316406 259.574219 L 195.402344 275.660156 L 254.671875 216.394531 C 260.535156 210.527344 270.046875 210.527344 275.914062 216.394531 C 281.78125 222.257812 281.78125 231.769531 275.914062 237.636719 Z M 275.914062 237.636719 "
                            style="stroke: none; fill-rule: nonzero; fill-opacity: 1"
                        />
                        <path
                            [ngClass]="isStreetValidatorRemoved ? 'gray' : 'green'"
                            d="M 435.488281 138.917969 L 435.472656 138.519531 C 435.25 133.601562 435.101562 128.398438 435.011719 122.609375 C 434.59375 94.378906 412.152344 71.027344 383.917969 69.449219 C 325.050781 66.164062 279.511719 46.96875 240.601562 9.042969 L 240.269531 8.726562 C 227.578125 -2.910156 208.433594 -2.910156 195.738281 8.726562 L 195.40625 9.042969 C 156.496094 46.96875 110.957031 66.164062 52.089844 69.453125 C 23.859375 71.027344 1.414062 94.378906 0.996094 122.613281 C 0.910156 128.363281 0.757812 133.566406 0.535156 138.519531 L 0.511719 139.445312 C -0.632812 199.472656 -2.054688 274.179688 22.9375 341.988281 C 36.679688 379.277344 57.492188 411.691406 84.792969 438.335938 C 115.886719 468.679688 156.613281 492.769531 205.839844 509.933594 C 207.441406 510.492188 209.105469 510.945312 210.800781 511.285156 C 213.191406 511.761719 215.597656 512 218.003906 512 C 220.410156 512 222.820312 511.761719 225.207031 511.285156 C 226.902344 510.945312 228.578125 510.488281 230.1875 509.925781 C 279.355469 492.730469 320.039062 468.628906 351.105469 438.289062 C 378.394531 411.636719 399.207031 379.214844 412.960938 341.917969 C 438.046875 273.90625 436.628906 199.058594 435.488281 138.917969 Z M 217.996094 383.605469 C 147.636719 383.605469 90.398438 326.367188 90.398438 256.007812 C 90.398438 185.648438 147.636719 128.410156 217.996094 128.410156 C 288.351562 128.410156 345.59375 185.648438 345.59375 256.007812 C 345.59375 326.367188 288.351562 383.605469 217.996094 383.605469 Z M 217.996094 383.605469 "
                            style="stroke: none; fill-rule: nonzero; fill-opacity: 1"
                        />
                    </g>
                </svg>
            </button>
        </div>
        <div *ngIf="typeaheadLaneNoResults">
            <i class="glyphicon glyphicon-remove"></i>
            {{ 'No Results Found' | translate }}
        </div>
        <small *ngIf="!formAddress.controls.address_line1.value" class="street-note">{{
            'Set the zip code and locality first.' | translate
        }}</small>
        <app-input-error-messages [control]="formAddress.controls.address_line1"></app-input-error-messages>
        <app-input-error-messages [control]="formAddress"></app-input-error-messages>
    </div>
    <div class="form-group col-md-12">
        <label [ngClass]="{ 'required-label': requiredLabel.address_line2 }" for="inputAddressLine2">{{
            'Address Line 2' | translate
        }}</label>
        <input
            type="text"
            class="form-control"
            id="inputAddressLine2"
            autocomplete="nope"
            formControlName="address_line2"
        />
        <app-input-error-messages [control]="formAddress.controls.address_line2"></app-input-error-messages>
    </div>

    <div class="form-group col-md-5">
        <label [ngClass]="{ 'required-label': requiredLabel.zip_code }" for="typeaheadZipCity">{{
            'Zip Code' | translate
        }}</label>
        <div class="zip-code-wrapper">
            <ng-template #customZipItemTemplate let-model="item" let-index="index">
                <span>{{ model.zip_code }}, {{ model.city }}, {{ model.country }}</span>
            </ng-template>
            <input
                formControlName="zip_code"
                [typeaheadItemTemplate]="customZipItemTemplate"
                [typeaheadWaitMs]="400"
                [typeaheadAsync]="true"
                [typeahead]="zipDataSource"
                [typeaheadScrollable]="true"
                [typeaheadOptionsInScrollableView]="7"
                [typeaheadOptionsLimit]="999999"
                (typeaheadLoading)="changeTypeaheadZipLoading($event)"
                (typeaheadNoResults)="changeTypeaheadZipNoResults($event)"
                (typeaheadOnSelect)="typeaheadOnZipSelect($event)"
                typeaheadOptionField="zip_code"
                placeholder="{{ 'Search for zip code and city..' | translate }}"
                id="typeaheadZipCity"
                class="form-control"
                autocomplete="nope"
            />
            <div *ngIf="typeaheadZipLoading">
                <i class="glyphicon glyphicon-refresh ng-hide"></i>
            </div>
        </div>
        <div *ngIf="typeaheadZipNoResults">
            <i class="glyphicon glyphicon-remove"></i>
            {{ 'No Results Found' | translate }}
        </div>
        <app-input-error-messages [control]="formAddress.controls.zip_code"></app-input-error-messages>
    </div>
    <div class="form-group col-md-7">
        <label [ngClass]="{ 'required-label': requiredLabel.city }" for="inputCity">{{ 'City' | translate }}</label>
        <input type="text" class="form-control" disabled id="inputCity" autocomplete="nope" formControlName="city" />
        <app-input-error-messages [control]="formAddress.controls.city"></app-input-error-messages>
    </div>

    <div class="form-group col-md-12">
        <label [ngClass]="{ 'required-label': requiredLabel.country }" for="inputCountry">{{
            'Country' | translate
        }}</label>
        <input
            name="countries"
            type="text"
            formControlName="country"
            disabled
            class="form-control"
            autocomplete="nope"
            id="countries"
        />
        <app-input-error-messages [control]="formAddress.controls.country"></app-input-error-messages>
    </div>
    <div formGroupName="coordinates">
        <div class="form-group col-md-6">
            <label for="inputLatitude">{{ 'Latitude' | translate }}</label>
            <input type="number" class="form-control" id="inputLatitude" formControlName="latitude" />
        </div>
        <div class="form-group col-md-6">
            <label for="inputLongitude">{{ 'Longitude' | translate }}</label>
            <input type="number" class="form-control" id="inputLongitude" formControlName="longitude" />
        </div>
    </div>

    <input type="hidden" id="insee_code" formControlName="insee_code" />

    <div class="form-group col-md-12 geocoding-info" translate>
        <i class="glyphicon glyphicon-info-sign"></i>
        The changes made to the geo-coordinates by editing them or by changing the pin on the map, can only be pushed
        once the very first time before connecting the publishers (so before clicking on Connect GMB or FB).
    </div>

    <div class="form-group col-md-12 geocoder-tools">
        <h5>{{ 'Geocoding Tools' | translate }}</h5>
        <button class="btn btn-primary remove-field-button" (click)="getCoordinatesFromAddress()" translate>
            Get Coordinates From Address
            <span *ngIf="geocoderBusy" class="loading-icon">
                <i class="glyphicon glyphicon-refresh"></i>
            </span>
        </button>

        <div class="geocoder-results" *ngIf="!geocoderBusy">
            <button
                (click)="setCoordinatesFromAddress(geoResult.geometry)"
                class="list-group-item"
                *ngFor="let geoResult of geocoderResults; let i = index"
            >
                <p>
                    <span class="bold">{{ geoResult.formatted_address }}</span>
                    {{ 'latitude' | translate }}:{{ geoResult.geometry.location.lat }} {{ 'longitude' | translate }}:{{
                        geoResult.geometry.location.lng
                    }}
                </p>
            </button>
            <div *ngIf="geocoderResults.length === 0 && geocoderDone">
                {{ 'no results' | translate }}
            </div>
        </div>

        <hr />

        <app-input-google-maps-url [currentCoordinates]="coordinates" (onCoordinates)="getCoordinates($event)">
        </app-input-google-maps-url>
    </div>
</div>
