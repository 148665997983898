import { DatePipe } from '@angular/common';
import { Component, OnChanges, OnInit, Input, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'app-input-datepicker',
    templateUrl: './input-datepicker.component.html',
    styleUrls: ['./input-datepicker.component.scss'],
})
export class InputDatepickerComponent implements OnInit, OnChanges, OnDestroy {
    @Input() control: UntypedFormControl;

    controlSubscribtion;
    datepickerShow = false;
    formattedDate: string;
    dt: Date;
    datePipe: DatePipe = new DatePipe('en-us');

    constructor() {}

    ngOnInit(): void {
        this.controlSubscribtion = this.control.valueChanges.subscribe(control => {
            this.dt = control;
            this.formatDate(new Date(this.dt));
        });
    }

    ngOnChanges(changed): void {
        if (this.control.value) {
            this.dt = this.control.value;
            this.formatDate(new Date(this.dt));
        }
    }

    ngOnDestroy(): void {
        this.controlSubscribtion.unsubscribe();
    }

    onDateSelect(dt): void {
        this.datepickerShow = false;
        this.formatDate(dt);
        this.control.setValue(this.formattedDate);
    }

    private formatDate(dt): void {
        this.formattedDate = this.datePipe.transform(dt, 'yyyy-MM-dd');
    }
}
