import { UntypedFormArray, UntypedFormGroup, ValidationErrors } from '@angular/forms';

export class OpenningHoursValidator {
    static create(formRepeatable: UntypedFormGroup) {
        return (group: UntypedFormGroup): ValidationErrors => {
            const openTimeValue = group.controls.open_time.value;
            const closeTimeValue = group.controls.close_time.value;
            // Checking for having at least one "opened" day
            const repeatableControls = <UntypedFormArray>formRepeatable.controls.data;
            const closed = repeatableControls.controls.filter(control => control.value.open_time === 'closed');
            repeatableControls.controls.forEach(control => {
                if (control.getError('openedDayMissing')) {
                    control.setErrors(null);
                }
            });
            if (closed.length === repeatableControls.controls.length) {
                return {
                    openedDayMissing: true,
                };
            }
            // Checking for closing time missing
            if (openTimeValue && openTimeValue !== 'closed' && !closeTimeValue) {
                return {
                    closingTimeMissing: true,
                };
            }

            return null;
        };
    }
}
