import { createFeatureSelector, createSelector } from '@ngrx/store';

import { EPacks, FeatureType, Location } from '@solocal-manager/sirius/support/base-models';

import { createConnectedPublishers } from '../../models/connected-publishers';

import { createFeatureSettingsViewModel, FeatureSettingsViewModel } from '../../models/feature-settings-view-model';
import { epjToIdAdapter } from './epj-to-id/epj-to-id.adapter';
import { sortByName } from './location.utils';
import { LocationsAndState } from './locations.models';
import { LOCATIONS_FEATURE_KEY, locationsAdapter, State } from './locations.reducer';
import { partnerIdToIdAdapter } from './partner-id-to-id/partner-id-to-id.adapter';

const partnerIdToIdSelectors = partnerIdToIdAdapter.getSelectors();
const epjToIdSelectors = epjToIdAdapter.getSelectors();

export const getLocationsState = createFeatureSelector<State>(LOCATIONS_FEATURE_KEY);

export const getEpjToIdMap = createSelector(getLocationsState, state => epjToIdSelectors.selectEntities(state.epjToId));

export const getEpjToId = (epj: string) => createSelector(getEpjToIdMap, epjToIdMap => epjToIdMap[epj]);

export const getParnterIdToIdMap = createSelector(getLocationsState, state =>
    partnerIdToIdSelectors.selectEntities(state.partnerIdToId),
);

export const getPartnerIdToId = (partnerId: string) =>
    createSelector(getParnterIdToIdMap, partnerIdToIdMap => partnerIdToIdMap[partnerId]);

const { selectAll, selectEntities, selectIds } = locationsAdapter.getSelectors();

export const getLocationsLoaded = createSelector(getLocationsState, (state: State) => state.loaded);

export const getLocationsError = createSelector(getLocationsState, (state: State) => state.error);

export const getAllLocations = createSelector(getLocationsState, state => (state ? selectAll(state) : []));

export const getLocationsEntities = createSelector(getLocationsState, (state: State) => selectEntities(state));
export const getLocationsCount = createSelector(getLocationsState, state => state.count);

export const getLocationIds = createSelector(getLocationsState, (state: State) => selectIds(state));

export const selectIsMultiLocationAccount = createSelector(getLocationsCount, count => count > 1);

export const getSelectedId = createSelector(getLocationsState, getAllLocations, (state: State, allLocations) => {
    return state.selectedId ?? allLocations?.[0]?.id;
});
export const getSelectedIdsForMultiSelect = createSelector(getLocationsState, (state: State) => state.selectedIds);
export const selectHasMultipleSelected = createSelector(getSelectedIdsForMultiSelect, ids => ids?.length !== 1);
export const selectHasOneSelected = createSelector(
    selectIsMultiLocationAccount,
    selectHasMultipleSelected,
    (isMultiLocationAccount, hasMultiple) => !isMultiLocationAccount || !hasMultiple,
);

export const getEpjsFromIds = (ids: string[]) =>
    createSelector(getLocationsEntities, locations => {
        return ids.map(id => locations?.[id].epj);
    });

export const getEpjFromId = (id: string) => createSelector(getLocationsEntities, locations => locations?.[id]?.epj);
export const getSelectedLocation = createSelector(
    getLocationsEntities,
    getSelectedId,
    (entities, selectedId) => entities[selectedId],
);

export const getLocationByEpj = (epj: string) =>
    createSelector(
        getLocationsEntities,
        getEpjToId(epj),
        (locations, epjToId) => epjToId != null && locations[epjToId.epj],
    );

export const getLocationByPartnerId = (partnerId: string) =>
    createSelector(
        getLocationsEntities,
        getPartnerIdToId(partnerId),
        (locations, partnerIdToId) => partnerIdToId != null && locations[partnerIdToId?.id],
    );

export const getLocationById = (id: string) => createSelector(getLocationsEntities, locations => locations[id]);

export const getSelectedLocationPages = createSelector(getSelectedLocation, location => location?.pages);

export const getSelectedLocationOpeningHours = createSelector(getSelectedLocation, location => location?.opening_hours);

export const getLocationsAndState = createSelector(
    getAllLocations,
    getLocationsState,
    (locations, state) =>
        ({
            locations,
            selectedId: state?.selectedId,
            error: state?.error,
            count: state?.count,
            loaded: state?.loaded,
            defaultLocationId: state?.defaultLocationId,
            defaultLocationLoaded: state?.defaultLocationLoaded,
            initial: state?.initial,
            nextPage: state?.nextPage,
            next: state?.next,
            currentPage: state?.currentPage,
        }) as LocationsAndState,
);

export const isSingleLocationSelected = createSelector(getAllLocations, locations => locations.length === 1);

export const getAllLocationsLoaded = createSelector(getLocationsState, state => state.loaded && !state.nextPage);

export const getSelectedLocationsForMultiSelect = createSelector(
    getSelectedIdsForMultiSelect,
    getLocationsEntities,
    (ids, dict) => ids?.map(id => dict?.[id]).sort(sortByName) ?? [],
);

export const getSelectedOrAllLocations = createSelector(
    getSelectedLocationsForMultiSelect,
    getAllLocations,
    (selected, all) => {
        if (selected?.length > 0) {
            return selected;
        }

        return all;
    },
);

export const areSelectedLocationsProspects = (condition: 'every' | 'some' = 'every') =>
    createSelector(getSelectedOrAllLocations, locations =>
        locations[condition](location => location?.custom_data?.pack?.id === EPacks.pack0),
    );

export const areSelectedLocationsClients = (condition: 'every' | 'some' = 'every') =>
    createSelector(getSelectedOrAllLocations, locations =>
        locations[condition](location => location?.custom_data?.pack?.id !== EPacks.pack0),
    );

export const getSelectedEpjsForMultiSelect = createSelector(getSelectedOrAllLocations, locations =>
    locations.map(location => location.epj),
);

export const getSelectedLocationForSingleSelect = createSelector(
    getSelectedOrAllLocations,
    getSelectedId,
    (locations, selectId) => locations.find(location => location?.id === selectId),
);
export const getSelectedIdForSingleSelect = createSelector(
    getSelectedLocationForSingleSelect,
    location => location?.id,
);

export const getConnectedPublishers = createSelector(getSelectedOrAllLocations, locations =>
    createConnectedPublishers(locations),
);
export const getConnectedPublishersSingleSelect = createSelector(getSelectedLocationForSingleSelect, locations =>
    createConnectedPublishers([locations]),
);
export const selectLocationTags = createSelector(getSelectedLocationForSingleSelect, (state: Location) => state?.tags);

export const selectLocationPacks = createSelector(
    getSelectedLocationForSingleSelect,
    (location: Location) => location?.custom_data?.pack?.id,
);

const getPublishersWithFeatureSelector = (feature: string) =>
    createSelector(getAllLocations, allLocations => [
        ...new Set(
            allLocations
                .map(location =>
                    location.pages.filter(page => page.features.includes(feature)).map(page => page.publisher_id),
                )
                .flat(),
        ),
    ]);

export const getPublishersWithVideo = getPublishersWithFeatureSelector('social_posts_videos');

export const getFeatureSettingsOfSelectedLocation = (feature: FeatureType) =>
    createSelector(getSelectedLocation, (location: Location) => {
        const featureSettings = location?.feature_settings?.find(
            settings => settings.feature === FeatureSettingsViewModel.getKeyForFeatureType(feature),
        );

        return createFeatureSettingsViewModel({ feature, ...featureSettings }, location);
    });

export const selectLocationEpj = createSelector(getSelectedLocation, (state: Location) => state?.epj);
