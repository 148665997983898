import { Injectable, Injector } from '@angular/core';
import { Params } from '@angular/router';
import { INavigationLogic } from '@solocal-manager/sirius/core/models/navigation/interfaces';
import { NavLogicRouteIds } from '@solocal-manager/sirius/core/models/navigation/nav-logic-route-id.enum';
import { NavigationDescriptor } from '@solocal-manager/sirius/core/models/navigation/navigation-descriptor';
import { NavigationUtils } from '@solocal-manager/sirius/core/utils/navigation';

const validRouteIds: string[] = [NavLogicRouteIds.LOCATION_INFORMATION, NavLogicRouteIds.ONBOARDING_JOURNEY];

@Injectable()
class LocationInformationNextLogic implements INavigationLogic {
    getEffectiveRoute(routeId: string, params?: Params): NavigationDescriptor {
        if (!validRouteIds.includes(routeId)) {
            throw new Error(`LocationInformationsNavLogic is not the right nav logic for id '${routeId}'`);
        }

        return NavigationUtils.createLocationNavDesc('/content/info', params.epj);
    }
}

export function locationInformationNavLogicFact(injector: Injector): INavigationLogic {
    const creationInjector = Injector.create({
        providers: [
            {
                provide: LocationInformationNextLogic,
            },
        ],
        parent: injector,
    });

    return creationInjector.get(LocationInformationNextLogic);
}
