import { Component, EventEmitter, OnChanges, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { LocationsActions, LocationsAndState, LocationsSelectors } from '@slm/location/state';
import { Location } from '@solocal-manager/sirius/support/base-models';
import { NGXLogger } from 'ngx-logger';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Pager, UserSettings } from '../../models';

@Component({
    template: '',
})
export class WpmLocationListComponent implements OnInit, OnChanges {
    locationList: Location[];
    locationListCount: number;

    selectedLocation: Location;

    userSettings: UserSettings = { itemsPerPage: 20, currentPage: 1 };
    searchValue: string;
    habilitatedLocationsListCount: number;
    isDestroyed$ = new Subject<void>();

    @Output() selectLocation: EventEmitter<Location> = new EventEmitter<Location>();
    @Output() mouseoverLocation: EventEmitter<Location> = new EventEmitter<Location>();

    constructor(
        public store: Store<any>,
        public logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        this.store
            .pipe(select(LocationsSelectors.getLocationsAndState), takeUntil(this.isDestroyed$))
            .subscribe((locations: LocationsAndState) => {
                if (locations) {
                    this.locationListCount = locations.count;
                    this.locationList = locations.locations;
                }
            });
    }

    ngOnChanges(changes): void {
        this.getLocationList('all', changes.page, changes.itemsPerPage);
    }

    getLocationList(companyId: string, pageNumber: number, pageSize: number): void {
        this.store.dispatch(
            LocationsActions.loadLocations({
                request: {
                    companyId,
                    config: {
                        pageSize,
                        pageNumber,
                        shouldReturnMeta: true,
                    },
                },
            }),
        );
    }

    pagerValueChanged(pager: Pager): void {
        this.userSettings = {
            currentPage: pager.page,
            itemsPerPage: pager.itemsPerPage,
        };
        if (!this.searchValue) {
            this.getLocationList('all', this.userSettings.currentPage, this.userSettings.itemsPerPage);
        }
    }

    onSelectLocation(location: Location | { id; company_id }): void {
        this.selectedLocation = location as Location;
        this.selectLocation.emit(this.selectedLocation);
    }

    onMouseoverLocation(location: Location): void {
        this.mouseoverLocation.emit(location);
    }

    setUserSettings(params: { searchValue: string; settings: UserSettings; count?: number }): void {
        this.userSettings = params.settings;
        this.searchValue = params.searchValue;
        this.habilitatedLocationsListCount = params.count;

        if (!this.searchValue) {
            this.getLocationList('all', this.userSettings.currentPage, this.userSettings.itemsPerPage);
        }
    }
}
