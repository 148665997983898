import { AbstractControl, ValidationErrors } from '@angular/forms';
import { get } from 'lodash-es';

export class ValidateRepeatableLength {
    static createValidator(maxLength: number, fieldType?: string) {
        return (control: AbstractControl): ValidationErrors => {
            const errorObject = {
                [fieldType || 'repeatable']: { valid: false, maxLength },
            };
            return maxLength && get(control, 'value.length') > maxLength ? errorObject : null;
        };
    }
}
