import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';

// lib
import { IUserAndLocationIds, IContactV2 } from '@solocal-manager/sirius/core/models';

export enum ContactsActionTypes {
    LOAD_CONTACTS = '[CONTACTS] get contacts list',
    LOAD_CONTACTS_FAIL = '[CONTACTS] get contacts list failed',
    LOAD_CONTACTS_SUCCESS = '[CONTACTS] get contacts list succeeded',
    MUTATE_CONTACTS = '[CONTACTS] Mutate contacts list',
    UPDATE_CONTACTS = '[CONTACTS] update contacts list',
    NEW_CONTACTS = '[CONTACTS] new contacts list',
}

export class LoadContacts implements Action {
    readonly type = ContactsActionTypes.LOAD_CONTACTS;

    constructor(public payload: IUserAndLocationIds) {}
}

export class LoadContactsSuccess implements Action {
    readonly type = ContactsActionTypes.LOAD_CONTACTS_SUCCESS;

    constructor(public payload: IContactV2[], public gls?: IUserAndLocationIds, public isNewContact = false) {}
}

export class LoadContactsFail implements Action {
    readonly type = ContactsActionTypes.LOAD_CONTACTS_FAIL;

    constructor(public error: HttpErrorResponse) {}
}

export class MutateContacts implements Action {
    readonly type = ContactsActionTypes.MUTATE_CONTACTS;

    constructor(public payload: IContactV2, public gls: IUserAndLocationIds, public isNewContact: boolean) {}
}

export class UpdateContacts implements Action {
    readonly type = ContactsActionTypes.UPDATE_CONTACTS;

    constructor(public payload: IContactV2, public gls: IUserAndLocationIds) {}
}

export class NewContacts implements Action {
    readonly type = ContactsActionTypes.NEW_CONTACTS;

    constructor(public payload: IContactV2, public gls: IUserAndLocationIds) {}
}

export type ContactsAction =
    | LoadContacts
    | LoadContactsFail
    | LoadContactsSuccess
    | MutateContacts
    | UpdateContacts
    | NewContacts;
