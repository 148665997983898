import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { EnvironmentService } from '@slm/shared/environment';
import { SlmAuthFacade } from '@solocal-manager/auth';
import { AppConfig, AuthConfig } from '@solocal-manager/shared/util-common';
import { PaginationDTO, Photo, Video } from '@solocal-manager/sirius/support/base-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface PostVideoResponse {
    data: {
        id: string;
        height: number;
        width: number;
        video: string;
        title: string;
        description: string;
        length: string;
        company_id: string;
        thumbnail: string;
        locations: { id: string }[];
    };
}

interface VideoMetaData {
    locationId: number;
    title: string;
}

@Injectable({ providedIn: 'root' })
export class GalleryService {
    private readonly slmAuthFacade = inject(SlmAuthFacade);
    private readonly authConfig = inject(AuthConfig);
    private readonly appConfig = inject(AppConfig);

    constructor(
        public http: HttpClient,
        public envService: EnvironmentService,
    ) {}

    getImageList(
        companyId: string,
        pageNumber: number,
        pageSize: number,
        imageType?: string,
        minHeight?: number,
        minWidth?: number,
        maxHeight?: number,
        maxWidth?: number,
        locationIds?: string[],
    ): Observable<PaginationDTO<Photo>> {
        const requestUrl = `${this.envService.apiUrl}/consumer/companies/${companyId}/photos`;

        let params = new HttpParams()
            .set('page_number', '' + pageNumber)
            .set('page_size', '' + pageSize)
            .set('ordering', '-created');

        if (imageType) {
            params = params.append('type', imageType);
        }

        if (minHeight) {
            params = params.set('min_height', '' + minHeight);
        }
        if (minWidth) {
            params = params.set('min_width', '' + minWidth);
        }
        if (maxHeight) {
            params = params.set('max_height', '' + maxHeight);
        }
        if (maxWidth) {
            params = params.set('max_width', '' + maxWidth);
        }

        if (!!locationIds && locationIds.every(id => !!id)) {
            params = params.append('locations', locationIds.join(','));
        }

        return this.http.get<PaginationDTO<Photo>>(requestUrl, { params });
    }

    getUploadedVideoList(companyId: string, pageNumber?: number, pageSize?: number): Observable<PaginationDTO<Video>> {
        const requestUrl = `${this.envService.apiUrl}/consumer/companies/${companyId || 'all'}/uploaded-videos`;

        const params = { ordering: '-created' };

        if (!!pageNumber && !!pageSize) {
            params['page_number'] = pageNumber;
            params['page_size'] = pageSize;
        }

        return this.http.get<PaginationDTO<Video>>(requestUrl, { params });
    }

    patchUploadedVideo(uploadedVideoId: string, title?: string, description?: string): Observable<Video> {
        const requestUrl = `${this.envService.apiUrl}/consumer/uploaded-videos/${uploadedVideoId}`;

        const data = {};

        if (title) {
            data['title'] = title;
        }
        if (description) {
            data['description'] = description;
        }

        return this.http.patch<{ data: Video }>(requestUrl, data).pipe(map(result => result.data));
    }

    deleteUploadedVideo(uploadedVideoId: string): Observable<any> {
        const requestUrl = `${this.envService.apiUrl}/consumer/uploaded-videos/${uploadedVideoId}`;

        return this.http.delete<any>(requestUrl);
    }

    uploadVideo(file: File, metadata: VideoMetaData): Observable<Video> {
        const formData = new FormData();
        formData.append('video', file, file.name);
        formData.append('title', file.name);
        formData.append('locations', JSON.stringify([{ id: metadata.locationId }]));

        return new Observable(subscriber => {
            this.slmAuthFacade
                .getToken()
                .then(token => {
                    const xhr = new XMLHttpRequest();

                    xhr.onreadystatechange = () => {
                        if (xhr.readyState === XMLHttpRequest.DONE) {
                            if (xhr.status === 201) {
                                const result: PostVideoResponse = JSON.parse(xhr.response);
                                subscriber.next(result.data);
                            } else {
                                subscriber.error(xhr.response);
                            }
                            subscriber.complete();
                        }
                    };
                    xhr.open('POST', `${this.envService.apiUrl}/consumer/companies/all/uploaded-videos`, true);
                    xhr.setRequestHeader('Authorization', `Bearer ${token}`);
                    xhr.setRequestHeader('Solocal-Frontend-Type', this.authConfig.frontendType);
                    xhr.setRequestHeader('Solocal-Frontend-Version', this.appConfig.version);
                    xhr.send(formData);
                })
                .catch(err => {
                    console.error(err);
                    subscriber.error('Error when retrieving token');
                });
        });
    }
}
