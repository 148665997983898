import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { capitalize } from 'lodash-es';
import * as moment from 'moment';
import { take } from 'rxjs/operators';

@Pipe({
    name: 'dailyMenuPrefixer',
    pure: false,
})
export class DailyMenuPrefixerPipe implements PipeTransform {
    constructor(private translate: TranslateService) {}

    getPrependText(content, item): string {
        if (item.daily_menu && item.daily_menu.meal_date && item.daily_menu.meal_type) {
            const lang = this.translate.currentLang;
            const mealDate = item.daily_menu.meal_date;
            let mealType = item.daily_menu.meal_type;
            const weekday = capitalize(moment(mealDate).locale(lang).format('dddd'));
            const dayOfMonth = moment(mealDate).locale(lang).format('DD');
            const monthName = capitalize(moment(mealDate).locale(lang).format('MMMM'));
            let menuText = 'Menu of';

            this.translate
                .get(menuText)
                .pipe(take(1))
                .subscribe(t => (menuText = t));
            this.translate
                .get(mealType)
                .pipe(take(1))
                .subscribe(m => (mealType = m));

            const prepend = [menuText, weekday, dayOfMonth, monthName, mealType].join(' ');

            return prepend + ': ' + content;
        }

        return content;
    }

    transform(content: any, args: any): string {
        const item = args && args.item ? args.item : {};

        if (item && item.content_type && item.content_type === 'daily_menu') {
            return this.getPrependText(content, item);
        }

        return content;
    }
}
