import { Action } from '@ngrx/store';

import { Page } from '@solocal-manager/sirius/support/base-models';
import { Location } from '@solocal-manager/sirius/support/base-models';

import { PublisherStatus, DisconnectPageResponse } from '../models';

export enum PageActionTypes {
    GET_PAGE = '[GET_PAGE] get page by pageId',
    GET_PAGE_SUCCESS = '[GET_PAGE_SUCCESS] get page by pageId success',
    PATCH_PAGE = '[PATCH_PAGE] patch page',
    PATCH_PAGE_SUCCESS = '[PATCH_PAGE_SUCCESS] patch page success',
    POST_PAGE_DISCONNECT = '[POST_PAGE_DISCONNECT] post disconnect page',
    POST_PAGE_DISCONNECT_SUCCESS = '[POST_PAGE_DISCONNECT_SUCCESS] post disconnect page success',
    DELETE_PAGE = '[DELETE_PAGE] delete page by pageId',
    DELETE_PAGE_SUCCESS = '[DELETE_PAGE_SUCCESS] delete page by pageId success',
    RESET_PAGE_STORE = '[RESET PAGE STORE] reset page store',
    DELETE_DUPLICATE = '[DELETE_DUPLICATE] delete duplicate',
    DELETE_DUPLICATE_SUCCESS = '[DELETE_DUPLICATE_SUCCESS] delete duplicate success',
}

export class GetPage implements Action {
    readonly type = PageActionTypes.GET_PAGE;

    constructor(public params: { pageId: string }) {}
}

export class GetPageSuccess implements Action {
    readonly type = PageActionTypes.GET_PAGE_SUCCESS;

    constructor(public result: Page) {}
}

export class PatchPage implements Action {
    readonly type = PageActionTypes.PATCH_PAGE;

    constructor(public params: { pageId: string; page?: Page | PublisherStatus }) {}
}

export class PatchPageSuccess implements Action {
    readonly type = PageActionTypes.PATCH_PAGE_SUCCESS;

    constructor(public result: Page) {}
}

export class PostPageDisconnect implements Action {
    readonly type = PageActionTypes.POST_PAGE_DISCONNECT;

    constructor(public params: { pageId: string }) {}
}

export class PostPageDisconnectSuccess implements Action {
    readonly type = PageActionTypes.POST_PAGE_DISCONNECT_SUCCESS;

    constructor(public params: DisconnectPageResponse) {}
}

export class DeletePage implements Action {
    readonly type = PageActionTypes.DELETE_PAGE;

    constructor(public params: { pageId: string; location: Location }) {}
}

export class DeletePageSuccess implements Action {
    readonly type = PageActionTypes.DELETE_PAGE_SUCCESS;

    constructor(public result: any) {}
}

export class ResetPageStore implements Action {
    readonly type = PageActionTypes.RESET_PAGE_STORE;

    constructor() {}
}

export class DeleteDuplicate implements Action {
    readonly type = PageActionTypes.DELETE_DUPLICATE;

    constructor(
        public params: {
            publisherId: string;
            publisherPageId: string;
            locationId?: string;
        },
    ) {}
}

export class DeleteDuplicateSuccess implements Action {
    readonly type = PageActionTypes.DELETE_DUPLICATE_SUCCESS;

    constructor(public result: any) {}
}

export type PageActionsUnion =
    | GetPage
    | GetPageSuccess
    | PatchPage
    | PatchPageSuccess
    | DeletePage
    | DeletePageSuccess
    | ResetPageStore
    | PostPageDisconnect
    | PostPageDisconnectSuccess
    | DeleteDuplicate
    | DeleteDuplicateSuccess;
