import { Action } from '@ngrx/store';

import { Page } from '@solocal-manager/sirius/support/base-models';

export enum PageListActionTypes {
    GET_PAGE_LIST = '[GET_PAGE_LIST] get page list',
    GET_PAGE_LIST_SUCCESS = '[GET_PAGE_LIST_SUCCESS] get page list success',
    RESET_PAGE_LIST = '[RESET_PAGE_LIST] reset page list',
}

export interface IGetPageListRequest {
    companyId?: string;
    publishers?: string[];
    locations?: string[];
    state?: string;
    pageType?: string;
    pageSize?: number;
    pageNumber?: number;
}

export class GetPageList implements Action {
    readonly type = PageListActionTypes.GET_PAGE_LIST;

    constructor(public params: IGetPageListRequest) {}
}

export class GetPageListSuccess implements Action {
    readonly type = PageListActionTypes.GET_PAGE_LIST_SUCCESS;

    constructor(public result: Page[]) {}
}

export class ResetPageList implements Action {
    readonly type = PageListActionTypes.RESET_PAGE_LIST;

    constructor() {}
}

export type PageListActionsUnion = GetPageList | GetPageListSuccess | ResetPageList;
