import { Pipe, PipeTransform } from '@angular/core';

/**
 * This pipe will make phone format like the following:
 * "0660607880" => "06 60 60 78 80"
 */
@Pipe({
    name: 'phoneFormat',
})
export class PhoneFormatPipe implements PipeTransform {
    transform(n: string): any {
        return n
            ? n
                  .toString()
                  .replace(/ /g, '')
                  .replace(/\B(?=(\d{2})+(?!\d))/g, ' ')
            : '';
    }
}
