import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'isYoutubeEmbedUrl',
})
export class IsYoutubeEmbedUrlPipe implements PipeTransform {
    transform(url) {
        return url;
    }
}
