import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'shortName',
})
export class ShortNamePipe implements PipeTransform {
    transform(value: string): any {
        if (value) {
            return value.charAt(0);
        }
    }
}
