import { Page } from '@solocal-manager/sirius/support/base-models';

import { PageActionTypes, PageActionsUnion } from '../actions';

export type PagesState = Page;

const initialState: any = {};

export function pagesReducer(state = initialState, action: PageActionsUnion): PagesState {
    switch (action.type) {
        case PageActionTypes.RESET_PAGE_STORE: {
            return null;
        }
        case PageActionTypes.GET_PAGE_SUCCESS: {
            return action.result;
        }
        case PageActionTypes.PATCH_PAGE_SUCCESS: {
            return action.result;
        }
        case PageActionTypes.DELETE_PAGE_SUCCESS: {
            return action.result;
        }
        default:
            return state;
    }
}
