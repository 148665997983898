import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';

//actions
import { EpjSearchActions } from '@solocal-manager/sirius/core/core';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
    public form: UntypedFormGroup;
    public userInput: string;

    constructor(private formBuilder: UntypedFormBuilder, private store: Store<any>) {
        this.userInput = '5e857a67-aed7-4d9e-ba18-bb3f5fa01058';

        this.form = this.formBuilder.group({
            searchstring: [''],
        });

        this.form.valueChanges.subscribe(controls => {
            if (controls.searchstring) {
                this.search(controls.searchstring);
            }
        });
    }

    ngOnInit() {}

    search(searchstring: string) {
        this.store.dispatch({
            type: EpjSearchActions.GET_EPJ_SEARCH,
            payload: { partner_id_extended: searchstring },
            // payload: { cviv_todo: true }
        });
    }
}
