import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@slm/shared/environment';
import { Observable } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class EpjSearchService {
    epjSearchUrl: string;

    constructor(
        public http: HttpClient,
        public envService: EnvironmentService,
    ) {
        this.epjSearchUrl = `${this.envService.apiUrl}/partner/companies`;
    }

    getEpjSearch(p: any): Observable<any> {
        let params = new HttpParams();
        Object.keys(p).forEach(k => (params = params.set(k, p[k])));

        return this.http.get(this.epjSearchUrl, { params });
    }
}
