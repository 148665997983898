import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EnvironmentService } from '@slm/shared/environment';
import { MultiSearchInternalResult } from '@solocal-manager/sirius/core/models';
import { flatten, union } from 'lodash-es';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Md5 } from 'ts-md5/dist/md5';


import { MultiSearchActions } from '../actions';

@Injectable({ providedIn: 'root' })
export class MultiSearchService {
    _latestTerm: string;
    resultCache = {};

    constructor(
        public http: HttpClient,
        public store: Store<any>,
        public logger: NGXLogger,
        public envService: EnvironmentService,
    ) {}

    set latestTerm(term: string) {
        this._latestTerm = Md5.hashAsciiStr(term) as string;
    }

    get latestTerm(): string {
        return this._latestTerm;
    }

    search(term: string, config?: any): Observable<MultiSearchInternalResult[]> {
        this.latestTerm = term;
        const searchUrl = `${this.envService.apiUrl}/consumer/companies/search`;

        const params = new HttpParams().set('q', term);

        return this.http.get(searchUrl, { params }).pipe(
            map((body: any) => {
                const data = body ? body.data : undefined;
                const meta = body ? body.meta : {};
                const query = meta.q;
                let companyResults = [];
                let locationResults = [];

                if (data && query && this.isEqualTerm(query)) {
                    companyResults = config.locationOnly ? [] : this.prepareCompanyResults(data);
                    locationResults = this.prepareLocationResults(data);
                }

                body.data.internalResults = this.getResults(query, companyResults, locationResults);

                this.store.dispatch({
                    type: MultiSearchActions.GET_MULTI_SEARCH_SUCCESS,
                    payload: body.data.internalResults,
                });

                return body.data.internalResults;
            }),
        );
    }

    /* just cache results for lastest search term
     if a previous request takes longer return results for current term
  */
    getResults(term: string, companyResults, locationResults) {
        if (this.isEqualTerm(term)) {
            const combinedResults = flatten(union([companyResults, locationResults]));
            const newCache = {};
            newCache[this.latestTerm] = combinedResults;
            this.resultCache = newCache;
        }

        const resultsFromCache = this.resultCache[this.latestTerm];
        return resultsFromCache ? resultsFromCache : [];
    }

    isEqualTerm(term: string): boolean {
        const md5Term = Md5.hashAsciiStr(term) as string;
        return md5Term === this.latestTerm ? true : false;
    }

    prepareLocationResults(data: any[]) {
        return flatten(
            data.map(company =>
                company.locations.map(location => {
                    return {
                        name: location.name,
                        id: location.id,
                        customerId: company.customer_id,
                        companyId: company.id,
                        companyName: company.name,
                        resultType: 'location',
                        address: location.address,
                    };
                }),
            ),
        );
    }

    prepareCompanyResults(data: any[]) {
        return flatten(
            data.map(company => {
                return {
                    name: company.name,
                    id: company.id,
                    customerId: company.customer_id,
                    companyId: company.id,
                    companyName: company.name,
                    resultType: 'company',
                };
            }),
        );
    }

    preparePartnerIdResults(data: any[]) {
        return flatten(
            data.map(company => {
                return {
                    name: company.partner_id,
                    id: company.partner_id,
                    companyId: company.id,
                    resultType: 'partnerId',
                };
            }),
        );
    }
}
