import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { API_CONFIG } from '@slm/shared/environment';
import {
    Location,
    LocationRetentionEligibilityData,
    LocationRetentionEligibilityResponse,
} from '@solocal-manager/sirius/support/base-models';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LocationService implements OnDestroy {
    locationUpdateErrorOccured$: Subject<any> = new Subject();
    private readonly apiConfig = inject(API_CONFIG);

    constructor(
        public http: HttpClient,
        public translate: TranslateService,
    ) {}

    ngOnDestroy(): void {
        this.locationUpdateErrorOccured$.complete();
    }

    getLocation(locationId: string) {
        const currentLang = this.translate.currentLang;
        const requestUrl = `${this.apiConfig.versioned}/consumer/locations/${locationId}?fields=custom_data&language=${currentLang}&fe_version=2021-03-03`;

        return this.http.get(requestUrl).pipe(map((result: any) => result.data));
    }

    updateLocation(location: Location): Observable<Location> {
        const currentLang = this.translate.currentLang;
        const requestUrl = `${this.apiConfig.versioned}/consumer/locations/${location.id}?fields=custom_data&language=${currentLang}&fe_version=2021-03-03`;

        return this.http.put(requestUrl, location).pipe(map((result: any) => (result.data ? result.data : {})));
    }

    patchLocation(locationId: string, locationPartial: any): Observable<Location> {
        const currentLang = this.translate.currentLang;
        const requestUrl = `${this.apiConfig.versioned}/consumer/locations/${locationId}?fields=custom_data&language=${currentLang}&fe_version=2021-03-03`;

        return this.http
            .patch(requestUrl, locationPartial)
            .pipe(map((result: any) => (result.data ? result.data : {})));
    }

    emitLocationUpdateError(error: any): void {
        this.locationUpdateErrorOccured$.next(error);
    }

    getLocationRetention(epj: string): Observable<LocationRetentionEligibilityData> {
        const url = `${this.apiConfig.siriusProxy}/retention-eligibility`;
        const params = new HttpParams().append('epj', epj);

        return this.http
            .get<LocationRetentionEligibilityResponse>(url, { observe: 'response', params })
            .pipe(map(res => (res.status !== 404 ? res?.body.data : { epj, isEligible: false })));
    }
}
