import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@slm/shared/environment';
import { Page } from '@solocal-manager/sirius/support/base-models';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


import { DisconnectPageResponse, PublisherStatus } from '../models';

@Injectable({ providedIn: 'root' })
export class PagesService {
    constructor(
        public http: HttpClient,
        public envService: EnvironmentService,
    ) {}

    get(pageId: string): Observable<any> {
        const getPageUrl = `${this.envService.apiUrl}/consumer/pages/${pageId}`;

        return this.http.get(getPageUrl).pipe(
            map((res: any) => res.data),
            catchError((err: any) => observableThrowError(err)),
        );
    }

    patch(pageId: string, page: Page | PublisherStatus): Observable<any> {
        const getPageUrl = `${this.envService.apiUrl}/consumer/pages/${pageId}`;

        return this.http.patch(getPageUrl, page || {}).pipe(
            map((res: any) => res.data),
            catchError((err: any) => observableThrowError(err)),
        );
    }

    delete(pageId: string): Observable<any> {
        const disconnectUrl = `${this.envService.apiUrl}/consumer/pages/${pageId}`;

        return this.http.delete(disconnectUrl).pipe(catchError((err: any) => observableThrowError(err)));
    }

    facebookDeepLink(locationId: string): Observable<any> {
        const transferUrl = `${this.envService.apiUrl}/consumer/locations/${locationId}`;

        return this.http.get(transferUrl).pipe(catchError((err: any) => observableThrowError(err)));
    }

    postPageDisconnect(pageId: string): Observable<DisconnectPageResponse> {
        const disconnectUrl = `${this.envService.apiUrl}/consumer/pages/${pageId}/disconnect`;
        return this.http
            .post<DisconnectPageResponse>(disconnectUrl, {})
            .pipe(catchError((err: any) => observableThrowError(err)));
    }

    deleteDuplicate(publisherId: string, publisherPageId: string, locationId?: string) {
        const deleteDuplicateUrl = `${this.envService.apiUrl}/consumer/pages/delete-duplicate`;
        const body = {
            publisher_id: publisherId,
            publisher_page_id: publisherPageId,
        };
        if (locationId) {
            body['location_id'] = locationId;
        }
        return this.http.post(deleteDuplicateUrl, body).pipe(catchError((err: any) => observableThrowError(err)));
    }
}
