import { Component, Input, OnChanges } from '@angular/core';
import { Store } from '@ngrx/store';

import { EpjSearchActions } from '@solocal-manager/sirius/core/core';

import { SearchResultsComponent } from './search-results.component';

//actions

@Component({
    selector: 'app-search-epj-results',
    templateUrl: './search-results.component.html',
    styleUrls: ['./search-results.component.scss'],
})
export class SearchEpjResultsComponent extends SearchResultsComponent implements OnChanges {
    @Input() userInput;
    private searchString: string;
    public showReload = false;

    constructor(public store: Store<any>) {
        super(store, 'location-list');
    }

    ngOnChanges(changes: any) {
        this.searchString = changes.userInput.currentValue;
    }

    reloadResults() {
        if (this.searchString) {
            this.store.dispatch({
                type: EpjSearchActions.GET_EPJ_SEARCH,
                payload: { partner_id_extended: this.searchString },
            });
        }
    }
}
