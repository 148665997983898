import { Component } from '@angular/core';

import { PublisherConnectWebsiteComponent } from '../publisher-connect-website/publisher-connect-website.component';

@Component({
    selector: 'slm-publisher-connect-tripadvisor',
    templateUrl: './publisher-connect-tripadvisor.component.html',
})
export class PublisherConnectTripadvisorComponent extends PublisherConnectWebsiteComponent {
    shouldShowUrlInput: boolean;

    get shouldShowAdditionalSearch(): boolean {
        return (
            this.publisherSearchResults.length === 0 &&
            this.publisherId === 'tripadvisor' &&
            (this.state === this.publisherSearchStates.failure || this.state === this.publisherSearchStates.success)
        );
    }

    showUrlInput() {
        this.shouldShowUrlInput = true;
    }
}
