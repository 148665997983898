import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-toggle-switch',
    templateUrl: './toggle-switch.component.html',
    styleUrls: ['./toggle-switch.component.scss'],
})
export class ToggleSwitchComponent {
    @Input() checked: boolean;
    @Output() onchange: EventEmitter<any> = new EventEmitter<any>();

    onSwitchChange(event): void {
        this.onchange.emit({});
    }
}
