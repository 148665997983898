import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { LocationsState } from '@slm/location/state';
import { INavigationLogic } from '@solocal-manager/sirius/core/models/navigation/interfaces';

import { NavLogicRouteIds } from '@solocal-manager/sirius/core/models/navigation/nav-logic-route-id.enum';
import { NavigationDescriptor } from '@solocal-manager/sirius/core/models/navigation/navigation-descriptor';
import { selectLocationEntities } from '@solocal-manager/sirius/core/reducers/location-entities.reducer';
import { NavigationUtils } from '@solocal-manager/sirius/core/utils/navigation';
import { take } from 'rxjs/operators';

@Injectable()
export class LocationInformationCondNavLogic implements INavigationLogic {
    constructor(private store: Store<{ locations: LocationsState }>) {}

    getEffectiveRoute(routeId: string): NavigationDescriptor {
        if (routeId !== NavLogicRouteIds.LOCATION_INFORMATION_CONDITIONAL) {
            throw Error(`LocationInformationsCondNavLogic is not the right navigation logic for id '${routeId}'`);
        }

        let navDesc: NavigationDescriptor = {
            route: '',
            queryParams: {},
        };

        this.store
            .select(selectLocationEntities)
            .pipe(take(1))
            .subscribe(decoLocations => {
                if (decoLocations?.length > 1) {
                    navDesc.route = '/locations';
                } else {
                    const location = decoLocations[0];

                    navDesc = NavigationUtils.createLocationNavDesc('/content/info', location.epj);
                }
            });

        return navDesc;
    }
}

type LocationListStore = Store<{ locations: LocationsState }>;

export function locationInformationCondNavLogicFact(injector: Injector): INavigationLogic {
    const creationInjector = Injector.create({
        providers: [
            {
                provide: LocationInformationCondNavLogic,
                useFactory: (store: LocationListStore) => new LocationInformationCondNavLogic(store),
                deps: [Store],
            },
        ],
        parent: injector,
    });
    return creationInjector.get(LocationInformationCondNavLogic);
}
