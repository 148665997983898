import { RouteAppActionTypes } from '@solocal-manager/sirius/core/actions';
import { ActionWithPayload, IErrorApp } from '@solocal-manager/sirius/core/models';

export type RouteAppState = IErrorApp[];

export const initialState: RouteAppState = [];

export function routeAppReducer(state = initialState, action: ActionWithPayload<IErrorApp>): RouteAppState {
    switch (action.type) {
        case RouteAppActionTypes.SET_ERROR_APP: {
            return [...state, action.payload];
        }
        default:
            return state;
    }
}
