import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { v4 as uuid } from 'uuid';

import { GeocodeService } from '@solocal-manager/sirius/core/services';
import { IGooglePlace } from '@solocal-manager/sirius/support/base-models';

@Injectable({
    providedIn: 'root',
})
export class ServiceAreaHelperFacade {
    constructor(private readonly geocodeService: GeocodeService) {}

    getGooglePlaceIds(q: string, countryCode: string, sessionToken: string): Observable<IGooglePlace[]> {
        return this.geocodeService.getGooglePlaceIds(q, countryCode, sessionToken);
    }

    generateSessionToken(sessionToken: string) {
        /*
        Place Autocomplete can use session tokens to group together autocomplete requests for billing purposes.
        A session token is a random string that is used to uniquely identify a session.
        A session consists of the activities required to resolve user input to a place.
        When a session token is passed (using the optional sessiontoken parameter), autocomplete requests are not billed independently, but are instead billed once after a full autocomplete result is returned.
        If the sessiontoken parameter is omitted, each request is billed independently. See the pricing sheet for details.

        Warning:
        Be sure to pass a unique session token for each new session.
        Using the same token for more than one session will result in each request being billed individually.
        Using a version 4 UUID is recommended.
        */
        sessionToken = uuid();
    }
}
