import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LocationsActions } from '@slm/location/state';
import { selectUser, User } from '@slm/user/state';
import {
    CustomerActions,
    GetCurrentCompany,
    GetLocationList,
    MultiSearchActions,
    MultiSearchService,
} from '@solocal-manager/sirius/core/core';
import { UserSettings } from '@solocal-manager/sirius/core/models';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subject, Subscriber } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'slm-multi-search',
    templateUrl: './multi-search.component.html',
    styleUrls: ['./multi-search.component.scss'],
})
export class MultiSearchComponent implements OnInit, OnDestroy {
    @Input()
    locationOnly;
    @Input()
    moveLocation;
    @Output()
    moveLocationChanged: EventEmitter<any> = new EventEmitter<any>();

    asyncSelected = '';
    typeaheadLoading: boolean;
    typeaheadNoResults: boolean;
    dataSource: Observable<any>;
    applicationId: string;
    userSettings: UserSettings = { itemsPerPage: 20, currentPage: 1 };
    _hideEpjNew = false;
    private isDestroyed$ = new Subject<void>();

    constructor(
        public router: Router,
        public multiSearchService: MultiSearchService,
        public store: Store<any>,
        public logger: NGXLogger,
    ) {
        this.dataSource = new Observable((subscriber: Subscriber<string>) => {
            if (this.asyncSelected.length > 2) {
                // min length of input to start searching
                subscriber.next(this.asyncSelected);
            }
        }).pipe(
            tap(x => this.logger.debug('<MultiSearchComponent> asyncSelected', this.asyncSelected)),
            switchMap((term: string) => {
                this.hideEpjNew(false);
                return this.multiSearchService.search(term, {
                    locationOnly: this.locationOnly,
                });
            }),
        );
    }

    get displayEpjNew(): boolean {
        return !this._hideEpjNew && this.asyncSelected.length === 8 ? true : false;
    }

    ngOnInit(): void {
        this.store
            .select(selectUser)
            .pipe(takeUntil(this.isDestroyed$))
            .subscribe((user: User) => {
                this.applicationId = user?.application?.id;
            });
    }

    changeTypeaheadLoading(e: boolean): void {
        // this.logger.debug('<MultiSearchComponent> changeTypeaheadLoading: ', e);
        this.typeaheadLoading = e;
    }

    changeTypeaheadNoResults(e: boolean): void {
        this.typeaheadNoResults = e;
    }

    hideEpjNew(hide: boolean) {
        this._hideEpjNew = hide;
    }

    closeCreateLocation(successfull: boolean) {
        this.typeaheadNoResults = false;
        this._hideEpjNew = true;
    }

    typeaheadOnSelect(e: TypeaheadMatch): void {
        const doAction = !this.moveLocation ? this.navigateToResultObject(e) : this.doMoveLocation(e);
    }

    navigateToResultObject(e: TypeaheadMatch): void {
        const companyId = e.item.companyId;
        const locationId = e.item.id;
        const customerId = e.item.customerId;
        const tab = e.item.tab;

        this.store.dispatch({
            type: MultiSearchActions.SET_MULTI_SEARCH_SELECTED,
            payload: e.item,
        });

        this.store.dispatch({
            type: CustomerActions.SET_CURRENT_CUSTOMER_ID,
            payload: customerId,
        });

        this.store.dispatch(new GetCurrentCompany(companyId));

        if (e.item.resultType === 'location') {
            this.store.dispatch(LocationsActions.loadLocation({ id: locationId, selectIt: true }));
            this.moveLocationChanged.emit(true);
            this.navLocation(locationId, companyId, tab);
        } else if (e.item.resultType === 'company') {
            this.handleCompanyResult(companyId);
            this.navCompanyStatistics(companyId);
        }
    }

    doMoveLocation(e: TypeaheadMatch): void {
        this.moveLocationChanged.emit(e);
    }

    handleCompanyResult(companyId: string) {
        this.getLocationList(companyId, 1, this.userSettings.itemsPerPage);
    }

    getLocationList(companyId: string, pageNumber: number, pageSize: number): void {
        this.store.dispatch(
            new GetLocationList({
                companyId,
                config: {
                    pageSize,
                    pageNumber,
                },
            }),
        );
    }

    navLocation(locationId: string, companyId: string, tab = 'edit') {
        this.router.navigate(['app', this.applicationId, 'company', companyId, 'location', locationId, tab]);
    }

    navCompanyStatistics(companyId: string) {
        this.router.navigate(['app', this.applicationId, 'company', companyId, 'location']);
    }

    ngOnDestroy() {
        this.isDestroyed$.next(undefined);
        this.isDestroyed$.complete();
    }
}
