import { Component, OnInit, OnDestroy } from '@angular/core';

import { LoadingService } from '@solocal-manager/sirius/core/core';

@Component({
    selector: 'app-loading-indicator',
    template: `<div class="loading-indicator">
        <div *ngIf="apiLoading" class="loading-bar"></div>
    </div>`,
    styleUrls: ['./loading-indicator.component.scss'],
})
export class LoadingIndicatorComponent implements OnInit, OnDestroy {
    apiLoading = false;
    apiLoadingSubscribtion;

    constructor(public loadingService: LoadingService) {}

    ngOnInit(): void {
        this.apiLoadingSubscribtion = this.loadingService.isLoading.subscribe(loading => {
            this.apiLoading = loading;
        });
    }

    ngOnDestroy(): void {
        this.apiLoadingSubscribtion.unsubscribe();
    }
}
