import { Pipe, PipeTransform } from '@angular/core';

import { Location } from '@solocal-manager/sirius/support/base-models';

@Pipe({
    name: 'locationImage',
})
export class LocationImagePipe implements PipeTransform {
    transform(location: Location, type: string): any {
        if (location && location.photos && location.photos.length && type) {
            return location.photos.filter(photo => photo.type === type)[0];
        }

        return {};
    }
}
