import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'dateFormatWithTranslation',
})
export class DateFormatWithTranslationPipe implements PipeTransform {
    transform(val: string, lang: string, format: string = null): string {
        moment.locale(lang);

        if (val?.endsWith('Z')) {
            return moment(val)
                .local()
                .format(format || 'D MMMM YYYY');
        }
        if (val?.length === 'YYYY-DD-MM'.length) {
            return moment(val).format(format || 'D MMMM YYYY');
        }
        return moment(val)
            .locale(lang)
            .format(format || 'D MMMM YYYY');
    }
}
