import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { EnvironmentService } from '@slm/shared/environment';
import {
    Publisher,
    PublisherSettings,
    PublisherSettingsState,
    PublisherStatus,
} from '@solocal-manager/sirius/core/models';
import { Page } from '@solocal-manager/sirius/support/base-models';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class PublisherService implements OnDestroy {
    publisherModified$: Subject<string> = new Subject();

    constructor(
        private http: HttpClient,
        private envService: EnvironmentService,
    ) {}

    getPublishers(): Observable<Publisher[]> {
        const requestUrl = `${this.envService.apiUrl}/consumer/publishers`;
        return this.http.get<Publisher[]>(requestUrl).pipe(map((res: any) => res.data));
    }

    getPublisherStatus(locationId: string): Observable<PublisherStatus[]> {
        const requestUrl = `${this.envService.apiUrl}/consumer/locations/${locationId}/publishers-status`;
        return this.http.get<PublisherStatus[]>(requestUrl).pipe(map((res: any) => res.data));
    }

    updatePublisherSettings(
        locationId: string,
        publisherId: string,
        state: PublisherSettingsState,
    ): Observable<PublisherSettings> {
        const requestUrl = `${this.envService.apiUrl}/consumer/locations/${locationId}/publishers-settings`;
        return this.http
            .post<{ data: PublisherSettings }>(requestUrl, {
                publisher_id: publisherId,
                state,
            })
            .pipe(map(res => res.data));
    }

    patchPage(pageId: string, page?: any): Observable<Page> {
        const requestUrl = `${this.envService.apiUrl}/consumer/pages/${pageId}`;
        return this.http.patch<{ data: Page }>(requestUrl, page || {}).pipe(map(res => res.data));
    }

    createPage(locationId: string, publisherId: string): Observable<Page> {
        const requestUrl = `${this.envService.apiUrl}/consumer/locations/${locationId}/pages`;
        return this.http
            .post<{ data: Page }>(requestUrl, {
                publisher_id: publisherId,
            })
            .pipe(map(res => res.data));
    }

    listPages(locationId: string): Observable<Page[]> {
        const requestUrl = `${this.envService.apiUrl}/consumer/locations/${locationId}/pages`;
        return this.http.get<Page[]>(requestUrl).pipe(map((res: any) => res.data));
    }

    deletePage(pageId: string): Observable<any> {
        const requestUrl = `${this.envService.apiUrl}/consumer/pages/${pageId}`;
        return this.http.delete(requestUrl);
    }

    disconnectPage(pageId: string): Observable<Page> {
        const requestUrl = `${this.envService.apiUrl}/consumer/pages/${pageId}/disconnect`;
        return this.http.post<{ data: Page }>(requestUrl, {}).pipe(map(res => res.data));
    }

    emitPublisherModified(publisherId: string) {
        this.publisherModified$.next(publisherId);
    }

    ngOnDestroy() {
        this.publisherModified$.complete();
    }
}
