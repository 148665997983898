import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectUserPermissions } from '@slm/user/state';
import { find } from 'lodash-es';
import { BehaviorSubject, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

enum UserPermissions {
    AppSuperUser = 'application-superuser-support_user',
    MovePos = 'change-pos-company_user',
    ManageEpjSearch = 'manage-epj-search-support_user',
}

@Injectable({ providedIn: 'root' })
export class AdminUiService {
    onCompanyPage: BehaviorSubject<boolean> = new BehaviorSubject(false);
    onMoveLocationSuccess: Subject<object | boolean> = new Subject();
    locationChanged: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(private store: Store<any>) {}

    mayMoveLocation(): boolean {
        let mayMove = false;

        this.store
            .select(selectUserPermissions)
            .pipe(take(1))
            .subscribe((permissions: any[]) => {
                mayMove = find(permissions, row => row.codename === UserPermissions.MovePos) ? true : false;
            });

        return mayMove;
    }

    companyPage(active: boolean) {
        this.onCompanyPage.next(!!active);
    }

    moveLocationSuccess(success: object | boolean) {
        this.onMoveLocationSuccess.next(success);
    }
}
