import { Component } from '@angular/core';
import { AsyncValidator, ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

import { urlsConfig } from '@solocal-manager/sirius/core/config/urls.config';
import { UrlCheckerService } from '@solocal-manager/sirius/core/services/url-checker.service';
import { ValidateUrl, UrlAsyncValidator } from '@solocal-manager/sirius/core/validators';

import { SubscriptionCloserComponent } from '../../class/subscription-closer-component';
import { CombinedUrlValidator } from '../validators';

const initObject = {
    url: '',
    type: '',
    description: '',
    display_url: '',
};

@Component({
    selector: 'wpm-app-input-combined-urls',
    template: '',
})
export class WpmInputCombinedUrlsComponent
    extends SubscriptionCloserComponent
    implements ControlValueAccessor, AsyncValidator
{
    combinedUrlsForm: UntypedFormGroup;
    additionalUrlTypes = urlsConfig.singleUrlTypes;

    constructor(public formBuilder: UntypedFormBuilder, public urlCheckerService: UrlCheckerService) {
        super();
        this.buildForm();
        this.registerPropagations();
    }

    propagateChange: any = () => {};
    validateFn: any = () => {};

    registerOnChange(fn) {
        this.propagateChange = () => {
            let cleanedData = [];
            urlsConfig.multipleUrlTypes.forEach(type => {
                cleanedData = [...cleanedData, ...this.combinedUrlsForm.get(type).value];
            });
            urlsConfig.singleUrlTypes.forEach(type => {
                cleanedData = [
                    ...cleanedData,
                    {
                        ...initObject,
                        url: this.combinedUrlsForm.get(type).value,
                        type,
                    },
                ];
            });

            cleanedData = cleanedData.filter(urlObj => urlObj.url);

            return fn(cleanedData);
        };
    }

    registerOnTouched() {}

    validate(c: UntypedFormGroup) {
        return this.validateFn(c);
    }

    buildForm() {
        const controls = {};
        urlsConfig.multipleUrlTypes.forEach(type => {
            controls[type] = [[]];
        });
        urlsConfig.singleUrlTypes.forEach(type => {
            controls[type] = [
                '',
                [ValidateUrl.createValidator()],
                [UrlAsyncValidator.createValidator(this.urlCheckerService)],
            ];
        });
        this.combinedUrlsForm = this.formBuilder.group(controls);
    }

    registerPropagations() {
        this.combinedUrlsForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
            this.propagateChange();
        });

        this.combinedUrlsForm.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(state => {
            this.propagateChange();
        });
    }

    writeValue(urls) {
        if (urls) {
            urlsConfig.multipleUrlTypes.forEach(type => {
                const value = urls.filter(url => url.type === type);
                this.combinedUrlsForm.get(type).setValue(value);
            });
            urlsConfig.singleUrlTypes.forEach(type => {
                const value = urls.find(url => url.type === type);
                this.combinedUrlsForm.get(type).setValue(value?.url || '');
            });

            this.validateFn = CombinedUrlValidator.create(this.combinedUrlsForm);
        }
    }
}
