import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'property',
})
export class PropertyPipe implements PipeTransform {
    transform(value: any[], propertyName: string, propertyValue: any): any[] {
        return value.filter(item => item[propertyName] === propertyValue);
    }
}
