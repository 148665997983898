import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { SlmAuthFacade } from '@solocal-manager/auth';

import { PermissionStrategy } from '../../../enums';
import { PermissionEvaluation } from '../../../models';

@Injectable({
    providedIn: 'root',
})
export class HasUserRoleService implements PermissionEvaluation {
    constructor(private readonly slmAuthFacade: SlmAuthFacade) {}
    readonly type = PermissionStrategy.HasUserRole;

    hasPermission(params: { role: string | string[] }): Observable<boolean> {
        return of(this.checkUserRole(params?.role));
    }

    private checkUserRole(role: string | string[]): boolean {
        return [].concat(role).some(role => this.slmAuthFacade.getUserRoles()?.includes(role));
    }
}
