import { createAction, props } from '@ngrx/store';

import { User } from './user.model';

export const getUser = createAction('[USER] get user');

export const getUserSuccess = createAction('[USER] get user success', props<{ user: User }>());

export const getUserError = createAction('[USER] get user error', props<{ error: any }>());

export const logout = createAction('[USER] logout');
