import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'publisherStateIcon',
})
export class PublisherStateIconPipe implements PipeTransform {
    public icons = {
        active: 'checkmark-circle-outline',
        pending: 'time-outline',
        error: 'alert-circle-outline',
        not_connected: 'alert-circle-outline',
    };

    transform(state: string): any {
        const icon = this.icons[state];

        return icon ? icon : '';
    }
}
