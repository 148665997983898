<div [formGroup]="formRepeatable">
    <div formArrayName="data">
        <div [formGroupName]="i" *ngFor="let email of formData.controls; let i = index">
            <div class="row">
                <div class="form-group col-md-10 col-sm-10 col-10">
                    <label *ngIf="i === 0" for="inputEmail">{{ 'E-mail' | translate }}</label>
                    <input type="text" class="form-control" autocomplete="nope" formControlName="email" />
                </div>

                <div class="form-group col-md-2 col-sm-2 col-2">
                    <button
                        type="button"
                        [ngClass]="{ 'allign-button': i === 0 }"
                        class="btn btn-primary remove-field-button on-the-right"
                        (click)="removeInput(i)"
                    >
                        X
                    </button>
                </div>

                <input type="hidden" id="inputType" formControlName="type" />
            </div>
            <div class="row">
                <div class="col-md-10 col-sm-10 col-10 email-err-msg-holder">
                    <app-input-error-messages [control]="email.controls.email"></app-input-error-messages>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-12 email-form-add-holder">
        <button class="btn btn-primary remove-field-button" (click)="addInput()">{{ 'Add Email' | translate }}</button>
    </div>
</div>
