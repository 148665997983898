/* eslint-disable @nx/enforce-module-boundaries */
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getCoordinates, getLogo, getPagesJaunesActivities } from '@slm/location/state';
import { Coordinates, Location } from '@solocal-manager/sirius/support/base-models';
import { Subject } from 'rxjs';

import {
    Behavior,
    DomIcon,
    DomMarker,
    Event,
    Feature,
    Group,
    InfoBubble,
    Map,
    MapEvents,
    Platform,
    Point,
    UI,
    ZoomControl,
} from './here-type';

@Component({ template: '<div #map><div #wheel></div></div>' })
export class WpmHereMapComponent implements OnChanges, OnDestroy, AfterViewInit {
    @Input() locations: Location[] = [];
    @Input() showPopups = true;
    @Input() showControls = true;
    @Input() draggable = true;
    @Input() draggableMarker = false;
    @Input() roundedCorners = true;

    @Output() markerPlaced: EventEmitter<{ lat: number; lng: number }> = new EventEmitter();

    @ViewChild('map') mapDiv?: ElementRef<HTMLDivElement>;

    private behavior: Behavior;
    private bounds: Coordinates[];
    private map: Map;
    private markers: Group;
    private mapUI: UI;
    private resizeEvent: () => void;
    protected isDestroyed$: Subject<void> = new Subject();

    /** Without this, you'll not able to scroll over the map because we are disabling the ZoomBehavior
     * Add the following div inside the map : <div #wheel class="wheel-fix"></div>
     * And the following CSS :
     * .wheel-fix {
            position: absolute;
            background-color: transparent;
            width: 100%;
            height: 100%;
            z-index: 1000;
            display: none;
        }
     * See : https://www.stevefenton.co.uk/blog/2021/03/here-maps-scroll-wheel-temporary-fix/
    */
    private isScrolling = false;
    private scrollingTimeout: number;
    @ViewChild('wheel') wheelFixer?: ElementRef<HTMLDivElement>;

    constructor(protected translate: TranslateService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (!!changes.locations && !!changes.locations.currentValue) {
            this.bounds = undefined;
            this.filterLocations();
        }
        if (this.locations && this.locations.length > 1) {
            // Disable dragging when multiple markers are present
            this.draggableMarker = false;
            if (this.map) {
                this.updateMap();
            }
        }
        if (!!this.map && this.locations && this.locations.length === 1) {
            // Happens on changes but not on first load
            this.updateMap();
        }
    }

    /**
     * Only happens on first load
     */
    ngAfterViewInit(): void {
        // /!\ This timeout is to ensure the map layout is loaded
        setTimeout(() => {
            this.filterLocations();
            this.initMap();
        });
    }

    ngOnDestroy(): void {
        this.isDestroyed$.next(null);
        this.isDestroyed$.complete();
    }

    /**
     * Happens when we have multiple locations and the list changes
     * Adding more markers and update map bounds
     */
    public updateMap(): void {
        this.resetMarkers();
        this.setMarkers();
        this.setMapBounds();
    }

    private initMap(): void {
        const API_KEY = '5y3DGLlIGC8mO1NZbtzgBreZShtYmNRNHYHUoKQjC5c';
        if (!this.map && this.mapDiv) {
            const platform: Platform = new Platform({ apikey: API_KEY });
            const layers = platform.createDefaultLayers();
            const map = new Map(this.mapDiv?.nativeElement, layers['vector'].normal.map, {
                pixelRatio: window.devicePixelRatio,
            });

            this.map = map;
            this.mapUI = new UI(this.map);
            this.resizeEvent = this.resizeMap.bind(this);

            window.addEventListener('resize', this.resizeEvent);

            this.enableBehavior();
            this.enableZoomControl();
            this.setMarkers();
            this.setMapBounds();
            // SteveFenton => Temporary fix scrolling over the map
            this.enableScrollOverMap();
        }
    }

    //#region SteveFenton => Temporary fix scrolling over the map
    private enableScrollOverMap() {
        if (this.wheelFixer) {
            this.map?.addEventListener('wheel', () => {
                if (this.isScrolling) {
                    clearTimeout(this.scrollingTimeout);
                    this.scrollingTimeout = window.setTimeout(this.disableScrollOverMap.bind(this), 500);
                } else {
                    this.isScrolling = true;
                    this.wheelFixer.nativeElement.style.display = 'block';
                    this.scrollingTimeout = window.setTimeout(this.disableScrollOverMap.bind(this), 500);
                }
            });
        }
    }

    private disableScrollOverMap() {
        this.isScrolling = false;
        this.wheelFixer.nativeElement.style.display = 'none';
    }
    //#endregion

    /**
     * Only keeps locations that have coordinates
     */
    private filterLocations(): void {
        this.locations = this.locations.filter(location => {
            const coords = getCoordinates(location);
            return !!coords && !!coords.latitude && !!coords.longitude;
        });
    }

    private resizeMap(): void {
        this.map?.getViewPort().resize();
    }

    protected enableBehavior(): void {
        if (!this.behavior) {
            const behavior: Behavior = new Behavior(new MapEvents(this.map));
            behavior.disable();

            this.behavior = behavior;
        }
        this.draggable && this.behavior.enable(Feature.PANNING);
    }

    protected enableZoomControl(): void {
        if (this.showControls) {
            this.mapUI?.addControl('zoomControl', new ZoomControl());
        }
    }

    public setMapBounds(): void {
        if (!this.bounds) {
            this.bounds = this.locations.map(location => getCoordinates(location));
        }

        if (this.bounds.length) {
            const markers: DomMarker[] = this.bounds.map(b => new DomMarker({ lat: b.latitude, lng: b.longitude }));
            const markerGroup = new Group();
            markerGroup.addObjects(markers);
            const bbox = markerGroup.getBoundingBox();

            this.map?.getViewModel().setLookAtData({ bounds: bbox });

            if (this.bounds.length === 1) {
                this.map?.setZoom(17);
            }
        }
    }

    //#region Markers
    private getMarkerIcon(): DomIcon {
        const outerElement = document.createElement('div');
        outerElement.style.cursor = 'pointer';
        outerElement.className = 'locations-map-marker';

        return new DomIcon(outerElement);
    }

    protected setMarkers(): void {
        const group: Group = new Group();

        this.locations.forEach(location => {
            const coords = getCoordinates(location);
            if (coords) {
                const marker: DomMarker = new DomMarker(
                    {
                        lat: coords.latitude,
                        lng: coords.longitude,
                    },
                    {
                        data: null,
                        icon: this.getMarkerIcon(),
                    },
                );
                marker.draggable = this.draggableMarker;
                this.registerDraggingEvents(marker);

                if (this.showPopups) {
                    this.createPopup(marker, location);
                    group.addEventListener('tap', (evt: Event) => {
                        const target = evt.target as DomMarker;
                        const point = target.getGeometry() as Point;
                        const content = target.getData();
                        const bubble: InfoBubble = new InfoBubble(point, { content });
                        this.mapUI?.addBubble(bubble);
                    });
                }

                group.addObject(marker);
            }
        });
        this.markers = group;
        this.map?.addObject(group);
    }

    private resetMarkers(): void {
        if (this.markers) {
            this.map?.removeObject(this.markers);
            this.markers = null;
        }
    }

    //#region Markers drag events
    private registerDraggingEvents(marker: DomMarker): void {
        if (this.draggableMarker) {
            this.registerDragStartEvent(marker);
            this.registerDragEvent(marker);
            this.registerDragEndEvent(marker);
        }
    }

    private registerDragStartEvent(marker: DomMarker): void {
        marker.addEventListener('dragstart', (evt: Event) => {
            const target = evt.target as DomMarker;
            const pointer = evt.currentPointer;
            const targetPosition = this.map?.geoToScreen(target.getGeometry() as Point);

            target['offset'] = new Point(pointer.viewportX - targetPosition.x, pointer.viewportY - targetPosition.y);
            this.behavior.disable();
        });
    }

    private registerDragEvent(marker: DomMarker): void {
        marker.addEventListener('drag', (evt: Event) => {
            const target = evt.target as DomMarker;
            const pointer = evt.currentPointer;

            target.setGeometry(
                this.map?.screenToGeo(pointer.viewportX - target['offset'].x, pointer.viewportY - target['offset'].y),
            );
        });
    }

    private registerDragEndEvent(marker: DomMarker): void {
        marker.addEventListener('dragend', (evt: Event) => {
            const pointer = evt.currentPointer;
            const coords = this.map?.screenToGeo(pointer.viewportX, pointer.viewportY);
            this.markerPlaced.emit({
                lat: coords.lat,
                lng: coords.lng,
            });

            this.enableBehavior();
        });
    }
    //#endregion
    //#endregion

    private createPopup(marker: DomMarker, location: Location): void {
        const address = location.address;
        const mainActivity = getPagesJaunesActivities(location)[0];
        const logo = getLogo(location);

        marker.setData(`
            <div class="locations-map__popup">
                <div class="locations-map__popup-logo">
                    <img src="${logo ? logo.thumbnail : 'assets/images/no-image.png'}">
                </div>

                <div class="locations-map__popup-info">
                    <div>
                    <div class="locations-map__popup-title bold font16">${location.name.toUpperCase()}</div>
                    <div class="font12">
                        ${mainActivity ? mainActivity.name : ''}
                    </div>
                    <div class="font14">
                        ${address.house_number ? `${address.house_number}, ` : ''}${address.address_line1}
                        ${address.address_line2 ? `<br>${address.address_line2}` : ''}
                    </div>
                    <div class="font14">
                        ${address.zip_code} ${address.city}
                    </div>
                    </div>

                    <div class="locations-map__popup-link">
                        <a href="/#/app/2/location/${location.epj}/content">
                        ${this.translate.instant('Complete the information')}
                        </a>
                    </div>
                </div>
            </div>
        `);
    }
}
