import { get, findIndex, merge } from 'lodash-es';

import { ContactsActionTypes, ContactsAction } from '@solocal-manager/sirius/core/actions';
import { IContactV2 } from '@solocal-manager/sirius/core/models';

export interface IContactsState {
    loaded: boolean;
    loading: boolean;
    info: IContactV2[];
}

export const initialState: IContactsState = {
    loaded: false,
    loading: false,
    info: [],
};

export function contactsReducer(state = initialState, action: ContactsAction): any {
    switch (action.type) {
        case ContactsActionTypes.LOAD_CONTACTS:
            return {
                ...state,
                loading: true,
            };
        case ContactsActionTypes.LOAD_CONTACTS_SUCCESS:
            return {
                ...state,
                info: [...action.payload],
                loading: false,
                loaded: true,
            };
        case ContactsActionTypes.NEW_CONTACTS:
            return {
                ...state,
                loading: true,
                loaded: false,
            };
        case ContactsActionTypes.UPDATE_CONTACTS:
            return {
                ...state,
                loading: true,
                loaded: false,
            };

        case ContactsActionTypes.MUTATE_CONTACTS:
            const payload = [...state.info];
            if (action.isNewContact) {
                payload.push(action.payload);
            } else {
                const contactIndex = findIndex(payload, c => c.id === action.payload.id);
                payload[contactIndex] = action.payload;
                // merge(payload[contactIndex], action.payload);
            }
            return {
                ...state,
                info: payload,
                loading: false,
                loaded: true,
            };
        case ContactsActionTypes.LOAD_CONTACTS_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
            };

        default:
            return state;
    }
}
