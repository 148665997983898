const isFunction = fn => typeof fn === 'function';
const isObject = obj => typeof obj === 'object';
const isSubscriber = sub => sub && sub.constructor.name === 'Subscriber';

export function AutoUnsubscribe<T extends { new (...args: any[]): {} }>({
    blackList = [],
    event = 'ngOnDestroy',
} = {}) {
    return (constructor: any) => {
        const original = constructor.prototype[event];

        if (!isFunction(original)) {
            console.warn(`${constructor.name} is using @AutoUnsubscribe but does not implement OnDestroy`);
        }

        constructor.prototype[event] = function () {
            for (const p in this) {
                if (blackList.includes(p)) {
                    continue;
                }

                if (
                    this.hasOwnProperty(p) &&
                    isObject(this[p]) &&
                    isSubscriber(this[p]) &&
                    isFunction(this[p].unsubscribe)
                ) {
                    this[p].unsubscribe();
                }
            }

            return isFunction(original) && original.apply(this, arguments);
        };
    };
}
