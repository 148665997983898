import { Component, OnChanges, Input } from '@angular/core';
import * as moment from 'moment';

// models
import { Location } from '@solocal-manager/sirius/support/base-models';

@Component({
    selector: 'app-production-information',
    templateUrl: './production-information.component.html',
    styleUrls: ['./production-information.component.scss'],
})
export class ProductionInformationComponent implements OnChanges {
    createdDateFormatted: string;
    lastModified: string;

    @Input() location: Location;

    constructor() {}

    ngOnChanges() {
        if (this.location && this.location.custom_data) {
            this.createdDateFormatted = this.formatDate(this.location.custom_data.created);
            this.lastModified = this.formatDate(this.location.modified);
        }
    }

    formatDate(date) {
        return moment(date).format('DD-MM-YYYY HH:mm:ss');
    }
}
