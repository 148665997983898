import { UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { of, timer, Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IRguSearchResultLane } from '@solocal-manager/sirius/core/models';

import { AddressHelperFacade } from '../facade/address.helper.facade';

export class ValidateRguAddress {
    static createValidator(addressHelpeFacade: AddressHelperFacade, locationId: string, debouncedTime?: number) {
        debouncedTime = debouncedTime || 500;

        return (group: UntypedFormGroup): Observable<ValidationErrors> => {
            const zipCode = group.get('zip_code').value;
            const city = group.get('city').value;
            const lane = group.get('address_line1').value;

            const laneInvalid = {
                addressLaneInvalid: true,
            };

            if (!zipCode || !lane) {
                return of(laneInvalid);
            }

            return timer(debouncedTime).pipe(
                switchMap(() =>
                    addressHelpeFacade.getLanes(lane, zipCode, city, locationId).pipe(
                        map((lanes: IRguSearchResultLane[]) => {
                            return this.isLaneValid(lane, lanes) ? null : laneInvalid;
                        }),
                        catchError(error => of(laneInvalid)),
                    ),
                ),
            );
        };
    }

    static isLaneValid(lane: string, searchResultLanes: IRguSearchResultLane[]): boolean {
        return (
            searchResultLanes.map((l: IRguSearchResultLane) => l.l4).includes(lane) ||
            searchResultLanes
                .map((l: IRguSearchResultLane) => `${l.numVoie} ${l.typeVoie} ${l.libVoiePj}`)
                .includes(lane)
        );
    }
}
