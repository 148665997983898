import { ENavIDs, ENavType, NavOrderModel, NavOrderRootModel } from '@solocal-manager/sirius/core/models';

export const navCommonParts = {
    hidden: false,
    hovered: false,
    hasChild: false,
};

export const sideNavItems: NavOrderRootModel[] = [
    {
        id: ENavIDs.ONBOARDING_JOURNEY_GROUP,

        child: [
            {
                id: ENavIDs.ONBOARDING_JOURNEY,
            },
        ],
    },
    {
        id: ENavIDs.HOME_STATISTICS,
        child: [
            {
                id: ENavIDs.HOME,
            },
            {
                id: ENavIDs.MY_STATISTICS,
            },
        ],
    },
    {
        id: ENavIDs.LOCATION_INFO__PHOTOS_VIDEOS,
        child: [
            {
                id: ENavIDs.LOCATION_INFORMATION,
            },
            {
                id: ENavIDs.PHOTOS_VIDEOS_INFO,
            },
        ],
    },
    {
        id: ENavIDs.REVIEWS__INSTANT_MESSAGING__POSTS_PUBLICATIONS,
        child: [
            {
                id: ENavIDs.REVIEWS,
            },
            {
                id: ENavIDs.INSTANT_MESSAGING,
            },
            {
                id: ENavIDs.NEWS_1,
            },
        ],
    },
    {
        id: ENavIDs.MY_CLIENTS,
        child: [
            {
                id: ENavIDs.CONTACT,
            },
            {
                id: ENavIDs.QUOTE_REQUEST,
            },
        ],
    },
    {
        id: ENavIDs.AGENDA__CLICK_AND_COLLECT,
        child: [
            {
                id: ENavIDs.AGENDA,
            },
        ],
    },
    {
        id: ENavIDs.MY_PUBLICATIONS_GROUP,
        child: [
            {
                id: ENavIDs.MY_PUBLICATIONS,
                child: [],
            },
        ],
    },
    {
        id: ENavIDs.WEBSITE_NAV_GROUP,
        child: [
            {
                id: ENavIDs.MY_WEBSITES,
            },
        ],
    },
    {
        id: ENavIDs.COMPANY_PERKS_GROUP,
        child: [
            {
                id: ENavIDs.COMPANY_PERKS,
            },
        ],
    },
    {
        id: ENavIDs.PLUGIN_TEST_GROUP,
        child: [],
    },
];

export const headerNavItems: NavOrderModel[] = [
    {
        id: ENavIDs.HELP_TUTORIALS,
    },
    {
        id: ENavIDs.NOTIFICATIONS,
    },
    {
        id: ENavIDs.ACCOUNTS,
        child: [
            ENavIDs.ACCOUNTS_EDIT,
            ENavIDs.MY_INVOICES,
            ENavIDs.MY_ORDERS,
            ENavIDs.PREFERENCES,
            ENavIDs.MY_SOLUTIONS,
            ENavIDs.MY_APPOINTMENTS,
            ENavIDs.MY_SERVICES,
        ],
    },
];
