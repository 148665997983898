import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { LocationsSelectors } from '@slm/location/state';
import { Location } from '@solocal-manager/sirius/support/base-models';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

/**
 * @deprecated
 * we should use injection token instead
 */
@Injectable({ providedIn: 'root' })
export class PathService implements OnDestroy {
    private _basePath = '';
    private _location: Location;
    subscriptions: Subscription[] = [];

    constructor(
        public logger: NGXLogger,
        private router: Router,
        public store: Store<any>,
    ) {
        const routerSub = this.router.events.subscribe(event => {
            const urlLocationMatch = this.router.url.match(/offer-presentation\/([0-9]+)/);
            const locationId = urlLocationMatch ? urlLocationMatch[1] : '';
            const locationSub = this.store
                .pipe(select(LocationsSelectors.getLocationById(locationId)), take(1))
                .subscribe(loc => (this.locationFromPath = loc));

            this.subscriptions.push(locationSub);
        });

        this.subscriptions.push(routerSub);
    }

    get basePath(): string {
        return this._basePath;
    }

    set basePath(basePath: string) {
        this._basePath = basePath;
    }

    // Only using this in offer presentation page!!!
    set locationFromPath(location: Location) {
        this._location = location;
    }

    get locationFromPath() {
        return this._location;
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
