import { createFeatureSelector, createSelector } from '@ngrx/store';

import { LocationsSelectors } from '@slm/location/state';
import { EPageIDs, FilterItem, PageItem } from '@solocal-manager/sirius/core/models';
import { Dictionary } from '@solocal-manager/sirius/support/base-models';
import { keys, pickBy } from 'lodash-es';

import { AppNavPageState } from './nav-page.reducer';

export const getNavPages = createFeatureSelector<AppNavPageState>('navPageConf');

export const getActivEPageID = createSelector(getNavPages, (state: AppNavPageState) => state.activePage);

export const getActivePage = createSelector(getNavPages, (state: AppNavPageState) => state.activePageConf);

export const getPrevPage = createSelector(getNavPages, (state: AppNavPageState) => state.prevPage);

export const getPageFilters = createSelector(getActivePage, (state: PageItem) => state.pageFilters);

export const getNavItemOrder = createSelector(getNavPages, (state: AppNavPageState) => state.navItemOrder);

export const getNavItemMap = createSelector(getNavPages, (state: AppNavPageState) => state.navItemMap);

export const getSelectedPageData = createSelector(getActivEPageID, getActivePage, (selectedPage, activePage) => {
    return {
        pageID: selectedPage as EPageIDs,
        page: activePage,
    };
});

export const getHeaderTitle = createSelector(getActivePage, (activePage: PageItem) => activePage?.titleSection);

export const getHeaderBreadCrumbs = createSelector(getActivePage, (activePage: PageItem) => activePage?.breadCrumbs);

export const getHeaderBreadcrumbsWithHome = createSelector(getHeaderBreadCrumbs, breadcrumbs => [
    {
        label: 'Home',
        gtmAction: 'homepage',
        url: '/home',
    },
    ...breadcrumbs,
]);

export const getPageSummary = createSelector(getActivePage, (activePage: PageItem) => {
    if (!activePage?.pageSummary) {
        return undefined;
    }

    return {
        ...activePage.pageSummary,
        hideOnMobile: activePage.pageSummary.hideOnMobile ?? true,
    };
});

// NAV PAGES FILTER SELECTORS
export const selectPageFilters = createSelector(getPageFilters, filterState => {
    if (!filterState?.order?.length || !filterState?.filters) {
        return [];
    }

    const filters: FilterItem[] = [];
    filterState.order.forEach(element => {
        filters.push(filterState?.filters[element]);
    });
    return filters;
});

export const getFilters = createSelector(getActivePage, (state: PageItem) => state?.pageFilters?.filters);

export const getFilterOrder = createSelector(getActivePage, (state: PageItem) => state?.pageFilters?.order);

export const selectedFilterValues = createSelector(
    getFilters,
    getFilterOrder,
    (filterObj, order): Dictionary<string | string[]> => {
        if (!order?.length) {
            return {};
        }
        const filters = {};
        Object.values(filterObj).forEach(item => {
            filters[item.id] = item.selected;
        });
        return filters;
    },
);

export const selectedLocationFilterValue = createSelector(
    selectedFilterValues,
    (filtered): Dictionary<string | string[]> => {
        if (Object.keys(filtered).length === 1 && Object.keys(filtered).includes('slm_location_selector')) {
            return filtered;
        } else {
            return {};
        }
    },
);

/**
 * Normalizes the selected location state of location filter to epj
 * This is necessary since the Statistics page filter uses location id for selected state which is not supported by Addon SDK
 * Can be safely removed when all Statistics pages are inside the Addon
 */
export const selectAdaptedFilterValuesForSDK = createSelector(
    selectedFilterValues,
    LocationsSelectors.getAllLocations,
    (filter, locations) => {
        const newFilter = { ...filter };
        if (newFilter?.location_filter) {
            newFilter.location_filter = []
                .concat(newFilter.location_filter)
                .map(selected => locations?.find(location => [location.id, location.epj].includes(selected))?.epj)
                .filter(value => !!value);
        }

        return newFilter;
    },
);

export const selectFilterView = createSelector(getPageFilters, filterState => filterState?.showFilters);

export const selectSecondaryFilterView = createSelector(
    getPageFilters,
    filterState => filterState?.showSecondaryFilters,
);

export const selectLocationFilterSelectedIds = createSelector(selectPageFilters, state => {
    if (!state) {
        return undefined;
    }
    return state.find(f => f.id === 'location_filter')?.selected;
});

export const selectLocationFilterEpjs = createSelector(
    selectLocationFilterSelectedIds,
    LocationsSelectors.getEpjToIdMap,
    LocationsSelectors.getLocationIds,
    (locationIds, epjToIdMap, allLocationIds) => {
        if (!locationIds) {
            return undefined;
        }
        let ids = Array.isArray(locationIds) ? [...locationIds.map(id => '' + id)] : [locationIds];
        if (!locationIds.length) {
            ids = allLocationIds.map(id => '' + id);
        }
        return keys(pickBy(epjToIdMap, val => ids.includes(val['id'])));
    },
);

export const selectLocationAndSelectedEpjs = createSelector(
    selectLocationFilterEpjs,
    LocationsSelectors.getSelectedLocation,
    LocationsSelectors.getSelectedEpjsForMultiSelect,
    (filteredEpjs, location, selectedEpjs) => ({ location, epjs: filteredEpjs ?? selectedEpjs }),
);
