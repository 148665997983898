import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { API_CONFIG } from '@slm/shared/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TotalPublisherStatistics } from '../models';

@Injectable({ providedIn: 'root' })
export class PublisherStatisticsService {
    totalStatistics: TotalPublisherStatistics;

    private readonly http = inject(HttpClient);
    private readonly apiConfig = inject(API_CONFIG);

    get(companyId?: string): Observable<TotalPublisherStatistics> {
        this.totalStatistics = {
            location_count: 0,
            statistics: [
                {
                    publisher_id: '',
                    pages_count: 0,
                    connection_pct: 0,
                },
            ],
        };

        const requestUrl = companyId
            ? `${this.apiConfig.versioned}/consumer/locations/publishers-statistics?companies=${companyId}`
            : `${this.apiConfig.versioned}/consumer/locations/publishers-statistics`;

        return this.http
            .get<TotalPublisherStatistics>(requestUrl)
            .pipe(map((res: any) => (this.totalStatistics = res.data)));
    }
}
