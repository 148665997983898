import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { getPublisherStatus } from '@solocal-manager/sirius/core/core';
import {
    DeletePage,
    ResetPageStore,
    PublisherConnectService,
    PublisherSearchService,
} from '@solocal-manager/sirius/core/core';
import { PageMatches, PublisherStatus } from '@solocal-manager/sirius/core/models';
import { Location } from '@solocal-manager/sirius/support/base-models';

import { ConnectModalService } from '../../../services';

const inputPublisherPatternMap = {
    facebook: /www.facebook.com\/.+/,
    gmb: /business.google.com\/manage\/.+/,
    tripadvisor: /^https:\/\/(www.)?tripadvisor.[^/]+\/.+-d\d+-/,
    default: /.*/,
};

export function createPublisherUrlValidator(publisherId) {
    return (c: UntypedFormControl) => {
        const pattern = inputPublisherPatternMap[publisherId];
        const valid = pattern.test(c.value);
        const errorKey = publisherId + 'Url';
        const error = {};
        error[errorKey] = {
            valid: false,
        };

        return valid ? null : error;
    };
}

@Component({
    selector: 'app-input-search-publisher',
    templateUrl: './input-search-publisher.component.html',
    styleUrls: ['./input-search-publisher.component.scss'],
})
export class InputSearchPublisherComponent implements OnInit, OnChanges, OnDestroy {
    destroyed$: Subject<boolean> = new Subject<boolean>();
    searchForm: UntypedFormGroup;
    customSearchResults: PageMatches[] = [];
    noResults = false;
    claimed = false;
    searchState: string;
    publisherStatus: PublisherStatus[];

    @Input()
    location: Location;
    @Input()
    publisherId: string;
    @Input()
    connectEmpty = false;
    @Input()
    businessManagerId: string;

    @Output()
    onClaimed: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        public logger: NGXLogger,
        public store: Store<any>,
        public formBuilder: UntypedFormBuilder,
        public publisherSearchService: PublisherSearchService,
        public publisherConnectService: PublisherConnectService,
        public ref: ChangeDetectorRef,
        private connectModalService: ConnectModalService,
    ) {}

    ngOnInit() {
        this.store.pipe(select('page'), takeUntil(this.destroyed$)).subscribe(config => {
            if (config && config.reconnect) {
                const conf = { ...config };

                this.connectPublisher(conf.location.id, conf.publisherId, conf.publisherPageId);
                this.store.dispatch(new ResetPageStore());
            }
        });

        this.store.pipe(select('publisherStatus'), takeUntil(this.destroyed$)).subscribe(publisherStatus => {
            this.publisherStatus = publisherStatus;
        });

        this.connectModalService.onClose.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            this.initResults();
        });
    }

    ngOnChanges(): void {
        this.initResults();
    }

    initResults() {
        this.customSearchResults = [];

        if (this.publisherId) {
            this.claimed = false;
            this.searchForm = this.formBuilder.group({
                phrase: ['', [Validators.required, createPublisherUrlValidator(this.publisherId)]],
            });
        }
    }

    hasPhrase(): boolean {
        return !!this.searchForm.controls.phrase;
    }

    submit(): void {
        this.noResults = false;
        this.customSearchResults = [];
        this.searchState = 'PENDING';

        if (this.searchForm.valid) {
            this.publisherSearchService
                .searchAtPublisher(this.location.id, this.publisherId, {
                    q: this.searchForm.value.phrase,
                    business_manager_id: this.businessManagerId || '',
                })
                .pipe(take(1))
                .subscribe(res => {
                    this.logger.debug('<InputSearchPublisherComponent> submit: ', res);
                    if (res.data && res.data.length) {
                        this.customSearchResults = res.data;
                    } else {
                        this.noResults = true;
                    }
                    this.claimed = false;
                    this.searchState = '';
                    this.onClaimed.emit(this.claimed);
                    this.ref.detectChanges();
                });
        }
    }

    connect(publisherPageId: string): void {
        if (this.publisherId === 'gmb') {
            const gmbPage = this.publisherStatus.find(page => page.publisher_id === 'gmb');

            if (gmbPage && gmbPage.page_id) {
                this.store.dispatch(
                    new DeletePage({
                        location: this.location,
                        pageId: gmbPage.page_id,
                    }),
                );
            }
        } else {
            this.connectPublisher(this.location.id, this.publisherId, publisherPageId);
        }
    }

    connectPublisher(locationId: string, publisherId: string, publisherPageId: string) {
        this.publisherConnectService
            .connect(
                locationId,
                publisherId,
                publisherPageId,
                this.businessManagerId
                    ? {
                          customData: {
                              business_manager_id: this.businessManagerId,
                          },
                      }
                    : undefined,
            )
            .pipe(take(1))
            .subscribe(res => {
                this.logger.debug('<InputSearchPublisherComponent> page has been claimed: ', res);
                this.claimed = true;
                this.onClaimed.emit(this.claimed);

                this.store.dispatch(getPublisherStatus({ params: { location: this.location } }));
            });
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}
