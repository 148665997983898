export enum EPackFeatures {
    socialPost = 'social_posts',
    reviewGeneration = 'review_generation',
    coldContent = 'cold_content',
    reviewComments = 'review_comments',
    phototech = 'phototech',
    statistics = 'statistics',
    createRevgenPos = 'create_revgen_pos',
    chatPJ = 'chat_pj',
    chatFB = 'chat_facebook',
    quotationRequests = 'quotation_requests',
    appointments = 'appointments',
    order = 'order',
    contacts = 'contacts',
    reviewRequests = 'review_requests',
    reviewRequestsLimited = 'review_requests_limited',
    directMarketing = 'direct_marketing',
    dynabuy = 'dynabuy',
    boosterContact = 'booster_contact',
    pjReviewsEligible = 'pj_reviews_eligible',
    website = 'website',
    pjEligible = 'pj_reviews_eligible',
    clientDemands = 'client_demands',
    aiCompletion = 'ai_completion',
}
