export enum FeatureType {
    appointments = 'appointments',
    appointments_v2 = 'appointments_v2',
    chat_pj = 'chat_pj',
    chat_facebook = 'chat_facebook',
    cold_content = 'cold_content',
    contacts = 'contacts',
    create_revgen_pos = 'create_revgen_pos',
    direct_marketing = 'direct_marketing',
    order = 'order',
    phototech = 'phototech',
    quotation_requests = 'quotation_requests',
    review_comments = 'review_comments',
    review_generation = 'review_generation',
    review_requests = 'review_requests',
    social_posts = 'social_posts',
    site_catalog = 'site_catalog',
    statistics = 'statistics',
}
