<slm-publisher-connect-status
    [state]="state"
    [message]="connectionMessage"
    [count]="publisherSearchResults?.length"
></slm-publisher-connect-status>

<table class="table">
    <thead>
        <th class="text-center" *ngIf="withPublisherSubType" scope="col">
            {{ 'Website Type' | translate }}
        </th>
        <th class="text-center" scope="col">{{ 'URL' | translate }}</th>
        <th class="text-center" scope="col">{{ 'Address' | translate }}</th>
        <th class="text-center" scope="col">{{ 'Status' | translate }}</th>
        <th class="text-center" scope="col">{{ 'Action' | translate }}</th>
    </thead>
    <tbody>
        <tr *ngFor="let searchResult of publisherSearchResults">
            <td class="text-center" *ngIf="withPublisherSubType">
                {{ searchResult.publisher_sub_type | translate }}
            </td>
            <td class="text-center">
                <a href="{{ searchResult.publisher_page_url }}" target="_blank">{{
                    searchResult.publisher_page_url
                }}</a>
            </td>
            <td class="text-center">
                {{ searchResult.address?.address_line1 }}, {{ searchResult.address?.zip_code }},
                {{ searchResult.address?.city }},
                {{ searchResult.address?.country }}
            </td>
            <td class="text-center">{{ searchResult.publisher_page_state }}</td>
            <td class="col-md-3 text-center">
                <button class="btn btn-primary publisher-claim-page-btn" (click)="connect(searchResult)">
                    {{ 'Claim this page' | translate }}
                </button>
            </td>
        </tr>
    </tbody>
</table>
