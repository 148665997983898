import { ActionWithPayload, CustomerActions } from '../actions';

export type CustomerState = any;

const initialState: any = {};

export function customerReducer(state = initialState, action: ActionWithPayload<any>): CustomerState {
    switch (action.type) {
        case CustomerActions.SET_CURRENT_CUSTOMER_ID: {
            return action.payload;
        }
        default:
            return state;
    }
}
