export enum ESsoOrigins {
    content = 'content',
    SocialPosts = 'SocialPosts',
    gallery = 'gallery',
    solutions = 'solutions',
    default = '',
    statistic = 'statistic',
    reviews = 'reviews',
    meetings = 'meetings',
    invoices = 'invoices',
}
