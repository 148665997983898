<div class="kpi-error-status-detail">
    <div class="sub-title">
        <a [routerLink]="[basePath, 'total-error', publisherId, state]">
            {{ publisherName$ | async }}
        </a>
    </div>
    <div class="title">{{ 'Error status detail' | translate }}</div>
    <div class="error-message">
        <a [routerLink]="[basePath, 'total-error', publisherId, state]">
            {{ errorMessage }}
        </a>
    </div>

    <div class="locations-table">
        <table>
            <tr class="locations-header">
                <td class="kpi-text">
                    <div (click)="orderBy('location_name')" class="header-title">
                        <span>{{ 'Location name' | translate }}</span>
                        <i *ngIf="sortConfig.location_name" class="glyphicon glyphicon-menu-down"></i>
                        <i *ngIf="!sortConfig.location_name" class="glyphicon glyphicon-menu-up"></i>
                    </div>
                </td>
                <td>
                    <div (click)="orderBy('partner_id')" class="header-title">
                        <span>Epj</span>
                        <i *ngIf="sortConfig.partner_id" class="glyphicon glyphicon-menu-down"></i>
                        <i *ngIf="!sortConfig.partner_id" class="glyphicon glyphicon-menu-up"></i>
                    </div>
                </td>
                <td>
                    <div (click)="orderBy('address_line1')" class="header-title">
                        <span>{{ 'Address' | translate }}</span>
                        <i *ngIf="sortConfig.address_line1" class="glyphicon glyphicon-menu-down"></i>
                        <i *ngIf="!sortConfig.address_line1" class="glyphicon glyphicon-menu-up"></i>
                    </div>
                </td>
                <td>
                    <div (click)="orderBy('zip_code')" class="header-title">
                        <span>{{ 'PC' | translate }}</span>
                        <i *ngIf="sortConfig.zip_code" class="glyphicon glyphicon-menu-down"></i>
                        <i *ngIf="!sortConfig.zip_code" class="glyphicon glyphicon-menu-up"></i>
                    </div>
                </td>
                <td>
                    <div (click)="orderBy('city')" class="header-title">
                        <span>{{ 'City' | translate }}</span>
                        <i *ngIf="sortConfig.city" class="glyphicon glyphicon-menu-down"></i>
                        <i *ngIf="!sortConfig.city" class="glyphicon glyphicon-menu-up"></i>
                    </div>
                </td>
            </tr>
            <tbody class="locations-rows">
                <tr *ngFor="let location of locationList?.data" class="locations-row">
                    <td class="location-name kpi-text">
                        {{ location.location_name }}
                    </td>
                    <td class="location-epj">
                        <a [routerLink]="getLocationLink(location)" target="_blank">
                            {{ location.partner_id }}
                        </a>
                    </td>
                    <td class="location-street">
                        {{ location.address | address: 1 }}
                    </td>
                    <td class="location-zip">
                        {{ location.address.zip_code }}
                    </td>
                    <td class="location-city">{{ location.address.city }}</td>
                </tr>
            </tbody>
            <tr>
                <td colspan="5">
                    <div *ngIf="locationList?.count > 5" class="pagination-holder">
                        <app-pagination
                            [totalCount]="locationList?.count"
                            (pagerChanged)="onPagerValueChanged($event)"
                        ></app-pagination>
                    </div>
                </td>
            </tr>
        </table>
    </div>
</div>
