import { Dictionary } from './dictionary';
import { FeatureType } from './feature-type.enum';

export const featureTypeKeyMap: Dictionary<string> = {
    [FeatureType.appointments]: 'appointment',
};

export interface IFeatureSettings {
    feature: string;
    status: string;
    status_changed_at: string;
    status_paused_from: string;
    status_paused_to: string;
    settings: any;
    error?: IFeatureError;
}

export interface IFeatureError {
    attribute: string;
    code: number;
    message: string;
}
