import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { includes } from 'lodash-es';

@Component({
    selector: 'wpm-input-error-messages',
    template: '',
})
export class WpmInputErrorMessagesComponent {
    @Input() control: UntypedFormControl;
    @Input() dirtyCheck = false;
    @Input() messageTypes: string[];
    errorMessage: Partial<{ type: string; params: any }> = {};
    get isError(): boolean {
        return this.getErrorMessage();
    }
    constructor() {}

    getErrorMessage(): boolean {
        const errors = this.control ? this.control.errors : null;
        if (this.dirtyCheck && !this.control.dirty) {
            return false;
        }

        if (errors) {
            for (const propertyName in errors) {
                if (errors.hasOwnProperty(propertyName)) {
                    if (!this.messageTypes || (this.messageTypes && includes(this.messageTypes, propertyName))) {
                        this.errorMessage = { type: propertyName, params: errors[propertyName] };
                        return true;
                    }
                }
            }
        }
        return false;
    }
}
