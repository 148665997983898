
<td class="publisher-cell publisher-eta">
    <div
        bsModal
        #disconnectModal="bs-modal"
        class="modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="mySmallModalLabel"
        aria-hidden="true"
    >
        <div class="modal-dialog modallg">
            <div class="modal-validate modal-content">
                <div class="modal-header">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="disconnectModal.hide()">
                        <span class="close-x" aria-hidden="true">&times;</span>
                    </button>
                    <strong>
                        <h4 class="modal-title">
                            {{ 'Are you sure you want to disconnect this publisher ?' | translate }}
                        </h4>
                    </strong>
                </div>
                <div class="modal-body">
                    <p>
                        {{
                        'If you disconnect, the client will not be able to update his information and manage his page using Solocal Manager.'
                            | translate
                        }}
                    </p>
                    <br />
                    <strong>
                        {{ 'Warning:' | translate }}
                    </strong>
                    <p>
                        {{
                        'If the customer is not an administrator of his GMB or Facebook page, you will not be able to reconnect it.'
                            | translate
                        }}
                    </p>
                    <td class="validate-button actions-cell disconnect-cell">
                        <button color="primary" class="refresh-button-custom" (click)="disconnect()">
                            {{ 'Disconnect' | translate }}
                        </button>
                    </td>
                </div>
            </div>
        </div>
    </div>
    <ng-template #tooltipETA>
        <div class="tooltip-template">
            <div class="tooltip-header">
                {{ 'ETA: ' | translate }}
                {{ publisherETAs[publisherStatus.publisher_id] }}
            </div>
        </div>
    </ng-template>
    <span class="publisher publisher-{{ publisherStatus.publisher_id }}"></span>
    <span
        [tooltip]="tooltipETA"
        [isDisabled]="!publisherETAs[publisherStatus.publisher_id]"
        placement="bottom"
        class="publisher-title"
        >{{ publisherStatus.name }}</span
    >
</td>

<td class="status-cell">
    <ng-template #popTemplate>
        <div
            class="tooltip-template"
            (mouseenter)="onTooltipMouseEnter(publisherStatus)"
            (mouseleave)="onTooltipMouseLeave(publisherStatus)"
        >
            <div class="tooltip-header">{{ 'The following issues have been reported' | translate }}:</div>
            <div
                class="tooltip-description"
                [innerHtml]="publisherStatus.state_description | publisherStatusStateDescription"
            ></div>
            <div class="tooltip-footer">
                <button class="btn btn-default edit-button" (click)="editLocation(publisherStatus)">
                    {{ 'Edit' | translate }}
                </button>
            </div>
        </div>
    </ng-template>
    <span class=" status-icon {{ publisherStatus.state }}"></span>
    <span
        *ngIf="publisherStatus.state === 'rejected' || publisherStatus.state === 'error'"
        class="status-rejected"
        [tooltip]="popTemplate"
        #pop="bs-tooltip"
        placement="left"
        triggers="manual"
        (mouseover)="pop.show()"
        (mouseout)="onStatusMouseLeave()"
    >
        {{ publisherStatus.state | translate }}
    </span>
    <span
        *ngIf="publisherStatus.state !== 'rejected' && publisherStatus.state !== 'error'"
        [ngClass]="'status-' + publisherStatus.state"
    >
        {{ publisherStatus.state | translate }}
    </span>
    <span *ngIf="!!selfOnboardingFlag" class="self-onboarding-flag">
        {{ selfOnboardingFlag | translate }}
    </span>
</td>
<td class="status-chat-cell">
    <ng-container>
        <span class=" status-icon {{ chatState }}"></span>
        <span
            *ngIf="chatState === 'rejected' || chatState === 'error'"
            class="status-rejected"
            [tooltip]="popTemplate"
            #pop="bs-tooltip"
            placement="left"
            triggers="manual"
            (mouseover)="pop.show()"
            (mouseout)="onStatusMouseLeave()"
        >
            {{ chatState | translate }}
        </span>
        <span *ngIf="chatState !== 'rejected' && chatState !== 'error'" [ngClass]="'status-' + chatState">
            {{ chatState | translate }}
        </span>
    </ng-container>
</td>
<td class="toggle-switch-cell">
    <app-toggle-switch
        *ngIf="canToggleSwitch"
        [checked]="customerAgreed()"
        (onchange)="togglePublisherDoNotConnect()"
    ></app-toggle-switch>
</td>
<td class="view-cell">
    <a *ngIf="canView()" href="{{ publisherStatus.page_url }}" target="_blank" class="actions-view-link">
        <span class="glyphicon glyphicon-eye-open"></span>{{ 'View' | translate }}</a
    >
</td>
<td *ngIf="!navadsColumnHidden" class="actions-cell" [attr.rowspan]="navadsRowsCount || 1">
    <button class="confirm-button-custom" *ngIf="canConnect" (click)="triggerConnect(publisherStatus.publisher_id)">
        {{ 'Connect' | translate }}
    </button>

    <button class="refresh-button-custom" *ngIf="canRefresh(publisherStatus)" (click)="triggerRefresh(publisherStatus)">
        {{ 'Refresh' | translate }}
    </button>

    <button
        class="confirm-button-custom"
        *ngIf="canChangeConnection() && publisherStatus.name !== 'PagesJaunes'"
        (click)="triggerConnect(publisherStatus.publisher_id)"
    >
        {{ 'Change connection' | translate }}
    </button>

    <button *ngIf="canDisconnect && !navadsDisconnect" (click)="openModal()" class="actions-disconnect-button">
        {{ 'Disconnect' | translate }}
    </button>
    <ng-container *ngIf="isChatPublisher()">
        <button
            *ngIf="chatState === 'active'"
            (click)="toggleChat('inactive')"
            class="actions-disconnect-button"
            [disabled]="chatConfigStateLoading"
        >
            {{ 'Disconnect Chat' | translate }}
        </button>

        <button
            *ngIf="canConnectChat()"
            (click)="toggleChat('active')"
            class="confirm-button-custom"
            [disabled]="chatConfigStateLoading"
        >
            {{ 'Connect Chat' | translate }}
        </button>
    </ng-container>
</td>
<td
    *ngIf="!navadsPublisher && navadsDisconnect"
    class="actions-cell disconnect-cell"
    [attr.rowspan]="navadsRowsCount || 1"
>
    <button *ngIf="canDisconnect" (click)="disconnect()" class="actions-disconnect-button">
        {{ 'Disconnect' | translate }}
    </button>
</td>
<td
    *ngIf="navadsRowsCount && navadsDisconnect"
    class="actions-cell disconnect-cell"
    [attr.rowspan]="navadsRowsCount || 1"
>
    <button *ngIf="canDisconnect" (click)="disconnect()" class="actions-disconnect-button">
        {{ 'Disconnect' | translate }}
    </button>

    <div *ngIf="canDisconnect" class="hint-area">
        <table>
            <tr>
                <td><i class="glyphicon glyphicon-info-sign"></i></td>
                <td>
                    <span>{{ 'The whole package will be disconnected' | translate }}</span>
                </td>
            </tr>
        </table>
    </div>
</td>
