export const urlsConfig = {
    multipleUrlTypes: ['website'],
    singleUrlTypes: [
        'reservation',
        'appointment',
        'order',
        'quotation',
        'estimation',
        'catalog_download',
        'catalog_demand',
        'catalog_consult',
    ],
};
