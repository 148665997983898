import { UntypedFormControl, ValidationErrors } from '@angular/forms';
import { PhoneNumber, PhoneNumberUtil } from 'google-libphonenumber';

import { isFrenchShortNumber } from '../utils';

export class PhoneNumberValidator {
    static create(country: string = 'fr') {
        const phoneNumberUtil = PhoneNumberUtil.getInstance();
        return (control: UntypedFormControl): ValidationErrors => {
            let validNumber = country === 'fr' && isFrenchShortNumber(control.value);
            // there is a ShortNumberInfo class but its type declaration isn't yet implemented
            if (!validNumber) {
                try {
                    const phoneNumber: PhoneNumber = phoneNumberUtil.parseAndKeepRawInput(
                        control.value,
                        country.toUpperCase(),
                    );
                    validNumber = phoneNumberUtil.isValidNumber(phoneNumber);
                } catch (e) {}
            }
            return validNumber ? null : { nonNumericValue: { value: control.value } };
        };
    }
}
