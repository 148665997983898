import { forwardRef, Component } from '@angular/core';
import { UntypedFormBuilder, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';

import { WpmInputRepeatable } from '@solocal-manager/sirius/core/class';

@Component({
    selector: 'app-input-email',
    templateUrl: './input-email.component.html',
    styleUrls: ['./input-email.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputEmailComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => InputEmailComponent),
            multi: true,
        },
    ],
})
export class InputEmailComponent extends WpmInputRepeatable {
    _initData = {
        email: ['', [Validators.required, Validators.email]],
        description: ['', []],
        type: ['contact', []],
    };

    constructor(protected formBuilder: UntypedFormBuilder) {
        super(formBuilder);
    }
}
