import { Injectable, Injector } from '@angular/core';
import { Params } from '@angular/router';

import { EnvironmentService } from '@slm/shared/environment';
import { INavigationLogic } from '@solocal-manager/sirius/core/models/navigation/interfaces';
import { NavLogicRouteIds } from '@solocal-manager/sirius/core/models/navigation/nav-logic-route-id.enum';
import { NavigationDescriptor } from '@solocal-manager/sirius/core/models/navigation/navigation-descriptor';

@Injectable()
class SitesReseauxNavLogic implements INavigationLogic {
    constructor(private envService: EnvironmentService) {}

    getEffectiveRoute(routeId: string, params?: Params): NavigationDescriptor {
        if (routeId !== NavLogicRouteIds.SITES_RESEAUX) {
            throw new Error(`SiteReseauxNavLogic is not the right nav logic for id '${routeId}'`);
        }
        let queryParams: Params = { epj: params?.epj };
        const route = `/location/${params?.epj}/content/publishers`;
        queryParams = {};
        return {
            route,
            queryParams,
        };
    }
}

export function sitesReseauxNavLogicFact(injector: Injector): INavigationLogic {
    const createInjector = Injector.create({
        providers: [
            {
                provide: SitesReseauxNavLogic,
                useFactory: (envService: EnvironmentService) => new SitesReseauxNavLogic(envService),
                deps: [EnvironmentService],
            },
        ],
        parent: injector,
    });
    return createInjector.get(SitesReseauxNavLogic);
}
