import { Action } from '@ngrx/store';

import { ICategoryBrands, IDevelopementLabel } from '@solocal-manager/sirius/core/models';

export enum ReferentielActionTypes {
    GET_DEVELOPEMENT_LABELS = '[GET_DEVELOPEMENT_LABELS] get developement labels',
    GET_DEVELOPEMENT_LABELS_SUCCESS = '[GET_DEVELOPEMENT_LABELS_SUCCESS] get developement labels success',
    GET_CATEGORY_BRANDS = '[GET_CATEGORY_BRANDS] get category brands',
    GET_CATEGORY_BRANDS_SUCCESS = '[GET_CATEGORY_BRANDS_SUCCESS] get category brands success',
}

export class GetDevelopementLabels implements Action {
    readonly type = ReferentielActionTypes.GET_DEVELOPEMENT_LABELS;

    constructor() {}
}

export class GetDevelopementLabelsSuccess implements Action {
    readonly type = ReferentielActionTypes.GET_DEVELOPEMENT_LABELS_SUCCESS;

    constructor(public result: IDevelopementLabel[]) {}
}

export class GetCategoryBrands implements Action {
    readonly type = ReferentielActionTypes.GET_CATEGORY_BRANDS;

    constructor(
        public params: {
            locationIds: string[];
            pageSize?: number;
            pageNumber?: number;
        },
    ) {}
}

export class GetCategoryBrandsSuccess implements Action {
    readonly type = ReferentielActionTypes.GET_CATEGORY_BRANDS_SUCCESS;

    constructor(public result: ICategoryBrands[]) {}
}

export type ReferentielActionTypesUnion =
    | GetDevelopementLabels
    | GetDevelopementLabelsSuccess
    | GetCategoryBrands
    | GetCategoryBrandsSuccess;
