import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'imageSizer',
})
export class ImageSizerPipe implements PipeTransform {
    imageWidths = {
        100: true,
        240: true,
        320: true,
        480: true,
        640: true,
        800: true,
        1024: true,
    };

    //...201603/f4510967b4bb416ba22b2047bec19a86/companies/14/images/2264/pos_input_length.png
    //...201603/f4510967b4bb416ba22b2047bec19a86/companies/14/images/2264/pos_input_length_800.png

    transform(imageUrl: any, size: any): any {
        if (imageUrl && this.imageWidths[size] === true) {
            const replacement = ['_', size, '$1'].join('');
            const resized = imageUrl.replace(/(\.[^.]+)$/, replacement);
            return resized;
        }
        return imageUrl;
    }
}
