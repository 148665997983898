import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { GetTotalParcUsage } from '../../actions';
import { IParcUsageKpi } from '../../models';
import { getParcUsageByYear } from '../../reducers';
import { KpiUtilsService } from '../../services';

interface IUploadedYearInfo {
    year: string;
    uploaded: boolean;
}

@Component({
    selector: 'app-kpi-total-uses',
    templateUrl: './kpi-total-uses.component.html',
    styleUrls: ['./kpi-total-uses.component.scss'],
})
export class KpiTotalUsesComponent implements OnInit {
    parcUsage$: Observable<IParcUsageKpi[]>;
    yearFrom = 2016;
    yearUntil = moment().year();
    years: IUploadedYearInfo[] = [];

    constructor(private store: Store<any>, private kpiUtils: KpiUtilsService) {}

    ngOnInit() {
        for (let y = this.yearUntil; y >= this.yearFrom; y--) {
            this.years.push({
                year: '' + y,
                uploaded: y === this.yearUntil,
            });
        }
    }

    getParcUsageByYear(year: string): Observable<IParcUsageKpi> {
        return this.store.pipe(
            select(getParcUsageByYear(year)),
            map(parcUsage => this.kpiUtils.processParcUsage(parcUsage)),
        );
    }

    onYearExpand(opened: boolean, yearInfo: IUploadedYearInfo) {
        if (opened && !yearInfo.uploaded) {
            this.store.dispatch(
                new GetTotalParcUsage({
                    yearFrom: yearInfo.year,
                    yearUntil: yearInfo.year,
                }),
            );
            yearInfo.uploaded = true;
        }
    }
}
