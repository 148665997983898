// tslint:disable:max-classes-per-file
import { Action } from '@ngrx/store';

import { Pagination, PaginationDTO } from '@solocal-manager/sirius/core/models';

import {
    ILocationKpi,
    IPageStatesKpi,
    IParcUsageKpi,
    IProvisioningIssueDetailsKpi,
    IProvisioningIssuesKpi,
    IPublisherErrorsKpi,
    ITotalSalesKpi,
} from '../models';

export enum KpiActionTypes {
    GET_PAGE_STATES = '[GET_PAGE_STATES] get page states',
    GET_PAGE_STATES_SUCCESS = '[GET_PAGE_STATES_SUCCESS] get page states success',
    GET_LOCATION_LIST_BY_ERROR = '[GET_LOCATION_LIST_BY_ERROR] get location list by error',
    GET_LOCATION_LIST_BY_ERROR_SUCCESS = '[GET_LOCATION_LIST_BY_ERROR_SUCCESS] get location list by error success',
    GET_PUBLISHER_ERRORS = '[GET_PUBLISHER_ERRORS] get publisher errors',
    GET_PUBLISHER_ERRORS_SUCCESS = '[GET_PUBLISHER_ERRORS_SUCCESS] get publisher errors success',
    GET_TOTAL_SALES = '[GET_TOTAL_SALES] get total sales',
    GET_TOTAL_SALES_SUCCESS = '[GET_TOTAL_SALES_SUCCESS] get total sales success',
    GET_PROVISIONING_ISSUES = '[GET_PROVISIONING_ISSUES] get provisioning issues',
    GET_PROVISIONING_ISSUES_SUCCESS = '[GET_PROVISIONING_ISSUES_SUCCESS] get provisioning issues success',
    GET_PROVISIONING_ISSUE_DETAILS = '[GET_PROVISIONING_ISSUE_DETAILS] get provisioning issue details',
    GET_PROVISIONING_ISSUE_DETAILS_SUCCESS = '[GET_PROVISIONING_ISSUE_DETAILS] get provisioning issue details success',
    GET_TOTAL_PARC_USAGE = '[GET_TOTAL_PARC_USAGE] get total parc usage',
    GET_TOTAL_PARC_USAGE_SUCCESS = '[GET_TOTAL_PARC_USAGE_SUCCESS] get total parc usage success',
}

export class GetPageStates implements Action {
    readonly type = KpiActionTypes.GET_PAGE_STATES;

    constructor() {}
}

export class GetPageStatesSuccess implements Action {
    readonly type = KpiActionTypes.GET_PAGE_STATES_SUCCESS;

    constructor(public result: IPageStatesKpi[]) {}
}

export class GetLocationListByError implements Action {
    readonly type = KpiActionTypes.GET_LOCATION_LIST_BY_ERROR;

    constructor(
        public params: {
            publisherId: string;
            errorMessage: string;
            pageSize?: number;
            pageNumber?: number;
        },
    ) {}
}

export class GetLocationListByErrorSuccess implements Action {
    readonly type = KpiActionTypes.GET_LOCATION_LIST_BY_ERROR_SUCCESS;

    constructor(public result: PaginationDTO<ILocationKpi>) {}
}

export class GetPublisherErrors implements Action {
    readonly type = KpiActionTypes.GET_PUBLISHER_ERRORS;

    constructor(public params: { publisherId: string; states?: string[] }) {}
}

export class GetPublisherErrorsSuccess implements Action {
    readonly type = KpiActionTypes.GET_PUBLISHER_ERRORS_SUCCESS;

    constructor(public result: IPublisherErrorsKpi[]) {}
}

export class GetTotalSales implements Action {
    readonly type = KpiActionTypes.GET_TOTAL_SALES;

    constructor(public params?: { status?: string }) {}
}

export class GetTotalSalesSuccess implements Action {
    readonly type = KpiActionTypes.GET_TOTAL_SALES_SUCCESS;

    constructor(public result: ITotalSalesKpi) {}
}

export class GetProvisioningIssues implements Action {
    readonly type = KpiActionTypes.GET_PROVISIONING_ISSUES;

    constructor() {}
}

export class GetProvisioningIssuesSuccess implements Action {
    readonly type = KpiActionTypes.GET_PROVISIONING_ISSUES_SUCCESS;

    constructor(public result: IProvisioningIssuesKpi) {}
}

export class GetProvisioningIssueDetails implements Action {
    readonly type = KpiActionTypes.GET_PROVISIONING_ISSUE_DETAILS;

    constructor(public params: { type: string; year: number; week: number }) {}
}

export class GetProvisioningIssueDetailsSuccess implements Action {
    readonly type = KpiActionTypes.GET_PROVISIONING_ISSUE_DETAILS_SUCCESS;

    constructor(public result: IProvisioningIssueDetailsKpi) {}
}

export class GetTotalParcUsage implements Action {
    readonly type = KpiActionTypes.GET_TOTAL_PARC_USAGE;

    constructor(public params?: { yearFrom?: string; yearUntil?: string }) {}
}

export class GetTotalParcUsageSuccess implements Action {
    readonly type = KpiActionTypes.GET_TOTAL_PARC_USAGE_SUCCESS;

    constructor(public result: IParcUsageKpi[]) {}
}

export type KpiActionsUnion =
    | GetPageStates
    | GetPageStatesSuccess
    | GetLocationListByError
    | GetLocationListByErrorSuccess
    | GetPublisherErrors
    | GetPublisherErrorsSuccess
    | GetTotalSales
    | GetTotalSalesSuccess
    | GetProvisioningIssues
    | GetProvisioningIssuesSuccess
    | GetProvisioningIssueDetails
    | GetProvisioningIssueDetailsSuccess
    | GetTotalParcUsage
    | GetTotalParcUsageSuccess;
