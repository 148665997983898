import { Injectable } from '@angular/core';
import * as moment from 'moment';

import { ItimeSlot } from '@solocal-manager/sirius/core/models/time-slots/date-slots';

export function createTimeSteps(minutes: number = 15) {
    const times: Array<string | object> = ['closed'];
    let tt = 0;

    for (let i = 0; tt < 24 * 60 + minutes; i++) {
        const hh = Math.floor(tt / 60);
        const mm = tt % 60;
        const h = (hh === 24 ? '24' : '0' + (hh % 24)).slice(-2);
        const m = ('0' + mm).slice(-2);
        const time = h + ':' + m;

        times[i] = {
            id: time,
            name: time,
        };
        tt += minutes;
    }

    return times;
}

export function insertSlot(slot: string, timeSlots: string[]): string[] {
    let i = 0;
    let time = 0;
    const slotToInsert: number = parseFloat(slot.split(':').join('.'));

    for (i; i < timeSlots.length; i++) {
        time = parseFloat(timeSlots[i].split(':').join('.'));
        if (slotToInsert < time) {
            timeSlots.splice(timeSlots.indexOf(timeSlots[i]), 0, slot);
            break;
        }
    }

    return timeSlots;
}

export function getClosestDate(dates: string[]): string {
    const target = Date.now();

    let nearest = Infinity;
    let winner = -1;

    dates.forEach((d, index) => {
        const date = new Date(d).getTime();
        const distance = date - target;
        if (distance >= 0 && distance < nearest) {
            nearest = distance;
            winner = index;
        }
    });

    return dates[winner];
}

export function isValidTimeSlot(slot: ItimeSlot): boolean {
    return +slot.to.split(':')[0] < 8 || compareTimeStrings(slot.from, slot.to) < 0;
}

export function compareTimeStrings(time1: string, time2: string): number {
    const split1 = time1.split(':');
    const split2 = time2.split(':');
    const h1 = +split1[0];
    const m1 = +split1[1];
    const h2 = +split2[0];
    const m2 = +split2[1];

    return h1 * 60 + m1 - (h2 * 60 + m2);
}

export function createIsoString(hours: number, minutes: number = 0): string {
    let date = new Date();
    date.setHours(hours, minutes, 0, 0);
    date = new Date(date.getTime() - 60000 * date.getTimezoneOffset());
    return date.toISOString();
}

export function getCalendarFutureYear(startDate: string, years: number = 1): Date {
    const today = new Date(moment(startDate).format());
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    const currentDate = today.getDate();
    const maxDate = new Date(currentYear + years, currentMonth, currentDate);
    return maxDate;
}

@Injectable({ providedIn: 'root' })
export class TimeUtils {
    createTimeSteps() {
        const minutes = 15;
        const times = [];
        let tt = 0;

        for (let i = 0; tt < 24 * 60 + minutes; i++) {
            const hh = Math.floor(tt / 60);
            const mm = tt % 60;
            const h = (hh === 24 ? '24' : '0' + (hh % 24)).slice(-2);
            const m = ('0' + mm).slice(-2);
            const time = h + ':' + m;

            times[i] = {
                id: time,
                name: time,
            };
            tt += minutes;
        }

        return times;
    }

    insertSlot(slot: string, timeSlots: string[]): string[] {
        let i = 0;
        let time = 0;
        const slotToInsert: number = parseFloat(slot.split(':').join('.'));

        for (i; i < timeSlots.length; i++) {
            time = parseFloat(timeSlots[i].split(':').join('.'));
            if (slotToInsert < time) {
                timeSlots.splice(timeSlots.indexOf(timeSlots[i]), 0, slot);
                break;
            }
        }

        return timeSlots;
    }
}
