import { Pipe, PipeTransform } from '@angular/core';

import { sortByProperty } from '../utils';

@Pipe({
    name: 'sorter',
})
export class SorterPipe implements PipeTransform {
    constructor() {}

    transform(
        arrayToSort: any[],
        args: {
            property?: string;
            sortedArray?: any[];
            desc?: boolean;
        },
    ): any[] {
        return sortByProperty(arrayToSort, args);
    }
}
