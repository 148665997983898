import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-admin-epj-page',
    templateUrl: './admin-epj-page.component.html',
    styleUrls: ['./admin-epj-page.component.scss'],
})
export class AdminEpjPageComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
