import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Location as LocationModel } from '@solocal-manager/sirius/support/base-models';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { InstantMessagingFacade } from '../data-access-chat.facade';
import { getChatStatus } from '../helpers/chat-is-activated.helper';
import {
    ChatConfig,
    ChatConfigViewModel,
    ChatPublisherPreferences,
    EChatIntegration,
    EChatIntegrationStatus,
    IIntegration,
} from '../models';

@Component({
    template: '',
})
export class BaseChatPreferencesComponent implements OnInit {
    protected readonly destroyRef = inject(DestroyRef);
    protected readonly imFacade = inject(InstantMessagingFacade);

    location: LocationModel;
    listOfPublisher: ChatPublisherPreferences[] = [];
    hasPJChatEnabled = false;
    hasFBChatEnabled = false;
    chatConfig: ChatConfig;
    chatIntegrationLoading: string;
    isWaitingForFacebookActivation = false;
    temporaryActivationLoading = false;

    readonly getChatConfig$: Observable<ChatConfigViewModel> = this.imFacade.getChatConfig$;

    get hasChatFeatures(): boolean {
        return this.hasChatPJFeature || this.hasChatFacebookFeature;
    }

    get hasChatPJFeature(): boolean {
        const features = this.location.tags || [];
        return !!features.includes('chat_pj');
    }

    get hasChatFacebookFeature(): boolean {
        const features = this.location.tags || [];
        return !!features.includes('chat_facebook');
    }

    get hasFacebookPageConnected(): boolean {
        return this.location?.pages?.find(page => page.publisher_id === 'facebook')?.state === 'active';
    }

    get chatDescriptionText(): string {
        if (!!this.hasChatFacebookFeature && !!this.hasChatPJFeature) {
            return 'chat_preferences:description_fb_pj';
        } else if (!!this.hasChatFacebookFeature && !this.hasChatPJFeature) {
            return 'chat_preferences:description_fb';
        } else if (!this.hasChatFacebookFeature && !!this.hasChatPJFeature) {
            return 'chat_preferences:description_pj';
        }
    }

    ngOnInit(): void {
        this.chatIntegrationLoading = undefined;
        this.imFacade.selectedLocation$
            .pipe(
                filter(location => !!location.epj),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe(location => {
                this.location = location;
                this.imFacade.fetchSelectedLocationChatConfig(this.location.epj);
                this.listOfPublisher = [];
                this.initPreferences();
            });

        this.imFacade.selectedLocationChatConfig$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(config => {
            this.chatIntegrationLoading = undefined;
            this.listOfPublisher = [];
            this.chatConfig = config;
            this.initPreferences();
        });
    }

    initPreferences(): void {
        const packFeaturesConfig = this.location.tags || [];

        if (!!this.location && !!this.location.epj) {
            if (packFeaturesConfig.includes('chat_pj')) {
                this.initPagesJaunes();
            }

            if (packFeaturesConfig.includes('chat_facebook')) {
                this.initFacebook();
            }

            if (packFeaturesConfig.includes('chat_google')) {
                this.initGoogle();
            }
        }
    }

    /**
     * Init PJ Chat preferences
     * @param packFeaturesConfig List of activated features for the location
     */
    initPagesJaunes(): void {
        const publisherPreferences: ChatPublisherPreferences = {
            publisherId: 'pagesjaunes',
            label: 'PagesJaunes',
            chatConfig: this.chatConfig,
            isActive: getChatStatus('pagesjaunes', this.chatConfig) === 'active',
            epj: this.location.epj,
            oAuthNeeded: this.chatIsOAuthNeeded('pagesjaunes'),
        };

        this.listOfPublisher.push(publisherPreferences);
    }

    /**
     * Init Facebook Chat preferences
     * @param packFeaturesConfig List of activated features for the location
     */
    initFacebook(): void {
        const publisherPreferences: ChatPublisherPreferences = {
            publisherId: 'facebook',
            label: 'Facebook',
            chatConfig: this.chatConfig,
            isActive: getChatStatus('facebook', this.chatConfig) === 'active',
            epj: this.location.epj,
            oAuthNeeded: this.chatIsOAuthNeeded('facebook'),
            chatIntegration: this.getChatIntegration('facebook'),
        };
        this.listOfPublisher.push(publisherPreferences);
    }

    initGoogle(): void {
        const publisherPreferences: ChatPublisherPreferences = {
            publisherId: 'gbm',
            label: 'Google (Business Messages)',
            chatConfig: this.chatConfig,
            isActive: getChatStatus('gbm', this.chatConfig) === 'active',
            epj: this.location.epj,
            oAuthNeeded: this.chatIsOAuthNeeded('gbm'),
            chatIntegration: this.getChatIntegration('gbm'),
            isPending: getChatStatus('gbm', this.chatConfig) === 'pending',
        };
        this.listOfPublisher.push(publisherPreferences);
    }

    toggleFeatureActivation(publisherId: string): void {
        this.setPreference(publisherId);
    }

    chatIsActivated(publisher: string): boolean {
        const chatIntegration = this.getChatIntegration(publisher);
        return !!chatIntegration && chatIntegration.status === EChatIntegrationStatus.ACTIVE;
    }

    chatIsOAuthNeeded(publisher: string): boolean {
        const chatIntegration = this.getChatIntegration(publisher);
        return !!chatIntegration && chatIntegration.status === EChatIntegrationStatus.OAUTH_NEEDED;
    }

    getChatIntegration(publisher: string): IIntegration {
        if (!this.chatConfig || !this.chatConfig.integrations) return null;
        const integration_type = publisher === 'facebook' ? EChatIntegration.FACEBOOK : publisher;
        return this.chatConfig.integrations.find(integration => integration.integration_type === integration_type);
    }

    getNewChatStatus(integration: IIntegration): string {
        return integration.status === 'active' || integration.status === EChatIntegrationStatus.OAUTH_NEEDED
            ? 'inactive'
            : 'active';
    }

    getChatConfigStatus(_chatConfig: ChatConfig): string {
        return _chatConfig.status === null || _chatConfig.status === 'pending' ? 'active' : _chatConfig.status;
    }

    setPreference(publisherId: string): void {
        let chatIntegration = this.getChatIntegration(publisherId);
        if (chatIntegration) {
            chatIntegration.status = this.getNewChatStatus(chatIntegration);
        } else {
            this.createChatIntegration(publisherId);
            chatIntegration = this.getChatIntegration(publisherId);
        }
        const _chatConfig = { ...this.chatConfig };
        chatIntegration.refresh = true;
        _chatConfig.integrations = [chatIntegration];
        _chatConfig.status = this.getChatConfigStatus(_chatConfig);
        if (chatIntegration.status === 'active' && publisherId === 'facebook')
            this.isWaitingForFacebookActivation = true;
        this.imFacade.putSelectedLocationChatConfig(this.location.epj, _chatConfig);
        this.chatIntegrationLoading = publisherId;
    }

    /**
     * Create chat integration - if needed create chat config
     */
    createChatIntegration(publisherId: string): void {
        let integration_type = publisherId;
        if (publisherId === 'pagesjaunes') {
            integration_type = 'pagesjaunes';
        }

        if (publisherId === 'facebook') {
            integration_type = 'messenger';
        }

        if (publisherId === 'gmb') {
            integration_type = 'gmb';
        }

        if (!this.chatConfig) {
            this.chatConfig = {
                status: 'active',
                integrations: [
                    {
                        integration_type,
                        status: 'active',
                        options: {},
                    },
                ],
            };
        } else {
            this.chatConfig.integrations[this.chatConfig.integrations.length] = {
                integration_type,
                status: 'active',
                options: {},
            };
        }
    }

    refreshFeature(): void {
        this.imFacade.fetchSelectedLocationChatConfig(this.location.epj);
    }
}
