import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UserDtoModel, UserHttpService } from '@slm/user/api';
import { SlmAuthFacade } from '@solocal-manager/auth';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';

import { getUser, getUserError, getUserSuccess, logout } from './user.actions';
import { User, createUser } from './user.model';

@Injectable({ providedIn: 'root' })
export class UserEffects {
    getUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getUser),
            filter(() => this.slmAuthFacade.hasToken()),
            switchMap(() => this.userHttpService.getUser()),
            map((user: UserDtoModel) => createUser(user)),
            map((user: User) => getUserSuccess({ user })),
            catchError(error => of(getUserError({ error }))),
        ),
    );

    navigateToRoot$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(logout),
                tap(() => {
                    this.slmAuthFacade.logout();
                }),
            ),
        { dispatch: false },
    );

    constructor(
        private readonly actions$: Actions,
        private readonly userHttpService: UserHttpService,
        private readonly slmAuthFacade: SlmAuthFacade,
    ) {}
}
