import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'dateFromNow',
})
export class DateFromNowPipe implements PipeTransform {
    transform(timestamp: number) {
        const duration = moment.duration(moment(new Date()).diff(moment(timestamp)));
        const hours = duration.asHours();
        if (hours > 24) return moment(timestamp).format('L');
        return moment(timestamp).fromNow();
    }
}
