import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { LocationsDTO, PaginationDecoratorEntity } from '@solocal-manager/sirius/support/base-models';

import { epjToIdAdapter, initialState as EpjToIdInitial, State as EpjToId } from './epj-to-id/epj-to-id.adapter';
import { EpjToIdEntity } from './epj-to-id/epj-to-id.models';
import { sortByName } from './location.utils';
import * as LocationsActions from './locations.actions';
import { LocationEntity } from './locations.models';
import {
    initialState as ParnterIdToIdInitial,
    partnerIdToIdAdapter,
    State as PartnerIdToId,
} from './partner-id-to-id/partner-id-to-id.adapter';
import { PartnerIdToIdEntity } from './partner-id-to-id/partner-id-to-id.models';

export const LOCATIONS_FEATURE_KEY = 'locations';

export interface State extends PaginationDecoratorEntity<LocationEntity> {
    selectedId?: string; // which Locations record has been selected
    selectedIds?: string[];
    error?: string | null; // last known error (if any)
    count: number;
    loaded?: boolean;
    partnerIdToId: PartnerIdToId;
    epjToId: EpjToId;
    defaultLocationId?: string;
    defaultLocationLoaded: boolean;
    initial: boolean;
}

function updateStateFromDTO(state: State, locationsDTO: LocationsDTO): State {
    return {
        ...state,
        currentPage: locationsDTO.current_page ?? null,
        initial: locationsDTO.initial ?? false,
        pageOnly: locationsDTO.pageOnly,
        next: locationsDTO.next,
        count: locationsDTO.count,
        nextPage: locationsDTO.next_page,
        previous: locationsDTO.previous,
        previousPage: locationsDTO.previous_page,
        meta: locationsDTO.meta,
        state: locationsDTO.state,
    };
}

function partnerIdToIdSetAllByDTO(state: PartnerIdToId, locationsDTO: LocationsDTO): PartnerIdToId {
    return partnerIdToIdAdapter.setAll(
        locationsDTO.data.reduce((acc, location) => {
            if (location.partner_id && location.id) {
                return [...acc, { id: location.id, partnerId: location.partner_id }];
            }

            return acc;
        }, [] as PartnerIdToIdEntity[]),
        state,
    );
}

function partnerIdToIdSetAll(state: PartnerIdToId, entities: Partial<PartnerIdToIdEntity>[]): PartnerIdToId {
    return partnerIdToIdAdapter.setAll(
        entities.filter(entity => isValidPartnerIdToIdEntity(entity)) as PartnerIdToIdEntity[],
        state,
    );
}

function isValidPartnerIdToIdEntity(entity: Partial<PartnerIdToIdEntity>): entity is PartnerIdToIdEntity {
    return entity.id != null && entity.partnerId != null;
}

function partnerIdToIdSetOne(state: PartnerIdToId, entity: Partial<PartnerIdToIdEntity>) {
    if (isValidPartnerIdToIdEntity(entity)) {
        return partnerIdToIdAdapter.setOne(entity, state);
    }

    return state;
}

function partnerIdToIdUpsertManyByDTO(state: PartnerIdToId, locationsDTO: LocationsDTO): PartnerIdToId {
    return partnerIdToIdAdapter.upsertMany(
        locationsDTO.data.reduce((acc, location) => {
            if (location.id && location.partner_id) {
                return [...acc, { id: location.id, partnerId: location.partner_id }];
            }

            return acc;
        }, [] as PartnerIdToIdEntity[]),
        state,
    );
}

function epjToIdSetAllByDTO(state: EpjToId, locationsDTO: LocationsDTO): EpjToId {
    return epjToIdAdapter.setAll(
        locationsDTO.data.reduce((acc, location) => {
            if (location.id && location.epj) {
                return [...acc, { id: location.id, epj: location.epj }];
            }

            return acc;
        }, [] as EpjToIdEntity[]),
        state,
    );
}

function epjToIdUpsertManyByDTO(state: EpjToId, locationsDTO: LocationsDTO): EpjToId {
    return epjToIdAdapter.upsertMany(
        locationsDTO.data.reduce((acc, location) => {
            if (location.id && location.epj) {
                return [...acc, { id: location.id, epj: location.epj }];
            }

            return acc;
        }, [] as EpjToIdEntity[]),
        state,
    );
}

function epjToIdSetAll(state: EpjToId, entities: Partial<EpjToIdEntity>[]): EpjToId {
    return epjToIdAdapter.setAll(entities.filter(entity => isValidEpjToIdEntity(entity)) as EpjToIdEntity[], state);
}

function isValidEpjToIdEntity(entity: Partial<EpjToIdEntity>): entity is EpjToIdEntity {
    return entity.id != null && entity.epj != null;
}

function epjToIdSetOne(state: EpjToId, entity: Partial<EpjToIdEntity>): EpjToId {
    if (isValidEpjToIdEntity(entity)) {
        return epjToIdAdapter.setOne(entity, state);
    }

    return state;
}

function setAllFromSelectedLocation(state: State, locationEntity: LocationEntity) {
    const { position: remPos, ...unpositionedLocation } = locationEntity;
    return locationsAdapter.setAll([unpositionedLocation], {
        ...state,
        partnerIdToId: partnerIdToIdSetAll(state.partnerIdToId, [
            {
                partnerId: locationEntity.partner_id,
                id: locationEntity.id,
            },
        ]),
        epjToId: epjToIdSetAll(state.epjToId, [
            {
                epj: locationEntity.epj,
                id: locationEntity.id,
            },
        ]),
    });
}

function upsertManyFromDTO(state: State, locationsDTO: LocationsDTO, updates: Partial<State>): State {
    return locationsAdapter.upsertMany(locationsDTO.data, {
        ...updateStateFromDTO(state, locationsDTO),
        partnerIdToId: partnerIdToIdUpsertManyByDTO(state.partnerIdToId, locationsDTO),
        epjToId: epjToIdUpsertManyByDTO(state.epjToId, locationsDTO),
        ...updates,
    });
}

function setAllFromDTO(state: State, locationsDTO: LocationsDTO, updates: Partial<State>): State {
    return locationsAdapter.setAll(locationsDTO.data, {
        ...updateStateFromDTO(state, locationsDTO),
        partnerIdToId: partnerIdToIdSetAllByDTO(state.partnerIdToId, locationsDTO),
        epjToId: epjToIdSetAllByDTO(state.epjToId, locationsDTO),
        ...updates,
    });
}

export interface LocationsPartialState {
    readonly [LOCATIONS_FEATURE_KEY]: State;
}

export const locationsAdapter: EntityAdapter<LocationEntity> = createEntityAdapter<LocationEntity>({
    sortComparer: sortByName,
});

export const initialState: State = locationsAdapter.getInitialState({
    currentPage: 0,
    count: 0,
    next: null,
    nextPage: null,
    previous: null,
    previousPage: null,
    initial: true,
    pageOnly: false,
    loaded: false,
    partnerIdToId: ParnterIdToIdInitial,
    epjToId: EpjToIdInitial,
    defaultLocationLoaded: false,
});

export const locationsReducer = createReducer(
    initialState,
    on(LocationsActions.setSingleLocation, state => ({
        ...state,
        loaded: false,
        error: null,
    })),
    on(LocationsActions.loadLocations, (state, { continuation }) => ({
        ...state,
        loaded: !!continuation,
        error: null,
    })),
    on(LocationsActions.loadLocationsSuccess, (state, { locations: locationsDTO }) => {
        const selectors = locationsAdapter.getSelectors();
        const selectedLocation = state.selectedId != null ? selectors.selectEntities(state)[state.selectedId] : null;

        if (locationsDTO.pageOnly || state.pageOnly) {
            if (selectedLocation != null) {
                const newState = setAllFromSelectedLocation(state, selectedLocation);

                return upsertManyFromDTO(newState, locationsDTO, {
                    initial: false,
                    pageOnly: true,
                    loaded: true,
                });
            }

            return setAllFromDTO(state, locationsDTO, {
                initial: false,
                pageOnly: true,
                loaded: true,
            });
        }

        const currentPage = (locationsDTO.previous_page ?? 0) + 1;
        if (locationsDTO.previous_page) {
            return upsertManyFromDTO(state, locationsDTO, {
                initial: false,
                currentPage,
                loaded: true,
            });
        }

        if (selectedLocation != null) {
            const newState = setAllFromSelectedLocation(state, selectedLocation);

            return upsertManyFromDTO(newState, locationsDTO, {
                initial: false,
                currentPage,
                loaded: true,
            });
        }

        return setAllFromDTO(state, locationsDTO, {
            initial: false,
            currentPage,
            loaded: true,
        });
    }),
    on(LocationsActions.updateLocation, (state, { location }) => {
        if (location.id == null) {
            return state;
        }

        return locationsAdapter.mapOne(
            {
                id: location.id,
                map: storeLocation => ({ ...location, position: storeLocation.position }),
            },
            state,
        );
    }),
    on(LocationsActions.patchLocationOnboarding, (state, { id, onboarding_steps }) =>
        locationsAdapter.mapOne(
            {
                id,
                map: location => {
                    if (location.custom_data?.onboarding_steps) {
                        return { ...location, custom_data: { ...location.custom_data, onboarding_steps } };
                    }

                    return location;
                },
            },
            state,
        ),
    ),
    on(LocationsActions.patchLocationsBulkSuccess, (state, { locationIds, locationPartial }) =>
        locationsAdapter.updateMany(
            locationIds.map(id => ({ id, changes: locationPartial })),
            state,
        ),
    ),
    on(LocationsActions.patchLocationSucces, (state, { id, location }) =>
        locationsAdapter.updateOne(
            {
                id,
                changes: location,
            },
            state,
        ),
    ),
    on(LocationsActions.loadLocationsFailure, (state, { error }) => ({
        ...state,
        error,
    })),
    on(LocationsActions.loadLocationSuccess, (state, { location }) =>
        locationsAdapter.upsertOne(
            {
                ...location,
                hidden: false,
            },
            state,
        ),
    ),
    on(LocationsActions.setSelectedLocation, (state, { id }) => ({
        ...state,
        selectedId: id,
    })),
    on(LocationsActions.resetLocations, () => ({ ...initialState })),

    on(
        LocationsActions.putSelectedLocationSuccess,
        LocationsActions.patchSelectedLocationSuccess,
        LocationsActions.updateSelectedLocationOnboardingStep,
        (state, { location }) => {
            if (state.selectedId == null) {
                return state;
            }

            if (location.id != null && location.id !== state.selectedId) {
                return state;
            }

            return locationsAdapter.updateOne(
                {
                    id: state.selectedId,
                    changes: location,
                },
                state,
            );
        },
    ),
    on(LocationsActions.putLocationSuccess, (state, { location }) =>
        location.id != null
            ? locationsAdapter.updateOne(
                  {
                      id: location.id,
                      changes: location,
                  },
                  state,
              )
            : state,
    ),
    on(LocationsActions.unselectLocation, state => {
        return {
            ...state,
            selectedId: undefined,
            selectedIds: [],
        };
    }),
    on(LocationsActions.setSelectedIds, (state, { selectedIds }) => ({
        ...state,
        selectedIds,
    })),
    on(LocationsActions.getLocationRetentionEligibilitySuccess, (state, response) => {
        return locationsAdapter.updateOne(
            {
                id: response.locationId,
                changes: { isRetentionEligible: response.isEligible },
            },
            state,
        );
    }),
);
