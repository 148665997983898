import { PaginationDecorator, PaginationDecoratorDTO } from '@solocal-manager/sirius/core/models';

import { KpiActionsUnion, KpiActionTypes } from '../actions';
import { ILocationKpi } from '../models';

export type LocationsKpiState = PaginationDecoratorDTO<ILocationKpi>;

const initialState: LocationsKpiState = {
    currentPage: 0,
    next: null,
    next_page: null,
    previous: null,
    previous_page: null,
    count: 0,
    data: [],
    initial: true,
};

export function locationsByErrorKpiReducer(state = initialState, action: KpiActionsUnion): LocationsKpiState {
    switch (action.type) {
        case KpiActionTypes.GET_LOCATION_LIST_BY_ERROR_SUCCESS: {
            const currentPage = (action.result.previous_page || 0) + 1;
            return { ...action.result, initial: false, currentPage };
        }
        default:
            return state;
    }
}
