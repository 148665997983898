import { keys, merge, union } from 'lodash-es';

import { sectionAdvertisingConfig } from './section.advertising.config';
import { sectionPresenceConfig } from './section.presence.config';
import { sectionVideoConfig } from './section.video.config';
import { sectionWebsiteConfig } from './section.website.config';

// create list of all items;
export const availableProductItems = merge(
    sectionPresenceConfig,
    sectionAdvertisingConfig,
    sectionWebsiteConfig,
    sectionVideoConfig,
);

// 3 sections are displayed on the UI (presence/advertising/website)
// create list of string keys
export const presenceSection = keys(sectionPresenceConfig); // + selector should select only (K2DA codePack 1 and 2)
export const advertisingSection = keys(sectionAdvertisingConfig); // + selector should select also pack 3 (K2DA + codePack 3)
export const websiteSection = union(keys(sectionWebsiteConfig), keys(sectionVideoConfig));
// group all to create the query used by the API
export const availableProductTypes = union(presenceSection, advertisingSection, websiteSection);
