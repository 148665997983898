<div class="container-fluid">
    <div class="row">
        <div class="col-xs-12">
            <div class="row">
                <div class="col-md-12">
                    <slm-input-image-upload
                        [multiple]="true"
                        [mediaType]="'gallery'"
                        [minHeight]="150"
                        [minWidth]="400"
                        [locationId]="locationId"
                        [mediaName]="'photo'"
                        [publishToLocation]="true"
                    ></slm-input-image-upload>
                </div>
            </div>
            <div class="row"></div>
        </div>
    </div>
</div>
