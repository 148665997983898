import {
    Component,
    ComponentFactoryResolver,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { isEqual } from 'lodash-es';
import { Subscription } from 'rxjs';

import { GmbProcessService } from '../../services';
import { PublisherConnectGmbNewComponent } from '../publisher-connect-gmb-new/publisher-connect-gmb-new.component';
import { PublisherConnectGmbSearchComponent } from '../publisher-connect-gmb-search/publisher-connect-gmb-search.component';

import { IGmbProcessComponent } from './gmb-process-component.interface';
import { IGmbStep } from './steps.interface';

@Component({
    selector: 'slm-publisher-connect-gmb-process',
    templateUrl: './publisher-connect-gmb-process.component.html',
    styleUrls: ['./publisher-connect-gmb-process.component.scss'],
})
export class PublisherConnectGmbProcessComponent implements OnInit, OnDestroy {
    @ViewChild('processContainer', { read: ViewContainerRef })
    container: ViewContainerRef;

    _currentStep: IGmbStep | IGmbStep[];
    _previousStep: IGmbStep | IGmbStep[];

    subscriptions: Subscription[] = [];
    @Input() location: Location;
    @Output() closeModal: EventEmitter<boolean> = new EventEmitter<boolean>();

    // SUPPORTS ONLY 1 LEVEL OF NESTING (hence hack in resolvePreviousStep())
    steps: IGmbStep[] = [
        {
            name: 'new',
            btnText: 'Create new',
            component: PublisherConnectGmbNewComponent,
        },
        {
            name: 'existing',
            btnText: 'Connect existing',
            steps: [
                {
                    name: 'withoutRights',
                    btnText: 'I need to ask for rights',
                    component: PublisherConnectGmbSearchComponent,
                },
                {
                    name: 'withRights',
                    btnText: 'I have the rights',
                    component: PublisherConnectGmbSearchComponent,
                },
            ],
        },
    ];

    constructor(private resolver: ComponentFactoryResolver, public gmbProcessService: GmbProcessService) {}

    ngOnInit() {
        this.resetSteps();
        const gmbProcessServiceSubscription = this.gmbProcessService.onClose.subscribe(shouldClose => {
            if (shouldClose) {
                this.resetSteps();
                this.container.clear();

                this.closeModal.emit(shouldClose);
            }
        });

        this.subscriptions.push(gmbProcessServiceSubscription);
    }

    resetSteps() {
        this.previousStep = null;
        this.currentStep = this.steps;
    }

    resolveStep(step: IGmbStep) {
        this.previousStep = this.currentStep;
        this.currentStep = step.steps || step;
        this.container.clear();
        this.loadComponent(step);
    }

    resolvePreviousStep() {
        // hack when it gets stack on the child node
        if (isEqual(this.previousStep, this.currentStep)) {
            this.previousStep = this.steps;
        }

        this.currentStep = this.previousStep;
        const step = this.currentStep as IGmbStep;
        this.container.clear();
        this.loadComponent(step);
    }

    loadComponent(step: IGmbStep) {
        if (step && step.component) {
            const factory = this.resolver.resolveComponentFactory(step.component);
            const componentRef = this.container.createComponent(factory);
            // here we can set component's Input() someInput programatically, like this:
            (componentRef.instance as IGmbProcessComponent).requestRights = step.name === 'withoutRights';
            componentRef.instance.location = this.location;
        }
    }

    get canGoBack(): boolean {
        return this.previousStep !== null && !isEqual(this.currentStep, this.steps);
    }

    get availableSteps(): IGmbStep[] {
        return Array.isArray(this.currentStep) ? this.currentStep : [];
    }

    get btnRowClass() {
        const columnWidth = 12 / this.availableSteps.length;
        return `col-md-${columnWidth}`;
    }

    get currentStep(): IGmbStep | IGmbStep[] {
        return this._currentStep;
    }

    set currentStep(step: IGmbStep | IGmbStep[]) {
        this._currentStep = step;
    }

    get previousStep(): IGmbStep | IGmbStep[] {
        return this._previousStep;
    }

    set previousStep(step: IGmbStep | IGmbStep[]) {
        this._previousStep = step;
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
