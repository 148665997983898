import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GmbProcessService {
    onClose: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    registerClose(shouldClose: boolean): void {
        this.onClose.next(shouldClose);
    }
}
