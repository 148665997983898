import { Injectable } from '@angular/core';

import { Location } from '@solocal-manager/sirius/support/base-models';

import { completionConfig } from '../configs/completion.config';
import { CompletionConfigModel } from '../models/completion-config.model';
import { CompletionViewModel } from '../models/completion-view-model';
import { Completion } from '../models/completion.model';

@Injectable({ providedIn: 'root' })
export class LocationCompletionSuggestionService {
    createViewModel(location: Location, hasReviewRequestsAccess: boolean): CompletionViewModel {
        const key = this.getSuggestion(location.completion_rate);

        return {
            hasReviewRequestsAccess,
            key,
            completion: location.completion_rate.total,
            icon: this.getIcon(key),
        };
    }

    getCompletionRateByLocation(location: Location): number {
        return location.completion_rate.total;
    }

    getSuggestion(completion: Completion): string {
        let suggestKey = '';

        completionConfig.some(config => {
            const needsSuggestion = completion[config.key] !== config.max;
            suggestKey = needsSuggestion ? config.key : suggestKey;
            return needsSuggestion;
        });

        return suggestKey;
    }

    getFilteredConfigs(completion: Completion, configs: CompletionConfigModel[]): CompletionConfigModel[] {
        return configs.filter(config => completion[config.key] < config.max);
    }

    getIcon(suggestion: string): string {
        return completionConfig.find(element => element.key === suggestion).icon;
    }

    getRedirectionPath(completionVM: CompletionViewModel): Array<string> {
        switch (completionVM.key) {
            case 'reviews':
                return completionVM.hasReviewRequestsAccess ? ['reputation', 'request'] : ['reputation', 'review'];
            case 'post':
                return ['post'];
            case 'feature':
                return ['profile', 'preferences'];
            case 'categories':
            case 'fb_cover':
            case 'emails':
            case 'payment_methods':
            case 'opening_hours':
                return ['content', 'edit', completionConfig.find(element => element.key === completionVM.key).path];
            default:
                return ['content', 'edit', completionVM.key];
        }
    }
}
