import { EPackFeatures, EPacks } from '@solocal-manager/sirius/support/base-models';

import { photosVidosInfoNavLogicFact as photosVideosInfoNavLogicFact } from '../..//services/app-nav/navigation-logic/photos-videos-info';
import { ENavIDs, NavItemMap, NavPageView } from '../../models';
import { CustomNavItemFilterIds } from '../../models/navigation/custom-nav-item-filter-id.enum';
import { CustomMenuItemFilterFactory, NavLogicFactory } from '../../models/navigation/interfaces';
import { NavLogicRouteIds } from '../../models/navigation/nav-logic-route-id.enum';
import { onboardingJourneyCustomItemFilterFact } from '../../services/app-nav/custom-menu-item-filters/onboarding-journey-filter';
import { locationInformationCondNavLogicFact } from '../../services/app-nav/navigation-logic/location-information-cond-nav-logic';
import { locationInformationNavLogicFact } from '../../services/app-nav/navigation-logic/location-information-nav-logic';
import { onboardingJourneyCondNavLogicFact } from '../../services/app-nav/navigation-logic/onboarding-journey-cond-nav-logic';
import { photosVideosInfoCondFact } from '../../services/app-nav/navigation-logic/photos-videos-info-cond';
import { sitesReseauxNavLogicFact } from '../../services/app-nav/navigation-logic/site-reseaux-nav-logic';

const groupNavItem: NavPageView = {
    useConfigIconBasePath: true,
    title: '',
    icon: '',
    ariaLabel: '',
    gtmAction: '',
    gtmLabel: '',
};

/**
 * ########### ALL NAV ELEMENTS ###########
 * This should be a MAP of ENavIDs.
 * Where we can add all the details which
 * is required to display and render all
 * the navigation elements.
 *
 */
export const sourceNavConf: NavItemMap = {
    [ENavIDs.COMPANY_PERKS]: {
        id: ENavIDs.COMPANY_PERKS,
        type: 'normal',
        route: '/company-perks',

        navItem: {
            title: 'menu_items:company_perks',
            ariaLabel: 'Company Perks',
            icon: 'icon_menu_company_perks',
            useConfigIconBasePath: true,
            gtmAction: 'avantages entreprises clicked',
            gtmLabel: 'redirection',
        },
        navDisplayRules: {
            showForFeature: ['dynabuy'],
        },
    },
    [ENavIDs.LOCATION_INFO__PHOTOS_VIDEOS]: {
        id: ENavIDs.LOCATION_INFO__PHOTOS_VIDEOS,
        type: 'normal',
        route: '',

        navItem: {
            useConfigIconBasePath: true,
            title: 'new_plugin',
            ariaLabel: 'agenda',
            icon: 'agenda',
            gtmAction: 'agenda',
            gtmLabel: 'redirection',
        },
        navDisplayRules: {},
    },
    [ENavIDs.REVIEWS__INSTANT_MESSAGING__POSTS_PUBLICATIONS]: {
        id: ENavIDs.REVIEWS__INSTANT_MESSAGING__POSTS_PUBLICATIONS,
        type: 'normal',
        route: '',
        navItem: {
            ...groupNavItem,
        },
    },
    [ENavIDs.COMPANY_PERKS_GROUP]: {
        id: ENavIDs.COMPANY_PERKS_GROUP,
        type: 'normal',
        route: '',
        navItem: {
            ...groupNavItem,
        },
    },
    [ENavIDs.LOCATION_INFORMATION]: {
        id: ENavIDs.LOCATION_INFORMATION,
        type: 'normal',
        route: `/locations`,

        navItem: {
            useConfigIconBasePath: true,
            title: 'menu_items:location_information',
            icon: 'icon_menu_infoetab',
            ariaLabel: 'side_nav:open_my_location',
            gtmAction: 'locations',
            gtmLabel: 'redirection',
        },
    },
    [ENavIDs.PHOTOS_VIDEOS_INFO]: {
        id: ENavIDs.PHOTOS_VIDEOS_INFO,
        type: 'normal',
        route: `routeId://${NavLogicRouteIds.PHOTOS_VIDEOS_INFO_CONDITIONAL}`,

        navItem: {
            useConfigIconBasePath: true,
            title: 'menu_items:photos_videos_info',
            icon: 'icon_menu_photo',
            ariaLabel: 'side_nav:open_my_medias',
            gtmAction: 'gallery',
            gtmLabel: 'redirection',
        },
    },
    [ENavIDs.HOME_STATISTICS]: {
        id: ENavIDs.HOME_STATISTICS,
        type: 'normal',
        route: '',

        navItem: {
            useConfigIconBasePath: true,
            title: '',
            icon: '',
            ariaLabel: '',
            gtmAction: '',
            gtmLabel: '',
        },
        navDisplayRules: {},
    },
    [ENavIDs.HOME]: {
        id: ENavIDs.HOME,
        type: 'normal',
        route: '/home',

        navItem: {
            useConfigIconBasePath: true,
            title: 'menu_items:home',
            icon: 'icon_menu_home',
            ariaLabel: 'home',
            gtmAction: 'home',
            gtmLabel: 'redirection',
        },
        navDisplayRules: {},
    },
    [ENavIDs.AGENDA]: {
        id: ENavIDs.AGENDA,
        type: 'normal',
        route: '/agenda/discover',

        navItem: {
            title: 'menu_items:agenda',
            ariaLabel: 'agenda',
            icon: 'icon_menu_calendar',
            useConfigIconBasePath: true,
            gtmAction: 'agenda',
            gtmLabel: 'redirection',
        },
        navDisplayRules: {},
    },
    [ENavIDs.ONBOARDING_JOURNEY_GROUP]: {
        id: ENavIDs.ONBOARDING_JOURNEY_GROUP,
        type: 'normal',
        route: '',

        navItem: groupNavItem,
    },
    [ENavIDs.ONBOARDING_JOURNEY]: {
        id: ENavIDs.ONBOARDING_JOURNEY,
        type: 'normal',
        route: 'routeId://ONBOARDING_JOURNEY_CONDITIONAL',

        navItem: {
            title: 'menu_items:onboarding_journey',
            ariaLabel: 'onboarding-journey',
            icon: 'icon_menu_configuration',
            gtmAction: 'onboarding-journey',
            gtmLabel: 'redirection',
            class: ['onboarding'],
            useConfigIconBasePath: true,
            customBackground: '#6ff99c',
            customSelectedBackground: '#09AB7F',
            selectedIconFilter: 'brightness(0) invert(1)',
            customSelectedColor: 'white',
        },
        navDisplayRules: {
            customFilterId: CustomNavItemFilterIds.ONBOARDING_JOURNEY,
            hide: true,
        },
    },
    [ENavIDs.AGENDA__CLICK_AND_COLLECT]: {
        id: ENavIDs.AGENDA__CLICK_AND_COLLECT,
        type: 'normal',
        route: '',

        navItem: {
            title: '',
            ariaLabel: '',
            icon: '',
            useConfigIconBasePath: true,
            gtmAction: 'agenda_click_and_collect',
            gtmLabel: 'redirection',
        },
        navDisplayRules: {},
    },

    [ENavIDs.MY_CLIENTS]: {
        id: ENavIDs.MY_CLIENTS,
        type: 'normal',
        route: '/my-clients',

        navItem: {
            title: 'side_nav:my_clients',
            ariaLabel: 'side_nav:unfold_my_clients',
            icon: 'chat',
            useConfigIconBasePath: true,
            gtmAction: 'my-clients',
            gtmLabel: 'redirection',
        },

        navDisplayRules: {},
    },

    [ENavIDs.REVIEWS]: {
        id: ENavIDs.REVIEWS,
        type: 'normal',
        route: '/reputation/review',

        navItem: {
            title: 'menu_items:my_reviews',
            ariaLabel: 'side_nav:open_my_reviews',
            icon: 'icon_menu_review',
            useConfigIconBasePath: true,
            gtmAction: 'reviews',
            gtmLabel: 'redirection',
        },

        navDisplayRules: {},
    },

    [ENavIDs.INSTANT_MESSAGING]: {
        id: ENavIDs.INSTANT_MESSAGING,
        type: 'normal',
        route: '/instant-messaging/list',

        navItem: {
            title: 'menu_items:instant_msg',
            ariaLabel: 'side_nav:open_instant_msg',
            icon: 'icon_menu_messaging',
            useConfigIconBasePath: true,
            gtmAction: 'instant-messaging',
            gtmLabel: 'redirection',
        },

        navDisplayRules: {
            showForFeature: [EPackFeatures.chatPJ, EPackFeatures.chatFB],
        },
    },

    [ENavIDs.QUOTE_REQUEST]: {
        id: ENavIDs.QUOTE_REQUEST,
        type: 'normal',
        route: '/quotation',

        navItem: {
            title: 'menu_items:quotation',
            ariaLabel: 'side_nav:open_quotation',
            icon: 'icon_menu_request_customer',
            useConfigIconBasePath: true,
            gtmAction: 'quotation',
            gtmLabel: 'redirection',
        },

        navDisplayRules: {},
    },
    [ENavIDs.MY_PUBLICATIONS_GROUP]: {
        id: ENavIDs.MY_PUBLICATIONS_GROUP,
        type: 'normal',
        route: '',

        navItem: { ...groupNavItem },
    },
    [ENavIDs.MY_PUBLICATIONS]: {
        id: ENavIDs.MY_PUBLICATIONS,
        type: 'normal',
        route: '',

        navItem: {
            title: 'side_nav:my_publications',
            ariaLabel: 'side_nav:unfold_my_publications',
            icon: 'icon_menu_emails_campaign',
            useConfigIconBasePath: true,
            gtmAction: 'my-publications',
            gtmLabel: 'toggle',
        },

        navDisplayRules: {},
    },
    [ENavIDs.NEWS_1]: {
        id: ENavIDs.NEWS_1,
        type: 'normal',
        route: '/post',

        navItem: {
            title: 'menu_items:my_news',
            pageTitleCaps: false,
            ariaLabel: 'side_nav:open_my_news',
            icon: 'icon_menu_post',
            useConfigIconBasePath: true,
            gtmAction: 'post',
            gtmLabel: 'redirection',
        },

        navDisplayRules: {},
    },

    [ENavIDs.NEWS]: {
        id: ENavIDs.NEWS,
        type: 'normal',
        route: '/post',

        navItem: {
            title: 'side_nav:my_news',
            ariaLabel: 'side_nav:open_my_news',
            icon: 'news-grey',
            useConfigIconBasePath: true,
            gtmAction: 'post',
            gtmLabel: 'redirection',
        },

        navDisplayRules: {},
    },

    [ENavIDs.MY_VISIBILITY]: {
        id: ENavIDs.MY_VISIBILITY,
        type: 'normal',
        route: '',

        navItem: {
            title: 'side_nav:my_visibility',
            ariaLabel: 'side_nav:unfold_my_visibility',
            icon: 'home',
            useConfigIconBasePath: true,
            gtmAction: 'my-visibility',
            gtmLabel: 'toggle',
        },

        navDisplayRules: {},
    },
    [ENavIDs.MY_LOCATIONS]: {
        id: ENavIDs.MY_LOCATIONS,
        type: 'normal',
        route: '/locations',

        navItem: {
            title: 'side_nav:my_location',
            ariaLabel: 'side_nav:open_my_location',
            icon: 'house',
            useConfigIconBasePath: true,
            gtmAction: 'locations',
            gtmLabel: 'redirection',
        },

        navDisplayRules: {},
    },
    [ENavIDs.WEBSITE_NAV_GROUP]: {
        id: ENavIDs.WEBSITE_NAV_GROUP,
        type: 'normal',
        route: '',

        navItem: {
            ...groupNavItem,
        },
    },
    [ENavIDs.MY_WEBSITES]: {
        id: ENavIDs.MY_WEBSITES,
        type: 'normal',
        route: '/offer-presentation/website',
        extra: { queryParams: { type: 'WEBSITE' } },

        navItem: {
            title: 'menu_items:my_websites',
            ariaLabel: 'side_nav:open_my_websites',
            icon: 'icon_menu_website',
            useConfigIconBasePath: true,
            gtmAction: 'websites',
            gtmLabel: 'redirection',
        },

        navDisplayRules: {},
    },
    [ENavIDs.MY_PHOTOS]: {
        id: ENavIDs.MY_PHOTOS,
        type: 'normal',
        route: '/gallery/pictures',

        navItem: {
            title: 'side_nav:my_medias',
            ariaLabel: 'side_nav:open_my_medias',
            icon: 'photos',
            useConfigIconBasePath: true,
            gtmAction: 'gallery',
            gtmLabel: 'redirection',
        },

        navDisplayRules: {},
    },

    [ENavIDs.SOLOCAL_AND_I]: {
        id: ENavIDs.SOLOCAL_AND_I,
        type: 'normal',
        route: '',

        navItem: {
            title: 'side_nav:solocal_and_i',
            ariaLabel: 'side_nav:unfold_solocal_and_i',
            icon: 'solocal-me',
            useConfigIconBasePath: true,
            gtmAction: 'solocal-me',
            gtmLabel: 'toggle',
        },

        navDisplayRules: {},
    },
    [ENavIDs.MY_SOLUTIONS]: {
        id: ENavIDs.MY_SOLUTIONS,
        type: 'normal',
        route: '/offer-presentation',

        navItem: {
            title: 'menu_items:my_solutions',
            ariaLabel: 'menu_items:open_my_solutions',
            icon: 'ri-apps-2-line',
            useConfigIconBasePath: true,
            gtmAction: 'solution',
            gtmLabel: 'redirection',
            pageTile: 'page_title:my_solutions_page',
        },

        navDisplayRules: {},
    },
    [ENavIDs.MY_INVOICES]: {
        id: ENavIDs.MY_INVOICES,
        type: 'normal',
        route: '/invoices',

        navItem: {
            title: 'menu_items:my_invoices',
            ariaLabel: 'menu_items:open_my_invoices',
            icon: 'build-icon-invoices-line',
            useConfigIconBasePath: false,
            gtmAction: 'invoices',
            gtmLabel: 'redirection',
            pageTile: 'page_title:my_invoices',
        },

        navDisplayRules: {},
    },
    [ENavIDs.MY_ORDERS]: {
        id: ENavIDs.MY_ORDERS,
        type: 'normal',
        route: '/orders',

        navItem: {
            title: 'menu_items:my_orders',
            pageTitleCaps: false,
            ariaLabel: 'menu_items:open_my_orders',
            icon: 'ri-bill-line',
            useConfigIconBasePath: true,
            gtmAction: 'orders',
            gtmLabel: 'redirection',
            pageTile: 'page_title:my_orders',
        },

        navDisplayRules: {},
    },
    [ENavIDs.MY_APPOINTMENTS]: {
        id: ENavIDs.MY_APPOINTMENTS,
        type: 'normal',
        route: '/meetings',

        navItem: {
            title: 'menu_items:my_appointments',
            ariaLabel: 'menu_items:open_my_appointments',
            icon: 'ri-customer-service-2-line',
            useConfigIconBasePath: true,
            gtmAction: 'meetings',
            gtmLabel: 'redirection',
            pageTile: 'page_title:my_appointments',
        },

        navDisplayRules: {},
    },
    [ENavIDs.MY_SERVICES]: {
        id: ENavIDs.MY_SERVICES,
        type: 'normal',
        route: '/contact-solocal',

        navItem: {
            title: 'menu_items:solocal_services',
            ariaLabel: 'menu_items:open_solocal_services',
            icon: 'ri-question-answer-line',
            useConfigIconBasePath: true,
            gtmAction: 'myservices',
            gtmLabel: 'redirection',
            pageTile: 'page_title:my_services_page',
        },

        navDisplayRules: {},
    },
    [ENavIDs.HELP_TUTORIALS]: {
        id: ENavIDs.HELP_TUTORIALS,
        type: 'normal',
        route: '',

        navItem: {
            title: 'side_nav:assistance',
            ariaLabel: 'side_nav:open_tutorial',
            icon: 'icon_nav_faq_access_grey',
            useConfigIconBasePath: false,
            gtmAction: 'online-help',
            gtmLabel: 'toggle',
        },

        navDisplayRules: {},
    },
    [ENavIDs.PREFERENCES]: {
        id: ENavIDs.PREFERENCES,
        type: 'normal',
        route: '/profile/preferences',

        navItem: {
            title: 'menu_items:settings',
            ariaLabel: 'header_nav_cmp:open_preferences_page',
            icon: 'ri-user-settings-line',
            useConfigIconBasePath: false,
            gtmAction: 'content',
            gtmLabel: '//',
        },

        navDisplayRules: {},
    },
    [ENavIDs.NOTIFICATIONS]: {
        id: ENavIDs.NOTIFICATIONS,
        type: 'action',
        route: '',

        navItem: {
            title: 'Todo list',
            ariaLabel: 'header_navbar:open_todo_list',
            icon: 'notifications',
            useConfigIconBasePath: false,
            gtmAction: 'todolist',
            gtmLabel: 'toggle',
        },

        navDisplayRules: {},
    },
    [ENavIDs.ACCOUNTS]: {
        id: ENavIDs.ACCOUNTS,
        type: 'action',
        route: '',

        navItem: {
            title: 'Account',
            gtmAction: 'account',
            icon: 'account',
            useConfigIconBasePath: false,
            gtmLabel: 'toggle',
            ariaLabel: 'header_navbar:open_account_menu',
        },

        navDisplayRules: {},
    },
    [ENavIDs.ACCOUNTS_EDIT]: {
        id: ENavIDs.ACCOUNTS_EDIT,
        type: 'normal',
        route: '/profile/account',

        navItem: {
            title: 'My account',
            ariaLabel: 'header_nav_cmp:open_acc_page',
            icon: 'ri-account-circle-line',
            useConfigIconBasePath: false,
            gtmAction: 'profile',
            gtmLabel: 'redirection',
        },

        navDisplayRules: {},
    },
    [ENavIDs.ACCOUNTS_CONSULT_ESTABLISHMENT]: {
        id: ENavIDs.ACCOUNTS_CONSULT_ESTABLISHMENT,
        type: 'normal',
        route: '/locations',

        navItem: {
            title: 'Location information',
            ariaLabel: 'header_navbar:open_establishments_page',
            icon: 'content-edit',
            useConfigIconBasePath: false,
            gtmAction: 'content',
            gtmLabel: 'redirection',
            class: [],
        },

        navDisplayRules: {},
    },
    [ENavIDs.PLUGIN_TEST_GROUP]: {
        id: ENavIDs.PLUGIN_TEST_GROUP,
        type: 'normal',
        route: '',

        navItem: groupNavItem,
    },
};

export const routeIdToLogicMap: { [routeId: string]: NavLogicFactory } = {
    [NavLogicRouteIds.LOCATION_INFORMATION_CONDITIONAL]: locationInformationCondNavLogicFact,
    [NavLogicRouteIds.PHOTOS_VIDEOS_INFO]: photosVideosInfoNavLogicFact,
    [NavLogicRouteIds.PHOTOS_VIDEOS_INFO_CONDITIONAL]: photosVideosInfoCondFact,
    [NavLogicRouteIds.LOCATION_INFORMATION]: locationInformationNavLogicFact,
    [NavLogicRouteIds.ONBOARDING_JOURNEY_CONDITIONAL]: onboardingJourneyCondNavLogicFact,
    [NavLogicRouteIds.ONBOARDING_JOURNEY]: locationInformationNavLogicFact,
    [NavLogicRouteIds.SITES_RESEAUX]: sitesReseauxNavLogicFact,
};

export const customNavItemFilterIdMap: {
    [filterId: string]: CustomMenuItemFilterFactory;
} = {
    [CustomNavItemFilterIds.ONBOARDING_JOURNEY]: onboardingJourneyCustomItemFilterFact,
};

function showForPack(packnames: EPacks[]) {
    let packList = Object.values(EPacks);
    packList = packList.filter((el: EPacks) => {
        return !packnames.includes(el);
    });

    return packList;
}

export const GENERAL_MENU_ICON_BASE_PATH = 'assets/images/menu/';
export const LOGOUT_ICON = 'ri-shut-down-line';
