import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import {
    ControlValueAccessor,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { uniq } from 'lodash-es';
import { NGXLogger } from 'ngx-logger';

import { VideoInfo } from '@solocal-manager/sirius/support/base-models';

import { YoutubeService } from '../../services';
import { VideoValidator, YoutubeUrlValidator } from '../validators';

@Component({
    template: '',
})
export class WpmInputVideoComponent implements ControlValueAccessor, OnChanges, OnInit, OnDestroy {
    @Input() video: VideoInfo[];
    @Input() companyId: string;

    formSubscribtion;
    youtubeSubscribtion;
    currentVideo: VideoInfo;
    videoForm: UntypedFormGroup;
    videoUrlChanged = false;
    videoUrlEmbed: string;
    videoList: UntypedFormArray;
    urlEmbedList = [];
    videoError = false;
    videoErrorIndex: number[] = [];
    inputVideoUrl: string;

    propagateChange: any = () => {
        return void 0;
    };
    validateFn: any = () => {
        return void 0;
    };

    constructor(
        public formBuilder: UntypedFormBuilder,
        public youtubeService: YoutubeService,
        public logger: NGXLogger,
        public store: Store<any>,
    ) {
        this.videoForm = this.formBuilder.group({
            video_form: this.formBuilder.array([this.createContact()]),
        });
        this.videoList = this.videoForm.get('video_form') as UntypedFormArray;
    }
    registerOnTouched(fn: any): void {
        throw new Error('Method not implemented.');
    }
    setDisabledState?(isDisabled: boolean): void {
        throw new Error('Method not implemented.');
    }

    createContact(): UntypedFormGroup {
        return this.formBuilder.group({
            company_id: [''],
            title: [''],
            video_url: ['', [YoutubeUrlValidator.create()]],
            id: [''],
            description: [''],
            length: [null],
        });
    }

    ngOnInit() {
        this.formSubscribtion = this.videoForm.valueChanges.subscribe(controls => {
            this.propagateChange(controls);
        });
    }

    ngOnChanges(inputs) {
        const currentVideo =
            inputs.video.currentValue.length && inputs.video.currentValue[0].video_url
                ? inputs.video.currentValue[0]
                : this.getVideoObject(this.companyId);
        this.videoUrlEmbed = '';
        this.urlEmbedList = [];
        for (let i = this.videoList.length; i < inputs.video.currentValue.length; i++) {
            this.videoList.push(this.createContact());
        }

        if (this.companyId && currentVideo.video_url) {
            this.currentVideo = currentVideo;
            this.videoUrlEmbed = this.youtubeService.getVideoUrlEmbed(currentVideo.video_url);
            this.videoForm.controls['video_form'].setValue(inputs.video.currentValue);
        }
        for (let i = 0; i < this.videoList.value.length; i++) {
            if (this.videoList.value[i].video_url) {
                this.urlEmbedList.push(this.youtubeService.getVideoUrlEmbed(this.videoList.value[i].video_url));
            }
        }
        this.validateFn = VideoValidator.create(this.videoForm);
    }

    ngOnDestroy(): void {
        this.formSubscribtion.unsubscribe();

        if (this.youtubeSubscribtion) {
            this.youtubeSubscribtion.unsubscribe();
        }
    }

    onChange($event, index: number): void {
        this.videoError = false;
        this.videoErrorIndex = [];
        this.formBuilder.control('id').setValue('');
        this.currentVideo = this.getVideoObject();
        this.inputVideoUrl = $event.target.value;
        if (this.videoForm.valid) {
            this.saveVideo(index);
        } else {
            this.propagateChange(this.formBuilder.control);
            this.videoForm.controls['video_form']['controls'][index].setValue(this.currentVideo);
        }
    }

    getVideoObject(companyId?: string, videoUrl?: string): VideoInfo {
        return {
            company_id: companyId || '',
            title: '',
            video_url: videoUrl || '',
            id: '',
            description: '',
            length: null,
        };
    }

    saveVideo(index: number): void {
        const videoId = this.youtubeService.getVideoId(this.videoForm.value.video_form[index].video_url);
        if (videoId) {
            this.youtubeSubscribtion = this.youtubeService
                .getVideoInfo(videoId, this.companyId)
                .subscribe((info: any) => {
                    if (!info || !info.video) {
                        // Unable to get video data, (privte vid or error)
                        this.videoError = true;
                        this.setVideoErrors(index);
                        this.videoUrlEmbed = this.youtubeService.getVideoUrlEmbed(this.inputVideoUrl);
                        return;
                    }

                    const video = info.video;
                    this.videoError = !info.videoIsAvailable;
                    if (this.videoError) {
                        this.setVideoErrors(index);
                    }
                    if (video && !this.videoError) {
                        this.currentVideo = { ...video };
                        if (index === 0) {
                            this.videoUrlEmbed = this.youtubeService.getVideoUrlEmbed(this.currentVideo.video_url);
                        }
                        this.videoForm.controls['video_form']['controls'][index].setValue(this.currentVideo);
                    }
                });
        }
    }

    setVideoErrors(index: number): void {
        this.videoErrorIndex.push(index);
        this.videoErrorIndex = uniq(this.videoErrorIndex);

        setTimeout(() => {
            this.videoForm.controls['video_form']['controls'][index].setErrors({
                invalid: true,
            });
            this.videoForm.updateValueAndValidity();
        });
    }

    writeValue(value) {
        if (value) {
            this.video = value;
        }
    }

    registerOnChange(fn) {
        this.propagateChange = newValue => {
            const fixedData = [];
            if (newValue && newValue.video_form && newValue.video_form.length) {
                fixedData.push(...newValue.video_form);
            }
            return fn(fixedData);
        };
    }

    validate(c: UntypedFormControl) {
        return this.validateFn(c);
    }

    addVideo(): void {
        this.videoList.push(this.createContact());
    }

    removeVideo(index: number): void {
        this.videoList.removeAt(index);
        this.urlEmbedList.splice(index, 1);
        this.videoErrorIndex.splice(this.videoErrorIndex.indexOf(index), 1);
        if (this.videoList.value.length === 0) {
            this.videoUrlEmbed = '';
        }
        if (this.companyId && index === 0 && this.videoList.value.length > 0 && this.videoList.value[0].video_url) {
            this.videoUrlEmbed = '';
            this.videoUrlEmbed = this.youtubeService.getVideoUrlEmbed(this.videoList.value[0].video_url);
        }
    }
}
