import { Pipe, PipeTransform } from '@angular/core';

import { PublisherStatusStateDescription } from '../models';

@Pipe({
    name: 'publisherStatusStateDescription',
})
export class PublisherStatusStateDescriptionPipe implements PipeTransform {
    transform(stateDescription: PublisherStatusStateDescription, args?: any): any {
        let result = '';
        // if (stateDescription.details.length) {
        //     stateDescription.details.forEach((detail: ErrorDetail) => {
        //         if (detail.message.toLowerCase() === 'error') {
        //             result += `<div class="invalid-field"> - ${stateDescription.user_msg} (${detail.field})</div>`;
        //         }
        //         else {
        //             result += `<div class="invalid-field"> - ${detail.message}</div>`;
        //         }
        //     });
        // }
        // else

        if (stateDescription.user_msg) {
            result += `<div class="invalid-field">${stateDescription.user_msg}</div>`;
        }
        return result;
    }
}
