import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getCoordinates, getLogo, getPagesJaunesActivities } from '@slm/location/state';
import { Location } from '@solocal-manager/sirius/support/base-models';
import * as L from 'leaflet/dist/leaflet';
import { toUpper } from 'lodash-es';
import { Subject } from 'rxjs';

/*
Prerequisite:
- npm i -D @types/leaflet
- npm i -S leaflet
- Add the following scripts in your index.html
  <link
      rel="stylesheet"
      type="text/css"
      href="https://unpkg.com/leaflet/dist/leaflet.css"
  />
*/

@Component({
    template: '',
})
export class WpmLocationsMapComponent implements OnChanges, OnDestroy, AfterViewInit {
    @Input() locations: Location[];
    @Input() showPopups = true;
    @Input() showControls = true;
    @Input() roundedCorners = true;
    @Input() draggable = true;
    @Input() draggableMarker = false;
    @Output() markerPlaced: EventEmitter<L.LatLng> = new EventEmitter();
    mapContainerId = 'locations-map-container';
    protected locationsMap: L.Map;
    private bounds: L.LatLngBoundsLiteral;
    private mappyTilesUrl = 'https://map{s}.mappy.net/map/1.0/slab/standard/256/{z}/{x}/{y}';
    private markersLayer: L.LayerGroup;
    private zoomControl: L.Control.Zoom;
    isDestroyed$: Subject<void> = new Subject();

    constructor(protected translate: TranslateService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (!!changes.locations && !!changes.locations.currentValue) {
            this.bounds = undefined;
            this.filterLocations();
        }
        if (this.locations && this.locations.length > 1) {
            // Disable dragging when multiple markers are present
            this.draggableMarker = false;
            if (this.locationsMap) {
                this.updateMap();
                // this.resetMap();
            }
        }
        if (!!this.locationsMap && this.locations && this.locations.length === 1) {
            // Happens on changes but not on first load
            this.resetMap();
        }
    }

    ngAfterViewInit(): void {
        // Only happens on first load
        setTimeout(() => {
            this.initMap();
            this.toggleZoomControl();
            this.setMarkers();
            this.setMapBounds();
        }, 500);
    }

    updateMap(): void {
        /**
         * Happens when we have multiple locations and the list changes
         * Adding more markers and update map bounds
         */
        setTimeout(() => {
            this.setMarkers();
            this.setMapBounds();
        });
    }

    private resetMap(): void {
        this.locationsMap?.remove();

        setTimeout(() => {
            this.initMap();
            this.toggleZoomControl();
            this.setMarkers();
            this.setMapBounds();
        });
    }

    private initMap() {
        const tileLayer = L.tileLayer(this.mappyTilesUrl, {
            subdomains: '1234',
            detectRetina: true,
            attribution:
                '&copy <a href="https://corporate.mappy.com/conditions-dutilisation/copyright/" title="Mappy">Mappy</a>',
        });
        this.markersLayer = L.layerGroup();
        this.locationsMap = L.map(this.mapContainerId, {
            zoomControl: false,
            layers: [tileLayer],
            scrollWheelZoom: false,
            attributionControl: false,
            dragging: this.draggable,
        });
        setTimeout(() => {
            this.locationsMap?.invalidateSize();
        }, 0);
        this.locationsMap?.addLayer(this.markersLayer);
    }

    ngOnDestroy(): void {
        this.isDestroyed$.next(null);
        this.isDestroyed$.complete();
    }

    setMapBounds(shouldFly?: boolean): void {
        if (!this.bounds) {
            this.bounds = this.locations.map(location => {
                const coords = getCoordinates(location);
                return [coords.latitude, coords.longitude] as L.LatLngTuple;
            });
        }
        if (this.bounds.length) {
            if (!shouldFly) {
                this.locationsMap?.fitBounds(this.bounds, { duration: 0.35 });
            } else {
                this.locationsMap?.flyToBounds(this.bounds, { duration: 0.35 });
            }
        }
    }

    /**
     * Only keeps locations that have coordinates
     */
    private filterLocations(): void {
        this.locations = this.locations.filter(location => {
            const coords = getCoordinates(location);
            return !!coords && !!coords.latitude && !!coords.longitude;
        });
    }

    private toggleZoomControl(): void {
        if (this.showControls) {
            this.zoomControl = L.control.zoom({
                zoomInTitle: this.translate.instant('Zoom in'),
                zoomOutTitle: this.translate.instant('Zoom out'),
                position: 'topright',
                keyboard: false,
            });
            this.locationsMap?.addControl(this.zoomControl);
        } else if (this.zoomControl) {
            this.locationsMap?.removeControl(this.zoomControl);
        }
    }

    protected setMarkers(): void {
        this.resetMarkers();
        this.locations.forEach(location => {
            const coords = getCoordinates(location);
            if (coords) {
                const marker = L.marker(L.latLng(coords.latitude, coords.longitude), {
                    icon: L.divIcon({
                        className: 'locations-map-marker',
                    }),
                    draggable: this.draggableMarker,
                    keyboard: false,
                });
                this.setDraggingEvent(marker);
                if (this.showPopups) {
                    marker.bindPopup(this.createPopup(location));
                }
                this.markersLayer.addLayer(marker);
            }
        });
    }

    private resetMarkers(): void {
        this.markersLayer.clearLayers();
    }

    private setDraggingEvent(marker: L.Marker): void {
        if (this.draggableMarker) {
            marker.on('dragend', (event: L.DragEndEvent) => {
                const coords = event.target.getLatLng();
                this.markerPlaced.emit(coords);
            });
        }
    }

    private createPopup(location: Location): L.Popup {
        const address = location.address;
        const mainActivity = getPagesJaunesActivities(location)[0];
        const logo = getLogo(location);

        return L.popup({
            closeButton: false,
            maxWidth: 600,
            minWidth: 350,
        }).setContent(
            `
        <div class="locations-map__popup">
            <div class="locations-map__popup-logo">
                <img src="${logo ? logo.thumbnail : 'assets/images/no-image.png'}">
            </div>

            <div class="locations-map__popup-info">
                <div>
                  <div class="locations-map__popup-title bold font16">${toUpper(location.name)}</div>
                  <div class="font12">
                    ${mainActivity ? mainActivity.name : ''}
                  </div>
                  <div class="font14">
                    ${address.house_number ? `${address.house_number}, ` : ''}${address.address_line1}
                    ${address.address_line2 ? `<br>${address.address_line2}` : ''}
                  </div>
                  <div class="font14">
                    ${address.zip_code} ${address.city}
                  </div>
                </div>

                <div class="locations-map__popup-link">
                    <a href="/#/app/2/location/${location.epj}/content">
                      ${this.translate.instant('Complete the information')}
                    </a>
                </div>
            </div>
        </div>
        `,
        );
    }
}
