import { Component, Input } from '@angular/core';
import { ImageErrorConfig } from '@solocal-manager/sirius/core/config';

import { IImageRules } from '@solocal-manager/sirius/core/models';
import { ImageErrorTypes } from '@solocal-manager/sirius/support/base-models';

@Component({ template: '' })
export abstract class BaseImageErrorMessageComponent {
    @Input() imageTypeErrors?: ImageErrorTypes[];
    @Input() imageRules?: IImageRules;
    @Input() imageErrorConfig?: ImageErrorConfig[];
    abstract translationPrefix: string;
    abstract translationSuffix: string;
}
