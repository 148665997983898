<carousel
    [interval]="myInterval"
    [noWrap]="false"
    [(activeSlide)]="activeSlideIndex"
>
    <slide
        *ngFor="let slide of slides; let index = index"
        class="slide-{{ index }} slide-container"
    >
        <img [src]="slide.image" alt="image slide" />

        <div class="carousel-caption">
            <h4 translate>Step {{ index + 1 }}</h4>
        </div>
        <div class="fb-tutorial-btns">
            <button
                *ngIf="activeSlideIndex > 0"
                (click)="prevSlide()"
                class="btn btn-success fb-tutorial-btn"
                translate
            >
                Back
            </button>
            <button
                *ngIf="!isLastPage"
                (click)="nextSlide()"
                class="btn btn-success fb-tutorial-btn"
                translate
            >
                Continue
            </button>
            <button
                *ngIf="isLastPage"
                (click)="slidesDone()"
                class="btn btn-success fb-tutorial-btn"
                translate
            >
                Done
            </button>
        </div>
    </slide>
</carousel>
<br />
