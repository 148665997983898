import { forwardRef, Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';

import { WpmInputCategory } from '@solocal-manager/sirius/core/core';
import { Reference } from '@solocal-manager/sirius/support/base-models';

@Component({
    selector: 'app-input-category-publisher',
    templateUrl: './input-category-publisher.component.html',
    styleUrls: ['./input-category-publisher.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputCategoryPublisherComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => InputCategoryPublisherComponent),
            multi: true,
        },
    ],
})
export class InputCategoryPublisherComponent extends WpmInputCategory implements ControlValueAccessor, OnInit {
    @Input() control: UntypedFormControl; // needed for input-error-messages

    ngOnInit(): void {
        super.ngOnInit();

        this.formRepeatable.valueChanges
            .pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.destroyed$))
            .subscribe(() => this.updateCategories());
    }

    writeValue(value) {
        if (value) {
            super.writeValue(this.getPublisherCategories(this.publisherId, value));
        }
    }

    registerOnChange(fn) {
        this.propagateChange = () => {
            const clearData = [
                {
                    publisher_id: this.publisherId,
                    categories: this.formRepeatable.get('data').value,
                },
            ];

            return fn(clearData);
        };
    }

    getPublisherCategories(publisherId: string, publisherSpecificArray: any[]): Reference[] {
        const desiredGroup = publisherSpecificArray.find(pubSpecGroup => pubSpecGroup.publisher_id === publisherId);
        const cats = desiredGroup ? desiredGroup.categories : [];
        return cats;
    }
}
