import { createSelector } from '@ngrx/store';

import { IContactDetails } from '@solocal-manager/sirius/core/models';

import { ContactDetailsActionTypes, ContactDetailsActionUnion } from '../actions';

export type ContactDetailsState = IContactDetails;

const initialState: ContactDetailsState = {
    mail: undefined,
    nom: undefined,
    prenom: undefined,
    civilite: undefined,
};

export function contactDetailsReducer(state = initialState, action: ContactDetailsActionUnion): ContactDetailsState {
    switch (action.type) {
        case ContactDetailsActionTypes.GET_CONTACT_DETAILS_SUCCESS: {
            const newState: ContactDetailsState = { ...action.payload.data };
            return newState;
        }
        case ContactDetailsActionTypes.PATCH_CONTACT_DETAILS_SUCCESS: {
            return { ...state, ...action.payload.data };
        }
        default: {
            return state;
        }
    }
}

export const getProfile = (state: any) => state.myProfile;

export const getContactDetails = createSelector(getProfile, profile => profile.contactDetails);
