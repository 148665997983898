import { createAction, props } from '@ngrx/store';

import {
    ENavIDs,
    EPageIDs,
    FilterItem,
    NavItem,
    NavOrderModel,
    NavOrderRootModel,
    NavPageView,
    PageBreadcrumbs,
    PageFilters,
    PageItem,
    PageSummary,
    PageTitle,
} from '@solocal-manager/sirius/core/models';

export enum NavPageActionTypes {
    InitNav = '[NAV PAGE] Init',
    FetchApi = '[NAV PAGE] Fetch API',
    FetchDone = '[NAV PAGE] Fetch done',

    NavClick = '[NAV PAGE] Nav clicked',
    PageLoaded = '[NAV PAGE] Page loaded',

    SetHeaderNav = '[NAV PAGE] Set header NAV',
    SetSideNav = '[NAV PAGE] Set side NAV',

    SetNavItem = '[NAV PAGE] Set NAV item',
    SetNavItemAttr = '[NAV PAGE] Set NAV item attr',

    SetActivePage = '[NAV PAGE] Set active PAGE',
    SetPrevPage = '[NAV PAGE] Set prev PAGE',
    SetActivePageConfig = '[NAV PAGE] Set active PAGE config',
    InitActivePage = '[NAV PAGE] Init active PAGE on load',
    SetPageItemAttr = '[NAV PAGE] Set PAGE item attr',

    // PAGE FILTERS PART
    SetFilters = '[PAGE FILTERS] Set Page Filters',
    ResetFilters = '[PAGE FILTERS] Reset Page Filters',
    UpdateFilter = '[PAGE FILTERS] Update Single Filter Selected VALUE',
    AddUpdateFilter = '[PAGE FILTERS] Add / Update filters',
    ClearFilter = '[PAGE FILTERS] Clear Single Filter',
    SetFilterView = '[PAGE FILTERS] Set Filter visibility',
    SetSecFiltView = '[PAGE FILTERS] Set secondary Filter visibility',
    PatchSideNavGroup = '[NAV PAGE] Patch Side Nav Group',

    MergeNav = '[NAV PAGE] Merge Nav',

    SetNavItemHidden = '[NAV PAGE] Set Nav Item Hidden',

    PageSummaryAction = '[NAV PAGE] page summary action',
}

export const setNavItemHidden = createAction(
    NavPageActionTypes.SetNavItemHidden,
    props<{
        id: string | ENavIDs;
        hidden: boolean;
    }>(),
);

export const mergeSidNav = createAction(
    NavPageActionTypes.MergeNav,
    props<{
        items: NavOrderRootModel[];
    }>(),
);

export const setHeaderNav = createAction(NavPageActionTypes.SetHeaderNav, props<{ payload: NavOrderRootModel[] }>());

export const setSideNav = createAction(NavPageActionTypes.SetSideNav, props<{ payload: NavOrderRootModel[] }>());

export const patchSideNavGroup = createAction(
    NavPageActionTypes.PatchSideNavGroup,
    props<{
        groupId: ENavIDs;
        children: NavOrderModel[];
    }>(),
);

export const setNavItem = createAction(
    NavPageActionTypes.SetNavItem,
    props<{ payload: { nav: ENavIDs; data: NavItem } }>(),
);

export const setNavItemAttr = createAction(
    NavPageActionTypes.SetNavItemAttr,
    props<{
        payload: {
            page: ENavIDs;
            data: NavPageView | PageBreadcrumbs[] | PageTitle | PageFilters;
            field: string;
        };
    }>(),
);

export const setActivePage = createAction(
    NavPageActionTypes.SetActivePage,
    props<{ payload: { pageID: EPageIDs | string } }>(),
);

export const setPrevPage = createAction(
    NavPageActionTypes.SetPrevPage,
    props<{ payload: { pageID: EPageIDs | string; path: string } }>(),
);

export const setActivePageConfig = createAction(
    NavPageActionTypes.SetActivePageConfig,
    props<{ payload: { page: PageItem } }>(),
);

export const initActivePage = createAction(
    NavPageActionTypes.InitActivePage,
    props<{ payload: { pageID: EPageIDs | string; page: PageItem } }>(),
);

export const setPageItemAttr = createAction(
    NavPageActionTypes.SetPageItemAttr,
    props<{
        payload: {
            data: PageTitle | PageBreadcrumbs[] | PageSummary;
            field: string;
        };
    }>(),
);

// page filters
export const setFilters = createAction(NavPageActionTypes.SetFilters, props<{ payload: { data: FilterItem[] } }>());

export const resetFilters = createAction(NavPageActionTypes.ResetFilters);

export const updateFilter = createAction(
    NavPageActionTypes.UpdateFilter,
    props<{ payload: { filterID: string; values: any[] } }>(),
);

export const addFilter = createAction(NavPageActionTypes.AddUpdateFilter, props<{ payload: { filter: FilterItem } }>());

export const pageSummaryAction = createAction(NavPageActionTypes.PageSummaryAction, props<{ payload: string }>());

export const setFilterView = createAction(NavPageActionTypes.SetFilterView, props<{ payload: { show: boolean } }>());

export const setSecFiltView = createAction(NavPageActionTypes.SetSecFiltView, props<{ payload: { show: boolean } }>());

export const clearFilter = createAction(NavPageActionTypes.ClearFilter);
