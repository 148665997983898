import { Coordinates, ELocationRole, Location, Photo, Reference } from '@solocal-manager/sirius/support/base-models';

export function sortByName(a: Location, b: Location): number {
    const aliasOrName = (location: Location): string => location?.alias || location?.name;

    return aliasOrName(a)?.localeCompare(aliasOrName(b));
}

const singleSelectMatch = (location: Location, ids: string[], allowPrehab: boolean): boolean =>
    (ids.includes(location.id) || ids?.length === 0) &&
    (!location.roles?.includes(ELocationRole.preHabilitated) || allowPrehab);

// remove when unused
export function setSelectedIdFromIds(ids: string[], locations: Array<Location>): string {
    const allowPrehab = !locations.some(location => singleSelectMatch(location, ids, false));
    return [...locations].sort(sortByName).find(location => singleSelectMatch(location, ids, allowPrehab))?.id;
}

export function getPagesJaunesActivities(location: Location): Reference[] {
    const publisherSpecific: Location['publisher_specific'] = location?.publisher_specific ?? [];
    return publisherSpecific.find(pub => pub.publisher_id === 'pagesjaunes')?.categories ?? [];
}

export function getCoordinates(location: Location): Coordinates {
    return location?.address?.coordinates;
}

export function getLogo(location: Location): Photo {
    const photos: Photo[] = location?.photos ?? [];
    return photos.find(photo => photo.type === 'logo');
}
