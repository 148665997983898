import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

// services
import { WpmLocationListItemComponent } from '@solocal-manager/sirius/core/core';

@Component({
    selector: 'slm-location-list-item',
    templateUrl: './location-list-item.component.html',
    styleUrls: ['./location-list-item.component.scss'],
})
export class LocationListItemComponent extends WpmLocationListItemComponent {
    @Input() position: number;
}
