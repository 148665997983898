import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dayTitle',
})
export class DayTitlePipe implements PipeTransform {
    transform(value: any, args?: any): string {
        switch (value) {
            case 'MONDAY':
                return 'mon';
            case 'TUESDAY':
                return 'tue';
            case 'WEDNESDAY':
                return 'wed';
            case 'THURSDAY':
                return 'thu';
            case 'FRIDAY':
                return 'fri';
            case 'SATURDAY':
                return 'sat';
            case 'SUNDAY':
                return 'sun';
            default:
                return value;
        }
    }
}
