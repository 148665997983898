import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import { IfeatureSettingsState, IMyProfileState } from '../models';

import * as contactDetails from './contact-details.reducer';
import * as personalPreferencesAction from './personal-preferences.reducer';
import * as featuresSettings from './settings.reducer';

export * from './contact-details.reducer';

export const reducers: ActionReducerMap<IMyProfileState> = {
    personalPreferences: personalPreferencesAction.reducer,
    contactDetails: contactDetails.contactDetailsReducer,
    featuresSettings: featuresSettings.reducer,
};

// Create ahead-of-time for nested properties of state.
export const getMyProfileState = createFeatureSelector<IMyProfileState>('myProfile');

/**
 * Personal preferences
 */
export const getPersonalPreferences = createSelector(
    getMyProfileState,
    (state: IMyProfileState) => state.personalPreferences,
);
export const getPersonalPreferenceSetting = createSelector(
    getPersonalPreferences,
    personalPreferencesAction.getPersonalPreferenceSetting,
);

/**
 * Contact Details
 */
export const getContactDetails = createSelector(getMyProfileState, (state: IMyProfileState) => state.contactDetails);

/**
 * Features settings
 */
export const getFeatureSettingsState = createSelector(
    getMyProfileState,
    (state: IMyProfileState) => state.featuresSettings,
);

export const getPatchQuotationSettingsSuccess = createSelector(
    getFeatureSettingsState,
    (obj: IfeatureSettingsState) => {
        return obj.quotationActivated;
    },
);

export const getPatchQuotationTemporaryDeactivationSuccess = createSelector(
    getFeatureSettingsState,
    (obj: IfeatureSettingsState) => {
        return obj.temporaryActivationUpdated;
    },
);
