import { createReducer, on } from '@ngrx/store';

import { OnBehalfOptions } from '../models/on-behalf-options';

import * as OnBehalfActions from './on-behalf.actions';

export const onBehalfFeatureKey = 'onBehalf';

export interface State {
    onBehalfOptions: OnBehalfOptions;
    displayOnBehalfMessage: boolean;
}

export const initialState: State = {
    onBehalfOptions: {},
    displayOnBehalfMessage: false,
};

export interface OnBehalfPartialState {
    readonly [onBehalfFeatureKey]: State;
}

export const reducer = createReducer(
    initialState,
    on(OnBehalfActions.getOnBehalfOptionsSuccess, (state, { onBehalfOptions }) => ({
        ...state,
        onBehalfOptions,
    })),
    on(OnBehalfActions.setDisplayOnBehalfMessage, (state, { displayOnBehalfMessage }) => ({
        ...state,
        displayOnBehalfMessage,
    })),
);
