import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ImageErrorConfig } from '@solocal-manager/sirius/core/config';

import { IImageRules } from '@solocal-manager/sirius/core/models';
import { ImageErrorTypes } from '@solocal-manager/sirius/support/base-models';

@Pipe({ name: 'imgerrormessageTranslation' })
export class ImageErrorMessageTranslation implements PipeTransform {
    imageError: ImageErrorTypes;
    imageRules: IImageRules;
    imageErrorConfig: ImageErrorConfig[];
    translationPrefix: string;
    translationSuffix: string;

    constructor(private translate: TranslateService) {}

    transform(
        imageError: ImageErrorTypes,
        imageRules: IImageRules,
        imageErrorConfig: ImageErrorConfig[],
        translationPrefix: string,
        translationSuffix: string,
    ): string {
        this.imageError = imageError;
        this.imageRules = imageRules;
        this.imageErrorConfig = imageErrorConfig;
        this.translationPrefix = translationPrefix;
        this.translationSuffix = translationSuffix;
        return this.getMessage();
    }

    private getTranslateObject() {
        const config = this.imageErrorConfig.find(config => config.key === this.imageError.errorType);
        if (config) {
            const translationKey = {};
            for (const param of config.params) {
                if (param.value === 'name') {
                    translationKey[param.key] = this.imageError.name;
                } else {
                    const rule = this.imageRules[param.value];
                    translationKey[param.key] = Array.isArray(rule) ? rule.join(', ') : rule;
                }
            }
            return translationKey;
        }
        return null;
    }

    private getTranslationKey(): string {
        const typeSpecification = this.imageError.type ? `${this.imageError.type.toLowerCase()}_` : '';
        return `${this.translationPrefix.toLowerCase()}:${typeSpecification}${this.imageError.errorType}${
            this.translationSuffix
        }`;
    }

    private getMessage(): string {
        const translationKey = this.getTranslationKey();
        const translationObject = this.getTranslateObject();
        return this.translate.instant(translationKey, translationObject);
    }
}
