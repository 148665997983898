import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SiriusSharedDialogModule } from '@solocal-manager/sirius/shared/dialog';

import {
    BaseChatComponent,
    BaseChatListComponent,
    BaseChatListItemComponent,
    BaseChatPreferenceActivationComponent,
    BaseChatPreferencesComponent,
    BaseChatPreferencesRefactoComponent,
} from './components';
import { ChatEffects } from './effects/chat.effects';
import { chatReducer } from './reducers/chat.reducer';

@NgModule({
    declarations: [
        BaseChatPreferencesComponent,
        BaseChatPreferenceActivationComponent,
        BaseChatPreferencesRefactoComponent,
        BaseChatListComponent,
        BaseChatListItemComponent,
        BaseChatComponent,
    ],
    imports: [
        CommonModule,
        StoreModule.forFeature('chat', chatReducer),
        EffectsModule.forFeature([ChatEffects]),
        SiriusSharedDialogModule,
    ],
    exports: [BaseChatPreferencesComponent, BaseChatPreferenceActivationComponent],
})
export class DataAccessChatModule {}
