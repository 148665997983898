import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { EnvironmentProvider } from '@slm/shared/environment';
import { AppConfig, AuthConfig, PjSpecificConfig } from '@solocal-manager/shared/util-common';

import { environment } from '../../environments/environment';

import { BackofficeAppConfig, BackofficeAuthConfig, BackofficePjSpecifcConfig } from './config';
import { CoreRoutingModule } from './core-routing.module';

@NgModule({
    declarations: [],
    imports: [CommonModule, CoreRoutingModule],
})
export class CoreModule {
    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                { provide: AuthConfig, useClass: BackofficeAuthConfig },
                {
                    provide: EnvironmentProvider,
                    useValue: {
                        environment,
                    },
                },
                {
                    provide: AppConfig,
                    useClass: BackofficeAppConfig,
                },
                {
                    provide: PjSpecificConfig,
                    useClass: BackofficePjSpecifcConfig,
                },
            ],
        };
    }
}
