import { createAction, props } from '@ngrx/store';

import { OnBehalfOptions } from '../models/on-behalf-options';

export const getOnBehalfOptions = createAction(
    '[OnBehalf] get on behalf options',
    props<{ params: URLSearchParams }>(),
);

export const getOnBehalfOptionsSuccess = createAction(
    '[OnBehalf] get on behalf options success',
    props<{ onBehalfOptions: OnBehalfOptions }>(),
);

export const setDisplayOnBehalfMessage = createAction(
    '[OnBehalf] set display on behalf message',
    props<{ displayOnBehalfMessage: boolean }>(),
);
