import { Observable } from 'rxjs';

import { PermissionStrategy } from '../enums/permission-strategy.enum';

export abstract class PermissionEvaluation {
    abstract type: PermissionStrategy;

    abstract hasPermission(params?: { [index: string]: any }): Observable<boolean>;
}

export type PermissionEvaluationType = new (...args: any[]) => PermissionEvaluation;
