import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IPageStatesKpi, IParcUsageKpi, IProvisioningIssuesKpi, ITotalSalesKpi } from '../../models';
import { getKpiPageStates, getKpiProvisioningIssues, getKpiTotalParcUsage, getKpiTotalSales } from '../../reducers';
import { KpiTotalErrorFactoryService, KpiUtilsService } from '../../services';

@Component({
    selector: 'app-kpi-widget-container',
    templateUrl: './kpi-widget-container.component.html',
    styleUrls: ['./kpi-widget-container.component.scss'],
})
export class KpiWidgetContainerComponent implements OnInit {
    totalError$: Observable<number>;
    totalSales$: Observable<number>;
    notSuccessfulProvisionings$: Observable<number>;
    totalUses$: Observable<number>;

    constructor(
        private store: Store<any>,
        private kpiTotalErrorFactory: KpiTotalErrorFactoryService,
        private kpiUtils: KpiUtilsService,
    ) {}

    ngOnInit() {
        this.totalError$ = this.store.pipe(
            select(getKpiPageStates),
            map((pageStates: IPageStatesKpi[]) => {
                const overallStates = this.kpiTotalErrorFactory.createTotalErrorKpi(pageStates).meta.overallStates;
                return (overallStates['rejected'] || 0) + (overallStates['error'] || 0);
            }),
        );

        this.totalSales$ = this.store.pipe(
            select(getKpiTotalSales),
            map((totalSales: ITotalSalesKpi) => this.kpiUtils.getYearsTotalNumber(totalSales ? totalSales.data : null)),
        );

        this.notSuccessfulProvisionings$ = this.store.pipe(
            select(getKpiProvisioningIssues),
            map((issues: IProvisioningIssuesKpi) => this.kpiUtils.getYearsTotalNumber(issues ? issues.data : null)),
        );

        this.totalUses$ = this.store.pipe(
            select(getKpiTotalParcUsage),
            map((parcUsage: IParcUsageKpi[]) => {
                if (parcUsage && parcUsage.length) {
                    return parcUsage[parcUsage.length - 1].months[11].parc_total;
                }
                return 0;
            }),
        );
    }
}
