import { OnInit, Directive } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

import { EpjSearchActions, PublisherSearchActions } from '@solocal-manager/sirius/core/core';

import { environment } from '../../../../environments/environment';

@Directive()
export class SearchResultsComponent implements OnInit {
    results: any;
    uiUrl: string;
    searchType: string;
    currentLocation: any;
    currentId: number;

    constructor(public store: Store<any>, public page: string) {
        let current;

        this.store.pipe(select('epjSearch')).subscribe((action: any) => {
            if (EpjSearchActions.GET_EPJ_SEARCH_SUCCESS && action) {
                this.results = action.data;
            }
        });

        this.store.pipe(select('publisherSearch')).subscribe((action: any) => {
            if (PublisherSearchActions.GET_PUBLISHER_SEARCH_SUCCESS && action) {
            }
        });

        this.loadResults();
    }

    ngOnInit() {}

    setCurrentLocation(result: any) {
        this.currentId = result.id;
        this.currentLocation = result.address;
        this.uiUrl = this._createUiUrl(result);
    }

    private _createUiUrl(result: any): string {
        let url = environment.uiUrl + `?for_customer=${result.customer_id}`;

        const params = {
            company_id: result.id,
        };

        Object.keys(params).forEach(key => {
            url += `&${key}=${params[key]}`;
        });

        return url;
    }

    loadResults() {
        //
        //     this.store.dispatch({
        //         type: PublisherSearchActions.GET_PUBLISHER_SEARCH,
        //         payload: { posId: 2448, jobId: '770f2e2b-3d01-4654-8687-dd786ef11533' }
        //     });
    }
}
