import { Action } from '@ngrx/store';

import { IHabilitatedLocationListParams } from '@solocal-manager/sirius/core/models';
import { IHabilitatedLocation } from '@solocal-manager/sirius/support/base-models';
import { PaginationDTO } from '@solocal-manager/sirius/support/base-models';

export enum HabilitatedLocationListActionTypes {
    GET_HAB_LOCATION_LIST = '[HABILITATED_LOCATION_LIST] get',
    GET_HAB_LOCATION_LIST_SUCCESS = '[HABILITATED_LOCATION_LIST] get success',
    RESET_HAB_LOCATION_LIST = '[HABILITATED_LOCATION_LIST] reset',
}

export class GetHabilitatedLocationList implements Action {
    readonly type = HabilitatedLocationListActionTypes.GET_HAB_LOCATION_LIST;

    constructor(public payload: IHabilitatedLocationListParams) {}
}

export class ResetHabilitatedLocationList implements Action {
    readonly type = HabilitatedLocationListActionTypes.RESET_HAB_LOCATION_LIST;

    constructor() {}
}

export class GetHabilitatedLocationListSuccess implements Action {
    readonly type = HabilitatedLocationListActionTypes.GET_HAB_LOCATION_LIST_SUCCESS;

    constructor(public payload: PaginationDTO<IHabilitatedLocation>) {}
}

export type HabilitatedLocationListActionsUnion =
    | GetHabilitatedLocationList
    | ResetHabilitatedLocationList
    | GetHabilitatedLocationListSuccess;
