<li
    *ngIf="location"
    (mouseover)="onMouseoverLocation(location)"
    (click)="onSelectLocation(location)"
    class="list-group-item location"
    [ngClass]="{ selected: selectedLocation?.id === location.id }"
>
    <div
        class="row location-item-row"
        [ngClass]="{
            'location-selected': selectedLocation?.id === location.id
        }"
    >
        <div class="col-md-1 col-sm-1 col-1 location-number-holder">
            <div class="location-number">
                <span class="label">{{ position ?? 1 }}</span>
            </div>
        </div>
        <div class="col-md-5 col-sm-5 col-5 location-info">
            <div class="title">
                <a>{{ location.name }}</a>
                <small class="partner-id">- {{ location.partner_id }}</small>
            </div>
            <div class="address">
                {{
                    (location.address?.house_number ? location.address?.house_number + ' ' : '') +
                        location.address?.address_line1 +
                        '' +
                        location.address?.address_line2 +
                        ','
                }}
            </div>
            <div class="address">
                {{ location.address.zip_code + ' ' + location.address.city + ', ' + location.address.country }}
            </div>
        </div>
        <div class="col-md-5 col-sm-5 col-5 location-publishers">
            <!-- TODO default class: publisher-rejected; apply class for:
                 Active: publisher-active
                 Not Connected: publisher-notconnected
                 Pending: publisher-pending -->
            <div class="post-publishers-list">
                <div class="post-publisher publisher-pagesjaunes publisher-{{ publisherIcons.pagesjaunes['state'] }}">
                    <a title="pagesjaunes">
                        <div class="publisher-decorator"></div>
                        <div class="publisher-logo"></div>
                    </a>
                </div>
                <div class="post-publisher publisher-facebook publisher-{{ publisherIcons.facebook['state'] }}">
                    <a title="facebook">
                        <div class="publisher-decorator"></div>
                        <div class="publisher-logo"></div>
                    </a>
                </div>
                <div class="post-publisher publisher-gmb publisher-{{ publisherIcons.gmb['state'] }}">
                    <a title="gmb">
                        <div class="publisher-decorator"></div>
                        <div class="publisher-logo"></div>
                    </a>
                </div>
            </div>
        </div>
        <div
            *ngIf="location.custom_data && location.custom_data.onboarding_type === 'freshdesk'"
            class="col-md-1 col-sm-1 col-1 location-freshdesk"
        >
            <div class="post-publisher publisher-freshdesk publisher-pending">
                <a title="freshdesk">
                    <div class="publisher-logo"></div>
                </a>
            </div>
        </div>
    </div>
</li>
