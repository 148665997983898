import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forEach } from 'lodash-es';
import { take } from 'rxjs/operators';

@Pipe({
    name: 'timeSlotFormater',
})
export class TimeSlotFormaterPipe implements PipeTransform {
    constructor(private translate: TranslateService) {}

    transform(value: any, args?: any): any {
        if (value[0] && (value[0].open_time === 'closed' || value[0].closed === true)) {
            let closed = 'closed';

            this.translate
                .get(closed)
                .pipe(take(1))
                .subscribe(c => (closed = c));

            return closed;
        } else if (
            value[0] &&
            (value[0].open_time === '24h/24' ||
                value[0].close_time === '24h/24' ||
                (value[0].open_time === '00:00' && value[0].close_time === '24:00'))
        ) {
            return '24h/24';
        } else {
            let formated = '';
            const slots = [];

            forEach(value, slot => {
                slots.push([slot.open_time, slot.close_time].join(' - '));
                return '' || value;
            });

            formated = slots.join(', ');

            return formated;
        }
    }
}
