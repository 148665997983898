import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';

import { LocationsActions, LocationsSelectors } from '@slm/location/state';
import { LocationDecorated } from '@solocal-manager/sirius/core/models';
import { GetOffersByEpjSuccess, OffersActionsTypes } from '@solocal-manager/sirius/data-access-offers/actions';
import { OfferByEpj } from '@solocal-manager/sirius/data-access-offers/models';
import { LocationsDTO, PaginationDecoratorDTO } from '@solocal-manager/sirius/support/base-models';
import { debounceTime, filter, map, tap } from 'rxjs/operators';

import { TagManagerService } from '../services/tag-manager.service';
import { AccountUtils } from '../utils/account.utils';
import * as AnalyticsUtils from '../utils/analytics.utils';

export const LOCATION_SELECTED_DELAY = 800;
/**
 * Effects used to add values in the `location` variable of the `data layer`
 */
@Injectable({ providedIn: 'root' })
export class LocationAnalyticsEffects {
    locationAnalytics$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(LocationsActions.setSelectedLocation),
                debounceTime(LOCATION_SELECTED_DELAY),
                concatLatestFrom(() => [
                    this.store.pipe(select(LocationsSelectors.getSelectedLocation)),
                    this.store.pipe(select(LocationsSelectors.getSelectedEpjsForMultiSelect)),
                ]),
                map(([, location, epjs]) => {
                    this.gtmService.dlVariables(
                        AnalyticsUtils.getUserAndLocationDataLayerVariables({ location, epjs }),
                    );
                    this.gtmService.dlVariables({
                        location: { type: AccountUtils.getLocationType(location) },
                    });
                }),
            ),
        { dispatch: false },
    );

    allEPJAnalytics$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(LocationsActions.loadLocationsSuccess),
                map(action => action.locations),
                filter((res: LocationsDTO) => !res?.next),
                tap((res: PaginationDecoratorDTO<LocationDecorated>) => {
                    const epjs = res?.data?.map(item => item?.epj).filter(epj => !!epj);
                    const totalEpj = epjs.join('|');

                    this.gtmService.dlVariables({
                        location: {
                            totalEpj,
                        },
                        user: {
                            epjs: totalEpj,
                        },
                    });
                }),
            ),
        { dispatch: false },
    );

    locationAnalyticsOffers$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<GetOffersByEpjSuccess>(OffersActionsTypes.GET_OFFERS_BY_EPJ_SUCCESS),
                map((action: GetOffersByEpjSuccess) => action.payload),
                tap((offers: OfferByEpj[]) => {
                    if (offers && offers.length > 0) {
                        const presenceOffers = AnalyticsUtils.extractPresenceOffers(offers);
                        const advertisingOffers = AnalyticsUtils.extractAdvertisingOffers(offers);
                        const websiteOffers = AnalyticsUtils.extractWebsiteOffers(offers);
                        const location = {
                            advertisingHeld: AnalyticsUtils.formatOfferList(advertisingOffers),
                            websiteHeld: AnalyticsUtils.formatOfferList(websiteOffers),
                            otherHeld: AnalyticsUtils.formatOfferList(presenceOffers),
                        };

                        this.gtmService.dlVariables({ location });
                    }
                }),
            ),
        { dispatch: false },
    );

    constructor(
        private actions$: Actions,
        private gtmService: TagManagerService,
        private store: Store,
    ) {}
}
