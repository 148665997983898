<div class="datepicker-container">
    <div class="input-group input-container datepicker-input-container">
        <input type="text"
               required
               [(ngModel)]="formattedDate"
               class="form-control datepicker-input" />
        <span class="input-group-btn datepicker-button-container">
            <button class="btn btn-main btn-datepicker remove-field-button"
                    type="button"
                    (click)="datepickerShow = !datepickerShow">
                <i class="glyphicon glyphicon-calendar"></i>
            </button>
        </span>
        <datepicker [(ngModel)]="dt"
                    (selectionDone)="onDateSelect($event)"
                    [showWeeks]="false"
                    [startingDay]="1"
                    *ngIf="datepickerShow"></datepicker>
    </div>
</div>
