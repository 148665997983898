import { Injectable } from '@angular/core';

import { IPageStatesKpi, IPublisherErrorsKpi } from '../models';
import { PublishersErrorKpiViewModel, TotalErrorKpiViewModel } from '../view-models';

@Injectable({ providedIn: 'root' })
export class KpiTotalErrorFactoryService {
    createTotalErrorKpi(data: IPageStatesKpi[]): TotalErrorKpiViewModel {
        return new TotalErrorKpiViewModel(data);
    }

    createPublishersStateKpi(data: IPublisherErrorsKpi[], state: string): PublishersErrorKpiViewModel {
        return new PublishersErrorKpiViewModel(data, state);
    }
}
