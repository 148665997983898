import { Component, Input, OnChanges } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { get } from 'lodash-es';

import { InputSingle } from '../../class/input-single';

@Component({
    selector: 'wpm-input-single-field',
    template: '',
})
export class InputSingleFieldComponent extends InputSingle implements OnChanges {
    @Input() config: any;
    @Input() validators: any[];
    @Input() asyncValidators: any[];

    renderAs: string;

    constructor(public formBuilder: UntypedFormBuilder) {
        super(formBuilder);
    }

    ngOnChanges(input) {
        const config = get(input, 'config.currentValue');
        const validators = get(input, 'validators.currentValue');
        const asyncValidators = get(input, 'asyncValidators.currentValue');
        if (config) {
            this._config = config;
            this._validators = validators;
            this._asyncValidators = asyncValidators;

            this.setValidators();

            this.renderAs = this.getRenderAs(config);
        }
    }

    getRenderAs(config): string {
        let renderAs = '';

        if (config) {
            const attributes = config.attributes;

            if (attributes) {
                if (config.max || attributes.maxlength) {
                    renderAs = 'maxLength';
                } else if (attributes.min || attributes.step) {
                    renderAs = 'numberSteps';
                }
            } else {
                if (config.forceType === 'number') {
                    renderAs = 'numberSteps';
                } else {
                    renderAs = 'default';
                }
            }
        }
        return renderAs;
    }
}
