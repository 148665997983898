import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'durationRdvFormat',
})
export class DurationRdvFormatPipe implements PipeTransform {
    transform(value: number) {
        if (!value) {
            return '';
        }
        if (value < 60) {
            return value + 'min';
        }
        const hours = value / 60;
        const min = value % 60;
        return hours.toFixed() + 'h' + (!min ? '' : min + 'min');
    }
}
