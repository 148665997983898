import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'textWithParameter',
})
export class TextWithParameterPipe implements PipeTransform {
    transform(value: any, parameter: any): any {
        const replacement = parameter;
        return value.replace(/##([^#]+)##/, replacement);
    }
}
