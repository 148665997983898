import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { SolocalButtonComponent, SolocalIconComponent, SolocalModalComponent } from '@shared/ui-components';
import { SafeHtmlPipe } from '@slm/shared/html';

import { StandardModalComponent } from './components/standard-modal/standard-modal.component';

@NgModule({
    imports: [
        CommonModule,
        MatDialogModule,
        SolocalButtonComponent,
        SolocalIconComponent,
        SolocalModalComponent,
        TranslateModule,
        SafeHtmlPipe,
    ],
    declarations: [StandardModalComponent],
    exports: [StandardModalComponent],
})
export class SiriusSharedDialogModule {}
