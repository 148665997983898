import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import * as UserActions from '@slm/user/state';

@Component({
    selector: 'slm-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
    menuActive = false;

    @Input()
    disableSearch = false;
    @Output() locationChanged: EventEmitter<boolean> = new EventEmitter(false);

    constructor(
        public router: Router,
        private readonly store: Store,
    ) {}

    onMenuClick(menuActive: boolean) {
        this.menuActive = menuActive;
    }

    gotoSearch() {
        this.router.navigate(['/']);
    }

    logout() {
        this.store.dispatch(UserActions.logout());
    }

    locationSelection(event: boolean) {
        this.locationChanged.emit(true);
    }
}
