import { Component } from '@angular/core';

import { WpmInputErrorMessagesComponent } from '@solocal-manager/sirius/core/form';

@Component({
    selector: 'app-input-error-messages',
    templateUrl: './input-error-messages.component.html',
    styleUrls: ['./input-error-messages.component.scss'],
})
export class InputErrorMessagesComponent extends WpmInputErrorMessagesComponent {}
