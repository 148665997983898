import { Component, Input, OnChanges } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

import { publisherSearchStates } from '../../config/publisher-search-states.config';

@Component({
    selector: 'slm-publisher-connect-status',
    templateUrl: './publisher-connect-status.component.html',
    styleUrls: ['./publisher-connect-status.component.scss'],
})
export class PublisherConnectStatusComponent implements OnChanges {
    searchState = publisherSearchStates;

    @Input() state: string;
    @Input() error: string;
    @Input() message: string;
    @Input() count: number;

    constructor(public logger: NGXLogger) {}

    ngOnChanges(input: unknown): void {
        this.logger.debug('$$$$$$$$$$$$$$$$$$$$$$$', input);
    }
}
