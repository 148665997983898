import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';

// components
import { WpmInputVideoComponent } from '@solocal-manager/sirius/core/form';

@Component({
    selector: 'app-input-video',
    templateUrl: './input-video.component.html',
    styleUrls: ['./input-video.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputVideoComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => InputVideoComponent),
            multi: true,
        },
    ],
})
export class InputVideoComponent extends WpmInputVideoComponent implements ControlValueAccessor {}
