import { get } from 'lodash-es';

import {
    HabilitatedLocationListActionsUnion,
    HabilitatedLocationListActionTypes,
    SearchHabilitatedLocationsActionsUnion,
} from '@solocal-manager/sirius/core/actions';
import { IHabilitatedLocation } from '@solocal-manager/sirius/support/base-models';
import { PaginationDecoratorDTO } from '@solocal-manager/sirius/support/base-models';

export type HabilitatedLocationListState = PaginationDecoratorDTO<IHabilitatedLocation>;
const initialState: PaginationDecoratorDTO<IHabilitatedLocation> = {
    count: 0,
    currentPage: 0,
    data: [],
    initial: true,
    next: null,
    next_page: null,
    previous: null,
    previous_page: null,
};

export function habilitatedLocationListReducer(
    state = initialState,
    action: HabilitatedLocationListActionsUnion | SearchHabilitatedLocationsActionsUnion,
): HabilitatedLocationListState {
    switch (action.type) {
        case HabilitatedLocationListActionTypes.GET_HAB_LOCATION_LIST_SUCCESS:
            const currentPage = get(action, 'payload.previous', 0) + 1;
            const decoratedPayload: HabilitatedLocationListState = {
                ...action.payload,
                initial: false,
                currentPage,
            };

            // Merge new results into current state if pagination was triggered
            return !action.payload.previous_page
                ? decoratedPayload
                : {
                      ...decoratedPayload,
                      data: [...state.data, ...action.payload.data],
                  };
        case HabilitatedLocationListActionTypes.RESET_HAB_LOCATION_LIST:
            return initialState;
        default:
            return state;
    }
}
