import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { EnvironmentService } from '@slm/shared/environment';
import { Location, PaginationDTO } from '@solocal-manager/sirius/support/base-models';
import { Observable } from 'rxjs';

import { ILocationListHttpOptions } from '../../models/locations-list-http-options.model';

@Injectable({
    providedIn: 'root',
})
export class LocationsListHttpService {
    constructor(
        private envService: EnvironmentService,
        private http: HttpClient,
    ) {}

    getAll(options: ILocationListHttpOptions): Observable<PaginationDTO<Location>> {
        const url = `${this.envService.apiUrl}/consumer/user/me/locations`;
        let params = new HttpParams();
        options = this.appendDefaultFields(options);
        options.states = ['active', 'in_moderation', 'empty', 'verification_required', 'inactive'];

        Object.keys(options).forEach(key => {
            const option = (options as any)[key];
            if (option) {
                params = params.set(key, this.serialize(option));
            }
        });
        return this.http.get<PaginationDTO<Location>>(url, { params });
    }

    private appendDefaultFields(options: ILocationListHttpOptions): ILocationListHttpOptions {
        const defaultFields = [
            'id',
            'alias',
            'name',
            'partner_id',
            'epj',
            'address',
            'tags',
            'state',
            'roles',
            'completion_rate',
            'custom_data',
            'feature_settings',
            'pages', // TODO: Could be removed after refactoring Social posting feature (Florent)
            'publisher_specific', // TODO: Could be removed after refactoring Social posting feature (Florent),
            'company_id',
        ];
        return {
            ...options,
            fields: [...new Set(defaultFields.concat(options?.fields || []))],
        };
    }

    private serialize(option: string | string[]): string {
        return Array.isArray(option) ? option.join(',') : option;
    }
}
