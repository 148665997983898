import { Action, createReducer, on } from '@ngrx/store';

import * as NavActions from '@solocal-manager/sirius/core/actions/nav-page.actions';
import { sourceNavConf } from '@solocal-manager/sirius/core/config';
import { headerNavItems, sideNavItems } from '@solocal-manager/sirius/core/config/nav-pages/menus.config';
import {
    ENavIDs,
    EPageIDs,
    NavItemMap,
    NavItemOrders,
    NavOrderModel,
    NavOrderRootModel,
    PageItem,
} from '@solocal-manager/sirius/core/models';
import { uniq } from 'lodash-es';

export interface AppNavPageState {
    activePage: EPageIDs | string;
    prevPage: { pageId: EPageIDs | string; path: string };
    activePageConf: PageItem | null;

    navItemMap: NavItemMap;
    navItemOrder: NavItemOrders;

    fetchApi: boolean;
    fetchDone: boolean;
    locationIDs: string[];
}

export const initialState: AppNavPageState = {
    activePage: null,
    prevPage: null,
    activePageConf: {
        id: null,
        targetNav: null,
        titleSection: {
            mainTitle: null,
            mainTitleCaps: false,
            subTitle: null,
            icon: null,
        },
        breadCrumbs: [],
        pageFilters: {
            order: [],
            filters: null,
            showFilters: true,
            showSecondaryFilters: false,
        },
    },

    navItemMap: sourceNavConf,

    navItemOrder: {
        header: headerNavItems,
        side: sideNavItems,
    },

    fetchApi: false,
    fetchDone: true,
    locationIDs: [],
};

function setNavItemHiddenState(navItemMap: NavItemMap, id: string | ENavIDs, hidden: boolean): NavItemMap {
    const currNavItem = navItemMap?.[id];
    if (currNavItem == null) {
        return navItemMap;
    }
    const currDisplayRules = currNavItem.navDisplayRules ?? {};
    const newDisplayRules = { ...currDisplayRules, hide: hidden };
    const newNavItem = { ...currNavItem, navDisplayRules: newDisplayRules };
    return { ...navItemMap, [id]: newNavItem };
}

function mergeChildren(groupItemOld: NavOrderRootModel, groupItemNew: NavOrderRootModel): NavOrderRootModel {
    const oldChildren = groupItemOld?.child;
    const newChildren = groupItemNew?.child;
    if (!newChildren || newChildren.length < 1) {
        return groupItemOld;
    }

    if (!oldChildren || oldChildren.length < 1) {
        return groupItemNew;
    }

    const mergedNewChildren = newChildren.reduce((acc, groupItem) => {
        const oldChild = oldChildren.find(child => child.id === groupItem.id);
        if (oldChild == null) {
            return [...acc, groupItem];
        }

        if (oldChild.child != null) {
            if (groupItem.child == null) {
                return [...acc];
            }

            const newSubMenuItems = patchSubMenu(oldChild.child, groupItem.child);
            return acc.map(exGroupItem =>
                exGroupItem.id === groupItem.id ? { ...groupItem, child: newSubMenuItems } : exGroupItem,
            );
        }

        return [...acc];
    }, oldChildren);

    // const filteredNewChildren = newChildren.filter(
    //     groupItem => !oldChildren.some(child => groupItem.id === child.id),
    // );
    return {
        id: groupItemOld.id,
        child: reorderNavItems(mergedNewChildren),
    };
}

// TODO remove after crm addons refactoring is done
function hackMyPublications(navItems: NavOrderRootModel[]): NavOrderRootModel[] {
    return navItems.map(groupItem => {
        if (groupItem.id === ENavIDs.MY_PUBLICATIONS) {
            return {
                id: ENavIDs.MY_PUBLICATIONS_GROUP,
                child: [
                    {
                        id: ENavIDs.MY_PUBLICATIONS,
                        child: groupItem.child.map(child => child.id),
                    },
                ],
            };
        }
        return groupItem;
    });
}

function mergeSideNav(state: AppNavPageState, addonNavItems: NavOrderRootModel[]): AppNavPageState {
    const { side: currentSideItems } = state.navItemOrder;
    const newItemsWithPublicationsHack = hackMyPublications(addonNavItems);

    const filteredItems = newItemsWithPublicationsHack?.filter(
        item => !currentSideItems.some(stateItem => stateItem.id === item.id),
    );
    const mergedItems = currentSideItems.map(stateItem => {
        const item = newItemsWithPublicationsHack.find(elem => elem.id === stateItem.id);
        if (item) {
            return mergeChildren(stateItem, item);
        }

        return stateItem;
    });

    return {
        ...state,
        navItemOrder: {
            ...state.navItemOrder,
            side: reorderNavItems(mergedItems.concat(filteredItems)),
        },
    };
}

function reorderNavItems<T extends { id: string; insertAfter?: string }>(unorderedItems: Array<T>): Array<T> {
    const orderedItems = unorderedItems.filter(item => !item.insertAfter);

    unorderedItems
        .filter(item => item.insertAfter)
        .forEach(item => {
            const target = orderedItems.findIndex(_item => _item.id === item.insertAfter) + 1;
            orderedItems.splice(target, 0, item);
        });

    return orderedItems;
}

function updateNav(state: AppNavPageState, which, data): AppNavPageState {
    return {
        ...state,
        navItemOrder: {
            ...state.navItemOrder,
            [which]: data,
        },
    };
}

function updateRoot(state: AppNavPageState, which: keyof AppNavPageState, data): AppNavPageState {
    return {
        ...state,
        [which]: data,
    };
}

function updateNavItem(state: AppNavPageState, data): AppNavPageState {
    return {
        ...state,
        navItemMap: {
            ...state.navItemMap,
            [data.nav]: data.data,
        },
    };
}

function patchSubMenu(existingChildren: string[], newChildren: string[]) {
    return newChildren.reduce((accChildren, newChild) => {
        if (existingChildren.includes(newChild)) {
            return accChildren;
        }

        return [...accChildren, newChild];
    }, existingChildren);
}

function patchSideNavGroup(state: AppNavPageState, groupId: ENavIDs, children: NavOrderModel[]): AppNavPageState {
    const navItems = state?.navItemOrder?.side;
    const navItemGroup = navItems.find(item => item.id === groupId);
    let newItemGroup = navItemGroup;
    if (navItemGroup == null) {
        newItemGroup = {
            id: groupId,
            child: children,
        };
    } else {
        const newChildren = navItemGroup.child
            ? children.reduce((accChilds, childItem) => {
                  const existingChild = navItemGroup.child.find(exChild => exChild.id === childItem.id);
                  if (existingChild == null) {
                      return [...accChilds, childItem];
                  }

                  if (existingChild.child != null) {
                      if (childItem.child == null) {
                          return [...accChilds];
                      }

                      const newSubChildren = patchSubMenu(existingChild.child, childItem.child);
                      return accChilds.map(item =>
                          item.id === existingChild.id ? { ...existingChild, child: newSubChildren } : item,
                      );
                  }
                  return [...accChilds];
              }, navItemGroup.child)
            : { ...children };

        newItemGroup = {
            id: groupId,
            child: newChildren,
        };
    }

    const newNavItems = navItems.map(navItem => {
        if (navItem.id === groupId) {
            return newItemGroup;
        }

        return navItem;
    });

    return {
        ...state,
        navItemOrder: {
            ...state.navItemOrder,
            side: newNavItems,
        },
    };
}

/**
 * Update items Map field attributes.
 */
function updateNavAttr(state, payload): AppNavPageState {
    const { page, data, field } = payload;
    const sPage = state.navItemMap[page];
    return {
        ...state,
        navItemMap: {
            ...state.navItemMap,
            [page]: {
                ...sPage,
                [field]: {
                    ...sPage[field],
                    ...data,
                },
            },
        },
    };
}

function updatePageAttr(state, payload): AppNavPageState {
    const { data, field } = payload;

    return {
        ...state,
        activePageConf: {
            ...state.activePageConf,
            [field]: data,
        },
    };
}

/**
 * note:BD => this might do issues with selected page conf
 */
function initActivePage(state: AppNavPageState, payload) {
    return {
        ...state,
        activePageConf: {
            ...state.activePageConf,
            ...payload.page,
            pageFilters: state?.activePageConf?.pageFilters ?? null,
        },
        activePage: payload.pageID,
    };
}

function setFilters(state: AppNavPageState, payload): AppNavPageState {
    const filterMap = {};
    const order = payload.data.map(element => {
        filterMap[element.id] = element;
        return element.id;
    });

    return {
        ...state,
        activePageConf: {
            ...state.activePageConf,
            pageFilters: {
                ...state.activePageConf.pageFilters,
                order: order,
                filters: filterMap,
            },
        },
    };
}

function resetFilters(state: AppNavPageState): AppNavPageState {
    return {
        ...state,
        activePageConf: {
            ...state.activePageConf,
            pageFilters: {
                order: [],
                filters: null,
                showFilters: true,
                showSecondaryFilters: false,
            },
        },
    };
}

function setFilterSelectedValues(state: AppNavPageState, data) {
    const currState = { ...state };
    const activePage = currState.activePageConf;
    const { filterID, values } = data;
    if (!activePage.pageFilters) {
        return state;
    }

    const currentFilter = activePage.pageFilters.filters[filterID];

    return {
        ...state,
        activePageConf: {
            ...activePage,
            pageFilters: {
                ...activePage.pageFilters,
                filters: {
                    ...activePage.pageFilters.filters,
                    [data.filterID]: {
                        ...currentFilter,
                        selected: values,
                    },
                },
            },
        },
    };
}

function addOrUpdateFilter(state: AppNavPageState, { filter }) {
    const currState = { ...state };
    const order = uniq([...currState.activePageConf.pageFilters.order, filter.id]);

    const filters = {
        ...currState.activePageConf.pageFilters.filters,
        [filter.id]: filter,
    };
    return <AppNavPageState>{
        ...state,
        activePageConf: {
            ...state.activePageConf,
            pageFilters: {
                ...state.activePageConf.pageFilters,
                order: order,
                filters: filters,
            },
        },
    };
}

function setFilterView(state: AppNavPageState, data) {
    return <AppNavPageState>{
        ...state,
        activePageConf: {
            ...state.activePageConf,
            pageFilters: {
                ...state.activePageConf.pageFilters,
                showFilters: data.show,
            },
        },
    };
}

function setSecFilterView(state: AppNavPageState, data) {
    return <AppNavPageState>{
        ...state,
        activePageConf: {
            ...state.activePageConf,
            pageFilters: {
                ...state.activePageConf.pageFilters,
                showSecondaryFilters: data.show,
            },
        },
    };
}

const reducer = createReducer(
    initialState,
    on(NavActions.setHeaderNav, (state, { payload }) => updateNav(state, 'header', payload)),

    on(NavActions.setSideNav, (state, { payload }) => updateNav(state, 'side', payload)),

    on(NavActions.setNavItem, (state, { payload }) => updateNavItem(state, payload)),

    on(NavActions.setNavItemAttr, (state, { payload }) => updateNavAttr(state, payload)),

    on(NavActions.setActivePage, (state, { payload }) => updateRoot(state, 'activePage', payload.pageID)),
    on(NavActions.setPrevPage, (state, { payload }) => updateRoot(state, 'prevPage', payload)),

    on(NavActions.setActivePageConfig, (state, { payload }) => updateRoot(state, 'activePageConf', payload.page)),
    on(NavActions.initActivePage, (state, { payload }) => initActivePage(state, payload)),
    on(NavActions.setPageItemAttr, (state, { payload }) => updatePageAttr(state, payload)),

    on(NavActions.patchSideNavGroup, (state, payload) => patchSideNavGroup(state, payload.groupId, payload.children)),
    on(NavActions.mergeSidNav, (state, { items }) => mergeSideNav(state, items)),
    on(NavActions.setNavItemHidden, (state, { id, hidden }) => ({
        ...state,
        navItemMap: setNavItemHiddenState(state.navItemMap, id, hidden),
    })),

    // filters
    on(NavActions.setFilters, (state, { payload }) => setFilters(state, payload)),
    on(NavActions.resetFilters, state => resetFilters(state)),

    on(NavActions.updateFilter, (state, { payload }) => setFilterSelectedValues(state, payload)),
    on(NavActions.setFilterView, (state, { payload }) => setFilterView(state, payload)),
    on(NavActions.setSecFiltView, (state, { payload }) => setSecFilterView(state, payload)),
    on(NavActions.addFilter, (state, { payload }) => addOrUpdateFilter(state, payload)),
);

export function getNavPageReducer(state: AppNavPageState | undefined, action: Action) {
    return reducer(state, action);
}
