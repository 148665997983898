import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export abstract class AppConfig {
    abstract version: string;
    abstract prod?: any;
    abstract default?: any;
    abstract env?: any;
    abstract buildNumber?: string;
    abstract buildDate?: string;
}
