<slm-publisher-connect-status
    *ngIf="!shouldShowAdditionalSearch"
    [state]="state"
    [message]="connectionMessage"
    [count]="publisherSearchResults?.length"
></slm-publisher-connect-status>

<table class="table" *ngIf="publisherSearchResults?.length">
    <thead>
        <th class="text-center" scope="col">{{ 'Name' | translate }}</th>
        <th class="text-center" scope="col">{{ 'Address' | translate }}</th>
        <th class="text-center" scope="col">{{ 'Status' | translate }}</th>
        <th class="text-center" scope="col">{{ 'Action' | translate }}</th>
    </thead>
    <tbody>
        <tr *ngFor="let searchResult of publisherSearchResults">
            <td class="text-center">
                <ng-container *ngIf="searchResult?.publisher_page_url; then hasPageUrl; else noPageUrl"></ng-container>

                <ng-template #hasPageUrl>
                    <a href="{{ searchResult.publisher_page_url }}" target="_blank">{{ searchResult.name }}</a>
                </ng-template>

                <ng-template #noPageUrl>
                    <span>{{ searchResult.name }}</span>
                </ng-template>
            </td>
            <td class="text-center">
                {{ searchResult.address?.address_line1 }}, {{ searchResult.address?.zip_code }},
                {{ searchResult.address?.city }},
                {{ searchResult.address?.country }}
            </td>
            <td class="text-center">{{ searchResult.publisher_page_state }}</td>
            <td class="col-md-3 text-center">
                <button class="btn btn-primary publisher-claim-page-btn" (click)="connect(searchResult)">
                    {{ 'Claim this page' | translate }}
                </button>
            </td>
        </tr>
    </tbody>
</table>

<div class="row col-md-12 text-center" *ngIf="!shouldShowAdditionalSearch && !shouldShowUrlInput">
    <button class="btn btn-primary" (click)="showUrlInput()">
        {{ 'None of these' | translate }}
    </button>
</div>

<div *ngIf="shouldShowUrlInput || shouldShowAdditionalSearch">
    <div class="row col-md-12 text-center">
        <p>
            {{ 'Search by TripAdvisor page URL in the input below.' | translate }}
        </p>
    </div>
    <app-input-search-publisher
        [location]="location"
        [publisherId]="publisherId"
        (onClaimed)="onPageClaimed($event)"
    ></app-input-search-publisher>
</div>
