import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { IUserAndLocationIds } from '@solocal-manager/sirius/core/models';

// Models
import { IPreferenceSettingByEpj } from '../models';

// Personal contacts actions

export enum ApiPersonalPreferencesActionTypes {
    LOAD_PERSONAL_PREFERENCE_SETTING_BY_EPJ = '[SETTINGS] get settings by epj',
    LOAD_PERSONAL_PREFERENCE_SETTING_BY_EPJ_SUCCESS = '[SETTINGS] get settings by epj succeeded',
    LOAD_PERSONAL_PREFERENCE_SETTING_BY_EPJ_FAIL = '[SETTINGS] get settings by epj failed',
    UPDATE_PERSONAL_PREFERENCE_SETTING_BY_EPJ = '[SETTINGS] update settings by epj',
    POST_PERSONAL_PREFERENCE_SETTING_BY_EPJ = '[SETTINGS] post settings by epj',
}

export const LoadPersonalPreferenceSetting = createAction(
    ApiPersonalPreferencesActionTypes.LOAD_PERSONAL_PREFERENCE_SETTING_BY_EPJ,
    props<{ gd: IUserAndLocationIds }>(),
);

export const LoadPersonalPreferenceSettingByEpjSuccess = createAction(
    ApiPersonalPreferencesActionTypes.LOAD_PERSONAL_PREFERENCE_SETTING_BY_EPJ_SUCCESS,
    props<{ payload: IPreferenceSettingByEpj[] }>(),
);

export const LoadPersonalPreferenceSettingByEpjFail = createAction(
    ApiPersonalPreferencesActionTypes.LOAD_PERSONAL_PREFERENCE_SETTING_BY_EPJ_FAIL,
    props<{ error: HttpErrorResponse }>(),
);

export const UpdatePersonalPreferenceSetting = createAction(
    ApiPersonalPreferencesActionTypes.UPDATE_PERSONAL_PREFERENCE_SETTING_BY_EPJ,
    props<{
        payload: IPreferenceSettingByEpj;
        gls: IUserAndLocationIds;
    }>(),
);

export const PostPersonalPreferenceSetting = createAction(
    ApiPersonalPreferencesActionTypes.POST_PERSONAL_PREFERENCE_SETTING_BY_EPJ,
    props<{
        payload: IPreferenceSettingByEpj;
        gls: IUserAndLocationIds;
    }>(),
);
