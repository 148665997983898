import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { GetPublisherErrors } from '../actions';

@Injectable({ providedIn: 'root' })
export class KpiPublishersErrorGuard  {
    constructor(private store: Store<any>) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.handleUrl(next);
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): boolean | Observable<boolean> | Promise<boolean> {
        return this.handleUrl(childRoute.parent);
    }

    handleUrl(next: ActivatedRouteSnapshot): boolean {
        if (next && next.url && next.params && next.params.publisherId && next.params.state) {
            this.store.dispatch(
                new GetPublisherErrors({
                    publisherId: next.params.publisherId,
                    states: [next.params.state],
                }),
            );
            return true;
        }
        return false;
    }
}
