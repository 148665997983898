import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';

import { ActionWithPayload } from '@solocal-manager/sirius/core/models';

export type RoutePath = string;

@Injectable({ providedIn: 'root' })
export class RoutePathActions {
    static SET_ROUTE_PATH = '[ROUTE-PATH] set route path';

    setRoutePath(routePath: RoutePath): ActionWithPayload<RoutePath> {
        return {
            type: RoutePathActions.SET_ROUTE_PATH,
            payload: routePath,
        };
    }
}
