import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { MenuCategoryHttpService } from '@slm/location/api';
import { ApiErrorActions } from '@slm/shared/error';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { getMenuCardsCategories, getMenuCardsCategoriesSuccess } from './menu-category.actions';

@Injectable({ providedIn: 'root' })
export class MenuCategoryEffects {
    constructor(
        private readonly update$: Actions,
        private readonly menuCategoryService: MenuCategoryHttpService,
    ) {}

    getCategories$ = createEffect(() =>
        this.update$.pipe(
            ofType(getMenuCardsCategories),
            switchMap(() =>
                this.menuCategoryService.getMenuCardsCategories().pipe(
                    map(result => getMenuCardsCategoriesSuccess({ result })),
                    catchError(error => of(new ApiErrorActions.CatchApiError(error))),
                ),
            ),
        ),
    );
}
