import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { combineLatest as observableCombineLatest, Observable, of } from 'rxjs';
import { catchError, switchMap, take, tap } from 'rxjs/operators';

import { GetPageStates, GetProvisioningIssues, GetTotalParcUsage, GetTotalSales } from '../actions';
import { getKpiPageStates, getKpiProvisioningIssues, getKpiTotalParcUsage, getKpiTotalSales } from '../reducers';

@Injectable({ providedIn: 'root' })
export class KpiGuard  {
    constructor(private store: Store<any>) {}

    getKpisFromStoreOrApi() {
        return observableCombineLatest([
            this.store.pipe(select(getKpiPageStates)),
            this.store.pipe(select(getKpiTotalSales)),
            this.store.pipe(select(getKpiProvisioningIssues)),
            this.store.pipe(select(getKpiTotalParcUsage)),
        ]).pipe(
            tap(combined => {
                if (!combined[0]) {
                    this.store.dispatch(new GetPageStates());
                }
                if (!combined[1]) {
                    this.store.dispatch(new GetTotalSales());
                }
                if (!combined[2]) {
                    this.store.dispatch(new GetProvisioningIssues());
                }
                if (!combined[3]) {
                    this.store.dispatch(new GetTotalParcUsage());
                }
            }),
            take(1),
        );
    }

    handleUrl(next: ActivatedRouteSnapshot) {
        if (next && next.url) {
            return this.getKpisFromStoreOrApi().pipe(
                switchMap(() => of(true)),
                catchError(() => of(false)),
            );
        }

        return of(true);
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.handleUrl(next);
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): boolean | Observable<boolean> | Promise<boolean> {
        return this.handleUrl(childRoute);
    }
}
