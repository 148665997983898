import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';

import { EnvironmentService } from '@slm/shared/environment';
import { InfoContainer, VideoYoutubeInfo } from '@solocal-manager/sirius/core/models';
import { VideoInfo } from '@solocal-manager/sirius/support/base-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class YoutubeService implements OnDestroy {
    youtubeInfoSubscribtion;
    saveVideoSubscribtion;
    videoUrlPattern = new RegExp('^(?:https?://(?:www.youtube.com/watch[?]v=|youtu.be/))([A-Za-z0-9_-]{11})');
    cachedQueries: Map<string, VideoInfo> = new Map();
    videoIsAvailable: boolean;

    constructor(
        public http: HttpClient,
        public envService: EnvironmentService,
    ) {}

    getVideoInfo(
        videoId: string,
        companyId: string,
    ): Observable<VideoInfo | { video: VideoInfo; videoIsAvailable: boolean }> {
        return new Observable(observer => {
            // const videoInfo = this.cachedQueries.get(videoId);
            // if (!videoInfo) {
            this.youtubeInfoSubscribtion = this.fetchVideoInfo(videoId).subscribe(
                (youtubeInfo: InfoContainer<VideoYoutubeInfo>) => {
                    if ((youtubeInfo.items ?? []).length > 0) {
                        this.videoIsAvailable = youtubeInfo.video_available;
                        this.saveVideoSubscribtion = this.saveVideoInfo(
                            {
                                // description: youtubeInfo.items[0].snippet.description || "",
                                description: '',
                                title: youtubeInfo.items[0].snippet.title,
                                length: null,
                                video_url: this.getVideoUrl(videoId),
                            },
                            companyId,
                        ).subscribe((savedInfo: VideoInfo) => {
                            this.cachedQueries.set(videoId, savedInfo);
                            observer.next({
                                video: savedInfo,
                                videoIsAvailable: this.videoIsAvailable,
                            });
                            observer.complete();
                        });
                    } else {
                        this.cachedQueries.set(videoId, null);
                        observer.next(null);
                        observer.complete();
                    }
                },
            );
            // } else {
            //     observer.next(videoInfo);
            //     observer.complete();
            // }
        });
    }

    getVideoUrlEmbed(videoIdOrUrl: string, controls?: string): string {
        controls = controls || 'showinfo=0';
        let videoId = videoIdOrUrl;
        if (videoIdOrUrl && videoIdOrUrl.match(this.videoUrlPattern)) {
            videoId = this.getVideoId(videoIdOrUrl);
            return `https://www.youtube.com/embed/${videoId}?rel=0&amp;${controls}`;
        }
    }

    /**
     * imgNumber from 0-3
     */
    getVideoCoverImage(videoIdOrUrl: string, imgNumber?: number): string {
        imgNumber = imgNumber || 0;
        let videoId = videoIdOrUrl;
        if (videoIdOrUrl && videoIdOrUrl.match(this.videoUrlPattern)) {
            videoId = this.getVideoId(videoIdOrUrl);
            return `https://img.youtube.com/vi/${videoId}/${imgNumber}.jpg`;
        }
    }

    getVideoUrl(videoIdOrUrl: string): string {
        return videoIdOrUrl.match(this.videoUrlPattern)
            ? videoIdOrUrl
            : `https://www.youtube.com/watch?v=${videoIdOrUrl}`;
    }

    getVideoId(videoIdOrUrl: string): string {
        let videoId = '';
        if (videoIdOrUrl) {
            videoId = videoIdOrUrl.replace(this.videoUrlPattern, '$1');
        }
        return videoId;
    }

    //for booster contact customer
    getBoosterVideoId(boosterVideo: string): string {
        let boosterVideoId = '';
        if (boosterVideo) {
            boosterVideoId = boosterVideo.replace(this.videoUrlPattern, '$1');
        }
        return boosterVideoId;
    }

    getBoosterVideo(boosterVideo: string): string {
        let boosterVideoId = boosterVideo;
        if (boosterVideo && boosterVideo.match(this.videoUrlPattern)) {
            boosterVideoId = this.getBoosterVideoId(boosterVideo);
            return `https://www.youtube.com/embed/${boosterVideoId}`;
        }
    }

    ngOnDestroy(): void {
        if (this.youtubeInfoSubscribtion) {
            this.youtubeInfoSubscribtion.unsubscribe();
        }

        if (this.saveVideoSubscribtion) {
            this.saveVideoSubscribtion.unsubscribe();
        }
    }

    private fetchVideoInfo(videoId: string): Observable<InfoContainer<VideoYoutubeInfo>> {
        const requestUrl = `${this.envService.apiUrl}/external/youtube/${videoId}/`;
        return this.http.get<InfoContainer<VideoYoutubeInfo>>(requestUrl);
    }

    private saveVideoInfo(videoInfo: VideoInfo, companyId: string): Observable<VideoInfo> {
        const requestUrl = `${this.envService.apiUrl}/consumer/companies/${companyId}/videos`;
        return this.http.post(requestUrl, videoInfo).pipe(map((res: any) => res.data));
    }
}
