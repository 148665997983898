import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'frenchDecimal',
})
export class FrenchDecimalPipe implements PipeTransform {
    transform(val: number): string {
        if (val !== undefined && val !== null) {
            // return val.toLocaleString('fr-FR');
            return ('' + val).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        } else {
            return '';
        }
    }
}
