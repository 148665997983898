<!-- STATS -->
<div class="admin-tools-stats-container">
    <div *ngIf="stats" class="row admin-tools-stats">
        <div class="main-stats">
            <h2>
                {{ locationCount }}
                {{ 'Location(s) manually connected:' | translate }}
            </h2>
        </div>

        <div *ngIf="stats['pagesjaunes']" class="admin-tools-publisher-stats">
            <img src="assets/images/icon_pagesjaunes.svg" alt="pagesjaunes icon" />
            <span class="admin-tools-publisher-stats-title">{{ 'PagesJaunes' | translate }}</span>
            <div class="admin-tools-publisher-container">
                <span class="admin-tools-publisher-stats-nr"
                    >{{ stats['pagesjaunes'].page_count }} / {{ locationCount }}</span
                >
                <span class="admin-tools-publisher-stats-percentage">{{ stats['pagesjaunes'].connection_pct }} %</span>
            </div>
        </div>
        <div *ngIf="stats['gmb']" class="admin-tools-publisher-stats">
            <img src="assets/images/icon_gmb.svg" alt="gmb icon" />
            <span class="admin-tools-publisher-stats-title">{{ 'Google My Business' | translate }}</span>
            <div class="admin-tools-publisher-container">
                <span class="admin-tools-publisher-stats-nr">{{ stats['gmb'].page_count }} / {{ locationCount }}</span>
                <span class="admin-tools-publisher-stats-percentage">{{ stats['gmb'].connection_pct }} %</span>
            </div>
        </div>
        <div *ngIf="stats['facebook']" class="admin-tools-publisher-stats">
            <img src="assets/images/icon_facebook.svg" alt="facebook icon" />
            <span class="admin-tools-publisher-stats-title">{{ 'Facebook' | translate }}</span>
            <div class="admin-tools-publisher-container">
                <span class="admin-tools-publisher-stats-nr"
                    >{{ stats['facebook'].page_count }} / {{ locationCount }}</span
                >
                <span class="admin-tools-publisher-stats-percentage">{{ stats['facebook'].connection_pct }} %</span>
            </div>
        </div>
        <div *ngIf="stats['foursquare']" class="admin-tools-publisher-stats">
            <img src="assets/images/icon_foursquare.svg" alt="four square icon" />
            <span class="admin-tools-publisher-stats-title">{{ 'Foursquare' | translate }}</span>
            <div class="admin-tools-publisher-container">
                <span class="admin-tools-publisher-stats-nr"
                    >{{ stats['foursquare'].page_count }} / {{ locationCount }}</span
                >
                <span class="admin-tools-publisher-stats-percentage">{{ stats['foursquare'].connection_pct }} %</span>
            </div>
        </div>
        <div *ngIf="stats['navads']" class="admin-tools-publisher-stats">
            <img src="assets/images/icon_navads.svg" alt="nav ads icon" />
            <span class="admin-tools-publisher-stats-title">{{ 'Navads' | translate }}</span>
            <div class="admin-tools-publisher-container">
                <span class="admin-tools-publisher-stats-nr"
                    >{{ stats['navads'].page_count }} / {{ locationCount }}</span
                >
                <span class="admin-tools-publisher-stats-percentage">{{ stats['navads'].connection_pct }} %</span>
            </div>
        </div>
        <div *ngIf="stats['seety']" class="admin-tools-publisher-stats">
            <img src="assets/images/icon_siteweb.svg" alt="site web icon" />
            <span class="admin-tools-publisher-stats-title">{{ 'Site Web' | translate }}</span>
            <div class="admin-tools-publisher-container">
                <span class="admin-tools-publisher-stats-nr"
                    >{{ stats['seety'].page_count }} / {{ locationCount }}</span
                >
                <span class="admin-tools-publisher-stats-percentage">{{ stats['seety'].connection_pct }} %</span>
            </div>
        </div>
        <div *ngIf="stats['site_privilege']" class="admin-tools-publisher-stats">
            <img src="assets/images/icon_site_privilege.svg" alt="ste privilege icon" />
            <span class="admin-tools-publisher-stats-title">{{ 'Site Privilège' | translate }}</span>
            <div class="admin-tools-publisher-container">
                <span class="admin-tools-publisher-stats-nr"
                    >{{ stats['site_privilege'].page_count }} / {{ locationCount }}</span
                >
                <span class="admin-tools-publisher-stats-percentage"
                    >{{ stats['site_privilege'].connection_pct }} %</span
                >
            </div>
        </div>
    </div>
</div>
