// SHOULD REFACTOR IT
import { createSelector, select } from '@ngrx/store';
import { LocationsSelectors } from '@slm/location/state';
import { pipe } from 'rxjs';
import { filter, shareReplay } from 'rxjs/operators';

export const selectLocationList = LocationsSelectors.getLocationsAndState;

export const selectLocationEntities = createSelector(selectLocationList, locationList => locationList.locations);

export const isMultiLocationAccount = createSelector(selectLocationEntities, locations => locations?.length > 1);

export const selectLocationsFiltered = pipe(
    select(selectLocationEntities),
    filter(locations => locations?.length > 0 ?? false),
    shareReplay(1),
);
