import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { size, keys } from 'lodash-es';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';

import { PublisherStatisticsService } from '@solocal-manager/sirius/core/core';
import { PublisherStatisticsActions } from '@solocal-manager/sirius/core/core';
import { AutoUnsubscribe } from '@solocal-manager/sirius/core/core';
import { TotalPublisherStatistics } from '@solocal-manager/sirius/core/models';

import { AdminUiService } from '../../services';

@AutoUnsubscribe()
@Component({
    selector: 'app-admin-tools-statistics-page',
    templateUrl: './admin-tools-statistics-page.component.html',
    styleUrls: ['./admin-tools-statistics-page.component.scss'],
})
export class AdminToolsStatisticsPageComponent implements OnInit, OnDestroy {
    publisherStatistics$: Observable<TotalPublisherStatistics>;
    publisherStatisticsSubscribtion: Subscription;
    locationCount: number;
    publishers: string[] = ['pagesjaunes', 'facebook', 'gmb', 'seety', 'site_privilege', 'navads', 'foursquare'];
    stats: any = {};

    constructor(
        public publisherStatisticsService: PublisherStatisticsService,
        public store: Store<any>,
        public logger: NGXLogger,
        public uiService: AdminUiService,
    ) {}

    ngOnInit(): void {
        this.uiService.companyPage(true);
        this.publisherStatistics$ = this.store.pipe(select('publisherStatistics'));

        this.store.dispatch({
            type: PublisherStatisticsActions.GET_PUBLISHER_STATISTICS,
        });

        this.publisherStatisticsSubscribtion = this.publisherStatistics$.subscribe(
            (publisherStatistics: TotalPublisherStatistics) => {
                if (PublisherStatisticsActions.GET_PUBLISHER_STATISTICS_SUCCESS && size(keys(publisherStatistics))) {
                    this.locationCount = publisherStatistics.location_count;

                    this.publishers.forEach(publisher => {
                        const existingPublisherStat = publisherStatistics.statistics.find(
                            publisherStat => publisherStat.publisher_id === publisher,
                        );
                        this.stats[publisher] = existingPublisherStat
                            ? existingPublisherStat
                            : {
                                  connection_pct: 0,
                                  page_count: 0,
                                  publisher_id: publisher,
                              };
                    });
                }
            },
        );
    }

    ngOnDestroy(): void {
        this.publisherStatisticsSubscribtion.unsubscribe();
        this.uiService.companyPage(false);
    }
}
