import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { Observable, fromEvent as observableFromEvent, of } from 'rxjs';
import { exhaustMap, filter, map, pairwise, startWith } from 'rxjs/operators';

interface ScrollPosition {
    sH: number;
    sT: number;
    cH: number;
}

const DEFAULT_SCROLL_POSITION: ScrollPosition = {
    sH: 0,
    sT: 0,
    cH: 0,
};

@Directive({
    selector: '[slmInfiniteScroller]',
})
export class InfiniteScrollerDirective implements AfterViewInit {
    @Input() scrollCallback: () => Observable<any>;
    @Input() immediateCallback = false;
    @Input() scrollPercent = 0;

    private scrollEvent$;
    private userScrolledDown$;
    private requestOnScroll$;
    private busy = false;

    constructor(private elm: ElementRef) {}

    ngAfterViewInit(): void {
        this.registerScrollEvent();
        this.streamScrollEvents();
        this.requestCallbackOnScroll();
    }

    private registerScrollEvent(): void {
        this.scrollEvent$ = observableFromEvent(this.elm.nativeElement, 'scroll', { passive: true });
    }

    private streamScrollEvents(): void {
        this.userScrolledDown$ = this.scrollEvent$.pipe(
            map(
                (e: any): ScrollPosition => ({
                    sH: e.target.scrollHeight,
                    sT: e.target.scrollTop,
                    cH: e.target.clientHeight,
                }),
            ),
            pairwise(),
            filter(positions => this.isUserScrollingDown(positions) && this.isScrollExpectedPercent(positions[1])),
        );
    }

    private requestCallbackOnScroll(): void {
        this.requestOnScroll$ = this.userScrolledDown$;

        if (this.immediateCallback) {
            this.requestOnScroll$ = this.requestOnScroll$.pipe(
                startWith([DEFAULT_SCROLL_POSITION, DEFAULT_SCROLL_POSITION]),
            );
        }

        this.requestOnScroll$
            .pipe(
                exhaustMap(() => {
                    if (this.busy === false) {
                        this.busy = true;
                        return this.scrollCallback();
                    }

                    return of(void 0);
                }),
            )
            .subscribe(() => {
                window.setTimeout(() => {
                    this.busy = false;
                }, 1000);
            });
    }

    private isUserScrollingDown(positions): boolean {
        return positions[0].sT < positions[1].sT;
    }

    private isScrollExpectedPercent(position): boolean {
        return (position.sT + position.cH) / position.sH >= this.scrollPercent / 100;
    }
}
