export const connectablePublishers = {
    gmb: 'postcard',
    facebook: 'list',
    pagesjaunes: 'list',
    seety: 'list',
    site_privilege: 'list',
    tripadvisor: 'list',
    soms: 'list',
    pharmanity: 'list',
    petitfute: 'click',
    waze_navads: 'click',
    foursquare: 'click',
    navmii: 'click',
    tomtom: 'click',
    '118000': 'click',
    uber_navads: 'click',
    annuaire_navads: 'click',
    factual_navads: 'click',
    pages24_navads: 'click',
    justacote_navads: 'click',
    trouverouvert_navads: 'click',
    horaire_navads: 'click',
    horairesdouverture24_navads: 'click',
    apple_navads: 'click',
    bing_navads: 'click',
    here_navads: 'click',
};

export const websitePublishers = {
    seety: true,
    site_privilege: true,
    soms: true,
    pharmanity: true,
};
