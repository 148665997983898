import { Component } from '@angular/core';

import { WpmInputImageUploadComponent } from '@solocal-manager/sirius/core/core';

@Component({
    selector: 'slm-input-image-upload',
    templateUrl: './input-image-upload.component.html',
    styleUrls: ['./input-image-upload.component.scss'],
})
export class InputImageUploadComponent extends WpmInputImageUploadComponent {}
