import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export abstract class PjSpecificConfig {
    abstract value: Array<{
        key: string;
        label: string;
        helpText?: string;
        max: number;
        type: string;
        required: boolean;
        object_type: string;
        categories?: string[];
    }>;
}
