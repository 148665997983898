import { Pages } from '@solocal-manager/sirius/support/base-models';

import { CompletionConfigModel } from '../models/completion-config.model';

export const completionConfig: CompletionConfigModel[] = [
    { key: 'name', max: 2, icon: 'icon_pencil.svg', name: 'location-visibility-actions:business' },
    {
        path: 'category',
        key: 'categories',
        max: 10,
        icon: 'build-icon-activity-add_line',
        name: 'location-visibility-actions:activity',
    },
    {
        key: 'address',
        max: 2,
        icon: 'build-icon-address-add-line',
        name: 'location-visibility-actions:address',
    },
    { key: 'phone', max: 10, icon: 'icon_pencil.svg', name: 'location-visibility-actions:phone' },
    {
        key: 'reviews',
        max: 6,
        icon: 'build-icon-mail-star-line',
        name: 'location-visibility-actions:review',
    },
    {
        key: 'post',
        max: 5,
        icon: 'build-icon-draft-line',
        name: 'location-visibility-actions:post',
    },
    {
        key: 'feature',
        max: 5,
        icon: 'build-icon-toggle-line',
        name: 'location-visibility-actions:feature',
    },
    {
        key: 'logo',
        max: 10,
        icon: 'build-icon-image-add-line',
        name: 'location-visibility-actions:logo',
    },
    {
        path: 'fb-cover',
        key: 'fb_cover',
        max: 5,
        icon: 'build-icon-cover-image-facebook-add-line',
        name: 'location-visibility-actions:coverfb',
    },
    {
        key: 'gallery',
        max: 10,
        icon: 'build-icon-image-add-line',
        name: 'location-visibility-actions:photos',
    },
    {
        key: 'description',
        max: 10,
        icon: 'build-icon-description-add-line',
        name: 'location-visibility-actions:presentation',
    },
    {
        path: 'email',
        key: 'emails',
        max: 5,
        icon: 'build-icon-email-add-line',
        name: 'location-visibility-actions:email',
    },
    { key: 'website', max: 5, icon: 'build-icon-url-site-add-line', name: 'location-visibility-actions:website' },
    {
        path: 'payment-methods',
        key: 'payment_methods',
        max: 5,
        icon: 'build-icon-payment-add-line',
        name: 'location-visibility-actions:payment',
    },
    {
        path: 'opening-hours',
        key: 'opening_hours',
        max: 10,
        icon: 'build-icon-time-line',
        name: 'location-visibility-actions:opening',
    },
];

export const completionConfigDashboard: CompletionConfigModel[] = [
    {
        key: 'name',
        max: 2,
        icon: 'build-icon-name-add-line',
        name: 'location-visibility-actions:business',
        page: Pages.LOCATION_INFO_NAME,
    },
    {
        key: 'categories',
        max: 10,
        icon: 'build-icon-activity-add_line',
        name: 'location-visibility-actions:activity',
        page: Pages.LOCATION_INFO_CATEGORIES,
    },
    {
        key: 'address',
        max: 2,
        icon: 'build-icon-address-add-line',
        name: 'location-visibility-actions:address',
        page: Pages.LOCATION_INFO_ADDRESS,
    },
    {
        key: 'phone',
        max: 10,
        icon: 'build-icon-phone-add-line',
        name: 'location-visibility-actions:phone',
        page: Pages.LOCATION_INFO_PHONES,
    },
    {
        key: 'reviews',
        max: 6,
        icon: 'build-icon-mail-star-line',
        name: 'location-visibility-actions:review',
        page: Pages.REVIEWS_REQUESTS,
    },
    {
        key: 'post',
        max: 5,
        icon: 'build-icon-draft-line',
        name: 'location-visibility-actions:post',
        page: Pages.POSTS_PUBLICATIONS,
    },
    /**
     * Deactivated for now, page does not exist on mobile
     */
    // {
    //     key: 'feature',
    //     max: 5,
    //     icon: 'build-icon-toggle-line',
    //     name: 'location-visibility-actions:feature',
    // },
    {
        key: 'logo',
        max: 10,
        icon: 'build-icon-image-add-line',
        name: 'location-visibility-actions:logo',
        page: Pages.LOCATION_INFO_PHOTO,
    },
    {
        key: 'fb_cover',
        max: 5,
        icon: 'build-icon-cover-image-facebook-add-line',
        name: 'location-visibility-actions:coverfb',
        page: Pages.LOCATION_INFO_PHOTO,
    },
    {
        key: 'gallery',
        max: 10,
        icon: 'build-icon-image-add-line',
        name: 'location-visibility-actions:photos',
        page: Pages.LOCATION_INFO_PHOTO,
    },
    {
        key: 'description',
        max: 10,
        icon: 'build-icon-description-add-line',
        name: 'location-visibility-actions:presentation',
        page: Pages.LOCATION_INFO_DESCRIPTION,
    },
    {
        key: 'emails',
        max: 5,
        icon: 'build-icon-email-add-line',
        name: 'location-visibility-actions:email',
        page: Pages.LOCATION_INFO_EMAILS,
    },
    {
        key: 'website',
        max: 5,
        icon: 'build-icon-url-site-add-line',
        name: 'location-visibility-actions:website',
        page: Pages.LOCATION_INFO_WEBSITE,
    },
    {
        key: 'payment_methods',
        max: 5,
        icon: 'build-icon-payment-add-line',
        name: 'location-visibility-actions:payment',
        page: Pages.LOCATION_INFO_PAYMENT_METHODS,
    },
    {
        key: 'opening_hours',
        max: 10,
        icon: 'build-icon-time-line',
        name: 'location-visibility-actions:opening',
        page: Pages.LOCATION_INFO_OPENING_HOURS,
    },
];
export const locationInfoAction: CompletionConfigModel = {
    key: 'info',
    max: 10,
    icon: 'build-icon-edit-infos-line',
    name: 'location-visibility-actions:info',
    page: Pages.LOCATION_INFO,
};
