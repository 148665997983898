import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'capitalize',
})
export class CapitalizePipe implements PipeTransform {
    transform(value: string): any {
        const firstChar = value.slice(0, 1);
        return `${firstChar.toUpperCase()}${value.slice(1)}`;
    }
}
