import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { EnvironmentService } from '@slm/shared/environment';
import { IHabilitatedLocationListParams } from '@solocal-manager/sirius/core/models';
import { IHabilitatedLocation, Location, PaginationDecoratorDTO } from '@solocal-manager/sirius/support/base-models';
import { get } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class HabilitatedLocationListService {
    constructor(
        public http: HttpClient,
        public envService: EnvironmentService,
    ) {}

    getLocationList(options: IHabilitatedLocationListParams): Observable<PaginationDecoratorDTO<IHabilitatedLocation>> {
        let params = new HttpParams();
        const userId = get(options, 'account') ? 'all' : 'me';

        for (const key in options) {
            if (key in options && !!options[key]) {
                params = params.append(key, options[key].toString());
            }
        }
        const url = `${this.envService.apiUrl}/sirius/user/${userId}/authorized-epjs`;
        return this.http.get<PaginationDecoratorDTO<IHabilitatedLocation>>(url, { params }).pipe(
            map(result => {
                result.currentPage = get(result, 'current_page');
                return result;
            }),
        );
    }

    getDefaultLocation(): Observable<{ epj: string }> {
        const url = `${this.envService.apiUrl}/sirius/user/me/default-epj`;
        return this.http.get<{ data: { epj: string } }>(url).pipe(map(res => (res ? res.data : null)));
    }

    /**
     * It creates the location if not known in sirius.
     */
    setSelectedLocation(locationEpj: string): Observable<Location> {
        const url = `${this.envService.apiUrl}/sirius/user/me/select-epj`;
        return this.http.post<{ data: Location }>(url, { epj: locationEpj }).pipe(map(res => (res ? res.data : null)));
    }

    deleteLocation(params: { epj: string }): Observable<any> {
        const url = `${this.envService.apiUrl}/sirius/user/me/delete-authorized-epj`;
        return this.http.post<{ data: any }>(url, params);
    }

    deleteIndirectLocation(params: { epj: string; ignored: boolean }): Observable<any> {
        const url = `${this.envService.apiUrl}/sirius/user/me/user-ignore-epj`;
        return this.http.post<{ data: any }>(url, params);
    }
}
