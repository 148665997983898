<div [class.move-location]="moveLocation" class="from-group center-block text-center multi-search-form">
    <ng-template #customItemTemplate let-model="item" let-index="index">
        <div class="move-location-item" *ngIf="moveLocation">
            {{ model.name }}<br />
            {{ model.address | address }}
        </div>

        <span *ngIf="!moveLocation && model.resultType === 'location'"
            >{{ model.name }} @ {{ model.address | address }}</span
        >
    </ng-template>

    <span class="searchicon"></span>
    <input
        [(ngModel)]="asyncSelected"
        [typeaheadItemTemplate]="customItemTemplate"
        [typeahead]="dataSource"
        [typeaheadWaitMs]="400"
        [typeaheadAsync]="true"
        [typeaheadMinLength]="3"
        typeaheadOptionField="name"
        (typeaheadLoading)="changeTypeaheadLoading($event)"
        (typeaheadNoResults)="changeTypeaheadNoResults($event)"
        (typeaheadOnSelect)="typeaheadOnSelect($event)"
        class="form-control multi-search-input"
        placeholder=" {{ 'Search EPJ, Location ...' | translate }}"
    />
    <div *ngIf="typeaheadLoading === true" class="multisearch-loading-icon">
        <i class="glyphicon glyphicon-refresh"></i>
    </div>
    <div *ngIf="typeaheadNoResults === true && displayEpjNew" class="multisearch-no-res-icon">
        <slm-create-location
            (done)="closeCreateLocation($event)"
            (createdLocation)="typeaheadOnSelect($event)"
            [maybeEpj]="asyncSelected"
            [appId]="applicationId"
        ></slm-create-location>
    </div>
    <div *ngIf="typeaheadNoResults === true && !displayEpjNew" class="multisearch-no-res-icon">
        <i class="glyphicon glyphicon-remove ng-hide"></i>{{ 'No Results Found' | translate }}
    </div>
</div>
