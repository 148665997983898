import { Provider } from '@angular/core';

import { PermissionEvaluation, PermissionEvaluationType } from '../models/permission-evaluation';

export const createPermissionProviders = (permissionConfig: Array<PermissionEvaluationType>): Array<Provider> => {
    return permissionConfig.map(service => ({
        provide: PermissionEvaluation,
        useClass: service,
        multi: true,
    }));
};
