import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Pipe({
    name: 'customDateFormatWithTranslation',
})
export class CustomDateFormatWithTranslationPipe implements PipeTransform {
    constructor(private translate: TranslateService) {}

    transform(val: string, lang: string, type?: string): string {
        const currentLang = this.translate.currentLang || this.translate.defaultLang || 'fr';
        const label = this.translate.instant('my_contact_at');
        moment.locale(lang);
        if (type === 'withoutTime') {
            return moment(val).locale(currentLang).format(`D MMMM YYYY`);
        } else {
            return moment(val)
                .locale(currentLang)
                .format(`D MMMM YYYY ${label} HH:mm`)
                .replace('amt', 'at')
                .replace('pmt', 'at');
        }
    }
}
