import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { kebabCase, snakeCase } from 'lodash-es';

@Component({
    selector: 'app-kpi-widget',
    templateUrl: './kpi-widget.component.html',
    styleUrls: ['./kpi-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KpiWidgetComponent {
    @Input() kpiType: string;
    @Input() title: string;
    @Input() value: number;
    @Input() percentage: number;

    get iconLink(): string {
        return 'assets/images/icon_kpi_' + snakeCase(this.kpiType) + '.svg';
    }

    get pageLink(): string[] {
        return [`/app/2/kpi`, kebabCase(this.kpiType)];
    }
}
