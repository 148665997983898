import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiErrorActions } from '@slm/shared/error';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { CompaniesActions } from '../actions';
import { CompaniesService } from '../services/companies.service';

@Injectable({ providedIn: 'root' })
export class CompaniesEffects {
    companies$ = createEffect(() =>
        this.update$.pipe(
            ofType(CompaniesActions.GET_COMPANIES),
            switchMap(() =>
                this.svc.getCompanies().pipe(
                    map(results => this.companiesActions.getCompaniesSuccess(results)),
                    catchError(error => of(new ApiErrorActions.CatchApiError(error))),
                ),
            ),
        ),
    );

    constructor(
        private update$: Actions,
        private companiesActions: CompaniesActions,
        private svc: CompaniesService,
    ) {}
}
