import { Action } from '@ngrx/store';
import { ILocationListGetRequest } from '@slm/location/api';
import { LocationDecorated } from '@solocal-manager/sirius/support/base-models';
import { PaginationDecoratorDTO } from '@solocal-manager/sirius/support/base-models';
import { Observable } from 'rxjs';

export enum LocationListActionTypes {
    SET_LOCATION_LIST = '[SET_LOCATION_LIST] set location list',
    GET_LOCATION_LIST = '[GET_LOCATION_LIST] get location list',
    GET_LOCATION_LIST_SUCCESS = '[GET_LOCATION_LIST] get location lists success',
    RESET_LOCATION_LIST = '[RESET_LOCATION_LIST] reset location list',
    UPDATE_LOCATION_IN_LOCATION_LIST = '[UPDATE_LOCATION_IN_LOCATION_LIST] update location data in store',
    PATCH_LOCATION_IN_LOCATION_LIST = '[PATCH LOCATION IN LOCATION LIST] Patch Location In Location List After Onboarding step update',
    SET_LOCATION_LIST_OBSERVABLE = '[SET_LOCATION_LIST_OBSERVABLE] set location list observable',
}

export class SetLocationList implements Action {
    readonly type = LocationListActionTypes.SET_LOCATION_LIST;

    constructor(public params: any) {}
}

export class SetLocationListObservable implements Action {
    readonly type = LocationListActionTypes.SET_LOCATION_LIST_OBSERVABLE;

    constructor(public obs: Observable<any>) {}
}

export class GetLocationList implements Action {
    readonly type = LocationListActionTypes.GET_LOCATION_LIST;

    constructor(public params: ILocationListGetRequest) {}
}

export class GetLocationListSuccess implements Action {
    readonly type = LocationListActionTypes.GET_LOCATION_LIST_SUCCESS;

    constructor(public result: PaginationDecoratorDTO<LocationDecorated>) {}
}

export class UpdateLocationInLocationList implements Action {
    readonly type = LocationListActionTypes.UPDATE_LOCATION_IN_LOCATION_LIST;

    constructor(public location: LocationDecorated) {}
}

export class PatchLocationInLocationList implements Action {
    readonly type = LocationListActionTypes.PATCH_LOCATION_IN_LOCATION_LIST;

    constructor(
        public id: string,
        public onboarding_steps: any,
    ) {}
}

export class ResetLocationList implements Action {
    readonly type = LocationListActionTypes.RESET_LOCATION_LIST;
}

export type LocationListActionsUnion =
    | SetLocationList
    | GetLocationList
    | GetLocationListSuccess
    | ResetLocationList
    | UpdateLocationInLocationList
    | PatchLocationInLocationList;
