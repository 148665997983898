<slm-header [disableSearch]="true"></slm-header>

<div class="graph-overlay">
    <div class="container-fluid admin-search-content">
        <div class="row">
            <div class="col-md-12">
                <app-kpi-widget-container></app-kpi-widget-container>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="kpi-container">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="admin-search-footer col-md-12">
                <div class="admin-search-copyright">
                    &copy;
                    {{ '2017 Solocal Group. All Rights Reserved' | translate }}
                </div>
                <div class="admin-search-powered-by">
                    <span>
                        <p>{{ 'Service powered by' | translate }}</p>
                        <img
                            class="admin-search-footer-logo"
                            src="assets/images/logo-solocal-white.png"
                            alt="solocal logo"
                        />
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
