import { EnvironmentProviders, importProvidersFrom } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LocationsEffects } from '../+state/locations/locations.effects';
import { LOCATIONS_FEATURE_KEY, locationsReducer } from '../+state/locations/locations.reducer';
import { MenuCategoryEffects } from '../+state/menucategory/menu-category.effects';
import { menuCardsCategoriesReducer, MenuCategory_FEATURE_KEY } from '../+state/menucategory/menu-category.reducer';

export const provideLocationState = (): EnvironmentProviders =>
    importProvidersFrom([
        StoreModule.forFeature(LOCATIONS_FEATURE_KEY, locationsReducer),
        StoreModule.forFeature(MenuCategory_FEATURE_KEY, menuCardsCategoriesReducer),
        EffectsModule.forFeature([LocationsEffects, MenuCategoryEffects]),
    ]);
