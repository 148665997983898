import { Action } from '@ngrx/store';

import { IOfferByProductId, IRequestOptionsByEpj, OfferByEpj } from '../models';

export enum OffersActionsTypes {
    GET_OFFERS_BY_EPJ = '[GET_OFFERS_BY_EPJ] get offers by locations (theirs epjs)',
    GET_OFFERS_BY_EPJ_SUCCESS = '[GET_OFFERS_BY_EPJ] get offers by locations (theirs epjs) success',
    GET_OFFERS_BY_EPJ_ERROR = '[GET_OFFERS_BY_EPJ] get offers by locations (theirs epjs) error',

    GET_OFFERS_BY_PRODUCT_ID = '[GET_OFFERS_BY_PRODUCT_ID] get offers by locations (theirs PRODUCT_IDs)',
    GET_OFFERS_BY_PRODUCT_ID_SUCCESS = '[GET_OFFERS_BY_PRODUCT_ID] get offers by locations (theirs PRODUCT_IDs) success',

    RESET_OFFERS = '[RESET_OFFERS] reset offers',
}

export class GetOffersByEpj implements Action {
    readonly type = OffersActionsTypes.GET_OFFERS_BY_EPJ;

    constructor(
        public payload: {
            epj: string;
            options: IRequestOptionsByEpj;
        },
    ) {}
}

export class GetOffersByEpjSuccess implements Action {
    readonly type = OffersActionsTypes.GET_OFFERS_BY_EPJ_SUCCESS;

    constructor(public payload: OfferByEpj[]) {}
}

export class GetOffersByEpjError implements Action {
    readonly type = OffersActionsTypes.GET_OFFERS_BY_EPJ_ERROR;
}

export class GetOffersByProductId implements Action {
    readonly type = OffersActionsTypes.GET_OFFERS_BY_PRODUCT_ID;

    constructor(public payload: { productIds: string[] }) {}
}

export class GetOffersByProductIdSuccess implements Action {
    readonly type = OffersActionsTypes.GET_OFFERS_BY_PRODUCT_ID_SUCCESS;

    constructor(public payload: IOfferByProductId[]) {}
}

export class ResetOffers implements Action {
    readonly type = OffersActionsTypes.RESET_OFFERS;
}

export class ResetOffersSuccess implements Action {
    readonly type = OffersActionsTypes.RESET_OFFERS;
}

export type OffersActionsUnion =
    | GetOffersByEpj
    | GetOffersByEpjSuccess
    | GetOffersByEpjError
    | GetOffersByProductId
    | GetOffersByProductIdSuccess
    | ResetOffers
    | ResetOffersSuccess;
