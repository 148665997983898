import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

import { TagManagerService } from '@slm/analytics';
import { ChatConfigViewModel, Conversation } from '@solocal-manager/sirius/data-access-chat/models';
import { ChatService } from '@solocal-manager/sirius/data-access-chat/services/chat.service';
import { Location } from '@solocal-manager/sirius/support/base-models';

import { InstantMessagingFacade } from '../data-access-chat.facade';
import { SmoochService } from '../services/smooch.service';

@Component({
    template: '',
})
export class BaseChatListComponent implements OnChanges {
    @Input() location: Location;
    @Input() chatConfig: ChatConfigViewModel;
    conversations: Conversation[] = [];
    hasChatActivated: boolean;
    currentPage = 1;
    count = null;
    isLastPage = false;
    isLoading = false;
    notActivatedChatIntegrations: string[];
    hideNotActivatedMessage = false;
    offset = 0;
    displayConv = false;
    isPluginInit = false;
    publisherChatList: string[] = [];
    onInit = false;

    constructor(
        public smoochService: SmoochService,
        protected chatService: ChatService,
        protected router: Router,
        public imFacade: InstantMessagingFacade,
        protected readonly tagManager: TagManagerService,
    ) {}

    get isPaused(): boolean {
        const featureSettings =
            this.location?.feature_settings &&
            this.location?.feature_settings.find(settings => settings.feature === 'chat');
        return (
            featureSettings?.status === 'paused' &&
            !!featureSettings?.status_paused_from &&
            !!featureSettings?.status_paused_to
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.location) {
            this.conversations = [];
            this.displayConv = false;
            this.isLoading = true;
            this.publisherChatList = [];
        }
        if (changes.chatConfig) {
            this.count = 0;
            this.currentPage = 1;
            // Check if location has at least one configuration active before init smooch plugin
            if (this.chatConfig && !this.chatConfig.hasIntegrationsNotconnected) {
                this.initPlugin();
            } else {
                this.toggleLoading(false);
            }
        }
    }

    /**
     * Init smooch plugin
     * 1 - Init smooch service with appId (appId is link with epj)
     * 2 - Login
     */
    public initPlugin(): void {
        if (this.onInit) return;
        const integrationId = this.smoochService.getPlatformIntegrationId(this.chatConfig.integrations);
        //Init Smooch mobile sdk
        try {
            this.onInit = true;
            const options = this.isPluginInit
                ? { integrationId: integrationId }
                : {
                      integrationId: integrationId,
                      conversationAccentColor: '#0078fa',
                  };
            this.smoochService
                .init(options)
                .then(
                    () => {
                        // Login with a specific user id and jwt
                        this.isPluginInit = true;
                        this.smoochService
                            .login(
                                this.chatConfig?.default_pro_user?.user_id,
                                this.chatConfig?.default_pro_user?.jwt_token,
                            )
                            .then(() => {
                                this.onInit = false;
                                this.getConversations();
                            });
                    },
                    () => {
                        this.onInit = false;
                        this.toggleLoading(false);
                    },
                )
                .catch(err => {
                    this.onInit = false;
                    this.displayInfoMessage(true, err);
                });
        } catch (e) {
            this.onInit = false;
            this.toggleLoading(false);
        }

        //Init Smooch web sdk to fetch all conversations
        this.offset = 0;
    }

    /**
     * Load and show conversation
     * @param conversation_id Conversation to show
     */
    goToDetail(conversation_id: string): void {
        this.patchChatUnreadConversation(1);
        if (conversation_id) {
            const id = conversation_id;
            this.smoochService
                .loadConversation(id)
                .then(() => {
                    this.smoochService.show();
                })
                .catch(() => {
                    this.initPlugin();
                    this.displayInfoMessage(true, "Can't display conversation");
                });

            this.tagManager.pageDisplayEvent({
                page: 'conversation détail',
                page_chapter1: 'messagerie instantannée',
            });
        }
    }

    patchChatUnreadConversation(count: number): void {
        this.imFacade.putChatUnreadCount(count);
    }

    /**
     * @method getConversations
     * @description Get conversation from SDK
     */
    getConversations(): void {
        this.isLoading = true;
        this.smoochService.getConversations().then(res => {
            if (res) this.conversationResponse(res);
        });
    }

    /**
     * @method getMoreConversations
     * @description Get next 10 conversation from SDK
     */
    getMoreConversations(): void {
        this.isLoading = true;
        this.smoochService.getMoreConversations().then(res => {
            this.conversationResponse(res);
        });
    }

    /**
     * Handle Conversation service reponse
     * @param res { conversations: Conversation[], hasMoreConversations: boolean } - response from SDK
     */
    conversationResponse(res: { conversations: Conversation[]; hasMoreConversations: boolean }): void {
        if (!res) {
            this.conversations = [];
            this.isLastPage = true;
        }
        this.conversations = res.conversations;
        this.isLastPage = !res.hasMoreConversations;
        if (this.conversations) {
            this.filterConversations();
        }
        this.toggleLoading(false);
        this.isLoading = false;
    }

    /**
     * @description Remove conversation if they don't have messages
     * @param {conversation[]} list of conversatoins
     */
    filterConversations(): void {
        const removedConversationNumber =
            this.conversations.filter(
                conversation => !conversation.messages?.length || conversation.messages[0] === null,
            ).length || 0;
        this.conversations = this.conversations.filter(
            conversation => !!conversation.messages?.length && conversation.messages[0] !== null,
        );
        this.count = this.count - removedConversationNumber;
        if (this.conversations.length < 10 && !this.isLastPage) {
            this.currentPage++;
            this.getMoreConversations();
        }
    }

    /**
     * Call in extend component
     * @param isLoading
     */
    toggleLoading(isLoading: boolean): void {
        this.isLoading = isLoading;
    }

    /**
     * Call in extend component
     * @param isError
     * @param message
     */
    displayInfoMessage(isError: boolean, message: string): void {
        throw new Error('Method should be implemented in extended component.');
    }
}
