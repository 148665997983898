import { Address } from '@solocal-manager/sirius/support/base-models';

export interface ICreateLocation {
    id: string;
    company_id: string;
    created: boolean;
    alias: string;
    name: string;
    address: Address;
    description: string;
    partner_id: string;
}
