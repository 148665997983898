import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';

class CheckableOption<T> {
    checked: boolean;
    data: T;

    constructor(source: T) {
        this.data = source;
        this.checked = false;
    }
}

@Component({
    selector: 'app-input-multi-select',
    templateUrl: './input-multi-select.component.html',
    styleUrls: ['./input-multi-select.component.scss'],
})
export class InputMultiSelectComponent implements OnChanges {
    checkableOptions: CheckableOption<any>[] = [];

    @Input() name: string;
    @Input() displayProperty: string;
    @Input() options: any[];
    @Input() multiSelectValue: any[];

    @Output() optionsSelected: EventEmitter<any[]> = new EventEmitter<any[]>();

    ngOnChanges(changed) {
        if (this.options) {
            this.checkableOptions = [];
            this.options.forEach(option => {
                const checkableOption: CheckableOption<any> = new CheckableOption(option);
                if (this.multiSelectValue) {
                    this.multiSelectValue.some(value => {
                        if (!!value.id && value.id === option.id) {
                            checkableOption.checked = true;
                            return true;
                        }
                        return false;
                    });
                }
                this.checkableOptions.push(checkableOption);
            });
        }
    }

    select(value: boolean, checkableOption: CheckableOption<any>) {
        checkableOption.checked = value;
        if (value) {
            this.multiSelectValue.push(checkableOption.data);
        } else {
            this.multiSelectValue.splice(this.multiSelectValue.indexOf(checkableOption.data), 1);
        }
        this.optionsSelected.emit(this.multiSelectValue);
    }
}
