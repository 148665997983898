import { Pipe, PipeTransform } from '@angular/core';

/**
 * Generated class for the HourRdvFormatPipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
    name: 'hourRdvFormat',
})
export class HourRdvFormatPipe implements PipeTransform {
    /**
     * Takes a value and makes it lowercase.
     */
    transform(value: string) {
        const result = value.split(':')[0] + 'h' + value.split(':')[1];
        return result;
    }
}
