import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { API_CONFIG } from '@slm/shared/environment';
import { get } from 'lodash-es';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class PublisherConnectService {
    private readonly apiConfig = inject(API_CONFIG);

    constructor(public http: HttpClient) {}

    connect(
        locationId: string,
        publisherId: string,
        pageId: string,
        config?: {
            customData?: any;
            publisher_sub_type?: string;
        },
    ): Observable<any> {
        if (publisherId === `pagesjaunes`) {
            return this.connectPagesjaunes(locationId, publisherId, pageId);
        } else {
            return this.connectOthers(locationId, publisherId, pageId, config);
        }
    }

    connectPagesjaunes(locationId: string, publisherId: string, pageId: string) {
        const connectPJUrl = `${this.apiConfig.unversioned}/proprietary/pagesjaunes/cviv-update/${locationId}`;

        return this.http
            .put(connectPJUrl, {
                cviv_id: pageId,
                pos_id: locationId,
            })
            .pipe(catchError((err: any) => observableThrowError(err)));
    }

    connectOthers(
        locationId: string,
        publisherId: string,
        pageId?: string,
        config?: {
            customData?: any;
            publisher_sub_type?: string;
        },
    ) {
        const connectOthersUrl = `${this.apiConfig.consumer}/locations/${locationId}/pages`;
        let data: any = {
            pos_id: locationId,
            publisher_id: publisherId,
        };

        if (pageId) {
            data = {
                ...data,
                publisher_page_id: pageId,
            };
        }

        const customData = get(config, 'customData');
        const publisherSubType = get(config, 'publisher_sub_type');

        if (customData) {
            data = {
                ...data,
                custom_data: customData,
            };
        }

        if (publisherSubType) {
            data = {
                ...data,
                publisher_sub_type: publisherSubType,
            };
        }

        // /locations/{location_id}/pages
        // Create a Page or Connect an existing Publisher Page to PM
        return this.http.post(connectOthersUrl, data).pipe(catchError((err: any) => observableThrowError(err)));
    }

    connectPublisherForCompany(companyId: string, publisherId: string, config?: any): Observable<any> {
        const requestUrl = `${this.apiConfig.consumer}/companies/${companyId}/publishers/${publisherId}/pages`;

        let body: any = {
            refresh: 1,
        };

        if (config && config.boardname) {
            body = {
                ...body,
                options: { boardname: config.boardname },
            };
        }

        return this.http.post(requestUrl, body);
    }
}
