import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { concatLatestFrom } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { LocationsActions, LocationsSelectors } from '@slm/location/state';
import { HOST_APP } from '@slm/shared/environment';
import { selectUser, User } from '@slm/user/state';
import { AppConfig } from '@solocal-manager/shared/util-common';
import { AvailablePublishersActions, CustomerActions, MultiSearchService } from '@solocal-manager/sirius/core/core';
import { Location } from '@solocal-manager/sirius/support/base-models';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { NGXLogger } from 'ngx-logger';
import { Subject } from 'rxjs';
import { distinctUntilKeyChanged, filter, startWith, take, takeUntil } from 'rxjs/operators';

import { AppSettingsModel } from '../../models';

const tabsConfig = {
    'sales-agent': {
        edit: false,
        'publisher-status': false,
        production: true,
    },
    default: {
        edit: true,
        'publisher-status': true,
        production: true,
    },
};

@Component({
    selector: 'slm-admin-tools-page',
    templateUrl: './admin-tools-page.component.html',
    styleUrls: ['./admin-tools-page.component.scss'],
})
export class AdminToolsPageComponent implements OnInit, OnDestroy {
    viewInit = false;
    selectedLocation: Location;
    hoverLocation: Location;
    currentApp: any;
    currentAppConfig: AppSettingsModel;
    refresh: boolean;
    locationReadonly = true;
    showProdTab = false;
    activeTab: number;
    activeTabName: string;
    submitLocationEvent = false;
    user: User;
    formValid;
    ready: boolean;
    refreshStatusList: number;
    setPublisherStatus;
    destroyed$: Subject<boolean> = new Subject();
    @ViewChild('toolsTabs')
    toolsTabs: TabsetComponent;
    private readonly hostApp = inject(HOST_APP);

    constructor(
        public logger: NGXLogger,
        public router: Router,
        public route: ActivatedRoute,
        public store: Store<any>,
        public multiSearchService: MultiSearchService,
        private appConfig: AppConfig,
    ) {}

    _tabsConfig: any = {};

    get tabsConfig() {
        return this._tabsConfig;
    }

    _isPublisherStatusRefresh: boolean;

    get isPublisherStatusRefresh() {
        return this._isPublisherStatusRefresh;
    }

    set isPublisherStatusRefresh(busy: boolean) {
        this._isPublisherStatusRefresh = busy;
    }

    setTabsConfig(role: string) {
        this._tabsConfig = tabsConfig[role] ? tabsConfig[role] : tabsConfig.default;
    }

    submitLocation($event) {
        setTimeout(() => {
            this.submitLocationEvent = !this.submitLocationEvent;
        });
    }

    checkFormValidity($event: boolean) {
        this.formValid = $event;
    }

    ngOnInit() {
        this.refreshStatusList = 0;
        this.ready = false;

        this.store.dispatch({
            type: AvailablePublishersActions.GET_AVAILABLE_PUBLISHERS,
        });

        this.store
            .pipe(
                select(LocationsSelectors.getSelectedLocation),
                filter(location => !!location), // check if location is already available
                distinctUntilKeyChanged('id'),
                takeUntil(this.destroyed$),
            )
            .subscribe(loc => {
                this.formValid = false;
                this.selectedLocation = loc as Location;
                this.multiSearchService
                    .search(loc.id, { locationOnly: true })
                    .pipe(take(1))
                    .subscribe(results => {
                        const item = results.find(i => i.id === loc.id);
                        if (item) {
                            this.store.dispatch({
                                type: CustomerActions.SET_CURRENT_CUSTOMER_ID,
                                payload: item.customerId,
                            });
                        }
                    });
            });

        this.store
            .select(selectUser)
            .pipe(takeUntil(this.destroyed$))
            .subscribe(user => {
                if (user && user.id) {
                    this.user = user;
                    this.currentApp = user?.application;
                    this.currentAppConfig = this.getAppConfig(this.currentApp.id) as AppSettingsModel;

                    this.locationReadonly = this.currentAppConfig.features.location.edit ? false : true;
                    this.showProdTab = this.currentAppConfig.features.production.show === false ? false : true;

                    setTimeout(() => this.selectTab());
                }
            });

        this.route.params
            .pipe(
                startWith([
                    location.search
                        ?.split('?')[1]
                        ?.split('&')
                        .reduce((acc, curr) => {
                            const [key, val] = curr.split('=');
                            acc[key] = val;
                            return acc;
                        }, {}),
                ]),
                concatLatestFrom(() => this.store.select(LocationsSelectors.getSelectedLocation)),
                concatLatestFrom(() => this.store.select(LocationsSelectors.getLocationsAndState)),
                takeUntil(this.destroyed$),
            )
            .subscribe(([[params, loc], locationList]) => {
                if (!loc?.id && params['locationId']) {
                    this.store.dispatch(LocationsActions.loadLocation({ id: params['locationId'], selectIt: true }));
                }
                this.selectTab();
                if (!!loc?.id && locationList?.initial) {
                    this.store.dispatch(
                        LocationsActions.setSingleLocation({
                            location: loc,
                        }),
                    );
                }
                this.selectedLocation = loc;
            });
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    getStatus(status) {
        this.setPublisherStatus = status;
        this.switchRoute('locationEdit');
    }

    doRefreshStatusList() {
        this.refreshStatusList++;
    }

    selectTab() {
        const params = this.route.snapshot.params;
        if (params['action']) {
            Object.values(this.currentAppConfig?.tools ?? []).forEach((tool: any) => {
                if (tool.path === params['action']) {
                    this.activeTabName = params['action'];
                    this.activeTab = tool.tab;
                }
            });
        }

        let tab = this.activeTab;

        if (tab >= 0 && this.toolsTabs.tabs[tab] && this.user?.groups) {
            if ((this.user.groups as any).find((row: any) => row.name === 'sales-agent')) {
                tab = 2;
                this.setTabsConfig('sales-agent');
            } else {
                this.setTabsConfig('default');
            }

            if (this.toolsTabs.tabs[tab]) {
                this.ready = true;
                this.toolsTabs.tabs[tab].active = true;
            }
        }
    }

    mouseoverLocation(location: Location) {
        this.hoverLocation = location;
    }

    switchRoute(action: string) {
        const config = (this.currentAppConfig as any).tools[action];
        this.activeTabName = config.path;
        this.refresh = true;

        if (this.selectedLocation) {
            this.router.navigate([
                'app',
                this.currentApp.id,
                'company',
                this.selectedLocation.company_id,
                'location',
                this.selectedLocation.id,
                config.path,
            ]);
        }
    }

    deselectRoute($event) {
        this.refresh = undefined;
    }

    getCustomClass(available) {
        return (this.currentAppConfig as any)?.tools[available] ? '' : 'hidden';
    }

    isLocationEditEnabled(): boolean {
        if (this.activeTabName === 'edit' && !this.locationReadonly) {
            return true;
        }

        return false;
    }

    getAppConfig(appId: number): any {
        const envConfig = this.appConfig[this.hostApp.environment] || this.appConfig.default;

        return envConfig[appId] || envConfig['default'];
    }
}
