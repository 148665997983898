import { LocationEntity } from '../+state/locations/locations.models';

const NOT_INCLUDE_IN_OTHER = ['pagesjaunes', 'pj_contenu', 'gmb', 'facebook', 'facebook_chat', 'googlemaps'];

const EXCLUDED_STATES = ['not_connected', 'do_not_connect', 'deleted'];

const NOT_COUNTED_PUBLISHERS = ['qwant', 'uberall', 'factual_navads'];

export interface MonoLocationConnectedPublishers {
    pagesJaunes?: { state?: string; name?: string };
    google?: { state?: string; name?: string };
    facebook?: { state?: string; name?: string };
    other?: { countAll: number; state?: string; name?: string };
}

export interface MultiLocationConnectedPublishers {
    pagesJaunes: { countAll: number };
    google: { eligible: boolean; countConnected: number };
    facebook: { eligible: boolean; countConnected: number };
}

export interface ConnectedPublishers {
    mono?: MonoLocationConnectedPublishers;
    multi?: MultiLocationConnectedPublishers;
}

export const createConnectedPublishers = (locations: LocationEntity[]): ConnectedPublishers => {
    if (locations.length === 1) {
        return {
            mono: createForMonoLocation(locations[0]),
        };
    }

    return {
        multi: createForMultiLocation(locations),
    };
};

export const createForMonoLocation = (location: LocationEntity): MonoLocationConnectedPublishers => {
    const other = filterPages(location?.pages);
    const otherErrorPages = other?.filter(page => page.state === 'error');
    const otherPendingOrRejectedPages = other?.filter(page => page.state === 'rejected' || page.state === 'pending');
    const publisherMap = {
        pagesJaunes: getPublisher(location, 'pagesjaunes', 'Pagesjaunes'),
        google: getPublisher(location, 'gmb', 'Google'),
        facebook: getPublisher(location, 'facebook', 'Facebook'),
        other:
            (other && {
                countAll: other.length,
                state: otherErrorPages.length > 0 || otherPendingOrRejectedPages.length > 0 ? 'error' : '',
                name: otherErrorPages.length === 1 ? setFirstLetterUpperCase(otherErrorPages[0]?.publisher_id) : '',
            }) ??
            null,
    };

    return publisherMap;
};

const filterPages = pages => {
    return pages?.filter(
        page =>
            !NOT_INCLUDE_IN_OTHER.includes(page.publisher_id) &&
            !EXCLUDED_STATES.includes(page.state) &&
            !NOT_COUNTED_PUBLISHERS.includes(page.publisher_id),
    );
};

const createForMultiLocation = (locations: LocationEntity[]): MultiLocationConnectedPublishers => {
    const pages = locations.flatMap(location => location.pages);
    const google = pages.filter(page => page?.publisher_id === 'gmb');
    const facebook = pages.filter(page => page?.publisher_id === 'facebook');

    return {
        pagesJaunes: { countAll: locations.length },
        google: {
            eligible: google.length > 0,
            countConnected: google.filter(page => page?.state === 'active').length,
        },
        facebook: {
            eligible: facebook.length > 0,
            countConnected: facebook.filter(page => page?.state === 'active').length,
        },
    };
};

const getPublisher = (
    location: LocationEntity,
    publisherId: string,
    aliasName?: string,
): { state?: string; name?: string } => {
    const page = location?.pages?.find(
        page => page.publisher_id === publisherId && (page.state === 'active' || page.state === 'error'),
    );
    return page
        ? {
              state: page.state,
              name: aliasName ?? '',
          }
        : null;
};

export const setFirstLetterUpperCase = (name: string): string => {
    return name?.charAt(0).toUpperCase() + name?.slice(1);
};
