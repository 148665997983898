import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';

import { getPublisherStatus } from '@solocal-manager/sirius/core/core';
import { PublisherConnectService } from '@solocal-manager/sirius/core/core';
import { Location } from '@solocal-manager/sirius/support/base-models';

import { GmbProcessService } from '../../services';

@Component({
    selector: 'slm-publisher-connect-gmb-new',
    templateUrl: './publisher-connect-gmb-new.component.html',
    styleUrls: ['./publisher-connect-gmb-new.component.scss'],
})
export class PublisherConnectGmbNewComponent implements OnInit, OnDestroy {
    connectionSubscribtion: Subscription;
    locationSubscribtion: Subscription;

    gmbAllowForm: UntypedFormGroup;
    @Input() location: Location;
    constructor(
        public logger: NGXLogger,
        public formBuilder: UntypedFormBuilder,
        public publisherConnectService: PublisherConnectService,
        public store: Store<any>,
        public gmbProcessService: GmbProcessService,
    ) {
        this.gmbAllowForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            optout: [false, []],
        });
    }

    ngOnInit() {
        if (this.location) {
            this.gmbAllowForm.controls.email.setValue('');
        }
    }

    onOptoutChange($event) {
        if ($event.target.checked) {
            this.gmbAllowForm.controls['email'].clearValidators();
        } else {
            this.gmbAllowForm.controls['email'].setValidators([Validators.required, Validators.email]);
        }

        this.gmbAllowForm.controls['email'].updateValueAndValidity();
    }

    connect(pubPageId: string) {
        const customData = {
            invite_admins: {
                'opt-out': this.gmbAllowForm.controls.optout.value,
                emails: this.gmbAllowForm.controls.optout.value ? [] : [this.gmbAllowForm.controls.email.value],
            },
        };

        this.connectionSubscribtion = this.publisherConnectService
            .connect(this.location.id, 'gmb', pubPageId, { customData })
            .subscribe(
                res => {
                    if (res) {
                        this.store.dispatch(getPublisherStatus({ params: { location: this.location } }));
                        this.gmbProcessService.registerClose(true);
                    }
                },
                err => {
                    this.logger.debug('<PublisherConnectGmbNewComponent> connect error:', err);
                },
            );
    }

    createNewGMBPage() {
        this.connect('');
    }

    ngOnDestroy() {
        if (this.connectionSubscribtion) {
            this.connectionSubscribtion.unsubscribe();
        }

        if (this.locationSubscribtion) {
            this.locationSubscribtion.unsubscribe();
        }
    }
}
