import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';

import { PermissionStrategy } from '../enums/permission-strategy.enum';
import { PermissionBroker } from '../models/permission-broker';
import { PermissionEvaluation } from '../models/permission-evaluation';

@Injectable({
    providedIn: 'root',
})
export class PermissionService extends PermissionBroker {
    constructor(private readonly permissions: PermissionEvaluation) {
        super();
    }

    hasPermission(type: PermissionStrategy, params?: { [index: string]: any }): Observable<boolean> {
        return this.getEvaluationService(type, this.permissions)?.hasPermission(params) ?? of(false);
    }

    protected getEvaluationService(type: PermissionStrategy, permissions: PermissionEvaluation): PermissionEvaluation {
        if (Array.isArray(permissions)) {
            return permissions.find(service => service.type === type);
        }

        if (permissions.type === type) {
            return permissions;
        }
        return permissions;
    }
}
