import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { HOST_APP, HostApp } from '@slm/shared/environment';
import { Dictionary } from '@solocal-manager/sirius/support/base-models';
import { Observable, of } from 'rxjs';

import * as UserActions from './user.actions';
import { User } from './user.model';
import * as fromUser from './user.reducer';
import * as UserSelectors from './user.selectors';

@Injectable({
    providedIn: 'root',
})
export class UserFacade {
    constructor(
        private readonly store: Store<fromUser.UserPartialState>,
        @Inject(HOST_APP) public readonly hostApp: HostApp,
    ) {}

    selectUser(): Observable<User> {
        return this.store.select(UserSelectors.selectUser);
    }

    fetchUser(): void {
        this.store.dispatch(UserActions.getUser());
    }

    selectBasePath(): Observable<string[]> {
        if (this.hostApp.isSlmMobile) {
            return of(['tabs']);
        }
        return this.store.select(UserSelectors.getAppBasePath);
    }

    selectBasePathAsString(): Observable<string> {
        if (this.hostApp.isSlmMobile) {
            return of('tabs');
        }
        return this.store.select(UserSelectors.getAppBasePathAsString);
    }
}
