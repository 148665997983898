import { Pipe, PipeTransform } from '@angular/core';

// transforms array into concatinated strings with separator specified as argument
// usage examples:
// {{ arrayOfStrings | separator:',' }}
// {{ arrayOfStrings | separator:'-' }}
@Pipe({
    name: 'separator',
})
export class SeparatorPipe implements PipeTransform {
    transform(value: any, separator: string): any {
        value = value.join(separator);
        return value;
    }
}
