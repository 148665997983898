import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'isSelected',
    pure: true,
})
export class IsSelected implements PipeTransform {
    transform(item: string | number, list: any[]): boolean {
        return list.includes(item);
    }
}
