import { KpiActionsUnion, KpiActionTypes } from '../actions';
import { IParcUsageKpi } from '../models';

export type TotalParcUsageKpiState = IParcUsageKpi[];

const initialState: TotalParcUsageKpiState = undefined;

export function totalParcUsageKpiReducer(state = initialState, action: KpiActionsUnion): TotalParcUsageKpiState {
    switch (action.type) {
        case KpiActionTypes.GET_TOTAL_PARC_USAGE_SUCCESS: {
            if (!state) {
                return action.result;
            } else {
                const clonedState = state.map(x => ({ ...x }));
                action.result.forEach((resultParcUsage: IParcUsageKpi) => {
                    const storedParcUsages = clonedState.filter(p => p.year === resultParcUsage.year);
                    if (!storedParcUsages.length) {
                        clonedState.push(resultParcUsage);
                    } else {
                        storedParcUsages[0].months = resultParcUsage.months;
                    }
                });
                clonedState.sort((a, b) => (a.year > b.year ? 1 : -1));
                return clonedState;
            }
        }
        default:
            return state;
    }
}
