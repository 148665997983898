import { UntypedFormGroup, ValidationErrors } from '@angular/forms';

export class VideoValidator {
    static create(form: UntypedFormGroup) {
        return (): ValidationErrors => {
            const err = {
                addressError: {
                    valid: form.valid,
                },
            };
            return form.valid ? null : err;
        };
    }
}
