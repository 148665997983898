import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { PublisherConnectService, PublishersService } from '@solocal-manager/sirius/core/core';
import { Location, Reference } from '@solocal-manager/sirius/support/base-models';

import { ConnectModalService } from '../../services';

@Component({
    selector: 'slm-publisher-connect-facebook',
    templateUrl: './publisher-connect-facebook.component.html',
    styleUrls: ['./publisher-connect-facebook.component.scss'],
})
export class PublisherConnectFacebookComponent implements OnInit, OnChanges, OnDestroy {
    onCloseSubscription: Subscription;
    publisherSearchResults;
    claimed = false;
    businessManagerList$: Observable<Reference[]>;
    businessManagerId = '';

    @Input() location: Location;

    @Output() closeModal: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        public logger: NGXLogger,
        public store: Store,
        public publisherConnectService: PublisherConnectService,
        private publishersService: PublishersService,
        private connectModalService: ConnectModalService,
    ) {}

    ngOnInit(): void {
        this.businessManagerList$ = this.publishersService.getBusinessManagerOptions('facebook');
        this.onCloseSubscription = this.connectModalService.onClose.subscribe(() => {
            this.claimed = false;
        });
    }

    ngOnChanges() {
        this.publisherSearchResults = [];
    }

    ngOnDestroy() {
        this.onCloseSubscription.unsubscribe();
    }

    connect() {
        this.claimed = false;

        this.logger.debug('<PublisherConnectFacebookComponent> connect new page');

        this.publisherConnectService
            .connect(this.location.id, 'facebook', '', {
                customData: { business_manager_id: this.businessManagerId },
            })
            .pipe(take(1))
            .subscribe(
                res => {
                    if (res) {
                        this.publisherSearchResults = res.data;
                        this.closeModal.emit(true);
                    }
                },
                err => {
                    this.logger.debug('<PublisherConnectFacebookComponent> connect error:', err);
                },
            );
    }
}
