<div [formGroup]="formRepeatable">
    <div cdkDropList (cdkDropListDropped)="drop($event)" formArrayName="data">
        <label for="inputText">{{ label | translate }}</label>
        <div cdkDrag [formGroupName]="i" *ngFor="let row of formData.controls; let i = index">
            <div class="row">
                <div class="form-group col-md-1 col-sm-1 col-1 x-btn-holder force-left">
                    <button type="button" cdkDragHandle class="btn btn-primary remove-field-button">
                        <span class="glyphicon drag-handle">&#xe140;</span>
                    </button>
                </div>
                <div class="form-group col-md-10 col-sm-10 col-10">
                    <input type="text" class="form-control" formControlName="text" />
                </div>
                <div class="form-group col-md-1 col-sm-1 col-1 x-btn-holder">
                    <button
                        type="button"
                        class="btn btn-primary remove-field-button on-the-right"
                        (click)="removeInput(i)"
                    >
                        X
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-1 col-sm-1 col-1 force-left"></div>
                <div class="col-md-11 err-msg-holder">
                    <app-input-error-messages [control]="row.controls.text"></app-input-error-messages>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-12 form-add-holder">
        <button class="btn btn-primary remove-field-button" (click)="addInput()" translate>Add {{ label }}</button>
    </div>
</div>
