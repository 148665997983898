import { forwardRef, Component, OnChanges, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_ASYNC_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Observable, Subscriber } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { ValidateRguAddress } from '@solocal-manager/sirius/core/core';
import { WpmInputAddressComponent } from '@solocal-manager/sirius/core/core';

@Component({
    selector: 'slm-input-address',
    templateUrl: './input-address.component.html',
    styleUrls: ['./input-address.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputAddressComponent),
            multi: true,
        },
        {
            provide: NG_ASYNC_VALIDATORS,
            useExisting: forwardRef(() => InputAddressComponent),
            multi: true,
        },
    ],
})
export class InputAddressComponent extends WpmInputAddressComponent implements ControlValueAccessor, OnInit {
    showToolTip = false;
    isStreetValidatorRemoved = false;
    typeaheadZipLoading = false;
    typeaheadZipNoResults = false;
    typeaheadLaneLoading = false;
    typeaheadLaneNoResults = false;
    zipDataSource: Observable<any>;
    lanesDataSource: Observable<any>;

    showTooltip(toggle) {
        this.showToolTip = toggle;
    }

    ngOnInit() {
        super.ngOnInit();

        this.zipDataSource = new Observable((subscriber: Subscriber<string>) => {
            this.formAddress.controls.city.setValue('');
            this.formAddress.controls.country.setValue('');
            if (this.formAddress.get('zip_code').value && this.formAddress.get('zip_code').value.length > 2) {
                subscriber.next(this.formAddress.get('zip_code').value);
            }
        }).pipe(mergeMap((token: string) => this.addressHelperFacade.getStates(token)));

        this.lanesDataSource = new Observable(
            (
                subscriber: Subscriber<{
                    zipCode: string;
                    street: string;
                    city: string;
                }>,
            ) => {
                const zipCode = this.formAddress.get('zip_code').value;
                const street = this.formAddress.get('address_line1').value;
                const city = this.formAddress.get('city').value;
                const zipCodePattern = /^\d{5}$/;
                if (street.length > 2 && !!zipCode && zipCodePattern.test(zipCode)) {
                    subscriber.next({ zipCode, street, city });
                }
            },
        ).pipe(
            mergeMap((params: { zipCode: string; street: string; city: string }) =>
                this.addressHelperFacade.getLanes(params.street, params.zipCode, params.city, this.locationId),
            ),
        );
    }

    changeTypeaheadZipLoading(e: boolean): void {
        this.formAddress.get('address_line1').setValue('');
        this.typeaheadZipLoading = e;
    }

    changeTypeaheadZipNoResults(e: boolean): void {
        this.typeaheadZipNoResults = e;
    }

    typeaheadOnZipSelect(e: TypeaheadMatch): void {
        this.formAddress.controls.zip_code.setValue(e.item.zip_code);
        this.formAddress.controls.city.setValue(e.item.city);
        this.formAddress.controls.country.setValue(e.item.country);
        this.formAddress.controls.insee_code.setValue(e.item.insee_code);
    }

    changeTypeaheadLaneLoading(e: boolean): void {
        this.typeaheadLaneLoading = e;
    }

    changeTypeaheadLaneNoResults(e: boolean): void {
        this.typeaheadLaneNoResults = e;
    }

    typeaheadOnLaneSelect(e: TypeaheadMatch): void {
        this.formAddress.controls.address_line1.setValue(e.item.l4);
    }

    removeStreetValidator(): void {
        if (this.isStreetValidatorRemoved) {
            this.isStreetValidatorRemoved = false;
            this.formAddress.setAsyncValidators(
                ValidateRguAddress.createValidator(this.addressHelperFacade, this.locationId),
            );
        } else {
            this.isStreetValidatorRemoved = true;
            this.formAddress.setAsyncValidators([]);
        }
        this.formAddress.updateValueAndValidity();
    }

    get laneInvalid(): boolean {
        return this.formAddress.errors && this.formAddress.errors.addressLaneInvalid;
    }
}
