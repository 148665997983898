import { Pipe, PipeTransform } from '@angular/core';

import { LocationCompletionSuggestionService } from '@slm/location/service';
import { Location } from '@solocal-manager/sirius/support/base-models';

@Pipe({
    name: 'locationSuggestion',
})
export class LocationSuggestionPipe implements PipeTransform {
    constructor(private completionService: LocationCompletionSuggestionService) {}

    transform(location: Location): string {
        let completionSuggestion: string;

        if (location) {
            completionSuggestion = this.completionService.getSuggestion(location.completion_rate);
        }

        return completionSuggestion;
    }
}
