import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@slm/shared/environment';
import { Location } from '@solocal-manager/sirius/support/base-models';
import * as jwtDecode from 'jwt-decode';
import * as moment from 'moment';
import { of, throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';


const MILLISECONDS = 1000;
const LEAPTIME = 500;

@Injectable({ providedIn: 'root' })
export class AzureTokenService {
    public token: string;
    interval: any;
    private cachedRequest: Observable<string>;

    constructor(
        private http: HttpClient,
        private environmentService: EnvironmentService,
    ) {}

    get subKey() {
        return this.environmentService.azureSubKey;
    }

    get isExpiredToken$(): Observable<boolean> {
        if (!this.cachedRequest) {
            return of(true);
        }
        return this.cachedRequest.pipe(
            map((token: string) => {
                const decodedToken = jwtDecode(token);
                const now = moment();
                const expirationDate = moment(decodedToken.exp * MILLISECONDS - LEAPTIME);
                return now.isAfter(expirationDate);
            }),
        );
    }

    resetToken() {
        this.token = null;
        this.cachedRequest = undefined;
    }

    tokenFromLocations(locations: Location[]): Observable<string> {
        const ids = locations.map(loc => {
            return loc.id;
        });
        return this.getToken(ids);
    }

    getToken(locationIds: string[]): Observable<string> {
        if (!locationIds || locationIds.length === 0) {
            return observableThrowError('No location provided get token');
        }
        return this.isExpiredToken$.pipe(
            switchMap((isExpiredToken: boolean) => {
                if (isExpiredToken) {
                    this.cachedRequest = this.setCachedRequest(locationIds, this.environmentService.apiUrl);
                }
                return this.cachedRequest.pipe(
                    catchError(err => {
                        this.resetToken();
                        return observableThrowError(err);
                    }),
                );
            }),
        );
    }

    getHeaders(locationIds: string[]): Observable<HttpHeaders> {
        return this.getToken(locationIds).pipe(
            map(token =>
                new HttpHeaders()
                    .set('jwt', token)
                    .set('Ocp-Apim-Subscription-Key', this.subKey)
                    .set('Content-Type', 'application/json'),
            ),
        );
    }

    getJsonHeaders(locationIds: string[]): Observable<{ [key: string]: string }> {
        return this.getHeaders(locationIds).pipe(
            map(headers => {
                const keys = headers.keys();
                const headersObj = {};
                keys.forEach(key => {
                    headersObj[key] = headers.get(key);
                });
                return headersObj;
            }),
        );
    }

    private setCachedRequest(locationIds: string[], apiBaseUrl: string): Observable<string> {
        const params = new HttpParams().append('locations', locationIds.join(','));
        const apiUrl = `${apiBaseUrl}/consumer/user/me/solocal-token`;

        return this.http
            .get<{ token: string }>(apiUrl, {
                params,
            })
            .pipe(
                map(res => res.token),
                tap(token => (this.token = token)),
                shareReplay(1),
            );
    }
}
