import { UntypedFormArray, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { find } from 'lodash-es';

/*

    How does this validator work?

    example:

    +-----------------------------------------------------------------+
    |                                                                 |
    |                            NOT SORTED - INVALID                 |
    |               +-----------------------------------------+       |
    |               |                                         |       |
    |               |                                         |       |
    |               |                                         |       |
    |     MONDAY:   |     4.0,         3.0,       15.0        |       |
    |               |      ^                                  |       |
    |               |      |                                  |       |
    |               +-----------------------------------------+       |
    |                      |                                          |
    |                      |                                          |
    |                      | insert at first place                    |
    |                      |                                          |
    |                      +-----------------------+                  |
    |                                              | 4.0 is smaller   |
    |                                              | than 15.0        |
    |                                              | so belongs to    |
    |                      SORTED - VALID          | next day         |
    |               +-----------------------------------------+       |
    |               |                          |   |          |       |
    |               |                          |   |          |       |
    |               |                          |   +          |       |
    |     SUNDAY:   |     6.0,    9.0,    15.0,|  4.0         |       |
    |               |                          |              |       |
    |               |                          |              |       |
    |               +-----------------------------------------+       |
    |                                          |                      |
    |                                          +                      |
    |                                                                 |
    +-----------------------------------------------------------------+

*/

export class OverlappingValidator {
    static create(formRepeatable: UntypedFormGroup) {
        return (group: UntypedFormGroup): ValidationErrors => {
            const repeatableControls = <UntypedFormArray>formRepeatable.controls.data;
            const dayTimeArray: any = [];
            if (repeatableControls.controls.length > 0) {
                repeatableControls.controls
                    .filter(control => !control.value.closed)
                    .forEach(element => {
                        dayTimeArray.push(
                            ...element.value.openingHours.map(item => {
                                return {
                                    open_day: element.value.open_day,
                                    close_day: element.value.close_day,
                                    open_time: item.open_time,
                                    close_time: item.close_time,
                                };
                            }),
                        );
                    });

                const dayMap = {};

                dayTimeArray.forEach(dayTime => {
                    dayMap[dayTime.open_day] = dayMap[dayTime.open_day] ? [...dayMap[dayTime.open_day]] : [];
                    dayMap[dayTime.close_day] = dayMap[dayTime.close_day] ? [...dayMap[dayTime.close_day]] : [];

                    if (dayTime.open_day === dayTime.close_day) {
                        // append to current times
                        const firstCloseTime = dayMap[dayTime.open_day][1];

                        // if second slot has earlier times than first
                        if (firstCloseTime && dayTime.close_time < firstCloseTime) {
                            // prepend
                            dayMap[dayTime.open_day] = [
                                dayTime.open_time,
                                dayTime.close_time,
                                ...dayMap[dayTime.open_day],
                            ];
                        } else {
                            // append
                            dayMap[dayTime.open_day] = [
                                ...dayMap[dayTime.open_day],
                                dayTime.open_time,
                                dayTime.close_time,
                            ];
                        }
                    } else {
                        // prepend to current times
                        dayMap[dayTime.open_day] = [...dayMap[dayTime.open_day], dayTime.open_time];

                        dayMap[dayTime.close_day] = [dayTime.close_time, ...dayMap[dayTime.close_day]];
                    }
                });

                // if for one week day numbers are not sorted -> has mismatch
                const hasMismatch = !!find(
                    dayMap,
                    (times: number[]) =>
                        // check if numbers are sorted
                        !!times.find((time: number, index: number) => times[index + 1] && time > times[index + 1]),
                );

                return hasMismatch
                    ? {
                          openingVsClosingTimeOverlapping: true,
                      }
                    : null;
            }
            return null;
        };
    }
}
