import { Component, OnInit } from '@angular/core';

import { ISearchLocationSelected } from '../../models';
import { AdminUiService } from '../../services';

@Component({
    selector: 'app-admin-search-page',
    templateUrl: './admin-search-page.component.html',
    styleUrls: ['./admin-search-page.component.scss'],
})
export class AdminSearchPageComponent implements OnInit, ISearchLocationSelected {
    constructor(public adminUiService: AdminUiService) {}

    ngOnInit() {}
    locationSelection(change: boolean) {
        this.adminUiService.locationChanged.next(change);
    }
}
