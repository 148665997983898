import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiErrorActions } from '@slm/shared/error';
import { of } from 'rxjs';
import { catchError, switchMap, map } from 'rxjs/operators';

import { ActionWithPayload, PaymentMethodsActions } from '../actions';
import { ReferenceService } from '../services/reference.service';

@Injectable({ providedIn: 'root' })
export class PaymentMethodsEffects {
    constructor(
        private update$: Actions,
        private paymentMethodsActions: PaymentMethodsActions,
        private referenceService: ReferenceService,
    ) {}

    paymentMethods$ = createEffect(() =>
        this.update$.pipe(
            ofType(PaymentMethodsActions.GET_PAYMENT_METHODS),
            map((action: ActionWithPayload<any>) => action.payload),
            switchMap(payload =>
                this.referenceService.getPaymentMethods().pipe(
                    map(results => this.paymentMethodsActions.paymentMethodsSuccess(results)),
                    catchError(error => of(new ApiErrorActions.CatchApiError(error))),
                ),
            ),
        ),
    );
}
