import { UntypedFormGroup, ValidationErrors } from '@angular/forms';

export class ServiceAreaValidator {
    static create(form: UntypedFormGroup) {
        return (): ValidationErrors => {
            const err = {
                serviceAreaError: {
                    errors: form.invalid,
                },
            };
            return form.valid ? null : err;
        };
    }
}
