<div class="specific-opening-hours-input">
    <div [formGroup]="formRepeatable">
        <div formArrayName="data">
            <div
                [formGroupName]="i"
                class="specific-opening-hours"
                *ngFor="let specificOpeningHours of formData.controls; let i = index"
            >
                <div class="row">
                    <div class="col-md-4 col-sm-4 col-4">
                        <app-input-datepicker
                            [control]="specificOpeningHours.controls.start_date"
                            (change)="openingChanged(specificOpeningHours)"
                        ></app-input-datepicker>
                        <app-input-error-messages
                            [control]="specificOpeningHours.controls.start_date"
                        ></app-input-error-messages>
                    </div>
                    <div class="col-md-2 col-sm-2 col-2">
                        <select name="closed" formControlName="closed" class="custom-select">
                            <option value=""></option>
                            <option [ngValue]="true">{{ 'closed' | translate }}</option>
                            <option [ngValue]="false">{{ 'opened' | translate }}</option>
                        </select>
                        <app-input-error-messages
                            [control]="specificOpeningHours.controls.closed"
                        ></app-input-error-messages>
                    </div>
                    <div class="col-md-2 col-sm-2 col-2">
                        <select
                            name="openTime"
                            *ngIf="
                                !specificOpeningHours.controls.closed.value &&
                                specificOpeningHours.controls.closed.value !== ''
                            "
                            formControlName="open_time"
                            (change)="openingChanged(specificOpeningHours)"
                            class="custom-select"
                        >
                            <option *ngFor="let slot of timeSlots" [ngValue]="slot">{{ slot }}</option>
                        </select>
                    </div>
                    <div class="col-md-2 col-sm-2 col-2">
                        <select
                            name="closeTime"
                            *ngIf="
                                !specificOpeningHours.controls.closed.value &&
                                specificOpeningHours.controls.closed.value !== ''
                            "
                            formControlName="close_time"
                            (change)="openingChanged(specificOpeningHours)"
                            class="custom-select"
                        >
                            <option *ngFor="let slot of timeSlots" [ngValue]="slot">{{ slot }}</option>
                        </select>
                    </div>
                    <div class="col-md-2 col-sm-2 col-2">
                        <button
                            type="button"
                            class="btn btn-primary remove-field-button on-the-right"
                            (click)="removeTimeslot(specificOpeningHours, i)"
                        >
                            X
                        </button>
                    </div>
                    <input type="hidden" id="inputEndDate" formControlName="end_date" />
                </div>
                <app-input-error-messages [control]="specificOpeningHours"></app-input-error-messages>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <button class="btn btn-primary remove-field-button" (click)="addInput()">
                {{ 'Add Specific Hours' | translate }}
            </button>
        </div>
    </div>
</div>
