import { TranslateService } from '@ngx-translate/core';
import { getName } from 'i18n-iso-countries';
import { sortBy } from 'lodash-es';

import { Country } from '@solocal-manager/sirius/core/models';

export class CountryViewModel {
    private readonly allowedIsoCodes: string[] = [
        'FR',
        'MC',
        'RE',
        'GF',
        'GP',
        'MQ',
        'YT',
        'PM',
        'MF',
        'BL',
        'WF',
        'PF',
    ];
    readonly countryCodes: string[];
    readonly countries: Country[];

    constructor(public translate: TranslateService) {
        this.countryCodes = this.allowedIsoCodes;
        this.countries = this.getCountries();
    }

    getTranslatedName(countryCode: string): string {
        return getName(countryCode, this.translate.currentLang);
    }

    private getCountries(): Country[] {
        const countries: Country[] = [];
        this.allowedIsoCodes.forEach(code => {
            countries.push({
                name: getName(code, this.translate.currentLang),
                code,
            });
        });
        return sortBy(countries, ['name']);
    }
}
