const pricingData = { price: null, type: null };

export const initData = {
    number: null,
    pricing: pricingData,
    type: '',
    type_line: null,
};

export const phonesConfig = {
    number: {
        label: '',
        placeholder: 'Your telephone number',
        type: 'number',
        initData: '',
        required: true,
    },
    type: {
        label: '',
        placeholder: '',
        type: 'string',
        initData: '',
        required: true,
    },
    type_line: {
        label: '',
        placeholder: 'Current, standard, service',
        type: 'text',
        initData: '',
        // attributes: { maxlength: 70 }
    },
    pricing: {
        price: {
            label: '',
            placeholder: 'price (in Euro)',
            type: 'number',
            initData: null,
            attributes: { min: 0.0, step: 0.01 },
            forceType: 'number',
        },
        type: {
            label: '',
            placeholder: '',
            type: 'string',
            options: [
                { id: 'per_call', value: 'Per call' },
                { id: 'per_min', value: 'Per minute' },
            ],
        },
    },
};
