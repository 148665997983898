import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiErrorActions } from '@slm/shared/error';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { AvailablePublishersActions } from '../actions';
import { PublishersService } from '../services';

@Injectable({ providedIn: 'root' })
export class AvailablePublishersEffects {
    private readonly update$ = inject(Actions);

    availablePublishers$ = createEffect(() =>
        this.update$.pipe(
            ofType(AvailablePublishersActions.GET_AVAILABLE_PUBLISHERS),
            switchMap(() =>
                this.svc.getPublishers().pipe(
                    map(publishers => this.availablePublishersActions.getAvailablePublishersSuccess(publishers)),
                    catchError(error => of(new ApiErrorActions.CatchApiError(error))),
                ),
            ),
        ),
    );

    constructor(
        private availablePublishersActions: AvailablePublishersActions,
        private svc: PublishersService,
    ) {}
}
