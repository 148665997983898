import { IErrorInfo, IPublisherErrorsKpi } from '../models';

interface IPublishersErrorMetaInfo {
    total: number;
    errorIndexMap: { [messageId: string]: number };
}

export class PublishersErrorKpiViewModel {
    /**
     * @description
     * A view model for KpiPublisherErrorComponent
     */
    data: IPublisherErrorsKpi;
    meta: IPublishersErrorMetaInfo;

    constructor(data: IPublisherErrorsKpi[], state: string) {
        let dataByState;
        let clonedDataByState;
        let metaData;
        if (data && state) {
            dataByState = data.find(stateInfo => stateInfo.state === state);
            if (dataByState) {
                clonedDataByState = {
                    state,
                    errors: dataByState.errors.map(x => ({ ...x })),
                };
                const total = dataByState.errors
                    .map((errorInfo: IErrorInfo) => errorInfo.count)
                    .reduce((acc: number, curVal: number) => acc + curVal);
                const errorIndexMap = {};
                dataByState.errors.forEach((errorInfo: IErrorInfo, i: number) => {
                    errorIndexMap[errorInfo.msg_id] = i;
                });
                metaData = { total, errorIndexMap };
            }
        }
        this.data = clonedDataByState || {};
        this.meta = metaData || {};
    }
}
