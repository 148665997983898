import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';

import { ActionWithPayload } from '.';

@Injectable({ providedIn: 'root' })
export class EpjSearchActions {
    static GET_EPJ_SEARCH = '[EPJ-SEARCH] get pos by epj';

    getEpjSearch(): Action {
        return {
            type: EpjSearchActions.GET_EPJ_SEARCH,
        };
    }

    static GET_EPJ_SEARCH_SUCCESS = '[EPJ-SEARCH-SUCCESS] get pos by epj success';

    getEpjSearchSuccess(result: any): ActionWithPayload<any> {
        return {
            type: EpjSearchActions.GET_EPJ_SEARCH_SUCCESS,
            payload: result,
        };
    }
}
