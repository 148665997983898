import { IIntegration, ChatConfig } from '../models/chat-config.model';
import { EChatIntegrationStatus } from '../models/chat-integration-status.enum';
import { EChatIntegration } from '../models/chat-integrations.enum';

export function chatIsActivated(publisher: string, chatConfig: ChatConfig): boolean {
    const chatIntegration = getChatIntegration(publisher, chatConfig);
    return (
        !!chatIntegration &&
        (chatIntegration.status === EChatIntegrationStatus.ACTIVE ||
            chatIntegration.status === EChatIntegrationStatus.PAUSED)
    );
}

export function getChatStatus(publisher: string, chatConfig: ChatConfig): string {
    const chatIntegration = getChatIntegration(publisher, chatConfig);
    return !!chatIntegration && chatIntegration.status;
}

function getChatIntegration(publisher: string, chatConfig: ChatConfig): IIntegration {
    if (!chatConfig || !chatConfig.integrations) return null;
    const integration_type = publisher === 'facebook' ? EChatIntegration.FACEBOOK : publisher;
    return chatConfig.integrations.find(integration => integration.integration_type === integration_type);
}
