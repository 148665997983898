import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';

import { PaymentMethod } from '../models';

import { ActionWithPayload } from '.';

@Injectable({ providedIn: 'root' })
export class PaymentMethodsActions {
    static GET_PAYMENT_METHODS = '[GET_PAYMENT_METHODS] get payment methods reference';

    paymentMethods(data): Action {
        return {
            type: PaymentMethodsActions.GET_PAYMENT_METHODS,
        };
    }

    static GET_PAYMENT_METHODS_SUCCESS = '[GET_PAYMENT_METHODS_SUCCESS] get payment methods reference complete';

    paymentMethodsSuccess(data: PaymentMethod[]): ActionWithPayload<PaymentMethod[]> {
        return {
            type: PaymentMethodsActions.GET_PAYMENT_METHODS_SUCCESS,
            payload: data,
        };
    }
}
