import { InjectionToken } from '@angular/core';

import { Writable } from '@solocal-manager/sirius/support/base-models';

import { ApiConfigOptions } from './api-config-options';
import { SlmEnvironment } from './slm-environment';

export class ApiConfig {
    public readonly serverUrl: string; // e.g. https://wpm.stg.yelsterdigital.com
    public readonly unversioned: string; // e.g. https://wpm.stg.yelsterdigital.com/api
    public readonly versioned: string; // e.g. https://wpm.stg.yelsterdigital.com/api/v1.0
    public readonly consumer: string; // e.g. https://wpm.stg.yelsterdigital.com/api/v1.0/consumer
    public readonly sirius: string; // e.g. https://wpm.stg.yelsterdigital.com/api/v1.0/sirius
    public readonly siriusProxy: string; // e.g. https://wpm.stg.yelsterdigital.com/api/v1.0/sirius/proxy

    public readonly solocalUrl: string;
    public readonly apiAzureUrl: string;
    public readonly slpReviewsUrl: string;
    public readonly slpPostsUrl: string;
    public readonly clicRDVUrl: string;
    public readonly casUrl: string;
    public readonly crmBackEndBaseUrl: string;
    public readonly crmApiCoreBaseUrl: string;

    constructor(options: ApiConfigOptions) {
        this.updateSlmBackend(options);

        this.solocalUrl = options.solocalUrl;
        this.apiAzureUrl = options.apiAzureUrl;
        this.slpReviewsUrl = options.slpReviewsUrl;
        this.slpPostsUrl = options.slpPostsUrl;
        this.clicRDVUrl = options.clicRDVUrl;
        this.casUrl = options.casUrl;
        this.crmBackEndBaseUrl = options.crmBackEndBaseUrl;
        this.crmApiCoreBaseUrl = options.crmApiCoreBaseUrl;
    }

    get solocalApisBaseUrls(): Array<string> {
        return [this.serverUrl, this.slpReviewsUrl, this.slpPostsUrl, this.crmBackEndBaseUrl, this.crmApiCoreBaseUrl];
    }

    static removeTrailingSlash(path = ''): string {
        return path.replace(/\/$/, '');
    }

    updateSlmBackend(options: ApiConfigOptions): void {
        const {
            serverUrl,
            apiBaseRoute = 'api',
            version = 'v1.0',
            consumerRoute = 'consumer',
            siriusRoute = 'sirius',
            siriusProxyRoute = 'proxy',
        } = options;

        (<Writable<ApiConfig>>this).serverUrl = `${ApiConfig.removeTrailingSlash(serverUrl)}`;
        (<Writable<ApiConfig>>this).unversioned = `${this.serverUrl}/${ApiConfig.removeTrailingSlash(apiBaseRoute)}`;
        (<Writable<ApiConfig>>this).versioned = `${this.unversioned}/${ApiConfig.removeTrailingSlash(version)}`;
        (<Writable<ApiConfig>>this).consumer = `${this.versioned}/${ApiConfig.removeTrailingSlash(consumerRoute)}`;
        (<Writable<ApiConfig>>this).sirius = `${this.versioned}/${ApiConfig.removeTrailingSlash(siriusRoute)}`;
        (<Writable<ApiConfig>>this).siriusProxy = `${this.sirius}/${ApiConfig.removeTrailingSlash(siriusProxyRoute)}`;
    }
}

export const createApiConfig = (environment: SlmEnvironment): ApiConfig =>
    new ApiConfig({
        serverUrl: environment?.apiBase,
        solocalUrl: environment?.solocalUrl,
        apiAzureUrl: environment?.apiAzureUrl,
        slpReviewsUrl: environment?.slpReviewsUrl,
        slpPostsUrl: environment?.slpPostsUrl,
        clicRDVUrl: environment?.clicRDVUrl,
        casUrl: environment?.casUrl,
        crmBackEndBaseUrl: environment?.crmBackEndBaseUrl,
        crmApiCoreBaseUrl: environment?.crmApiCoreBaseUrl,
    });

export const API_CONFIG = new InjectionToken<ApiConfig>('API_CONFIG');
