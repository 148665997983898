import { createReducer, on } from '@ngrx/store';

import { getPagesSuccess } from '@solocal-manager/sirius/core/actions';

export type PagesListState = any;

const initialState: PagesListState = [];

export const pagesListReducer = createReducer(
    initialState,
    on(getPagesSuccess, (state, action) => [...state, ...[action.result]]),
);
