import { Injectable } from '@angular/core';
import { KeycloakOptions } from 'keycloak-angular';
import Keycloak, { KeycloakLoginOptions } from 'keycloak-js';
import { addThemeParam } from './auth-helper';

@Injectable({
    providedIn: 'root',
})
export class SlmAuthFacade {
    public keycloak: Keycloak;

    init(options?: KeycloakOptions): Promise<boolean> {
        this.keycloak = new Keycloak(options.config);
        this.overrideCreateLoginUrl();
        return this.keycloak.init(options.initOptions);
    }

    private overrideCreateLoginUrl() {
        const originalCreateLoginUrl = this.keycloak.createLoginUrl;
        this.keycloak.createLoginUrl = options => {
            const loginUrl = originalCreateLoginUrl.call(this, options);

            return addThemeParam(loginUrl);
        };
    }

    hasToken(): boolean {
        return !this.keycloak.isTokenExpired();
    }

    async getToken(): Promise<string> {
        return this.keycloak.token;
    }

    logout(redirectUri?: string): Promise<void> {
        return this.keycloak.logout({ redirectUri });
    }

    getUserRoles(realmRoles = true, resource?: string): string[] {
        let roles: string[] = [];

        if (this.keycloak.resourceAccess) {
            Object.keys(this.keycloak.resourceAccess).forEach(key => {
                if (resource && resource !== key) {
                    return;
                }

                const resourceAccess = this.keycloak.resourceAccess[key];
                const clientRoles = resourceAccess['roles'] || [];
                roles = roles.concat(clientRoles);
            });
        }

        if (realmRoles && this.keycloak.realmAccess) {
            const realmRoles = this.keycloak.realmAccess['roles'] || [];
            roles.push(...realmRoles);
        }

        return roles;
    }

    login(options: KeycloakLoginOptions): Promise<void> {
        return this.keycloak.login(options);
    }

    register(options: KeycloakLoginOptions): Promise<void> {
        return this.keycloak.register(options);
    }

    isAuthenticated(): boolean {
        return this.keycloak.authenticated;
    }
}
