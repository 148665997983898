import { Injectable } from '@angular/core';
import { PermissionService, PermissionStrategy } from '@slm/permission';
import { map, Observable } from 'rxjs';

import { OnBehalfOptions } from '../models/on-behalf-options';

@Injectable({
    providedIn: 'root',
})
export class OnBehalfService {
    constructor(private readonly permission: PermissionService) {}

    getOnBehalfOptions(params: URLSearchParams): Observable<OnBehalfOptions> {
        const onBehalfOptions = this.extractOnBehalfOptionsFromParams(params);

        return this.permission
            .hasPermission(PermissionStrategy.CanReadOnBehalf)
            .pipe(map(hasPermission => (hasPermission ? onBehalfOptions : {})));
    }

    extractOnBehalfOptionsFromParams(params: URLSearchParams): OnBehalfOptions {
        const epj: string = params.get('onbehalfEpj');
        const email = params.get('onbehalfEmail');

        if (epj) {
            return { epj };
        }

        if (email) {
            return { email };
        }

        return {};
    }

    canWriteOnBehalf(): Observable<boolean> {
        return this.permission.hasPermission(PermissionStrategy.HasUserRole, { role: 'slm_backoffice-user' });
    }
}
