import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';

import { LocationsSelectors } from '@slm/location/state';
import { Location } from '@solocal-manager/sirius/support/base-models';

import {
    GetChatConfig,
    GetChatConfigError,
    GetChatConfigSuccess,
    GetChatConversations,
    GetChatConversationsError,
    GetChatConversationsSuccess,
    GetMultiLocationChatInfos,
    GetMultiLocationChatInfosError,
    GetMultiLocationChatInfosSuccess,
    PutChatConfig,
    PutChatConfigError,
    PutChatConfigSuccess,
    PutChatTemporaryDeactivationConfig,
    PutChatTemporaryDeactivationConfigError,
    PutChatTemporaryDeactivationConfigSuccess,
    PutChatUnreadCount,
    ResetChatConversations,
    PutMultiLocationChatActivation,
    GetMultiLocationChatSuccess,
    PostGbmWizardSuccess,
    GetGbmWizardSuccess,
} from '../actions';
import {
    ChatConfig,
    createChatConfigViewModel,
    IChatLocation,
    IIntegration,
    IPaginationConversation,
    IEpjMultiLocation,
    GbmWizard,
    IChatLocationList,
} from '../models';

export interface IChatState {
    loaded: boolean;
    loading: boolean;
    chatConfig: ChatConfig;
    loadingPutChatConfig: boolean;
    conversations: IPaginationConversation;
    oauthUrlChange: boolean;
    loadingPutTemporaryChatConfigSuccess: boolean;
    chatLocationList: IChatLocationList;
    gbmWizardData: GbmWizard;
}

export const initialState: IChatState = {
    loaded: false,
    loading: false,
    loadingPutChatConfig: false,
    chatConfig: null,
    conversations: null,
    oauthUrlChange: false,
    loadingPutTemporaryChatConfigSuccess: false,
    chatLocationList: null,
    gbmWizardData: null,
};

export const chatReducer = createReducer(
    initialState,
    on(GetChatConfig, state => {
        return {
            ...state,
            chatConfig: null,
            loading: true,
            oauthUrlChange: false,
            loadingPutTemporaryChatConfigSuccess: false,
        };
    }),
    on(GetChatConfigSuccess, (state, { payload }) => {
        return {
            ...state,
            chatConfig: payload,
            loading: false,
            loaded: true,
            oauthUrlChange: oauthUrlChange(state.chatConfig, payload),
            chatLocationList: updateChatLocationList(payload, state.chatLocationList),
        };
    }),
    on(GetChatConfigError, state => {
        return {
            ...state,
            chatConfig: null,
            loading: false,
            loaded: true,
        };
    }),
    on(PutChatConfig, state => {
        return {
            ...state,
            loading: true,
            loadingPutChatConfig: true,
        };
    }),
    on(PutChatConfigSuccess, (state, { payload }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            loadingPutChatConfig: false,
            chatLocationList: updateChatLocationList(payload, state.chatLocationList),
        };
    }),
    on(PutChatConfigError, state => {
        return {
            ...state,
            chatConfig: null,
            loading: false,
            loaded: true,
            loadingPutChatConfig: false,
        };
    }),
    on(ResetChatConversations, state => {
        return {
            ...state,
            conversations: null,
            loading: true,
        };
    }),
    on(GetChatConversations, state => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(GetChatConversationsSuccess, (state, { payload }) => {
        return {
            ...state,
            conversations: payload,
            loading: false,
            loaded: true,
        };
    }),
    on(GetChatConversationsError, state => {
        return {
            ...state,
            conversations: null,
            loading: false,
            loaded: true,
        };
    }),
    on(PutChatUnreadCount, (state, { payload }) => {
        return {
            ...state,
            conversations: {
                ...state?.conversations,
                meta: {
                    ...state?.conversations?.meta,
                    total_unread: state?.conversations?.meta.total_unread - payload,
                },
            },
        };
    }),
    on(PutChatTemporaryDeactivationConfig, state => {
        return {
            ...state,
            loading: true,
            loadingPutTemporaryChatConfigSuccess: false,
        };
    }),
    on(PutChatTemporaryDeactivationConfigSuccess, (state, { payload }) => {
        return {
            ...state,
            loading: false,
            loadingPutTemporaryChatConfigSuccess: true,
            chatLocationList: updateChatLocationList(payload, state.chatLocationList),
        };
    }),
    on(PutChatTemporaryDeactivationConfigError, state => {
        return {
            ...state,
            loading: false,
            loadingPutTemporaryChatConfigSuccess: false,
        };
    }),
    on(GetMultiLocationChatInfos, state => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(GetMultiLocationChatInfosSuccess, (state, { payload }) => {
        return {
            ...state,
            loading: false,
            chatLocationList: updateLocationList(state.chatLocationList, payload),
        };
    }),
    on(GetMultiLocationChatInfosError, state => {
        return {
            ...state,
            loading: false,
            chatLocationList: null,
        };
    }),
    on(PutMultiLocationChatActivation, state => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(GetMultiLocationChatSuccess, (state, { payload }) => {
        return {
            ...state,
            loading: false,
            chatLocationList: updateMultiLocationList(state.chatLocationList, payload),
        };
    }),
    on(PostGbmWizardSuccess, GetGbmWizardSuccess, (state, { payload }) => {
        return {
            ...state,
            gbmWizardData: payload,
        };
    }),
);

///
/// Methods
/// !!!! refactoring - put all logic from reducer to effect !!!!!
/**
 * We update the multiLocationList field after updated of the chat config without request again the endpoint
 * @param chatConfig
 * @param multiLocationList
 * @returns
 */
function updateChatLocationList(chatConfig: ChatConfig, chatLocationList: IChatLocationList): IChatLocationList {
    if (!chatLocationList || chatLocationList.data.length === 0 || !chatConfig) {
        return chatLocationList;
    } else {
        return {
            ...chatLocationList,
            data: chatLocationList.data.map(loc =>
                loc.epj === chatConfig.epj
                    ? {
                          ...loc,
                          status: chatConfig.status,
                          integrations: chatConfig.integrations
                              ? transformChatConfigIntegrations(
                                    chatConfig.integrations,
                                    loc.integrations.map(int => int.integration_code),
                                )
                              : [],
                          paused_from: chatConfig.paused_from,
                          paused_to: chatConfig.paused_to,
                      }
                    : loc,
            ),
        };
    }
}

function updateMultiLocationList(chatLocationList: IChatLocationList, epjs: IEpjMultiLocation[]): IChatLocationList {
    if (!chatLocationList || chatLocationList.data.length === 0 || !epjs || epjs.length === 0) return chatLocationList;
    else {
        return {
            ...chatLocationList,
            data: chatLocationList.data.map(loc => getEPJLocations(loc, epjs)),
        };
    }
}

function updateLocationList(chatLocationList: IChatLocationList, payload: IChatLocationList) {
    if (!(chatLocationList && payload)) return payload;
    if (
        payload.data.some(
            pom => pom.location_id === chatLocationList.data[chatLocationList.data.length - 1].location_id,
        )
    )
        return chatLocationList;
    return {
        ...chatLocationList,
        data: chatLocationList.data.concat(payload.data),
    };
}

function getEPJLocations(loc, epjs): IChatLocation {
    const epj = epjs.filter(item => item.epj === loc.epj);
    if (epj.length > 0 && epj[0]) {
        const inter = loc.integrations.map(item => {
            if (item.integration_code === 'pagesjaunes') {
                return {
                    integration_code: item.integration_code,
                    status: epj[0].pj_status,
                };
            } else {
                return {
                    integration_code: item.integration_code,
                    status: item.status,
                };
            }
        });

        return {
            status: epj[0].status,
            integrations: inter,
            paused_from: epj[0].paused_from,
            paused_to: epj[0].paused_to,
            location: loc.location,
            epj: loc.epj,
            location_id: loc.location_id,
            name: loc.name,
            alias: loc.alias,
            conv_count: loc.conv_count,
            conv_unread_count: loc.conv_unread_count,
        };
    } else {
        return loc;
    }
}

function transformChatConfigIntegrations(
    integrations: IIntegration[],
    integrationsList: string[],
): {
    integration_code: string;
    status: string;
}[] {
    const locIntegrations: {
        integration_code: string;
        status: string;
    }[] = [];

    integrations
        .filter(int => integrationsList.includes(int.integration_type))
        .forEach(element => {
            locIntegrations.push({
                integration_code: element.integration_type,
                status: element.status,
            });
        });

    return locIntegrations;
}

/**
 * Selectors
 */
/**
 * @function getConversations State
 * @description Retrieve list of Conversations for a location related to authenticated user
 */
export const getConversations = (state: any) => state.chat.conversations;

export const selectChat = createFeatureSelector<IChatState>('chat');

/**
 * @function getChatConfig State
 * @description Retrieve Chat Configuration for a location related to the authenticated user
 */
export const getChatConfig = createSelector(selectChat, obj => obj.chatConfig);

export const countUnreadMessages = createSelector(selectChat, obj => {
    return obj.conversations && obj.conversations.meta ? obj.conversations.meta.total_unread : 0;
});

export const getPutChatConfigState = createSelector(selectChat, obj => {
    return obj.loadingPutChatConfig;
});

export const getPutTemporaryChatConfigSucess = createSelector(selectChat, obj => {
    return obj?.loadingPutTemporaryChatConfigSuccess;
});

export const getOAuthUrlChange = createSelector(selectChat, obj => {
    return obj.oauthUrlChange;
});

function oauthUrlChange(chatConfig: ChatConfig, newChatConfig: ChatConfig): boolean {
    const newOauthUrl = newChatConfig?.integrations?.find(page => page.integration_type === 'messenger')?.oauth_url;
    const oldOauthUrl = chatConfig?.integrations?.find(page => page.integration_type === 'messenger')?.oauth_url;

    return oldOauthUrl !== null && !!newOauthUrl && newOauthUrl !== oldOauthUrl;
}

export const getChatConfigOfSelectedLocation = () =>
    createSelector(
        LocationsSelectors.getSelectedLocation,
        getChatConfig,
        (location: Location, chatConfig: ChatConfig) => {
            return createChatConfigViewModel(chatConfig, location);
        },
    );

export const getChatLocationList = createSelector(selectChat, obj => obj.chatLocationList);

export const getGbmWizard = createSelector(selectChat, gbmWizard => gbmWizard.gbmWizardData);
