import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'numberToFixed',
})
export class NumberToFixedPipe implements PipeTransform {
    transform(value: any, toFixed: number): string | number {
        if (!toFixed) {
            toFixed = 2;
        }
        if (!(value instanceof Array) && !isNaN(+value)) {
            return value === parseInt(value) ? value : value.toFixed(toFixed);
        }
        return '';
    }
}
