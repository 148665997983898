import { forwardRef, Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { ControlValueAccessor, NG_ASYNC_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

import { urlsConfig } from '@solocal-manager/sirius/core/core';
import { ValidateUrl } from '@solocal-manager/sirius/core/core';
import { WpmInputCombinedUrlsComponent } from '@solocal-manager/sirius/core/core';

@Component({
    selector: 'app-input-combined-urls',
    templateUrl: './input-combined-urls.component.html',
    styleUrls: ['./input-combined-urls.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputCombinedUrlsComponent),
            multi: true,
        },
        {
            provide: NG_ASYNC_VALIDATORS,
            useExisting: forwardRef(() => InputCombinedUrlsComponent),
            multi: true,
        },
    ],
})
export class InputCombinedUrlsComponent
    extends WpmInputCombinedUrlsComponent
    implements ControlValueAccessor, OnDestroy
{
    buildForm() {
        const controls = {};
        urlsConfig.multipleUrlTypes.forEach(type => {
            controls[type] = [[]];
        });
        urlsConfig.singleUrlTypes.forEach(type => {
            controls[type] = ['', [ValidateUrl.createValidator()]];
        });
        this.combinedUrlsForm = this.formBuilder.group(controls);
    }
}
