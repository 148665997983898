import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@slm/shared/environment';
import { Company } from '@solocal-manager/sirius/core/models';
import { PaginationDTO } from '@solocal-manager/sirius/support/base-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class CompaniesService {
    constructor(
        public http: HttpClient,
        private environmentService: EnvironmentService,
    ) {}

    getCompanies(): Observable<Company[]> {
        const companiesUrl = `${this.environmentService.apiUrl}/consumer/companies?fields=custom_data,location_count`;

        return this.http.get(companiesUrl).pipe(map((result: PaginationDTO<Company>) => result.data));
    }

    getCompany(companyId: string): Observable<Company> {
        const companyUrl = `${this.environmentService.apiUrl}/consumer/companies/${companyId}?fields=custom_data`;

        return this.http.get(companyUrl).pipe(map((result: any) => result.data));
    }
}
