/* eslint-disable no-case-declarations */

import {
    SearchHabilitatedLocationsActionsUnion,
    SearchHabilitatedLocationsActionTypes,
} from '@solocal-manager/sirius/core/actions';
import { SearchHabLocationListState } from '@solocal-manager/sirius/support/base-models';

export const initialState: SearchHabLocationListState = {
    count: 0,
    currentPage: 0,
    current_page: 0,
    data: [],
    initial: true,
    next: null,
    next_page: null,
    previous: null,
    previous_page: null,
};

export function searchHabilitatedLocationsReducer(
    state = initialState,
    action: SearchHabilitatedLocationsActionsUnion,
): SearchHabLocationListState {
    switch (action.type) {
        case SearchHabilitatedLocationsActionTypes.RESET_SEARCH_HAB_LOCATION_LIST:
            return initialState;
        case SearchHabilitatedLocationsActionTypes.SEARCH_HAB_LOCATION_LIST_SUCCESS:
            const currentPage = (action.payload.previous ?? 0) + 1;
            const decoratedPayload: SearchHabLocationListState = {
                ...action.payload,
                initial: false,
                currentPage,
            };

            // Merge new results into current state if pagination was triggered
            return !action.payload.previous_page
                ? decoratedPayload
                : {
                      ...decoratedPayload,
                      data: [...state.data, ...action.payload.data],
                  };
        default:
            return state;
    }
}
