import { Injectable, Injector } from '@angular/core';
import { Params } from '@angular/router';
import { Store } from '@ngrx/store';
import { LocationsSelectors, LocationsState } from '@slm/location/state';
import { INavigationLogic } from '@solocal-manager/sirius/core/models/navigation/interfaces';

import { NavLogicRouteIds } from '@solocal-manager/sirius/core/models/navigation/nav-logic-route-id.enum';
import { NavigationDescriptor } from '@solocal-manager/sirius/core/models/navigation/navigation-descriptor';
import { take } from 'rxjs/operators';

@Injectable()
class PhotosVideosCondNavLogic implements INavigationLogic {
    constructor(private store: Store<{ locations: LocationsState }>) {}

    getEffectiveRoute(routeId: string): NavigationDescriptor {
        if (routeId !== NavLogicRouteIds.PHOTOS_VIDEOS_INFO_CONDITIONAL) {
            throw new Error(`PhotosVideosCondNavLogic is not the right navigation logic for id '${routeId}'`);
        }

        let route: string;
        let queryParams: Params;
        this.store
            .select(LocationsSelectors.getAllLocations)
            .pipe(take(1))
            .subscribe(decoLocations => {
                if (decoLocations?.length > 1) {
                    (route = '/locations'), (queryParams = { selectForNext: NavLogicRouteIds.PHOTOS_VIDEOS_INFO });
                } else {
                    route = '/content/photos';
                    queryParams = {};
                }
            });

        return {
            route,
            queryParams,
        };
    }
}

export function photosVideosInfoCondFact(injector: Injector): INavigationLogic {
    const creationInjector = Injector.create({
        providers: [
            {
                provide: PhotosVideosCondNavLogic,
                useFactory: (store: Store<{ locations: LocationsState }>) => new PhotosVideosCondNavLogic(store),
                deps: [Store],
            },
        ],
        parent: injector,
    });
    return creationInjector.get(PhotosVideosCondNavLogic);
}
