<div [formGroup]="searchDetailsForm">
    <div class="form-group">
        <label class="required-label">{{ 'Location name' | translate }}</label>
        <input
            type="text"
            class="form-control"
            autocomplete="nope"
            placeholder="{{ 'Location name' | translate }}"
            formControlName="name"
        />
        <app-input-error-messages [control]="searchDetailsForm.get('name')"></app-input-error-messages>
    </div>

    <div class="form-group">
        <label [ngClass]="{ 'required-label': requestRights }">{{ 'Location city' | translate }}</label>
        <input
            type="text"
            class="form-control"
            placeholder="{{ 'Location city' | translate }}"
            autocomplete="nope"
            formControlName="city"
        />
    </div>

    <div class="form-group">
        <button class="btn btn-primary pull-right" [disabled]="searchDetailsForm.invalid" (click)="search()">
            <span>{{ 'Search again' | translate }}</span>
        </button>
    </div>
</div>

<table class="table table-striped" *ngIf="results?.length && !searching">
    <thead>
        <tr>
            <th class="col-md-2 text-center" scope="col">
                {{ 'Name' | translate }}
            </th>
            <th class="col-md-1 text-center" scope="col">
                {{ 'Status' | translate }}
            </th>
            <th class="col-md-1 text-center" scope="col">
                {{ 'Website' | translate }}
            </th>
            <th class="col-md-3 text-center" scope="col">
                {{ 'Address' | translate }}
            </th>
            <th class="col-md-1 text-center" scope="col">
                {{ 'Phone number' | translate }}
            </th>
            <th class="col-md-1 text-center" scope="col">
                {{ 'GMB Location' | translate }}
            </th>
            <th class="col-md-3 text-center" scope="col">
                {{ 'Action' | translate }}
            </th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let result of results">
            <td class="col-md-2 text-center">{{ result.name || '-' }}</td>
            <td class="col-md-1">{{ result.publisher_page_state }}</td>
            <td class="col-md-1 text-center">
                <a *ngIf="result.website" [href]="result.website" target="blank">{{ 'link' | translate }}</a>
            </td>
            <td class="col-md-3 text-center">
                <div>{{ getAddress(result, 1) || '-' }}</div>
                <div *ngIf="getAddress(result)">
                    {{ getAddress(result, 2) }}
                </div>
            </td>
            <td class="col-md-1 text-center">{{ result.phone || '-' }}</td>
            <td class="col-md-1 text-center">
                <a *ngIf="result.admin_url" [href]="result.admin_url" target="blank">{{ 'link' | translate }}</a>
            </td>
            <td class="col-md-3 text-center">
                <button
                    *ngIf="requestRights && !isClaimEnabled(result)"
                    class="btn btn-secondary publisher-claim-page-btn"
                    (click)="openRequestRightsPage(result)"
                >
                    {{ 'Request rights' | translate }}
                </button>

                <button
                    *ngIf="
                        (!requestRights || isClaimEnabled(result)) &&
                        result.publisher_page_state !== 'duplicate' &&
                        !result.connected_to
                    "
                    class="btn btn-primary publisher-claim-page-btn"
                    (click)="connect(result.publisher_page_id)"
                >
                    <span *ngIf="requestRights">{{ 'Claimed' | translate }}</span>
                    <span *ngIf="!requestRights">{{ 'Connect' | translate }}</span>
                </button>
                <button
                    *ngIf="!requestRights && result.publisher_page_state === 'duplicate'"
                    class="btn btn-primary publisher-delete-duplicate-btn"
                    (click)="deleteDuplicate(result.publisher_page_id)"
                >
                    <span>{{ 'Delete' | translate }}</span>
                </button>

                <div *ngIf="result.connected_to">
                    <span
                        >{{ 'This Gmb page is already connected to this or to another point of sale' | translate }} ({{
                            result.connected_to_partner_id || result.connected_to
                        }})</span
                    >
                    <span>{{ '. It is not allowed to have the same Gmb page connected' | translate }}</span>
                    <span>{{ ' to two different Prolive accounts nor to reconnect it twice.' | translate }}</span>
                    <span>{{ 'Please enter another link, thank you.' | translate }}</span>
                </div>
            </td>
        </tr>
    </tbody>
</table>

<p *ngIf="searching" class="text-center">{{ 'searching...' | translate }}</p>

<p *ngIf="results?.length === 0" class="text-center">
    {{ 'No results for given search parameters' | translate }}
</p>
