<div
    bsModal
    #connectModal="bs-modal"
    class="modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="mySmallModalLabel"
    aria-hidden="true"
>
    <div class="modal-dialog modallg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal(false)">
                    <span class="close-x" aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <slm-publisher-connect
                    [location]="currentLocation"
                    [publisherId]="publisherId"
                    [autoSearch]="autoSearch[publisherId]"
                    (resetPublisherId)="onResetPublisherId($event)"
                    (close)="closeModal($event)"
                    [lastDisconnected]="lastDisconnected"
                ></slm-publisher-connect>
            </div>
        </div>
    </div>
</div>

<ng-template #toggleTooltip>
    {{ 'Toggle Publishing Status on/off without disconnecting Pro.live with the Publisher' | translate }}
</ng-template>

<table class="table">
    <thead>
        <tr>
            <th class="pub-publisher" scope="col">
                {{ 'Publisher' | translate }}
            </th>
            <th class="pub-status" scope="col">{{ 'Status' | translate }}</th>
            <th class="pub-status-chat" scope="col">
                {{ 'Status Chat' | translate }}
            </th>
            <th class="toggle-switch-thead-cell" scope="col">
                {{ 'Turn on/off' | translate }}
                <span
                    [tooltip]="toggleTooltip"
                    placement="bottom"
                    class="glyphicon glyphicon-question-sign toggle-switch-tooltip"
                ></span>
            </th>
            <th class="pub-view" scope="col"></th>
            <th class="pub-actions" scope="col">{{ 'Actions' | translate }}</th>
            <th *ngIf="navadsDisconnect" class="pub-disconnect" scope="col"></th>
        </tr>
    </thead>
    <tbody>
        <tr
            slm-app-publisher-status-list-item
            *ngFor="let publisherStatus of publisherStatusList"
            [location]="location"
            [publisherStatus]="publisherStatus"
            [selfOnboardingFlag]="isSelfOnboardedPage(publisherStatus)"
            [canConnect]="canConnect(publisherStatus)"
            [canDisconnect]="canDisconnect(publisherStatus)"
            [navadsRowsCount]="getNavadsRowsCount(publisherStatus)"
            (publisherStatusSelected)="onPublisherStatusSelect($event)"
            (ableToChangeConnection)="onAbleToChangeConnection($event)"
            (publisherId)="changedPublisherId($event)"
            (disconnection)="onDisconnection($event)"
            [chatConfig]="chatConfig$ | async"
            [navadsDisconnect]="navadsDisconnect"
        ></tr>
    </tbody>
</table>
