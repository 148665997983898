import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

import { Conversation, ConversationMetadata, EChatIntegration, Message } from '../models';

@Component({
    template: '',
})
export class BaseChatListItemComponent {
    @Input() conversation: Conversation;
    @Input() appUserId: string;
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() public onClick: EventEmitter<any> = new EventEmitter();

    constructor(protected translateService: TranslateService) {}

    getDate(): string {
        if (this.conversation?.messages?.length) {
            let date = moment(this.latestMessage?.date);
            if (this.latestMessage?.received) {
                date = moment(this.latestMessage?.received * 1000);
            }
            return date.fromNow();
        }
    }

    handleClick(): void {
        this.onClick.emit(true);
        if (this.isUnread) {
            this.handleReadConversation();
        }
    }

    handleReadConversation(): void {
        const participant = this.conversation?.participants?.find(part => part.userId === this.appUserId);
        if (participant) participant.unreadCount = 0;
    }

    getAnonmymousUser(type: EChatIntegration): string {
        switch (type) {
            case EChatIntegration.FACEBOOK:
                return 'conversation_item:anonyme_user_facebook';
            case EChatIntegration.GOOGLE:
                return 'conversation_item:anonyme_user_google';
            case EChatIntegration.PAGESJAUNES:
                return 'conversation_item:anonyme_user_pagesjaunes';
            default:
                return 'conversation_item:anonyme_user';
        }
    }

    get hasIntegrationType(): boolean {
        return !!this.metadata?.integration_type;
    }

    get metadata(): ConversationMetadata {
        return !!this.conversation && this.conversation.metadata;
    }

    get username(): string {
        return this.hasUserName
            ? `${this.metadata?.origin_forename || ''} ${this.metadata?.origin_surname || ''}`
            : this.getAnonmymousUser(this.conversation.metadata.integration_type);
    }

    get isPJUser(): boolean {
        return this.metadata?.integration_type === EChatIntegration.PAGESJAUNES;
    }

    get hasUserName(): boolean {
        return !!this.metadata?.origin_forename || !!this.metadata?.origin_surname;
    }

    get isUnread(): boolean {
        return (
            !!this.conversation?.participants &&
            !!this.conversation.participants.find(part => part.userId === this.appUserId) &&
            this.conversation.participants.find(part => part.userId === this.appUserId).unreadCount > 0
        );
    }

    get latestMessage(): Message {
        if (!this.conversation?.messages?.length) return;
        const latestMessage = this.conversation?.messages.reduce((a, b) => (a.received > b.received ? a : b));
        if (latestMessage.metadata && typeof latestMessage.metadata === 'string') {
            // Smooch Web SDK Android sent metadata as string
            latestMessage.metadata = JSON.parse(latestMessage.metadata);
        }
        if (
            latestMessage?.metadata &&
            latestMessage.metadata.code === 'blocked' &&
            latestMessage.metadata.integration_type === 'messenger' &&
            latestMessage.metadata.event === 'deliveryFailure' &&
            !!latestMessage.metadata.system
        ) {
            latestMessage.text = this.translateService.instant('smooch_delegate:message_blocked_fb24h');
        }
        return latestMessage;
    }
}
