import { createReducer, on } from '@ngrx/store';

import { getAvailablePublishersSuccess } from '@solocal-manager/sirius/core/actions';
import { Publisher } from '@solocal-manager/sirius/core/models';

export type AvailablePublisherState = Publisher[];

export const initialState: AvailablePublisherState = [];

export const availablePublisherReducer = createReducer(
    initialState,
    on(getAvailablePublishersSuccess, (state, action) => action.result),
);
