<slm-header [disableSearch]="true"></slm-header>

<div class="graph-overlay">
    <div class="container-fluid admin-search-content">
        <div class="row">
            <div class="col-md-12">
                <h1 class="center-block w-40 text-center admin-search-title">
                    {{ 'Pro.live Backoffice' | translate }}
                </h1>
                <div class="center-block w-40 text-center search-page-multi-search-container">
                    <slm-multi-search
                        [locationOnly]="true"
                        (moveLocationChanged)="locationSelection($event)"
                    ></slm-multi-search>
                </div>
            </div>
        </div>

        <div class="admin-search-footer col-md-12">
            <div class="row">
                <div class="col-md-12">
                    <app-kpi-widget-container></app-kpi-widget-container>
                </div>
            </div>
            <div class="admin-search-copyright">
                &copy;
                {{ '2017 Solocal Group. All Rights Reserved' | translate }}
            </div>
            <div class="admin-search-powered-by">
                <span>
                    <p>{{ 'Service powered by' | translate }}</p>
                    <img
                        class="admin-search-footer-logo"
                        src="assets/images/logo-solocal-white.png"
                        alt="solocal logo"
                    />
                </span>
            </div>
        </div>
    </div>
</div>
