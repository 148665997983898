import { PageListActionsUnion, PageListActionTypes } from '@solocal-manager/sirius/core/actions';
import { Page } from '@solocal-manager/sirius/support/base-models';

export type PageListState = Page[];

export const initialState: PageListState = [];

export function pageListReducer(state = initialState, action: PageListActionsUnion): PageListState {
    switch (action.type) {
        case PageListActionTypes.GET_PAGE_LIST_SUCCESS: {
            return action.result;
        }
        case PageListActionTypes.RESET_PAGE_LIST: {
            return initialState;
        }
        default:
            return state;
    }
}
