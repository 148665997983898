import { BehaviorSubject, Observable } from 'rxjs';

import { imagesDimensions, imageErrorConfig, ImageErrorConfig } from '@solocal-manager/sirius/core/config';
import { IImageRules } from '@solocal-manager/sirius/core/models/images-rules.model';
import { ImageDimensionFormatSize, ImageErrorTypes } from '@solocal-manager/sirius/support/base-models';

export abstract class BaseImageService {
    protected _imageErrorType$: BehaviorSubject<ImageErrorTypes[]> = new BehaviorSubject<ImageErrorTypes[]>(null);

    get imageErrorTypes$(): Observable<ImageErrorTypes[]> {
        return this._imageErrorType$.asObservable();
    }

    get imageErrorConfig(): ImageErrorConfig[] {
        return imageErrorConfig;
    }

    protected _imageDimensions$: BehaviorSubject<IImageRules> = new BehaviorSubject<IImageRules>(null);
    get imageDimensions$(): Observable<IImageRules> {
        return this._imageDimensions$.asObservable();
    }

    protected abstract getDimensions(
        url: string,
    ): Promise<{ width: number; height: number; src: string; size: number }>;

    /**
     * Checks if an image size is valid
     */
    protected checkImageDimensions(type: string, image: ImageDimensionFormatSize): boolean {
        const rules = this._imageDimensions$.value;
        const matchesMinimum = this.getImageMinimumDimensions(image, rules.minWidth, rules.minHeight);
        const matchesMaximum = this.getImageMaximumDimensions(image, rules.maxWidth, rules.maxHeight);
        return matchesMinimum && matchesMaximum;
    }

    /**
     * Checks if an image format is valid
     */
    protected checkImageFormat(type: string, url: string): boolean {
        const rules = this._imageDimensions$.value;
        return this.matchesFormats(url.toLowerCase(), rules.formats);
    }

    /**
     * Return weather an an image matches the minimum requirements
     */
    protected getImageMinimumDimensions(
        image: ImageDimensionFormatSize,
        ruleMinWidth: number,
        ruleMinHeight: number,
    ): boolean {
        const minWidth = !ruleMinWidth ? image.width : ruleMinWidth;
        const minHeight = !ruleMinHeight ? image.height : ruleMinHeight;
        return !(image.width < minWidth || image.height < minHeight);
    }

    /**
     * Return weather an an image matches the maximum requirements
     */
    protected getImageMaximumDimensions(
        image: ImageDimensionFormatSize,
        rulesMaxWidth: number,
        rulesMaxHeight: number,
    ): boolean {
        const maxWidth = !rulesMaxWidth ? image.width : rulesMaxWidth;
        const maxHeight = !rulesMaxHeight ? image.height : rulesMaxHeight;
        return !(image.width > maxWidth || image.height > maxHeight);
    }

    /**
     * Checks if image's format is acceptable
     */
    matchesFormats(url: string, formats: string[]): boolean {
        const formatRegex = new RegExp(formats.join('|'), 'i');
        return formatRegex.test(url);
    }

    isPortrait(imgDims: { id: string; width: number; height: number }): boolean {
        return imgDims.width / imgDims.height < 1;
    }

    isLandscape(imgDims: { id: string; width: number; height: number }): boolean {
        return !this.isPortrait(imgDims);
    }

    isFileURI(str: string): boolean {
        return str?.startsWith('file://');
    }

    validateImage(type: string, image: ImageDimensionFormatSize): boolean {
        const validateImageDimension = this.checkImageDimensions(type, image);
        const validateImageFormat = this.checkImageFormat(type, image.formatType);
        const validateFileSize = !image.size ? true : this.isFileSizeValid(type, image.size);

        if (validateImageDimension && validateImageFormat && validateFileSize) {
            return true;
        }

        if (!validateImageDimension) this.setImageErrors('dimension', image.name);
        else if (!validateImageFormat) this.setImageErrors('format', image.name);
        else if (!validateFileSize) this.setImageErrors('filesize', image.name);
        return false;
    }

    //type parameter initialy it will be null in case if we want to translation keys with type of the image we can set the type
    protected setImageErrors(errorType: string, name: string, type?: string): void {
        if (this._imageErrorType$.value) {
            this._imageErrorType$.value.push({
                name: name,
                type: type,
                errorType: errorType,
            });
        } else {
            this._imageErrorType$.next([{ name: name, type: type, errorType: errorType }]);
        }
    }

    isFileSizeValid(type: string, fileSize: number): boolean {
        const rules = imagesDimensions[type];
        return fileSize >= rules.minFileSize && fileSize <= rules.maxFileSize;
    }

    clearImageErrors(): void {
        this._imageErrorType$.next(null);
    }

    setDimensions(type: string, imageDimension?: IImageRules): void {
        const rules = Object.assign({}, imagesDimensions[type]);
        if (imageDimension) {
            rules.minWidth = imageDimension.minWidth || rules?.minWidth;
            rules.minHeight = imageDimension.minHeight || rules?.minHeight;
            rules.maxHeight = imageDimension.maxHeight || rules?.maxHeight;
            rules.maxWidth = imageDimension.maxWidth || rules?.maxWidth;
            rules.minFileSize = imageDimension.minFileSize || rules?.minFileSize;
        }
        this._imageDimensions$.next(rules);
    }

    getImageElement(file: File): Observable<any> {
        const fReader = new FileReader();
        const img = new Image();

        return new Observable((observer: any) => {
            fReader.readAsDataURL(file);
            fReader.onload = () => {
                img.src = fReader.result as string;
                if (file.type === 'image/tiff') {
                    observer.next(img);
                    observer.complete();
                } else {
                    img.onload = () => {
                        observer.next(img);
                        observer.complete();
                    };
                }
            };
        });
    }
}
