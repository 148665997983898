import { Injectable } from '@angular/core';
import { ofType, Actions, createEffect } from '@ngrx/effects';
import { ApiErrorActions } from '@slm/shared/error';
import {
    DeleteUploadedVideo,
    DeleteUploadedVideoSuccess,
    GalleryActionTypes,
    GetImageList,
    GetImageListSuccess,
    GetUploadedVideoList,
    GetUploadedVideoListSuccess,
    PatchUploadedVideo,
    PatchUploadedVideoSuccess,
} from '@solocal-manager/sirius/core/actions';
import { GalleryService } from '@solocal-manager/sirius/core/services';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class GalleryEffects {
    constructor(
        private update$: Actions,
        private galleryService: GalleryService,
    ) {}

    imageList$ = createEffect(() =>
        this.update$.pipe(
            ofType<GetImageList>(GalleryActionTypes.GET_IMAGE_LIST),
            map((action: any) => action.payload),
            switchMap(payload =>
                this.galleryService
                    .getImageList(
                        payload.companyId,
                        payload.pageNumber,
                        payload.pageSize,
                        payload.imageType,
                        payload.minHeight,
                        payload.minWidth,
                        payload.maxHeight,
                        payload.maxWidth,
                        payload.locationIds,
                    )
                    .pipe(
                        map(results => new GetImageListSuccess(results)),
                        catchError(error => of(new ApiErrorActions.CatchApiError(error))),
                    ),
            ),
        ),
    );

    uploadedVideoList$ = createEffect(() =>
        this.update$.pipe(
            ofType<GetUploadedVideoList>(GalleryActionTypes.GET_UPLOADED_VIDEO_LIST),
            map(action => action.params),
            switchMap(params =>
                this.galleryService.getUploadedVideoList(params.companyId, params.pageNumber, params.pageSize).pipe(
                    map(result => new GetUploadedVideoListSuccess(result)),
                    catchError(error => of(new ApiErrorActions.CatchApiError(error))),
                ),
            ),
        ),
    );

    patchUploadedVideo$ = createEffect(() =>
        this.update$.pipe(
            ofType<PatchUploadedVideo>(GalleryActionTypes.PATCH_UPLOADED_VIDEO),
            map(action => action.params),
            switchMap(params =>
                this.galleryService.patchUploadedVideo(params.uploadedVideoId, params.title, params.description).pipe(
                    map(
                        result =>
                            new PatchUploadedVideoSuccess({
                                video: result,
                                pageSize: params.pageSize,
                                pageNumber: params.pageNumber,
                            }),
                    ),
                    catchError(error => of(new ApiErrorActions.CatchApiError(error))),
                ),
            ),
        ),
    );

    deleteUploadedVideo$ = createEffect(() =>
        this.update$.pipe(
            ofType<DeleteUploadedVideo>(GalleryActionTypes.DELETE_UPLOADED_VIDEO),
            map(action => action.params),
            switchMap(params =>
                this.galleryService.deleteUploadedVideo(params.uploadedVideoId).pipe(
                    map(
                        () =>
                            new DeleteUploadedVideoSuccess({
                                pageSize: params.pageSize,
                                pageNumber: params.pageNumber,
                            }),
                    ),
                    catchError(error => of(new ApiErrorActions.CatchApiError(error))),
                ),
            ),
        ),
    );

    patchUploadedVideoSuccess$ = createEffect(() =>
        this.update$.pipe(
            ofType<PatchUploadedVideoSuccess>(GalleryActionTypes.PATCH_UPLOADED_VIDEO_SUCCESS),
            map(action => action.result),
            map(
                result =>
                    new GetUploadedVideoList({
                        pageSize: result.pageSize,
                        pageNumber: result.pageNumber,
                    }),
            ),
        ),
    );

    deleteUploadedVideoSucess$ = createEffect(() =>
        this.update$.pipe(
            ofType<DeleteUploadedVideoSuccess>(GalleryActionTypes.DELETE_UPLOADED_VIDEO_SUCCESS),
            map(action => action.result),
            map(
                result =>
                    new GetUploadedVideoList({
                        pageSize: result.pageSize,
                        pageNumber: result.pageNumber,
                    }),
            ),
        ),
    );
}
