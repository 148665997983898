<ul class="nav navbar-nav navbar-right">
    <li class="dropdown" id="burger" dropdown (click)="menuToggle()">
        <span class="burger-bars"></span>
        <span class="burger-bars"></span>
        <span class="burger-bars"></span>
        <span class="burger-bars"></span>
        <a
            dropdownToggle
            class="dropdown-toggle"
            data-toggle="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
        >
        </a>
        <ul *dropdownMenu class="dropdown-menu">
            <li role="menuitem">
                <a class="dropdown-item">{{ user?.email }}</a>
                <span class="glyphicon glyphicon-user"></span>
            </li>
            <li class="divider dropdown-divider"></li>
            <li role="menuitem" (click)="goHome()">
                <a class="dropdown-item">{{ 'Home' | translate }}</a>
                <span class="glyphicon glyphicon-home"></span>
            </li>
            <li class="divider dropdown-divider"></li>
            <li role="menuitem" (click)="switchLanguage('en')">
                <a class="dropdown-item">{{ 'en' | translate }}</a>
                <span class="glyphicon glyphicon-flag"></span>
            </li>
            <li role="menuitem" (click)="switchLanguage('fr')">
                <a class="dropdown-item">{{ 'fr' | translate }}</a>
                <span class="glyphicon glyphicon-flag"></span>
            </li>
            <li class="divider dropdown-divider"></li>
            <li role="menuitem" (click)="logout()">
                <a class="dropdown-item">{{ 'Logout' | translate }}</a>
                <span class="glyphicon glyphicon-log-out"></span>
            </li>
        </ul>
    </li>
</ul>
