import { createAction, props } from '@ngrx/store';

import { EGFBTypes } from '@solocal-manager/sirius/core/models';
import { IAPInitGFB, IAPPatchGFB, IAPSetFeatureGFB, IAPSetListGFB } from '@solocal-manager/sirius/core/models';

export enum GFBActionTypes {
    Init = '[GFB] Init',
    InitSuccess = '[GFB] Init success',
    InitFailed = '[GFB] Init sailed',

    SetActive = '[GFB] Set active feature',
    ResetActive = '[GFB] Reset active feature',

    SetActiveEPJ = '[GFB] Set active EPJ',

    OpenSnack = '[GFB] Open snack bar event',
    CloseSnack = '[GFB] Close snack bar event',
    ShowSnack = '[GFB] Show snackbar',
    HideSnack = '[GFB] Hide snackbar',

    PatchAction = '[GFB] Patch feature state',
    PatchActionSuccess = '[GFB] Patch success',
    PatchActionFailed = '[GFB] Patch failed',

    OpenPopup = '[GFB] Open popupp event',
    ClosePopup = '[GFB] Close popupp event',
    ShowPopup = '[GFB] Show popup',
    HidePopup = '[GFB] Hide popup',
    CompletePopup = '[GFB] Complete popup',

    UpdateGenerealPopupState = '[GFB] Update general popup state',
}

export const init = createAction(GFBActionTypes.Init, props<{ payload: IAPInitGFB }>());

export const initSuccess = createAction(GFBActionTypes.InitSuccess, props<{ payload: IAPSetListGFB }>());

export const setActiveEPJ = createAction(GFBActionTypes.SetActiveEPJ, props<{ payload: string[] }>());

export const setActive = createAction(GFBActionTypes.SetActive, props<{ payload: IAPSetFeatureGFB }>());

export const resetActive = createAction(GFBActionTypes.ResetActive);

export const openSnack = createAction(GFBActionTypes.OpenSnack, props<{ payload?: IAPSetFeatureGFB }>());
export const closeSnack = createAction(GFBActionTypes.CloseSnack);
export const hideSnack = createAction(GFBActionTypes.HideSnack);
export const showSnack = createAction(GFBActionTypes.ShowSnack);

export const patchAction = createAction(GFBActionTypes.PatchAction, props<{ payload: IAPPatchGFB }>());
export const patchActionSuccess = createAction(GFBActionTypes.PatchActionSuccess, props<{ payload: IAPSetListGFB }>());

export const openPopup = createAction(GFBActionTypes.OpenPopup);
export const closePopup = createAction(GFBActionTypes.ClosePopup);
export const showPopup = createAction(GFBActionTypes.ShowPopup);
export const hidePopup = createAction(GFBActionTypes.HidePopup);
export const completePopup = createAction(GFBActionTypes.CompletePopup);

// Update general gfb popup visibility state
export const updateGeneralPopupState = createAction(
    GFBActionTypes.UpdateGenerealPopupState,
    props<{ payload: boolean }>(),
);
