import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { of, Observable } from 'rxjs';

import { AddressCity, AddressGeoInfo, Country, IRguSearchResultLane } from '@solocal-manager/sirius/core/models';
import { CountryViewModel } from '@solocal-manager/sirius/core/models/address-view-model';
import { CityService, GeocodeService, RguSearchService } from '@solocal-manager/sirius/core/services';

@Injectable({
    providedIn: 'root',
})
export class AddressHelperFacade {
    private readonly countryViewModel: CountryViewModel;

    constructor(
        private readonly translate: TranslateService,
        private readonly geocodeService: GeocodeService,
        private readonly cityService: CityService,
        private readonly rguSearchService: RguSearchService,
    ) {
        this.countryViewModel = new CountryViewModel(this.translate);
    }

    getCountries(): Observable<Country[]> {
        return of(this.countryViewModel.countries);
    }

    getGeoInfo(address: string): Observable<AddressGeoInfo[]> {
        return this.geocodeService.fetchGeoInfo(address);
    }

    getStates(token: string): Observable<AddressCity[]> {
        return this.cityService.getCityByZip(token);
    }

    getLanes(street: string, zipCode: string, city: string, locationId: string): Observable<IRguSearchResultLane[]> {
        return this.rguSearchService.getLanesByZipCode(street, zipCode, locationId, city);
    }
}
