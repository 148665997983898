import { APP_INITIALIZER, FactoryProvider } from '@angular/core';

import { KeycloakOptions } from 'keycloak-angular';
import { SlmAuthFacade } from './slm-oauth.facade';

export const authInitializer = (options: KeycloakOptions): FactoryProvider => {
    function initializeOauth(slmAuthFacade: SlmAuthFacade): () => Promise<boolean> {
        return async () => await slmAuthFacade.init(options);
    }

    return {
        provide: APP_INITIALIZER,
        useFactory: initializeOauth,
        multi: true,
        deps: [SlmAuthFacade],
    };
};
