import { createAction, props } from '@ngrx/store';

import { IHabilitatedLocationListPayload } from '@solocal-manager/sirius/core/models';
import { IHabilitatedLocation } from '@solocal-manager/sirius/support/base-models';

export enum HabilitatedLocationActionTypes {
    GET_HAB_LOCATION = '[HABILITATED_LOCATION] get',
    GET_FIRST_HAB_LOCATION = '[HABILITATED_LOCATION] get first',
    GET_DEFAULT_HAB_LOCATION = '[HABILITATED_LOCATION] get default',
    //
    SET_HAB_LOCATION_FULL = '[HABILITATED_LOCATION_FULL] set',
    SET_HAB_LOCATION_FULL_SUCCESS = '[HABILITATED_LOCATION_FULL] set success',
    //
    RESET_HAB_LOCATION = '[HABILITATED_LOCATION] reset',
    DELETE_HAB_LOCATION = '[HABILITATED_LOCATION] delete',
    DELETE_HAB_LOCATION_SUCCESS = '[HABILITATED_LOCATION] delete success',
    //
    DELETE_INDIRECT_LOCATION = '[INDIRECT_LOCATION] delete',
}

/**
 * Action to get a single habilitated location by epj number
 * It won't have any side effect on the list of habilitated locations
 */
export const GetHabilitatedLocation = createAction(
    HabilitatedLocationActionTypes.GET_HAB_LOCATION,
    props<IHabilitatedLocationListPayload>(),
);

export const GetFirstHabilitatedLocation = createAction(HabilitatedLocationActionTypes.GET_FIRST_HAB_LOCATION);

export const GetDefaultHabilitatedLocation = createAction(HabilitatedLocationActionTypes.GET_DEFAULT_HAB_LOCATION);

export const SetHabilitatedLocationFull = createAction(
    HabilitatedLocationActionTypes.SET_HAB_LOCATION_FULL,
    props<IHabilitatedLocation>(),
);

export const SetHabilitatedLocationFullSuccess = createAction(
    HabilitatedLocationActionTypes.SET_HAB_LOCATION_FULL_SUCCESS,
    props<IHabilitatedLocation>(),
);

export const ResetHabilitatedLocation = createAction(HabilitatedLocationActionTypes.RESET_HAB_LOCATION);

export const DeleteHabilitatedLocation = createAction(
    HabilitatedLocationActionTypes.DELETE_HAB_LOCATION,
    props<{ epj: string }>(),
);

export const DeleteIndirectLocation = createAction(
    HabilitatedLocationActionTypes.DELETE_INDIRECT_LOCATION,
    props<{ epj: string; ignored: boolean }>(),
);

export const DeleteHabilitatedLocationSuccess = createAction(
    HabilitatedLocationActionTypes.DELETE_HAB_LOCATION_SUCCESS,
);
