<div class="input-image">
    <div class="uploaded-data" [ngClass]="imageType" *ngIf="dataValue.length">
        <label translate>{{ label }}</label>
        <div>
            <div class="image-box">
                <div class="main-image" (click)="showOverlay()">
                    <img [src]="dataValue[0].thumbnail" alt="uploaded image" />
                    <div class="add-control">
                        <span class="glyphicon glyphicon-plus-sign"></span>
                    </div>
                </div>
                <div class="remove-control" (click)="removeImage(dataValue[0])" translate>Remove</div>
            </div>

            <div class="image-box" *ngFor="let image of dataValue; let i = index">
                <div *ngIf="i !== 0">
                    <div class="main-image multi-image" (click)="showOverlay()">
                        <img [src]="image.thumbnail" alt="main image" />
                        <div class="add-control">
                            <span class="glyphicon glyphicon-plus-sign"></span>
                        </div>
                    </div>
                    <div class="remove-control" (click)="removeImage(image)" translate>Remove</div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="!dataValue.length">
        <div class="col-md-12 phone-form-add-number-holder">
            <button class="btn btn-primary remove-field-button" (click)="showOverlay()" translate>
                {{ label }}
            </button>
        </div>
    </div>
</div>

<div
    bsModal
    #overlay="bs-modal"
    class="image-select-overlay modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="selectImageOverlayLabel"
    aria-hidden="true"
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-body">
                <div class="inner-header">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="hideOverlay()">
                        <span class="glyphicon glyphicon-remove"></span>
                    </button>
                </div>
                <slm-input-image-upload
                    [locationId]="locationId"
                    [multiple]="true"
                    [mediaType]="imageType"
                    [mediaName]="'photo'"
                    [hideUploaded]="true"
                    [minHeight]="minHeight"
                    [minWidth]="minWidth"
                    [maxHeight]="maxHeight"
                    [maxWidth]="maxWidth"
                    (uploaded)="uploadedImage($event)"
                    [publishToLocation]="true"
                >
                </slm-input-image-upload>
                <slm-input-image-error-messages
                    *ngIf="imageService.imageErrorTypes$ | async as imgerrTypes"
                    [imageTypeErrors]="imgerrTypes"
                    [imageRules]="imageService.imageDimensions$ | async"
                    [imageErrorConfig]="imageService.imageErrorConfig"
                >
                </slm-input-image-error-messages>
                <app-image-list
                    [locationId]="locationId"
                    [actions]="['select']"
                    [imageType]="imageType"
                    [imagesPerPage]="15"
                    [reset]="!overlay.isShown"
                    [selectedImages]="[]"
                    (imageSelected)="addImage($event)"
                    #imageList
                >
                </app-image-list>
            </div>
        </div>
    </div>
</div>
