import { Component, Input, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { ImageService } from '@solocal-manager/sirius/core/core';
import { Photo } from '@solocal-manager/sirius/support/base-models';

@Component({
    selector: 'slm-input-image',
    templateUrl: './input-image.component.html',
    styleUrls: ['./input-image.component.scss'],
})
export class InputImageComponent {
    dataValue: Photo[];

    @Input()
    imageValue: Photo[];
    @Input()
    control: UntypedFormControl;
    @Input()
    locationId: string;
    @Input()
    imageType: string;
    @Input()
    multipleValue: boolean;
    @Input()
    label: string;
    @Input()
    minHeight: number;
    @Input()
    minWidth: number;
    @Input()
    maxHeight: number;
    @Input()
    maxWidth: number;

    @ViewChild('overlay')
    overlay: ModalDirective;

    constructor(public imageService: ImageService) {}

    showOverlay(): void {
        this.overlay.show();
    }

    hideOverlay(): void {
        this.overlay.hide();
    }

    addImage(image: Photo): void {
        if (this.multipleValue) {
            this.dataValue.unshift(image);
            this.imageValue.unshift(image);
        } else {
            if (!this.dataValue.length) {
                this.dataValue.push(image);
                this.imageValue.push(image);
            } else {
                const replacedImageIndex = this.imageValue.indexOf(this.dataValue[0]);
                this.dataValue[0] = image;
                this.imageValue[replacedImageIndex] = image;
            }
        }
        this.control.setValue(this.getMergedValue());
        this.hideOverlay();
    }

    removeImage(image: Photo): void {
        this.dataValue.splice(this.dataValue.indexOf(image), 1);
        this.imageValue.splice(this.imageValue.indexOf(image), 1);
        this.control.setValue(this.getMergedValue());
    }

    ngOnChanges(changed): void {
        this.dataValue = this.imageValue.filter((image: Photo) => image.type === this.imageType);
        this.control.setValue(this.getMergedValue());
    }

    uploadedImage(image: Photo[]) {
        if (image.length) {
            this.addImage(image[0]);
        }
    }

    private getMergedValue(): object[] {
        return this.dataValue
            .concat(this.imageValue.filter((image: Photo) => image.type !== this.imageType))
            .map((image: Photo) => {
                return { id: image.id };
            });
    }
}
