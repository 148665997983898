import { Injectable } from '@angular/core';
import { get } from 'lodash-es';

import { EnvironmentProvider } from '../config/environment-provider.config';

@Injectable({ providedIn: 'root' })
export class EnvironmentService {
    constructor(envProvider: EnvironmentProvider) {
        this._env = envProvider.environment;
    }

    _env: any;

    get env() {
        return this._env;
    }

    get apiUrl(): string {
        return this._env.apiUrl;
    }

    get desktopBaseUrl(): string {
        return this._env.desktopBaseUrl ?? this._env.uiUrl;
    }

    get siriusProxy(): string {
        return this._env.siriusProxy;
    }

    get azureSubKey(): string {
        return get(this._env, 'azureSubKey');
    }

    get defaultLanguage(): string {
        return get(this._env, 'defaultLanguage') || 'en';
    }

    get frontClientBaseUrl(): string {
        return get(this._env, 'frontClientBaseUrl');
    }

    get clicRDVApiKey(): string {
        return get(this._env, 'clicRDVApiKey');
    }

    get ssoDynabuyURLKey(): string {
        return get(this._env, 'ssoDynabuyURL');
    }

    get offerPresentationWebsiteOfferExplainingGuideUrl(): string {
        return get(this._env, 'feature.offerPresentation.websiteOfferExplainingGuideUrl');
    }

    get gtmConfig(): { enable: boolean; id: string } {
        return get(this._env, 'feature.googleTagManager');
    }

    get kameleoonConfig(): { enable: boolean; id: string } {
        return get(this._env, 'feature.kameleoon');
    }

    get pages() {
        return get(this._env, 'pages', []);
    }

    get apiCoreApikey(): string {
        return get(this._env, 'apiCoreApikey');
    }

    get backEndApikey(): string {
        return get(this._env, 'backEndApikey');
    }

    hasFeature(feature: string): boolean {
        return get(this._env, `feature.${feature}`) === true;
    }

    getFeature(feature: string): any {
        return get(this._env, `feature.${feature}`);
    }
}
