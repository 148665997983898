<form
    class="location-list-search"
    [formGroup]="searchForm"
    (ngSubmit)="submitSearch()"
>
    <div class="form-group">
        <div class="input-container">
            <input
                formControlName="email"
                id="emailSearch"
                class="form-control"
                type="search"
                [placeholder]="'Search by user email' | translate"
            />
            <button
                [disabled]="!canSubmit"
                type="submit"
                class="btn btn-light search-icon"
            ></button>
        </div>
    </div>
</form>
