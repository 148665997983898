import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { getPages, getPublisherStatus, getPublisherStatusSuccess } from '@solocal-manager/sirius/core/core';
import { UiService } from '@solocal-manager/sirius/core/core';
import { PublisherStatus } from '@solocal-manager/sirius/core/models/publishers';
import { ChatConfig, GetChatConfig, getChatConfig } from '@solocal-manager/sirius/data-access-chat/core';
import { Location } from '@solocal-manager/sirius/support/base-models';

import { connectablePublishers } from '../../config/connectable-publishers.config';
import { publisherStates } from '../../config/publisher-states.config';
import { ConnectModalService } from '../../services';

const autoSearch = {
    pagesjaunes: true,
    seety: true,
    site_privilege: true,
    tripadvisor: true,
    pharmanity: true,
};

const refreshInterval = 5000;

@Component({
    selector: 'slm-app-publisher-status-list',
    templateUrl: './publisher-status-list.component.html',
    styleUrls: ['./publisher-status-list.component.scss'],
})
export class PublisherStatusListComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild('connectModal')
    public connectModal: ModalDirective;

    @Input()
    publisherStatusList: PublisherStatus[];
    @Input()
    location: Location;
    @Input()
    refresh: boolean;

    @Output()
    publisherStatusSelected: EventEmitter<PublisherStatus> = new EventEmitter();
    @Output()
    busy: EventEmitter<boolean> = new EventEmitter();

    // refreshSubscribtion;
    publisherStatusSubscribtion;
    currentLocation: Location;
    publisherId: string;
    autoSearch: object;
    chatConfig$: Observable<ChatConfig>;
    chatConfig: ChatConfig;
    PublisherStatusList: any[];
    canChangeConnection: any = {};
    lastDisconnected: any = {};
    destroyed$ = new Subject<boolean>();
    navadsDisconnect = false;

    constructor(
        public route: ActivatedRoute,
        public store: Store<any>,
        public logger: NGXLogger,
        public updates$: Actions,
        private uiService: UiService,
        private connectModalService: ConnectModalService,
    ) {
        updates$
            .pipe(
                filter(action =>
                    [getPublisherStatus.type as string, getPublisherStatusSuccess.type as string].includes(action.type),
                ),
                map((action: any) => action.result),
                takeUntil(this.destroyed$),
            )
            .subscribe(publisherStatus => {
                if (publisherStatus) {
                    this.busy.emit(false);
                    this.publisherStatusList = publisherStatus.sort(
                        // all the Navads Publishers lines should be grouped together
                        // at the end of the list of publishers
                        (a: PublisherStatus, b: PublisherStatus) => {
                            if (this.isNavadsPublisher(a)) {
                                if (this.isNavadsPublisher(b)) {
                                    if (this.isNavadsGrouped(b) && !this.isNavadsGrouped(a)) {
                                        return -1;
                                    }
                                    if (!this.isNavadsGrouped(b) && this.isNavadsGrouped(a)) {
                                        return 1;
                                    }
                                    return 0;
                                }
                                return 1;
                            } else if (this.isNavadsPublisher(b)) {
                                if (this.isNavadsPublisher(a)) {
                                    if (this.isNavadsGrouped(b) && !this.isNavadsGrouped(a)) {
                                        return -1;
                                    }
                                    if (!this.isNavadsGrouped(b) && this.isNavadsGrouped(a)) {
                                        return 1;
                                    }
                                    return 0;
                                }
                                return -1;
                            } else {
                                return 0;
                            }
                        },
                    );
                    this.navadsDisconnect = this.publisherStatusList.some(p => {
                        return this.isNavadsPublisher(p) && this.canDisconnect(p);
                    });
                } else {
                    this.busy.emit(true);
                }
            });
    }

    ngOnInit(): void {
        this.autoSearch = autoSearch;
        this.getPublisherStatus();
        this.chatConfig$ = this.store.pipe(select(getChatConfig));
        setTimeout(() => {
            this.store.dispatch(
                getPages({
                    params: {
                        locationId: this.location.id,
                    },
                }),
            );
        }, 2000);
    }

    ngOnChanges(changed): void {
        const currentLocation = changed.location ? changed.location.currentValue : undefined;
        const refresh = changed.refresh ? changed.refresh.currentValue : undefined;

        if (currentLocation && currentLocation.id) {
            this.currentLocation = currentLocation;
            this.getPublisherStatus();
        }

        if (refresh > 0) {
            this.getPublisherStatus();
        }

        setTimeout(() => {
            this.store.dispatch(
                getPages({
                    params: {
                        locationId: this.location.id,
                    },
                }),
            );
        }, 2000);
    }

    isSelfOnboardedPage(publisher): string {
        let isSelfOnboarded = false;
        this.store.pipe(select('pagesList'), takeUntil(this.destroyed$)).subscribe(pagesList => {
            if (pagesList[0]) {
                pagesList[0].forEach(page => {
                    if (page.publisher_id === publisher.publisher_id && page.custom_data.self_onboarding) {
                        isSelfOnboarded = true;
                    }
                });
            }
        });

        return isSelfOnboarded ? 'publisher_list:self_onboarding_flag' : null;
    }

    isNavadsPublisher(publisherStatus: PublisherStatus): boolean {
        return !!publisherStatus.proxy_publisher && publisherStatus.proxy_publisher === 'uberall';
    }

    changedPublisherId(newPublisherId: string): void {
        setTimeout(() => {
            this.publisherId = '';
            this.logger.debug('<PublisherStatusListComponent> changedPublisherId', newPublisherId);

            this.publisherId = newPublisherId;

            if (connectablePublishers[this.publisherId] !== 'click') {
                this.connectModal.show();
            }
        });
    }

    onResetPublisherId(shouldReset) {
        setTimeout(() => {
            this.logger.debug('<PublisherStatusListComponent> onResetPublisherId', shouldReset);
            if (shouldReset) {
                this.publisherId = '';
            }
        });
    }

    closeModal(shouldClose: boolean) {
        this.connectModal.hide();
        if (shouldClose) {
            /* single shot to update publisher status */
            this.busy.emit(true);
            setTimeout(() => this.getPublisherStatus(), refreshInterval);
        }
        this.connectModalService.registerClose();
    }

    onAbleToChangeConnection($event) {
        this.canChangeConnection[$event.publisherId] = $event.canChange;
    }

    onPublisherStatusSelect(publisherStatus: PublisherStatus): void {
        this.publisherStatusSelected.emit(publisherStatus);
    }

    getPublisherStatus(): void {
        if (this.currentLocation) {
            this.store.dispatch(getPublisherStatus({ params: { location: this.currentLocation } }));
            this.store.dispatch(
                GetChatConfig({
                    params: { epj: this.currentLocation.partner_id },
                }),
            );
        }
    }

    onDisconnection($event) {
        this.logger.debug('<PublisherStatusListComponent> onDisconnection', $event);
        this.lastDisconnected = $event;
    }

    getNavadsRowsCount(publisherStatus: PublisherStatus): number {
        const index = this.publisherStatusList.indexOf(publisherStatus);
        const prevPublisher = this.publisherStatusList[index - 1];
        const nextPublisher = this.publisherStatusList[index + 1];
        if (
            this.isNavadsGrouped(publisherStatus) &&
            (!nextPublisher || this.isNavadsPublisher(nextPublisher)) &&
            (!prevPublisher ||
                !this.isNavadsPublisher(prevPublisher) ||
                (!this.canDisconnect(prevPublisher) && !this.canConnect(prevPublisher)))
        ) {
            return this.publisherStatusList.filter(p => this.isNavadsGrouped(p)).length;
        }
        return 0;
    }

    canDisconnect(publisherStatus: PublisherStatus): boolean {
        const disconCond = {
            publisher: {
                gmb: true,
                facebook: true,
                seety: true,
                site_privilege: true,
                soms: true,
                '118000': true,
                tripadvisor: true,
                pharmanity: true,
            },
            states: {},
        };

        // enables disconnect button for every 'click' type publisher

        Object.keys(connectablePublishers)
            .filter(item => connectablePublishers[item] === 'click')
            .map(item => {
                disconCond.publisher[item] = true;
            });

        disconCond.states[publisherStates.notConnected] = true;
        disconCond.states[publisherStates.doNotConnect] = true;

        return !!(disconCond.publisher[publisherStatus.publisher_id] && !disconCond.states[publisherStatus.state]);
    }

    canConnect(publisherStatus: PublisherStatus): boolean {
        return (
            !this.shouldDisableActions(publisherStatus) &&
            this.isPublisherConnectable(publisherStatus) &&
            publisherStatus.state === publisherStates.notConnected
        );
    }

    get isNewOfferLocation(): boolean {
        if (!this.location) {
            return false;
        }
        return this.uiService.isNewOfferLocation(this.location);
    }

    shouldDisableActions(publisherStatus: PublisherStatus): boolean {
        return this.isNewOfferLocation && publisherStatus.publisher_id === 'pagesjaunes';
    }

    isPublisherConnectable(publisherStatus: PublisherStatus): boolean {
        return (
            connectablePublishers[publisherStatus.publisher_id] === 'postcard' ||
            connectablePublishers[publisherStatus.publisher_id] === 'list' ||
            connectablePublishers[publisherStatus.publisher_id] === 'click'
        );
    }

    isNavadsGrouped(publisherStatus) {
        return (
            this.isNavadsPublisher(publisherStatus) &&
            (this.canConnect(publisherStatus) || this.canDisconnect(publisherStatus))
        );
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}
