<div class="input-error-message" *ngIf="isError && errorMessage as errmsg">
    <!-- error messages -->
    <p *ngIf="errmsg?.type === 'required'">
        {{ 'This field is required' | translate }}
    </p>
    <p *ngIf="errmsg?.type === 'addressError'">
        {{ 'Invalid address' | translate }}
    </p>
    <p *ngIf="errmsg?.type === 'invalidStreetAddress'">
        {{ 'Please select a valid street name from the list.' | translate }}
    </p>
    <p *ngIf="errmsg?.type === 'openingVsClosingTimeOverlapping'">
        {{ 'Overlapped time slots are not allowed' | translate }}
    </p>
    <p *ngIf="errmsg?.type === 'openingVsClosingTimeMismatch'">
        {{ 'Mismatched opening and closing times' | translate }}
    </p>
    <p *ngIf="errmsg?.type === 'openedDayMissing'">
        {{ 'Business should not be marked as closed everyday' | translate }}
    </p>
    <p *ngIf="errmsg?.type === 'closingTimeMissing'">
        {{ 'Closing time missing' | translate }}
    </p>
    <p *ngIf="errmsg?.type === 'specificOpeningHoursInvalid'">
        {{ 'Invalid opening hours' | translate }}
    </p>
    <p *ngIf="errmsg?.type === 'forbiddenNameCedex'">
        {{ 'The name of the city does not exist in RGU' | translate }}
    </p>
    <p *ngIf="errmsg?.type === 'phonesMainType'">
        {{ 'At least one phone number of type Main, VOIP or Mobile is required' | translate }}
    </p>
    <p *ngIf="errmsg?.type === 'phonesMobileType'">
        {{ "You can't use more than 2 mobile numbers." | translate }}
    </p>
    <p *ngIf="errmsg?.type === 'invalidCity'">
        {{ 'The city does not exist in RGU. Please re-enter the zip code' | translate }}
    </p>
    <p *ngIf="errmsg?.type === 'oneCategoryNeeded'">
        {{ 'At least one category is needed' | translate }}
    </p>
    <p *ngIf="errmsg?.type === 'email'">
        {{ 'Invalid email format' | translate }}
    </p>
    <p *ngIf="errmsg?.type === 'url'">
        {{ 'Invalid URL format: use http://address.com or https://address.com' | translate }}
    </p>
    <p *ngIf="errmsg?.type === 'urlRemote'">{{ 'Invalid URL HTTP' | translate }} {{ error.params.status_code }}</p>
    <p *ngIf="errmsg?.type === 'facebookUrl'">
        {{ 'Invalid URL format' | translate }}
    </p>
    <p *ngIf="errmsg?.type === 'tripadvisorUrl'">
        {{ 'Invalid URL format' | translate }}
    </p>
    <p *ngIf="errmsg?.type === 'price'">
        {{ 'Value has to have price format XXXXXX.XX ' | translate }}
    </p>
    <div
        *ngIf="errmsg?.type === 'gmbUrl'"
        [innerHTML]="
            'This URL is invalid, please navigate to the ###GMB interface### to find the GMB page in question'
                | translate
                | textToUrl: { url: 'https://business.google.com/manage' }
                | safeHtml
        "
    ></div>
    <!-- error messages parameritized -->
    <p *ngIf="errmsg?.type === 'addressLine1Length'">
        {{ 'The address must not exceed ' | translate }}
        {{ error.params.maxLength }} {{ 'characters' | translate }}
    </p>
    <p *ngIf="errmsg?.type === 'addressLine2Length'">
        {{ 'The additional address info must not exceed ' | translate }}
        {{ error.params.maxLength }} {{ 'characters' | translate }}
    </p>
    <p *ngIf="errmsg?.type === 'max'">
        {{ 'This value must not exceed ' | translate }}
        {{ error.params.max }} km
    </p>
    <p *ngIf="errmsg?.type === 'min'">
        {{ 'This value must be at least ' | translate }}
        {{ error.params.min }} km
    </p>
    <p *ngIf="errmsg?.type === 'maxlength'">
        {{ 'The text must not exceed ' | translate }}
        {{ error.params.requiredLength }} {{ 'characters' | translate }}
    </p>
    <p *ngIf="errmsg?.type === 'forbiddenName'">{{ 'Forbidden name:' | translate }} {{ error.params.value }}</p>
    <p *ngIf="errmsg?.type === 'forbiddenCoutryCode'">
        {{ 'Forbidden country code:' | translate }} {{ error.params.value }}
    </p>
    <p *ngIf="errmsg?.type === 'nonNumericValue'">{{ 'Invalid number:' | translate }} {{ error?.params?.value }}</p>
    <p *ngIf="errmsg?.type === 'youtubeVideoUrlInvalid'">
        {{ 'Invalid YouTube url:' | translate }} {{ error.params.value }}
    </p>
    <p *ngIf="errmsg?.type === 'pattern'">
        {{ 'Invalid pattern. Must match:' | translate }}
        {{ error.params.requiredPattern }}
    </p>
    <p *ngIf="errmsg?.type === 'dotSpace'">
        {{ 'After each "." there must be a space.' | translate }}
    </p>
    <p *ngIf="errmsg?.type === 'addressLaneInvalid'">
        {{ 'Please select a valid street name from the list.' | translate }}
    </p>
</div>
