import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import { locationsByErrorKpiReducer, LocationsKpiState } from './locations-kpi.reducer';
import { pageStatesKpiReducer, PageStatesKpiState } from './page-states-kpi.reducer';
import {
    provisioningIssueDetailsKpiReducer,
    ProvisioningIssueDetailsKpiState,
} from './provisioning-issue-details-kpi.reducer';
import { provisioningIssuesKpiReducer, ProvisioningIssuesKpiState } from './provisioning-issues-kpi.reducer';
import { publisherErrorsKpiReducer, PublisherErrorsKpiState } from './publisher-errors-kpi.reducer';
import { totalParcUsageKpiReducer, TotalParcUsageKpiState } from './total-parc-usage-kpi.reducer';
import { totalSalesKpiReducer, TotalSalesKpiState } from './total-sales-kpi.reducer';

export interface IKpiState {
    locationsByError: LocationsKpiState;
    pageStates: PageStatesKpiState;
    publisherErrors: PublisherErrorsKpiState;
    totalSales: TotalSalesKpiState;
    provisioningIssues: ProvisioningIssuesKpiState;
    provisioningIssueDetails: ProvisioningIssueDetailsKpiState;
    totalParcUsage: TotalParcUsageKpiState;
}

export const kpiReducers: ActionReducerMap<IKpiState> = {
    locationsByError: locationsByErrorKpiReducer,
    pageStates: pageStatesKpiReducer,
    publisherErrors: publisherErrorsKpiReducer,
    totalSales: totalSalesKpiReducer,
    provisioningIssues: provisioningIssuesKpiReducer,
    provisioningIssueDetails: provisioningIssueDetailsKpiReducer,
    totalParcUsage: totalParcUsageKpiReducer,
};

export const selectKpiState = createFeatureSelector<IKpiState>('kpi');

export const getKpiLocationsByError = createSelector(selectKpiState, (state: IKpiState) => state?.locationsByError);

export const getKpiPageStates = createSelector(selectKpiState, (state: IKpiState) => state?.pageStates);

export const getKpiPublisherErrors = createSelector(selectKpiState, (state: IKpiState) => state?.publisherErrors);

export const getKpiTotalSales = createSelector(selectKpiState, (state: IKpiState) => state.totalSales);

export const getKpiProvisioningIssues = createSelector(selectKpiState, (state: IKpiState) => state.provisioningIssues);

export const getKpiProvisioningIssueDetails = createSelector(
    selectKpiState,
    (state: IKpiState) => state.provisioningIssueDetails,
);

export const getKpiTotalParcUsage = createSelector(selectKpiState, (state: IKpiState) => state.totalParcUsage);

export const getPublisherNameById = (publisherId: string) =>
    createSelector(getKpiPageStates, (pageStates: PageStatesKpiState) => {
        if (pageStates && publisherId) {
            const selectedPage = pageStates.find(page => page.publisher_id === publisherId);
            return selectedPage ? selectedPage.publisher_name : publisherId;
        }
        return '';
    });

export const getErrorNameByType = (errorType: string) =>
    createSelector(getKpiProvisioningIssues, (provisioningIssues: ProvisioningIssuesKpiState) => {
        if (errorType && provisioningIssues && provisioningIssues.meta) {
            return provisioningIssues.meta.error_type_map[errorType];
        }
        return '';
    });

export const getParcUsageByYear = (year: string) =>
    createSelector(getKpiTotalParcUsage, (totalParcUsage: TotalParcUsageKpiState) => {
        const emptyInfo = { year, months: [] };
        if (totalParcUsage) {
            return totalParcUsage.find(yearInfo => yearInfo.year === year) || emptyInfo;
        }
        return emptyInfo;
    });
