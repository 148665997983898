import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EnvironmentService } from '@slm/shared/environment';
import { Reference } from '@solocal-manager/sirius/support/base-models';
import { includes, keys } from 'lodash-es';
import { of, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class TypeaheadCategoryService {
    countryLanguageMap: object = {
        fr: 'FR',
        en: 'US',
        de: 'DE',
    };
    country: string;
    language: string;

    private cachedResults: Map<string, Array<Reference>> = new Map();

    constructor(
        public http: HttpClient,
        public translate: TranslateService,
        public envService: EnvironmentService,
    ) {}

    getCategory(term: string, publisherId: string, eligibiliteDA?: boolean): Observable<Reference[]> {
        const categorySearchUrl: string = this.envService.apiUrl + '/consumer/categories/autocomplete/';
        this.setLocalizationParams();

        let params = new HttpParams()
            .set('country', '' + this.country)
            .set('language', '' + this.language)
            .set('term', '' + term);

        if (eligibiliteDA) {
            params = params.set('eligibilite_da', '' + eligibiliteDA);
        }

        if (publisherId !== 'gmb') {
            params = params.set('publisher_id', publisherId);
        }

        const paramStr = params.toString();
        const cachedResults = this.cachedResults.get(paramStr);
        if (!cachedResults) {
            return this.http
                .get<Reference[]>(categorySearchUrl, {
                    params,
                })
                .pipe(
                    tap((results: Reference[]) => {
                        this.cachedResults.set(paramStr, results);
                    }),
                    catchError(() => {
                        return of([]);
                    }),
                );
        }
        return of(cachedResults);
    }

    setLocalizationParams(): void {
        if (!includes(keys(this.countryLanguageMap), this.translate.currentLang)) {
            this.country = 'FR';
            this.language = 'fr';
        } else {
            this.country = this.countryLanguageMap[this.translate.currentLang];
            this.language = this.translate.currentLang;
        }
    }
}
