<div class="container-fluid">
    <div class="row m-top-5">
        <div class="col-md-2 col-sm-2 col-2 onboarding-title">
            {{ 'Information' | translate }}
        </div>
        <table class="col-md-10 col-sm-10 col-10 production-info">
            <tr>
                <td translate>Pack level:</td>
                <td translate>{{ location?.custom_data?.pack?.id || '-' }}</td>
            </tr>
            <tr>
                <td translate>Product type:</td>
                <td>{{ location?.custom_data?.product_type || '-' }}</td>
            </tr>
            <tr>
                <td translate>Business unit code:</td>
                <td>{{ location?.custom_data?.business_unit_code || '-' }}</td>
            </tr>
            <tr>
                <td translate>Sales person:</td>
                <td>
                    {{ location?.custom_data?.sales_person?.sales_name || '-' }}
                </td>
            </tr>
            <tr>
                <td translate>Created date:</td>
                <td>{{ createdDateFormatted || '-' }}</td>
            </tr>
            <tr>
                <td translate>Last modified date:</td>
                <td>{{ lastModified || '-' }}</td>
            </tr>
        </table>
    </div>
</div>
