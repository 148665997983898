import { Component, Input, OnChanges } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { get } from 'lodash-es';

import { InputSingle } from '../class';

@Component({
    selector: 'wpm-input-single-field',
    template: '',
})
export class WpmInputSingleFieldComponent extends InputSingle implements OnChanges {
    @Input() config: any;
    @Input() validators: any[];
    @Input() asyncValidators: any[];

    constructor(public formBuilder: UntypedFormBuilder) {
        super(formBuilder);
    }

    ngOnChanges(input) {
        const config = get(input, 'config.currentValue');
        const validators = get(input, 'validators.currentValue');
        const asyncValidators = get(input, 'asyncValidators.currentValue');
        if (config) {
            this._config = config;
            this._validators = validators;
            this._asyncValidators = asyncValidators;

            this.setValidators();
        }
    }
}
