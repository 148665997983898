import { Pipe, PipeTransform } from '@angular/core';

interface ExcerptModel {
    length: number;
    shouldTruncate: boolean;
}

@Pipe({
    name: 'excerpt',
})
export class ExcerptPipe implements PipeTransform {
    transform(text: string, args?: ExcerptModel): any {
        if (args.shouldTruncate === undefined) {
            args.shouldTruncate = true;
        }

        if (!text?.length) {
            return null;
        }

        if (args.shouldTruncate && args.length) {
            // in case someone wants to fool around
            if (args.length <= 0) {
                return text;
            }

            // percentage mode
            // example 0.2 => 20% of text
            if (args.length < 1) {
                return text.substring(0, Math.ceil(text.length * 0.2)) + '...';
            }

            // length mode
            // example 100 => 100 first characters of text
            if (args.length >= 1 && text.length > args.length) {
                return text.substr(0, args.length) + '...';
            }
        }

        return text;
    }
}
