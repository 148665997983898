import { Component, OnInit, Output, Input, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';

// models
import { AddressCoords } from '@solocal-manager/sirius/core/models';

@Component({
    selector: 'app-input-google-maps-url',
    templateUrl: './input-google-maps-url.component.html',
    styleUrls: ['./input-google-maps-url.component.scss'],
})
export class InputGoogleMapsUrlComponent implements OnInit, OnChanges, OnDestroy {
    googleMapsUrlSubscribtion;
    googleMapsUrl: UntypedFormGroup;
    coordinates: AddressCoords;

    @Output()
    onCoordinates: EventEmitter<AddressCoords> = new EventEmitter<AddressCoords>();
    @Input() currentCoordinates: AddressCoords;

    constructor(public formBuilder: UntypedFormBuilder, public logger: NGXLogger) {}

    ngOnInit(): void {
        this.googleMapsUrl = this.formBuilder.group({
            url: ['', [Validators.pattern(/\/maps\/place\//)]],
        });

        this.googleMapsUrlSubscribtion = this.googleMapsUrl.valueChanges.subscribe(controls => {
            this.translateToCoordinates(controls.url);
        });
    }

    ngOnChanges(changes) {
        if (changes.currentCoordinates && this.googleMapsUrl) {
            this.googleMapsUrl.setValue({ url: '' });
        }
    }

    translateToCoordinates(url): void {
        this.coordinates = {
            lat: 0,
            lng: 0,
        };

        const re = new RegExp(/\/\@(-?[0-9]{1,3}\.[0-9]{0,7})\,([^,]+)\,[^,]+\//);
        const matches = re.exec(url);

        this.logger.debug('translateToCoordinates: ', url, matches);
        if (matches !== null) {
            this.coordinates.lat = parseFloat(matches[1]);
            this.coordinates.lng = parseFloat(matches[2]);
            this.logger.debug('coordinates: ', this.coordinates);
            this.onCoordinates.emit(this.coordinates);
        }
    }

    ngOnDestroy(): void {
        this.googleMapsUrlSubscribtion.unsubscribe();
    }
}
