import { KpiActionsUnion, KpiActionTypes } from '../actions';
import { IProvisioningIssueDetailsKpi } from '../models';

export type ProvisioningIssueDetailsKpiState = IProvisioningIssueDetailsKpi;

const initialState: ProvisioningIssueDetailsKpiState = undefined;

export function provisioningIssueDetailsKpiReducer(
    state = initialState,
    action: KpiActionsUnion,
): ProvisioningIssueDetailsKpiState {
    switch (action.type) {
        case KpiActionTypes.GET_PROVISIONING_ISSUE_DETAILS_SUCCESS: {
            return action.result;
        }
        default:
            return state;
    }
}
