import { forwardRef, Component, OnDestroy } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    UntypedFormArray,
    UntypedFormBuilder,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    Validators,
} from '@angular/forms';
import * as momentInstance from 'moment';
import { extendMoment } from 'moment-range';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { WpmInputRepeatable } from '@solocal-manager/sirius/core/core';
import { TimePeriod } from '@solocal-manager/sirius/support/base-models';

import { SpecificOpenningHoursValidator } from '../validators';

const moment = extendMoment(momentInstance);

@Component({
    selector: 'app-input-specific-opening-hours',
    templateUrl: './input-specific-opening-hours.component.html',
    styleUrls: ['./input-specific-opening-hours.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputSpecificOpeningHoursComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => InputSpecificOpeningHoursComponent),
            multi: true,
        },
    ],
})
export class InputSpecificOpeningHoursComponent extends WpmInputRepeatable implements ControlValueAccessor, OnDestroy {
    constructor(public formBuilder: UntypedFormBuilder) {
        super(formBuilder);
    }

    set currentData(data: any) {
        const fixedData = [];

        data.forEach(item => {
            fixedData.push({
                closed: item.closed,
                start_date: item.start_date,
                close_time: item.close_time || '',
                open_time: item.open_time || '',
                end_date: item.end_date || '',
            });
        });

        this._currentData = fixedData;
    }

    get currentData() {
        return this._currentData;
    }

    timeSlots = this.createTimeSlots();
    _initData = {
        start_date: ['', [Validators.required]],
        end_date: ['', []],
        open_time: ['', []],
        close_time: ['', []],
        closed: ['', [Validators.required]],
    };
    destroyed$: Subject<boolean> = new Subject();

    propagateChange: any = () => {};

    ngOnInit(): void {
        this.formRepeatable.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(controls => {
            this.propagateChange(controls);
        });
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    initInputRow() {
        return this.formBuilder.group(this.initData, {
            validator: SpecificOpenningHoursValidator.create(this.formRepeatable),
        });
    }

    prepareWriteValue(value) {
        this.prepareTimeSlots(value);
        this.writeValue(value);
    }

    registerOnChange(fn) {
        this.propagateChange = newValue => this.transformValue(newValue, fn);
    }

    transformValue(newValue, fn?) {
        const fixedData = [];
        let objectData: any = {};
        newValue?.data.forEach(item => {
            if (item.closed) {
                objectData = {
                    start_date: item.start_date,
                    closed: item.closed,
                };
            } else {
                objectData = {
                    closed: item.closed,
                    start_date: item.start_date,
                    close_time: item.close_time || '',
                    open_time: item.open_time || '',
                    end_date: item.end_date || '',
                };
            }

            fixedData.push(objectData);
        });

        return fn ? fn(fixedData) : fixedData;
    }

    removeTimeslot(specificOpeningHours, i) {
        const startDayValue = specificOpeningHours.controls.start_date.value;
        this.removeInput(i);
        const repeatableControls = <UntypedFormArray>this.formRepeatable.controls.data;
        const controlsByDay = repeatableControls.controls.filter(control => control.value.start_date === startDayValue);
        controlsByDay.forEach(control => {
            control.updateValueAndValidity();
        });
    }

    prepareTimeSlots(periods: TimePeriod[]): void {
        periods.forEach((period: TimePeriod) => {
            if (!this.timeSlots.includes(period.open_time)) {
                this.insertSlot(period.open_time);
            }

            if (!this.timeSlots.includes(period.close_time)) {
                this.insertSlot(period.close_time);
            }
        });
    }

    openingChanged(control: AbstractControl) {
        const startDate = control.get('start_date');
        const openTime = control.get('open_time');
        const endDate = control.get('end_date');
        const closeTime = control.get('close_time');

        // compare and set if next day is not set up yet
        if (startDate.value === endDate.value || !endDate.value) {
            if (openTime.value === closeTime.value || !this.isTimeBefore(openTime.value, closeTime.value)) {
                // set close day to next day if close time is before
                endDate.setValue(endDate.value ? this.getNextDay(endDate.value) : startDate.value);
            }
        }

        // if close and open days are different
        if (endDate.value && startDate.value !== endDate.value) {
            //  check if there is 24 hours difference between these two times
            if (this.isTimeBefore(openTime.value, closeTime.value)) {
                // if so, set the close day to the open day
                endDate.setValue(startDate.value);
            }
        }
    }

    isTimeBefore(openTime: string, closeTime: string) {
        const open = moment(openTime, 'hh:mm');
        const close = moment(closeTime, 'hh:mm');
        const isBefore = open.isBefore(close);

        return isBefore;
    }

    getNextDay(date: string) {
        return moment(date).add(1, 'days').format('YYYY-MM-DD');
    }

    insertSlot(slot: string): void {
        let i = 0;
        let time = 0;

        if (slot) {
            const slotToInsert: number = parseFloat(slot.split(':').join('.'));

            for (i; i < this.timeSlots.length; i++) {
                time = parseFloat(this.timeSlots[i].split(':').join('.'));
                if (slotToInsert < time) {
                    this.timeSlots.splice(this.timeSlots.indexOf(this.timeSlots[i]), 0, slot);
                    break;
                }
            }
        }
    }

    private createTimeSlots() {
        const minutes = 5;
        const times = [''];
        let tt = 0;

        for (let i = 1; tt < 24 * 60 + minutes; i++) {
            const hh = Math.floor(tt / 60);
            const mm = tt % 60;
            const h = (hh === 24 ? '24' : '0' + (hh % 24)).slice(-2);
            const m = ('0' + mm).slice(-2);
            const time = h + ':' + m;

            times[i] = time;
            tt += minutes;
        }

        // returns time slots without last 24:00 to match the mobile app
        times.pop();

        return times;
    }
}
