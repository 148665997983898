import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@slm/shared/environment';
import { Url } from '@solocal-manager/sirius/support/base-models';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class UrlCheckerService {
    constructor(
        public http: HttpClient,
        public environmentService: EnvironmentService,
    ) {}

    validateUrl(url: string): Observable<any> {
        const checkUrlUrl = `${this.environmentService.apiUrl}/external/url-check`;
        const params = new HttpParams().set('url', url);

        return this.http.get(checkUrlUrl, { params }).pipe(
            map(result => {
                return { ...result, url };
            }),
        );
    }

    validateUrls(urls: Url[]): Observable<any> {
        const urlObservers = [];
        urls.forEach(url => urlObservers.push(this.validateUrl(url.url)));
        return combineLatest(urlObservers);
    }
}
