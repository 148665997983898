<div class="kpi-total-uses">
    <div class="sub-title">{{ 'Total uses' | translate }}</div>
    <div class="title">
        {{ 'Usage frequency / parc (hors chute)' | translate }}
    </div>

    <div class="parc-usage-table">
        <accordion>
            <table>
                <thead>
                    <tr class="year-header">
                        <th class="year" scope="col"></th>
                        <th class="month" scope="col">
                            {{ 'Jan.' | translate }}
                        </th>
                        <th class="month" scope="col">
                            {{ 'Feb.' | translate }}
                        </th>
                        <th class="month" scope="col">
                            {{ 'Mar.' | translate }}
                        </th>
                        <th class="month" scope="col">
                            {{ 'Apr.' | translate }}
                        </th>
                        <th class="month" scope="col">
                            {{ 'May' | translate }}
                        </th>
                        <th class="month" scope="col">
                            {{ 'Jun.' | translate }}
                        </th>
                        <th class="month" scope="col">
                            {{ 'Jul.' | translate }}
                        </th>
                        <th class="month" scope="col">
                            {{ 'Aug.' | translate }}
                        </th>
                        <th class="month" scope="col">
                            {{ 'Sept.' | translate }}
                        </th>
                        <th class="month" scope="col">
                            {{ 'Oct.' | translate }}
                        </th>
                        <th class="month" scope="col">
                            {{ 'Nov.' | translate }}
                        </th>
                        <th class="month" scope="col">
                            {{ 'Dec.' | translate }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="heading-col" colspan="13">
                            <accordion-group
                                [isOpen]="first"
                                (isOpenChange)="onYearExpand($event, yearInfo)"
                                #group
                                *ngFor="let yearInfo of years; let first = first"
                            >
                                <div class="year-heading" accordion-heading>
                                    <div>
                                        <span>{{ yearInfo.year }}</span>
                                        <i
                                            class="glyphicon"
                                            [class.glyphicon-menu-down]="group.isOpen"
                                            [class.glyphicon-menu-up]="!group.isOpen"
                                        >
                                        </i>
                                    </div>
                                </div>
                                <table
                                    *ngIf="(getParcUsageByYear(yearInfo.year) | async) || {}; let parcUsage"
                                    class="year-table"
                                >
                                    <tr *ngIf="!parcUsage?.months?.length">
                                        <td class="loading-col" colspan="13">
                                            <div class="image-holder">
                                                <img src="assets/loader.gif" alt="" />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="parc-header">
                                        <td>{{ 'Parc' | translate }}</td>
                                        <td *ngFor="let month of parcUsage?.months" class="month total parc-data">
                                            {{ month.parc_total || '—' }}
                                        </td>
                                    </tr>
                                    <tr class="parc-data evolution">
                                        <td class="parc-title">% {{ 'evolution' | translate }}</td>
                                        <td
                                            *ngFor="let month of parcUsage?.months"
                                            class="month"
                                            [class.positive]="month.parc_evolution > 0"
                                            [class.zero]="month.parc_evolution === 0"
                                            [class.negative]="month.parc_evolution < 0"
                                        >
                                            <span>{{ (month.parc_evolution | numberToFixed) || '—' }}</span>
                                        </td>
                                    </tr>
                                    <tr class="parc-header">
                                        <td colspan="13">
                                            {{ 'Number of connection ' | translate }}
                                        </td>
                                    </tr>
                                    <tr class="parc-data">
                                        <td class="parc-title">
                                            {{ 'Desktop' | translate }}
                                        </td>
                                        <td *ngFor="let month of parcUsage?.months" class="month">
                                            {{ month.connections_desktop || '—' }}
                                        </td>
                                    </tr>
                                    <tr class="parc-data">
                                        <td class="parc-title">
                                            {{ 'Mobile' | translate }}
                                        </td>
                                        <td *ngFor="let month of parcUsage?.months" class="month">
                                            {{ month.connections_mobile || '—' }}
                                        </td>
                                    </tr>
                                    <tr class="parc-data total">
                                        <td class="parc-title">
                                            {{ 'Total' | translate }}
                                        </td>
                                        <td *ngFor="let month of parcUsage?.months" class="month">
                                            {{ month.connections_total || '—' }}
                                        </td>
                                    </tr>
                                    <tr class="parc-header">
                                        <td colspan="13">
                                            {{ 'Number of clients' | translate }}
                                        </td>
                                    </tr>
                                    <tr class="parc-data">
                                        <td class="parc-title">
                                            {{ 'Desktop' | translate }}
                                        </td>
                                        <td *ngFor="let month of parcUsage?.months" class="month">
                                            {{ month.number_used_desktop || '—' }}
                                        </td>
                                    </tr>
                                    <tr class="parc-data">
                                        <td class="parc-title">
                                            {{ 'Mobile' | translate }}
                                        </td>
                                        <td *ngFor="let month of parcUsage?.months" class="month">
                                            {{ month.number_used_mobile || '—' }}
                                        </td>
                                    </tr>
                                    <tr class="parc-data total">
                                        <td class="parc-title">
                                            {{ 'Total' | translate }}
                                        </td>
                                        <td *ngFor="let month of parcUsage?.months" class="month">
                                            {{ month.number_used_prolive || '—' }}
                                        </td>
                                    </tr>
                                    <tr class="parc-data">
                                        <td class="parc-title">% {{ 'Desktop' | translate }}</td>
                                        <td *ngFor="let month of parcUsage?.months" class="month">
                                            {{ (month.percent_used_desktop | numberToFixed) || '—' }}
                                        </td>
                                    </tr>
                                    <tr class="parc-data">
                                        <td class="parc-title">% {{ 'Mobile' | translate }}</td>
                                        <td *ngFor="let month of parcUsage?.months" class="month">
                                            {{ (month.percent_used_mobile | numberToFixed) || '—' }}
                                        </td>
                                    </tr>
                                    <tr class="parc-data total">
                                        <td class="parc-title">% {{ 'Total' | translate }}</td>
                                        <td *ngFor="let month of parcUsage?.months" class="month">
                                            {{ (month.percent_used_either | numberToFixed) || '—' }}
                                        </td>
                                    </tr>
                                </table>
                            </accordion-group>
                        </td>
                    </tr>
                </tbody>
            </table>
        </accordion>
    </div>
</div>
