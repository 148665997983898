import { AbstractControl, ValidationErrors } from '@angular/forms';

export class ValidateAddressLength {
    static createValidator(addressType: string, maxLength: number) {
        return (control: AbstractControl): ValidationErrors => {
            const errorObject = {};
            errorObject[addressType] = { valid: false, maxLength };
            return control.value.length <= maxLength ? null : errorObject;
        };
    }
}
