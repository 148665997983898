import H, { geo, Map as M, map, mapevents, service, ui } from '@here/maps-api-for-javascript';

/** This file has been created to fix the type problem from the Here map library
 * As you can see, all type from this library are exported from a namespace H as default
 * This seems to block typescript to find the types when you are creating a class instance
 * const domMarker = new H.map.DomMarker(); ==> any
 *
 * Thanks to this file, you can correctly get types as a simpler name
 * const domMarker: DomMarker = new DomMarker(); ==> DomMarker
 */

// Map
export type Map = M;
export const { Map } = H;

// map
export type DomMarker = map.DomMarker;
export type DomIcon = map.DomIcon;
export type Group = map.Group;
export const { Group, DomIcon, DomMarker } = H.map;

// mapevents
export type MapEvents = mapevents.MapEvents;
export type Behavior = mapevents.Behavior;
export type Event = mapevents.Event;
export const { MapEvents, Behavior } = H.mapevents;
export const { Feature } = H.mapevents.Behavior;

// service
export type Platform = service.Platform;
export const { Platform } = H.service;

// ui
export type InfoBubble = ui.InfoBubble;
export type UI = ui.UI;
export type ZoomControl = ui.ZoomControl;
export const { UI, ZoomControl, InfoBubble } = H.ui;

// geo
export type Point = geo.Point;
export const { Point } = H.math;
