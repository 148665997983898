<div class="kpi-total-error">
    <div class="sub-title">{{ 'Total error' | translate }}</div>
    <div class="title">{{ 'All publishers' | translate }}</div>

    <div *ngIf="(totalError$ | async) || {}; let totalError" class="total-error-table">
        <table>
            <tr class="state-header">
                <td class="empty-cell"></td>
                <td *ngFor="let state of states" class="state-name state-{{ state }}" translate>
                    {{ state }}
                </td>
                <td class="state-name">{{ 'Overall' | translate }}</td>
            </tr>
            <tbody class="publisher-rows">
                <tr *ngFor="let page of totalError?.data" class="publisher-row">
                    <td class="publisher-name">{{ page.publisher_name }}</td>
                    <td
                        *ngFor="let state of states"
                        class="state-{{ state }}"
                        [class.not-errored]="!isPublisherError(totalError, page.publisher_id, state)"
                    >
                        <span>
                            <a [routerLink]="getPublishersErrorLink(totalError, page.publisher_id, state)">
                                {{ totalError?.getStateCountByPublisher(page.publisher_id, state) || '—' }}
                            </a>
                        </span>
                    </td>
                    <td>
                        {{ (totalError?.meta?.overallPublishers)[page.publisher_id] }}
                    </td>
                </tr>
            </tbody>
            <tr class="total-row">
                <td class="sub-title">{{ 'Overall' | translate }}</td>
                <td *ngFor="let state of states" class="state-{{ state }}">
                    {{ totalError?.meta?.overallStates[state] }}
                </td>
                <td class="total-overall">
                    {{ totalError?.meta?.total }}
                </td>
            </tr>
        </table>
    </div>
</div>
