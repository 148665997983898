import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { LocationsSelectors } from '@slm/location/state';
import { OnBehalfOptions, selectOnBehalfOptions } from '@slm/on-behalf';
import { API_CONFIG } from '@slm/shared/environment';
import { SlmAuthFacade } from '@solocal-manager/auth';

import { AppConfig, AuthConfig } from '@solocal-manager/shared/util-common';
import { AzureTokenService } from '@solocal-manager/sirius/core/services';
import { Location } from '@solocal-manager/sirius/support/base-models';
import { Observable, from } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class HeadersInterceptor implements HttpInterceptor {
    private readonly apiConfig = inject(API_CONFIG);
    private readonly translateService = inject(TranslateService);

    constructor(
        private store: Store,
        private azureTokenService: AzureTokenService,
        private authConfig: AuthConfig,
        private appConfig: AppConfig,
        private slmAuthFacade: SlmAuthFacade,
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const urlPattern = new RegExp(`^${this.apiConfig.solocalApisBaseUrls.join('|')}`);
        const isAzureUrl = new RegExp(`^${this.apiConfig.apiAzureUrl}`);
        const isProxyAudienceApiUrl = /proxyaudienceapi.solocal.com/;
        const keyCloakUrl = 'sso.solocal.com';
        return this.store.select(selectOnBehalfOptions).pipe(
            take(1),
            switchMap(onBehalfOptions => {
                let headers = request.headers;

                if (/customer-requests\/\w*\/attachments/.test(request.url)) {
                    headers = this.addOnBehalfHeaders(headers, onBehalfOptions);
                    headers = headers.set('Solocal-Frontend-Type', this.authConfig.frontendType);
                    return next.handle(request.clone({ headers }));
                }

                if (urlPattern.test(request.url) && !request.url.includes(keyCloakUrl)) {
                    const content_type = request.headers.get('Content-Type');
                    headers = headers
                        .set('Content-Type', content_type ? content_type : 'application/json')
                        .set('Accept', 'application/json')
                        .set('Solocal-Frontend-Type', this.authConfig.frontendType)
                        .set('Solocal-Frontend-Version', this.appConfig.version)
                        .set('Accept-Language', this.translateService.currentLang || 'fr');

                    headers = this.addOnBehalfHeaders(headers, onBehalfOptions);

                    return next.handle(
                        request.clone({
                            headers,
                        }),
                    );
                }

                if (isAzureUrl.test(request.url) && !request.headers.get('jwt')) {
                    return this.handleAzureApiRequest(request, next);
                }

                if (isProxyAudienceApiUrl.test(request.url)) {
                    return from(this.slmAuthFacade.getToken()).pipe(
                        switchMap(token => {
                            return next.handle(
                                request.clone({
                                    setHeaders: {
                                        Authorization: `Bearer ${token}`,
                                    },
                                }),
                            );
                        }),
                    );
                }
                return next.handle(request);
            }),
        );
    }

    addOnBehalfHeaders(_headers: HttpHeaders, onBehalfOptions: OnBehalfOptions): HttpHeaders {
        let headers: HttpHeaders = _headers;

        if (onBehalfOptions?.email) {
            headers = headers.set('Solocal-Onbehalf-Email', onBehalfOptions.email);
        }

        if (onBehalfOptions?.epj) {
            headers = headers.set('Solocal-Onbehalf-Epj', onBehalfOptions.epj);
        }

        return headers;
    }

    private handleAzureApiRequest(request: HttpRequest<unknown>, next: HttpHandler) {
        return this.store.pipe(
            select(LocationsSelectors.getSelectedLocation),
            take(1),
            switchMap((location: Location) => {
                if (!location.id) {
                    return next.handle(request);
                }

                return this.azureTokenService.getHeaders([location.id]).pipe(
                    switchMap((headers: HttpHeaders) => {
                        const modifiedRequest = request.clone({
                            headers,
                        });

                        return next.handle(modifiedRequest);
                    }),
                );
            }),
        );
    }
}
