import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiErrorActions } from '@slm/shared/error';
import { Location } from '@solocal-manager/sirius/support/base-models';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import {
    DeleteDuplicate,
    DeleteDuplicateSuccess,
    DeletePage,
    DeletePageSuccess,
    GetPage,
    GetPageSuccess,
    getPublisherStatus,
    LocationActions,
    PageActionTypes,
    PatchPage,
    PatchPageSuccess,
    PostPageDisconnect,
    PostPageDisconnectSuccess,
} from '../actions';
import { PagesService } from '../services';

@Injectable({ providedIn: 'root' })
export class PagesEffects {
    getPage$ = createEffect(() =>
        this.update$.pipe(
            ofType<GetPage>(PageActionTypes.GET_PAGE),
            map(action => action.params),
            switchMap(params =>
                this.pagesService.get(params.pageId).pipe(
                    map(result => new GetPageSuccess(result)),
                    catchError(error => of(new ApiErrorActions.CatchApiError(error))),
                ),
            ),
        ),
    );
    deletePage$ = createEffect(() =>
        this.update$.pipe(
            ofType<DeletePage>(PageActionTypes.DELETE_PAGE),
            map(action => action.params),
            switchMap(params =>
                this.pagesService.delete(params.pageId).pipe(
                    map(
                        result =>
                            new DeletePageSuccess({
                                ...result,
                                location: params.location,
                            }),
                    ),
                    catchError(error => of(new ApiErrorActions.CatchApiError(error))),
                ),
            ),
        ),
    );
    postPageDisconnect$ = createEffect(() =>
        this.update$.pipe(
            ofType<PostPageDisconnect>(PageActionTypes.POST_PAGE_DISCONNECT),
            map(action => action.params),
            switchMap(params =>
                this.pagesService.postPageDisconnect(params.pageId).pipe(
                    map(result => new PostPageDisconnectSuccess(result)),
                    catchError(error => of(new ApiErrorActions.CatchApiError(error))),
                ),
            ),
        ),
    );
    updatePublisherStatusForCompanyAfterDisconnectSuccess$ = createEffect(() =>
        this.update$.pipe(
            ofType<PostPageDisconnectSuccess>(PageActionTypes.POST_PAGE_DISCONNECT_SUCCESS),
            map(action => action.params.data.location_id),
            switchMap((locationId: string) => of(getPublisherStatus({ params: { locationId } }))),
        ),
    );
    patchPage$ = createEffect(() =>
        this.update$.pipe(
            ofType<PatchPage>(PageActionTypes.PATCH_PAGE),
            map(action => action.params),
            switchMap(params =>
                this.pagesService.patch(params.pageId, params.page).pipe(
                    map(result => new PatchPageSuccess(result)),
                    catchError(error => of(new ApiErrorActions.CatchApiError(error))),
                ),
            ),
        ),
    );
    updatePublisherStatusAfterDeleteSuccess$ = createEffect(() =>
        this.update$.pipe(
            ofType<DeletePageSuccess>(PageActionTypes.DELETE_PAGE_SUCCESS),
            map(action => action.result.location),
            switchMap((location: Location) => of(getPublisherStatus({ params: { location } }))),
        ),
    );
    updateLocationAfterDeleteSuccess$ = createEffect(() =>
        this.update$.pipe(
            ofType<DeletePageSuccess>(PageActionTypes.DELETE_PAGE_SUCCESS),
            map(action => action.result.location),
            switchMap((location: Location) => of(this.locationActions.getLocation(location.id))),
        ),
    );
    updatePublisherStatusAfterPatchSuccess$ = createEffect(() =>
        this.update$.pipe(
            ofType<PatchPageSuccess>(PageActionTypes.PATCH_PAGE_SUCCESS),
            map(action => action.result.location_id),
            switchMap((locationId: string) => of(getPublisherStatus({ params: { locationId } }))),
        ),
    );
    deleteDuplicate$ = createEffect(() =>
        this.update$.pipe(
            ofType<DeleteDuplicate>(PageActionTypes.DELETE_DUPLICATE),
            map(action => action.params),
            switchMap(params =>
                this.pagesService.deleteDuplicate(params.publisherId, params.publisherPageId, params.locationId).pipe(
                    map(() => {
                        return new DeleteDuplicateSuccess({
                            locationId: params.locationId,
                        });
                    }),
                    catchError(error => of(new ApiErrorActions.CatchApiError(error))),
                ),
            ),
        ),
    );
    updatePublisherStatusAfterDeleteDuplicateSuccess$ = createEffect(() =>
        this.update$.pipe(
            ofType<DeleteDuplicateSuccess>(PageActionTypes.DELETE_DUPLICATE_SUCCESS),
            map(action => action.result.locationId),
            switchMap((locationId: string) => {
                return of(getPublisherStatus({ params: { locationId } }));
            }),
        ),
    );

    constructor(
        public update$: Actions,
        public pagesService: PagesService,
        public locationActions: LocationActions,
    ) {}
}
