import { Component, OnChanges, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { PaymentMethod } from '@solocal-manager/sirius/core/models';

@Component({
    selector: 'app-input-payment-methods',
    templateUrl: './input-payment-methods.component.html',
    styleUrls: ['./input-payment-methods.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputPaymentMethodsComponent),
            multi: true,
        },
    ],
})
export class InputPaymentMethodsComponent implements OnChanges, ControlValueAccessor {
    constructor(public translate: TranslateService) {}

    checkedPaymentMethods: PaymentMethod[] = [];

    @Input() paymentMethods: PaymentMethod[];
    @Input() paymentMethodsValue: any[];
    propagateChange: any = () => {};

    ngOnChanges(changed) {
        if (!!this.paymentMethods && !!this.paymentMethodsValue) {
            /*
                BE is using method.payment_method for saving not method.id
                but this will maybe change. so just created this hack to store payment_method into id.
                if the api is changing we can remmove this line, and fix the type of  PaymentMethod model "id" -> string
            */
            this.paymentMethods.forEach(method => {
                method.id = method.payment_method;
                this.translate.get(method.name).subscribe(translated => (method.name = translated));

                // method. = this.translate.get( method.name );
            });

            this.checkedPaymentMethods = this.paymentMethods.filter(
                method => this.paymentMethodsValue.indexOf(method.id) !== -1,
            );
        }
    }

    selectPaymentMethods(selectedPaymentMethods: PaymentMethod[]) {
        this.checkedPaymentMethods = selectedPaymentMethods;
        this.paymentMethodsValue = this.checkedPaymentMethods.map(method => method.id);
        this.propagateChange(this.paymentMethodsValue);
    }

    writeValue(value) {
        if (value) {
            this.paymentMethodsValue = value;
        }
    }

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    registerOnTouched() {}
}
