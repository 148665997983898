import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { LocationsActions } from '@slm/location/state';
import { getUserSuccess, User } from '@slm/user/state';
import { loadCiamContactSuccess } from '@solocal-manager/sirius/core/actions';
import { ICiamContactV2 } from '@solocal-manager/sirius/core/models';
import { LocationsDTO } from '@solocal-manager/sirius/support/base-models';
import * as moment from 'moment';
import { map, tap } from 'rxjs/operators';

import { TagManagerService } from '../services/tag-manager.service';

/**
 * Effects used to add values in the `user` variable of the `data layer`
 */
@Injectable({ providedIn: 'root' })
export class UserAnalyticsEffects {
    userAnalytics$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(getUserSuccess),
                map(action => action.user),
                tap((_user: User) => {
                    let user: any = {
                        idCupro: _user.partner_id,
                        type: this.getUserType(_user),
                        presenceRefHeld: _user.packs.join(' | '),
                    };
                    if (_user.date_joined) {
                        user = {
                            ...user,
                            dateJoined: moment(_user.date_joined).format('DD/MM/YYYY HH:mm:ss'),
                        };
                    }
                    this.gtmService.dlVariables({
                        user,
                    });
                }),
            ),
        { dispatch: false },
    );

    userAnalyticsContact$createEffect = createEffect(
        () =>
            this.actions$.pipe(
                ofType(loadCiamContactSuccess),
                map((action: { payload: ICiamContactV2 }) => action.payload),
                tap((contact: ICiamContactV2) => {
                    if (contact) {
                        this.gtmService.dlVariables({
                            user: {
                                email: contact.email,
                                firstName: contact.firstname,
                                lastName: contact.lastname,
                            },
                        });
                    }
                }),
            ),
        { dispatch: false },
    );
    userAnalyticsLocations$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(LocationsActions.loadLocationsSuccess),
                map(action => action.locations),
                tap((res: LocationsDTO) => {
                    if (res.currentPage === 1) {
                        this.gtmService.dlVariables({
                            user: {
                                establishmentNumber: String(res.count),
                            },
                        });
                    }
                }),
            ),
        { dispatch: false },
    );

    constructor(
        private actions$: Actions,
        private gtmService: TagManagerService,
    ) {}

    getUserType(user: User): string {
        if (user.is_onbehalf_customer) {
            return 'support';
        }
        if (['', '00000000', '2714629'].includes(user.partner_id)) {
            return 'internal';
        }
        return 'client';
    }
}
