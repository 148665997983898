import { inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import { Action, Store } from '@ngrx/store';
import {
    ILocationListHttpOptions,
    LocationListService,
    LocationService,
    LocationsListHttpService,
    WpmLocationService,
} from '@slm/location/api';
import { HOST_APP } from '@slm/shared/environment';
import { ApiErrorActions } from '@slm/shared/error';
import { ELocationRole, Location, LocationsDTO, PaginationDTO } from '@solocal-manager/sirius/support/base-models';
import { concat, Observable, of } from 'rxjs';
import { catchError, concatMap, delay, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { setSelectedIdFromIds } from './location.utils';
import * as LocationsActions from './locations.actions';
import * as LocationsSelectors from './locations.selectors';

@Injectable({ providedIn: 'root' })
export class LocationsEffects {
    setSingleLocation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LocationsActions.setSingleLocation),
            map(action => action.location),
            switchMap(location =>
                of(
                    LocationsActions.stopFetchingLocations(),
                    LocationsActions.loadLocationsSuccess({
                        locations: this.locationListService.getLimitedLocationList(location),
                    }),
                    LocationsActions.setSelectedLocation({ id: location.id }),
                ),
            ),
        ),
    );

    getLocation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LocationsActions.loadLocation),
            map(action =>
                LocationsActions.getOrSetLocation({
                    opType: 'GET',
                    ...action,
                }),
            ),
        ),
    );

    setFetchedLocation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LocationsActions.setFetchedLocation),
            map(action =>
                LocationsActions.getOrSetLocation({
                    opType: 'SET',
                    ...action,
                }),
            ),
        ),
    );

    getOrSetLocation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LocationsActions.getOrSetLocation),
            fetch({
                id: action => LocationsActions.getIdForGetOrSetLocationAction(action),
                run: action => {
                    switch (action.opType) {
                        case 'GET':
                            return this.locationService.getLocation(action.id).pipe(
                                switchMap(location => this.createLoadLocationSuccessObs(location, action.selectIt)),
                                catchError(err =>
                                    of(
                                        new ApiErrorActions.CatchApiError({
                                            source: 'getLocation',
                                            getLocation: err,
                                        }),
                                    ),
                                ),
                            );
                        case 'SET':
                            return this.createLoadLocationSuccessObs(action.location, action.selectIt);
                    }
                },
            }),
        ),
    );
    patchLocation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LocationsActions.patchLocation),
            switchMap(({ id, locationUpdate }) => {
                return this.locationService.patchLocation(id, locationUpdate).pipe(
                    map(result =>
                        LocationsActions.patchLocationSucces({
                            id,
                            location: result,
                        }),
                    ),
                    catchError(error => {
                        this.locationService.emitLocationUpdateError(error);
                        const actions: Observable<Action>[] = [of(new ApiErrorActions.CatchApiError(error))];
                        if (error.status === 409) {
                            actions.push(
                                of(
                                    LocationsActions.loadLocation({
                                        id: id,
                                        selectIt: false,
                                    }),
                                ),
                            );
                        }

                        return concat(...actions);
                    }),
                );
            }),
        ),
    );
    patchLocationsBulk$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LocationsActions.patchLocationsBulk),
            switchMap(action =>
                this.wpmLocationService.patchLocationsBulk(action.locationIds, action.locationPartial).pipe(
                    switchMap((result: { success: boolean }) => {
                        if (result && result.success) {
                            return [
                                LocationsActions.patchLocationsBulkSuccess({
                                    locationIds: action.locationIds,
                                    locationPartial: action.locationPartial,
                                }),
                                LocationsActions.unselectLocation(),
                            ];
                        }

                        return of(
                            new ApiErrorActions.CatchApiError(new Error('Unsuccessful Patch Location Operation')),
                        );
                    }),
                    catchError(error => of(new ApiErrorActions.CatchApiError(error))),
                ),
            ),
        ),
    );
    putLocation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LocationsActions.putLocation),
            map(action => action.locationUpdate),
            switchMap(locationUpdate =>
                this.locationService.updateLocation(locationUpdate).pipe(
                    map(updatedLocation => LocationsActions.putLocationSuccess({ location: updatedLocation })),
                    catchError(error => {
                        const actions: Array<Observable<Action>> = [of(new ApiErrorActions.CatchApiError(error))];
                        if (error.status === 409) {
                            actions.push(of(LocationsActions.loadLocation({ id: locationUpdate.id, selectIt: false })));
                        }

                        return concat(...actions);
                    }),
                ),
            ),
        ),
    );

    patchSelectedLocationSiteCatalogSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LocationsActions.patchSelectedLocationSiteCatalogSettings),
            switchMap(action =>
                this.wpmLocationService
                    .patchLocationSiteCatalogSettings(action.locationId, action.email, action.status)
                    .pipe(map(res => ({ ...res, locationId: action.locationId }))),
            ),
            filter(res => res?.data?.activate ?? false),
            concatMap(({ locationId }) => [
                LocationsActions.loadLocation({ id: locationId, selectIt: true }),
                LocationsActions.loadLocations({ request: { companyId: 'all' } }),
            ]),
        ),
    );

    setSelectedIdOnSelectedIdsChange = createEffect(() =>
        this.actions$.pipe(
            ofType(LocationsActions.setSelectedIds),
            map(action => action.selectedIds),
            concatLatestFrom(() => this.store.select(LocationsSelectors.getAllLocations)),
            map(([ids, locations]) => setSelectedIdFromIds(ids, locations)),
            filter(id => !!id),
            map(id => LocationsActions.setSelectedLocation({ id })),
        ),
    );

    delayTime = 1000;

    loadLocations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LocationsActions.loadLocations, LocationsActions.stopFetchingLocations),
            fetch({
                run: action => {
                    if (action.type === LocationsActions.StopFetchingLocationsType) {
                        return of();
                    }
                    const loadLocationsRequest = action.request;
                    return this.loadLocationsDTO(loadLocationsRequest).pipe(
                        filter(res => !!res),
                        switchMap((res: LocationsDTO) => {
                            const pageNumber = loadLocationsRequest?.config?.pageNumber || 1;

                            return loadLocationsRequest?.config?.fetchAll && res?.next_page
                                ? concat(
                                      of(LocationsActions.loadLocationsSuccess({ locations: res })),
                                      of(
                                          LocationsActions.loadLocations({
                                              request: {
                                                  ...loadLocationsRequest,
                                                  config: {
                                                      ...loadLocationsRequest.config,
                                                      pageNumber: pageNumber + 1,
                                                  },
                                              },
                                              continuation: true,
                                          }),
                                      ).pipe(delay(this.delayTime)),
                                  )
                                : of(LocationsActions.loadLocationsSuccess({ locations: res }));
                        }),
                    );
                },

                onError: (action, error) => {
                    console.error('Error', error);
                    return LocationsActions.loadLocationsFailure({ error });
                },
            }),
        ),
    );

    getLocationRetentionEligibility$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(LocationsActions.getLocationRetentionEligibility),
            switchMap(({ epj }) => this.locationService.getLocationRetention(epj)),
            withLatestFrom(this.store.select(LocationsSelectors.getSelectedId)),
            map(([response, locationId]) =>
                LocationsActions.getLocationRetentionEligibilitySuccess({ ...response, locationId }),
            ),
        );
    });

    private readonly hostApp = inject(HOST_APP);

    constructor(
        private actions$: Actions,
        private locationListService: LocationListService,
        private locationListHttpService: LocationsListHttpService,
        private locationService: LocationService,
        private wpmLocationService: WpmLocationService,
        private readonly store: Store,
    ) {}

    loadLocationsDTO(request: LocationsActions.LocationsLoadRequest): Observable<LocationsDTO> {
        return this.hostApp.isSlmWeb
            ? this.getUserLocationsDTO(request)
            : this.locationListService.getLocationList(request).pipe(map(this.removePrehabilitatedLocations));
    }

    private removePrehabilitatedLocations(res: LocationsDTO): LocationsDTO {
        return res?.data
            ? {
                  ...res,
                  data: res.data.filter(
                      location => !location.roles || !location.roles?.includes(ELocationRole.preHabilitated),
                  ),
              }
            : res;
    }

    private getUserLocationsDTO(request: LocationsActions.LocationsLoadRequest): Observable<LocationsDTO> {
        const options: ILocationListHttpOptions = {
            fields: request?.config?.fields,
            page_number: request?.config?.pageNumber,
            page_size: request?.config?.pageSize,
            partner_id: request?.config?.partnerIds?.join(','),
            packs: request?.config?.filter?.packs,
            location_name: request?.config?.filter?.locationName,
            roles: [ELocationRole.preHabilitated, ELocationRole.admin, ELocationRole.bridge],
        };

        if (request?.config?.shouldReturnMeta) {
            options.fields = ['meta', 'custom_data'].concat(options?.fields ?? []);
        }

        return this.locationListHttpService.getAll(options).pipe(
            map((res: PaginationDTO<Location>) => {
                const { pageNumber, pageSize, pageOnly } = request?.config ?? {
                    pageNumber: 1,
                    pageSize: 0,
                    pageOnly: false,
                };

                let position: number = pageNumber * pageSize - (pageSize - 1);

                return {
                    ...res,
                    currentPage: pageNumber,
                    pageOnly,
                    data: res.data.map(d => ({
                        ...d,
                        position: position++,
                        hidden: false,
                    })),
                };
            }),
        );
    }

    private createLoadLocationSuccessObs(location: Location, selectIt: boolean) {
        if (selectIt && location.id != null) {
            return of(
                LocationsActions.loadLocationSuccess({ location }),
                LocationsActions.setSelectedLocation({ id: location.id }),
            );
        }

        return of(LocationsActions.loadLocationSuccess({ location }));
    }
}
