import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';

import { getPublisherStatus, PublisherConnectService } from '@solocal-manager/sirius/core/core';
import { Location } from '@solocal-manager/sirius/support/base-models';

import { connectablePublishers, websitePublishers } from '../../config';

@Component({
    selector: 'slm-publisher-connect',
    templateUrl: './publisher-connect.component.html',
    styleUrls: ['./publisher-connect.component.scss'],
})
export class PublisherConnectComponent implements OnChanges, OnDestroy {
    connectablePublishers = connectablePublishers;

    publisherConnectionSubscribtion;
    isClaimed: any = {};

    websiteTypePublishers = websitePublishers;

    @Input() location: Location;
    @Input() publisherId: string;
    @Input() autoSearch = false;
    @Input() lastDisconnected: any;

    @Output() closeModal: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() resetPublisherId: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        public logger: NGXLogger,
        public store: Store,
        public publisherConnectService: PublisherConnectService,
    ) {}

    get shouldRenderConnectWebsite(): boolean {
        return this.websiteTypePublishers[this.publisherId];
    }

    ngOnChanges(changed) {
        this.logger.debug('<PublisherConnectComponent> ngOnChanges(changed)', changed);
        const publisherId = changed && changed.publisherId ? changed.publisherId.currentValue : '';

        if (publisherId && this.connectablePublishers[publisherId] === 'click') {
            this.connect('');
        }
    }

    connect(pageId: string) {
        this.logger.debug('<PublisherConnectComponent> connect: ', pageId);

        this.publisherConnectionSubscribtion = this.publisherConnectService
            .connect(this.location.id, this.publisherId, pageId)
            .subscribe(res => {
                this.reloadPublisherStatus();
                this.onClaimed(true);
            });

        this.resetPublisherId.emit(true);
    }

    onClaimed(isClaimed) {
        this.isClaimed[this.publisherId] = isClaimed;
        this.reloadPublisherStatus();
        this.logger.debug('<PublisherConnectComponent> onClaimed: ', isClaimed, this.isClaimed);
        this.closeModal.emit(true);
    }

    reloadPublisherStatus() {
        this.store.dispatch(getPublisherStatus({ params: { location: this.location } }));
    }

    ngOnDestroy(): void {
        if (this.publisherConnectionSubscribtion) {
            this.publisherConnectionSubscribtion.unsubscribe();
        }
    }
}
