import { Component, Input } from '@angular/core';

import { Location } from '@solocal-manager/sirius/support/base-models';

import { environment } from '../../../../environments/environment';

@Component({
    selector: 'slm-on-behalf',
    templateUrl: './on-behalf.component.html',
    styleUrls: ['./on-behalf.component.scss'],
})
export class OnBehalfComponent {
    @Input() location: Location;

    openSolocalManager(partnerId: string, epj: string): void {
        if (partnerId) {
            window.open(`${environment.uiUrl}#/app/2/location/${partnerId}/content?onbehalfEpj=${epj}`);
        }
    }
}
