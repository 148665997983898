import { ElementRef, EventEmitter, OnDestroy, OnInit, Output, Directive } from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

export function createSubformSingleValidator(form) {
    return (c: UntypedFormControl) => {
        const err = { subFormError: { valid: form.valid } };
        return form.valid ? null : err;
    };
}

@Directive({})
export class InputSingle implements ControlValueAccessor, OnInit, OnDestroy {
    formSubscribtion;
    formSingle: UntypedFormGroup;
    _config: any;
    _validators: any[];
    _asyncValidators: any[];
    _singleInput: ElementRef;

    @Output() onChanges: EventEmitter<any> = new EventEmitter();

    propagateChange: any = () => {};
    validateFn: any = () => {};

    constructor(protected formBuilder: UntypedFormBuilder) {
        this.formSingle = this.formBuilder.group({
            single: [''],
        });
    }

    get wpmInputConfig() {
        return this._config;
    }

    ngOnInit(): void {
        this.formSubscribtion = this.formSingle.valueChanges.subscribe(controls => {
            this.propagateChange();
        });

        this.formSubscribtion = this.formSingle.statusChanges.subscribe(status => {
            this.propagateChange();
        });
    }

    // ngAfterViewInit() {
    //     this.formSingle.get('single').updateValueAndValidity();
    // }

    setValidators() {
        if (this._validators && this._validators.length) {
            this.formSingle.get('single').setValidators(this._validators);
        }

        if (this._asyncValidators && this._asyncValidators.length) {
            this.formSingle.get('single').setAsyncValidators(this._asyncValidators);
        }

        this.formSingle.get('single').updateValueAndValidity();
    }

    registerOnChange(fn) {
        this.propagateChange = function () {
            if (this.formSingle.get('single').valid) {
                if (this._config.inputKey) {
                    // data has to be propagated to parent as object
                    const clonedData = { ...this._config.initData };
                    clonedData[this._config.inputKey] = this.formSingle.get('single').value;

                    return fn(clonedData);
                }

                // data has to be propagated to parent as string
                let val = this.formSingle.get('single').value;
                val = this._config.forceType === 'number' ? parseFloat(val) : val;

                return fn(val);
            }

            return fn(null);
        };
    }

    registerOnTouched() {}

    validate(c: UntypedFormControl) {
        return this.validateFn(c);
    }

    writeValue(value) {
        if (value && this._config) {
            if (this._config.inputKey) {
                // coming from parent as object
                this.formSingle.get('single').setValue(value[this._config.inputKey]);
            } else {
                // coming from parent as string
                value = this._config.forceType === 'number' ? parseFloat(value) : value;
                this.formSingle.get('single').setValue(value);
            }

            this.validateFn = createSubformSingleValidator(this.formSingle);
        }
    }

    inputChanged($event) {
        let inputValue = $event.target.value;
        inputValue = this._config.forceType === 'number' ? parseFloat(inputValue) : inputValue;
        inputValue =
            this._config.label === 'Price'
                ? parseFloat(inputValue.replace(/[^\d.-]/g, ''))
                    ? inputValue.replace(/[^\d.-]/g, '')
                    : ''
                : inputValue;

        this.formSingle.get('single').setValue(inputValue);

        this.onChanges.emit({
            type: this.wpmInputConfig.type,
            value: inputValue,
        });
    }

    clearField($event) {
        this.formSingle.get('single').setValue('');

        this.onChanges.emit({
            type: this.wpmInputConfig.type,
            value: null,
        });
    }

    onMouseEnter($event) {
        $event.target.focus();
    }

    ngOnDestroy(): void {
        if (this.formSubscribtion) {
            this.formSubscribtion.unsubscribe();
        }
    }
}
