import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Address } from '@solocal-manager/sirius/support/base-models';

@Injectable({ providedIn: 'root' })
export class AddressService {
    constructor(private readonly translateService: TranslateService) {}

    getLine(address: Address, line?: number, noCountry?: boolean): string {
        let addressStr = '';
        if (!address) {
            return this.translateService.instant('Unknown address');
        }
        if (line === 1) {
            addressStr = `${address.house_number || ''} ${address.address_line1} ${address.address_line2}`;
        }
        if (line === 2) {
            addressStr = `${address.zip_code} ${address.city} ${!noCountry ? address.country : ''}`;
        }
        if (!line) {
            addressStr = `${address.house_number || ''} ${address.address_line1} ${address.address_line2 || ''} ${
                address.zip_code
            } ${address.city} ${!noCountry ? address.country : ''}`;
        }
        return addressStr.replace(/([ ]+|null|undefined)(?= )/g, '').trim();
    }
}
