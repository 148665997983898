import { forwardRef, Component, OnDestroy, OnInit } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    UntypedFormArray,
    UntypedFormBuilder,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    Validators,
} from '@angular/forms';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { createSubformValidator, WpmInputRepeatable } from '@solocal-manager/sirius/core/core';
import { OpenningHoursValidator } from '@solocal-manager/sirius/core/form';
import { TimePeriod } from '@solocal-manager/sirius/support/base-models';

import { OverlappingValidator } from '../validators/overlapping.validator';

@Component({
    selector: 'app-input-opening-hours',
    templateUrl: './input-opening-hours.component.html',
    styleUrls: ['./input-opening-hours.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputOpeningHoursComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => InputOpeningHoursComponent),
            multi: true,
        },
    ],
})
export class InputOpeningHoursComponent extends WpmInputRepeatable implements ControlValueAccessor, OnInit, OnDestroy {
    days = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
    timeSlots = this.createOpeningHoursSteps();
    _initData = {
        open_day: ['', []],
        close_day: ['', []],
        open_time: ['', [Validators.required]],
        close_time: ['', []],
    };
    destroyed$: Subject<boolean> = new Subject();
    MAX_NUMBER_OF_SLOTS_PER_DAY = 2;

    constructor(public formBuilder: UntypedFormBuilder) {
        super(formBuilder);
        this.formRepeatable.get('data').setValidators([OverlappingValidator.create]);
    }

    ngOnInit(): void {
        this.formRepeatable.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(controls => {
            this.propagateChange(controls);
        });
    }

    set currentData(data: any) {
        this._currentData = data.periods;
    }

    get currentData() {
        return this._currentData;
    }

    initDays() {
        // check for 'closed' days
        const repeatableControls = <UntypedFormArray>this.formRepeatable.controls.data;
        this.days.forEach(day => {
            const filledDays = repeatableControls.controls.filter(control => control.value.open_day === day);
            if (!filledDays.length) {
                this.addInputForDay(day, 'closed');
            }
        });
    }

    initGroupValidators() {
        const repeatableControls = <UntypedFormArray>this.formRepeatable.controls.data;
        repeatableControls.controls.forEach(control => {
            control.setValidators(OpenningHoursValidator.create(this.formRepeatable));
        });
    }

    initInputRowForDay(day: string, openTime: string, closeTime: string) {
        const newRow = {
            open_day: [day, []],
            close_day: [day, []],
            open_time: [openTime ? openTime : '', [Validators.required]],
            close_time: [closeTime ? closeTime : '', []],
        };
        return this.formBuilder.group(newRow, {
            validator: OpenningHoursValidator.create(this.formRepeatable),
        });
    }

    writeValue(value) {
        value = value || [];
        this.prepareTimeSlots(value.periods);

        this.currentData = value;

        this.buildFormControls();
        this.formRepeatable.setValue({ data: this.currentData });
        this.validateFn = createSubformValidator(this.formRepeatable);

        this.initDays();
        this.initGroupValidators();

        const repeatableControls = <UntypedFormArray>this.formRepeatable.controls.data;
        repeatableControls.controls.forEach(control => this.timeChanged(control));
    }

    prepareTimeSlots(periods: TimePeriod[]): void {
        periods.forEach((period: TimePeriod) => {
            if (!this.timeSlots.includes(period.open_time)) {
                this.insertSlot(period.open_time);
            }

            if (!this.timeSlots.includes(period.close_time)) {
                this.insertSlot(period.close_time);
            }
        });
    }

    insertSlot(slot: string): void {
        let i = 0;
        let time = 0;
        const slotToInsert: number = parseFloat(slot.split(':').join('.'));

        for (i; i < this.timeSlots.length; i++) {
            time = parseFloat(this.timeSlots[i].split(':').join('.'));
            if (slotToInsert < time) {
                this.timeSlots.splice(this.timeSlots.indexOf(this.timeSlots[i]), 0, slot);
                break;
            }
        }
    }

    addInputForDay(day: string, openTime?: string, closeTime?: string) {
        const repeatableControls = <UntypedFormArray>this.formRepeatable.controls.data;
        repeatableControls.push(this.initInputRowForDay(day, openTime, closeTime));
        repeatableControls.controls.sort((a, b) => {
            if (a.value.open_day === b.value.open_day) {
                return moment(a.value.open_time, 'hh:mm').diff(moment(b.value.close_time, 'hh:mm'));
            }
            return this.days.indexOf(a.value.open_day) - this.days.indexOf(b.value.open_day);
        });
    }

    timeChanged(control: AbstractControl) {
        const openDay = control.get('open_day');
        const closeDay = control.get('close_day');
        const openTime = control.get('open_time');
        const closeTime = control.get('close_time');

        // extra check to match mobile app (no 24:00 option there)
        if (openDay.value === closeDay.value && closeTime.value === '24:00') {
            closeTime.setValue('00:00');
        }

        // compare and set if next day is not set up yet
        if (openDay.value === closeDay.value || !closeDay.value) {
            if (
                // example from 16:00 to 16:00 is 24 hours - close day should be next day
                openTime.value === closeTime.value ||
                // close time is before open time
                (!this.isTimeBefore(openTime.value, closeTime.value) && closeTime.value !== '00:00')
            ) {
                // set close day to next day if close time is before
                closeDay.setValue(this.getNextDay(closeDay.value));
            }
        }

        // if close and open days are different
        if (closeDay.value && openDay.value !== closeDay.value) {
            if (
                // open time is before close time
                this.isTimeBefore(openTime.value, closeTime.value) ||
                closeTime.value === '00:00'
            ) {
                // close day should be the same day
                closeDay.setValue(openDay.value);
            }
        }
    }

    isTimeBefore(openTime: string, closeTime: string) {
        const open = moment(openTime, 'hh:mm');
        const close = moment(closeTime, 'hh:mm');
        const isBefore = open.isBefore(close);

        return isBefore;
    }

    getNextDay(day: string) {
        let currentDayIndex = this.days.indexOf(day);
        currentDayIndex++;

        return currentDayIndex === this.days.length ? this.days[0] : this.days[currentDayIndex];
    }

    isAddingTimeEnabled(openingHours, i): boolean {
        if (!openingHours.controls.open_time.value || openingHours.controls.open_time.value === 'closed') {
            return false;
        }
        const repeatableControls = <UntypedFormArray>this.formRepeatable.controls.data;
        const controlsByDay = repeatableControls.controls.filter(
            control => control.value.open_day === openingHours.controls.open_day.value,
        );
        const nextControl = repeatableControls.controls[i + 1];
        return (
            controlsByDay.length !== this.MAX_NUMBER_OF_SLOTS_PER_DAY &&
            (!nextControl || (nextControl && nextControl.value.open_day !== openingHours.controls.open_day.value))
        );
    }

    setToClosed(openingHours, i) {
        const repeatableControls = <UntypedFormArray>this.formRepeatable.controls.data;
        for (
            let j = i + 1;
            repeatableControls.controls[j] &&
            repeatableControls.controls[j].value.open_day === openingHours.controls.open_day.value;
            j++
        ) {
            this.removeInput(j);
        }
        openingHours.controls.open_time.setValue('closed');
        openingHours.markAsDirty();
    }

    isTimeslotAdditional(openingHours, i): boolean {
        const repeatableControls = <UntypedFormArray>this.formRepeatable.controls.data;
        return (
            repeatableControls.controls[i - 1] &&
            repeatableControls.controls[i - 1].value.open_day === openingHours.controls.open_day.value
        );
    }

    isApplyingToAllEnabled(i) {
        const repeatableControls = <UntypedFormArray>this.formRepeatable.controls.data;
        const firstTimeslot = repeatableControls.controls[0];
        if (i !== 0 || !firstTimeslot.valid || firstTimeslot.value.open_time === 'closed') {
            return false;
        }
        for (let j = 1; repeatableControls.controls[j].value.open_day === firstTimeslot.value.open_day; j++) {
            if (!repeatableControls.controls[j].valid) {
                return false;
            }
        }
        return true;
    }

    applyToAll() {
        // store values to apply
        const values: TimePeriod[] = [];
        const repeatableControls = <UntypedFormArray>this.formRepeatable.controls.data;
        const firstTimeslot = repeatableControls.controls[0];
        values.push(firstTimeslot.value);
        for (let i = 1; repeatableControls.controls[i].value.open_day === firstTimeslot.value.open_day; i++) {
            values.push(repeatableControls.controls[i].value);
        }
        // erase old values
        let j = repeatableControls.controls.length;
        while (j--) {
            this.removeInput(j);
        }
        // generate new timeslots
        this.days.forEach(day => {
            values.forEach(value => {
                this.addInputForDay(day, value.open_time, value.close_time);
            });
        });

        repeatableControls.controls.forEach(control => {
            this.timeChanged(control);
        });
    }

    registerOnChange(fn) {
        this.propagateChange = newValue => this.transformValue(newValue, fn);
    }

    transformValue(newValue: { data: TimePeriod[] }, fn?) {
        const periods: TimePeriod[] = [];
        newValue?.data.forEach((period: TimePeriod) => {
            // translate displayed 00:00 to 24:00 to be saved
            if (period.open_day === period.close_day && period.close_time === '00:00') {
                period.close_time = '24:00';
            }

            if (period.open_time !== 'closed') {
                periods.push(period);
            }
        });

        return fn ? fn({ periods }) : { periods };
    }

    removeTimeslot(openingHours, i) {
        const openDayValue = openingHours.controls.open_day.value;
        this.removeInput(i);
        const repeatableControls = <UntypedFormArray>this.formRepeatable.controls.data;
        const controlsByDay = repeatableControls.controls.filter(control => control.value.open_day === openDayValue);
        controlsByDay.forEach(control => {
            control.updateValueAndValidity();
        });
    }

    private createOpeningHoursSteps() {
        const minutes = 5;
        const times = ['closed'];
        let tt = 0;

        for (let i = 1; tt < 24 * 60 + minutes; i++) {
            const hh = Math.floor(tt / 60);
            const mm = tt % 60;
            const h = (hh === 24 ? '24' : '0' + (hh % 24)).slice(-2);
            const m = ('0' + mm).slice(-2);
            const time = h + ':' + m;

            times[i] = time;
            tt += minutes;
        }

        // returns time slots without last 24:00 to match the mobile app
        times.pop();

        return times;
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}
