import { ChatbotActions } from '@solocal-manager/sirius/core/actions';
import { ActionWithPayload, ChatbotInfo } from '@solocal-manager/sirius/core/models';

export type chatbotInfoState = ChatbotInfo;

const initialState: chatbotInfoState = {
    chatbot_eligible: false,
    chatbot_product_code: '',
    typology: '',
    delivery_status: '',
    livechat_eligible: false,
    phone_eligible: false,
};

export function chatbotInfoReducer(
    state = initialState,
    action: ActionWithPayload<chatbotInfoState>,
): chatbotInfoState {
    switch (action.type) {
        case ChatbotActions.SET_CHATBOT_INFO: {
            return action.payload;
        }
        default:
            return state;
    }
}
