import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@slm/shared/environment';
import { TimePeriod } from '@solocal-manager/sirius/support/base-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ChatConfig, GbmWizard, IChatLocationList, IPaginationConversation } from '../models';
import { ConversationList } from '../models/conversation.model';

const WEEKDAYS = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

/**
 * @class ChatService
 * @description Chat services:
 *                -    Get chat configuration
 *                -    list of smooch(sunshine) conversations
 *                -    Get all smooch app (connected to an epj)
 */
@Injectable({ providedIn: 'root' })
export class ChatService {
    public conversations: ConversationList;

    constructor(
        private http: HttpClient,
        private environmentService: EnvironmentService,
    ) {}

    /**
     * @function getChatConfig
     * @description Retrieve Chat Configuration for a location related to the authenticated user
     * @method GET
     * @param {string} epj
     * @returns {Observable<ChatConfig>} Chat Configuration for a Location
     */
    getChatConfig(epj?: string): Observable<ChatConfig> {
        const url = `${this.environmentService.apiUrl}/consumer/chat/${epj}/configuration`;
        return this.http.get(url).pipe(
            map((result: { data: ChatConfig }) => {
                return result.data;
            }),
        );
    }

    /**
     * @function putChatConfig
     * @description Set Chat Configuration for a location related to the authenticated user
     * @method PUT
     * @param {string} epj
     */
    putChatConfig(epj: string, chatConfig: ChatConfig): Observable<any> {
        const headers = new HttpHeaders().append('Content-Type', 'application/json');
        const url = `${this.environmentService.apiUrl}/consumer/chat/${epj}/configuration`;
        const body = JSON.stringify(chatConfig);
        return this.http
            .put<{ data: string }>(url, body, {
                headers,
            })
            .pipe(map(res => res.data));
    }

    getChatConversations(epj: string, pageNumber = 1): Observable<IPaginationConversation> {
        const params = new HttpParams()
            .append('ordering', '-last_message_date')
            .append('page_size', '10')
            .append('page_number', `${pageNumber}`);
        const url = `${this.environmentService.apiUrl}/consumer/chat/${epj}/conversations`;
        return this.http.get(url, { params }).pipe(
            map((result: any) => {
                return result;
            }),
        );
    }

    getMultiLocationChatInfos(pageNumber = 1): Observable<IChatLocationList> {
        const params = new HttpParams()
            .append('ordering', '-last_message_date')
            .append('page_size', '10')
            .append('page_number', `${pageNumber}`);
        const url = `${this.environmentService.apiUrl}/consumer/chat/multilocation`;
        return this.http.get(url, { params }).pipe(
            map((result: any) => {
                return result;
            }),
        );
    }

    putMultiLocationChatActvation(locations: ChatConfig): Observable<any> {
        const headers = new HttpHeaders().append('Content-Type', 'application/json');
        const url = `${this.environmentService.apiUrl}/consumer/chat/multilocation/activate`;
        return this.http
            .put(
                url,
                {
                    epjs: locations.multiLocEpj,
                },
                { headers },
            )
            .pipe(map((res: any) => res.data));
    }

    postGbmWizard(epj: string, gbmActivationWizardFormData: GbmWizard): Observable<any> {
        const chatIntegrationType = 'gbm';
        const requestUrl = `${this.environmentService.apiUrl}/consumer/chat/${epj}/configuration/integrations/${chatIntegrationType}/setup`;

        const body = {
            welcome_message: gbmActivationWizardFormData.gbmWelcomeMessage,
            offline_message: gbmActivationWizardFormData.gbmOfflineMessage,
            available_hours: {
                ...gbmActivationWizardFormData.gbmOpeningHours,
                periods: this.fixGbmAvailableHours(gbmActivationWizardFormData.gbmOpeningHours.periods),
            },
            contact_name: gbmActivationWizardFormData.accountFullName,
            contact_email: gbmActivationWizardFormData.accountEmail,
        };

        return this.http.post<any>(requestUrl, body).pipe(map((res: any) => res.data));
    }

    getGbmWizard(epj: string, integrationType: string): Observable<GbmWizard> {
        const requestUrl = `${this.environmentService.apiUrl}/consumer/chat/${epj}/configuration/integrations/${integrationType}/setup`;

        return this.http.get(requestUrl).pipe(
            map((result: any) => {
                return {
                    gbmWelcomeMessage: result.data.welcome_message,
                    gbmOpeningHours: result.data.available_hours,
                    gbmOfflineMessage: result.data.offline_message,
                    accountFullName: result.data.contact_name,
                    accountEmail: result.data.contact_email,
                };
            }),
        );
    }

    deleteConversation(epj: string, conversationId: string): Observable<unknown> {
        return this.http.delete(
            `${this.environmentService.apiUrl}/consumer/chat/${epj}/conversations/${conversationId}`,
        );
    }

    /*
     * For each defined period, if the open is after the close then we force the close_day to next
     * day in week.
     *
     * For instance:
     * { open_day:'MONDAY', open_time:'19:00', close_day:'MONDAY', close_time:'04:00' }
     * is replaced with:
     * { open_day:'MONDAY', open_time:'19:00', close_day:'TUESDAY', close_time:'04:00' }
     */
    private fixGbmAvailableHours(openingHours: TimePeriod[]): TimePeriod[] {
        const leftPadTime = (time: string) => (time.length === 4 ? `0${time}` : time);

        return openingHours.map(period => ({
            ...period,
            close_day:
                period.close_day === period.open_day && leftPadTime(period.close_time) >= leftPadTime(period.open_time)
                    ? period.close_day
                    : this.nextDay(period.close_day),
        }));
    }

    private nextDay(day: string): string {
        if (!WEEKDAYS.includes(day)) {
            throw new Error(`Invalid day "${day}"`);
        }

        return WEEKDAYS[(WEEKDAYS.indexOf(day) + 1) % WEEKDAYS.length];
    }
}
