import { KpiActionsUnion, KpiActionTypes } from '../actions';
import { IPublisherErrorsKpi } from '../models';

export type PublisherErrorsKpiState = IPublisherErrorsKpi[];

const initialState: PublisherErrorsKpiState = undefined;

export function publisherErrorsKpiReducer(state = initialState, action: KpiActionsUnion): PublisherErrorsKpiState {
    switch (action.type) {
        case KpiActionTypes.GET_PUBLISHER_ERRORS_SUCCESS: {
            return action.result;
        }
        default:
            return state;
    }
}
