export enum PermissionStrategy {
    CanActivateFeature = 'CanActivateFeature',
    SelectedLocationsProspects = 'SelectedLocationsProspects',
    HasWebsiteProducts = 'HasWebsiteProducts',
    HasClaim = 'HasClaim',
    HasFeature = 'HasFeature',
    HasSAAMPermission = 'HasSAAMPermission',
    LocationHasFeature = 'LocationHasFeature',
    LocationDoesNotHaveFeature = 'LocationDoesNotHaveFeature',
    HasAccess = 'HasAccess',
    LocationHasPack = 'LocationHasPack',
    SelectedLocationsHasFeature = 'SelectedLocationsHasFeature',
    LocationHasMenuCards = 'LocationHasMenuCards',
    CanReadOnBehalf = 'CanReadOnBehalf',
    HasUserRole = 'HasUserRole',
}
