import { Pipe, PipeTransform } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { LocationsSelectors } from '@slm/location/state';
import { Location } from '@solocal-manager/sirius/support/base-models';
import { get } from 'lodash-es';
import { take } from 'rxjs/operators';

@Pipe({
    name: 'displayAlias',
})
export class DisplayAliasPipe implements PipeTransform {
    constructor(private store: Store<any>) {}

    transform(locationName: string, location: Location): any {
        if (get(location, 'id') && !location.alias) {
            this.store.pipe(select(LocationsSelectors.getLocationById(location.id)), take(1)).subscribe(l => {
                location = l;
            });
        }
        return get(location, 'alias') ? location.alias : locationName;
    }
}
