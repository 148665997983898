import { Injectable } from '@angular/core';
import { Data, Params, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';

export interface RouterStateUrl {
    url: string;
    queryParams: Params;
    data: Data;
}

@Injectable({ providedIn: 'root' })
export class CustomRouterStateSerializer implements RouterStateSerializer<RouterStateUrl> {
    serialize(routerState: RouterStateSnapshot): RouterStateUrl {
        const { url } = routerState;
        const queryParams = routerState.root.queryParams;

        return {
            url,
            queryParams,
            data: this.mergeData(routerState),
        };
    }

    protected mergeData(routerState: RouterStateSnapshot): Data {
        let activatedRoute = routerState.root;
        let data: Data = {};
        while (activatedRoute) {
            data = { ...data, ...activatedRoute.data };
            activatedRoute = activatedRoute.children[0];
        }

        return data;
    }
}
