import { Action, createReducer, on } from '@ngrx/store';

import * as GFBActions from '@solocal-manager/sirius/core/actions/gfb.actions';

import { IAPInitGFB, IAPSetListGFB, IGFBState } from '../models/get-feed-back';

export const initialState: IGFBState = {
    platform: null,
    isInited: false,

    dataMap: null,
    isLoading: true,

    showSnackBar: false,
    activeFeature: null,

    selectedEPJs: [],

    showPopup: false,
    isGeneralOpened: false,
};

// reducer functions
function initState(state, payload: IAPInitGFB): IGFBState {
    return {
        ...state,
        ...payload,
        isInited: true,
    };
}

function loadSuccess(state, payload: IAPSetListGFB): IGFBState {
    const newDataMap = { ...state.dataMap };
    Object.keys(payload.dataMap).forEach(item => {
        const dataset = newDataMap[item];
        const newData = payload.dataMap[item];
        newDataMap[item] = {
            ...dataset,
            ...newData,
        };
    });
    return {
        ...state,
        dataMap: newDataMap,
        isLoading: false,
    };
}

function update(state, data: any): IGFBState {
    return {
        ...state,
        ...data,
    };
}

const reducer = createReducer(
    initialState,

    // INIT
    on(GFBActions.init, (state, { payload }) => initState(state, payload)),

    // Init Success
    on(GFBActions.initSuccess, (state, { payload }) => loadSuccess(state, payload)),

    // Patch Action Success
    on(GFBActions.patchActionSuccess, (state, { payload }) => loadSuccess(state, payload)),

    // SET ACTIVE FEATURE
    on(GFBActions.setActive, (state, { payload }) => update(state, payload)),

    // RESET ACTIVE FEATURE
    on(GFBActions.resetActive, state => update(state, { activeFeature: null })),

    // SET ACTIVE EPJ
    on(GFBActions.setActiveEPJ, (state, { payload }) => update(state, { selectedEPJs: payload })),

    // OPEN SNACKBAR
    on(GFBActions.openSnack, (state, { payload }) => update(state, payload)),

    // SHOW SNACKBAR
    on(GFBActions.showSnack, state => update(state, { showSnackBar: true })),

    // HIDE SNACKBAR
    on(GFBActions.hideSnack, state => update(state, { showSnackBar: false })),

    // OPEN POPUP
    on(GFBActions.openPopup, state => update(state, { showSnackBar: false })),

    // SHOW POPUP
    on(GFBActions.showPopup, state => update(state, { showPopup: true })),

    // HIDE POPUP
    on(GFBActions.hidePopup, state => update(state, { showPopup: false })),

    // SET GENERAL POPUP VISIBILITY STATE
    on(GFBActions.updateGeneralPopupState, (state, { payload }) => update(state, { isGeneralOpened: payload })),
);

export function getFeedBackReducer(state: IGFBState | undefined, action: Action) {
    return reducer(state, action);
}
