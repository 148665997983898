import { Injectable } from '@angular/core';

import { ActionWithPayload, ChatbotInfo } from '@solocal-manager/sirius/core/models';

@Injectable({ providedIn: 'root' })
export class ChatbotActions {
    static SET_CHATBOT_INFO = '[CHATBOT-INFO] set chatbot info';
    static GET_CHATBOT_INFO = '[CHATBOT-INFO] get chatbot info';

    setChatbotInfo(chatbotInfo: ChatbotInfo): ActionWithPayload<ChatbotInfo> {
        return {
            type: ChatbotActions.SET_CHATBOT_INFO,
            payload: chatbotInfo,
        };
    }

    getChatbotInfo(userID: number): ActionWithPayload<number> {
        return {
            type: ChatbotActions.GET_CHATBOT_INFO,
            payload: userID,
        };
    }
}
