import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiErrorActions } from '@slm/shared/error';
import { of } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';

import { ActionWithPayload, EpjSearchActions } from '../actions';
import { EpjSearchService } from '../services';

@Injectable({ providedIn: 'root' })
export class EpjSearchEffects {
    private readonly update$ = inject(Actions);

    login$ = createEffect(() =>
        this.update$.pipe(
            ofType(EpjSearchActions.GET_EPJ_SEARCH),
            map((action: ActionWithPayload<never>) => action.payload),
            debounceTime(400),
            switchMap(payload =>
                this.svc.getEpjSearch(payload).pipe(
                    map(results => this.epjSearchActions.getEpjSearchSuccess(results)),
                    catchError(error => of(new ApiErrorActions.CatchApiError(error))),
                ),
            ),
        ),
    );

    constructor(
        private epjSearchActions: EpjSearchActions,
        private svc: EpjSearchService,
    ) {}
}
