import { importProvidersFrom, makeEnvironmentProviders } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { OnBehalfEffects } from '../+state/on-behalf.effects';
import * as fromOnBehalf from '../+state/on-behalf.reducer';

export const provideOnBehalf = () =>
    makeEnvironmentProviders([
        importProvidersFrom([
            StoreModule.forFeature(fromOnBehalf.onBehalfFeatureKey, fromOnBehalf.reducer),
            EffectsModule.forFeature([OnBehalfEffects]),
        ]),
    ]);
