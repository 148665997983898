import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MfeBusService } from '@slp/mfe-bus';
import { forEach, get, isArray, keys, omit } from 'lodash-es';

import { IWpmHttpError, IWpmHttpResponse } from '../../models';
import { MessagesService } from '../../services/messages.service';
import { parseDetail } from '../../utils/json-utils';

// lib
// shared
const NO_ERROR_MESSAGE_REGEX =
    /\/auth\/login|consumer\/user\/me\/solocal-token|\/select-epj|\/settings|\/appointments|\/callmeback|consumer\/locations\/[^/]+\/pages|\/siteSelfcareUrls|slp-reviews/;
const geocodeRegex = /external\/geocode/;
const supportRegex = /support\/onbehalf-customer/;
const reviewsRegex = /consumer\/reviews\/\d+|\/revgen\/validate-recipients|slp-reviews/;
const publisherRegex = /consumer\/companies\/[^/]+\/publishers\/(twitter|linkedin)/;
const userRegex = /\/select-epj|\/delete-authorized-epj|\/auth\/logout|consumer\/user\/\d+\/notifications/;
const apimUrlRegex = /api\.app\.solocalgroup\.com|recapi\.app\.solocalgroup\.com/;
const NO_SUCCESS_MESSAGE_REGEX = new RegExp(
    `${geocodeRegex.source}|${supportRegex.source}` +
        `${reviewsRegex.source}|${publisherRegex.source}` +
        `${userRegex.source}|${apimUrlRegex.source}`,
);
const CHAT_CONFIGURATION_NO_MESSAGE_REGEX = /\/chat\/pj:\d+\/configuration/;

const AlertTypes = {
    success: 'success',
    info: 'info',
    warning: 'warning',
    danger: 'danger',
};

const messages = {
    put: 'Successfully updated!',
    patch: 'Successfully updated!',
    post: 'Successfully created',
    delete: 'Successfully deleted',
};

const keyErrorMap = {
    json_schema_error: error => (error.length ? ` ${error[0].path} ${error[0].message}` : ''),
    non_field_errors: error => (error.length ? ` ${isArray(error) ? error[0] : error}` : ''),
};

class Alert {
    alertType: string;
    message: string;
    timeout: number;
    params: any;

    constructor(alertType: string, message: string, params?: any) {
        this.alertType = alertType;
        this.message = message;
        this.params = params || {};
        switch (alertType) {
            case AlertTypes.danger:
                this.timeout = 10000;
                break;
            default:
                this.timeout = 5000;
        }
    }
}

@UntilDestroy()
@Component({
    template: '',
})
export class WpmFeedbackMessageHandlerComponent implements OnInit {
    alerts: Alert[] = [];
    isBold = false;
    isStrong = false;

    noErrorMessageRegex = NO_ERROR_MESSAGE_REGEX;
    noSuccessMessageRegex = NO_SUCCESS_MESSAGE_REGEX;
    chatConfigurationRegex = CHAT_CONFIGURATION_NO_MESSAGE_REGEX;

    constructor(
        protected messagesService: MessagesService,
        protected mfeBus: MfeBusService,
    ) {}

    ngOnInit(): void {
        this.isStrong = false;

        this.messagesService.messageError.pipe(untilDestroyed(this)).subscribe((err: IWpmHttpError) => {
            this.isStrong = false;
            // manage the different types of errors
            this.handleErrors(err);
        });

        this.messagesService.messageSuccess.pipe(untilDestroyed(this)).subscribe((res: IWpmHttpResponse) => {
            this.isBold = false;
            this.handleSuccess(res);
        });

        this.mfeBus.successNotification$.pipe(untilDestroyed(this)).subscribe(({ message }) => {
            this.isBold = false;
            this.handleSuccessMFENotification(message);
        });

        this.mfeBus.errorNotification$.pipe(untilDestroyed(this)).subscribe(({ message }) => {
            this.isStrong = false;
            this.handleErrorMFENotification(message);
        });
    }

    handleRevGenSuccess(method: string, url: string, data: any) {
        const revGenRegex = /\/v\d+[.]\d+\/revgen\/([^/]+)\/?/;

        if (method && method.toLocaleLowerCase() === 'patch' && revGenRegex.test(url)) {
            return [];
        }

        revGenRegex.exec(url);

        if (RegExp.$1 === 'reviews' && get(data, 'data.email')) {
            return ['Mail sent to:', { email: data.data.email }];
        }
    }

    handleAdditionalErrorProperties(additional: any): string {
        return '';
    }

    handleAlerts(): void {
        // implemented in the inherited component
    }

    shouldDisplay(url: string): boolean {
        return !this.noSuccessMessageRegex.test(url);
    }

    private handleSuccess(res: IWpmHttpResponse): void {
        if (res && res.response && res.method) {
            res = {
                ...res,
                method: res.method.toLowerCase(),
            };

            const url = res.response.url;

            if (this.shouldDisplay(url)) {
                this.isBold = true;
                const json = res.response;
                const detail = get(json, 'detail');
                const content = json && detail ? detail : messages[res.method];

                const revgen = this.handleRevGenSuccess(res.method as string, url, json);

                if (revgen) {
                    if (revgen[0]) {
                        this.alerts.push(new Alert(AlertTypes.success, revgen[0] as string, revgen[1]));
                        this.handleAlerts();
                    }
                    // do nothing .....
                } else if (content) {
                    this.alerts.push(new Alert(AlertTypes.success, content));
                    this.handleAlerts();
                }
            }
        }
    }

    private handleErrors(err: IWpmHttpError): void {
        const errorObject = err.error;

        // Settings is added among the APIs to not show the error when user tries to patch
        // his preferences as in October version we have to fire POST then PATCH in order to work
        // because of Salesforce BE BUG
        // Appointments added due to APIM changes and #14561 disscussion
        //Don't display 404 error for get chat configuration - not interesting message

        if (
            !this.noErrorMessageRegex.test(err.url) &&
            !(this.chatConfigurationRegex.test(err.url) && errorObject?.status === 404)
        ) {
            this.alerts.push(new Alert(AlertTypes.danger, 'global:error'));
            this.handleAlerts();
        }
    }

    private handleErrorMFENotification(message: string): void {
        this.alerts.push(new Alert(AlertTypes.danger, message));
        this.handleAlerts();
    }

    private handleSuccessMFENotification(message: string): void {
        this.alerts.push(new Alert(AlertTypes.success, message));
        this.handleAlerts();
    }
}
