<div [formGroup]="formRepeatable">
    <div formArrayName="data">
        <div [formGroupName]="i" *ngFor="let phone of formData.controls; let i = index; let first = first">
            <div class="row">
                <!-- code -->
                <div class="col-md-2 col-sm-2 col-2">
                    <div class="form-group">
                        <div class="blank-label" *ngIf="first">&nbsp;</div>
                        <div>
                            <select
                                name="selectCountryCode"
                                class="select-country-code custom-select"
                                formControlName="country_code"
                                (change)="codeChanged(phone)"
                            >
                                <option *ngFor="let code of countryCodes" [ngValue]="code.code">
                                    ({{ '+' + code.code }}) {{ 'country:' + code.country | translate }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-2 col-sm-2 col-2 phone-number-err-msg-holder">
                        <app-input-error-messages [control]="phone.controls.country_code"></app-input-error-messages>
                    </div>
                </div>
                <!-- number -->
                <div class="col-md-3 col-sm-3 col-3">
                    <div class="form-group">
                        <div *ngIf="first">
                            <label class="required-label" for="inputNumber">{{ 'Number' | translate }}</label>
                        </div>
                        <div>
                            <input type="text" class="form-control phone-number" formControlName="number" />
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-3 col-3 phone-number-err-msg-holder">
                        <app-input-error-messages [control]="phone.controls.number"></app-input-error-messages>
                    </div>
                </div>

                <!-- type -->
                <div class="col-md-2 col-sm-2 col-2">
                    <div class="form-group">
                        <div *ngIf="first">
                            <label class="required-label" for="selectPhoneType">{{ 'Type' | translate }}</label>
                        </div>
                        <div>
                            <select
                                name="selectPhoneType"
                                class="select-phone-type custom-select"
                                formControlName="type"
                            >
                                <option *ngFor="let phoneType of phoneTypes" [ngValue]="phoneType">
                                    {{ 'input_phone:type_name_' + phoneType | translate }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-2 col-sm-2 col-2 phone-number-err-msg-holder">
                        <app-input-error-messages [control]="phone.controls.type"></app-input-error-messages>
                    </div>
                </div>

                <!-- type_line -->
                <div class="col-md-3 col-sm-3 col-3">
                    <div class="form-group">
                        <div *ngIf="first">
                            <label for="inputNumber">{{ 'input_phone:data_type_line' | translate }}</label>
                        </div>
                        <div>
                            <input
                                type="text"
                                class="form-control phone-number input-type-line"
                                formControlName="type_line"
                            />
                        </div>
                    </div>
                </div>

                <div class="col-md-2 col-sm-2 col-2">
                    <div class="form-group">
                        <button
                            type="button"
                            [ngClass]="{ 'allign-button': first }"
                            class="btn btn-primary remove-field-button on-the-right"
                            (click)="removeInput(i)"
                        >
                            X
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-12 phone-number-err-msg-holder">
        <app-input-error-messages
            [control]="formRepeatable.get('data')"
            [messageTypes]="['phonesMainType', 'phonesMobileType']"
        ></app-input-error-messages>
    </div>
</div>
<div class="row">
    <div class="col-md-12 phone-form-add-number-holder">
        <button class="btn btn-primary remove-field-button" (click)="addInput()">
            {{ 'Add Phone Number' | translate }}
        </button>
    </div>
</div>
