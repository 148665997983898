import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { HOST_APP } from '@slm/shared/environment';
import { ApiErrorActions } from '@slm/shared/error';
import {
    GetHabilitatedLocationList,
    GetHabilitatedLocationListSuccess,
    HabilitatedLocationListActionTypes,
} from '@solocal-manager/sirius/core/actions';
import { HabilitatedLocationListService } from '@solocal-manager/sirius/core/services';
import { IHabilitatedLocation, PaginationDecoratorDTO } from '@solocal-manager/sirius/support/base-models';

import { get } from 'lodash-es';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class HabilitatedLocationListEffects {
    private readonly hostApp = inject(HOST_APP);
    getLocationList$ = createEffect(() =>
        this.update$.pipe(
            ofType<GetHabilitatedLocationList>(HabilitatedLocationListActionTypes.GET_HAB_LOCATION_LIST),
            map(action => action.payload),
            switchMap(payload => {
                return this.hLocationListService.getLocationList(payload).pipe(
                    map(res => (this.hostApp.isSlmWeb ? res : this.removePrehabilitatedLocations(res))),
                    map(res => this.handleApiResponse(res)),
                    catchError(err => {
                        this.logger.debug('HabilitatedLocationListEffects.getLocationList$ error ', err);
                        return of(
                            new ApiErrorActions.CatchApiError({
                                souce: 'getHabilitatedLocationList',
                                getHabilitatedLocationList: err,
                            }),
                        );
                    }),
                );
            }),
        ),
    );

    constructor(
        private update$: Actions,
        private hLocationListService: HabilitatedLocationListService,
        private logger: NGXLogger,
    ) {}

    /**
     * Dispatch error if use isn't habilitated on any EPJ
     * #13744 spec 1)
     * @param result API result
     */
    private handleApiResponse(result: PaginationDecoratorDTO<IHabilitatedLocation>): Action {
        const hLocationList = get(result, 'data', []);
        return hLocationList && hLocationList.length
            ? new GetHabilitatedLocationListSuccess(result)
            : new ApiErrorActions.CatchApiError({
                  source: 'getHabilitatedLocationListEmpty',
                  getHabilitatedLocationListEmpty: 'empty',
              });
    }

    private removePrehabilitatedLocations(
        res: PaginationDecoratorDTO<IHabilitatedLocation>,
    ): PaginationDecoratorDTO<IHabilitatedLocation> {
        return {
            ...res,
            data: res.data.filter(habLocation => habLocation.status !== 'PRE_HABILITATED'),
        };
    }
}
