import { ValidationErrors, AbstractControl } from '@angular/forms';

export class ValidateOneStandardPhone {
    static createValidator() {
        return (form: AbstractControl): ValidationErrors => {
            const error = {
                // must provide at least one phone number of type 'standard'
                phonesStandardType: {
                    value: form.value,
                },
            };

            const error_mobile = {
                phonesMobileType: {
                    value: form.value,
                },
            };

            if (form.value.filter(phone => phone.type === 'mobile').length > 2) {
                return error_mobile;
            }

            if (form.value && form.value.length > 0) {
                const validator = form.value.find(
                    phone => phone.type === 'main' || phone.type === 'mobile' || phone.type === 'voip',
                )
                    ? null
                    : error;
                return validator;
            } else if (form.value && form.value.length === 0) {
                return error;
            }

            return null;
        };
    }
}
