import { createReducer, on, Action } from '@ngrx/store';

import * as habLocActions from '@solocal-manager/sirius/core/actions';
import { IHabilitatedLocation } from '@solocal-manager/sirius/support/base-models';

const initialState = null;

const reducer = createReducer(
    initialState,

    on(habLocActions.SetHabilitatedLocationFullSuccess, (state, payload) => payload),
    on(habLocActions.ResetHabilitatedLocation, () => initialState),
    on(habLocActions.DeleteHabilitatedLocationSuccess, () => {
        return { initialState, success: true };
    }),
);

export function habLocationReducer(state: any, action: Action): IHabilitatedLocation {
    return reducer(state, action);
}
