<div class="opening-hours-input">
    <div [formGroup]="formRepeatable">
        <div formArrayName="data">
            <div
                [formGroupName]="i"
                class="flex-container"
                *ngFor="let openingHours of formData.controls; let i = index"
            >
                <div class="row">
                    <div class="col-md-1 col-sm-1 col-1 day-title-container">
                        <span class="day-title" [hidden]="isTimeslotAdditional(openingHours, i)">
                            {{ openingHours.controls.open_day.value | dayTitle | translate }}
                        </span>
                        <input type="hidden" formControlName="open_day" />
                        <input type="hidden" formControlName="close_day" />
                    </div>
                    <div class="col-md-2 col-sm-2 col-2">
                        <select
                            class="custom-select"
                            name="openTime"
                            (change)="timeChanged(openingHours)"
                            formControlName="open_time"
                        >
                            <option
                                *ngFor="let slot of timeSlots; let j = index"
                                [ngValue]="slot"
                                [hidden]="!j && isTimeslotAdditional(openingHours, i)"
                                translate
                            >
                                {{ slot }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-2 col-sm-2 col-2">
                        <div
                            [hidden]="
                                openingHours.controls.open_time.value &&
                                openingHours.controls.open_time.value === 'closed'
                            "
                        >
                            <span class="to-close-time-label">to</span>
                            <select
                                class="custom-select close-time"
                                name="closeTime"
                                (change)="timeChanged(openingHours)"
                                formControlName="close_time"
                            >
                                <option *ngFor="let slot of timeSlots; let j = index" [ngValue]="slot" [hidden]="!j">
                                    {{ slot }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 col-6">
                        <div class="row">
                            <div class="col-md-4 col-sm-4 col-4">
                                <div *ngIf="isAddingTimeEnabled(openingHours, i)" class="action">
                                    <a (click)="addInputForDay(openingHours.controls.open_day.value)"
                                        >{{ '+ Add Time' | translate }}
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 col-4">
                                <div
                                    *ngIf="
                                        openingHours.controls.open_time.value &&
                                        openingHours.controls.open_time.value !== 'closed' &&
                                        !isTimeslotAdditional(openingHours, i)
                                    "
                                    class="action"
                                >
                                    <a (click)="setToClosed(openingHours, i)">{{ 'set to "closed"' | translate }}</a>
                                </div>
                                <div type="button" *ngIf="isTimeslotAdditional(openingHours, i)" class="action">
                                    <button
                                        type="button"
                                        class="btn btn-primary allign-button remove-field-button remove-added-time-btn"
                                        (click)="removeTimeslot(openingHours, i)"
                                    >
                                        X
                                    </button>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 col-4">
                                <div *ngIf="isApplyingToAllEnabled(i)" class="action">
                                    <a (click)="applyToAll()">
                                        <span class="glyphicon glyphicon-arrow-down"></span
                                        >{{ 'Apply To All' | translate }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="group-error">
                    <app-input-error-messages [control]="openingHours"></app-input-error-messages>
                </div>
            </div>
            <div class="group-error">
                <app-input-error-messages [control]="formRepeatable.get('data')"></app-input-error-messages>
            </div>
        </div>
    </div>
</div>
