import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';

import { TotalPublisherStatistics } from '../models';

import { ActionWithPayload } from '.';

@Injectable({ providedIn: 'root' })
export class PublisherStatisticsActions {
    static GET_PUBLISHER_STATISTICS = '[PUBLISHER_STATISTICS] get publisher statistics';

    getPublisherStatistics(): Action {
        return {
            type: PublisherStatisticsActions.GET_PUBLISHER_STATISTICS,
        };
    }

    static GET_PUBLISHER_STATISTICS_SUCCESS = '[PUBLISHER_STATISTICS] get publisher statistics success';

    getPublisherStatisticsSuccess(
        publisherStatistics: TotalPublisherStatistics,
    ): ActionWithPayload<TotalPublisherStatistics> {
        return {
            type: PublisherStatisticsActions.GET_PUBLISHER_STATISTICS_SUCCESS,
            payload: publisherStatistics,
        };
    }
}
