import { getRedirectUriBO } from '@solocal-manager/auth';
import { KeycloakOptions } from 'keycloak-angular';

export const createAuthConfig = (url: string): KeycloakOptions => ({
    config: {
        url,
        realm: 'enterprise',
        clientId: 'solocal-manager-auth-oidc',
    },
    initOptions: {
        onLoad: 'login-required',
        redirectUri: getRedirectUriBO(),
        checkLoginIframe: false,
        enableLogging: true,
        pkceMethod: 'S256',
    },
    bearerExcludedUrls: ['/en.po', '/fr.po'],
});
