import { IPageStatesKpi, IStateInfo } from '../models';

interface IPageStatesMetaInfo {
    overallStates: { [state: string]: number };
    overallPublishers: { [publisher: string]: number };
    total: number;
}

export class TotalErrorKpiViewModel {
    /**
     * @description
     * A view model for KpiTotalErrorComponent
     */
    data: IPageStatesKpi[];
    meta: IPageStatesMetaInfo;

    constructor(data: IPageStatesKpi[]) {
        const metaData: IPageStatesMetaInfo = {
            total: 0,
            overallStates: {},
            overallPublishers: {},
        };
        if (data) {
            data.forEach((pageInfo: IPageStatesKpi) => {
                let publisherTotal = 0;

                pageInfo.states.forEach((stateInfo: IStateInfo) => {
                    metaData.overallStates[stateInfo.state] = metaData.overallStates[stateInfo.state]
                        ? metaData.overallStates[stateInfo.state] + stateInfo.count
                        : stateInfo.count;
                    publisherTotal += stateInfo.count;
                });
                metaData.overallPublishers[pageInfo.publisher_id] = publisherTotal;
                metaData.total += publisherTotal;
            });
        }
        this.data = data || [];
        this.meta = metaData;
    }

    getStateCountByPublisher(publisherId: string, state: string) {
        const selectedPage = this.data.find(page => page.publisher_id === publisherId);
        if (selectedPage) {
            const stateInfoFound = selectedPage.states.find(stateInfo => stateInfo.state === state);
            return stateInfoFound ? stateInfoFound.count : 0;
        }
        return 0;
    }
}
