<form [formGroup]="searchForm" autocomplete="off">
    <div class="row form-group col-md-12">
        <label for="phrase" *ngIf="publisherId === 'facebook'" translate>Facebook page URL</label>
        <label for="phrase" *ngIf="publisherId === 'gmb'" translate>Copy/Paste your Gmb URL</label>
    </div>
    <div class="row">
        <div class="form-group" [ngClass]="publisherId === 'facebook' ? 'col-md-12' : 'col-md-10'">
            <input type="text" name="phrase" class="form-control publisher-search-input" formControlName="phrase" />
            <app-input-error-messages [control]="searchForm.controls.phrase"></app-input-error-messages>
        </div>
        <div *ngIf="publisherId !== 'facebook'" class="col-md-2 pull-right">
            <button
                type="button"
                class="btn btn-success publisher-search-btn"
                [disabled]="!searchForm.valid && hasPhrase()"
                (click)="submit()"
            >
                {{ 'Search' | translate }}
            </button>
        </div>
    </div>
    <div *ngIf="publisherId === 'facebook' && searchForm.valid" class="pull-right">
        <button type="button" class="btn btn-primary" [disabled]="claimed || !businessManagerId" (click)="submit()">
            {{ 'Start a lookup' | translate }}
        </button>
    </div>
</form>

<table class="table table-striped m-top-5" [ngClass]="publisherId" *ngIf="customSearchResults.length">
    <thead>
        <th *ngIf="publisherId !== 'gmb' && publisherId !== 'tripadvisor'" scope="col">
            {{ 'Image' | translate }}
        </th>
        <th scope="col">{{ 'Name' | translate }}</th>
        <th *ngIf="publisherId !== 'gmb'" scope="col">
            {{ 'Url' | translate }}
        </th>
        <th scope="col">{{ 'Action' | translate }}</th>
    </thead>
    <tbody>
        <tr *ngFor="let result of customSearchResults">
            <td *ngIf="publisherId !== 'gmb' && publisherId !== 'tripadvisor'">
                <img [src]="result.image" alt="" />
            </td>
            <td>{{ result.name }}</td>
            <td *ngIf="publisherId !== 'gmb'">
                <a href="{{ result.publisher_page_url }}" target="blank">{{ 'link' | translate }}</a>
            </td>
            <td>
                <button
                    class="btn btn-primary publisher-claim-page-btn"
                    [disabled]="claimed"
                    *ngIf="!result.connected_to"
                    (click)="connect(result.publisher_page_id)"
                >
                    {{ 'Claim this page' | translate }}
                </button>

                <span *ngIf="result.connected_to">
                    <span *ngIf="publisherId === 'facebook'">
                        <span
                            >{{ 'This page is already connected to another point of sale' | translate }} ({{
                                result.connected_to_partner_id || result.connected_to
                            }}).</span
                        >
                        <span>{{
                            'It is not allowed to have the same page connected to two different Prolive accounts.'
                                | translate
                        }}</span>
                        <span>{{ 'Please enter another link, thank you.' | translate }}</span>
                    </span>

                    <span *ngIf="publisherId === 'gmb'">
                        <span
                            >{{
                                'This Gmb page is already connected to this or to another point of sale' | translate
                            }}
                            ({{ result.connected_to_partner_id || result.connected_to }})</span
                        >
                        <span>{{ '. It is not allowed to have the same Gmb page connected' | translate }}</span>
                        <span>{{ ' to two different Prolive accounts nor to reconnect it twice.' | translate }}</span>
                        <span>{{ 'Please enter another link, thank you.' | translate }}</span>
                    </span>
                </span>
            </td>
        </tr>
    </tbody>
</table>

<div *ngIf="searchState" class="alert alert-info">
    <p>{{ 'searching ...' | translate }}</p>
</div>

<div *ngIf="noResults" class="alert alert-warning">
    {{ 'No results were found for this search.' | translate }}
</div>

<div class="alert alert-success" *ngIf="claimed">
    {{ 'Page has been claimed successfully.' | translate }}
</div>
