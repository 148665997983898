import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { PathService } from '@solocal-manager/sirius/core/core';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { IPublisherErrorsKpi } from '../../models';
import { getKpiPublisherErrors, getPublisherNameById } from '../../reducers';
import { KpiTotalErrorFactoryService } from '../../services';
import { PublishersErrorKpiViewModel } from '../../view-models';

interface ISortConfig {
    field: string;
    desc: boolean;
    descMessage: boolean;
    descCount: boolean;
}

@Component({
    selector: 'slm-app-kpi-publishers-error',
    templateUrl: './kpi-publishers-error.component.html',
    styleUrls: ['./kpi-publishers-error.component.scss'],
})
export class KpiPublishersErrorComponent implements OnInit {
    params$: Observable<any>;
    publisherName$: Observable<string>;
    kpiPublishersError$: Observable<PublishersErrorKpiViewModel>;
    state: string;
    publisherId: string;
    sortConfig: ISortConfig = {
        field: 'count',
        desc: true,
        descMessage: true,
        descCount: true,
    };

    constructor(
        private route: ActivatedRoute,
        private pathService: PathService,
        private store: Store<any>,
        private kpiTotalErrorFactory: KpiTotalErrorFactoryService,
    ) {}

    ngOnInit() {
        this.params$ = this.route.params?.pipe(
            tap(params => {
                this.publisherId = params.publisherId;
                this.state = params.state;
                this.publisherName$ = this.store.pipe(select(getPublisherNameById(params.publisherId)));
            }),
        );
        this.kpiPublishersError$ = this.params$?.pipe(
            switchMap(params =>
                this.store.pipe(
                    select(getKpiPublisherErrors),
                    map((publisherErrors: IPublisherErrorsKpi[]) =>
                        this.kpiTotalErrorFactory.createPublishersStateKpi(publisherErrors, params.state),
                    ),
                ),
            ),
        );
    }

    get basePath(): string {
        return this.pathService.basePath.includes('app/2/')
            ? this.pathService.basePath
            : `${this.pathService.basePath}/app/2/kpi`;
    }

    generateRouterLink(errorInfo: any, publisherId: string, state: string, errorIndex: any): any[] {
        if (errorInfo.msg_id) {
            return [this.basePath, 'total-error', publisherId, state, 'error-status-detail', errorIndex];
        }
        return [];
    }

    orderBy(fieldName: string) {
        this.sortConfig = {
            field: fieldName,
            desc: !this.sortConfig.desc,
            descMessage: fieldName === 'user_msg' ? !this.sortConfig.desc : this.sortConfig.descMessage,
            descCount: fieldName === 'count' ? !this.sortConfig.desc : this.sortConfig.descCount,
        };
    }
}
