import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { UserState } from './user.reducer';

const userState = createFeatureSelector<UserState>('user');

export const selectUser = createSelector(userState, state => state.user);

export const selectUserPermissions = createSelector(selectUser, user => user?.user_permissions);

export const selectUserTags = createSelector(selectUser, user => user?.tags);

export const selectUserEmail = createSelector(selectUser, user => user?.email?.toLocaleLowerCase());

export const getAppSettings = createSelector(selectUser, user => user?.application);

export const getAppBasePath = createSelector(getAppSettings, appSettings => ['app', appSettings?.id ?? '']);

export const getAppBasePathAsString = createSelector(getAppBasePath, basePath => basePath.join('/'));

export const getCustomAppData = createSelector(getAppSettings, appSettings => appSettings?.custom_data);

export const hasTag = (tag: string): MemoizedSelector<UserState, boolean> =>
    createSelector(selectUserTags, tags => tags.includes(tag));
