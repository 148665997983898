import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@slm/shared/environment';
import { PaymentMethod } from '@solocal-manager/sirius/core/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class ReferenceService {
    constructor(
        public http: HttpClient,
        public envService: EnvironmentService,
    ) {}

    getPaymentMethods(): Observable<PaymentMethod[]> {
        const requestUrl = `${this.envService.apiUrl}/consumer/payment-methods`;
        return this.http.get<PaymentMethod[]>(requestUrl).pipe(map((res: any) => res.results));
    }
}
