import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { API_CONFIG } from '@slm/shared/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CreateLocationService {
    private readonly http = inject(HttpClient);
    private readonly apiConfig = inject(API_CONFIG);

    execute(epj: string): Observable<any> {
        const requestUrl = `${this.apiConfig.consumer}/locations/create-onbehalf-prospects`;

        return this.http.post(requestUrl, { epj });
    }
}
