import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { EpjToIdEntity } from './epj-to-id.models';

export const EPJ_TO_ID_FEATURE_KEY = 'epjToId';

export interface State extends EntityState<EpjToIdEntity> {
    selectedId?: string;
    loaded: boolean;
    error?: string | null;
}

export interface EpjToIdPartialState {
    readonly [EPJ_TO_ID_FEATURE_KEY]: State;
}

export const epjToIdAdapter: EntityAdapter<EpjToIdEntity> = createEntityAdapter<EpjToIdEntity>({
    selectId: epjToId => epjToId.epj,
});

export const initialState: State = epjToIdAdapter.getInitialState({
    loaded: false,
});
