// Actions
import { VarsLengthActionTypes, VarsLengthAction } from '@solocal-manager/sirius/core/actions';

// models
import { IvarsLengthState } from '@solocal-manager/sirius/core/models';

export const initialState: IvarsLengthState = {
    loaded: false,
    loading: false,
    varsLength: undefined,
};

export function varsLengthReducer(state = initialState, action: VarsLengthAction): IvarsLengthState {
    switch (action.type) {
        case VarsLengthActionTypes.GET_SMS_VARIABLES_LENGTH:
            return {
                ...state,
                loading: true,
            };
        case VarsLengthActionTypes.GET_SMS_VARIABLES_LENGTH_SUCCESS:
            return {
                ...state,
                loaded: true,
                loading: false,
                varsLength: action.payload,
            };
        case VarsLengthActionTypes.RESET_SMS_VARIABLES:
            return initialState;

        default:
            initialState;
    }
    return state;
}
