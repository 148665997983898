import { createSelector } from '@ngrx/store';
import { flatten } from 'lodash-es';

import { ActionWithPayload, CompaniesActions } from '../actions';
import { Company } from '../models';

export type CompaniesState = Company[];

const initialState: CompaniesState = [];

export function companiesReducer(state = initialState, action: ActionWithPayload<Company[]>): CompaniesState {
    switch (action.type) {
        case CompaniesActions.GET_COMPANIES_SUCCESS: {
            return action.payload;
        }
        default:
            return state;
    }
}

export const getCompanies = (state: any) => state.companies;

export const hasCompanies = createSelector(getCompanies, (companies: Company[]) => {
    return !!companies.length;
});
/*
export const getLocationDataList = createSelector(
    getCompanies,
    (companies: Company[]) => {
        return flatten(
            companies.map((company: Company) => company.custom_data.locations)
        );
    }
);
*/
