import { AbstractControl, ValidationErrors } from '@angular/forms';

export class CedexValidator {
    static create() {
        return (control: AbstractControl): ValidationErrors => {
            const forbidden = /cedex/i.test(control.value);
            return forbidden ? { forbiddenNameCedex: { value: control.value } } : null;
        };
    }
}
