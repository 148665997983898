<div [formGroup]="formRepeatable">
    <div formArrayName="data">
        <div [formGroupName]="i" *ngFor="let url of formData.controls; let i = index">
            <div class="row">
                <div class="form-group col-md-10 col-sm-10 col-10">
                    <label *ngIf="i === 0" for="inputUrl">{{ 'Url' | translate }}</label>
                    <input type="text" placeholder="Url for website" class="form-control" formControlName="url" />
                </div>
                <div class="form-group col-md-2 col-sm-2 col-2">
                    <button
                        type="button"
                        [ngClass]="{ 'allign-button': i === 0 }"
                        class="btn btn-primary remove-field-button on-the-right"
                        (click)="removeInput(i)"
                    >
                        X
                    </button>
                </div>

                <input formControlName="display_url" type="hidden" />
                <input formControlName="description" type="hidden" />

                <div class="row">
                    <div class="col-md-10 col-sm-10 col-10 url-err-msg-holder">
                        <app-input-error-messages [control]="url.controls.url"></app-input-error-messages>

                        <div class="input-error-message" *ngIf="hasUrlError(url.controls.url)">
                            <p>{{ 'Invalid URL HTTP' | translate }} {{ getUrlError(url.controls.url).status_code }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 url-form-add-holder">
            <button class="btn btn-primary remove-field-button" (click)="addInput()">
                {{ 'Add Url' | translate }}
            </button>
        </div>
    </div>
</div>
