import { Component, OnInit } from '@angular/core';
import { ROUTER_NAVIGATION } from '@ngrx/router-store';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { UserFacade } from '@slm/user/state';
import { RoutePathActions } from '@solocal-manager/sirius/core/core';
import { NGXLogger } from 'ngx-logger';

import { environment } from '../environments/environment';

@Component({
    selector: 'slm-root',
    template: '<slm-feedback-message-handler></slm-feedback-message-handler><router-outlet></router-outlet>',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    constructor(
        public logger: NGXLogger,
        public translate: TranslateService,
        public store: Store<any>,
        private readonly userFacade: UserFacade,
    ) {
        const defaultLanguage = environment.defaultLanguage;
        const availableLanguages = ['en', 'fr'];
        const browserLang = translate.getBrowserLang();

        translate.addLangs(availableLanguages);

        const currentLang = availableLanguages.includes(browserLang) ? browserLang : defaultLanguage;

        // Default language 'en' required to make empty english translations work
        translate.setDefaultLang('en');
        translate.use(currentLang);
    }

    ngOnInit(): void {
        this.userFacade.fetchUser();

        this.store.pipe(select('router')).subscribe(routing => {
            if (ROUTER_NAVIGATION && routing && routing.state) {
                const url = routing.state.url;
                const navigationId = routing.navigationId;

                if (url && url !== '/login' && navigationId === 1) {
                    this.setPathAfterLogin(url);
                }
            }
        });
    }

    setPathAfterLogin(url: string) {
        this.store.dispatch({
            type: RoutePathActions.SET_ROUTE_PATH,
            payload: url,
        });
    }
}
