import { UntypedFormArray, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import * as momentInstance from 'moment';
import { extendMoment, DateRange } from 'moment-range';

const moment = extendMoment(momentInstance);

export class SpecificOpenningHoursValidator {
    static create(formRepeatable: UntypedFormGroup) {
        return (group: UntypedFormGroup): ValidationErrors => {
            const repeatableControls = <UntypedFormArray>formRepeatable.controls.data;
            const startDateValue = group.controls.start_date.value;
            //Overlapping opening and closing times checking
            const controlsByDay = repeatableControls.controls.filter(
                control => control.value.start_date === startDateValue,
            );
            if (controlsByDay.length > 1) {
                const intervals: DateRange[] = [];
                controlsByDay.forEach(control => {
                    if (control.getError('openingVsClosingTimeOverlapping')) {
                        control.setErrors(null);
                    }
                    const timeslotOpenTime = moment(
                        `${moment().format('YYYY-MM-DD')} ${control.value.open_time}`,
                        'YYYY-MM-DD HH:mm',
                    );
                    const timeslotCloseTime = moment(
                        `${moment().format('YYYY-MM-DD')} ${control.value.close_time}`,
                        'YYYY-MM-DD HH:mm',
                    );
                    intervals.push(moment.range(timeslotOpenTime, timeslotCloseTime));
                });
                for (let i = 0; i < intervals.length; i++) {
                    for (let j = 0; j < intervals.length; j++) {
                        if (
                            i !== j &&
                            (intervals[i].overlaps(intervals[j]) ||
                                controlsByDay[i].value.closed ||
                                controlsByDay[j].value.closed)
                        ) {
                            return {
                                openingVsClosingTimeOverlapping: true,
                            };
                        }
                    }
                }
            }
            return null;
        };
    }
}
