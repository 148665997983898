import { Injectable } from '@angular/core';
import * as moment from 'moment';

import { IErrorInfo, IParcUsageKpi, IPublisherErrorsKpi, IWeekInfo, IYearInfo } from '../models';

@Injectable({ providedIn: 'root' })
export class KpiUtilsService {
    constructor() {}

    getErrorInfoByIndexAndState(publisherErrors: IPublisherErrorsKpi[], errorIndex: number, state: string): IErrorInfo {
        if (publisherErrors) {
            const dataByState = publisherErrors.find(stateInfo => stateInfo.state === state);
            if (dataByState && dataByState.errors.length > errorIndex) {
                return dataByState.errors[errorIndex];
            }
        }
        return {
            user_msg: '',
            msg_id: '',
            count: 0,
        };
    }

    getYearsTotalNumber(source: IYearInfo[]): number {
        if (source) {
            return source
                .map((yearInfo: IYearInfo) => yearInfo.total)
                .reduce((acc: number, curVal: number) => acc + curVal);
        }
        return 0;
    }

    isFutureInfo(year: IYearInfo, week: IWeekInfo): boolean {
        const currentYear = '' + moment().year();
        if (year.year === currentYear) {
            const currentWeek = moment().isoWeek();
            if (+week.week > currentWeek) {
                return true;
            }
        }
        return false;
    }

    processParcUsage(parcUsageOrigin: IParcUsageKpi): IParcUsageKpi {
        if (parcUsageOrigin) {
            const parcUsage = { ...parcUsageOrigin };
            parcUsage.months.forEach((month, j) => {
                if (j) {
                    // take value from previous month
                    const prevParcTotal = parcUsage.months[j - 1].parc_total;
                    month.parc_evolution = (month.parc_total * 100) / (prevParcTotal || 1) - (prevParcTotal ? 100 : 0);
                    // round to 0.01 if value is < 0.01
                    if (month.parc_evolution && Math.abs(month.parc_evolution) < 0.01) {
                        month.parc_evolution = month.parc_evolution < 0 ? -0.01 : 0.01;
                    }
                } else {
                    month.parc_evolution = 0;
                }
            });
            return parcUsage;
        }
        return { year: '', months: [] };
    }
}
