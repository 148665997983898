import { ActionWithPayload, PaymentMethodsActions } from '../actions';
import { PaymentMethod } from '../models';

export type PaymentMethodsState = PaymentMethod[];

const initialState: PaymentMethodsState = [];

export function paymentMethodsReducer(
    state = initialState,
    action: ActionWithPayload<PaymentMethod[]>,
): PaymentMethodsState {
    switch (action.type) {
        case PaymentMethodsActions.GET_PAYMENT_METHODS_SUCCESS: {
            return action.payload;
        }
        default:
            return state;
    }
}
