import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiErrorActions } from '@slm/shared/error';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ActionWithPayload, PublisherSearchActions } from '../actions';
import { PublisherSearchService } from '../services/publisher-search.service';

@Injectable({ providedIn: 'root' })
export class PublisherSearchEffects {
    search$ = createEffect(() =>
        this.update$.pipe(
            ofType(PublisherSearchActions.GET_PUBLISHER_SEARCH),
            map((action: ActionWithPayload<never>) => action.payload),
            switchMap(payload =>
                this.svc.search(payload['posId'], payload['jobId']).pipe(
                    map(results => this.publisherSearchActions.getPublisherSearchSuccess(results)),
                    catchError(error => of(new ApiErrorActions.CatchApiError(error))),
                ),
            ),
        ),
    );

    constructor(
        private update$: Actions,
        private publisherSearchActions: PublisherSearchActions,
        private svc: PublisherSearchService,
    ) {}
}
