import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';

import { PublisherSearch } from '../models';

import { ActionWithPayload } from '.';

@Injectable({ providedIn: 'root' })
export class PublisherSearchActions {
    static GET_PUBLISHER_SEARCH = '[PUBLISHER_SEARCH] get page sugestions by posId and jobId';

    getPublisherSearch(): Action {
        return {
            type: PublisherSearchActions.GET_PUBLISHER_SEARCH,
        };
    }

    static GET_PUBLISHER_SEARCH_SUCCESS = '[PUBLISHER_SEARCH_SUCCESS] get page sugestions by posId and jobId success';

    getPublisherSearchSuccess(result: PublisherSearch): ActionWithPayload<PublisherSearch> {
        return {
            type: PublisherSearchActions.GET_PUBLISHER_SEARCH_SUCCESS,
            payload: result,
        };
    }
}
