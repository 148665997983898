import { Component, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CarouselModule } from 'ngx-bootstrap/carousel';

const descriptions = [
    'description text for step 1',
    'description text for step 2',
    'description text for step 3',
    'description text for step 4',
    'description text for step 5',
    'description text for step 6',
    'description text for step 7',
];

const supportedLanguages = {
    en: true,
    fr: true,
};

@Component({
    selector: 'app-facebook-connect-tutorial',
    templateUrl: './facebook-connect-tutorial.component.html',
    styleUrls: ['./facebook-connect-tutorial.component.scss'],
})
export class FacebookConnectTutorialComponent implements OnInit {
    myInterval = 250000;
    slides: any[] = [];
    activeSlideIndex = 0;
    noWrapSlides = false;
    currentLanguage: string;

    @Output() onSlideShowDone: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(public translate: TranslateService) {}

    ngOnInit() {
        this.currentLanguage = this.translate.currentLang;

        for (let i = 1; i < 8; i++) {
            this.addSlide();
        }
    }

    @HostListener('window:keydown', ['$event'])
    hotkeys(event) {
        if (event.keyCode === 37) {
            this.prevSlide();
        } else if (event.keyCode === 39) {
            this.nextSlide();
        }
    }

    prevSlide() {
        if (this.activeSlideIndex > 0) {
            this.activeSlideIndex--;
        } else if (this.activeSlideIndex === 0) {
            this.activeSlideIndex = this.slides.length - 1;
        }
    }

    nextSlide() {
        if (this.activeSlideIndex < this.slides.length - 1) {
            this.activeSlideIndex++;
        } else if (this.activeSlideIndex === this.slides.length - 1) {
            this.activeSlideIndex = 0;
        }
    }

    addSlide(): void {
        const language = supportedLanguages[this.currentLanguage] ? this.currentLanguage : 'en';

        this.slides.push({
            image: `assets/images/img_step${(this.slides.length % 8) + 1}_${language}.svg`,
            text: descriptions[this.slides.length % 8],
        });
    }

    slidesDone() {
        this.onSlideShowDone.emit(true);
    }

    get isLastPage(): boolean {
        return this.activeSlideIndex === 6 ? true : false;
    }
}
