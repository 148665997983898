import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';

import { Company } from '../models';

import { ActionWithPayload } from '.';

@Injectable({ providedIn: 'root' })
export class CompaniesActions {
    static GET_COMPANIES = '[COMPANIES] get companies';

    getCompanies(): Action {
        return {
            type: CompaniesActions.GET_COMPANIES,
        };
    }

    static GET_COMPANIES_SUCCESS = '[COMPANIES] get companies success';

    getCompaniesSuccess(companies: Company[]): ActionWithPayload<Company[]> {
        return {
            type: CompaniesActions.GET_COMPANIES_SUCCESS,
            payload: companies,
        };
    }
}
