import { getPagesJaunesActivities } from '@slm/location/state';
import { advertisingSection, websiteSection } from '@solocal-manager/sirius/data-access-offers/config';
import { OfferByEpj } from '@solocal-manager/sirius/data-access-offers/models';
import { Location } from '@solocal-manager/sirius/support/base-models';
import { head, orderBy, pull, union, uniqBy } from 'lodash-es';

import { LocationCustomData } from '../models/location-custom-data.model';
import { LocationSelectedData } from '../models/location-selected-data.model';
import { IDataLayerOnboarding, IDataLayerVariables } from '../models/tag-manager.model';

export function getUserAndLocationDataLayerVariables({ location, epjs }: LocationSelectedData): IDataLayerVariables {
    return {
        ...createDataLayerVariables(location),
        user: {
            epjs: epjs?.join('|'),
        },
    };
}

export function createDataLayerVariables(location: Location): IDataLayerVariables {
    // location active features
    const featureSettings = location?.feature_settings ?? undefined;
    let servicesActivatedTotal = featureSettings ? 0 : undefined;
    const _servicesActivated: string[] = [];
    for (const feature in featureSettings) {
        if (featureSettings[feature]['status'] === 'active') {
            servicesActivatedTotal += 1;
            _servicesActivated.push(featureSettings[feature]['feature']);
        }
    }
    const servicesActivated = _servicesActivated.length ? _servicesActivated.join('|') : undefined;
    // push to GTM Datalayer {
    return {
        location: {
            epj: location.epj,
            categories:
                getPagesJaunesActivities(location)
                    .map(category => category.name.toLowerCase())
                    .join(' | ') ?? '',
            role: location.roles.join(' | '),
            servicesActivatedTotal: servicesActivatedTotal,
            servicesActivated: servicesActivated,
            presenceRefHeld: location.custom_data?.pack?.id,
        },
        onboarding: getOnboardingInfo(location?.custom_data),
    };
}

export function getOnboardingInfo(customData: LocationCustomData): IDataLayerOnboarding {
    // old onboarding
    const k2sdOnboardingStep = customData?.onboarding_step ? parseInt(customData.onboarding_step) : undefined;
    // onboarding v2
    const onboardingDetails = customData?.onboarding_steps?.details;
    if (!onboardingDetails) {
        return undefined;
    }
    let onboardingStepV2 = onboardingDetails ? 0 : undefined;
    const _onboardingFeaturesActivated: string[] = [];
    Object.keys(onboardingDetails).forEach((feature: string) => {
        if (onboardingDetails[feature]) {
            if (onboardingDetails[feature]['status']) {
                onboardingStepV2++;
            }
            if (onboardingDetails[feature]['status'] === 'done') {
                _onboardingFeaturesActivated.push(feature);
            }
        }
    });
    const onboardingFeaturesActivated = _onboardingFeaturesActivated.length
        ? _onboardingFeaturesActivated.join('|')
        : undefined;

    let onboardingFinishedOnce: string;

    if (onboardingDetails) {
        onboardingFinishedOnce = customData?.onboarding_steps?.status === 'done' ? 'yes' : 'no';
    }

    return {
        step: k2sdOnboardingStep,
        stepV2: onboardingStepV2,
        featuresActivated: onboardingFeaturesActivated,
        finishedOnce: onboardingFinishedOnce,
    };
}

export function formatOfferList(offers: OfferByEpj[]): string {
    const offerNames = [...new Set(offers.filter(offer => !!offer.libelleProduit).map(offer => offer.libelleProduit))];
    return offerNames.join(' | ');
}

export function extractPresenceOffers(offers: OfferByEpj[]): OfferByEpj[] {
    const unpaidOffers = offers.filter(offer => offer.codeProduit === 'BE');
    const paidOffers = offers.filter(
        offer => offer.codeProduit === 'K2DA' && (offer.codePack === '1' || offer.codePack === '2'),
    );
    const epjs = uniqBy(paidOffers, 'epjBeneficiaire').map(offer => offer.epjBeneficiaire);
    const filteredPaidOffers = pull(
        epjs.map(epj => {
            if (
                !offers.find(
                    offer => offer.codeProduit === 'K2DA' && offer.codePack === '3' && offer.epjBeneficiaire === epj,
                )
            ) {
                return getHighestOffer(paidOffers, epj);
            }
        }),
        undefined,
    );
    return union(unpaidOffers, filteredPaidOffers);
}

export function extractAdvertisingOffers(offers: OfferByEpj[]): OfferByEpj[] {
    const unpaidOffers = offers.filter(offer => advertisingSection.includes(offer.codeProduit));
    const paidOffers = offers.filter(offer => offer.codeProduit === 'K2DA' && offer.codePack === '3');
    const epjs = uniqBy(paidOffers, 'epjBeneficiaire').map(offer => offer.epjBeneficiaire);
    const filteredPaidOffers = pull(
        epjs.map(epj => getHighestOffer(paidOffers, epj)),
        undefined,
    );
    return union(unpaidOffers, filteredPaidOffers);
}

export function extractWebsiteOffers(offers: OfferByEpj[]): OfferByEpj[] {
    return offers.filter(offer => websiteSection.includes(offer.codeProduit));
}

export function getHighestOffer(offers: OfferByEpj[], epj: string): OfferByEpj {
    return head(
        orderBy(
            offers.filter(offer => offer.codeProduit === 'K2DA' && offer.epjBeneficiaire === epj),
            ['codePack', 'dateSouscription'],
            ['desc', 'desc'],
        ),
    );
}
