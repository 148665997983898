import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { API_CONFIG, EnvironmentService } from '@slm/shared/environment';
import { IRguSearchResultLane, IRguSearchResultLocality } from '@solocal-manager/sirius/core/models';
import { Observable, of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';


import { AzureTokenService } from './azure-token/azure-token.service';

@Injectable({ providedIn: 'root' })
export class RguSearchService {
    protected readonly apiConfig = inject(API_CONFIG);

    baseUrl: string;
    private cachedLocalities: Map<string, Array<{ localiteOut: IRguSearchResultLocality }>> = new Map();
    private cachedLanes: Map<string, Array<{ rechercheVoieOut: IRguSearchResultLane }>> = new Map();

    constructor(
        public http: HttpClient,
        public env: EnvironmentService,
        public azureTokenService: AzureTokenService,
    ) {}

    generateHeader(token: string): HttpHeaders {
        const headers = new HttpHeaders()
            .append('jwt', token)
            .append('Ocp-Apim-Subscription-Key', this.env.azureSubKey)
            .append('Content-Type', 'application/json');

        return headers;
    }

    // TODO: YUI-538
    searchForLocality(tokenAzure: string, zipCode: string, city?: string): Observable<IRguSearchResultLocality> {
        const processResult = res => {
            // write results to cache
            this.cachedLocalities.set('' + zipCode, res);
            if (!!res && res.length) {
                // select locality data based on 'zip code' + 'city'
                // if none is found return first result in a row
                if (city) {
                    const matchedLocality = res.find(
                        locality => locality.localiteOut.libelleComplet.toLowerCase() === city.toLowerCase(),
                    );
                    if (matchedLocality) {
                        return matchedLocality.localiteOut;
                    }
                }
                return res[0].localiteOut;
            }
            return null;
        };

        const url = `${this.apiConfig.apiAzureUrl}/rgu/v1/localites/${zipCode}`;

        // find results in cache first
        const cachedResult = this.cachedLocalities.get('' + zipCode);
        if (cachedResult) {
            return of(cachedResult).pipe(map(processResult));
        }

        // call API if no cached results have been found
        const headers = this.generateHeader(tokenAzure);

        return this.http
            .get<Array<{ localiteOut: IRguSearchResultLocality }>>(url, {
                headers,
            })
            .pipe(map(processResult));
    }

    // TODO: YUI-538
    searchForLanes(
        tokenAzure: string,
        q: string,
        codeDep: number,
        codeLoc: number,
        district?: string,
    ): Observable<IRguSearchResultLane[]> {
        const url = `${this.apiConfig.apiAzureUrl}/rgu/v1/voie`;

        const params = {
            libelle: q,
            codedep: '' + codeDep,
            codeloc: '' + codeLoc,
        };

        if (district) {
            params['arrondissement'] = district;
        }

        // find results in cache first
        const cachedResult = this.cachedLanes.get(JSON.stringify(params).toLowerCase());
        if (cachedResult) {
            return of(cachedResult.map(l => l.rechercheVoieOut));
        }

        // call API if no cached results have been found
        const headers = this.generateHeader(tokenAzure);

        return this.http
            .get<Array<{ rechercheVoieOut: IRguSearchResultLane }>>(url, {
                headers,
                params,
            })
            .pipe(
                map(res => {
                    this.cachedLanes.set(JSON.stringify(params).toLowerCase(), res);
                    return res.map(l => l.rechercheVoieOut);
                }),
            );
    }

    getLanesByZipCode(q: string, zipCode: string, locationId: string, city?: string) {
        return this.azureTokenService.getToken([locationId]).pipe(
            switchMap(token =>
                this.searchForLocality(token, zipCode, city).pipe(
                    switchMap((locality: IRguSearchResultLocality) => {
                        if (locality) {
                            return this.searchForLanes(
                                token,
                                q,
                                locality.codeDep,
                                locality.codeLoc,
                                !locality.arrondissement ? undefined : '' + locality.arrondissement,
                            );
                        }
                        return [];
                    }),
                ),
            ),
        );
    }

    // TODO: YUI-538
    getStreetsByCity(zipCode: number, locationId: string) {
        const url = `${this.apiConfig.apiAzureUrl}/rgu/v1/localites/${zipCode}`;
        return this.http.get<Array<{ localiteOut: IRguSearchResultLocality }>>(url);
    }
}
