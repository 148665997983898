import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_CONFIG, ApiConfig } from '@slm/shared/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


import { UserDtoModel } from '../models/user-dto.model';

@Injectable({
    providedIn: 'root',
})
export class UserHttpService {
    constructor(
        private readonly http: HttpClient,
        @Inject(API_CONFIG) private readonly api: ApiConfig,
    ) {}

    getUser(): Observable<UserDtoModel> {
        return this.http
            .post<{ data: UserDtoModel }>(`${this.api.unversioned}/user/me/session`, {})
            .pipe(map(res => (res ? res.data : ({} as UserDtoModel))));
    }
}
