// taken from Bridge implementation
// https://gitlab.solocal.com/leadformance/bridge-lib/content-validator/-/blob/master/packages/content-validator/src/phone/isShortNumber.ts

const REGEX_FRENCH_10XX_NUMBERS = /^10\d\d$/;
const REGEX_FRENCH_30XX_NUMBERS = /^30\d\d$/;
const REGEX_FRENCH_31XX_NUMBERS = /^31\d\d$/;
const REGEX_FRENCH_3XXX_NUMBERS = /^3[2-9]\d\d$/;
const REGEX_FRENCH_116XXX_NUMBERS = /^116\d\d\d$/;
const REGEX_FRENCH_118XXX_NUMBERS = /^118\d\d\d$/;

const FRENCH_SHORT_NUMBERS_REGEX = [
    REGEX_FRENCH_10XX_NUMBERS,
    REGEX_FRENCH_30XX_NUMBERS,
    REGEX_FRENCH_31XX_NUMBERS,
    REGEX_FRENCH_3XXX_NUMBERS,
    REGEX_FRENCH_116XXX_NUMBERS,
    REGEX_FRENCH_118XXX_NUMBERS,
];

export const isFrenchShortNumber = (number: string) => {
    return FRENCH_SHORT_NUMBERS_REGEX.some(regex => regex.test(number));
};
