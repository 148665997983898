import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';

import { MultiSearch, MultiSearchInternalResult } from '../models';

import { ActionWithPayload } from '.';

@Injectable({ providedIn: 'root' })
export class MultiSearchActions {
    static GET_MULTI_SEARCH = '[GET_MULTI_SEARCH] get multi search results';

    getMultiSearch(): Action {
        return {
            type: MultiSearchActions.GET_MULTI_SEARCH,
        };
    }

    static RESET_MULTI_SEARCH = '[RESET_MULTI_SEARCH] reset multi search results';

    resetMultiSearch(): Action {
        return {
            type: MultiSearchActions.RESET_MULTI_SEARCH,
        };
    }

    static GET_MULTI_SEARCH_SUCCESS = '[GET_MULTI_SEARCH_SUCCESS ] get multi search results success';

    getMultiSearchSuccess(result: MultiSearchInternalResult[]): ActionWithPayload<MultiSearchInternalResult[]> {
        return {
            type: MultiSearchActions.GET_MULTI_SEARCH_SUCCESS,
            payload: result,
        };
    }

    static SET_MULTI_SEARCH_SELECTED = '[SET_MULTI_SEARCH_SELECTED ] set multi search selected result';

    setMultiSearchSelected(result: MultiSearch): ActionWithPayload<MultiSearch> {
        return {
            type: MultiSearchActions.SET_MULTI_SEARCH_SELECTED,
            payload: result,
        };
    }

    static RESET_MULTI_SEARCH_SELECTED = '[RESET_MULTI_SEARCH_SELECTED ] reset multi search selected result';

    resetMultiSearchSelected(): Action {
        return {
            type: MultiSearchActions.RESET_MULTI_SEARCH_SELECTED,
        };
    }
}
