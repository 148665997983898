export interface AppSettingsModel {
    tools: any;
    features: {
        location: {
            edit: boolean;
        };
        production: {
            show: boolean;
        };
    };
}
