import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

import { StandardModalComponent } from '../components/standard-modal/standard-modal.component';

@Injectable({
    providedIn: 'root',
})
export class DialogService {
    constructor(private readonly matDialog: MatDialog) {}

    open<T>(component: ComponentType<T>, config: MatDialogConfig = {}): MatDialogRef<T> {
        return this.matDialog.open(component, config);
    }

    openDialog(config: MatDialogConfig = {}): MatDialogRef<StandardModalComponent> {
        if (!config.panelClass) config.panelClass = [];
        config.panelClass = [...config.panelClass, 'solocal-dialog'];
        config.data.buttonOK = config.data.buttonOK ? config.data.buttonOK : 'standard_modal:i-understand';
        return this.matDialog.open(StandardModalComponent, config);
    }

    openSimpleDialog(
        title: string,
        description: string,
        size = 'medium',
        icon?: string,
    ): MatDialogRef<StandardModalComponent> {
        return this.openDialog({
            panelClass: ['solocal-dialog-' + size, 'solocal-dialog-dialog'],
            data: {
                title,
                description,
                icon,
            },
        });
    }
}
