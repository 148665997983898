import { CurrentCompanyActionsUnion, CurrentCompanyActionTypes } from '@solocal-manager/sirius/core/actions';
import { Company } from '@solocal-manager/sirius/core/models';

export type CurrentCompanyState = Company;

export const initialState: Company = {
    id: undefined,
    modified: undefined,
    name: undefined,
    partner_id: undefined,
};

export function currentCompanyReducer(state = initialState, action: CurrentCompanyActionsUnion): CurrentCompanyState {
    switch (action.type) {
        case CurrentCompanyActionTypes.GET_CURRENT_COMPANY_SUCCESS: {
            const newState: Company = { ...action.currentCompnay };
            return newState;
        }
        case CurrentCompanyActionTypes.GET_CURRENT_COMPANY: {
            return { ...initialState };
        }
        case CurrentCompanyActionTypes.RESET_CURRENT_COMPANY: {
            return { ...initialState };
        }
        default:
            return state;
    }
}

export const getcurrentCompany = (state: any) => state.currentCompany;
