import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';

import { WpmInputCategory } from '@solocal-manager/sirius/core/core';

@Component({
    selector: 'app-input-category',
    templateUrl: './input-category.component.html',
    styleUrls: ['./input-category.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputCategoryComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => InputCategoryComponent),
            multi: true,
        },
    ],
})
export class InputCategoryComponent extends WpmInputCategory implements ControlValueAccessor, OnInit {
    @Input() control: UntypedFormControl; // needed for input-error-messages

    ngOnInit(): void {
        super.ngOnInit();

        this.formRepeatable.valueChanges
            .pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.destroyed$))
            .subscribe(() => this.updateCategories());
    }
}
