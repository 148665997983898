import { createReducer, on } from '@ngrx/store';

import {
    LoadPersonalPreferenceSetting,
    LoadPersonalPreferenceSettingByEpjFail,
    LoadPersonalPreferenceSettingByEpjSuccess,
} from '../actions';
import { IPersonalPreferencesState } from '../models';

const initialState: IPersonalPreferencesState = {
    settingLoaded: false,
    settingLoading: false,
    settingsByEpj: undefined,
};

export const reducer = createReducer(
    initialState,
    on(LoadPersonalPreferenceSetting, (state, { gd }) => ({
        ...state,
        settingLoading: true,
    })),
    on(LoadPersonalPreferenceSettingByEpjSuccess, (state, { payload }) => ({
        ...state,
        settingLoaded: true,
        settingLoading: false,
        settingsByEpj: payload?.length ? payload[0] : undefined,
    })),
    on(LoadPersonalPreferenceSettingByEpjFail, (state, { error }) => ({
        ...state,
        settingLoaded: false,
        settingLoading: false,
    })),
);

export const getPersonalPreferences = (state: IPersonalPreferencesState) => state;
export const getPersonalPreferenceSetting = (state: IPersonalPreferencesState) => state.settingsByEpj;
