import { UntypedFormControl, ValidationErrors } from '@angular/forms';

export class YoutubeUrlValidator {
    static create() {
        return (c: UntypedFormControl): ValidationErrors => {
            let valid = true;
            const idPattern = /[A-Za-z0-9_-]{11}/;
            const urlPattern =
                /^((?:https?:\/\/(?:www.youtube.com\/watch[?]v=|youtu.be\/))([A-Za-z0-9_-]{11})|((https?:\/\/)?(www\.)?youtu((\.be)|(be\..{2,5}))\/((user)|(channel))\/))/;

            if (c.value) {
                valid = c.value.indexOf('http') === 0 ? urlPattern.test(c.value) : idPattern.test(c.value);
            }

            return valid
                ? null
                : {
                      youtubeVideoUrlInvalid: { value: c.value },
                  };
        };
    }
}
