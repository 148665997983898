import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'appointmentTitle',
})
export class AppointmentTitlePipe implements PipeTransform {
    transform(upMeeting: { startDate: string; start: string; end: string }): string {
        const { startDate, start, end } = upMeeting;

        if (!startDate) {
            return '';
        }

        let aptDay;
        let aptDate;
        let aptYear;
        let aptMonth;

        const iDate = new Date(startDate); // yyyy-mm-dd

        aptDay = moment(iDate).locale('fr').format('dddd'); // dimanche...
        aptDate = iDate.getDate(); // dd
        aptYear = iDate.getFullYear(); // yyyy
        aptMonth = moment(iDate).locale('fr').format('MMMM'); // janvier...

        return `${aptDay} ${aptDate} ${aptMonth} ${aptYear} de  ${moment(start).format('HH[h]mm')} à ${moment(
            end,
        ).format('HH[h]mm')}`;
    }
}
