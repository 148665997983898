export enum ENavType {
    main = 'main',
    child = 'child',
    action = 'action',
}

export enum ENavClickType {
    REDIRECT = 'REDIRECT',
    ACTION = 'ACTION',
}

// NAV ITEM ENUMS

export enum ENavIDs {
    WEBSITE_NAV_GROUP = 'WEBSITE_NAV_GROUP',
    COMPANY_PERKS_GROUP = 'COMPANY_PERKS_GROUP',
    REVIEWS__INSTANT_MESSAGING__POSTS_PUBLICATIONS = 'REVIEWS__INSTANT_MESSAGING__POSTS_PUBLICATIONS',
    PHOTOS_VIDEOS_INFO = 'PHOTOS_VIDEOS_INFO',
    HOME_STATISTICS = 'HOME_STATISTICS',
    LOCATION_INFORMATION = 'LOCATION_INFORMATIONS',
    LOCATION_INFO__PHOTOS_VIDEOS = 'LOCATION_INFOS__PHOTOS_VIDEOS',
    HOME = 'HOME',
    AGENDA__CLICK_AND_COLLECT = 'AGENDA_CLICK_AND_COLLECT',
    MESSAGES = 'MESSAGES',
    PREFERENCES = 'PREFERENCES',
    NOTIFICATIONS = 'NOTIFICATIONS',
    ACCOUNTS = 'ACCOUNTS',
    ACCOUNTS_EDIT = 'ACCOUNTS_EDIT',
    ACCOUNTS_CONSULT_ESTABLISHMENT = 'ACCOUNTS_CONSULT_ESTABLISHMENT',

    CONTACT = 'CONTACTS',

    CREATE_QUICK = 'CREATE_QUICK',
    CREATE_PUBLICATIONS = 'CREATE_PUBLICATIONS',
    CREATE_CAMPAIGN = 'CREATE_CAMPAIGN',
    CREATE_APPOINTMENT = 'CREATE_APPOINTMENT',
    CREATE_CLIENT = 'CREATE_CLIENT',
    CREATE_MESSAGE = 'CREATE_MESSAGE',
    CREATE_QUOTE = 'CREATE_QUOTE',

    AGENDA = 'AGENDA',
    REVIEWS = 'REVIEWS',

    MY_CLIENTS = 'MY_CLIENTS',
    MAIL_BOX = 'MAIL_BOX',
    QUOTE_REQUEST = 'QUOTE_REQUEST',
    CLIENT_FILE = 'CLIENT_FILE',
    PAYMENTS = 'PAYMENTS',
    INSTANT_MESSAGING = 'INSTANT_MESSAGING',

    MY_PUBLICATIONS = 'MY_PUBLICATIONS',
    // MY_AUTOMATION_MARKETING = 'MY_AUTOMATION_MARKETING',
    CAMPAIGN = 'CAMPAIGN',
    NEWS = 'NEWS',
    NEWS_1 = 'NEWS_1',

    MY_VISIBILITY = 'MY_VISIBILITY',
    MY_WEBSITES = 'MY_WEBSITES',
    MY_LOCATIONS = 'MY_LOCATIONS',
    MY_STORE_LOCATOR = 'MY_STORE_LOCATOR',
    MY_KEYWORDS = 'MY_KEYWORDS',
    MY_PHOTOS = 'MY_PHOTOS',
    MY_ESTABLISHMENT = 'MY_ESTABLISHMENT',

    MY_STATISTICS = 'MY_STATISTICS',
    SOLOCAL_AND_I = 'SOLOCAL_AND_I',
    MY_SOLUTIONS = 'MY_SOLUTIONS',
    MY_INVOICES = 'MY_INVOICES',
    MY_ORDERS = 'MY_ORDERS',
    MY_APPOINTMENTS = 'MY_APPOINTMENTS',
    MY_SERVICES = 'MY_SERVICES',
    MY_OPINIONS = 'MY_OPINIONS',

    HELP_TUTORIALS = 'HELP_TUTORIALS',

    ACCESSIBILITY = 'ACCESSIBILITY',

    ONBOARDING_JOURNEY_GROUP = 'ONBOARDING_JOURNEY_GROUP',
    ONBOARDING_JOURNEY = 'ONBOARDING_JOURNEY',
    /**
     * UNIQ GROUP ID FOR ALL THE PLUGIN COMPONENTS
     * But in the store each of these will have it's unique map ID
     * like PLUGIN-CMP-NAME-1, PLUGIN-CMP-NAME-2
     */
    PLUGIN_COMPONENT = 'PLUGIN_COMPONENT',

    PLUGIN_TEST_GROUP = 'PLUGIN_TEST_GROUP',

    NONE = 'NONE',
    CURRENT = 'CURRENT',

    /**
     * Ids for testing sub menu configuration in side nav
     */
    TEST_SUB_MENUS_GROUP = 'TEST_SUB_MENU_GROUP',
    TEST_SUB_MENU = 'TEST_SUB_MENU',
    TEST_SUB_MENU_ITEM_1 = 'TEST_SUB_MENU_ITEM_1',
    TEST_SUB_MENU_ITEM_2 = 'TEST_SUB_MENU_ITEM_2',
    MY_PUBLICATIONS_GROUP = 'MY_PUBLICATIONS_GROUP',

    ADDON_STATISTICS = 'ADDON-statistics',

    COMPANY_PERKS = 'COMPANY_PERKS',
}

// PAGE ITEM ENUMS

export enum EPageIDs {
    PHOTOS_VIDEOS = 'PHOTOS_VIDEOS',
    HOME = 'HOME',
    AGENDA_PAGE = 'AGENDA_PAGE',
    MY_REVIEWS_PAGE = 'MY_REVIEWS_PAGE',
    MY_REVIEWS_REQUEST_PAGE = 'MY_REVIEWS_REQUEST_PAGE',
    MY_REVIEWS_RESPONSE_TEMPLATES_PAGE = 'MY_REVIEWS_RESPONSE_TEMPLATES_PAGE',
    INSTANT_MESSAGING_PAGE = 'INSTANT_MESSAGING_PAGE',
    QUOTE_REQUEST_PAGE = 'QUOTATION_PAGE',
    CLIENT_CONTACTS_PAGE = 'CLIENT_CONTACTS_PAGE',
    CLIENT_CONTACTS_INFO_PAGE = 'CLIENT_CONTACTS_INFO_PAGE',
    CLIENT_CONTACTS_ACTIVITIES_PAGE = 'CLIENT_CONTACTS_ACTIVITIES_PAGE',
    CLIENT_CONTACTS_ACTIVATION_PAGE = 'CLIENT_CONTACTS_ACTIVATION_PAGE',

    CLIENT_CONTACTS_CONTACT_PAGE = 'CLIENT_CONTACTS_CONTACT_PAGE',
    CLIENT_CONTACTS_IMPORT_PAGE = 'CLIENT_CONTACTS_IMPORT_PAGE',
    CLIENT_CONTACTS_IMPORT_FILE_PAGE = 'CLIENT_CONTACTS_IMPORT_FILE_PAGE',
    CLIENT_CONTACTS_IMPORT_GOOGLE_PAGE = 'CLIENT_CONTACTS_IMPORT_GOOGLE_PAGE',
    CLIENT_CONTACTS_MERGE_PAGE = 'CLIENT_CONTACTS_MERGE_PAGE',
    CLIENT_CONTACTS_TAGS_PAGE = 'CLIENT_CONTACTS_TAGS_PAGE',
    CLIENT_CONTACTS_CUSTOM_FIELD_PAGE = 'CLIENT_CONTACTS_CUSTOM_FIELD_PAGE',
    CLIENT_CONTACTS_PARAMETERS_PAGE = 'CLIENT_CONTACTS_PARAMETERS_PAGE',

    MY_EMAIL_CAMPAIGNS_PAGE = 'MY_EMAIL_CAMPAIGNS_PAGE',
    MY_EMAIL_CAMPAIGNS_LIST_PAGE = 'MY_EMAIL_CAMPAIGNS_LIST_PAGE',
    MY_EMAIL_CAMPAIGNS_ACTIVATION_PAGE = 'MY_EMAIL_CAMPAIGNS_ACTIVATION_PAGE',
    MY_EMAIL_CAMPAIGNS_CREATE_PAGE = 'MY_EMAIL_CAMPAIGNS_CREATE_PAGE',
    MY_EMAIL_CAMPAIGNS_HISTORY_PAGE = 'MY_EMAIL_CAMPAIGNS_HISTORY_PAGE',
    MY_SMS_CAMPAIGNS_PAGE = 'MY_SMS_CAMPAIGNS_PAGE',
    MY_SMS_CAMPAIGNS_ACTIVATION_PAGE = 'MY_SMS_CAMPAIGNS_ACTIVATION_PAGE',
    MY_SMS_CAMPAIGNS_CREATE_PAGE = 'MY_SMS_CAMPAIGNS_CREATE_PAGE',
    MY_SMS_CAMPAIGNS_HISTORY_PAGE = 'MY_SMS_CAMPAIGNS_HISTORY_PAGE',
    NEWS_PAGE = 'NEWS_PAGE',

    LOCATION_CONTENT_PAGE = 'LOCATION_CONTENT_PAGE',
    LOCATION_CONTENT_ORDERS_PAGE = 'LOCATION_CONTENT_ORDERS_PAGE',
    LOCATION_CONTENT_PUBLISHERS_PAGE = 'LOCATION_CONTENT_PUBLISHERS_PAGE',
    LOCATION_CONTENT_USERS_PAGE = 'LOCATION_CONTENT_USERS_PAGE',
    LOCATION_CONTENT_CARDS_PAGE = 'LOCATION_CONTENT_CARDS_PAGE',
    LOCATION_CONTENT_ACTIVITIES_PAGE = 'LOCATION_CONTENT_ACTIVITIES_PAGE',
    LOCATION_CONTENT_SUGGESTIONS_PAGE = 'LOCATION_CONTENT_SUGGESTIONS_PAGE',

    // sub pages can come here in the future if needed ( information, product_catalog, photos/videos etc...)
    MY_LOCATIONS_PAGE = 'MY_LOCATIONS',
    LOCATION_INFORMATIONS = 'LOCATION_INFORMATIONS',
    MY_WEBSITES_PAGE = 'MY_WEBSITES_PAGE',
    MY_PHOTOS_PAGE = 'MY_PHOTOS_PAGE',

    MY_STATISTICS_PAGE = 'MY_STATISTICS_PAGE',

    MY_SOLUTIONS_PAGE = 'MY_SOLUTIONS_PAGE',
    MY_SOLUTIONS_DETAILS_PAGE = 'MY_SOLUTIONS_DETAILS_PAGE',
    MY_SOLUTIONS_WEBSITE_OFFER_PAGE = 'MY_SOLUTIONS_WEBSITE_OFFER_PAGE',

    MY_INVOICES_PAGE = 'MY_INVOICES_PAGE',
    MY_ORDERS_PAGE = 'MY_ORDERS_PAGE',
    MY_APPOINTMENTS_PAGE = 'MY_APPOINTMENTS_PAGE',
    MY_SERVICES_PAGE = 'MY_SERVICES_PAGE',
    CONTACT_SOLOCAL_PAGE = 'CONTACT_SOLOCAL_PAGE',

    HELP_TUTORIALS_PAGE = 'HELP_TUTORIALS_PAGE',

    PREFERENCES_PAGE = 'PREFERENCES_PAGE',

    ACCOUNTS_PAGE = 'ACCOUNTS_PAGE',

    ACCOUNTS_EDIT_PAGE = 'ACCOUNTS_EDIT_PAGE',

    ACCOUNTS_CONSULT_ESTABLISHMENT_PAGE = 'ACCOUNTS_CONSULT_ESTABLISHMENT_PAGE',

    ONBOARDING_JOURNEY = 'ONBOARDING_JOURNEY',

    ADDON = 'ADDON',

    COMPANY_PERKS_PAGE = 'COMPANY_PERKS_PAGE',
    ACCESSIBILITY = 'ACCESSIBILITY',
}
