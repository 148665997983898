import { Action } from '@ngrx/store';

// Models
import { IvarsLengthRes, IVarsLengthPayload } from '@solocal-manager/sirius/core/models';

export enum VarsLengthActionTypes {
    GET_SMS_VARIABLES_LENGTH = '[GET_SMS_VARIABLES_LENGTH] get sms variables length',
    GET_SMS_VARIABLES_LENGTH_SUCCESS = '[GET_SMS_VARIABLES_LENGTH_SUCCESS] get sms variables length success',
    RESET_SMS_VARIABLES = '[RESET_SMS_VARIABLES] reset variables length',
}

export class GetVariablesLength implements Action {
    readonly type = VarsLengthActionTypes.GET_SMS_VARIABLES_LENGTH;

    constructor(public payload: IVarsLengthPayload) {}
}

export class GetVariablesLengthSuccess implements Action {
    readonly type = VarsLengthActionTypes.GET_SMS_VARIABLES_LENGTH_SUCCESS;

    constructor(public payload: IvarsLengthRes) {}
}

export class ResetSMSVariables implements Action {
    readonly type = VarsLengthActionTypes.RESET_SMS_VARIABLES;
}

export type VarsLengthAction = GetVariablesLength | GetVariablesLengthSuccess | ResetSMSVariables;
