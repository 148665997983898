import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

import { ChatNotification, ChatConfig, IIntegration } from '@solocal-manager/sirius/data-access-chat/models';

/**
 * @class SmoochService
 * @description Smooch SDKs interface
 * Call the native method from the cordova plugin
 */
@Injectable({ providedIn: 'root' })
export abstract class SmoochService {
    isAlreadyInit;
    currentSettings: any;

    constructor() {}

    /**
     * Init smooch application.
     * @param {ISmoochSettings} options See Smooch iOS SDK docs https://docs.smooch.io/api/ios/Classes/SKTSettings.html#//api/name/authenticationDelegate.
     */
    abstract init(options: any): Promise<any>;

    /**
     * Display smooch conversation.
     */
    abstract show(): Promise<any>;

    /**
     * render smooch inside dedicated container.
     */
    abstract render(): void;

    abstract sendMessage(text: string): Promise<any>;

    /**
     * Load conversation - Call it before show()
     * @param {string} conversationId conversation to load
     */
    abstract loadConversation(conversationId: string): Promise<any>;

    /**
     * Login with userId and jwt
     * @param {string} userId
     * @param {string} jwt
     */
    abstract login(userId: string, jwt: string): Promise<any>;

    abstract logout(): Promise<any>;

    /**
     * Load conversation - Call it before show()
     * @param {string} conversationId conversation to load
     */
    abstract getMoreConversations(): Promise<any>;

    /**
     * get conversation - Call it before show()
     * @param {string} conversationId conversation to load
     */
    abstract getConversations(): Promise<any>;

    /**
     * @method openConversation
     * @description Open a smooch conversation from MK notifications
     * Smooch init => Smooch login => load and show conversation
     * @param notification{ChatNotification} - notification from MK
     * @param config{ChatConfig}
     */
    abstract openConversation(notification: ChatNotification, config: ChatConfig);

    /**
     * @method getPlatformIntegrationId
     * @description Since V2 we have one smooch integration per platform (IOS and Android)
     * @param integrations Smooch integrations for a location
     */
    abstract getPlatformIntegrationId(integrations: IIntegration[]): string;
}
