import { EMenuType, IMenuEntry } from '@solocal-manager/sirius/core/models';

export const SINGLE_LOCATION = {
    route: 'content',
    label: 'Edit the info of my location',
    class: 'single-loc',
};

export const MULTI_LOCATION = {
    route: 'content',
    label: 'Edit the info of my location',
    class: 'multi-loc',
};

export const AVAILABLE_PAGES: IMenuEntry[] = [
    // routes to enable
    {
        route: 'home',
        label: 'Home',
        class: 'home',
        target: EMenuType.other,
        enabled: true,
        routing: true,
    },
    {
        route: 'self-onboarding',
        label: 'Self-Onboarding',
        class: 'self-onboarding',
        target: EMenuType.other,
        // hidden: true,
        enabled: true,
        routing: true,
    },

    // main buttons on the header
    {
        route: 'faq',
        label: 'Online help',
        class: 'faq',
        target: EMenuType.main,
        enabled: true,
        routing: true,
        gtmName: 'online-help',
    },
    {
        route: 'todolist',
        label: 'To Do List',
        class: 'todo-list',
        target: EMenuType.main,
        enabled: true,
        routing: true,
        gtmName: 'todolist',
    },

    // links on the drop down menu
    {
        route: 'post',
        label: 'Share a post',
        class: 'post',
        target: EMenuType.dropDown,
        enabled: true,
        routing: true,
        gtmName: 'post',
    },
    {
        route: 'reputation',
        subRoute: 'review',
        label: 'Answer reviews',
        class: 'repu',
        target: EMenuType.dropDown,
        enabled: true,
        routing: true,
        gtmName: 'review',
    },
    {
        route: 'content',
        label: 'Edit the info of my location',
        class: 'single-loc',
        target: EMenuType.dropDown,
        enabled: true,
        routing: true,
        gtmName: 'content',
    },
    {
        route: 'gallery',
        label: 'Access the gallery',
        class: 'gall',
        target: EMenuType.dropDown,
        enabled: true,
        routing: true,
        gtmName: 'gallery',
    },
    {
        route: 'locations',
        label: 'Location information',
        class: 'gall',
        target: EMenuType.dropDown,
        enabled: false,
        routing: true,
        gtmName: '',
    },
    {
        route: 'statistic',
        label: 'My statistics',
        class: 'stat',
        target: EMenuType.dropDown,
        enabled: true,
        routing: true,
        gtmName: 'statistics',
    },
    {
        route: 'profile',
        subRoute: 'account',
        label: 'My account',
        class: '',
        target: EMenuType.dropDown,
        // hidden: true,
        enabled: true,
        routing: true,
        gtmName: 'profile',
    },
    {
        route: 'invoices',
        label: 'My invoices',
        class: '',
        target: EMenuType.dropDown,
        // hidden: true,
        enabled: true,
        routing: true,
        gtmName: 'invoices',
    },
    {
        route: 'orders',
        label: 'My orders',
        class: '',
        target: EMenuType.dropDown,
        // hidden: true,
        enabled: true,
        routing: true,
        gtmName: 'orders',
    },
    {
        route: 'offer-presentation',
        label: 'My Solocal offers',
        class: 'offer-presentation',
        target: EMenuType.dropDown,
        // hidden: true,
        enabled: true,
        routing: true,
        gtmName: 'solution',
    },
    {
        route: 'meetings',
        label: 'My Solocal appointments',
        class: 'rdvs',
        target: EMenuType.dropDown,
        // hidden: false,
        enabled: true,
        routing: true,
        gtmName: 'meetings',
    },
    {
        route: 'profile',
        subRoute: 'contact',
        class: 'contact',
        label: 'My Solocal requests',
        target: EMenuType.dropDown,
        enabled: true,
        routing: true,
        gtmName: 'profile',
    },
    {
        route: 'quotation',
        class: 'quotation',
        label: 'Quotation',
        target: EMenuType.dropDown,
        enabled: true,
        routing: true,
        gtmName: 'quotation',
    },
    {
        route: 'instant-messaging',
        class: 'instant-messaging',
        label: 'Instant messaging',
        target: EMenuType.dropDown,
        enabled: true,
        routing: true,
        gtmName: 'instant-messaging',
    },
    // {
    //     route: 'my-contacts',
    //     class: 'my-clients',
    //     label: 'My contacts',
    //     target: EMenuType.dropDown,
    //     enabled: false,
    //     routing: true,
    //     gtmName: 'my-clients'
    // },
    {
        route: 'contact-solocal',
        class: 'contact-solocal',
        label: 'Contact Solocal',
        target: EMenuType.dropDown,
        enabled: false,
        routing: true,
        gtmName: 'contact-solocal',
    },
    {
        route: 'agenda',
        label: 'Agenda',
        class: 'agenda',
        target: EMenuType.dropDown,
        enabled: true,
        routing: true,
        gtmName: 'agenda',
    },
    {
        route: 'company-perks',
        label: 'Company Perks',
        class: 'company-perks',
        target: EMenuType.dropDown,
        enabled: true,
        routing: true,
        gtmName: 'company-perks',
    },
    {
        route: 'onboarding-journey',
        label: 'Onboarding',
        class: 'onboarding',
        target: EMenuType.dropDown,
        enabled: true,
        routing: true,
        gtmName: 'onboarding-journey',
    },
    {
        route: 'accessibility',
        label: 'Accessibility',
        class: 'accessibility',
        target: EMenuType.dropDown,
        enabled: true,
        routing: true,
        gtmName: 'accessibility',
    },
];

export function mapPageIds() {
    return AVAILABLE_PAGES.reduce((accumulator, page, index) => {
        if (page.subRoute) {
            accumulator[`${page.route}/${page.subRoute}`] = index;
        } else {
            accumulator[page.route] = index;
        }
        return accumulator;
    }, {});
}

export const PageIdsMap = mapPageIds();
