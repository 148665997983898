import { UntypedFormControl, ValidationErrors } from '@angular/forms';

export class NumberOnlyValidator {
    static create() {
        return (control: UntypedFormControl): ValidationErrors | null => {
            const value = control.value;
            const numberRegex = /^\d+$/;
            const validNumber = numberRegex.test(value);
            return !value || validNumber ? null : { invalidNumber: true };
        };
    }
}
