import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    template: '',
})
export class BaseChatPreferenceActivationComponent {
    @Input() isActive: boolean;
    @Input() oAuthNeeded: boolean;
    @Input() isPending: boolean;
    @Input() featureText: string;
    @Input() pendingText: string;
    @Input() pendingStatus: string;
    @Input() isDisabled: boolean;
    @Output() toggleActivation: EventEmitter<boolean> = new EventEmitter();
    @Output() refreshFeature: EventEmitter<boolean> = new EventEmitter();
    @Input() urlAuth: string;
    @Input() hasPageActive: boolean;
    @Input() feature: string;
    @Input() configPending: boolean;
    @Input() navigateUrl: string;
    @Input() isGoogle: boolean;
    counter = 0; // needed for preventing double click on 'Refresh'
    isOnBoarding = false;

    refreshState() {
        this.refreshFeature.emit(true);
    }

    startTimer(counter = 10) {
        this.counter = counter;

        const intervalId = setInterval(() => {
            this.counter--;
            if (!this.counter) {
                clearInterval(intervalId);
                delete this.counter;
                this.refreshState();
            }
        }, 1000);
    }

    get status(): string {
        if (this.isPending && this.feature.includes('gbm')) {
            return 'activation_prending';
        }
        if (this.isPending) {
            return this.isActive ? 'deactivation_prending' : 'activation_prending';
        } else {
            return this.noPendingStatus;
        }
    }

    get noPendingStatus(): string {
        switch (true) {
            case this.needToActivePageFirst:
                return this.feature?.includes('messenger')
                    ? 'connect_facebook_page_first'
                    : 'connect_google_page_first';
            case this.isActive:
                return 'activation_feature_comp:active_status_text';
            default:
                return 'activation_feature_comp:deactive_status_text';
        }
    }

    get icon(): string {
        if (this.isPending) return 'schedule';
        else {
            return this.isActive ? 'check_circle_outline' : 'highlight_off';
        }
    }

    get isChatFeature(): boolean {
        return this.feature?.includes('instant_messaging');
    }

    get needToActivePageFirst(): boolean {
        return this.isChatFeature && !this.hasPageActive && !this.isActive;
    }
}
