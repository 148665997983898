import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { snakeCase } from 'lodash-es';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { PathService } from '@solocal-manager/sirius/core/core';

import { GetProvisioningIssueDetails } from '../../actions';
import { IProvisioningIssueDetailsKpi } from '../../models';
import { getErrorNameByType, getKpiProvisioningIssueDetails } from '../../reducers';

interface ISortConfig {
    field: string;
    desc: boolean;
    descProductId: boolean;
    descCompanyEpj: boolean;
    descLocationEpj: boolean;
}

@Component({
    selector: 'app-kpi-provisioning-issue-details',
    templateUrl: './kpi-provisioning-issue-details.component.html',
    styleUrls: ['./kpi-provisioning-issue-details.component.scss'],
})
export class KpiProvisioningIssueDetailsComponent implements OnInit {
    params$: Observable<any>;
    errorName$: Observable<string>;
    provIssueDetails$: Observable<IProvisioningIssueDetailsKpi>;
    errorType: string;
    year: string;
    week: string;
    sortConfig: ISortConfig = {
        field: 'product_id',
        desc: true,
        descProductId: true,
        descCompanyEpj: true,
        descLocationEpj: true,
    };

    constructor(private route: ActivatedRoute, private pathService: PathService, private store: Store<any>) {}

    ngOnInit() {
        this.params$ = this.route.params.pipe(
            tap(params => {
                this.errorType = snakeCase(params.type);
                this.year = params.year;
                this.week = params.week;
                this.errorName$ = this.store.pipe(select(getErrorNameByType(this.errorType)));
                this.store.dispatch(
                    new GetProvisioningIssueDetails({
                        type: this.errorType,
                        year: +this.year,
                        week: +this.week,
                    }),
                );
            }),
        );
        this.provIssueDetails$ = this.params$.pipe(
            switchMap(() => this.store.pipe(select(getKpiProvisioningIssueDetails))),
        );
    }

    get basePath(): string {
        return `${this.pathService.basePath}/kpi`;
    }

    orderBy(fieldName: string) {
        this.sortConfig = {
            field: fieldName,
            desc: !this.sortConfig.desc,
            descProductId: fieldName === 'product_id' ? !this.sortConfig.desc : this.sortConfig.descProductId,
            descCompanyEpj: fieldName === 'company_epj' ? !this.sortConfig.desc : this.sortConfig.descCompanyEpj,
            descLocationEpj: fieldName === 'pos_epj' ? !this.sortConfig.desc : this.sortConfig.descLocationEpj,
        };
    }
}
