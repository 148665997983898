<solocal-modal *ngIf="data">
    <div modal-title class="title">
        <solocal-icon *ngIf="data.icon" [solocalIcon]="data.icon" class="icon"></solocal-icon>
        <div *ngIf="data.title" [ngClass]="{ 'title-with-icon': data.icon }">
            {{ data.title | translate }}
        </div>
    </div>
    <div modal-help *ngIf="data.description" [innerHTML]="data.description | translate | safeHtml"></div>
    <div modal-content *ngIf="data.content" [innerHTML]="data.content | translate | safeHtml"></div>
    <ng-container modal-action>
        <solocal-button *ngIf="data.buttonNOK" color="secondary" [mat-dialog-close]="false">
            {{ data.buttonNOK | translate }}
        </solocal-button>
        <solocal-button *ngIf="data.buttonOK" [mat-dialog-close]="true">
            {{ data.buttonOK | translate }}
        </solocal-button>
    </ng-container>
</solocal-modal>
