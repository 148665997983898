<div class="new-epj">
    <p translate>
        This location doesn't exist. Do you want to create a new one?
    </p>
    <div>
        <div *ngIf="working">
            <svg
                version="1.1"
                id="loader-1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="40px"
                height="40px"
                viewBox="0 0 50 50"
                style="enable-background:new 0 0 50 50;"
                xml:space="preserve"
            >
                <path
                    fill="#FFF"
                    d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
                >
                    <animateTransform
                        attributeType="xml"
                        attributeName="transform"
                        type="rotate"
                        from="0 25 25"
                        to="360 25 25"
                        dur="0.6s"
                        repeatCount="indefinite"
                    />
                </path>
            </svg>
        </div>

        <div *ngIf="!working">
            <button
                (click)="createLocationByEpj(); working = true"
                class="btn btn-success"
                translate
            >
                Apply
            </button>
            <button (click)="close()" class="btn btn-default" translate="">
                Cancel
            </button>
        </div>
    </div>
</div>
