import { Component, Input, forwardRef, Renderer2, HostListener, ViewChild, OnChanges } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'wpm-review-edit-textarea',
    template: '',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => WpmReviewEditTextAreaComponent),
            multi: true,
        },
    ],
})
export class WpmReviewEditTextAreaComponent implements ControlValueAccessor, OnChanges {
    @ViewChild('review_textarea', { static: true })
    review_textarea;
    @Input() rows = 4;
    @Input() placeholder = '';
    @Input() publisher = '';
    @Input() showLoader: boolean;
    _value = '';
    get value(): string {
        return this._value;
    }

    maxlength: number;

    set value(v: string) {
        if (v !== this._value) {
            this._value = v;
            this.onChange(v);
        }
    }

    propagateChange: any = (_: any) => {};
    validateFn: any = () => {};

    constructor(public renderer2: Renderer2) {}

    ngOnChanges() {
        this.getMaxlength();
    }

    @HostListener('keydown', ['$event']) onKeydown(e: any) {
        if (e.keyCode > 47 && this.maxlength) {
            if (e.target.value.length >= this.maxlength) {
                e.preventDefault();
            }
        }
    }

    writeValue(value: string) {
        this._value = value;
        this.onChange(value);
    }

    onChange = _ => {};
    onTouched = () => {};

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {}

    validate(c: UntypedFormControl) {
        return this.validateFn(c);
    }

    listenOnContentPaste(): void {
        this.renderer2.listen(this.review_textarea.nativeElement, 'paste', event => {
            event.preventDefault();
            const text = event.clipboardData.getData('text/plain');
            document.execCommand('insertHTML', false, text);
        });
    }

    listenOnContentEditableInput() {
        this.renderer2.listen(this.review_textarea.nativeElement, 'input', input =>
            this.updatecountValue(input.target.textContent),
        );
    }

    updatecountValue(text: string): void {
        if (this.maxlength) this.review_textarea = this.maxlength - text?.length;
    }

    getMaxlength(): string {
        if (this.publisher === 'pagesjaunes') {
            this.maxlength = 4000;
            return '4000';
        } else {
            this.maxlength = null;
            return '';
        }
    }
}
