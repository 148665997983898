import { NavigationDescriptor } from '@solocal-manager/sirius/core/models/navigation/navigation-descriptor';

export class NavigationUtils {
    static createLocationNavDesc(endPath: string, epj: string): NavigationDescriptor {
        return {
            route: endPath,
            queryParams: {
                epj,
            },
        };
    }
}
