import { createAction, props } from '@ngrx/store';

import { ISetFeatureActivationStatusPayload } from '@solocal-manager/sirius/core/models';

export const setFeatureActivationStatus = createAction(
    '[FEATURE SETTINGS] set activation status',
    props<{
        payload: ISetFeatureActivationStatusPayload;
    }>(),
);
