import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { PathService } from '@solocal-manager/sirius/core/core';
import { PaginationDecorator, PaginationDecoratorDTO } from '@solocal-manager/sirius/core/models';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { GetLocationListByError, GetPublisherErrors } from '../../actions';
import { ILocationKpi, IPublisherErrorsKpi } from '../../models';
import { getKpiLocationsByError, getKpiPublisherErrors, getPublisherNameById } from '../../reducers';
import { KpiUtilsService } from '../../services';

interface ISortConfig {
    location_name: boolean;
    partner_id: boolean;
    address_line1: boolean;
    zip_code: boolean;
    city: boolean;
}

@Component({
    selector: 'slm-app-kpi-error-status-detail',
    templateUrl: './kpi-error-status-detail.component.html',
    styleUrls: ['./kpi-error-status-detail.component.scss'],
})
export class KpiErrorStatusDetailComponent implements OnInit, OnDestroy {
    publisherErrors: IPublisherErrorsKpi[];
    locationList: PaginationDecoratorDTO<ILocationKpi>;
    publisherName$: Observable<string>;
    publisherId: string;
    state: string;
    messageId: string;
    errorIndex: number;
    errorMessage: string;
    destroyed$ = new Subject<boolean>();
    sortConfig: ISortConfig;

    constructor(
        private route: ActivatedRoute,
        private store: Store<any>,
        private kpiUtils: KpiUtilsService,
        private pathService: PathService,
    ) {}

    ngOnInit() {
        this.publisherId = this.route.snapshot?.params.publisherId;
        this.state = this.route.snapshot?.params.state;
        this.errorIndex = +this.route.snapshot?.params.errorIndex;

        this.store.pipe(select(getKpiPublisherErrors), takeUntil(this.destroyed$)).subscribe(publisherErrors => {
            this.publisherErrors = publisherErrors;
            if (!publisherErrors) {
                this.loadPublishersErrorData();
            } else {
                this.loadPublisherName();
            }
            this.loadErrorDetailStatusData();
        });

        this.route.params?.pipe(takeUntil(this.destroyed$)).subscribe(params => {
            if (params.publisherId !== this.publisherId || params.state !== this.state) {
                this.publisherId = params.publisherId;
                this.state = params.state;
                this.loadPublishersErrorData();
            }
            if (params.errorIndex !== this.errorIndex) {
                this.errorIndex = +params.errorIndex;
                this.loadErrorDetailStatusData();
            }
        });

        this.store.pipe(select(getKpiLocationsByError), takeUntil(this.destroyed$)).subscribe(locations => {
            this.locationList = locations;
            this.initSortConfig();
        });
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    onPagerValueChanged(value) {
        this.fetchLocations(this.publisherId, this.messageId, +value.itemsPerPage, value.page);
    }

    getLocationLink(location: ILocationKpi): string[] {
        const basePath = this.pathService.basePath.includes('app/2/')
            ? this.pathService.basePath
            : `${this.pathService.basePath}/app/2`;
        return [`${basePath}/company/${location.company_id}/location`, location.location_id, 'edit'];
    }

    get basePath(): string {
        return `${this.pathService.basePath}/kpi`;
    }

    private fetchLocations(publisherId: string, errorMessage: string, pageSize?: number, pageNumber?: number) {
        const params = {
            publisherId,
            errorMessage,
            pageSize: 20,
            pageNumber: 1,
        };
        if (pageSize && pageNumber) {
            params.pageSize = pageSize;
            params.pageNumber = pageNumber;
        }
        if (errorMessage) {
            this.store.dispatch(new GetLocationListByError(params));
        }
    }

    private loadPublisherName() {
        this.publisherName$ = this.store.pipe(select(getPublisherNameById(this.publisherId)));
    }

    private loadPublishersErrorData() {
        this.loadPublisherName();
        this.store.dispatch(
            new GetPublisherErrors({
                publisherId: this.publisherId,
                states: [this.state],
            }),
        );
    }

    private loadErrorDetailStatusData() {
        const errorInfo = this.kpiUtils.getErrorInfoByIndexAndState(this.publisherErrors, this.errorIndex, this.state);
        this.messageId = errorInfo.msg_id;
        this.errorMessage = errorInfo.user_msg;
        this.fetchLocations(this.publisherId, this.messageId);
    }

    private initSortConfig() {
        this.sortConfig = {
            location_name: true,
            partner_id: true,
            address_line1: true,
            zip_code: true,
            city: true,
        };
    }

    orderBy(fieldName: string) {
        switch (fieldName) {
            case 'location_name':
            case 'partner_id': {
                this.sortConfig[fieldName] = !this.sortConfig[fieldName];
                const desc = this.sortConfig[fieldName] ? 1 : -1;
                this.locationList.data.sort((a, b) => (a[fieldName] > b[fieldName] ? desc * -1 : desc));
                return;
            }
            case 'address_line1':
            case 'zip_code':
            case 'city': {
                this.sortConfig[fieldName] = !this.sortConfig[fieldName];
                const desc = this.sortConfig[fieldName] ? 1 : -1;
                this.locationList.data.sort((a, b) => (a.address[fieldName] > b.address[fieldName] ? desc * -1 : desc));
                return;
            }
        }
    }
}
