import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { CreateLocationService } from '../../services';

@Component({
    selector: 'slm-create-location',
    templateUrl: './create-location.component.html',
    styleUrls: ['./create-location.component.scss'],
})
export class CreateLocationComponent implements OnInit {
    @Input() maybeEpj: string;
    @Input() appId: string;
    @Output() createdLocation: EventEmitter<any> = new EventEmitter();
    @Output() done: EventEmitter<boolean> = new EventEmitter();
    working: boolean;

    constructor(public createLocation: CreateLocationService) {}

    ngOnInit() {
        this.working = false;
    }

    createLocationByEpj() {
        this.createLocation
            .execute(this.maybeEpj)
            .pipe(
                map((res: any) => {
                    const result = res?.data?.[0];
                    if (result) {
                        this.createdLocation.emit({
                            item: {
                                ...result,
                                companyId: result.company_id,
                                customerId: result.customer_id,
                                tab: 'production',
                                resultType: 'location',
                            },
                        });
                    }

                    this.close(true);
                }),
                catchError(() => {
                    this.close(false);
                    return of(null);
                }),
            )
            .subscribe();
    }

    close(success = false) {
        this.done.emit(success);
    }
}
