<div class="image-list">
    <div
        class="image-container"
        [ngClass]="{ fetching: fetching }"
        slmInfiniteScroller
        [scrollPercent]="100"
        [immediateCallback]="true"
        [scrollCallback]="scrollCallback"
    >
        <div class="scrollbar-hide"></div>
        <div class="image-item" *ngFor="let image of (imageList$ | async)?.data">
            <div class="image">
                <img [src]="image.thumbnail" alt="" />
                <div
                    class="action"
                    *ngFor="let action of actions; let i = index"
                    [ngStyle]="{
                        left: ((i + 1) * 163) / actions.length - (163 - 50 * actions.length) + 'px'
                    }"
                    (click)="onSingleImageSelect(image)"
                >
                    <span
                        class="glyphicon"
                        [ngClass]="{
                            'glyphicon-ok': action === 'select',
                            'glyphicon-pencil': action === 'edit',
                            'glyphicon-trash': action === 'remove'
                        }"
                    >
                    </span>
                </div>
            </div>
        </div>
    </div>
    <button
        *ngIf="!allImagesFetched"
        class="btn btn-link more-items"
        type="button"
        (click)="loadMoreImages()"
        [disabled]="fetching"
    >
        <span [hidden]="fetching">{{ 'More Images' | translate }}</span>
        <span *ngIf="fetching">
            <span class="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span>
            {{ 'Loading...' | translate }}
        </span>
    </button>
</div>
