import { Action } from '@ngrx/store';

import { Location } from '@solocal-manager/sirius/support/base-models';

export enum LocationActionTypes {
    GET_LOCATION = '[GET_LOCATION] get location',
    GET_LOCATION_SUCCESS = '[GET_LOCATION_SUCCESS] get location success',

    SET_SELECTED_LOCATION = '[SET_SELECTED_LOCATION] set current location',
    RESET_LOCATION = '[RESET_LOCATION] reset location',

    UPDATE_LOCATION = '[UPDATE_LOCATION] update location data in store',
    UPDATE_LOCATION_SUCCESS = '[UPDATE_LOCATION_SUCCESS] update location success',

    PUT_LOCATION = '[PUT_LOCATION] put location',
    PUT_LOCATION_SUCCESS = '[PUT_LOCATION] put location success',

    PATCH_LOCATION = '[PATCH_LOCATION] patch location',
    PATCH_LOCATION_SUCCESS = '[PATCH_LOCATION] patch location success',
    PATCH_LOCATION_ERROR = '[PATCH_LOCATION] patch location error',

    PATCH_LOCATION_SITE_CATALOG_SETTINGS = '[PATCH_LOCATION] patch location site catalog settings',

    PATCH_LOCATIONS_BULK = '[PATCH_LOCATIONS_BULK] patch locations bulk',
    PATCH_LOCATIONS_BULK_SUCCESS = '[PATCH_LOCATIONS_BULK_SUCCESS] patch locations bulk success',

    UPDATE_LOCATION_ON_BOARDING_STEP_UPDATE = '[UPDATE LOCATION ON BOARDING STEP UPDATE] Update Location On Onboarding Step Update',
}

export interface PatchLocationPayload {
    locationId: string;
    data: Partial<Location>;
}

/**
 * habilitatedLocation.epj === location.partner_id
 *
 * 2 ways to dispatch GetLocation:
 * >> 1. from id => location.id => GetLocation(id)
 * >> 2. from partner_id => location.partner_id => GetLocation(`pj:${partner_id}`)
 */
export class GetLocation implements Action {
    readonly type = LocationActionTypes.GET_LOCATION;

    constructor(public payload: string) {}
}

export class GetLocationSuccess implements Action {
    readonly type = LocationActionTypes.GET_LOCATION_SUCCESS;

    constructor(public payload: Location) {}
}

export class SetSelectedLocation implements Action {
    readonly type = LocationActionTypes.SET_SELECTED_LOCATION;

    constructor(public payload: Location) {}
}

export class PutLocation implements Action {
    readonly type = LocationActionTypes.PUT_LOCATION;

    constructor(public payload: Location) {}
}

export class PutLocationSuccess implements Action {
    readonly type = LocationActionTypes.PUT_LOCATION_SUCCESS;

    constructor(public payload: Location) {}
}

export class PatchLocation implements Action {
    readonly type = LocationActionTypes.PATCH_LOCATION;

    constructor(public payload: PatchLocationPayload) {}
}

export class PatchLocationSuccess implements Action {
    readonly type = LocationActionTypes.PATCH_LOCATION_SUCCESS;

    constructor(public payload: Location) {}
}

export class PatchLocationError implements Action {
    readonly type = LocationActionTypes.PATCH_LOCATION_ERROR;

    constructor(public error: any) {}
}

export class PatchLocationSiteCatalogSettings implements Action {
    readonly type = LocationActionTypes.PATCH_LOCATION_SITE_CATALOG_SETTINGS;

    constructor(public payload: { locationId: string; email: string; status: boolean }) {}
}

export class PatchLocationsBulk implements Action {
    readonly type = LocationActionTypes.PATCH_LOCATIONS_BULK;

    constructor(public params: { locationIds: string[]; locationPartial: any }) {}
}

export class UpdateLocationOnOnboardingStepUpdate implements Action {
    readonly type = LocationActionTypes.UPDATE_LOCATION_ON_BOARDING_STEP_UPDATE;

    constructor(public payload: Location) {}
}

export class PatchLocationsBulkSuccess implements Action {
    readonly type = LocationActionTypes.PATCH_LOCATIONS_BULK_SUCCESS;

    constructor(public result: { locationIds: string[]; locationPartial: any }) {}
}

// TODO: Check if deprecated in favor of patch and put ?
export class UpdateLocation implements Action {
    readonly type = LocationActionTypes.UPDATE_LOCATION;

    constructor(public payload: Location) {}
}

// TODO: Check if deprecated in favor of patch and put ?
export class UpdateLocationSuccess implements Action {
    readonly type = LocationActionTypes.UPDATE_LOCATION_SUCCESS;

    constructor(public payload: Location) {}
}

export class ResetLocation implements Action {
    readonly type = LocationActionTypes.RESET_LOCATION;
}

export type LocationActionTypesUnion =
    | GetLocation
    | GetLocationSuccess
    | UpdateLocation
    | UpdateLocationSuccess
    | PatchLocation
    | PatchLocationSuccess
    | PatchLocationError
    | PutLocation
    | PutLocationSuccess
    | SetSelectedLocation
    | ResetLocation
    | PatchLocationsBulk
    | PatchLocationsBulkSuccess
    | UpdateLocationOnOnboardingStepUpdate;
