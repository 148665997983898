import { EnvironmentProviders, importProvidersFrom } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { LocationAnalyticsEffects } from '../effects/location-analytics.effects';
import { RouterAnalyticsEffects } from '../effects/router-analytics.effects';
import { UserAnalyticsEffects } from '../effects/user-analytics.effects';

export const provideAnalytics = (): EnvironmentProviders =>
    importProvidersFrom([
        EffectsModule.forFeature([UserAnalyticsEffects, LocationAnalyticsEffects, RouterAnalyticsEffects]),
    ]);
