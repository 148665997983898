import { KpiActionsUnion, KpiActionTypes } from '../actions';
import { IPageStatesKpi } from '../models';

export type PageStatesKpiState = IPageStatesKpi[];

const initialState: PageStatesKpiState = undefined;

export function pageStatesKpiReducer(state = initialState, action: KpiActionsUnion): PageStatesKpiState {
    switch (action.type) {
        case KpiActionTypes.GET_PAGE_STATES_SUCCESS: {
            return action.result;
        }
        default:
            return state;
    }
}
