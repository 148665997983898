import { Location as BrowserLocation } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectUser, User } from '@slm/user/state';
import { LocationActions } from '@solocal-manager/sirius/core/core';
import { Location } from '@solocal-manager/sirius/support/base-models';
import { NGXLogger } from 'ngx-logger';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ISearchLocationSelected } from '../../models';
import { AdminUiService } from '../../services';

@Component({
    selector: 'slm-admin-tools-index-page',
    templateUrl: './admin-tools-index-page.component.html',
    styleUrls: ['./admin-tools-index-page.component.scss'],
})
export class AdminToolsIndexPageComponent implements OnInit, ISearchLocationSelected, OnDestroy {
    hoverLocation: Location;
    showMoveLocation: boolean;
    selectedLocation: Location;
    applicationId: string;
    locationSelected = false;
    private isDestroyed$ = new Subject<void>();

    constructor(
        public router: Router,
        public store: Store<any>,
        public location: BrowserLocation,
        public logger: NGXLogger,
        public adminUiService: AdminUiService,
    ) {}

    ngOnInit(): void {
        this.store
            .select(selectUser)
            .pipe(takeUntil(this.isDestroyed$))
            .subscribe((user: User) => {
                this.applicationId = user?.application?.id;
            });
    }
    locationSelection(change: boolean): void {
        this.locationSelected = change;
        this.adminUiService.locationChanged.next(change);
    }
    selectLocation(location: Location): void {
        this.selectedLocation = location;

        this.logger.debug('selectLocation -> location: ', location);

        this.store.dispatch({
            type: LocationActions.GET_LOCATION,
            payload: location.id,
        });

        this.router.navigate([
            'app',
            this.applicationId,
            'company',
            location.company_id,
            'location',
            location.id,
            'edit',
        ]);
    }

    mouseoverLocation(location: Location): void {
        this.hoverLocation = location;
    }

    ngOnDestroy() {
        this.isDestroyed$.next(undefined);
        this.isDestroyed$.complete();
    }
}
