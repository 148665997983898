import { UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { of, Observable } from 'rxjs';

export class CombinedUrlValidator {
    static create(form: UntypedFormGroup) {
        return (): Observable<ValidationErrors> => {
            return of(form.valid ? null : { combinedUrl: false });
        };
    }
}
