import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'textToUrl',
})
export class TextToUrlPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (value) {
            const replacement = `<a target="_blank" href="${args && args.url ? args.url : '$1'}">$1</a>`;

            value = value.replace(/###([^#]+)###/, replacement);
        }

        return value;
    }
}
