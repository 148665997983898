import { Pipe, PipeTransform } from '@angular/core';
import { PhoneNumber, PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

import { countryCodes } from '@solocal-manager/sirius/core/config';

@Pipe({
    name: 'phoneNumber',
})
export class PhoneNumberPipe implements PipeTransform {
    phoneNumberUtil = PhoneNumberUtil.getInstance();

    transform(value: string, countryCode: string = '33'): string {
        const country = countryCodes.find(item => item.code === countryCode)?.country;
        let phoneNumber: PhoneNumber;
        try {
            phoneNumber = this.phoneNumberUtil.parseAndKeepRawInput(value, country);
            if (this.phoneNumberUtil.isValidNumber(phoneNumber)) {
                return this.phoneNumberUtil.format(phoneNumber, PhoneNumberFormat.INTERNATIONAL);
            }
            return value;
        } catch (e) {
            return value;
        }
    }
}
