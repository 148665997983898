export const serviceAreaConfig = {
    businessTypeOptions: [
        {
            id: 'business_type_unspecified',
            name: 'Business type unspecified',
            disabled: false,
        },
        {
            id: 'customer_location_only',
            name: 'Customer Location only',
            disabled: false,
        },
        {
            id: 'customer_and_business_location',
            name: 'Customer and Business Location',
            disabled: false,
        },
    ],
    maxPlaces: 20,
};
