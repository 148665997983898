import { UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { of } from 'rxjs';

export class AddressValidator {
    static create(form: UntypedFormGroup) {
        return (): ValidationErrors => {
            const err = {
                addressError: {
                    valid: form.valid,
                },
            };
            return form.valid || form.pending ? of(null) : of(err);
        };
    }
}
