export * from './app-version';
export * from './app.config';
export * from './auth.config';
export * from './connectable-publishers.config';
export * from './keys.config';
export * from './login.config';
export * from './oauth.config';
export * from './phones.config';
export * from './pj-specific.config';
export * from './publisher-search-states.config';
export * from './publisher-states.config';
export * from './sso.config';

export const ALLOWED_ORIGINS = [];
export const AVAILABLE_PAGES = undefined;
export const EMenuType = undefined;
export const ESsoOrigins = [];
export const getBackLinkByOrigin = undefined;
export const MULTI_LOCATION = undefined;
export const PageIdsMap = [];
export const SINGLE_LOCATION = undefined;
