<div *ngIf="publisherId === 'pagesjaunes'">
    <slm-publisher-connect-pagesjaunes
        [location]="location"
        (closeModal)="onClaimed($event)"
    ></slm-publisher-connect-pagesjaunes>
</div>

<div *ngIf="publisherId === 'facebook'">
    <slm-publisher-connect-facebook
        [location]="location"
        (closeModal)="onClaimed($event)"
    ></slm-publisher-connect-facebook>
</div>

<div *ngIf="publisherId === 'gmb'">
    <slm-publisher-connect-gmb-process
        [location]="location"
        (closeModal)="onClaimed($event)"
    ></slm-publisher-connect-gmb-process>
</div>

<div *ngIf="shouldRenderConnectWebsite">
    <slm-publisher-connect-website
        [location]="location"
        [publisherId]="publisherId"
        (closeModal)="onClaimed($event)"
    ></slm-publisher-connect-website>
</div>

<div *ngIf="publisherId === 'tripadvisor'">
    <slm-publisher-connect-tripadvisor
        [location]="location"
        [publisherId]="publisherId"
        (closeModal)="onClaimed($event)"
    ></slm-publisher-connect-tripadvisor>
</div>

<div *ngIf="connectablePublishers[publisherId] === 'click'" class="alert alert-info">
    <p>{{ 'Connected.' | translate }}</p>
</div>

<div class="clearfix"></div>
