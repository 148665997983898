<div class="row col-md-12 text-center">
    <div [formGroup]="gmbAllowForm"
         class="row col-md-12">
        <div class="row col-md-12">
            <label for="email">{{'Email address' | translate}}</label>
            <input type="email"
                   id="email"
                   name="email"
                   formControlName="email"
                   placeholder="{{'john@doe.com ...' | translate}}">
            <span>
                {{'used to manage the location on Google My Business Google might ' | translate}}
                {{'use this email address to directly communicate with you' | translate}}
            </span>
        </div>

        <div class="row col-md-12">
            <input type="checkbox"
                   (change)="onOptoutChange($event)"
                   formControlName="optout"
                   name="optout"
                   id="optout">
            <label for="optout">
                <b>{{ 'I don\'t want to become the owner of the location on Google My Business' | translate }}</b>
            </label>
        </div>

        <button class="btn btn-primary"
                [disabled]="gmbAllowForm.invalid"
                (click)="createNewGMBPage()">{{ 'Connect to Google My Business' | translate }}</button>
    </div>
</div>