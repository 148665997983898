import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';

import { GetCurrentCompany } from '@solocal-manager/sirius/core/core';
import { MultiSearchActions } from '@solocal-manager/sirius/core/core';
import { Company, MultiSearchInternalResult } from '@solocal-manager/sirius/core/models';

@Component({
    selector: 'app-company-select',
    templateUrl: './company-select.component.html',
    styleUrls: ['./company-select.component.scss'],
})
export class CompanySelectComponent implements OnInit, OnDestroy {
    multiSearch$: Observable<MultiSearchInternalResult[]>;
    multiSearchSubscribtion;
    multiSearchSelected$: Observable<MultiSearchInternalResult>;
    multiSearchSelectedSubscribtion;
    currentCompany$: Observable<string>;
    currentCompanySubscribtion;
    companies: Company[];
    currentCompanyId: string;

    constructor(public store: Store<any>, public logger: NGXLogger) {}

    ngOnInit() {
        this.multiSearch$ = this.store.pipe(select('multiSearch'));
        this.multiSearchSelected$ = this.store.pipe(select('multiSearchSelected'));

        this.multiSearchSubscribtion = this.multiSearch$.subscribe((internalResults: MultiSearchInternalResult[]) => {
            if (MultiSearchActions.GET_MULTI_SEARCH_SUCCESS) {
                this.companies = internalResults
                    .filter((result: MultiSearchInternalResult) => result.resultType === 'company')
                    .map((result: MultiSearchInternalResult) => {
                        return {
                            name: result.name,
                            id: result.id,
                            modified: '',
                            partner_id: '',
                        };
                    });
            }
        });

        this.multiSearchSelectedSubscribtion = this.multiSearchSelected$.subscribe(
            (selected: MultiSearchInternalResult) => {
                if (selected.resultType === 'location') {
                    this.companies = this.companies.filter((company: Company) => company.id === selected.companyId);
                }
            },
        );

        this.currentCompanySubscribtion = this.currentCompany$.subscribe((companyId: string) => {
            if (companyId) {
                this.currentCompanyId = companyId;
            }
        });
    }

    ngOnDestroy(): void {
        this.multiSearchSubscribtion.unsubscribe();
        this.multiSearchSelectedSubscribtion.unsubscribe();
        this.currentCompanySubscribtion.unsubscribe();
    }

    onCurrentCompanyChange(event) {
        this.store.dispatch(new GetCurrentCompany(event.target.value));
    }
}
