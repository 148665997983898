import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-input-checkbox',
    templateUrl: './input-checkbox.component.html',
    styleUrls: ['./input-checkbox.component.scss'],
})
export class InputCheckboxComponent {
    @Input() checkboxValue: boolean;
    @Input() id: string;
    @Input() title: string;

    @Output() valueChecked = new EventEmitter<boolean>();

    checked($event): void {
        this.valueChecked.emit(this.checkboxValue);
    }
}
