import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse,
    HttpResponse,
} from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

// services
import { LoadingService } from '../services/loading.service';

@Injectable({ providedIn: 'root' })
export class LoadingInterceptor implements HttpInterceptor {
    public apiLoading: EventEmitter<boolean> = new EventEmitter<boolean>();
    reqStats = {};
    loaderTimeout = 2000;
    loaderTimeoutId: number;
    private blacklist = [
        {
            method: 'GET',
            urlRegex: /\/user\/(\w+|\d+)\/notifications/,
        },
    ];

    constructor(public loadingService: LoadingService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.isBlackListed(request)) {
            return next.handle(request);
        }
        const customReq = request.clone({
            headers: request.headers,
        });

        this.handleLoaderTimeout();
        this.setInitialLoading(customReq.url);

        return next.handle(customReq).pipe(
            tap(
                response => {
                    if (response instanceof HttpResponse) {
                        this.decreaseLoadingCount(response.url);
                    }
                },
                err => {
                    if (err instanceof HttpErrorResponse) {
                        this.decreaseLoadingCount(err.url);
                    }
                },
            ),
        );
    }

    setInitialLoading(url) {
        if (url) {
            this.reqStats[url] = 1;
            this.loadingService.emitIsLoading(true);
        }
    }

    decreaseLoadingCount(url: string) {
        delete this.reqStats[url];

        if (!Object.keys(this.reqStats).length) {
            this.loadingService.emitIsLoading(false);
        }
    }

    handleLoaderTimeout() {
        if (this.loaderTimeoutId) {
            window.clearTimeout(this.loaderTimeoutId);
        }

        this.loaderTimeoutId = window.setTimeout(() => {
            this.reqStats = {};
            this.loadingService.emitIsLoading(false);
        }, this.loaderTimeout);
    }

    private isBlackListed(req: HttpRequest<any>): boolean {
        return this.blacklist.some(item => item.urlRegex.test(req.url) && req.method.toUpperCase() === item.method);
    }
}
