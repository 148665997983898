import { IImageRules } from '@solocal-manager/sirius/core/models';

export const imagesAllowedMimeTypes = ['image/png', 'image/jpg', 'image/jpeg'];

export const imagesMaxFileSizes = {
    search_result: 5 * 1024 * 1024, // 5MB
    others: 100 * 1024 * 1024,
    size: 10240, //10kb
};

export const imagesDimensions: { [key: string]: IImageRules } = {
    logo: {
        minWidth: 720,
        minHeight: 720,
        maxWeight: 2097152,
        maxWeightMo: 2,
        minFileSize: 10240,
        maxFileSize: 2097152,
        formats: ['jpeg', 'jpg', 'jpe', 'gif', 'png'],
    },
    fb_cover: {
        minWidth: 140,
        minHeight: 140,
        maxHeight: 5000,
        maxWidth: 5000,
        maxWeight: null,
        maxWeightMo: 5,
        minFileSize: 10240,
        maxFileSize: 5242880,
        formats: ['jpeg', 'jpg', 'jpe', 'gif', 'png'],
    },
    gallery: {
        minWidth: 720,
        minHeight: 720,
        minFileSize: 10240,
        maxFileSize: 10485760,
        maxWeightMo: 10,
        formats: ['jpeg', 'jpg', 'jpe', 'gif', 'png'],
    },
    search_result: {
        minWidth: 140,
        minHeight: 140,
        maxHeight: 5000,
        maxWidth: 5000,
        maxWeight: null,
        maxWeightMo: 5,
        minFileSize: 10240,
        maxFileSize: 5242880,
        formats: ['jpeg', 'jpg', 'jpe', 'gif', 'png'],
    },
    publication: {
        minWidth: 400,
        minHeight: 300,
        minFileSize: 10240,
        maxFileSize: 10485760,
        maxWeightMo: 10,
        formats: ['png', 'jpg', 'jpeg'],
    },
};

export type ImageErrorConfig = {
    key: 'dimension' | 'format' | 'filesize';
    params: Array<{
        key: 'min_Width' | 'min_Height' | 'image_name' | 'file_mb' | 'max_Height' | 'max_Width' | 'formats';
        value: 'name' | 'minWidth' | 'minHeight' | 'maxWeightMo' | 'maxHeight' | 'maxWidth' | 'formats';
    }>;
};
export const imageErrorConfig: ImageErrorConfig[] = [
    {
        key: 'dimension',
        params: [
            { key: 'image_name', value: 'name' },
            { key: 'min_Width', value: 'minWidth' },
            { key: 'min_Height', value: 'minHeight' },
            { key: 'max_Width', value: 'maxWidth' },
            { key: 'max_Height', value: 'maxHeight' },
        ],
    },
    {
        key: 'format',
        params: [
            { key: 'image_name', value: 'name' },
            { key: 'formats', value: 'formats' },
        ],
    },
    {
        key: 'filesize',
        params: [
            { key: 'image_name', value: 'name' },
            { key: 'file_mb', value: 'maxWeightMo' },
        ],
    },
];
