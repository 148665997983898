<slm-publisher-connect-status
    [state]="state"
    [message]="message"
    [count]="publisherSearchResults?.length"
></slm-publisher-connect-status>

<div class="results-container" *ngFor="let searchResult of publisherSearchResults">
    <div
        class="results"
        [ngClass]="{ highlight: isExistingResult(searchResult?.page_id) }"
        *ngIf="searchResult?.places && searchResult?.places?.length"
    >
        <table class="table">
            <thead>
                <th scope="col">{{ 'ID' | translate }}</th>
                <th scope="col">{{ 'TYPE' | translate }}</th>
                <th scope="col">{{ 'PAGE ID' | translate }}</th>
            </thead>
            <tbody>
                <tr>
                    <td>{{ searchResult.id }}</td>
                    <td>{{ searchResult.type }}</td>
                    <td>{{ searchResult.page_id }}</td>
                </tr>
            </tbody>
        </table>

        <table class="table">
            <thead>
                <th scope="col">{{ 'Category' | translate }}</th>
                <th scope="col">{{ 'Location' | translate }}</th>
            </thead>
            <tbody>
                <tr *ngFor="let place of searchResult.places">
                    <td>{{ place.activity }}</td>
                    <td>{{ place.locality }}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div
        class="row col-md-12 clear text-center"
        *ngIf="!isExistingResult(searchResult.page_id) && searchResult.places?.length"
    >
        <button class="btn btn-primary publisher-create-page" (click)="connect(searchResult.page_id)">
            {{ 'Connect' | translate }}
        </button>
    </div>
</div>

<div class="row col-md-12 clear text-center">
    <button class="btn btn-primary publisher-create-page" (click)="connect('')">
        {{ 'Connect to PagesJaunes without CVIV' | translate }}
    </button>
</div>
