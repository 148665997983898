import { FeatureType, IFeatureError } from '@solocal-manager/sirius/support/base-models';
import { Location } from '@solocal-manager/sirius/support/base-models';
import { Dictionary } from '@solocal-manager/sirius/support/base-models';
import { featureTypeKeyMap, IFeatureSettings } from '@solocal-manager/sirius/support/base-models';
import { FeatureActivationStatus } from '../enum/feature-activation-status.enum';

export class FeatureSettingsViewModel {
    readonly active: boolean;
    readonly error: boolean;
    readonly errorMsg: IFeatureError;
    readonly ignored: boolean;
    readonly inactive: boolean;
    readonly oAuthNeeded: boolean;
    readonly paused: boolean;
    readonly pending: boolean;
    readonly activated: boolean;

    readonly locationId: string;
    readonly locationEpj: string;
    readonly locationPack: string;

    /**
     * WARNING: Will only work correctly for feature_settings using matching IDs with pack.features
     */
    readonly canBeActivated: boolean;

    constructor(
        public feature: FeatureType,
        public status: FeatureActivationStatus,
        public status_changed_at: string,
        public status_paused_from: string,
        public status_paused_to: string,
        public settings: Dictionary<any>,
        location: Location,
        public featureError?: IFeatureError,
    ) {
        this.active = status === FeatureActivationStatus.ACTIVE;
        this.error = status === FeatureActivationStatus.ERROR;
        this.errorMsg = featureError;
        this.ignored = status === FeatureActivationStatus.IGNORED;
        this.inactive = status === FeatureActivationStatus.INACTIVE;
        this.oAuthNeeded = status === FeatureActivationStatus.OAUTH_NEEDED;
        this.paused = status === FeatureActivationStatus.PAUSED;
        this.pending = status === FeatureActivationStatus.PENDING;
        this.activated = !!status;

        this.locationId = location?.id;
        this.locationEpj = location?.epj;
        this.locationPack = location?.custom_data?.pack?.id;

        this.canBeActivated = location?.custom_data?.pack?.features?.includes(feature);
    }

    static getKeyForFeatureType(featureType: FeatureType) {
        return featureTypeKeyMap[featureType] ?? featureType;
    }

    static getFeatureTypeForKey(val: string): FeatureType {
        return (Object.keys(featureTypeKeyMap).find(key => featureTypeKeyMap[key] === val) ?? val) as FeatureType;
    }
}

export const createFeatureSettingsViewModel = (
    featureSettings: IFeatureSettings,
    location: Location,
): FeatureSettingsViewModel =>
    new FeatureSettingsViewModel(
        FeatureSettingsViewModel.getFeatureTypeForKey(featureSettings.feature),
        FeatureActivationStatus[featureSettings?.status?.toUpperCase()],
        featureSettings?.status_changed_at,
        featureSettings?.status_paused_from,
        featureSettings?.status_paused_to,
        featureSettings?.settings,
        location,
        featureSettings.error,
    );
