import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { SlmAuthFacade } from '@solocal-manager/auth';

import { PermissionStrategy } from '../../../enums';
import { PermissionEvaluation } from '../../../models';

@Injectable({
    providedIn: 'root',
})
export class CanReadOnBehalfService implements PermissionEvaluation {
    readonly type = PermissionStrategy.CanReadOnBehalf;

    constructor(private readonly slmAuthFacade: SlmAuthFacade) {}

    hasPermission(): Observable<boolean> {
        return of(this.hasUserRole(this.slmAuthFacade.getUserRoles(), ['slm_backoffice-user', 'slm_crm-user']));
    }

    hasUserRole(keycloakRoles: string[], onBehalfRoles: string[]): boolean {
        return onBehalfRoles.some(role => keycloakRoles.includes(role));
    }
}
