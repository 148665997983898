import { ReferentielActionTypes, ReferentielActionTypesUnion } from '@solocal-manager/sirius/core/actions';
import { ICategoryBrands } from '@solocal-manager/sirius/core/models';

export type CategoryBrandsState = ICategoryBrands[];

export const initialState: CategoryBrandsState = undefined;

export function categoryBrandsReducer(state = initialState, action: ReferentielActionTypesUnion): CategoryBrandsState {
    switch (action.type) {
        case ReferentielActionTypes.GET_CATEGORY_BRANDS_SUCCESS: {
            return action.result;
        }
        default:
            return state;
    }
}
