import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';

import { Publisher } from '../models';

import { ActionWithPayload } from '.';

@Injectable({ providedIn: 'root' })
export class AvailablePublishersActions {
    static GET_AVAILABLE_PUBLISHERS = '[GET_AVAILABLE_PUBLISHERS] get array of available publishers publishers';

    getAvailablePublishers(): Action {
        return {
            type: AvailablePublishersActions.GET_AVAILABLE_PUBLISHERS,
        };
    }

    static GET_AVAILABLE_PUBLISHERS_SUCCESS =
        '[GET_AVAILABLE_PUBLISHERS_SUCCESS] get array of available publishers success';

    getAvailablePublishersSuccess(publishersArray: Publisher[]): ActionWithPayload<Publisher[]> {
        return {
            type: AvailablePublishersActions.GET_AVAILABLE_PUBLISHERS_SUCCESS,
            payload: publishersArray,
        };
    }
}
