import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiErrorActions } from '@slm/shared/error';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { CurrentCompanyActionTypes, GetCurrentCompany, GetCurrentCompanySuccess } from '../actions';
import { CompaniesService } from '../services/companies.service';

@Injectable({ providedIn: 'root' })
export class CurrentCompanyEffects {
    company$ = createEffect(() =>
        this.update$.pipe(
            ofType<GetCurrentCompany>(CurrentCompanyActionTypes.GET_CURRENT_COMPANY),
            map(action => action.id),
            switchMap(companyId =>
                this.svc.getCompany(companyId).pipe(
                    map(results => new GetCurrentCompanySuccess(results)),
                    catchError(error => of(new ApiErrorActions.CatchApiError(error))),
                ),
            ),
        ),
    );

    constructor(
        private update$: Actions,
        private svc: CompaniesService,
    ) {}
}
