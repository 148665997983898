import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@slm/shared/environment';
import {
    Location,
    LocationDecorated,
    LocationsDTO,
    PaginationDecoratorDTO,
} from '@solocal-manager/sirius/support/base-models';
import { get } from 'lodash-es';
import { Observable } from 'rxjs';

import { ILocationListGetRequest } from '../../models/location-list-get-request.model';

/**
 * @legacy
 */
@Injectable({ providedIn: 'root' })
export class LocationListService {
    constructor(
        public http: HttpClient,
        public environmentService: EnvironmentService,
    ) {}

    /*
        type described in ILocationListGetRequest
    */
    getLocationList(payload: ILocationListGetRequest): Observable<LocationsDTO> {
        const { companyId, config } = payload;

        const requestUrl = `${this.environmentService.apiUrl}/consumer/companies/${companyId}/locations`;

        let params = new HttpParams().set('fields', 'custom_data');
        const pageNumber = get(config, 'pageNumber', 0);
        const pageSize = get(config, 'pageSize', 0);
        const locationName = get(config, 'filter.locationName');
        const packs = get(config, 'filter.packs');
        const shouldReturnMeta = get(config, 'shouldReturnMeta');
        const partnerIds = get(config, 'partnerIds');

        if (pageNumber) {
            params = params.set('page_number', '' + pageNumber);
        }

        if (pageSize) {
            params = params.set('page_size', '' + pageSize);
        }

        if (locationName) {
            params = params.set('location_name', '' + locationName);
        }

        if (packs && packs.length) {
            params = params.set('packs', '' + packs.join(','));
        }

        if (shouldReturnMeta) {
            params = params.set('fields', 'custom_data,meta');
        }

        if (partnerIds) {
            params = params.set('partner_ids', partnerIds.join(','));
        }

        // params = params.set('states', '' + 'all');

        return this.http.get<PaginationDecoratorDTO<LocationDecorated>>(requestUrl, {
            params,
        });
    }

    getLimitedLocationList(location: Location): PaginationDecoratorDTO<LocationDecorated> {
        const decoratedLocation: LocationDecorated = {
            position: 1,
            hidden: false,
            ...location,
        };

        const locationPaginator: PaginationDecoratorDTO<LocationDecorated> = {
            next: null,
            next_page: null,
            previous: null,
            previous_page: null,
            currentPage: 1,
            count: 1,
            data: [decoratedLocation],
        };

        return locationPaginator;
    }
}
