import { createAction, props } from '@ngrx/store';

import { ILocationListGetRequest } from '@slm/location/api';
import { Location, LocationsDTO } from '@solocal-manager/sirius/support/base-models';

import { LocationEntity } from './locations.models';

export type LocationsLoadRequest = ILocationListGetRequest;

export const StopFetchingLocationsType = '[Locations] Stop Fetching Locations';

export interface GetOrSetLocationCommonProps {
    selectIt: boolean;
}

export interface GetLocationProps extends GetOrSetLocationCommonProps {
    opType: 'GET';
    id: string;
}

export interface SetLocationProps extends GetOrSetLocationCommonProps {
    opType: 'SET';
    location: Location;
}

export type GetOrSetLocationProps = GetLocationProps | SetLocationProps;

export const loadLocations = createAction(
    '[Locations] Load Locations',
    props<{ request: LocationsLoadRequest; continuation?: boolean }>(),
);

export const loadLocationsSuccess = createAction(
    '[Locations] Load Locations Success',
    props<{ locations: LocationsDTO }>(),
);

export const setSingleLocation = createAction('[Locations] Set Single Location', props<{ location: Location }>());

export const updateLocation = createAction('[Locations] Update Location', props<{ location: LocationEntity }>());

export const loadLocationsFailure = createAction('[Locations] Load Locations Failure', props<{ error: any }>());

export const patchLocationOnboarding = createAction(
    '[Locations] Patch Location',
    props<{ id: string; onboarding_steps: Partial<LocationEntity> }>(),
);

export const patchLocationsBulkSuccess = createAction(
    '[Locations] Patch Locations Buld Success',
    props<{ locationIds: string[]; locationPartial: any }>(),
);

export const patchLocationSucces = createAction(
    '[Locations] Patch Location Success',
    props<{ id: string; location: Partial<LocationEntity> }>(),
);

export const putLocationSuccess = createAction('[Locations] Put Location Success', props<{ location: Location }>());

export const resetLocations = createAction('[Locations] Reset Locations');

export const setSelectedLocation = createAction('[Locations] Set Selected', props<{ id: string }>());

export const loadLocation = createAction('[Locations] Load Location', props<{ id: string; selectIt: boolean }>());

export const loadLocationSuccess = createAction('[Locations] Get Location Success', props<{ location: Location }>());

export const setFetchedLocation = createAction(
    '[Locations] Set Fetched Locations',
    props<{ location: Location; selectIt: boolean }>(),
);

export const getOrSetLocation = createAction('[Locations] Get Or Set Location', props<GetOrSetLocationProps>());

export const putSelectedLocationSuccess = createAction(
    '[Locations] Put Selected Location Success',
    props<{ location: Location }>(),
);

export const patchSelectedLocationSuccess = createAction(
    '[Locations] Patch Selected Location Success',
    props<{ location: Location }>(),
);

export const updateSelectedLocationOnboardingStep = createAction(
    '[Locations] Update Selected Location Onboarding Steap',
    props<{ location: Location }>(),
);

export const unselectLocation = createAction('[Locations] Unselect Location');

export const patchLocationsBulk = createAction(
    '[Locations] Patch Locations Bulk',
    props<{ locationIds: string[]; locationPartial: Partial<Location> }>(),
);

export const putLocation = createAction(
    '[Locations] Put Selected Location',
    props<{ locationId: string; locationUpdate: Partial<Location> }>(),
);

export const patchLocation = createAction(
    '[Locations] Patch Selected Location',
    props<{ id: string; locationUpdate: Partial<Location> }>(),
);

export const patchSelectedLocationSiteCatalogSettings = createAction(
    '[Locations] Patch Selected Location Site Catalog Settings',
    props<{ locationId: string; email: string; status: boolean }>(),
);

export const stopFetchingLocations = createAction(StopFetchingLocationsType);

export function getIdForGetOrSetLocationAction(actionProps: GetOrSetLocationProps): string {
    switch (actionProps.opType) {
        case 'GET':
            return actionProps.id;
        case 'SET':
            return actionProps.location.id;
    }
}

export const setSelectedIds = createAction('[Locations] Set Selected Ids', props<{ selectedIds: string[] }>());

export const getLocationRetentionEligibility = createAction(
    '[Locations] get Selected Location Retention Eligibility',
    props<{ epj: string }>(),
);

export const getLocationRetentionEligibilitySuccess = createAction(
    '[Locations] get Selected Location Retention Eligibility Success',
    props<{ epj: string; isEligible: boolean; locationId: string }>(),
);
