import { SlmEnvironment } from '@slm/shared/environment';

const apiAzureBase = 'https://recapi.app.solocalgroup.com';

export const environment: SlmEnvironment = {
    app: 'backoffice',
    production: false,
    env: 'stg',
    defaultLanguage: 'fr',
    saamApi: 'https://api-staging.solocal.com/v1/saam',
    apiUrl: 'https://wpm.stg.yelsterdigital.com/api/v1.0',
    apiBase: 'https://wpm.stg.yelsterdigital.com/',
    uiUrl: 'https://rec.manager.solocal.com/',
    armatisUrl: 'https://prolive-rec.armatis-lc.com/',
    apiAzureUrl: apiAzureBase,
    apiAzureBase,
    azureSubKey: 'ff39870ffbdb4be6b6c65376d6fcea6e',
    SsoOriginPages: [], // used only in sirius desktop
    SsoSiriusPages: [], // used only in sirius desktop
    keycloakUrl: 'https://qa-rec.dev.sso.solocal.com/auth/',
};
