import { IOfferConfig } from '../models';

export const sectionPresenceConfig: IOfferConfig = {
    BE: {
        label: 'Espace Bons Plans',
        name: '',
        logo: '',
        disableCRM: true,
    },
    K2DA: {
        label: 'Solution Pack',
        name: '',
        logo: '',
        disableCRM: false,
    },
    O_GCR_TDR_CONNECT: {
        label: 'Connect Réseaux',
        name: '',
        logo: '',
        disableCRM: false,
    },
    O_NETWORK_CONNECT_RANKED: {
        label: 'Connect Réseau Ranké',
        name: '',
        logo: '',
        disableCRM: false,
    },
    K2SD: {
        label: 'Connect Initial',
        name: '',
        logo: '',
        disableCRM: false,
    },
    K2QC: {
        label: 'My Connect',
        name: '',
        logo: '',
        disableCRM: false,
    },
};
