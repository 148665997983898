import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PathService } from '@solocal-manager/sirius/core/core';

import { GetTotalSales } from '../../actions';
import { ITotalSalesKpi, IWeekInfo, IYearInfo } from '../../models';
import { getKpiTotalSales } from '../../reducers';
import { KpiUtilsService } from '../../services';

@Component({
    selector: 'app-kpi-total-sales',
    templateUrl: './kpi-total-sales.component.html',
    styleUrls: ['./kpi-total-sales.component.scss'],
})
export class KpiTotalSalesComponent implements OnInit {
    totalSales$: Observable<ITotalSalesKpi>;
    status: string;
    availableStatusList = ['active'];

    constructor(
        private route: ActivatedRoute,
        private store: Store<any>,
        private pathService: PathService,
        private kpiUtils: KpiUtilsService,
    ) {}

    ngOnInit() {
        this.status = this.route.snapshot.params.status;
        if (!this.status || this.availableStatusList.indexOf(this.status) !== -1) {
            this.store.dispatch(
                new GetTotalSales({
                    status: this.status,
                }),
            );
            this.totalSales$ = this.store.pipe(
                select(getKpiTotalSales),
                map((totalSales: ITotalSalesKpi) => {
                    if (totalSales && totalSales.data) {
                        totalSales.data.forEach((yearInfo: IYearInfo) => {
                            yearInfo.weeks.sort((a, b) => (a.week > b.week ? -1 : 1));
                        });
                        totalSales.data.sort((a, b) => (a.year > b.year ? -1 : 1));
                    }
                    return totalSales;
                }),
            );
        }
    }

    get basePath() {
        return this.pathService.basePath + '/kpi';
    }

    isFuture(year: IYearInfo, week: IWeekInfo) {
        return this.kpiUtils.isFutureInfo(year, week);
    }
}
