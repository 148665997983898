<div class="location-list-pagination-container col-md-12">
    <div class="col-md-10 col-sm-10 col-10">
        <pagination
            [totalItems]="totalCount"
            [maxSize]="PAGINATION_MAX_SIZE"
            [boundaryLinks]="true"
            previousText="&lsaquo;"
            nextText="&rsaquo;"
            firstText="&laquo;"
            lastText="&raquo;"
            (pageChanged)="changePage($event)"
            [(ngModel)]="userSettings.currentPage"
            [(itemsPerPage)]="userSettings.itemsPerPage"
        ></pagination>
    </div>
    <div class="col-md-2 col-sm-2 col-2">
        <div class="items-per-page">
            <select class="form-control" [(ngModel)]="userSettings.itemsPerPage" (change)="changeItemsPerPage($event)">
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
            </select>
        </div>
    </div>
</div>
