import { Action } from '@ngrx/store';

import { IQuotationSettingsStatus } from '../models/personal-preferences.interface';

// Contact details actions
export enum QuotationSettingsActionTypes {
    GET_QUOTATION_SETTINGS = '[QUOTATION_SETTINGS] get settings',
    GET_QUOTATION_SETTINGS_SUCCESS = '[QUOTATION_SETTINGS] get settings succeeded',
    PATCH_QUOTATION_SETTINGS = '[QUOTATION_SETTINGS] patch settings',
    PATCH_QUOTATION_SETTINGS_SUCCESS = '[QUOTATION_SETTINGS] patch settings succeeded',
    PATCH_QUOTATION_TEMPORARY_DEACTIVATION_STATUS = '[QUOTATION_SETTINGS] patch temporary deactivation settings',
    PATCH_QUOTATION_TEMPORARY_DEACTIVATION_STATUS_SUCCESS = '[QUOTATION_SETTINGS] patch temporary deactivation settings succeeded',
    RESET_PATCH_QUOTATION_SETTINGS_STATE = '[QUOTATION_SETTINGS] reset quotation patch settings state',
}

export class GetQuotationSettings implements Action {
    readonly type = QuotationSettingsActionTypes.GET_QUOTATION_SETTINGS;

    constructor(public payload: { locationId: string }) {}
}

export class GetQuotationSettingsSuccess implements Action {
    readonly type = QuotationSettingsActionTypes.GET_QUOTATION_SETTINGS_SUCCESS;

    constructor(public payload: boolean) {}
}

export class PatchQuotationSettings implements Action {
    readonly type = QuotationSettingsActionTypes.PATCH_QUOTATION_SETTINGS;

    constructor(public payload: IQuotationSettingsStatus) {}
}

export class PatchQuotationSettingsSuccess implements Action {
    readonly type = QuotationSettingsActionTypes.PATCH_QUOTATION_SETTINGS_SUCCESS;

    constructor(public payload: boolean) {}
}

export class PatchTemporaryDeactivationStatus implements Action {
    readonly type = QuotationSettingsActionTypes.PATCH_QUOTATION_TEMPORARY_DEACTIVATION_STATUS;

    constructor(
        public payload: {
            locationId: string;
            startDate: string;
            endDate: string;
        },
    ) {}
}

export class PatchTemporaryDeactivationStatusSuccess implements Action {
    readonly type = QuotationSettingsActionTypes.PATCH_QUOTATION_TEMPORARY_DEACTIVATION_STATUS_SUCCESS;

    constructor(public payload: boolean) {}
}

export class ResetPatchQuotationSettingsState implements Action {
    readonly type = QuotationSettingsActionTypes.RESET_PATCH_QUOTATION_SETTINGS_STATE;
}

export type QuotationSettingsActionUnion =
    | GetQuotationSettings
    | GetQuotationSettingsSuccess
    | PatchQuotationSettings
    | PatchQuotationSettingsSuccess
    | PatchTemporaryDeactivationStatus
    | PatchTemporaryDeactivationStatusSuccess
    | ResetPatchQuotationSettingsState;
