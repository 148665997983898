import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

// models
import { UserSettings } from '@solocal-manager/sirius/core/models';
import { Pager } from '@solocal-manager/sirius/core/models';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent {
    PAGINATION_MAX_SIZE = 5;
    userSettings: UserSettings = {
        itemsPerPage: 20,
        currentPage: 1,
    };

    @Input() totalCount: number;

    @Output() pagerChanged: EventEmitter<Pager> = new EventEmitter<Pager>();

    constructor(public logger: NGXLogger) {}

    changePage(event: Pager): void {
        this.logger.debug('<PaginationComponent> changePage -> event: ', event);
        this.pagerChanged.emit(event);
    }

    changeItemsPerPage(event): void {
        this.logger.debug('<PaginationComponent> changeItemsPerPage -> event: ', event.target.value);
        this.pagerChanged.emit({
            page: this.userSettings.currentPage,
            itemsPerPage: this.userSettings.itemsPerPage,
        });
    }
}
