export interface ICountryCode {
    country: string;
    code: string;
}

export const countryCodes: ICountryCode[] = [
    {
        code: '33',
        country: 'FR',
    },
    {
        code: '49',
        country: 'DE',
    },
    {
        code: '32',
        country: 'BE',
    },
    {
        code: '34',
        country: 'ES',
    },
    {
        code: '39',
        country: 'IT',
    },
    {
        code: '377',
        country: 'MC',
    },
    {
        code: '590',
        country: 'GP',
    },
    {
        code: '594',
        country: 'GF',
    },
    {
        code: '262',
        country: 'RE',
    },
    {
        code: '596',
        country: 'MQ',
    },
    {
        code: '262',
        country: 'YT',
    },
    {
        code: '687',
        country: 'NC',
    },
    {
        code: '689',
        country: 'PF',
    },
    // -------------------------------------
    {
        code: '93',
        country: 'AF',
    },
    {
        code: '355',
        country: 'AL',
    },
    {
        code: '213',
        country: 'DZ',
    },
    {
        code: '1684',
        country: 'AS',
    },
    {
        code: '376',
        country: 'AD',
    },
    {
        code: '244',
        country: 'AO',
    },
    {
        code: '1264',
        country: 'AI',
    },
    {
        code: '1268',
        country: 'AG',
    },
    {
        code: '672',
        country: 'AQ',
    },
    {
        code: '54',
        country: 'AR',
    },
    {
        code: '374',
        country: 'AM',
    },
    {
        code: '247',
        country: 'AC',
    },
    {
        code: '297',
        country: 'AW',
    },
    {
        code: '61',
        country: 'AU',
    },
    {
        code: '672',
        country: 'AQ-AU',
    },
    {
        code: '43',
        country: 'AT',
    },
    {
        code: '994',
        country: 'AZ',
    },
    {
        code: '973',
        country: 'BH',
    },
    {
        code: '880',
        country: 'BD',
    },
    {
        code: '1246',
        country: 'BB',
    },
    {
        code: '375',
        country: 'BY',
    },
    {
        code: '501',
        country: 'BZ',
    },
    {
        code: '229',
        country: 'BJ',
    },
    {
        code: '1441',
        country: 'BM',
    },
    {
        code: '975',
        country: 'BT',
    },
    {
        code: '591',
        country: 'BO',
    },
    {
        code: '599',
        country: 'BQ',
    },
    {
        code: '387',
        country: 'BA',
    },
    {
        code: '267',
        country: 'BW',
    },
    {
        code: '47',
        country: 'BV',
    },
    {
        code: '55',
        country: 'BR',
    },
    {
        code: '246',
        country: 'IO',
    },
    {
        code: '1284',
        country: 'VG',
    },
    {
        code: '673',
        country: 'BN',
    },
    {
        code: '359',
        country: 'BG',
    },
    {
        code: '226',
        country: 'BF',
    },
    {
        code: '95',
        country: 'MM',
    },
    {
        code: '257',
        country: 'BI',
    },
    {
        code: '855',
        country: 'KH',
    },
    {
        code: '237',
        country: 'CM',
    },
    {
        code: '1',
        country: 'CA',
    },
    {
        code: '238',
        country: 'CV',
    },
    {
        code: '1345',
        country: 'KY',
    },
    {
        code: '236',
        country: 'CF',
    },
    {
        code: '235',
        country: 'TD',
    },
    {
        code: '56',
        country: 'CL',
    },
    {
        code: '86',
        country: 'CN',
    },
    {
        code: '57',
        country: 'CO',
    },
    {
        code: '269',
        country: 'KM',
    },
    {
        code: '682',
        country: 'CK',
    },
    {
        code: '506',
        country: 'CR',
    },
    {
        code: '225',
        country: 'CI',
    },
    {
        code: '385',
        country: 'HR',
    },
    {
        code: '53',
        country: 'CU',
    },
    {
        code: '599',
        country: 'CW',
    },
    {
        code: '357',
        country: 'CY',
    },
    {
        code: '420',
        country: 'CZ',
    },
    {
        code: '45',
        country: 'DK',
    },
    {
        code: '253',
        country: 'DJ',
    },
    {
        code: '1767',
        country: 'DM',
    },
    {
        code: '1809',
        country: 'DO',
    },
    {
        code: '1829',
        country: 'DO',
    },
    {
        code: '1849',
        country: 'DO',
    },
    {
        code: '593',
        country: 'EC',
    },
    {
        code: '20',
        country: 'EG',
    },
    {
        code: '503',
        country: 'SV',
    },
    {
        code: '240',
        country: 'GQ',
    },
    {
        code: '291',
        country: 'ER',
    },
    {
        code: '372',
        country: 'EE',
    },
    {
        code: '251',
        country: 'ET',
    },
    {
        code: '500',
        country: 'FK',
    },
    {
        code: '298',
        country: 'FO',
    },
    {
        code: '691',
        country: 'FM',
    },
    {
        code: '679',
        country: 'FJ',
    },
    {
        code: '358',
        country: 'FI',
    },
    {
        code: '358',
        country: 'AX',
    },
    {
        code: '241',
        country: 'GA',
    },
    {
        code: '995',
        country: 'GE',
    },
    {
        code: '500',
        country: 'GS',
    },
    {
        code: '233',
        country: 'GH',
    },
    {
        code: '350',
        country: 'GI',
    },
    {
        code: '30',
        country: 'GR',
    },
    {
        code: '299',
        country: 'GL',
    },
    {
        code: '1473',
        country: 'GD',
    },
    {
        code: '1671',
        country: 'GU',
    },
    {
        code: '502',
        country: 'GT',
    },
    {
        code: '44',
        country: 'GG',
    },
    {
        code: '224',
        country: 'GN',
    },
    {
        code: '245',
        country: 'GW',
    },
    {
        code: '592',
        country: 'GY',
    },
    {
        code: '509',
        country: 'HT',
    },
    {
        code: '504',
        country: 'HN',
    },
    {
        code: '852',
        country: 'HK',
    },
    {
        code: '36',
        country: 'HU',
    },
    {
        code: '354',
        country: 'IS',
    },
    {
        code: '44',
        country: 'IM',
    },
    {
        code: '91',
        country: 'IN',
    },
    {
        code: '62',
        country: 'ID',
    },
    {
        code: '98',
        country: 'IR',
    },
    {
        code: '964',
        country: 'IQ',
    },
    {
        code: '353',
        country: 'IE',
    },
    {
        code: '972',
        country: 'IL',
    },

    {
        code: '1876',
        country: 'JM',
    },
    {
        code: '81',
        country: 'JP',
    },
    {
        code: '44',
        country: 'JE',
    },
    {
        code: '962',
        country: 'JO',
    },
    {
        code: '7',
        country: 'KZ',
    },
    {
        code: '254',
        country: 'KE',
    },
    {
        code: '686',
        country: 'KI',
    },
    {
        code: '965',
        country: 'KW',
    },
    {
        code: '996',
        country: 'KG',
    },
    {
        code: '383',
        country: 'XK',
    },
    {
        code: '856',
        country: 'LA',
    },
    {
        code: '371',
        country: 'LV',
    },
    {
        code: '961',
        country: 'LB',
    },
    {
        code: '266',
        country: 'LS',
    },
    {
        code: '231',
        country: 'LR',
    },
    {
        code: '218',
        country: 'LY',
    },
    {
        code: '423',
        country: 'LI',
    },
    {
        code: '370',
        country: 'LT',
    },
    {
        code: '352',
        country: 'LU',
    },
    {
        code: '853',
        country: 'MO',
    },
    {
        code: '389',
        country: 'MK',
    },
    {
        code: '261',
        country: 'MG',
    },
    {
        code: '265',
        country: 'MW',
    },
    {
        code: '60',
        country: 'MY',
    },
    {
        code: '960',
        country: 'MV',
    },
    {
        code: '223',
        country: 'ML',
    },
    {
        code: '356',
        country: 'MT',
    },
    {
        code: '692',
        country: 'MH',
    },
    {
        code: '222',
        country: 'MR',
    },
    {
        code: '230',
        country: 'MU',
    },
    {
        code: '52',
        country: 'MX',
    },
    {
        code: '373',
        country: 'MD',
    },
    {
        code: '976',
        country: 'MN',
    },
    {
        code: '382',
        country: 'ME',
    },
    {
        code: '1664',
        country: 'MS',
    },
    {
        code: '212',
        country: 'MA',
    },
    {
        code: '258',
        country: 'MZ',
    },
    {
        code: '264',
        country: 'NA',
    },
    {
        code: '674',
        country: 'NR',
    },
    {
        code: '977',
        country: 'NP',
    },
    {
        code: '31',
        country: 'NL',
    },
    {
        code: '64',
        country: 'NZ',
    },
    {
        code: '505',
        country: 'NI',
    },
    {
        code: '227',
        country: 'NE',
    },
    {
        code: '234',
        country: 'NG',
    },
    {
        code: '683',
        country: 'NU',
    },
    {
        code: '6723',
        country: 'NF',
    },
    {
        code: '850',
        country: 'KP',
    },
    {
        code: '1670',
        country: 'MP',
    },
    {
        code: '47',
        country: 'NO',
    },
    {
        code: '968',
        country: 'OM',
    },
    {
        code: '92',
        country: 'PK',
    },
    {
        code: '680',
        country: 'PW',
    },
    {
        code: '970',
        country: 'PS',
    },
    {
        code: '507',
        country: 'PA',
    },
    {
        code: '675',
        country: 'PG',
    },
    {
        code: '595',
        country: 'PY',
    },
    {
        code: '51',
        country: 'PE',
    },
    {
        code: '63',
        country: 'PH',
    },
    {
        code: '64',
        country: 'PN',
    },
    {
        code: '48',
        country: 'PL',
    },
    {
        code: '351',
        country: 'PT',
    },
    {
        code: '1787',
        country: 'PR',
    },
    {
        code: '1939',
        country: 'PR',
    },
    {
        code: '974',
        country: 'QA',
    },
    {
        code: '243',
        country: 'CD',
    },
    {
        code: '242',
        country: 'CG',
    },
    {
        code: '40',
        country: 'RO',
    },
    {
        code: '7',
        country: 'RU',
    },
    {
        code: '250',
        country: 'RW',
    },
    {
        code: '212',
        country: 'EH',
    },
    {
        code: '590',
        country: 'BL',
    },
    {
        code: '290',
        country: 'SH',
    },
    {
        code: '1869',
        country: 'KN',
    },
    {
        code: '590',
        country: 'MF',
    },
    {
        code: '508',
        country: 'PM',
    },
    {
        code: '1784',
        country: 'VC',
    },
    {
        code: '685',
        country: 'WS',
    },
    {
        code: '378',
        country: 'SM',
    },
    {
        code: '239',
        country: 'ST',
    },
    {
        code: '966',
        country: 'SA',
    },
    {
        code: '221',
        country: 'SN',
    },
    {
        code: '381',
        country: 'RS',
    },
    {
        code: '248',
        country: 'SC',
    },
    {
        code: '232',
        country: 'SL',
    },
    {
        code: '65',
        country: 'SG',
    },
    {
        code: '1721',
        country: 'SX',
    },
    {
        code: '421',
        country: 'SK',
    },
    {
        code: '386',
        country: 'SI',
    },
    {
        code: '677',
        country: 'SB',
    },
    {
        code: '252',
        country: 'SO',
    },
    {
        code: '27',
        country: 'ZA',
    },
    {
        code: '82',
        country: 'KR',
    },
    {
        code: '211',
        country: 'SS',
    },
    {
        code: '94',
        country: 'LK',
    },
    {
        code: '1758',
        country: 'LC',
    },
    {
        code: '249',
        country: 'SD',
    },
    {
        code: '597',
        country: 'SR',
    },
    {
        code: '47',
        country: 'SJ',
    },
    {
        code: '268',
        country: 'SZ',
    },
    {
        code: '46',
        country: 'SE',
    },
    {
        code: '41',
        country: 'CH',
    },
    {
        code: '963',
        country: 'SY',
    },
    {
        code: '886',
        country: 'TW',
    },
    {
        code: '992',
        country: 'TJ',
    },
    {
        code: '255',
        country: 'TZ',
    },
    {
        code: '66',
        country: 'TH',
    },
    {
        code: '1242',
        country: 'BS',
    },
    {
        code: '220',
        country: 'GM',
    },
    {
        code: '262',
        country: 'TF',
    },
    {
        code: '670',
        country: 'TL',
    },
    {
        code: '228',
        country: 'TG',
    },
    {
        code: '690',
        country: 'TK',
    },
    {
        code: '676',
        country: 'TO',
    },
    {
        code: '1868',
        country: 'TT',
    },
    {
        code: '216',
        country: 'TN',
    },
    {
        code: '90',
        country: 'TR',
    },
    {
        code: '993',
        country: 'TM',
    },
    {
        code: '1649',
        country: 'TC',
    },
    {
        code: '688',
        country: 'TV',
    },
    {
        code: '256',
        country: 'UG',
    },
    {
        code: '380',
        country: 'UA',
    },
    {
        code: '971',
        country: 'AE',
    },
    {
        code: '44',
        country: 'GB',
    },
    {
        code: '1',
        country: 'US',
    },
    {
        code: '598',
        country: 'UY',
    },
    {
        code: '1340',
        country: 'VI',
    },
    {
        code: '998',
        country: 'UZ',
    },
    {
        code: '678',
        country: 'VU',
    },
    {
        code: '379',
        country: 'VA',
    },
    {
        code: '58',
        country: 'VE',
    },
    {
        code: '84',
        country: 'VN',
    },
    {
        code: '681',
        country: 'WF',
    },
    {
        code: '967',
        country: 'YE',
    },
    {
        code: '260',
        country: 'ZM',
    },
    {
        code: '263',
        country: 'ZW',
    },
];
