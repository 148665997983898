export enum EPacks {
    pack0 = 'pack0', // Prospect
    pack1 = 'pack1', // New offer
    pack2 = 'pack2', // New offer
    pack3 = 'pack3', // New offer
    unspecified = '', // deprecated ?
    oldOffer = 'old_offer', // Old offer WITH Presence Management (prolive)
    otherOffer = 'other_offer', // Old offer WITHOUT Presence Management,
    pprOtherOffer = 'ppr_other_offer', // Bridge client with CVI,
    k2sdPremium = 'k2sd_premium',
    k2sdEssential = 'k2sd_essential',
    k2sdPrivilege = 'k2sd_privilege',
    ngGcr = 'ng_gcr',
    k2sdInitial = 'k2sd_initial',
    k2sd_beauty = 'k2sd_beauty',
    ngGcrRanked = 'ng_gcr_ranked',
}
