import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { selectUser, User } from '@slm/user/state';
import { CompaniesActions, GetCurrentCompany, GetLocationList } from '@solocal-manager/sirius/core/core';
import { Company, UserSettings } from '@solocal-manager/sirius/core/models';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'slm-companies-table',
    templateUrl: './companies-table.component.html',
    styleUrls: ['./companies-table.component.scss'],
})
export class CompaniesTableComponent implements OnInit, OnDestroy {
    companies$: Observable<Company[]>;
    applicationId: string;
    userSettings: UserSettings = { itemsPerPage: 20, currentPage: 1 };
    private isDestroyed$ = new Subject<void>();

    constructor(
        public logger: NGXLogger,
        public store: Store<any>,
        private router: Router,
    ) {
        this.companies$ = this.store.pipe(select('companies')); // async pipe in the template unsubscribes automatically
    }

    ngOnInit() {
        this.store.dispatch({
            type: CompaniesActions.GET_COMPANIES,
        });

        this.store
            .select(selectUser)
            .pipe(takeUntil(this.isDestroyed$))
            .subscribe((user: User) => {
                if (user?.application) {
                    this.applicationId = user.application.id;
                }
            });
    }

    loadCompany(company) {
        this.store.dispatch(new GetCurrentCompany(company.id));

        this.store.dispatch(
            new GetLocationList({
                companyId: company.id,
                config: {
                    pageSize: this.userSettings.itemsPerPage,
                    pageNumber: 1,
                },
            }),
        );

        this.router.navigate(['app', this.applicationId, 'company', company.id, 'location']);
    }

    ngOnDestroy() {
        this.isDestroyed$.next(undefined);
        this.isDestroyed$.complete();
    }
}
