<div [formGroup]="formServiceArea">
    <div class="row">
        <div class="col-md-4 col-sm-6 col-6">
            <div class="form-group">
                <label for="selectServiceArea">{{ 'Business Type' | translate }}</label>
                <select
                    class="business-type-select custom-select"
                    name="selectServiceArea"
                    (change)="onBusinessTypeChange()"
                    formControlName="business_type"
                >
                    <option
                        *ngFor="let businessTypeOption of businessTypeOptions"
                        [value]="businessTypeOption.id"
                        [disabled]="businessTypeOption.disabled"
                    >
                        {{ businessTypeOption.name | translate }}
                    </option>
                </select>
            </div>
        </div>
        <div class="col-md-8 col-sm-6 col-6">
            <div class="form-group places" *ngIf="!businessTypeUnspecified">
                <label for="inputPlaces">{{ 'Service area' | translate }}</label>
                <div *ngIf="places?.value.length < maxPlaces">
                    <input
                        type="text"
                        class="form-control"
                        id="inputPlaces"
                        [formControl]="areaControl"
                        [typeahead]="filteredPlaces$"
                        [typeaheadWaitMs]="400"
                        [typeaheadAsync]="true"
                        [typeaheadMinLength]="3"
                        typeaheadOptionField="name"
                        (typeaheadLoading)="changeTypeaheadLoading($event)"
                        (typeaheadOnSelect)="onSelectPlace($event.item)"
                        [placeholder]="'Add cities, zip codes, regions or countries.' | translate"
                    />
                    <div class="places-hint">
                        {{ 'You can add up to x service areas.' | translate: { x: maxPlaces } }}
                    </div>
                </div>
                <div *ngIf="places?.value.length >= maxPlaces" class="places-warning">
                    {{
                        'You have reached the number of service areas allowed by Google. You can not add more than x.'
                            | translate: { x: maxPlaces }
                    }}
                </div>
                <div *ngIf="typeaheadLoading" class="multisearch-loading-icon">
                    <i class="glyphicon glyphicon-refresh"></i>
                </div>
                <div class="places-item" *ngFor="let place of places?.value">
                    <div class="row">
                        <div class="col-md-11 col-sm-10 col-10">
                            <label class="places-label">{{ place.name }}</label>
                        </div>
                        <div class="col-md-1 col-sm-2 col-2 x-btn-holder">
                            <button
                                type="button"
                                class="btn btn-primary remove-field-button pull-right"
                                (click)="removePlace(place)"
                            >
                                X
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="errors places-errors">
                <app-input-error-messages [control]="places"></app-input-error-messages>
            </div>
        </div>
    </div>

    <input type="hidden" formControlName="places" />
</div>
