import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiErrorActions } from '@slm/shared/error';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ActionWithPayload, PublisherStatisticsActions } from '../actions';
import { PublisherStatisticsService } from '../services/publisher-statistics.service';

@Injectable({ providedIn: 'root' })
export class PublisherStatisticsEffects {
    constructor(
        private update$: Actions,
        private publisherStatisticsActions: PublisherStatisticsActions,
        private svc: PublisherStatisticsService,
    ) {}

    publisherStatistics$ = createEffect(() =>
        this.update$.pipe(
            ofType(PublisherStatisticsActions.GET_PUBLISHER_STATISTICS),
            map((action: ActionWithPayload<any>) => action.payload),
            switchMap(payload =>
                this.svc.get(payload).pipe(
                    map(results => this.publisherStatisticsActions.getPublisherStatisticsSuccess(results)),
                    catchError(error => of(new ApiErrorActions.CatchApiError(error))),
                ),
            ),
        ),
    );
}
