import { Directive, HostListener, ElementRef, Input, Output, EventEmitter } from '@angular/core';

@Directive({
    selector: '[appMouseOverAlert]',
})
export class MouseOverAlertDirective {
    @Input() position: number;
    @Output() onOver: EventEmitter<number> = new EventEmitter();

    constructor(private el: ElementRef) {}

    @HostListener('mouseover', ['$event']) onClick($event) {
        $event.stopPropagation();
        this.onOver.emit(this.position);
    }
}
