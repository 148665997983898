import { FeatureActivationStatus } from '@slm/location/state';
import { Location } from '@solocal-manager/sirius/support/base-models';

import { EChatIntegrationStatus } from './chat-integration-status.enum';
import { EChatIntegration } from './chat-integrations.enum';

export interface ChatConfig {
    epj?: string;
    app_id?: string;
    status?: string;
    refresh?: boolean;
    default_pro_user?: {
        app_user_id?: string;
        user_id?: string;
        jwt_token?: string;
    };
    integrations?: IIntegration[];
    paused_from?: string;
    paused_to?: string;
    multiLocEpj?: any[];
}

export interface IIntegration {
    status: string;
    integration_id?: string;
    integration_type: string;
    display_name?: string;
    options?: any;
    oauth_url?: string;
    refresh?: boolean;
    pro?: boolean;
}

export class ChatConfigViewModel {
    readonly active: boolean;
    readonly error: boolean;
    readonly ignored: boolean;
    readonly inactive: boolean;
    readonly oAuthNeeded: boolean;
    readonly paused: boolean;
    readonly pending: boolean;
    readonly activated: boolean;
    readonly hasIntegrationsNotconnected: boolean;
    readonly integrationsNotconnected: Array<string>;
    readonly pagesJaunesIntegration: IIntegration;
    readonly facebookIntegration: IIntegration;
    readonly googleIntegration: IIntegration;
    readonly chatPublisherListAvailable: Array<{
        label: string;
        id: string;
        isActive: boolean;
        chatIntegration: IIntegration;
        oAuthNeeded: boolean;
    }>;
    readonly app_id?: string;
    readonly refresh?: boolean;
    readonly default_pro_user?: {
        app_user_id?: string;
        user_id?: string;
        jwt_token?: string;
    };
    readonly integrations?: IIntegration[];
    readonly paused_from?: string;
    readonly paused_to?: string;

    /**
     * WARNING: Will only work correctly for feature_settings using matching IDs with location.tags
     */
    readonly canBeActivated: boolean;

    constructor(
        public chatConfig: ChatConfig,
        public status: FeatureActivationStatus,
        public location: Location,
    ) {
        this.active = status === FeatureActivationStatus.ACTIVE;
        this.error = status === FeatureActivationStatus.ERROR;
        this.ignored = status === FeatureActivationStatus.IGNORED;
        this.inactive = status === FeatureActivationStatus.INACTIVE;
        this.oAuthNeeded = status === FeatureActivationStatus.OAUTH_NEEDED;
        this.paused = status === FeatureActivationStatus.PAUSED;
        this.pending = status === FeatureActivationStatus.PENDING;
        this.activated = !!status;

        if (this.chatConfig) {
            this.app_id = chatConfig.app_id;
            this.refresh = chatConfig.refresh;
            this.default_pro_user = chatConfig.default_pro_user;
            this.integrations = chatConfig.integrations;
            this.paused_from = chatConfig.paused_from;
            this.paused_to = chatConfig.paused_to;

            this.chatPublisherListAvailable = getChatPublisherList(location, chatConfig);

            this.pagesJaunesIntegration = chatConfig.integrations?.find(
                integration => integration.integration_type === 'pagesjaunes',
            );
            this.facebookIntegration = chatConfig.integrations?.find(
                integration => integration.integration_type === 'messenger',
            );
            this.googleIntegration = chatConfig.integrations?.find(
                integration => integration.integration_type === 'gmb',
            );
            this.integrationsNotconnected = getNotActivatedChatIntegrations(
                this.chatPublisherListAvailable,
                this.chatConfig.integrations,
            );
            this.hasIntegrationsNotconnected =
                !this.chatConfig || (!this.active && !this.paused) || !this.chatConfig.integrations
                    ? true
                    : this.integrationsNotconnected?.length > 0 &&
                      this.integrationsNotconnected?.length === this.chatPublisherListAvailable?.length;
        }
    }
}

export const createChatConfigViewModel = (chatConfig: ChatConfig, location: Location): ChatConfigViewModel => {
    if (chatConfig?.epj && location?.epj) {
        return new ChatConfigViewModel(
            chatConfig,
            FeatureActivationStatus[chatConfig?.status?.toUpperCase()],
            location,
        );
    } else return null;
};

export const getChatPublisherList = (
    location: Location,
    chatConfig: ChatConfig,
): Array<{
    label: string;
    id: string;
    isActive: boolean;
    chatIntegration: IIntegration;
    oAuthNeeded: boolean;
    isPending?: boolean;
}> => {
    const publisherChatList = [];
    const packFeaturesConfig = location.tags || [];

    if (packFeaturesConfig.includes('chat_pj')) {
        const integration = chatConfig?.integrations?.find(int => int.integration_type === 'pagesjaunes');
        publisherChatList.push({
            id: EChatIntegration.PAGESJAUNES,
            label: 'PagesJaunes',
            isActive: integration?.status === FeatureActivationStatus.ACTIVE,
            oAuthNeeded: false,
            chatIntegration: integration,
        });
    }
    if (packFeaturesConfig.includes('chat_facebook')) {
        const integration = chatConfig?.integrations?.find(int => int.integration_type === 'messenger');
        publisherChatList.push({
            id: EChatIntegration.FACEBOOK,
            label: 'Facebook',
            chatIntegration: integration,
            isActive: integration?.status === FeatureActivationStatus.ACTIVE,
            oAuthNeeded: integration?.status === FeatureActivationStatus.OAUTH_NEEDED,
        });
    }
    if (packFeaturesConfig.includes('chat_google')) {
        const integration = chatConfig?.integrations?.find(int => int.integration_type === 'gbm');
        if (integration?.status === FeatureActivationStatus.ACTIVE) {
            publisherChatList.push({
                id: EChatIntegration.GOOGLE,
                label: 'Google (Business Messages)',
                chatIntegration: integration,
                isActive: true,
                oAuthNeeded: false,
                isPending: false,
            });
        }
    }
    return publisherChatList;
};

const getNotActivatedChatIntegrations = (
    publisherChatList: Array<{ label: string; id: string }>,
    chatIntegrations: Array<IIntegration>,
): Array<string> => {
    return publisherChatList
        ?.filter(
            el =>
                !chatIntegrations.find(
                    integration =>
                        integration.integration_type === el.id &&
                        (integration.status === EChatIntegrationStatus.ACTIVE ||
                            integration.status === EChatIntegrationStatus.PAUSED),
                ),
        )
        .map(ob => ob.label);
};
