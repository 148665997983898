import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import { OnBehalfService } from '../services/on-behalf.service';

import { getOnBehalfOptions, getOnBehalfOptionsSuccess, setDisplayOnBehalfMessage } from './on-behalf.actions';

@Injectable({ providedIn: 'root' })
export class OnBehalfEffects {
    getOnBehalfOptions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getOnBehalfOptions),
            tap(console.log),
            switchMap(({ params }) => this.onBehalf.getOnBehalfOptions(params)),
            map(onBehalfOptions => getOnBehalfOptionsSuccess({ onBehalfOptions })),
        ),
    );

    setDisplayOnBehalfMessage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getOnBehalfOptionsSuccess),
            filter(({ onBehalfOptions }) => Object.values(onBehalfOptions).length > 0),
            switchMap(() => this.onBehalf.canWriteOnBehalf()),
            map(canWriteOnBehalf => setDisplayOnBehalfMessage({ displayOnBehalfMessage: !canWriteOnBehalf })),
        ),
    );

    constructor(
        private actions$: Actions,
        private readonly onBehalf: OnBehalfService,
    ) {}
}
