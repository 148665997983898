import { ReferentielActionTypes, ReferentielActionTypesUnion } from '@solocal-manager/sirius/core/actions';
import { IDevelopementLabel } from '@solocal-manager/sirius/core/models';

export type DevelopementLabelsState = IDevelopementLabel[];

export const initialState: DevelopementLabelsState = [];

export function developementLabelsReducer(
    state = initialState,
    action: ReferentielActionTypesUnion,
): DevelopementLabelsState {
    switch (action.type) {
        case ReferentielActionTypes.GET_DEVELOPEMENT_LABELS_SUCCESS: {
            return action.result;
        }
        default:
            return state;
    }
}
