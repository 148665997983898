import { Injectable, Injector } from '@angular/core';
import { Params } from '@angular/router';
import { Store } from '@ngrx/store';
import { LocationsState } from '@slm/location/state';
import { INavigationLogic } from '@solocal-manager/sirius/core/models/navigation/interfaces';

import { NavLogicRouteIds } from '@solocal-manager/sirius/core/models/navigation/nav-logic-route-id.enum';
import { NavigationDescriptor } from '@solocal-manager/sirius/core/models/navigation/navigation-descriptor';
import { selectLocationEntities } from '@solocal-manager/sirius/core/reducers/location-entities.reducer';
import { take } from 'rxjs/operators';

@Injectable()
class OnboardingJourneyCondNavLogic implements INavigationLogic {
    constructor(private store: Store<{ locations: LocationsState }>) {}

    getEffectiveRoute(routeId: string, params?: Params): NavigationDescriptor {
        const navDesc: NavigationDescriptor = {
            route: '',
            queryParams: {},
        };

        this.store
            .select(selectLocationEntities)
            .pipe(take(1))
            .subscribe(decoLocations => {
                if (decoLocations?.length > 1) {
                    navDesc.route = '/locations';
                    navDesc.queryParams = {
                        selectForNext: NavLogicRouteIds.ONBOARDING_JOURNEY,
                    };
                } else {
                    navDesc.route = '/onboarding-journey';
                }
            });

        return navDesc;
    }
}

export function onboardingJourneyCondNavLogicFact(injector: Injector): INavigationLogic {
    const creationInjector = Injector.create({
        providers: [
            {
                provide: OnboardingJourneyCondNavLogic,
                useFactory: (store: Store<{ locations: LocationsState }>) => new OnboardingJourneyCondNavLogic(store),
                deps: [Store],
            },
        ],
        parent: injector,
    });

    return creationInjector.get(OnboardingJourneyCondNavLogic);
}
