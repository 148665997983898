import { Component, forwardRef } from '@angular/core';
import {
    AbstractControl,
    AsyncValidator,
    UntypedFormBuilder,
    NG_ASYNC_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
} from '@angular/forms';
import { of, Observable } from 'rxjs';

import { UrlCheckerService, ValidateUrl, WpmInputUrlComponent } from '@solocal-manager/sirius/core/core';

@Component({
    selector: 'app-input-url',
    templateUrl: './input-url.component.html',
    styleUrls: ['./input-url.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputUrlComponent),
            multi: true,
        },
        {
            provide: NG_ASYNC_VALIDATORS,
            useExisting: forwardRef(() => InputUrlComponent),
            multi: true,
        },
    ],
})
export class InputUrlComponent extends WpmInputUrlComponent implements AsyncValidator {
    initConfig = {
        url: ['', [ValidateUrl.createValidator()]],
        type: ['website', []],
        display_url: [''],
        description: [''],
        primary: [true],
    };
    constructor(protected formBuilder: UntypedFormBuilder, public urlCheckerService: UrlCheckerService) {
        super(formBuilder);
        this._initData = { ...this.initConfig };
    }
    validate(c: AbstractControl): Observable<ValidationErrors> {
        return of(null);
    }
}
