import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
    ControlValueAccessor,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';

import { AddressHelperFacade } from '@solocal-manager/sirius/core/facade/address.helper.facade';
import { ValidateRguAddress } from '@solocal-manager/sirius/core/validators';
import { Address } from '@solocal-manager/sirius/support/base-models';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SubscriptionCloserComponent } from '../../class/subscription-closer-component';
import { Country } from '../../models';
import { IAddressViewModel } from '../../models/iaddress-view-model';
import { AddressValidator, CedexValidator, ValidateAddressLength } from '../validators';

@Component({
    selector: 'slm-wpm-input-address',
    template: '',
})
export class WpmInputAddressComponent
    extends SubscriptionCloserComponent
    implements ControlValueAccessor, OnInit, OnDestroy
{
    isStreetValidatorRemoved = true;
    geocoderResults = [];
    geocoderBusy = false;
    geocoderDone = false;
    formAddress: UntypedFormGroup;
    countries: Country[];
    requiredLabel = {
        address_line1: true,
        address_line2: false,
        city: true,
        zip_code: true,
        country: true,
    };
    addressMinLength = 2;
    location: Location;
    coordinates: any;
    @Input()
    setCoordinates: any;
    @Input()
    locationId: string;

    propagateChange: any = () => {
        return;
    };
    validateFn: any = () => {
        return;
    };

    constructor(
        public formBuilder: UntypedFormBuilder,
        public addressHelperFacade: AddressHelperFacade,
    ) {
        super();
    }

    buildFormControl() {
        const coordinates = this.formBuilder.group({
            longitude: ['', [Validators.pattern(/^-?([0-9]+)(\.([0-9]+))?$/)]],
            latitude: ['', [Validators.pattern(/^-?([0-9]+)(\.([0-9]+))?$/)]],
        });

        this.formAddress = this.formBuilder.group({
            address_line1: [
                '',
                [
                    Validators.required,
                    Validators.minLength(this.addressMinLength),
                    CedexValidator.create(),
                    ValidateAddressLength.createValidator('addressLine1Length', 80),
                ],
            ],
            address_line2: [
                '',
                [CedexValidator.create(), ValidateAddressLength.createValidator('addressLine2Length', 58)],
            ],
            city: ['', [Validators.required, CedexValidator.create()]],
            zip_code: ['', [Validators.required, CedexValidator.create()]],
            sublocality: ['', [CedexValidator.create()]],
            state: ['', [CedexValidator.create()]],
            country: ['', [Validators.required]],
            coordinates,
            insee_code: [''],
        });
    }

    ngOnInit(): void {
        this.buildFormControl();
        this.addressHelperFacade
            .getCountries()
            .pipe(takeUntil(this.destroy$))
            .subscribe((countries: Country[]) => {
                this.countries = countries;
            });

        combineLatest([this.formAddress.valueChanges, this.formAddress.statusChanges])
            .pipe(takeUntil(this.destroy$))
            .subscribe(([controls, validity]) => {
                if (['INVALID', 'VALID'].includes(validity)) {
                    this.propagateChange(controls);
                }
            });
        this.validateFn = AddressValidator.create(this.formAddress);
    }

    getCoordinatesFromAddress() {
        this.geocoderBusy = true;
        this.geocoderDone = false;
        const addressString = this.getAddressStr(this.formAddress.value);
        this.addressHelperFacade
            .getGeoInfo(addressString)
            .pipe(takeUntil(this.destroy$))
            .subscribe(result => {
                this.geocoderResults = result;
                this.geocoderBusy = false;
                this.geocoderDone = true;
            });
    }

    getAddressStr(address: Address): string {
        const addressStr =
            `${!address.house_number ? '' : address.house_number + ' '}` +
            `${address.address_line1}, ${address.zip_code} ${address.city}, ${address.country}`;
        return addressStr.replace(/ +(?= )/g, '');
    }

    setCoordinatesFromAddress(coordinates) {
        if (coordinates.location) {
            const loc = coordinates.location;
            this.formAddress.get('coordinates').setValue({
                longitude: loc.lng,
                latitude: loc.lat,
            });
        }
    }

    getCoordinates($event) {
        this.formAddress.controls.coordinates.setValue({
            latitude: $event.lat,
            longitude: $event.lng,
        });
    }

    writeValue(value): void {
        if (value) {
            this.geocoderResults = [];
            this.geocoderDone = false;
            this.coordinates = value?.coordinates;
            this.formAddress.setValue(this.getViewModel(value));
            if (this.setCoordinates?.coords) {
                this.getCoordinates(this.setCoordinates?.coords);
            }
            if (this.isStreetValidatorRemoved && this.locationId) {
                setTimeout(() => {
                    this.formAddress.setAsyncValidators(
                        ValidateRguAddress.createValidator(this.addressHelperFacade, this.locationId),
                    );
                    this.formAddress.updateValueAndValidity();
                });
            }
        }
    }

    getViewModel(value): IAddressViewModel {
        if (!value) {
            return;
        }
        value.coordinates.latitude = value.coordinates?.latitude || 0;
        value.coordinates.longitude = value.coordinates?.longitude || 0;

        value.insee_code = value.insee_code ? value.insee_code : '';

        if (value.house_number) {
            value.address_line1 = `${value.house_number} ${value.address_line1}`;
        }
        delete value['house_number'];

        return value;
    }

    registerOnChange(fn): void {
        this.propagateChange = fn;
    }

    registerOnTouched(): void {
        return;
    }

    validate(c: UntypedFormControl) {
        return this.validateFn(c);
    }
}
