import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { PathService } from '@solocal-manager/sirius/core/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { IPageStatesKpi } from '../../models';
import { getKpiPageStates } from '../../reducers';
import { KpiTotalErrorFactoryService } from '../../services';
import { TotalErrorKpiViewModel } from '../../view-models';

@Component({
    selector: 'slm-app-kpi-total-error',
    templateUrl: './kpi-total-error.component.html',
    styleUrls: ['./kpi-total-error.component.scss'],
})
export class KpiTotalErrorComponent implements OnInit {
    totalError$: Observable<TotalErrorKpiViewModel>;

    states = ['active', 'pending', 'duplicate', 'error', 'rejected'];

    errorStates = ['error', 'rejected', 'duplicate'];

    constructor(
        private router: Router,
        private store: Store<any>,
        private kpiTotalErrorFactory: KpiTotalErrorFactoryService,
        public pathService: PathService,
    ) {}

    ngOnInit() {
        this.totalError$ = this.store.pipe(
            select(getKpiPageStates),
            map((pageStates: IPageStatesKpi[]) => this.kpiTotalErrorFactory.createTotalErrorKpi(pageStates)),
        );
    }

    getPublishersErrorLink(totalError: TotalErrorKpiViewModel, publisherId: string, state: string): string[] {
        if (this.isPublisherError(totalError, publisherId, state)) {
            return ['/app/2/kpi', 'total-error', publisherId, state];
        }
        return [];
    }

    isPublisherError(totalError: TotalErrorKpiViewModel, publisherId: string, state: string): boolean {
        return this.errorStates.indexOf(state) !== -1 && !!totalError.getStateCountByPublisher(publisherId, state);
    }
}
