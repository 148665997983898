import { Action } from '@ngrx/store';

export enum ApiErrorActionTypes {
    CATCH_API_ERROR = '[CATCH_API_ERROR] catch api error',
    CATCH_API_ERROR_SUCCESS = '[CATCH_API_ERROR_SUCCESS] catch api error success',
    RESET_API_ERROR = '[RESET_API_ERROR] reset api error',
}

export class CatchApiError implements Action {
    readonly type = ApiErrorActionTypes.CATCH_API_ERROR;

    constructor(public payload: any) {}
}

export class CatchApiErrorSuccess implements Action {
    readonly type = ApiErrorActionTypes.CATCH_API_ERROR_SUCCESS;

    constructor(public payload: any) {}
}

export class ResetApiError implements Action {
    readonly type = ApiErrorActionTypes.RESET_API_ERROR;
}

export type ApiErrorActionsUnion = CatchApiError | CatchApiErrorSuccess | ResetApiError;
