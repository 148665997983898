import { Action } from '@ngrx/store';

import { Company } from '@solocal-manager/sirius/core/models';

export enum CurrentCompanyActionTypes {
    GET_CURRENT_COMPANY = '[CURRENT_COMPANY] get currently selected company',
    GET_CURRENT_COMPANY_SUCCESS = '[CURRENT_COMPANY] get currently selected company success',
    RESET_CURRENT_COMPANY = '[CURRENT_COMPANY] reset current company',
}

export class GetCurrentCompany implements Action {
    readonly type = CurrentCompanyActionTypes.GET_CURRENT_COMPANY;

    constructor(public id: string) {}
}

export class GetCurrentCompanySuccess implements Action {
    readonly type = CurrentCompanyActionTypes.GET_CURRENT_COMPANY_SUCCESS;

    constructor(public currentCompnay: Company) {}
}

export class ResetCurrentCompany implements Action {
    readonly type = CurrentCompanyActionTypes.RESET_CURRENT_COMPANY;

    constructor() {}
}

export type CurrentCompanyActionsUnion = GetCurrentCompany | GetCurrentCompanySuccess | ResetCurrentCompany;
