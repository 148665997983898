import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function timeStepValidator(stepSize = 5): ValidatorFn {
    return (control: AbstractControl<string>): ValidationErrors | null => {
        if (!control.value) {
            return null;
        }

        const time = timeStringToMinutes(control.value);
        if (time === -1 || time % stepSize === 0) {
            return null;
        }

        return { timeStep: true };
    };
}

function timeStringToMinutes(timestring: string): number {
    if (!timestring) {
        return -1;
    }
    const timeParts = timestring.split(':');
    if (timeParts.length === 2) {
        return Number(timeParts[0]) * 60 + Number(timeParts[1]);
    }
    return -1;
}
