import { createFeatureSelector } from '@ngrx/store';

import { ApiErrorActions } from '@slm/shared/error';

export type ApiErrorState = any;

export const initialState: ApiErrorState = {};

export function apiErrorReducer(state = initialState, action: ApiErrorActions.ApiErrorActionsUnion): ApiErrorState {
    switch (action.type) {
        case ApiErrorActions.ApiErrorActionTypes.CATCH_API_ERROR_SUCCESS: {
            return action.payload;
        }

        case ApiErrorActions.ApiErrorActionTypes.RESET_API_ERROR: {
            return { ...initialState };
        }

        default:
            return state;
    }
}

export const selectApiError = createFeatureSelector<ApiErrorState>('apiError');
