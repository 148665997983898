import { Injectable } from '@angular/core';

import { AppConfig } from '@solocal-manager/shared/util-common';

import { APP_VERSION } from './app-version';

const defaultAppConfig = {
    locationEdit: { path: 'edit', tab: 0 },
    publisherStatus: { path: 'publisher-status', tab: 1 },
    production: { path: 'production', tab: 2 },
};

const leadformanceAppCOnfig = {
    locationEdit: { path: 'edit', tab: 0 },
    publisherStatus: { path: 'publisher-status', tab: 1 },
};

const features = {
    leadformance: {
        location: {
            edit: false,
        },
        production: {
            show: false,
        },
    },
    default: {
        location: {
            edit: true,
        },
        production: {
            show: true,
        },
    },
};

const appConfig = {
    prod: {
        3: {
            tools: leadformanceAppCOnfig,
            features: features.leadformance,
        },
        default: {
            tools: defaultAppConfig,
            features: features.default,
        },
    },
    default: {
        3: {
            tools: leadformanceAppCOnfig,
            features: features.leadformance,
        },
        2: {
            tools: defaultAppConfig,
            features: features.default,
        },
        default: {
            tools: defaultAppConfig,
            features: features.default,
        },
    },
};

@Injectable({ providedIn: 'root' })
export class BackofficeAppConfig implements AppConfig {
    version = APP_VERSION;
    prod = {
        3: {
            tools: leadformanceAppCOnfig,
            features: features.leadformance,
        },
        default: {
            tools: defaultAppConfig,
            features: features.default,
        },
    };
    default = {
        3: {
            tools: leadformanceAppCOnfig,
            features: features.leadformance,
        },
        2: {
            tools: defaultAppConfig,
            features: features.default,
        },
        default: {
            tools: defaultAppConfig,
            features: features.default,
        },
    };
}
