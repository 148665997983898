import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';

import { GetImageList, ResetImageList } from '@solocal-manager/sirius/core/actions';
import { getGalleryImages } from '@solocal-manager/sirius/core/reducers';
import { PaginationDTO } from '@solocal-manager/sirius/support/base-models';
import { Photo } from '@solocal-manager/sirius/support/base-models';

import { ImageService } from '../../services';

interface IImageAction {
    images: Photo[];
    action: string;
}

@Component({
    selector: 'wpm-image-list',
    template: '',
})
export class WpmImageListComponent implements OnInit, OnChanges, OnDestroy {
    fetching = false;
    imageListSubscribtion;
    imageList$: Observable<PaginationDTO<Photo>>;
    imageList: any[] = [];
    pageNumber = 1;
    allImagesFetched = false;

    @Input() selectedImages: Photo[];
    alreadySelected: Photo;

    @Input() actions: string[];
    @Input() locationId: string;
    @Input() imagesPerPage: number;
    @Input() imageType: string;
    @Input() reset: boolean;
    @Input() minimal: boolean;
    @Input() multipleValue: boolean;

    @Output()
    imageSelected: EventEmitter<IImageAction | Photo> = new EventEmitter<IImageAction>();

    @Output()
    selectedImagesChanged: EventEmitter<Photo[]> = new EventEmitter<Photo[]>();

    scrollCallback = () => {
        return new Observable((observer: any) => {
            if (!this.allImagesFetched) {
                this.pageNumber++;
                this.getImageList(1, this.pageNumber * this.imagesPerPage);
            }
            observer.next();
            observer.complete();
        });
    };

    constructor(public logger: NGXLogger, public store: Store<any>, public imageService: ImageService) {}

    ngOnInit(): void {
        this.imageList$ = this.store.pipe(select(getGalleryImages));
        this.imageListSubscribtion = this.imageList$.subscribe(imageList => {
            if (imageList) {
                this.imageList = imageList.data;
                this.allImagesFetched = !imageList.next_page;
                this.fetching = false;
            }
        });
    }

    ngOnChanges(changes): void {
        if (changes.reset && changes.reset.currentValue === true) {
            this.fetching = false;
            this.pageNumber = 1;
            this.imageList = [];
        } else {
            this.getImageList();
        }
    }

    ngOnDestroy(): void {
        this.imageListSubscribtion.unsubscribe();
        this.store.dispatch(new ResetImageList());
    }

    onSingleImageSelect(image: Photo) {
        this.imageService.clearImageErrors();
        if (
            this.imageService.validateImage(this.imageType, {
                name: image.title,
                formatType: image.photo,
                height: image.height,
                width: image.height,
                size: image?.file_size,
            })
        ) {
            this.imageSelected.emit(image);
        }
    }

    onImageSelect(image: Photo, action: string): void {
        const selectedImage = this.getSelectedImage(image.id);
        if (selectedImage) {
            const imageIndex = this.selectedImages.indexOf(selectedImage);
            this.selectedImages.splice(imageIndex, 1);
        } else {
            this.selectedImages.push(image);
            this.imageSelected.emit({ images: this.selectedImages, action });
        }
        this.selectedImagesChanged.emit(this.selectedImages);
    }

    isSelectedImage(imageId) {
        this.alreadySelected = this.selectedImages.filter(img => img.id === imageId)[0];

        if (this.alreadySelected) {
            return true;
        } else {
            return false;
        }
    }

    indexOfImage(imageId: string): number {
        const image = this.selectedImages.find(selectedImage => selectedImage.id === imageId);
        return image ? this.selectedImages.indexOf(image) + 1 : 0;
    }

    getSelectedImage(imageId): Photo {
        return this.selectedImages.find(image => image.id === imageId);
    }

    getImageList(pageNumber?: number, pageSize?: number): void {
        const payload = {
            companyId: 'all',
            pageSize: pageSize || this.imagesPerPage,
            pageNumber: pageNumber || this.pageNumber,
            imageType: this.imageType,
        };
        if (this.locationId) {
            payload['locationIds'] = [this.locationId];
        }
        setTimeout(() => {
            this.fetching = true;
            this.store.dispatch(new GetImageList(payload));
        });
    }

    deselectImages() {
        this.selectedImages = [];
        this.selectedImagesChanged.emit(this.selectedImages);
    }
}
