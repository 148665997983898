import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LocationsActions, LocationsSelectors } from '@slm/location/state';
import { HOST_APP, HostApp } from '@slm/shared/environment';
import { GetLocationList } from '@solocal-manager/sirius/core/actions';

import { getConversations } from '@solocal-manager/sirius/data-access-chat/reducers';
import { DialogService } from '@solocal-manager/sirius/shared/dialog';
import { Location } from '@solocal-manager/sirius/support/base-models';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import {
    ChatActionTypes,
    GetChatConfigError,
    GetChatConfigSuccess,
    GetChatConversations,
    GetChatConversationsError,
    GetChatConversationsSuccess,
    GetGbmWizard,
    GetGbmWizardError,
    GetGbmWizardSuccess,
    GetMultiLocationChatInfosError,
    GetMultiLocationChatInfosSuccess,
    GetMultiLocationChatSuccess,
    PostGbmWizard,
    PostGbmWizardError,
    PostGbmWizardSuccess,
    PutChatConfigError,
    PutChatConfigSuccess,
    ResetChatConversations,
} from '../actions';
import { ChatConfig, ChatParams, GbmWizard, IChatLocationList, IPaginationConversation } from '../models';
import { ChatService } from '../services';

@Injectable({ providedIn: 'root' })
export class ChatEffects {
    getChatConfig$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChatActionTypes.GET_CHAT_CONFIG),
            map((action: any) => action.params),
            switchMap((params: ChatParams) => {
                if (!params) {
                    return;
                }

                return this.chatService.getChatConfig(params.epj).pipe(
                    map((value: ChatConfig) => GetChatConfigSuccess({ payload: value })),
                    catchError(error => {
                        return of(GetChatConfigError(error));
                    }),
                );
            }),
        ),
    );
    putChatConfig$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChatActionTypes.PUT_CHAT_CONFIG),
            map((action: any) => action.params),
            switchMap((params: ChatParams) => {
                if (!params) {
                    return;
                }

                return this.chatService.putChatConfig(params.epj, params.chatConfig).pipe(
                    map((value: ChatConfig) =>
                        PutChatConfigSuccess({
                            payload: value,
                        }),
                    ),
                    catchError(error => {
                        return of(PutChatConfigError(error));
                    }),
                );
            }),
        ),
    );
    putChatConfSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ChatActionTypes.PUT_CHAT_CONFIG_SUCCESS),
                map((action: any) => action.payload),
                map(action => action),
                tap((action: any) => {
                    this.store.dispatch(
                        new GetLocationList({
                            companyId: 'all',
                            config: {
                                fetchAll: true,
                                shouldReturnMeta: true,
                                pageSize: 100,
                                pageNumber: 1,
                            },
                        }),
                    );
                }),
            ),
        { dispatch: false },
    );
    getChatConversations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetChatConversations, ResetChatConversations),
            concatLatestFrom(() => this.store.select(getConversations)),
            switchMap(([{ params }, state]) => {
                if (!params) {
                    return;
                }

                return this.chatService.getChatConversations(params.epj, params.page).pipe(
                    map((value: IPaginationConversation) => {
                        if (state && value.previous !== null) {
                            value.data = state.data.concat(value.data);
                        }
                        return GetChatConversationsSuccess({
                            payload: value,
                        });
                    }),
                    catchError(error => {
                        return of(GetChatConversationsError(error));
                    }),
                );
            }),
        ),
    );
    getMultiLocationChatInfos$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChatActionTypes.GET_MULTI_LOCATION_CHAT_INFOS),
            map((action: any) => action.params),
            switchMap((params: ChatParams) => {
                if (!params) {
                    return;
                }
                return this.chatService.getMultiLocationChatInfos(params.page).pipe(
                    map((value: IChatLocationList) =>
                        GetMultiLocationChatInfosSuccess({
                            payload: value,
                        }),
                    ),
                    catchError(error => {
                        return of(GetMultiLocationChatInfosError(error));
                    }),
                );
            }),
        ),
    );
    putMultiLocationChatActivation = createEffect(() =>
        this.actions$.pipe(
            ofType(ChatActionTypes.PUT_MULTI_CHAT_LOCATIONS_ACTIVATION),
            map((action: any) => action.payload),
            switchMap(payload => {
                if (!payload) {
                    return;
                }

                return this.chatService.putMultiLocationChatActvation(payload).pipe(
                    map((value: []) =>
                        GetMultiLocationChatSuccess({
                            payload: value,
                        }),
                    ),
                    catchError(error => {
                        return of(GetMultiLocationChatInfosError(error));
                    }),
                );
            }),
        ),
    );
    postGbmWizard$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PostGbmWizard),
            map(action => action.gbmActivationWizardFormData),
            concatLatestFrom(() => this.store.select(LocationsSelectors.getSelectedLocation)),
            filter(
                ([gbmActivationWizardFormData, selectedLocation]) =>
                    !!gbmActivationWizardFormData && !!selectedLocation,
            ),
            mergeMap(([gbmActivationWizardFormData, selectedLocation]: [GbmWizard, Location]) => {
                return this.chatService.postGbmWizard(selectedLocation.epj, gbmActivationWizardFormData).pipe(
                    map((value: any) => {
                        this.dialogService.openSimpleDialog(
                            'im_gbm_dialog:success_title',
                            'im_gbm_dialog:update_soon_available',
                        );
                        return PostGbmWizardSuccess({
                            payload: value,
                        });
                    }),
                    catchError(error => {
                        this.dialogService.openSimpleDialog(
                            'im_gbm_dialog:error_title',
                            'im_gbm_dialog:error_description',
                        );
                        return of(PostGbmWizardError(error));
                    }),
                );
            }),
        ),
    );
    getGbmWizard$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetGbmWizard),
            map(action => action.integrationType),
            concatLatestFrom(() => this.store.select(LocationsSelectors.getSelectedLocation)),
            mergeMap(([integrationType, selectedLocation]: [string, Location]) => {
                return this.chatService.getGbmWizard(selectedLocation.epj, integrationType).pipe(
                    map((value: any) =>
                        GetGbmWizardSuccess({
                            payload: value,
                        }),
                    ),
                    catchError(error => {
                        return of(GetGbmWizardError(error));
                    }),
                );
            }),
        ),
    );
    initChatConversations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LocationsActions.setSelectedLocation),
            concatLatestFrom(() => this.store.select(LocationsSelectors.getSelectedLocation)),
            map(([, location]) => location),
            filter(() => this.hostApp.isSlmMobile),
            map(location =>
                GetChatConversations({
                    params: {
                        epj: location.epj,
                    },
                }),
            ),
        ),
    );

    constructor(
        @Inject(HOST_APP) private readonly hostApp: HostApp,
        private actions$: Actions,
        private chatService: ChatService,
        private store: Store,
        private dialogService: DialogService,
    ) {}
}
