import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { kebabCase } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PathService } from '@solocal-manager/sirius/core/core';

import { IProvisioningIssuesKpi, IWeekInfo, IYearInfo } from '../../models';
import { getKpiProvisioningIssues } from '../../reducers';
import { KpiUtilsService } from '../../services';

@Component({
    selector: 'app-kpi-not-successful-provisionings',
    templateUrl: './kpi-not-successful-provisionings.component.html',
    styleUrls: ['./kpi-not-successful-provisionings.component.scss'],
})
export class KpiNotSuccessfulProvisioningsComponent implements OnInit {
    provIssues$: Observable<IProvisioningIssuesKpi>;

    constructor(private store: Store<any>, private pathService: PathService, private kpiUtils: KpiUtilsService) {}

    ngOnInit() {
        this.provIssues$ = this.store.pipe(
            select(getKpiProvisioningIssues),
            map((issues: IProvisioningIssuesKpi) => {
                if (issues && issues.data) {
                    issues.data.forEach((yearInfo: IYearInfo) => {
                        yearInfo.weeks.sort((a, b) => (a.week > b.week ? -1 : 1));
                    });
                    issues.data.sort((a, b) => (a.year > b.year ? -1 : 1));
                }
                return issues;
            }),
        );
    }

    isFuture(year: IYearInfo, week: IWeekInfo) {
        return this.kpiUtils.isFutureInfo(year, week);
    }

    getDetailsLink(errorType: string, year: string, week: string, errorValue: number): string[] {
        if (errorValue) {
            return [
                `${this.pathService.basePath}/kpi`,
                'not-successful-provisionings',
                kebabCase(errorType),
                year,
                week,
            ];
        }
        return [];
    }
}
