import { Directive, ViewContainerRef, Input } from '@angular/core';

@Directive({
    selector: '[removeFromDom]',
})
export class RemoveFromDom {
    constructor(private viewContainer: ViewContainerRef) {}

    @Input() set removeFromDom(timeout: number) {
        setTimeout(() => {
            this.viewContainer.element.nativeElement.remove();
        }, timeout);
    }
}
