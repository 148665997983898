import { NgModule } from '@angular/core';

import { SafeHtmlPipe, SafeUrlPipe } from '@slm/shared/html';

import { PhoneNumberPipe } from './phone-number.pipe';
import {
    AddressPipe,
    AppointmentTitlePipe,
    CapitalizePipe,
    CustomDateFormatWithTranslationPipe,
    DateFormatWithTranslationPipe,
    DateFromNowPipe,
    DurationRdvFormatPipe,
    FormatDurationPipe,
    FrenchDecimalPipe,
    HourRdvFormatPipe,
    ImageErrorMessageTranslation,
    ImageSizerPipe,
    IsSelected,
    KeyValuePipe,
    NumberToFixedPipe,
    PhoneFormatPipe,
    PublisherStateIconPipe,
    SeparatorPipe,
    ShortNamePipe,
    ShortNumberPipe,
    SorterPipe,
    UrlifyPipe,
    WebsiteUrlPipe,
} from '.';

@NgModule({
    declarations: [
        SeparatorPipe,
        ShortNumberPipe,
        SorterPipe,
        UrlifyPipe,
        ImageSizerPipe,
        HourRdvFormatPipe,
        PublisherStateIconPipe,
        CapitalizePipe,
        FrenchDecimalPipe,
        KeyValuePipe,
        NumberToFixedPipe,
        WebsiteUrlPipe,
        PhoneFormatPipe,
        DurationRdvFormatPipe,
        DateFromNowPipe,
        ShortNamePipe,
        IsSelected,
        DateFormatWithTranslationPipe,
        CustomDateFormatWithTranslationPipe,
        FormatDurationPipe,
        AppointmentTitlePipe,
        ImageErrorMessageTranslation,
        PhoneNumberPipe,
        AddressPipe,
    ],
    imports: [SafeHtmlPipe, SafeUrlPipe],
    exports: [
        SeparatorPipe,
        ShortNumberPipe,
        SorterPipe,
        UrlifyPipe,
        ImageSizerPipe,
        HourRdvFormatPipe,
        PublisherStateIconPipe,
        CapitalizePipe,
        FrenchDecimalPipe,
        KeyValuePipe,
        NumberToFixedPipe,
        WebsiteUrlPipe,
        PhoneFormatPipe,
        DurationRdvFormatPipe,
        DateFromNowPipe,
        ShortNamePipe,
        IsSelected,
        DateFormatWithTranslationPipe,
        CustomDateFormatWithTranslationPipe,
        ImageErrorMessageTranslation,
        FormatDurationPipe,
        AppointmentTitlePipe,
        AppointmentTitlePipe,
        PhoneNumberPipe,
        SafeHtmlPipe,
        SafeUrlPipe,
        AddressPipe,
    ],
    providers: [],
})
export class WpmPipesModule {}
