import { ActionWithPayload, EpjSearchActions } from '../actions';

export type EpjSearchState = any[];

const initialState: EpjSearchState = [];

export function epjSearchReducer(state = initialState, action: ActionWithPayload<any>): EpjSearchState {
    switch (action.type) {
        case EpjSearchActions.GET_EPJ_SEARCH_SUCCESS: {
            return action.payload;
        }
        default:
            return state;
    }
}
