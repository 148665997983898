import { Component, Input, Output, EventEmitter } from '@angular/core';

import { WpmImageListComponent } from '@solocal-manager/sirius/core/core';

@Component({
    selector: 'app-image-list',
    templateUrl: './image-list.component.html',
    styleUrls: ['./image-list.component.scss'],
})
export class ImageListComponent extends WpmImageListComponent {
    loadMoreImages() {
        this.pageNumber++;
        this.getImageList(1, this.pageNumber * this.imagesPerPage);
    }
}
