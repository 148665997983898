<div class="container">
    <table class="table table-hover">
        <thead>
            <tr class="col-md-12 col-sm-12 col-12">
                <th class="col-md-9 col-sm-9 col-9" scope="col">
                    {{ 'ID/Name' | translate }}
                </th>
                <th class="col-md-3 col-sm-3 col-3" scope="col">
                    {{ 'Locations' | translate }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr
                class="col-md-12 col-sm-12 col-12"
                *ngFor="let company of companies$ | async"
                (click)="loadCompany(company)"
            >
                <td class="col-md-9 col-sm-9 col-9 text-align-left">{{ company?.id }} / {{ company?.name }}</td>
                <td class="col-md-3 col-sm-3 col-3">
                    {{ company?.location_count }}
                </td>
            </tr>
        </tbody>
    </table>
</div>
