import * as fromRouter from '@ngrx/router-store';
import { getRouterSelectors, RouterReducerState } from '@ngrx/router-store';
import {
    ActionReducer,
    ActionReducerMap,
    createFeatureSelector,
    createSelector,
    MetaReducer,
    select,
} from '@ngrx/store';
import { getPagesJaunesActivities, LocationsSelectors } from '@slm/location/state';
import { selectUser, User } from '@slm/user/state';
import { LocationListActionTypes } from '@solocal-manager/sirius/core/actions';

import {
    Company,
    EGFBTypes,
    EPlatform,
    ICurrentGeneric,
    IDidomiState,
    IUserAndLocationIds,
    IvarsLengthState,
} from '@solocal-manager/sirius/core/models';
import { AppNavPageState, getNavPageReducer } from '@solocal-manager/sirius/core/reducers/nav-page.reducer';
import {
    IHabilitatedLocation,
    Location,
    SearchHabLocationListState,
} from '@solocal-manager/sirius/support/base-models';
import { get } from 'lodash-es';
import { pipe } from 'rxjs';
import { map, share } from 'rxjs/operators';

import { IGFBMap, IGFBState } from '../models/get-feed-back';
import { PublisherStatusState } from '../models/state/publishers-status.model';
import { galleryReducer, InitialGalleryState } from '../reducers/gallery.reducer';
import { RouterStateUrl } from '../utils/router-utils';

import { apiErrorReducer, ApiErrorState } from './api-error.reducer';
import { availablePublisherReducer, AvailablePublisherState } from './available-publisher.reducer';
import { categoryBrandsReducer, CategoryBrandsState } from './category-brands.reducer';
import { chatbotInfoReducer, chatbotInfoState } from './chatbot-info.reducer';
import { ciamContactReducer, ICiamContactState } from './ciam-contacts.reducer';
import { companiesReducer, CompaniesState } from './companies.reducer';
import { contactsReducer, IContactsState } from './contacts.reducer';
import { currentCompanyReducer, CurrentCompanyState } from './current-company.reducer';
import { customerReducer, CustomerState } from './customer.reducer';
import { developementLabelsReducer, DevelopementLabelsState } from './developement-labels.reducer';
import { didomiReducer } from './didomi.reducer';
import { epjSearchReducer, EpjSearchState } from './epj-search.reducer';
import { getFeedBackReducer } from './gfb.reducers';
import { habilitatedLocationListReducer, HabilitatedLocationListState } from './habilitated-location-list.reducer';
import { habLocationReducer } from './habilitated-location.reducer';
import {
    multiSearchReducer,
    multiSearchSelectedReducer,
    MultiSearchSelectedState,
    MultiSearchState,
} from './multi-search.reducer';
import { pageListReducer, PageListState } from './page-list.reducer';
import { pagesListReducer } from './pages-list.reducer';
import { pagesReducer, PagesState } from './pages.reducer';
import { paymentMethodsReducer, PaymentMethodsState } from './payment-methods.reducer';
import { publisherStatisticsReducer, PublisherStatisticsState } from './publisher-statistics.reducer';
import { publisherStatusReducer } from './publishers-status.reducer';
import { routeAppReducer, RouteAppState } from './route-app.reducer';
import { routePathReducer, RoutePathState } from './route-path.reducer';
import { searchHabilitatedLocationsReducer } from './search-hab-location-list.reducer';
import { varsLengthReducer } from './vars-length.reducer';

export * from './ciam-contacts.selectors';
export * from './location-entities.reducer';
export * from './nav-config.selectors';

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
    return function (state, action) {
        return reducer(state, action);
    };
}

export function logout(reducer: ActionReducer<any>): ActionReducer<any> {
    return function (state, action) {
        if (action.type === '[USER] logout') {
            return reducer(undefined, action);
        }

        return reducer(state, action);
    };
}

/*
    this is a FE hack because is waiting for the habilitation API to be done.
    for intern users (readonly) the locationList needs to be reduced.
    pack settings in user needs to be fixed
    reviews needs to be limited
    posts needs to be limited
    statistics needs to be checked! i think this already works.
    widgets on home screen needs to be checked
    just one single location is allowed origin_location_id?
*/
export function limitForReadonly(reducer: ActionReducer<any>): ActionReducer<any> {
    return function (state, action) {
        if (action.type === LocationListActionTypes.GET_LOCATION_LIST_SUCCESS) {
            const locationList: Location[] = action['result'].data as Location[];

            const user: User = state.user;

            if (user.readonly) {
                const limitedLocation = locationList.find(
                    (location: Location) => location.partner_id === user.origin_location_id,
                );

                if (limitedLocation) {
                    action['result'] = {
                        count: 1,
                        currentPage: 1,
                        data: [limitedLocation],
                        next: null,
                        next_page: null,
                        previous: null,
                        previous_page: null,
                    };
                }
            }
        }

        return reducer(state, action);
    };
}

export const metaReducers: Array<MetaReducer<any>> = [debug, logout, limitForReadonly];

export interface IAppState {
    currentCompany: CurrentCompanyState;
    gallery: InitialGalleryState;
    habilitatedLocationList: HabilitatedLocationListState;
    habilitatedLocation: IHabilitatedLocation;
    searchHabilitatedLocations: SearchHabLocationListState;
    pageList: PageListState;
    pagesList: PageListState;
    availablePublishers: AvailablePublisherState;
    publisherStatus: PublisherStatusState;
    routePath: RoutePathState;
    routeApp: RouteAppState;
    contacts: IContactsState;
    ciamContact: ICiamContactState;

    developementLabels: DevelopementLabelsState;
    categoryBrands: CategoryBrandsState;
    chatbotInfo: chatbotInfoState;
    getFeedBack: IGFBState;
    mktVarsLength: IvarsLengthState;
    didomi: IDidomiState;
}

export const libReducers: ActionReducerMap<IAppState> = {
    currentCompany: currentCompanyReducer,
    gallery: galleryReducer,
    habilitatedLocationList: habilitatedLocationListReducer, // only for location header selector
    habilitatedLocation: habLocationReducer, // only for location header selector
    searchHabilitatedLocations: searchHabilitatedLocationsReducer, // only for profile/location page
    pageList: pageListReducer,
    availablePublishers: availablePublisherReducer,
    publisherStatus: publisherStatusReducer,
    routePath: routePathReducer,
    routeApp: routeAppReducer,
    contacts: contactsReducer,
    ciamContact: ciamContactReducer,
    developementLabels: developementLabelsReducer,
    categoryBrands: categoryBrandsReducer,
    chatbotInfo: chatbotInfoReducer,
    getFeedBack: getFeedBackReducer,
    pagesList: pagesListReducer,
    mktVarsLength: varsLengthReducer,
    didomi: didomiReducer,
};

export interface AppState {
    router: fromRouter.RouterReducerState<RouterStateUrl>;
    epjSearch: EpjSearchState;
    paymentMethods: PaymentMethodsState;
    companies: CompaniesState;
    multiSearch: MultiSearchState;
    multiSearchSelected: MultiSearchSelectedState;
    currentCustomer: CustomerState;
    publisherStatistics: PublisherStatisticsState;
    page: PagesState;
    apiError: ApiErrorState;
    navPageConf: AppNavPageState;
}

export const reducers: ActionReducerMap<AppState> = {
    router: fromRouter.routerReducer,
    navPageConf: getNavPageReducer,
    epjSearch: epjSearchReducer,
    paymentMethods: paymentMethodsReducer,
    companies: companiesReducer,
    multiSearch: multiSearchReducer,
    multiSearchSelected: multiSearchSelectedReducer,
    currentCustomer: customerReducer,
    publisherStatistics: publisherStatisticsReducer,
    page: pagesReducer,
    apiError: apiErrorReducer,
};

export const selectRoutePath = createFeatureSelector<RoutePathState>('routePath');

export const selectLocation = createFeatureSelector<Location>('location');

export const selectPaymentMethods = createFeatureSelector<PaymentMethodsState>('paymentMethods');

export const selectApiError = createFeatureSelector<ApiErrorState>('apiError');
const apiErrorState = (state: AppState) => state.apiError;

export const appiErrorSelector = createSelector(apiErrorState, state => state);

export const selectLocationList = LocationsSelectors.getLocationsAndState;

export const selectRouter = createFeatureSelector<RouterReducerState>('router');
export const {
    selectCurrentRoute, // select the current route
    selectFragment, // select the current route fragment
    selectQueryParams, // select the current route query params
    selectQueryParam, // factory function to select a query param
    selectRouteParams, // select the current route params
    selectRouteParam, // factory function to select a route param
    selectRouteData, // select the current route data
    selectUrl, // select the current url
} = getRouterSelectors(selectRouter);

export const selectLocationsOp = pipe(
    select(selectLocationList),
    map(locationList => locationList.locations),
    share(),
);

export const isSingleLocationOp = pipe(
    selectLocationsOp,
    map(locations => locations?.length === 1 ?? false),
    share(),
);

export const getLocationListCount = createSelector(selectLocationList, locationList => {
    return locationList?.count;
});

export const getHasLocations = createSelector(selectLocationList, locationList => locationList.count > 0);

export const selectCurrentCompany = createFeatureSelector<Company>('currentCompany');

export const selectRouteApp = createFeatureSelector<RouteAppState>('routeApp');

export const getGalleryState = createFeatureSelector<InitialGalleryState>('gallery');

export const selectLocationEpj = createSelector(
    LocationsSelectors.getSelectedLocation,
    (state: Location) => state?.epj,
);

export const selectLocationRoles = createSelector(
    LocationsSelectors.getSelectedLocation,
    (state: Location) => state?.roles,
);

export const selectLocationPacks = createSelector(
    LocationsSelectors.getSelectedLocationForSingleSelect,
    (location: Location) => location?.custom_data?.pack?.id,
);

export const getLocationActivities = createSelector(
    LocationsSelectors.getAllLocations,
    locations => locations?.flatMap(location => getPagesJaunesActivities(location).map(cat => cat.name)),
);

export const getGalleryImages = createSelector(getGalleryState, (state: InitialGalleryState) => state.images);

export const getGalleryVideos = createSelector(getGalleryState, (state: InitialGalleryState) => state.videos);

// only for location header selector
export const selectHabilitatedLocationList =
    createFeatureSelector<HabilitatedLocationListState>('habilitatedLocationList');

// only for location header selector
// habilitatedLocation.epj === location.partner_id
export const selectHabilitatedLocation = createFeatureSelector<IHabilitatedLocation>('habilitatedLocation');
export const selectHabilitatedLocationSuccess = createSelector(
    selectHabilitatedLocation,
    (state: any) => state.success,
);

export const selectExplicitAuthorization = createSelector(
    selectHabilitatedLocation,
    (state: any) => state?.explicitAuthorization,
);

export const getSearchHabilitatedLocations =
    createFeatureSelector<SearchHabLocationListState>('searchHabilitatedLocations');
export const selectFilterLocationList = createSelector(
    selectLocationList,
    getSearchHabilitatedLocations,

    (location, habLocation) =>
        location.locations.map(l => ({
            ...l,
            canDoBulk: AuthorizedPacks.has(l.custom_data?.pack?.id),
            checked: false,
            habLocationStatus: getHabLocationStatus(l.roles),
            explicitAuthorization: getExplicitAuthorization(l.epj, habLocation.data),
        })),
);

export const getPageList = createFeatureSelector<PageListState>('pageList');
export const getDidomi = createFeatureSelector<IDidomiState>('didomi');

export const getDidomiStatus = createSelector(getDidomi, state => state.isLoaded);

export const selectAvailablePublishers = createFeatureSelector<AvailablePublisherState>('availablePublishers');

export const selectPublisherStatus = createFeatureSelector<PublisherStatusState>('publisherStatus');

export const getVarsLength = createFeatureSelector<IvarsLengthState>('mktVarsLength');

// Deprecated selector. Do not use it anymore.
// Now origin_location_id should be selected using selectLocation

/**
 * Select only current object and not list here. For instance do not use
 * selectLocationList here because it loads in a async way all the pages
 * so it trigger many times the rendering cycle.
 */
export const selectCurrentGeneric = createSelector(
    selectUser,
    LocationsSelectors.getSelectedLocation,
    (user: User, location: Location): ICurrentGeneric => {
        return {
            user,
            location,
        };
    },
);

export const listenRoute = createSelector(selectUrl, selectCurrentGeneric, (url, current) => {
    return [url, current];
});

export const getUserAndLocationIds = createSelector(
    selectUser,
    LocationsSelectors.getSelectedLocation,
    (user: User, location: Location): IUserAndLocationIds => {
        const uid = get(user, 'partner_id');
        const { id, epj } = location ?? {};
        return uid && id && epj
            ? {
                  location: {
                      epj,
                      id,
                  },
                  uid,
              }
            : undefined;
    },
);

// contacts & ciam contacts

export const selectContacts = createFeatureSelector<IContactsState>('contacts');
export const chatbotInfo = createFeatureSelector<chatbotInfoState>('chatbotInfo');

// Contacts
export const getContactsInfo = createSelector(selectContacts, c => c.info);
export const getContactsLoaded = createSelector(selectContacts, c => c.loaded);
export const getContactsLoading = createSelector(selectContacts, c => c.loading);

//publisher status
export const getSelectPublisherStatus = createSelector(selectPublisherStatus, state => state.publisherStatus);
export const getSelectPublisherStatusLoaded = createSelector(selectPublisherStatus, state => state.loaded);

// Referentiels
export const getDevelopementLabels = createFeatureSelector<DevelopementLabelsState>('developementLabels');

export const getCategoryBrands = createFeatureSelector<CategoryBrandsState>('categoryBrands');

// GFB selectors.
export const selectGFB = (state: IAppState): IGFBState => state.getFeedBack;
export const selectGFBInited = (state: IAppState): boolean => state.getFeedBack.isInited;
export const selectGFBPlatform = (state: IAppState): EPlatform => state.getFeedBack.platform;
export const selectActiveFeature = (state: IAppState): EGFBTypes => state.getFeedBack.activeFeature;
export const selectSnackbarShow = (state: IAppState): boolean => state.getFeedBack.showSnackBar;
export const selectPopupShow = (state: IAppState): boolean => state.getFeedBack.showPopup;
export const selectGFBMap = (state: IAppState): IGFBMap => state.getFeedBack.dataMap;
export const selectActiveEPJs = (state: IAppState): string[] => state.getFeedBack.selectedEPJs;

export const selectGeneralGFBState = (state: IAppState): boolean => state.getFeedBack.isGeneralOpened;

export const selectGFBInit = createSelector(
    selectGFBInited,
    selectGFBPlatform,
    (isInited: boolean, platform: EPlatform) => {
        return {
            isInited,
            platform,
        };
    },
);

export const getGFBById = (featureId: EGFBTypes) =>
    createSelector(selectGFBMap, items => {
        return items ? items[featureId] : ({} as any);
    });

// NEW NAVIGATION AND PAGE SELECTORS

// Get MKT Vars Length
export const getMKTVarsLenght = createSelector(getVarsLength, (state: IvarsLengthState) => state?.varsLength);

export const AuthorizedPacks = new Set([
    'k2sd_premium',
    'pack1',
    'pack2',
    'pack3',
    'ppr_other_offer',
    'k2sd_essential',
]);

export function getHabLocationStatus(roles): boolean {
    const data = roles.filter(role => {
        return role.toLowerCase() === 'pre_habilitated';
    });
    return data?.length === 1;
}

export function getExplicitAuthorization(epj, habLocation) {
    const habAuthorization = habLocation.filter(hab => {
        return hab.epj === epj;
    });
    if (habAuthorization && habAuthorization.length === 1) {
        return habAuthorization[0].explicitAuthorization;
    } else {
        return false;
    }
}
