import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-admin-cviv-page',
    templateUrl: './admin-cviv-page.component.html',
    styleUrls: ['./admin-cviv-page.component.scss'],
})
export class AdminCvivPageComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
