import { Pipe, PipeTransform } from '@angular/core';

// handles different url from string use cases

@Pipe({
    name: 'urlify',
})
export class UrlifyPipe implements PipeTransform {
    transform(text: string, args: { hashtagify?: boolean }): any {
        if (text && args) {
            // `###${'Find me at http://www.example.com and a'.match(/(https?:\/\/[^\s]+)/g)[0]}###`
            if (args.hashtagify) {
                const urlRegExp = /(https?:\/\/[^\s]+)/g;
                const matchingUrl = text.match(urlRegExp);

                if (matchingUrl && matchingUrl[0]) {
                    const extractedUrl = matchingUrl[0];
                    const hashtagifiedUrl = this.applyHashtags(extractedUrl);
                    text = text.replace(extractedUrl, hashtagifiedUrl);
                }
            }
        }

        return text;
    }

    /*
        You can use this together with text-to-url.pipe (wpm-shared/)
        which finds text between ###HERE### and replaces it with anchor tag with href url
    */
    applyHashtags(text: string): string {
        return `###${text}###`;
    }
}
