import { Action } from '@ngrx/store';

import { RoutePathActions } from '@solocal-manager/sirius/core/actions';
import { ActionWithPayload } from '@solocal-manager/sirius/core/models';

export type RoutePathState = string;

const initialState: RoutePathState = '';

export function routePathReducer(state = initialState, action: ActionWithPayload<RoutePathState>): RoutePathState {
    switch (action.type) {
        case RoutePathActions.SET_ROUTE_PATH: {
            return action.payload;
        }
        default:
            return state;
    }
}
