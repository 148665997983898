import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LocationService } from '@slm/location/api';
import { LocationsActions } from '@slm/location/state';
import { HOST_APP } from '@slm/shared/environment';
import {
    CatchApiError,
    GetLocation,
    GetLocationSuccess,
    LocationActionTypes,
    PatchLocation,
    PatchLocationsBulk,
    PatchLocationSiteCatalogSettings,
    PutLocation,
} from '@solocal-manager/sirius/core/actions';
import { filter, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LocationEffects {
    getLocation$ = createEffect(() =>
        this.update$.pipe(
            ofType<GetLocation>(LocationActionTypes.GET_LOCATION),
            map(action => action.payload),
            map((locationId: string) => LocationsActions.loadLocation({ id: locationId, selectIt: true })),
        ),
    );
    putLocation$ = createEffect(() =>
        this.update$.pipe(
            ofType<PutLocation>(LocationActionTypes.PUT_LOCATION),
            map(action => action.payload),
            map(location =>
                LocationsActions.putLocation({
                    locationId: location.id,
                    locationUpdate: location,
                }),
            ),
        ),
    );
    patchLocation$ = createEffect(() =>
        this.update$.pipe(
            ofType<PatchLocation>(LocationActionTypes.PATCH_LOCATION),
            map(action => action.payload),
            map(payload => LocationsActions.patchLocation({ id: payload.locationId, locationUpdate: payload.data })),
        ),
    );
    patchLocationsBulk$ = createEffect(() =>
        this.update$.pipe(
            ofType<PatchLocationsBulk>(LocationActionTypes.PATCH_LOCATIONS_BULK),
            map(action => action.params),
            map(params =>
                LocationsActions.patchLocationsBulk({
                    locationIds: params.locationIds,
                    locationPartial: params.locationPartial,
                }),
            ),
        ),
    );
    patchLocationSiteCatalogSettings$ = createEffect(() =>
        this.update$.pipe(
            ofType<PatchLocationSiteCatalogSettings>(LocationActionTypes.PATCH_LOCATION_SITE_CATALOG_SETTINGS),
            map(action => action.payload),
            map(payload =>
                LocationsActions.patchSelectedLocationSiteCatalogSettings({
                    locationId: payload.locationId,
                    email: payload.email,
                    status: payload.status,
                }),
            ),
        ),
    );
    getLocationSuccess$ = createEffect(() =>
        this.update$.pipe(
            ofType<GetLocationSuccess>(LocationActionTypes.GET_LOCATION_SUCCESS),
            map(action => action.payload),
            map(location =>
                LocationsActions.setFetchedLocation({
                    location,
                    selectIt: true,
                }),
            ),
        ),
    );
    private readonly hostApp = inject(HOST_APP);
    getLocationBackOffice$ = createEffect(() =>
        this.update$.pipe(
            ofType<GetLocation>(LocationActionTypes.GET_LOCATION),
            map(action => action.payload),
            filter(() => this.hostApp.isSlmBackoffice),
            switchMap((locationId: string) => {
                return this.locationService.getLocation(locationId).pipe(
                    map(location => new GetLocationSuccess(location)),
                    catchError(err => {
                        return of(
                            new CatchApiError({
                                source: 'getLocation',
                                getLocation: err,
                            }),
                        );
                    }),
                );
            }),
        ),
    );

    constructor(
        private readonly update$: Actions,
        private readonly locationService: LocationService,
    ) {}
}
