import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-image-editor',
    templateUrl: './image-editor.component.html',
    styleUrls: ['./image-editor.component.scss'],
})
export class ImageEditorComponent {
    @Input()
    locationId: string;
}
