import { Injectable, inject } from '@angular/core';

import { getRedirectUri } from '../classes/auth-helper';
import { SlmAuthFacade } from '../classes/slm-oauth.facade';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
    private slmAuthFacade = inject(SlmAuthFacade);

    public async canActivate(): Promise<boolean> {
        if (!this.slmAuthFacade.isAuthenticated()) {
            await this.slmAuthFacade.login({
                redirectUri: getRedirectUri(),
            });
        }

        return true;
    }
}
