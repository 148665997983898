import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { LocationsActions } from '@slm/location/state';
import { ValidateDotSpace, ValidateMaxMobilePhone, ValidateOneStandardPhone } from '@solocal-manager/sirius/core/core';

import { UiService } from '@solocal-manager/sirius/core/core';
import { LocationActions, PaymentMethodsActions } from '@solocal-manager/sirius/core/core';
import { PaymentMethod } from '@solocal-manager/sirius/core/models';
import { Location, LocationDecorated, Reference } from '@solocal-manager/sirius/support/base-models';
import { pick } from 'lodash-es';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export function createAtLeastOneValidator(form, errorType) {
    return (c: UntypedFormControl) => {
        const isValid = false;
        const err = {};

        if (c.value && c.value.length === 0) {
            err[errorType] = { valid: false };

            return err;
        }
        return null;
    };
}

@Component({
    selector: 'slm-location-edit',
    templateUrl: './location-edit.component.html',
    styleUrls: ['./location-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationEditComponent implements OnInit, OnChanges, OnDestroy {
    id: string;
    form: UntypedFormGroup;
    paymentMethods: number[];
    showSection: object = {};
    paymentMethods$: Observable<PaymentMethod[]>;
    location$: Observable<Location>;
    showOpeningHours = false;
    markerCoordinates: any;
    formReady = false;
    MAX_LENGTH_DESCRIPTION = 750;
    _errors: string[] = [];
    errorTimeoutRef;
    errorTimeout = 500;
    hasPackConfig: boolean;
    isProspect = false;
    openDialog: boolean;
    isREProductType = false;
    publisherStatusObj;
    destroyed$: Subject<boolean> = new Subject();

    @Input()
    publisherStatus;
    @Input()
    readonly = false;
    @Input()
    submitLocation = false;
    @Input()
    location: Location;
    @Output()
    locationSubmited: EventEmitter<boolean> = new EventEmitter();
    @Output()
    isFormValid: EventEmitter<boolean> = new EventEmitter<boolean>(true);

    constructor(
        public formBuilder: UntypedFormBuilder,
        public store: Store<any>,
        public ref: ChangeDetectorRef,
        public translate: TranslateService,
        public uiService: UiService,
    ) {
        this.paymentMethods$ = this.store.pipe(select('paymentMethods'));
    }

    ngOnInit() {
        this.paymentMethods$.pipe(takeUntil(this.destroyed$)).subscribe((paymentMethods: PaymentMethod[]) => {
            if (!!paymentMethods && !paymentMethods.length) {
                this.store.dispatch({
                    type: PaymentMethodsActions.GET_PAYMENT_METHODS,
                });
            }
        });
        this.store.pipe(select('location'), takeUntil(this.destroyed$)).subscribe((location: Location) => {
            this.isREProductType = this.checkIfProductIsRE(location);
        });
    }

    // set invalid city err for address
    async setInvalidCityError() {
        await this.form.controls['address'].setErrors({ incorrect: true });
        this._errors[this._errors.indexOf('address')] = this.translate.instant('Address: please re-enter the zip code');
    }

    checkIfProductIsRE(location: Location): boolean {
        return location?.custom_data?.product_type === 'RE';
    }

    get errors(): string[] {
        return this._errors;
    }

    setErrors(skip = {}) {
        this._errors = [];
        Object.keys(this.form.controls).forEach(key => {
            const input = this.form.get(key);
            if (!skip[key] && !input.pending && input.invalid) {
                this._errors.push(key);
            }
        });
    }

    prepareLocation(location: LocationDecorated) {
        this.hasPackConfig = !this.uiService.isOldOfferLocation(location);
        this.isProspect = this.uiService.isProspectLocation(location);
        this.showOpeningHours = false;

        this.store.dispatch(LocationsActions.updateLocation({ location }));

        this.buildForm();
        this.prepareFormSubscriptions();

        if (this.readonly === true) {
            this.form.disable({ emitEvent: false });
        }

        this.updateForm();
        this.form.updateValueAndValidity();
        this.formReady = true;
    }

    prepareFormSubscriptions() {
        this.form.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe((status: string) => {
            this.setErrors({ urls: true });
            const isValid = status.toLocaleLowerCase() === 'valid' ? true : false;
            setTimeout(() => {
                this.isFormValid.emit(isValid);
            });
        });

        this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(controls => {
            if (this.form.controls.opening_hours.value) {
                const openingHours = this.form.controls.opening_hours.value;
                for (const propertyName in openingHours) {
                    if (
                        propertyName in openingHours &&
                        propertyName === 'periods' &&
                        openingHours[propertyName].length
                    ) {
                        this.showOpeningHours = true;
                    }
                }
            }
        });
    }

    ngOnChanges(input) {
        const currentLocation = input?.location?.currentValue;

        if (currentLocation) {
            this.resetForm();
            this.prepareLocation(currentLocation);
        }

        if (input?.submitLocation?.currentValue === true) {
            this.submit();
        }
    }

    toggleOpeningHours() {
        this.showOpeningHours = !this.showOpeningHours;
    }

    get hasValidateOpeningHours(): boolean {
        if (this.form.controls.opening_hours) {
            return this.form.get('opening_hours').valid && this.form.controls.opening_hours.value.periods?.length;
        }

        return false;
    }

    hasLengthExceeded(maxLength: number, actualLength: number) {
        return actualLength > maxLength;
    }

    calculateCharactersLeft(maxLength: number, actualLength: number) {
        return maxLength - actualLength;
    }

    submit() {
        this.submitLocation = false;

        const locationData: Location = {
            ...this.location,
            ...this.form.value,
        };

        this.store.dispatch({
            type: LocationActions.PUT_LOCATION,
            payload: locationData,
        });

        this.locationSubmited.emit(true);
    }

    resetForm() {
        this.formReady = false;
        this.form = undefined;
        this._errors = [];
    }

    buildForm() {
        this.form = this.formBuilder.group({
            name: ['', Validators.required],
            alias: [''],
            address: [{}],
            urls: [[]],
            emails: [[]],
            specific_opening_hours: [[]],
            opening_hours: [{}],
            payment_methods: [[]],
            photos: [[]],
            categories: [{}, [createAtLeastOneValidator(this.form, 'oneCategoryNeeded')]],
            videos: [[]],
            services: [[]],
            products: [[]],
            brands: [[]],
            associations: [[]],
            certifications: [[]],
            customers: [[]],
            description: [
                '',
                [this.maxLengthValidator(this.MAX_LENGTH_DESCRIPTION), ValidateDotSpace.createValidator()],
            ],
            phones: [
                [],
                [
                    Validators.required,
                    ValidateOneStandardPhone.createValidator(),
                    ValidateMaxMobilePhone.createValidator(),
                ],
            ],
            service_area: [{}],
            publisher_specific: [[]],
        });
    }
    maxLengthValidator(maxLength: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value && control.value.length > maxLength) {
                return { maxLengthExceeded: true };
            }

            return null;
        };
    }
    updateForm() {
        const _urls = pick(this.location, ['urls']);
        const formLocation = pick(this.location, [
            'name',
            'alias',
            'address',
            'phones',
            'emails',
            'opening_hours',
            'specific_opening_hours',
            'payment_methods',
            'videos',
            'photos',
            'services',
            'products',
            'brands',
            'associations',
            'certifications',
            'customers',
            'description',
            'categories',
            'service_area',
            'publisher_specific',
            'urls',
        ]);

        const cat = formLocation?.publisher_specific?.[0]?.categories;
        if (cat) {
            formLocation.publisher_specific[0].categories = cat.map((item: Reference) => {
                return {
                    id: item.id,
                    name: item.name,
                    paid: item.paid,
                };
            });
        }

        formLocation.urls = [];

        this.form.setValue(formLocation);

        setTimeout(() => {
            const urlForm = this.form.get('urls');
            urlForm.setValue(_urls.urls);
            this.form.updateValueAndValidity();
        });
    }

    ngOnDestroy(): void {
        this.resetForm();
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    markerDragEnd(event) {
        if (event && event.lat && event.lng) {
            this.markerCoordinates = { coords: event };
        }
    }
}
