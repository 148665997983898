import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@slm/shared/environment';
import { PaginationDecoratorDTO } from '@solocal-manager/sirius/core/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    ILocationKpi,
    IPageStatesKpi,
    IParcUsageKpi,
    IProvisioningIssueDetailsKpi,
    IProvisioningIssuesKpi,
    IPublisherErrorsKpi,
    ITotalSalesKpi,
} from '../models';

@Injectable({ providedIn: 'root' })
export class KpiService {
    constructor(
        public http: HttpClient,
        public envService: EnvironmentService,
    ) {}

    getPageStates(): Observable<IPageStatesKpi[]> {
        const requestUrl = `${this.envService.apiUrl}/consumer/kpis/page-states`;

        return this.http.get(requestUrl).pipe(map((result: { data: IPageStatesKpi[] }) => result.data));
    }

    getLocationListByError(
        publisherId: string,
        errorMessage: string,
        pageSize?: number,
        pageNumber?: number,
    ): Observable<PaginationDecoratorDTO<ILocationKpi>> {
        const requestUrl = `${this.envService.apiUrl}/consumer/kpis/pos-list-by-error`;

        const params = { publisher_id: publisherId, error_msg: errorMessage };
        if (pageSize) {
            params['page_size'] = '' + pageSize;
        }
        if (pageNumber) {
            params['page_number'] = '' + pageNumber;
        }

        return this.http
            .get(requestUrl, { params })
            .pipe(map((result: PaginationDecoratorDTO<ILocationKpi>) => result));
    }

    getPublisherErrors(publisherId: string, states?: string[]): Observable<IPublisherErrorsKpi[]> {
        const requestUrl = `${this.envService.apiUrl}/consumer/kpis/publisher-errors`;

        const params = { publisher_id: publisherId };
        if (states && states.length) {
            params['states'] = states.join(',');
        }

        return this.http
            .get(requestUrl, { params })
            .pipe(map((result: { data: IPublisherErrorsKpi[] }) => result.data));
    }

    getTotalSales(status?: string): Observable<ITotalSalesKpi> {
        const requestUrl = `${this.envService.apiUrl}/consumer/kpis/total-sales`;

        const params = {};
        if (status) {
            params['status'] = status;
        }

        return this.http.get(requestUrl, { params }).pipe(map((result: ITotalSalesKpi) => result));
    }

    getProvisioningIssues(): Observable<IProvisioningIssuesKpi> {
        const requestUrl = `${this.envService.apiUrl}/consumer/kpis/provisioning-issues`;

        return this.http.get(requestUrl).pipe(map((result: IProvisioningIssuesKpi) => result));
    }

    getProvisioningIssueDetails(
        errorType: string,
        year: number,
        week: number,
    ): Observable<IProvisioningIssueDetailsKpi> {
        const requestUrl = `${this.envService.apiUrl}/consumer/kpis/provisioning-issues/${errorType}/year/${year}/week/${week}`;

        return this.http.get(requestUrl).pipe(map((result: IProvisioningIssueDetailsKpi) => result));
    }

    getTotalParcUsage(yearFrom?: string, yearUntil?: string): Observable<IParcUsageKpi[]> {
        const requestUrl = `${this.envService.apiUrl}/consumer/kpis/total-parc-usage`;

        const params = {};
        if (yearFrom) {
            params['year'] = yearFrom;
        }
        if (yearUntil) {
            params['until'] = yearUntil;
        }

        return this.http.get(requestUrl, { params }).pipe(map((result: { data: IParcUsageKpi[] }) => result.data));
    }
}
