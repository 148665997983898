<div class="container-fluid">
    <div class="row m-top-5">
        <div class="col-md-2 col-sm-2 col-2 onboarding-title">
            {{ 'Prolive' | translate }}
        </div>
        <div class="col-md-4 col-sm-4 col-4">
            <a (click)="openSolocalManager(location?.partner_id, location?.epj)" class="confirm-button-custom">{{
                'Open in Tab' | translate
            }}</a>
        </div>
    </div>
</div>
