<div class="container-fluid">
    <div class="row m-top-5" *ngIf="!!gmbPage">
        <div class="col-md-2 col-sm-2 col-2 production-title">
            {{ 'GMB' | translate }}
        </div>
        <div class="col-md-4 col-sm-4 col-4">
            <a (click)="navigateToGMBAdmin()" class="confirm-button-custom" target="_blank"
                >{{ 'Start GMB Admin' | translate }}
            </a>
        </div>
    </div>

    <div class="row m-top-5" *ngIf="facebookAdminUrl">
        <div class="col-md-2 col-sm-2 col-2 production-title">
            {{ 'Facebook' | translate }}
        </div>
        <div class="col-md-4 col-sm-4 col-4">
            <a (click)="navigateToFacebookDeeplink()" class="confirm-button-custom" target="_blank"
                >{{ 'Start Facebook Admin' | translate }}
            </a>
        </div>
    </div>

    <div class="row m-top-5" *ngIf="facebookPageId">
        <div class="col-md-2 col-sm-2 col-2 production-title">
            {{ 'FB admin link' | translate }}
        </div>
        <div class="col-md-10 col-sm-10 col-10">
            <p class="fb-link">{{ facebookLink }}</p>
        </div>
    </div>
</div>
